import React from "react";
import { expenseService, invoiceService } from "../services";
import { RecentTransactionType } from "../@types";

export const useFetchTransactions = (limit = 3) => {
  const [error, setError] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [transactions, setTransactions] = React.useState<
    RecentTransactionType[]
  >([]);

  const fetchTransactions = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const [invoices, expensesResponse] = await Promise.all([
        invoiceService.get(`/invoices?limit=${limit}`),
        expenseService.get(`/expenses?limit=${limit}`),
      ]);

      const expenseData = Array.isArray(expensesResponse.data?.data)
        ? expensesResponse.data.data
        : [];

      const mergedTransactions: RecentTransactionType[] = [
        ...invoices.data.data.map((invoice: any) => ({
          data: { ...invoice, type: "invoice" },
        })),
        ...expenseData.map((expense: any) => ({
          data: { ...expense, type: "expense" },
        })),
      ];

      const sortedTransactions = mergedTransactions.sort((a, b) => {
        //@ts-ignore
        const timestampA = new Date(a.data.createdAt).getTime();
        //@ts-ignore
        const timestampB = new Date(b.data.createdAt).getTime();
        return timestampB - timestampA;
      });

      setTransactions(sortedTransactions.slice(0, 4));
    } catch (error) {
      setError("Error fetching transactions.");
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchTransactions();
  }, []);

  return {
    transactions,
    error,
    isLoading,
    fetchTransactions,
  };
};
