import axios, { AxiosInstance } from "axios";
import { getAuthToken } from "../utils";

const defaultService = axios.create({});

export const configureDefaultService = (service: AxiosInstance) => {
  service.interceptors.request.use(
    async (config) => {
      const token = await getAuthToken();
      if (token) {
        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    },
  );
};

export { defaultService };
