import React, { FormEventHandler, useState } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useNavigate, useLocation, Link } from "react-router-dom";
import LogoBlack from "../LogoBlack";
import * as UI from "../common";
import { isPasswordValid } from "../../utils";
import { Alert } from "flowbite-react";
import { InputComponent } from "../core/Forms";
import userPool from "../../utils/userPool";
import { useTranslation } from "react-i18next";
import useCognito from "../../hooks/auth/useCognito";
import ForgetPasswordIcon from "../../assets/icons/forgetPasswordIcon";
import toast from "react-hot-toast";

interface FormData {
  password: string;
  confirmPassword: string;
}

interface Errors {
  password: string;
  confirmPassword: string;
}

interface ValidationResults {
  confirmPassword: string;
}

const ChangePasswordView = () => {
  const { t } = useTranslation("onboarding");
  const {
    login,
    loading: isLoginLoading,
    error: message,
    confirmPassword,
  } = useCognito();

  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const code = searchParams.get("code");
  if (!email) throw new Error("user not found");

  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonText] = useState("Continue");
  const [attempts, setAttempts] = useState(0);

  const [formData, setFormData] = useState<FormData>({
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState<Errors>({
    password: "",
    confirmPassword: "",
  });

  const [validationResults, setValidationResults] = useState<ValidationResults>(
    {
      confirmPassword: "",
    },
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (name === "password") {
      let passwordError = "";
      if (!value) {
        passwordError = "Password is required";
      } else if (value.length < 8) {
        passwordError = "Password must be at least 8 characters";
      } else if (!/[A-Z]/.test(value)) {
        passwordError = "Password must contain at least one uppercase letter";
      } else if (!/[a-z]/.test(value)) {
        passwordError = "Password must contain at least one lowercase letter";
      } else if (!/[0-9]/.test(value)) {
        passwordError = "Password must contain at least one number";
      } else if (!/[^A-Za-z0-9]/.test(value)) {
        passwordError = "Password must include at least one special character";
      }
      setErrors({ ...errors, password: passwordError });
    }

    if (name === "confirmPassword") {
      if (!value) {
        setErrors({ ...errors, confirmPassword: "Confirm the Password" });
      } else if (value !== formData.password) {
        setErrors({ ...errors, confirmPassword: "Passwords do not match" });
      } else {
        setErrors({ ...errors, confirmPassword: "" });
      }
    }
  };
  const queryParams = location.search;

  const handleChangePassword: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (Object.values(errors).some((error) => error)) {
      setErrorMessage("Please correct the errors before submitting.");
      return;
    }

    if (!code) {
      setErrorMessage("Code cannot be null.");
      return;
    }

    setIsLoading(true);

    try {
      await confirmPassword(email, code, formData.password);

      toast.success("Password reset successful.");
      navigate("/login");
    } catch (err: any) {
      const message = err?.message || "Error resetting password. Try again.";
      setErrorMessage(message);
      switch (err.code) {
        case "CodeMismatchException":
        case "ExpiredCodeException":
          navigate(`/reset-password${queryParams}`, {
            state: { errorMessage: message },
          });
          break;
        case "UserNotFoundException":
        case "NotAuthorizedException":
          navigate("/login", { state: { errorMessage: message } });
          break;
        default:
          console.error("Unexpected error:", message);
          setErrorMessage(
            "An unexpected error occurred, please try again later.",
          );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* Desktop */}
      <div className="hidden lg:block md:block">
        <div className="flex items-center justify-center min-h-screen bg-[#F2F9FD]">
          <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
            {/* Logo Section */}
            <div className="flex justify-center mb-6 lg:hidden ">
              <LogoBlack />
            </div>
            <div className="flex justify-center">
              <ForgetPasswordIcon />
            </div>

            {/* Heading and Subtext */}
            <div className="text-center mb-4 mt-[1em]">
              <h1 className=" font-semibold text-[20px]">Reset Password</h1>
              <p className="text-[16px] text-gray-600 mt-2">
                Let’s help you reset your password.
              </p>
              {errorMessage && (
                <Alert color="failure" className="mt-4">
                  {errorMessage}
                </Alert>
              )}
            </div>

            {/* Form Section */}
            <form onSubmit={handleChangePassword}>
              <div className="mb-7 mt-[2em]">
                <InputComponent
                  placeholder="New password"
                  name="password"
                  type="password"
                  value={formData.password}
                  handleChange={handleInputChange}
                  handleOnInput={handleInputChange}
                  handleOnFocus={handleInputChange}
                  handleOnBlur={handleInputChange}
                  error={errors.password}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  imageClassName="ml-[21em] 375:ml-[15em]  414:ml-[15em] 360:ml-[15em] 412:ml-[15em] mt-[-5em] md:ml-[22em] md:mt-[-5.5em] lg:ml-[23em] lg:mt-[-2em] xl:ml-[23em] xl:mt-[-2em] 540:ml-[15em] 540:mt-[-4.5em]"
                />
              </div>

              <div className="mb-4 mt-[1em]">
                <InputComponent
                  placeholder="Confirm password"
                  name="confirmPassword"
                  type="password"
                  value={formData.confirmPassword}
                  handleChange={handleInputChange}
                  handleOnInput={handleInputChange}
                  handleOnFocus={handleInputChange}
                  handleOnBlur={handleInputChange}
                  error={
                    validationResults.confirmPassword || errors.confirmPassword
                  }
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  imageClassName=" ml-[21em] 375:ml-[15em]  414:ml-[15em] 360:ml-[15em] 412:ml-[15em] mt-[-5em] md:ml-[22em] md:mt-[-5.5em]  lg:ml-[23em] lg:mt-[-2em] xl:ml-[23em] xl:mt-[-2em] 540:ml-[15em] 540:mt-[-4.5em]"
                />
              </div>

              <div className="mb-6 mt-[1.5em] text-[16px]">
                <UI.Button
                  disabled={attempts >= 1}
                  text={buttonText}
                  variant="secondary"
                  isLoading={isLoading}
                />
              </div>

              <div className="text-center text-[16px]">
                <Link
                  to="/"
                  className="text-[#044E97] hover:underline text-[16px] font-semibold"
                >
                  Cancel
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div>
        <div className="lg:hidden md:hidden mt-[6em]">
          <div className="flex justify-center">
            <ForgetPasswordIcon />
          </div>

          {/* Heading and Subtext */}
          <div className="text-center mb-4 mt-[1em]">
            <h1 className="text-2xl font-semibold lg:text-3xl">
              Reset Password
            </h1>
            <p className="text-sm lg:text-base text-gray-600 mt-2 px-[2em]">
              Let’s help you reset your password.
            </p>
            {errorMessage && (
              <Alert color="failure" className="mt-4">
                {errorMessage}
              </Alert>
            )}
          </div>

          {/* Form Section */}
          <form onSubmit={handleChangePassword}>
            <div className="mb-4 px-[2em]">
              <InputComponent
                placeholder="New Password"
                name="password"
                type="password"
                value={formData.password}
                handleChange={handleInputChange}
                handleOnInput={handleInputChange}
                handleOnFocus={handleInputChange}
                handleOnBlur={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
                imageClassName="mt-[-2.1em] ml-[16em] 375:ml-[17em] 390:ml-[18em] 430:ml-[21em] 412:ml-[20em] 360:ml-[16em] 414:ml-[20em] 540:ml-[25em] "
              />
            </div>

            <div className="mb-4 px-[2em]">
              <InputComponent
                placeholder="Confirm Password"
                name="confirmPassword"
                type="password"
                value={formData.confirmPassword}
                handleChange={handleInputChange}
                handleOnInput={handleInputChange}
                handleOnFocus={handleInputChange}
                handleOnBlur={handleInputChange}
                error={
                  validationResults.confirmPassword || errors.confirmPassword
                }
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
                imageClassName="mt-[-2.1em] ml-[16em] 375:ml-[17em] 390:ml-[18em] 430:ml-[21em] 412:ml-[20em] 360:ml-[16em] 414:ml-[20em] 540:ml-[25em] "
              />
            </div>

            <div className="mb-6 mt-[1em] px-[2em]">
              <UI.Button
                disabled={attempts >= 1}
                text={buttonText}
                variant="secondary"
                isLoading={isLoading}
              />
            </div>

            <div className="text-center">
              <Link
                to="/"
                className="text-blue-600 hover:underline text-sm font-semibold"
              >
                Cancel
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export { ChangePasswordView };
