import React from "react";
import { Tooltip } from "../../../assets/icons/Tooltip";

const TooltipDropdown = ({
  toolTipText,
  isHovered,
}: {
  toolTipText: string;
  isHovered: boolean;
}) => {
  return (
    <div>
      <Tooltip />
      {isHovered && toolTipText && toolTipText.length > 0 && (
        <div className="absolute w-[204px] left-[-5.7em] z-50">
          <div className="flex items-center justify-center">
            <svg
              width="18"
              height="15"
              viewBox="0 0 18 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#FFF4C6" />
            </svg>
          </div>
          <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
            {toolTipText}
          </div>
        </div>
      )}
    </div>
  );
};

export default TooltipDropdown;
