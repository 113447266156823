import React from "react";
import { useTranslation } from "react-i18next";
import { ArchivedIcon } from "../../assets/icons/ArchivedIcon";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import EditIcon from "../../assets/icons/EditIcon";
import RemoooveIcon from "../../assets/icons/RemooveIcon";
import RestockIcon from "../../assets/icons/restock";
import { GroupButton } from "../../components/common";
import { useUserStore } from "../../state";
import { isAdmin } from "../../utils/isAdmin";
import { isNotAccountant } from "../../utils/isNotAccountant";

interface InventoryActionButtonsProps {
  onEdit: () => void;
  onArchive: () => void;
  onRemoveStock: () => void;
  onDelete?: () => void;
  onRestock?: () => void;
}

const InventoryActionButtons: React.FC<InventoryActionButtonsProps> = ({
  onEdit,
  onArchive,
  onRemoveStock,
  onDelete,
  onRestock,
}) => {
  const { roles } = useUserStore();
  const { t } = useTranslation("inventories");

  const isAdminUser = isAdmin(roles);
  const canRemoveStock = isNotAccountant(roles);

  const groupButtons = isAdminUser
    ? [
        {
          text: t("inventory.edit"),
          icon: <EditIcon color="#344054" size="18" />,
          fontSize: "text-[16px] text-[#344054]",
          onClick: onEdit,
        },
        {
          text: t("archive"),
          icon: <ArchivedIcon size="18" />,
          fontSize: "text-[16px] text-[#344054]",
          onClick: onArchive,
        },
        {
          text: "Remove Stock",
          icon: <RemoooveIcon size="16" />,
          fontSize: "text-[16px] text-[#344054] px-2",
          onClick: onRemoveStock,
        },
        ...(onDelete
          ? [
              {
                text: t("inventory.delete"),
                icon: <DeleteIcon size="22" />,
                fontSize: "text-[16px] text-[#FB0303]",
                onClick: onDelete,
              },
            ]
          : []),
        ...(onRestock
          ? [
              {
                text: t("inventory.restock"),
                icon: <RestockIcon />,
                fontSize: "text-[16px] px-2 text-[#344054]",
                onClick: onRestock,
              },
            ]
          : []),
      ]
    : [
        {
          text: t("inventory.edit"),
          icon: <EditIcon />,
          fontSize: "text-[16px]",
          onClick: onEdit,
        },
        {
          text: t("archive"),
          icon: <ArchivedIcon size="18" />,
          fontSize: "text-[16px]",
          onClick: onArchive,
        },
        {
          text: t("inventory.removeStock"),
          icon: <RemoooveIcon />,
          fontSize: "text-[16px] text-[#FB0303] px-2",
          onClick: onRemoveStock,
        },
      ];

  return (
    <>
      <div className="bg-[#EDF2F5] hidden lg:block md:block rounded-md">
        {canRemoveStock && <GroupButton buttons={groupButtons} />}
      </div>
    </>
  );
};

export default InventoryActionButtons;
