import React, { useState, useEffect } from "react";
import { CloseIcon } from "../../../assets/icons";
import UpArrowClose from "../../../assets/icons/UpArrowClose";
import { on } from "stream";

interface FilterRecord {
  label: string;
  value: string | boolean;
}

export interface FilterCategory {
  title: string;
  options: FilterRecord[];
  type: string;
}

interface MobileFilterProps {
  title: string;
  categories: FilterCategory[];
  onApply?: (filters: Record<string, string | boolean>) => void;
  setOpenFilter: (openFilter: boolean) => void;
  clearAllFilters?: () => void;
}

const MobileFilter: React.FC<MobileFilterProps> = ({
  title,
  categories,
  onApply,
  setOpenFilter,
  clearAllFilters,
}) => {
  const [localFilters, setLocalFilters] = useState<
    Record<string, string | boolean>
  >({});
  const [expandedCategories, setExpandedCategories] = useState<
    Record<string, boolean>
  >({});

  const handleOptionChange = (category: string, value: string | boolean) => {
    setLocalFilters((prev) => ({
      ...prev,
      [category]: value,
    }));
  };

  useEffect(() => {
    const initialExpanded: Record<string, boolean> = {};

    if (categories.length > 0) {
      initialExpanded[categories[0].type] = true;
    }
    setExpandedCategories(initialExpanded);
  }, []);

  const toggleCategory = (type: string) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const onSubmit = (localFilters: Record<string, string | boolean>) => {
    if (onApply) {
      onApply(localFilters);
      setOpenFilter(false);
    }
  };

  const handleClear = () => {
    setLocalFilters({});
    clearAllFilters && clearAllFilters();
    setOpenFilter(false);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-end bg-gray-900 bg-opacity-50">
      {/* Modal Container */}
      <div className="w-full bg-white p-6 shadow-lg rounded-t-3xl">
        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg leading-6 text-[#000] font-semibold">
            {title}
          </h2>
          <button
            onClick={() => setOpenFilter(false)}
            className="text-gray-500"
          >
            <CloseIcon width="18" height="18" />
          </button>
        </div>

        {/* Filters */}
        <div className="space-y-3">
          {categories.map((category) => (
            <div key={category.type} className="p-2">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleCategory(category.type)}
              >
                <h3 className="text-sm font-medium text-gray-700">
                  {category.title}
                </h3>
                <button>
                  {expandedCategories[category.type] ? (
                    <div className="rotate-180">
                      <UpArrowClose />
                    </div>
                  ) : (
                    <UpArrowClose />
                  )}
                </button>
              </div>

              {expandedCategories[category.type] && (
                <div className="space-y-1 mt-2">
                  {category.options.map((option) => (
                    <div
                      key={option.value.toString()}
                      className={`p-2 text-gray-600 rounded-lg ${
                        localFilters[category.type] === option.value
                          ? "bg-blue-100 text-blue-600"
                          : "hover:bg-gray-100"
                      }`}
                      onClick={() =>
                        handleOptionChange(category.type, option.value)
                      }
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between font-semibold text-[16px] mt-6 w-full gap-4">
          <button
            className="h-12 w-full text-[#044E97] border border-[#044E97] rounded-[4px]"
            onClick={handleClear}
          >
            Clear
          </button>
          <button
            className="h-12 w-full text-white bg-[#044E97] rounded-[4px]"
            onClick={() => onSubmit(localFilters)}
          >
            Apply Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileFilter;
