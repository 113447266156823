import React from "react";

const Profile = ({ color = "" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.875 17.3333C0.875 13.2391 4.58392 10.0417 9 10.0417C13.4161 10.0417 17.125 13.2391 17.125 17.3333V17.9583H0.875V17.3333Z"
        fill={color}
      />
      <path
        d="M4.625 4.41666C4.625 2.00041 6.58375 0.0416565 9 0.0416565C11.4162 0.0416565 13.375 2.00041 13.375 4.41666C13.375 6.8329 11.4162 8.79166 9 8.79166C6.58375 8.79166 4.625 6.8329 4.625 4.41666Z"
        fill={color}
      />
    </svg>
  );
};

export default Profile;
