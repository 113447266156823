import React, { useEffect, useState } from "react";
import { DashboardStats } from "../../@types";
import { profileService } from "../../services";
import { MobileBarchart } from "../core/dashboard/CashFlowChart/mobileBarchart";
import { ImSpinner } from "react-icons/im";
import { useTranslation } from "react-i18next";
import { dashboardInitialState } from "../../utils/dashboard";
import { CashFlowChart } from "../core/dashboard/CashFlowChart";

const CashflowTrend = () => {
  const { t } = useTranslation("dashboard");
  const currentYear = new Date().getFullYear()?.toString();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [barStats, setBarStats] = useState<DashboardStats>(
    dashboardInitialState,
  );
  const [year, setYear] = useState<string>("2024");
  const cashflowTrendData = barStats.cashflowTrend.trend as Record<
    string,
    { invoice: number; expense: number }
  >;
  const hasData = Object.values(cashflowTrendData).some(
    (dataPoint) => dataPoint.invoice !== 0 || dataPoint.expense !== 0,
  );
  const isSmallScreen = () => {
    return window.innerWidth < 768;
  };
  const handleChangeYear = (selectedYear: string) => {
    if (!selectedYear.trim()) {
      return;
    }
    const yearNumber = parseInt(selectedYear, 10);
    if (isNaN(yearNumber)) {
      return;
    }
    setIsLoading(false);
    setYear(selectedYear);
  };

  useEffect(() => {
    fetchTransactionBarChart(year);
  }, [year]);

  const fetchTransactionBarChart = async (selectedYear: string) => {
    setIsLoading(true);
    try {
      const parsedYear = parseInt(selectedYear, 10);
      if (!isNaN(parsedYear)) {
        const startDate = new Date(parsedYear, 0, 1);
        const endDate = new Date(parsedYear, 11, 31, 23, 59, 59, 999);
        const startDateISO = startDate.toISOString();
        const endDateISO = endDate.toISOString();
        const [stats] = await Promise.all([
          profileService.get(
            `/companies/stats?limit=${4}&start=${startDateISO}&end=${endDateISO}`,
          ),
        ]);
        setBarStats(stats.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setError("Error fetching transactions.");
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="md:hidden mt-[1em]">
        {isLoading ? (
          <div className="h-[280px] w-full bg-white flex justify-center items-center rounded-lg">
            <ImSpinner className="h-16 w-20 mx-auto text-slate-300 animate-spin" />
          </div>
        ) : hasData ? (
          <div className={` ${isSmallScreen() ? "overflow-x-auto" : ""}`}>
            <MobileBarchart
              title={t("cashflowTrend")}
              icon={""}
              data={barStats.cashflowTrend.trend}
              offset={-10}
              colorScale={barStats.cashflowTrend.fill}
              isEmpty={false}
              onChangeYear={handleChangeYear}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
            />
          </div>
        ) : (
          <div
            className={`h-[228px] ${isSmallScreen() ? "overflow-x-auto" : ""}`}
          >
            <MobileBarchart
              title={t("cashflowTrend")}
              icon={""}
              data={barStats.cashflowTrend.trend}
              offset={-10}
              colorScale={barStats.cashflowTrend.fill}
              isEmpty={true}
              onChangeYear={handleChangeYear}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
            />
          </div>
        )}
      </div>
      <div className="bg-white hidden md:block md:mt-4 w-[100%] rounded-lg">
        {isLoading ? (
          <div className="h-[360px] w-full flex justify-center items-center">
            <ImSpinner className="h-16 w-20 mx-auto text-slate-300 animate-spin" />
          </div>
        ) : hasData ? (
          <CashFlowChart
            title={t("cashflowTrend")}
            data={barStats.cashflowTrend.trend}
            standalone={false}
            orientation="left"
            colorScale={barStats.cashflowTrend.fill}
            isEmpty={false}
            onChangeYear={handleChangeYear}
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
            toolTipText={t("CashflowtrendTooltip")}
          />
        ) : (
          <div className="h-[360px]">
            <CashFlowChart
              title={t("cashflowTrend")}
              data={barStats.cashflowTrend.trend}
              standalone={false}
              orientation="left"
              colorScale={barStats.cashflowTrend.fill}
              isEmpty={true}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              onChangeYear={handleChangeYear}
              toolTipText={t("CashflowtrendTooltip")}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default CashflowTrend;
