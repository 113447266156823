import axios, { AxiosInstance } from "axios";
import config from "../config";
import { getAuthToken, setupAuthInterceptor } from "../utils";
import { History } from "history";

export const createCustomerService = (): AxiosInstance => {
  const customerService = axios.create({
    baseURL: config.customerServiceURL,
  });

  setupAuthInterceptor(customerService);

  return customerService;
};

const customerService = createCustomerService();
export { customerService };
