import * as Sentry from "@sentry/react";
import { handleError } from "./errorHandler";

const setupGlobalErrorHandler = () => {
  window.onerror = function (message, source, lineno, colno, error) {
    const errorMessage =
      typeof message === "string" ? message : "Unknown error";

    const errorObj = error instanceof Error ? error : new Error(errorMessage);
    handleError(errorObj, "Global onerror");
  };

  window.onunhandledrejection = function (event) {
    const reason =
      event.reason instanceof Error
        ? event.reason
        : new Error(String(event.reason));
    handleError(reason, "Global onunhandledrejection");
  };
};

export default setupGlobalErrorHandler;
