import React from "react";
import { CloseIcon } from "../../assets/icons";

interface InventoryModalProps {
  children: JSX.Element;
  setOpenModal: (show: boolean) => void;
  modalSize?: boolean;
}

const InventoryModal = ({
  children,
  setOpenModal,
  modalSize,
}: InventoryModalProps) => {
  const isVisible = true;
  if (!isVisible) return null;
  const cxs = modalSize
    ? " md:w-[93%] md:left-[5%] lg:left-[20%] lg:w-[74%]"
    : "md:w-[81%] md:left-[10%] lg:left-[28%] lg:w-[60%]";
  return (
    <>
      <div className="absolute top-0 left-0 w-screen h-[100%] bg-black bg-opacity-80 z-10"></div>
      <div
        className={`fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-scroll h-[calc(100%-1rem)] max-h-full md:absolute md:right-[50%] md:top-[15%] md:transform md-:translate-x-1/2 md:z-20 md:h-[40rem] scrollbar-hid ${cxs}`}
      >
        <div className="">
          <div className="bg-[#F2F9FD] p-2 rounded">{children}</div>
        </div>
      </div>
    </>
  );
};

export { InventoryModal };
