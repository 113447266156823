import React from "react";
import { useNavigate } from "react-router-dom";
import { ErrorIcon404, ErrorIcon500 } from "../../assets/icons";

export interface ErrorPageProps {
  statusCode: number;
  errorTitle: string;
  errorText: string;
  actionButton: React.ReactNode;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  statusCode,
  errorTitle,
  errorText,
  actionButton,
}) => {
  const icon =
    statusCode === 404 ? (
      <ErrorIcon404 aria-label="Page not found" />
    ) : (
      <ErrorIcon500 aria-label="Internal server error" />
    );

  return (
    <div className="flex flex-col justify-center items-center mt-auto h-screen">
      {icon}
      <h2 className="text-xl font-bold">{errorTitle}</h2>
      <p className="text-lg whitespace-pre-wrap text-center">{errorText}</p>
      <a
        href="/dashboard"
        className="flex items-center justify-center mt-[5%] px-3  font-medium text-white bg-[#044E97]"
      >
        <svg
          width="18"
          height="14"
          viewBox="0 0 18 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 6.90874L1.32733 7.64868L0.513393 6.90874L1.32733 6.1688L2 6.90874ZM17 5.90874C17.5523 5.90874 18 6.35645 18 6.90874C18 7.46102 17.5523 7.90874 17 7.90874L17 5.90874ZM7.32733 13.1032L1.32733 7.64868L2.67267 6.1688L8.67267 11.6233L7.32733 13.1032ZM1.32733 6.1688L7.32733 0.714251L8.67267 2.19413L2.67267 7.64868L1.32733 6.1688ZM2 5.90874L17 5.90874L17 7.90874L2 7.90874L2 5.90874Z"
            fill="white"
          />
        </svg>
        {actionButton}
      </a>
    </div>
  );
};

export { ErrorPage };
