import React, { useEffect, useRef, useState } from "react";
import { ICreateSalesData } from "../../../../@types";
import CanDollarIcon from "../../../../assets/icons/CanDollarIcon";
import NairaCurrency from "../../../../assets/icons/NairaCurrency";
import PoundsIcon from "../../../../assets/icons/PoundsIcon";
import { Tooltip } from "../../../../assets/icons/Tooltip";
import UsaIcon from "../../../../assets/icons/UsaIcon";
import { InvoiceErrors } from "../../../../modules/validateInvoiceForm";
import { DropdownComponent, InputComponent } from "../../../core/Forms";
import { useTranslation } from "react-i18next";

interface InvoiceCurrencyProps {
  handleDropDownChange: (name: string, value: string) => void;
  formData: ICreateSalesData;
  setFormData: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
  selectedCurrencyLabel: string;
  validationResults: InvoiceErrors;
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>;
  showRateInput: boolean;
  toolTipText?: string;
}

export const PAYMENT_CURRENCY_LIST = [
  { value: "NGN", label: "Naira ", name: "₦ ", icon: <NairaCurrency /> },
  { value: "USD", label: "Dollar  ", name: "$ ", icon: <UsaIcon /> },
  { value: "GBP", label: "Pound Sterling ", name: "GBP", icon: <PoundsIcon /> },
  {
    value: "CAD",
    label: "Canadian Dollar  ",
    name: "CAD",
    icon: <CanDollarIcon />,
  },
];

const InvoiceCurrency: React.FC<InvoiceCurrencyProps> = ({
  formData,
  selectedCurrencyLabel,
  handleDropDownChange,
  validationResults,
  setValidationResults,
  showRateInput,
  setFormData,
  toolTipText,
}) => {
  const { t } = useTranslation("invoices");
  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dropDownCurrency = PAYMENT_CURRENCY_LIST;
  const dynamicWidth =
    formData.currency === "Naira"
      ? "lg:w-[300px] md:w-[150px]"
      : "lg:w-[144px] md:w-[144px]";

  return (
    <>
      {/* Desktop */}
      <div className="hidden lg:block md:block xl:mt-[-2em] lg:mt-[-2em] relative z-9">
        <div className="mt-1 flex flex-col justify-end items-end text-right xl:mr-[15.5em] md:mr-[14em] biglg:mr-[17em] bigmd:mr-[15em]">
          <div
            className="xl:mr-[-11.7em] md:mr-[-12em] biglg:mr-[-13.7em] lg:mr-[-11em]"
            ref={dropdownRef}
          >
            <div className="flex gap-1">
              <label
                htmlFor="Currency"
                className="lg:text-[15px] md:text-[14px] font-normal md:mr-[3em] biglg:mr-[1em] xl:mr-[1.1em] cursor-pointer"
                style={{ whiteSpace: "nowrap" }}
              >
                {t("selectCurrency")}
              </label>
              {toolTipText && (
                <div
                  className="relative ml-[-3em] md:ml-[-2.6em] biglg:ml-[-1em] xl:ml-[-0.5em] mt-[0.3em] md:mt-[0.2em]"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Tooltip />
                  {isHovered && (
                    <div className="absolute w-[204px] left-[-5.7em] z-10">
                      <div className="flex items-center justify-center">
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 0L17.6603 15H0.339746L9 0Z"
                            fill="#FFF4C6"
                          />
                        </svg>
                      </div>
                      <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                        {toolTipText}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div onClick={handleDropdownClick}>
              <DropdownComponent
                name="currency"
                options={dropDownCurrency}
                value={formData.currency}
                placeholder={selectedCurrencyLabel || "Select Currency"}
                selectedValue={selectedCurrencyLabel}
                onChange={(name, value) => handleDropDownChange(name, value)}
                dropdownClassName="lg:w-[320px] md:w-[330px] rounded cursor-pointer md:mt-[0.6em]"
                iconClassName="mr-[2.6em] bigmd:mr-[1.7em] xl:mr-[1em] biglg:mr-[1em] md:mr-[1em] lg:mr-[1em] biglg:mt-[0.7em] xl:mt-[0.7em] lg:mt-[0.5em] md:mt-[0.5em]"
              />
            </div>
          </div>
        </div>
        <div className="mr-[5em] xl:mr-[5.5em] flex flex-col justify-end items-end text-right mt-[-3em]">
          {showRateInput && (
            <>
              <label
                htmlFor="exchangeRate"
                className="xl:text-[13px] mr-[3.6em] mt-[-2em] biglg:mr-[16em] lg:mr-[15.5em] bigmd:mr-[18.2em] md:mr-[15.5em] md:mt-[4.5em] lg:mt-[4em] md:text-[14px] font-norm cursor-pointer xl:mr-[17.9em]"
                style={{ whiteSpace: "nowrap" }}
              >
                Enter Rate
              </label>
              <div className="xl:mt-[0.3em] biglg:mt-[0.2em] lg:mt-[0.3em] bigmd:mt-[0.4em] md:mt-[0.1em] mr-[-4em] md:mr-[-2.9em] bigmd:mr-[-2em] biglg:mr-[-1.8em] xl:mr-[-1.7em] lg:mr-[-1.8em]">
                <InputComponent
                  type="number"
                  value={
                    formData.exchangeRate !== 0 ? formData.exchangeRate : ""
                  }
                  name="exchangeRate"
                  placeholder="Exchange Rate"
                  handleChange={(e) =>
                    setFormData({
                      ...formData,
                      exchangeRate: parseFloat(e.target.value) || 0,
                    })
                  }
                  className="lg:w-[320px] md:w-[330px] cursor-pointer"
                />
              </div>
            </>
          )}
        </div>
      </div>

      {/* Mobile */}
      <div className="mt-[-0.5em] lg:hidden md:hidden">
        <div className="flex gap-1 mt-[1em]">
          <label
            htmlFor="Currency"
            className="flex justify-start  items-start text-left lg:text-[15px] text-[9px] font-normal ml-[2em] 375:ml-[2.3em] 414:ml-[2.7em] 390:ml-[2em] 430:ml-[2.7em] 412:ml-[2em] 360:ml-[2em]"
          >
            {t("selectCurrency")}
          </label>
        </div>
        <div
          className="w-full pr-[2em] text-[12px]"
          onClick={handleDropdownClick}
        >
          <DropdownComponent
            name="currency"
            options={dropDownCurrency}
            value={formData.currency}
            placeholder={selectedCurrencyLabel || "Select Currency"}
            selectedValue={selectedCurrencyLabel}
            onChange={(name, value) => handleDropDownChange(name, value)}
            dropdownClassName="w-full rounded ml-[1em] placeholder:text-[9px]"
            iconClassName="375:mr-[0.4em] 414:mr-[0.5em] 390:mr-[0.5em] 430:mr-[0.5em] mt-[-0.2em] mr-[0.4em]"
          />
        </div>
        <div className="flex flex-col justify-start items-start text-left 375:ml-[1em] 414:ml-[0.7em] 390:ml-[0.7em] 430:ml-[0.7em] 412:ml-[0.7em] 360:ml-[1em] mt-[0.9em]">
          {showRateInput && (
            <>
              <label
                htmlFor="exchangeRate"
                className="lg:text-[13px] text-[9px]  font-normal cursor-pointer"
              >
                Enter Rate
              </label>
              <div className="w-full pr-[1em] text-[12px] cursor-pointer">
                <InputComponent
                  type="number"
                  value={
                    formData.exchangeRate !== 0 ? formData.exchangeRate : ""
                  }
                  name="exchangeRate"
                  placeholder="Exchange Rate"
                  handleChange={(e) =>
                    setFormData({
                      ...formData,
                      exchangeRate: parseFloat(e.target.value) || 0,
                    })
                  }
                  className="w-full pr-[1em] cursor-pointer"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InvoiceCurrency;
