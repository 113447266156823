import React, { useState } from "react";
import DotIcon from "../../../../assets/icons/DotIcon";
import { TableItem } from "..";
import { useNavigate } from "react-router-dom";

interface DotMenuProps {
  data: TableItem;
  CustomerCard: boolean | undefined;
  invoiceCard: boolean | undefined;
  quoteCard: boolean | undefined;
  expenseCard: boolean | undefined;
  ServiceCard: boolean | undefined;
  inventoryCard: boolean | undefined;
}

const DotMenu: React.FC<DotMenuProps> = ({
  data,
  CustomerCard,
  invoiceCard,
  expenseCard,
  ServiceCard,
  quoteCard,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const invoiceMenuItems = [
    {
      label: "View",
      link: `/invoices/${data.invoiceId}/view`,
      id: "invoice-view",
      onClick: () => {
        navigate(`/invoices/${data.invoiceId}/view`);
      },
    },
  ];

  const quoteMenuItems = [
    {
      label: "View",
      link: `/quotes/${data.quoteNo}/view`,
      id: "quote-view",
      onClick: () => {
        navigate(`/quotes/${data.quoteNo}/view`);
      },
    },
  ];

  const expenseMenuItems = [
    {
      label: "View",
      link: `/expenses/${data.id}/view`,
      id: "invoice-view",
      onClick: () => {
        navigate(`/expenses/${data.id}/view`);
      },
    },
    // {
    //   label: 'Edit',
    //   link: `/expenses/${data.id}/edit`,
    //   id: 'invoice-edit',
    //   onClick: () => {
    //     navigate(`/expenses/${data.id}/edit`);
    //   },
    // },
  ];
  const serviceMenuItems = [
    {
      label: "View",
      //@ts-ignore
      link: `/services/${data.id?.props?.href?.split("/")[2]}/view`,
      id: "service-view",
      onClick: () => {
        //@ts-ignore
        navigate(`/services/${data.id?.props?.href?.split("/")[2]}/view`);
      },
    },
  ];

  const customerMenuItems = [
    {
      label: "View",
      link: `/customers/${data.id}/view`,
      id: "invoice-view",
      onClick: () => {
        navigate(`/customers/${data.id}/view`);
      },
    },
  ];
  const customerView = {};

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = (onClick: (() => void) | undefined) => {
    if (onClick) {
      onClick();
    }
    setIsOpen(false);
  };

  return (
    <div className="">
      <button onClick={toggleMenu} className="focus:outline-none">
        <DotIcon />
      </button>
      {isOpen && (
        <div className="bg-white dark:bg-gray-800 border dark:border-gray-700 shadow-md rounded-lg">
          <>
            {invoiceCard &&
              invoiceMenuItems.map((menuItem, index) => (
                <div
                  key={index}
                  className="px-2 py-2 font-normal cursor-pointer hover:bg-[#fffff] dark:hover:bg-[]"
                  onClick={() => handleMenuItemClick(menuItem.onClick)}
                >
                  {menuItem.label}
                </div>
              ))}
            {quoteCard &&
              quoteMenuItems.map((menuItem, index) => (
                <div
                  key={index}
                  className="px-2 py-2 font-normal cursor-pointer hover:bg-[#fffff] dark:hover:bg-[]"
                  onClick={() => handleMenuItemClick(menuItem.onClick)}
                >
                  {menuItem.label}
                </div>
              ))}
            {expenseCard &&
              expenseMenuItems.map((item, index) => (
                <div
                  key={index}
                  className="px-2 py-2 font-normal cursor-pointer hover:bg-[#fffff] dark:hover:bg-[]"
                  onClick={() => handleMenuItemClick(item.onClick)}
                >
                  {item.label}
                </div>
              ))}
            {CustomerCard &&
              customerMenuItems.map((item, index) => (
                <div
                  key={index}
                  className="px-2 py-2 font-normal cursor-pointer hover:bg-[#fffff] dark:hover:bg-[]"
                  onClick={() => handleMenuItemClick(item.onClick)}
                >
                  {item.label}
                </div>
              ))}
            {ServiceCard &&
              serviceMenuItems.map((item, index) => (
                <div
                  key={index}
                  className="px-2 py-2 font-normal cursor-pointer hover:bg-[#fffff] dark:hover:bg-[]"
                  onClick={() => handleMenuItemClick(item.onClick)}
                >
                  {item.label}
                </div>
              ))}
          </>
        </div>
      )}
    </div>
  );
};

export default DotMenu;
