import React from "react";

const ExpandUp = () => {
  return (
    <div>
      <svg
        className="ml-[-8px]"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M21 17.5L14 10.5L7 17.5" stroke="#33363F" strokeWidth="2" />
      </svg>
    </div>
  );
};

export default ExpandUp;
