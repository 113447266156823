import React, { useState, useEffect } from "react";
import DownloadingLogo from "../../../assets/icons/DownloadingLogo";

const DownloadLoader = () => {
  const [dotCount, setDotCount] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotCount((prev) => (prev === 3 ? 1 : prev + 1));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="rounded-[32px] bg-white p-6 shadow-lg w-[400px] h-[400px] flex flex-col items-center justify-center">
        <span className="flex justify-center items-center">
          <DownloadingLogo />
        </span>
        <p className="lg:text-[20px] text-[16px] font-semibold text-gray-800 mt-4">
          Invoice Downloading...
        </p>

        <div className="flex justify-center mt-4">
          {[...Array(3)].map((_, idx) => (
            <span
              key={idx}
              className={`h-6 w-5 mx-1 rounded-full animate-up-down ${
                idx < dotCount ? "bg-blue-600" : "bg-black"
              }`}
              style={{ animationDelay: `${idx * 150}ms` }}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DownloadLoader;
