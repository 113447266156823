import * as React from "react";

interface FilterIconProps {
  color?: string;
  sizeW?: string;
  sizeH?: string;
}

const FilterIcon: React.FC<FilterIconProps> = ({
  color,
  sizeW = "24",
  sizeH = "25",
}) => {
  return (
    <svg
      width={sizeW}
      height={sizeH}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 8.5L20 8.5"
        stroke={color || "currentColor"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4 16.5L14 16.5"
        stroke={color || "currentColor"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <ellipse
        cx="7"
        cy="8.5"
        rx="3"
        ry="3"
        transform="rotate(90 7 8.5)"
        stroke={color || "currentColor"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <ellipse
        cx="17"
        cy="16.5"
        rx="3"
        ry="3"
        transform="rotate(90 17 16.5)"
        stroke={color || "currentColor"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { FilterIcon };
