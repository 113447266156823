import React from "react";
import ListInventoryBody from "../../list/ListInventoryBody";
import { removeDataProps } from "../../../../@types/inventory";
import { MobileTableBody } from "../../../common/MobileTableBody";
import { Tooltip } from "../../../../assets/icons/Tooltip";
import { ReusableTable } from "../../../table";
import { ReusableTableColumn } from "./VariationTable";

interface RemovedStockTableProps {
  isLoading: boolean;
  hasVariations: boolean;
  removeData: removeDataProps[];
}
const RemovedStockTable = ({
  isLoading,
  hasVariations,
  removeData,
}: RemovedStockTableProps) => {
  const [data, setData] = React.useState<typeof removeData>([]);
  React.useEffect(() => {
    setData(removeData);
  }, [removeData]);

  const [showTooltip, setShowTooltip] = React.useState(false);

  const tableHeadVariation: ReusableTableColumn[] = [
    {
      key: "date",
      dataIndex: "date",
      title: "Date",
      label: "Date",
      className: "text-start",
    },
    {
      key: "variation",
      dataIndex: "variation",
      title: "Variation",
      label: "Variation",
    },
    {
      key: "type",
      dataIndex: "type",
      title: "Variation Type",
      label: "Variation Type",
    },
    {
      key: "quantity",
      dataIndex: "quantity",
      title: "Qty Removed",
      label: "Quantity Removed",
    },
    {
      key: "reason",
      dataIndex: "reason",
      //@ts-ignore
      title: (
        <div className="relative">
          {showTooltip && (
            <div className="absolute text-[#212121] bg-white top-[-28px] font-normal right-6 w-[167px] h-[31px] p-2 text-[12px] z-50 shadow-lg rounded-[4px]">
              Click a reason to <span className="font-semibold">see more</span>
            </div>
          )}
          <span
            onClick={() => setShowTooltip(!showTooltip)}
            className="flex items-center gap-[13px]"
          >
            Reason <Tooltip color="white" />
          </span>
        </div>
      ),
      label: "Reason",
      className: "text-start",
    },
  ];
  const tableHead: ReusableTableColumn[] = [
    {
      key: "date",
      dataIndex: "date",
      title: "Date",
      label: "Date",
      className: "text-start",
    },
    {
      key: "quantity",
      dataIndex: "quantity",
      title: "Qty Removed",
      label: "Quantity Removed",
    },
    {
      key: "reason",
      dataIndex: "reason",
      title: "Reason",
      label: "Reason",
      className: "",
    },
  ];

  return (
    <>
      <div className="hidden md:block">
        <ReusableTable
          data={data}
          isLoading={isLoading}
          columns={hasVariations ? tableHeadVariation : tableHead}
        />
      </div>

      <div className="md:hidden">
        <MobileTableBody
          payHistoryTable={false}
          data={data}
          isLoading={false}
          CustomerCard={false}
          removeStockCard={true}
        />
      </div>
    </>
  );
};
export { RemovedStockTable };
