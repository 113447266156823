import React, {
  useState,
  useEffect,
  FunctionComponent,
  CSSProperties,
  useRef,
} from "react";
import { DropdownItem } from "./DropdownItem";
import { SearchIcon } from "../../../assets/icons";

interface SearchInputProps<T> {
  jsonFileUrl?: string;
  data: Array<T>;
  displayKey: keyof T;
  onSelect?: (item: T | null) => void;
  isLoading?: boolean;
  searchedDone?: boolean;
  setSearchedDone?: (searchedDone: boolean) => void;
  placeholder?: string;
  classNames?: string;
  value?: string;
  selected?: T | null;
  handleChangeText?: (text: string) => void;
  style?: React.CSSProperties;
  image?: FunctionComponent<{ className?: string }>;
  clearIcon?: FunctionComponent<{ color?: string }>;
  emptyMessage?: string;
  error?: string;
  onClearError?: () => void;
  dropdownStyle?: React.CSSProperties;
  dropdownClassName?: string;
  clearIconClass?: string;
  emptyLinks?: Array<{
    label: string;
    onClick: () => void;
    icon?: React.ReactNode;
  }>;
  emptyLinkStyle?: CSSProperties;
  specificSelectedValue?: boolean;
  icon?: React.ReactNode;
  searchKey: keyof T | Array<keyof T>;
  dropdownWidth?: string;
  expenseDropDown?: boolean;
}

const SearchInput = <T extends { [key: string]: any }>({
  jsonFileUrl,
  data,
  displayKey,
  onSelect,
  isLoading = false,
  searchedDone,
  setSearchedDone,
  placeholder,
  classNames,
  selected,
  handleChangeText,
  style,
  clearIcon: ClearIcon,
  emptyMessage = "No Result",
  error,
  onClearError,
  emptyLinks,
  emptyLinkStyle,
  dropdownClassName,
  dropdownStyle,
  clearIconClass,
  icon,
  searchKey,
  dropdownWidth = "",
  expenseDropDown,
  value = "",
}: SearchInputProps<T>) => {
  const [searchText, setSearchText] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [jsonData, setJsonData] = useState<T[]>([]);

  useEffect(() => {
    if (jsonFileUrl) {
      fetch(jsonFileUrl)
        .then((response) => response.json())
        .then((json) => {
          setJsonData(json);
        })
        .catch((error) => {
          console.error("Error fetching JSON data:", error);
        });
    }
  }, [jsonFileUrl]);

  useEffect(() => {
    if (selected) {
      setSearchText(selected[displayKey]);
      setShowDropdown(false);
    } else {
      setSearchText("");
    }
  }, [selected, displayKey]);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  // Handle changes in the input
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setSearchText(val);
    handleChangeText && handleChangeText(val);

    if (val.trim().length > 0) {
      // Show dropdown if there is a search text
      setShowDropdown(true);
    } else {
      // Hide dropdown if text cleared
      setShowDropdown(false);
    }
    onClearError && onClearError();
    setSearchedDone && setSearchedDone(false);
  };

  const handleDropdownClick = (item: T, event: React.MouseEvent) => {
    onClearError && onClearError();
    onSelect && onSelect(item);
    setSearchText(String(item[displayKey]));
    setShowDropdown(false);
    event.stopPropagation();
  };

  const handleClearSelection = () => {
    setSearchText("");
    setShowDropdown(false);
    onSelect && onSelect(null);
    onClearError && onClearError();
  };

  const handleInputClick = () => {
    // If we want to show suggestions on focus (even if empty), we could do so here
    // For now, only show if there's already text
    if (searchText.trim().length > 0) {
      setShowDropdown(true);
    }
    onClearError && onClearError();
  };

  const getNestedPropertyValue = (obj: any, key: string | number) => {
    const keys = String(key).split(".");
    return keys.reduce((acc, curr) => (acc ? acc[curr] : undefined), obj);
  };

  const getFilteredItems = (): T[] => {
    const sourceData = jsonData.length > 0 ? jsonData : data;
    if (!searchText.trim()) return [];

    const searchTextLower = searchText.toLowerCase();
    const searchKeysArray = Array.isArray(searchKey) ? searchKey : [searchKey];

    return sourceData.filter((item) => {
      return searchKeysArray.some((key) => {
        if (typeof key === "symbol") {
          // Skip symbol keys as they can't be processed for string operations
          return false;
        }

        const itemValue = String(
          getNestedPropertyValue(item, key) || "",
        ).toLowerCase();
        return itemValue.includes(searchTextLower);
      });
    });
  };

  const filteredItems = getFilteredItems();

  const renderDropdownItems = () => {
    if (error) {
      return <div className="p-2 text-sm text-red-600">{error}</div>;
    }

    if (isLoading) {
      return <div className="p-2 text-gray-500">Loading...</div>;
    }

    // Only show 'No Result' if the user has actually typed something
    if (searchText.trim().length > 0 && filteredItems.length === 0) {
      return (
        <div className="py-4 px-2">
          <span className="text-gray-500 italic text-sm">{emptyMessage}</span>
          {emptyLinks && emptyLinks.length > 0 && (
            <ul className="mt-2">
              {emptyLinks.map(({ label, onClick, icon: linkIcon }, index) => (
                <li key={index} style={emptyLinkStyle}>
                  <a
                    href="#"
                    className="text-[#044E97] flex items-center space-x-1"
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      onClick();
                    }}
                  >
                    <span>{label}</span>
                    {linkIcon && <span>{linkIcon}</span>}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      );
    }

    // Show filtered items if available
    return (
      <ul className="max-h-60 overflow-auto">
        {filteredItems.map((item, i) => (
          <li
            key={i}
            className="hover:bg-blue-200 cursor-pointer py-2 px-2"
            onClick={(event) => handleDropdownClick(item, event)}
          >
            <DropdownItem
              item={item}
              displayKey={displayKey}
              onClick={handleDropdownClick}
              ref={dropdownRef}
            />
          </li>
        ))}
      </ul>
    );
  };

  const inputClassName = `${
    classNames ||
    "border border-[#138EFF] md:h-[48px] h-[35px] rounded-md lg:placeholder:text-[16px] placeholder:text-[14px] lg:p-2 lg:mt-[5px] lg:leading-normal"
  } focus:outline-none focus:shadow-outline appearance-none focus:ring-0 ${selected ? "pl-5 border-[#138EFF]" : ""}`;

  const inputStyle = selected ? { backgroundColor: "#E3EFFC" } : {};
  return (
    <div className="relative" style={style}>
      <span className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        {icon ? icon : <SearchIcon className="text-gray-500" />}
      </span>
      <input
        type="text"
        placeholder={placeholder}
        value={searchText}
        onChange={handleInputChange}
        className={`${icon ? "pl-[2.25em]" : ""} ${inputClassName}`}
        disabled={Boolean(selected)}
        onClick={handleInputClick}
        ref={inputRef}
        style={inputStyle}
      />
      {isLoading && (
        <div className="absolute inset-y-0 right-6 flex items-center">
          <div className="w-5 h-5 border-2 border-gray-200 border-t-[#044E97] rounded-full animate-spin"></div>
        </div>
      )}
      {selected && (
        <button
          onClick={handleClearSelection}
          className={`absolute right-0 top-0 mt-[0.6em] xl:mt-[0.8em] md:mr-[1em] ${clearIconClass}`}
        >
          {ClearIcon ? <ClearIcon color="#212121" /> : "x"}
        </button>
      )}
      {showDropdown && (
        <div
          className={`absolute z-10 bg-white rounded-sm border border-gray-300 mt-2 ${dropdownClassName} ${dropdownWidth}`}
          style={{ ...dropdownStyle }}
          ref={dropdownRef}
        >
          {renderDropdownItems()}
        </div>
      )}
    </div>
  );
};

export { SearchInput };
