import React from "react";

interface CustomerIconProps {
  color?: string;
}

const CustomerIcon: React.FC<CustomerIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.168 6.75065C14.3186 6.75065 15.2513 5.81791 15.2513 4.66732C15.2513 3.51672 14.3186 2.58398 13.168 2.58398M17.3346 13.0007C17.3346 10.6995 15.4692 8.83398 13.168 8.83398"
          stroke={color || "currentColor"}
          stroke-width="1.2"
        />
        <path
          d="M4.83462 6.75065C3.68403 6.75065 2.75129 5.81791 2.75129 4.66732C2.75129 3.51672 3.68403 2.58398 4.83462 2.58398M0.667969 13.0007C0.667969 10.6995 2.53345 8.83398 4.83464 8.83398"
          stroke={color || "currentColor"}
          stroke-width="1.2"
        />
        <path
          d="M11.9193 4.25065C11.9193 5.86148 10.6134 7.16732 9.0026 7.16732C7.39177 7.16732 6.08594 5.86148 6.08594 4.25065C6.08594 2.63982 7.39177 1.33398 9.0026 1.33398C10.6134 1.33398 11.9193 2.63982 11.9193 4.25065Z"
          stroke={color || "currentColor"}
          stroke-width="1.2"
        />
        <path
          d="M3.16797 14.668H14.8346C14.8346 11.9065 12.223 9.66797 9.0013 9.66797C5.77964 9.66797 3.16797 11.9065 3.16797 14.668Z"
          stroke={color || "currentColor"}
          stroke-width="1.2"
        />
      </svg>
    </>
  );
};
export { CustomerIcon };

export const ActiveCustomerIcon: React.FC<CustomerIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.45964 4.25065C5.45964 2.29464 7.04529 0.708984 9.0013 0.708984C10.9573 0.708984 12.543 2.29464 12.543 4.25065C12.543 6.20666 10.9573 7.79232 9.0013 7.79232C7.04529 7.79232 5.45964 6.20666 5.45964 4.25065Z"
          fill="#0D3C6D"
        />
        <path
          d="M2.54297 14.6673C2.54297 11.4738 5.52832 9.04232 9.0013 9.04232C12.4743 9.04232 15.4596 11.4738 15.4596 14.6673V15.2923H2.54297V14.6673Z"
          fill="#0D3C6D"
        />
        <path
          d="M5.02724 1.96573C4.63968 2.63835 4.41797 3.41861 4.41797 4.25065C4.41797 5.41074 4.84897 6.47017 5.55958 7.27754C5.32885 7.34148 5.08574 7.37565 4.83465 7.37565C3.33889 7.37565 2.12629 6.1631 2.12629 4.66732C2.12629 3.17154 3.33889 1.95898 4.83465 1.95898C4.8994 1.95898 4.96363 1.96126 5.02724 1.96573Z"
          fill="#0D3C6D"
        />
        <path
          d="M6.32297 8.44463C3.92746 9.26129 2.01274 11.1476 1.58894 13.6257H0.0429688V13.0007C0.0429688 10.3543 2.18827 8.20898 4.83464 8.20898C5.35421 8.20898 5.85447 8.29168 6.32297 8.44463Z"
          fill="#0D3C6D"
        />
        <path
          d="M16.4137 13.6257H17.9597V13.0007C17.9597 10.3543 15.8144 8.20898 13.168 8.20898C12.6484 8.20898 12.1482 8.29168 11.6797 8.44463C14.0752 9.26129 15.9899 11.1476 16.4137 13.6257Z"
          fill="#0D3C6D"
        />
        <path
          d="M12.4431 7.27753C12.6738 7.34148 12.9169 7.37565 13.168 7.37565C14.6638 7.37565 15.8764 6.16309 15.8764 4.66732C15.8764 3.17155 14.6638 1.95898 13.168 1.95898C13.1033 1.95898 13.039 1.96126 12.9754 1.96573C13.363 2.63835 13.5847 3.41861 13.5847 4.25065C13.5847 5.41074 13.1537 6.47017 12.4431 7.27753Z"
          fill="#0D3C6D"
        />
      </svg>
    </>
  );
};
