import { useCallback, useRef } from "react";

export const useGoogleAuth = () => {
  const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID || "";
  const domain = process.env.REACT_APP_COGNITO_DOMAIN || "";
  const redirectUri = `${window.location.origin}/`;
  const hasGoogleCallbackExecuted = useRef(false);

 

  /**
   * Initiates the Google Sign-In process by redirecting the user.
   */
  const handleGoogleSignIn = useCallback(() => {
    console.log(redirectUri);
    const googleSignInUrl = `https://${domain}/oauth2/authorize?identity_provider=Google&redirect_uri=${encodeURIComponent(
      redirectUri,
    )}&response_type=CODE&client_id=${clientId}&scope=email%20openid%20profile`;
    window.location.assign(googleSignInUrl);
  }, [clientId, domain, redirectUri]);

  /**
   * Exchanges the authorization code for tokens.
   * @param code - The authorization code received from Google.
   * @returns The token response.
   */
  const handleCodeExchange = useCallback(
    async (code: string) => {

      if (hasGoogleCallbackExecuted.current) {
        return;
      }
    
      hasGoogleCallbackExecuted.current = true; 
      try {
        const bodyParams = new URLSearchParams();
        bodyParams.append("grant_type", "authorization_code");
        bodyParams.append("client_id", clientId);
        bodyParams.append("redirect_uri", redirectUri);
        bodyParams.append("code", code);

        const response = await fetch(`https://${domain}/oauth2/token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: bodyParams.toString(),
        });
        if (!response?.ok) {
          throw new Error("Failed to exchange code for tokens");
        }

        return await response.json();
      } catch (error) {
        throw error;
      }
    },
    [clientId, domain, redirectUri],
  );

  return {
    handleGoogleSignIn,
    handleCodeExchange,
  };
};

export default useGoogleAuth;
