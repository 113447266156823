import React, { useState } from "react";
import { Variation } from "../../../@types/inventory";
import { VariationTable } from "./VariationTable";

interface VariationLine {
  variation: Variation;
}

const VariationLine = ({ variation }: any) => {
  const [variations, setVariations] = useState<Variation[]>([]);

  const handleAddVariation = (newVariation: any) => {
    setVariations([...variations, newVariation]);
  };

  const handleRemoveVariation = (id: any) => {
    const updatedVariations = variations.filter(
      (variation) => variation.id !== id,
    );
    setVariations(updatedVariations);
  };

  const handleUpdateVariation = (id: any, field: any, value: any) => {
    const updatedVariations = variations.map((variation) => {
      if (variation.id === id) {
        return { ...variation, [field]: value };
      }
      return variation;
    });
    setVariations(updatedVariations);
  };
  return (
    <div>
      {/* <VariationTable
        onAddVariation={handleAddVariation}
        // variations={variations}
        onRemoveVariation={handleRemoveVariation}
        onUpdateVariation={handleUpdateVariation}
      /> */}
    </div>
  );
};
export { VariationLine };
