import React from "react";

const EditUserIcon = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_50474_127766)">
          <path
            d="M11.7294 3.2382C12.3504 2.56539 12.6609 2.22899 12.9908 2.03276C13.7869 1.55929 14.7672 1.54457 15.5766 1.99393C15.912 2.18016 16.2321 2.50709 16.8722 3.16096C17.5122 3.81483 17.8323 4.14176 18.0146 4.48443C18.4545 5.31126 18.4401 6.31265 17.9766 7.12591C17.7845 7.46296 17.4552 7.78014 16.7966 8.4145L8.96016 15.9622C7.71203 17.1644 7.08797 17.7655 6.30802 18.0701C5.52807 18.3747 4.67064 18.3523 2.95577 18.3075L2.72245 18.3014C2.20039 18.2877 1.93936 18.2809 1.78762 18.1087C1.63589 17.9365 1.6566 17.6706 1.69803 17.1388L1.72053 16.85C1.83714 15.3533 1.89545 14.6049 2.18772 13.9322C2.48 13.2594 2.98417 12.7132 3.99249 11.6207L11.7294 3.2382Z"
            stroke="#344054"
            stroke-width="1.5"
            stroke-linejoin="round"
          />
          <path
            d="M10.8359 3.33398L16.6693 9.16732"
            stroke="#344054"
            stroke-width="1.5"
            stroke-linejoin="round"
          />
          <path
            d="M11.668 18.334L18.3346 18.334"
            stroke="#344054"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_50474_127766">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default EditUserIcon;
