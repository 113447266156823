import React, { useState } from "react";
import { ICreateExpenseData } from "../../../@types";
import { Tooltip } from "../../../assets/icons/Tooltip";
import { CheckboxComponent } from "../../core/Forms";

interface ProtectedProps {
  validationResults: ICreateExpenseData;
  formData: ICreateExpenseData;
  handleIsProtectedChange: () => void;
  isProtected: boolean;
  toolTipText?: string;
}

const ProtectedExpense: React.FC<ProtectedProps> = ({
  handleIsProtectedChange,
  isProtected,
  toolTipText,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="flex justify-between gap-[6em] bg-[#F3FBFB] px-[1em] py-[0.7em]">
      <div className="flex justify-start gap-3">
        <label
          htmlFor="reoccurring"
          className="font-semibold block text-[14px] lg:text-[16px] lg:ml-0 lg:mt-[0.5em]"
        >
          Mark As Protected Expense
        </label>

        {toolTipText && (
          <div
            className="relative lg:mt-[0.8em]"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Tooltip />
            {isHovered && (
              <div className="absolute w-[204px] left-[-5.7em] z-10">
                <div className="flex items-center justify-center">
                  <svg
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#FFF4C6" />
                  </svg>
                </div>
                <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                  {toolTipText}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <span className="inline-flex gap-[8.5px]">
        <div className="mt-[0.5em] ml-[]">
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={isProtected}
              className="sr-only peer"
              onChange={handleIsProtectedChange}
              name="isProtected"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
          </label>
        </div>
      </span>
    </div>
  );
};

export default ProtectedExpense;
