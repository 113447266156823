import { CognitoUserPool } from "amazon-cognito-identity-js";
import config from "../config";

const poolData = {
  UserPoolId: config.CognitoUserPoolId as string,
  ClientId: config.CognitoClientId as string,
};


export default new CognitoUserPool(poolData);
