export const validateVendorForm = ({ name }: { name: string }) => {
  const errors = {
    name: "",
  };
  if (!name) {
    errors.name = "Name is required";
  }

  // Check if any error messages are present
  const hasErrors = Object.values(errors).some((error) => error !== "");

  return { hasErrors, errors };
};
