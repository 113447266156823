import * as React from "react";
import { Accordion as ACC } from "flowbite-react";
import { AccordionArrow } from "../../../assets/icons";

type ItemType = {
  title: string;
  content: React.ReactNode;
};
interface AccordionArrowProps {
  direction: "up" | "down";
}

interface AccordionProps {
  items: ItemType[];
  type?: "collapseAll";
  arrowIcon?: React.FC<AccordionArrowProps> | undefined;
}

const Accordion: React.FC<AccordionProps> = ({
  items = [],
  type = "collapseAll",
  arrowIcon: AccordionArrow,
}) => {
  return (
    <ACC
      alwaysOpen={type === "collapseAll"}
      arrowIcon={AccordionArrow as React.FC<React.SVGProps<SVGSVGElement>>}
      style={{
        border: "none",
        width: "100px",
      }}
    >
      {items.map((item, i) => (
        <ACC.Panel key={i}>
          <ACC.Title
            style={{
              background: "none",
              border: "none",
              height: 40,
              boxShadow: "none",
            }}
          >
            {item.title}
          </ACC.Title>
          <ACC.Content
            style={{
              border: "none",
            }}
          >
            {item.content}
          </ACC.Content>
        </ACC.Panel>
      ))}
    </ACC>
  );
};

export { Accordion };
