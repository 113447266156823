import React from "react";
const RestockIcon = () => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.0013 5.66797V12.3346M12.3346 9.0013H5.66797"
          stroke="#141B34"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle
          cx="9.0013"
          cy="9.0013"
          r="8.33333"
          stroke="#141B34"
          stroke-width="1.2"
        />
      </svg>
    </>
  );
};
export default RestockIcon;
