import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CloseIconBig } from "../../../../assets/icons";
import DangerIcon from "../../../../assets/icons/danger";
import { useUserStore } from "../../../../state";
import { BankData, Customer } from "../../../../types";
import { RCVerificationModal } from "./RCVerificationModal";
import { useTranslation } from "react-i18next";

interface GeneratePaymentPromptProps {
  onFailed: (event: any) => void;
  onClose?: () => void;
  showFailedRCVerificationPrompt: boolean;
  invoiceId: string | undefined;
  onValidateSuccess: (validatedData: BankData) => void;
  showVerificationPrompt: (show: boolean) => void;
}
const FailedRCVerifiicationModal: React.FC<GeneratePaymentPromptProps> = ({
  onFailed,
  onClose,
  showFailedRCVerificationPrompt,
  invoiceId,
  showVerificationPrompt,
}) => {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  if (!user) {
    return null;
  }
  const [showInputRC, setShowInputRC] = useState(false);

  const handleValidateSuccess = () => {
    setShowInputRC(false);
    showVerificationPrompt(true);
  };
  const { t } = useTranslation("invoices");
  return (
    <>
      <div className="p-[32px] bg-[#ffffff] rounded-[16px] xl:max-h-[748px] 2xl:min-h-[748px] biglg:max-h-[648px] lg:max-h-[500px] w-[704px] xl:w-[440px] 2xl:w-[440px] biglg:w-[440px] lg:w-[440px] overflow-y-auto">
        <div className="flex flex-col justify-center items-center text-center">
          <DangerIcon />
          <h1 className="xl:text-[25px] biglg:text-[25px] 2xl:text-[25px] lg:text-[20px] text-[14px] md:text-[18px] font-semibold mt-[0.6em]">
            Oops!
          </h1>

          <p className="hidden lg:block md:block text-center mt-[1em] lg:text-[14px] text-[14px] md:text-[18px]">
            {" "}
            {t("paymentlink10")} <br className="hidden lg:block" />{" "}
            {t("paymentlink15")}
          </p>
          <p className="lg:hidden md:hidden text-center mt-[1em] lg:text-[14px] text-[14px] md:text-[18px]">
            {" "}
            {t("paymentlink16")}
          </p>
        </div>
        <div className="flex justify-center gap-8 mt-[1em] text-[10px] md:text-[14px]">
          <button
            className="border border-[#044E97] text-[#044E97] bg-[white] lg:text-[16px] py-2 px-[3em] rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-[#044E97] text-white lg:text-[16px] py-2 px-[1em] rounded-md"
            onClick={() => setShowInputRC(true)}
          >
            Update RC Number
          </button>
        </div>
      </div>

      {showInputRC && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-opacity-80 flex items-center justify-center z-10 ">
          <div className="bg-white py-3  pl-2 pr-0 rounded-lg shadow-lg">
            <RCVerificationModal
              setShowSideModal={setShowInputRC}
              onGenerate={function (event: any): void {
                throw new Error("Function not implemented.");
              }}
              showVerificationPrompt={true}
              onClose={onClose}
              onVerifySuccess={handleValidateSuccess}
            />
          </div>
        </div>
      )}
    </>
  );
};

export { FailedRCVerifiicationModal };
