import React from "react";
import { useNavigate } from "react-router-dom";
import { NotificationEntity } from "../../../../@types";

interface SuccessNotificationProps {
  notification: NotificationEntity;
  closeDrawer: () => void;
}

const SuccessNotification: React.FC<SuccessNotificationProps> = ({
  notification,
  closeDrawer,
}) => {
  const navigate = useNavigate();

  const generateLink = (entityType: string) => {
    switch (entityType) {
      case "expense":
        return "/expenses";
      case "invoice":
        return "/invoices";
      default:
        return "/";
    }
  };

  const handleNavigation = () => {
    const link = generateLink(notification.entityType);
    navigate(link);
    closeDrawer();
  };

  return (
    <div className="flex flex-row bg-white rounded-md">
      <div
        className={notification.isRead ? "bg-blue-900 w-2" : "bg-green-600 w-2"}
      ></div>
      <div className="w-[439px] h-116 bg-blue-100 p-4 flex flex-col gap-5 ">
        <p className="font-semibold ">{notification.text}</p>
        <div className="flex justify-between">
          <p className="font-semibold text-md">{notification.type}</p>
          <p className="font-light text-sm">{notification.entityType}</p>
        </div>
        <div>
          <p
            className="text-[blue] underline cursor-pointer"
            onClick={handleNavigation}
          >
            View {notification.entityType}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuccessNotification;
