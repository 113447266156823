import React from "react";
import { useNavigate } from "react-router-dom";

interface SuccessNotificationProps {
  onClose?: () => void;
  showSuccessModal: boolean;
  icon: any;
}
const SuccessNotification: React.FC<SuccessNotificationProps> = ({
  icon,
  onClose,
}) => {
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);

  const handleGoHome = () => {
    setShowSuccessModal(false);
    onClose && onClose();
    navigate("/dashboard");
  };

  const handleRepeatForm = () => {
    setShowSuccessModal(false);
    onClose && onClose();
  };

  return (
    <>
      <div className="h-screen">
        <div className="text-center text-[25px] font-bold ">
          <h1>Thank you</h1>
        </div>
        <div className="  flex justify-center mt-[2em] text-[20px] text-center ">
          <h3 className="mt-[-2em]">
            Thank you for sending in your <br /> report, Its very helpful to us
          </h3>
        </div>
        <div className="flex justify-center mt-[2em] "> {icon}</div>
        <div role="form" onSubmit={(e) => e.preventDefault()}>
          <div className="flex ml-[15px] pt-[79px] pb-[40px]">
            <button
              className="w-[215px] h-[48px] rounded-md text-[#FFFFFF] text-[16px] font-medium bg-[#FF6702]"
              onClick={handleGoHome}
            >
              Go Back Home
            </button>
          </div>
          <div className="flex ml-[15px] pb-[40px]">
            <button
              className="w-[215px] h-[48px] rounded-md text-[#044E97] border border-[#044E97] text-[16px] font-medium bg-[#FFFFFF]"
              onClick={handleRepeatForm}
            >
              Send a new Report
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessNotification;
