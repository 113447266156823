import React from "react";

interface EditIconProps {
  size?: string;
  color?: string;
}

const EditIcon: React.FC<EditIconProps> = ({
  size = "24",
  color = "#044E97",
}) => {
  return (
    <div>
      <svg
        width={size}
        height={size}
        viewBox="0 0 23 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.5737 2.88545C14.3189 2.07808 14.6915 1.6744 15.0874 1.43893C16.0427 0.870759 17.2191 0.853091 18.1904 1.39232C18.5929 1.6158 18.9769 2.00812 19.745 2.79276C20.5131 3.5774 20.8972 3.96972 21.1159 4.38093C21.6438 5.37312 21.6265 6.57479 21.0703 7.5507C20.8398 7.95516 20.4446 8.33578 19.6543 9.09701L10.2506 18.1543C8.75288 19.5969 8.004 20.3182 7.06806 20.6837C6.13212 21.0493 5.1032 21.0224 3.04536 20.9686L2.76538 20.9613C2.13891 20.9449 1.82567 20.9367 1.64359 20.73C1.4615 20.5234 1.48636 20.2043 1.53608 19.5662L1.56308 19.2197C1.70301 17.4235 1.77297 16.5255 2.12371 15.7182C2.47444 14.9109 3.07944 14.2555 4.28943 12.9445L13.5737 2.88545Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 3L19.5 10"
          stroke={color}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 21L21.5 21"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default EditIcon;
