import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ExpandLeft from "../../assets/icons/ExpandLeft";
import EditIcon from "../../assets/icons/EditIcon";
import { GroupButton, SidebarDrawer } from "../../components/common";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import {
  AddSquareIcon,
  CloseIcon,
  NariaIcon,
  SubtractSquareIcon,
  WidgetIcon,
} from "../../assets/icons";
import {
  InfoCard,
  InfoCardProps,
} from "../../components/core/InfoCard/InfoCard";
import AddSquare from "../../assets/icons/AddSquare";
import { getInventory } from "../../backend-services";
import PageLoader from "../../components/common/PageLoader";
import { toast } from "react-hot-toast";
import { RestockHistory, Variation } from "../../@types/inventory";
import BinIcon from "../../assets/icons/BinIcon";
import TruncatedText from "../../components/TruncatedText";
import { InventoryModal } from "../../components/inventory/InventoryModal";
import { createPortal } from "react-dom";
import Restock from "../../components/inventory/restock/Restock";
import EditInventory from "../../components/inventory/edit/EditInventory";
import VariantDeletePrompt from "../../components/inventory/view/VariantDeletePrompt";
import InventoryDeletePrompt from "../../components/inventory/InventoryDeletePrompt";
import { formattedDate } from "../../utils/formatDate";
import Naira from "../../assets/icons/Naira";
import { formatMoney, shortenText } from "../../utils";
import { ArchivePrompt } from "../../components/inventory/archive/ArchivePrompt";
import { ViewInventoryTable } from "../../components/inventory/view/ViewInventoryTable";
import { useUserStore } from "../../state";
import { Annotation } from "../../@types/annotation";
import useAnnotations from "../../hooks/annotation/useAnnotations";
import { isAdmin } from "../../utils/isAdmin";
import { isNotAccountant } from "../../utils/isNotAccountant";
import { useTranslation } from "react-i18next";
import { SubscriptionModal } from "../../components/common/Subscription/SubscriptionModal";
import InventoryHeader from "../../components/inventory/viewInventoryHeader";
import InventoryActionButtons from "../../components/inventory/inventoryActionButtons";
import InventoryDetails from "../../components/inventory/viewIventoryDetails";
import InventoryInfoCards from "../../components/inventory/viewInventoryInfo";
import { StockRemoval } from "../../components/inventory/view/StockRemoval";
import useViewport from "../../hooks/auth/useViewPort";
import { DotMenu } from "../../assets/icons/DotMenu";
import RestockIcon from "../../assets/icons/restock";
import RemoooveIcon from "../../assets/icons/RemooveIcon";
import { ArchivedIcon } from "../../assets/icons/ArchivedIcon";

const ViewInventoryPage: React.FC = () => {
  // const { inventoryId } = useParams();
  const { inventoryId } = useParams() || {};
  if (!inventoryId) {
    toast.error("Inventory ID is missing");
    return <div>Inventory not found</div>;
  }
  const navigate = useNavigate();
  const { roles } = useUserStore();
  const [isLoading, setIsLoading] = React.useState(false);

  function getPriceRange(variations: any[]) {
    const prices = variations
      .map((variation) => variation.price)
      .filter((price) => price != null);
    if (prices.length === 0) return "--";
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    return minPrice === maxPrice
      ? formatMoney(minPrice)
      : `${formatMoney(minPrice)} - ${formatMoney(maxPrice)}`;
  }

  React.useEffect(() => {
    if (!inventoryId) throw new Error("Inventory Id is missing");
    getInventoryDetail(inventoryId);
  }, [inventoryId]);

  const getInventoryDetail = async (id: string) => {
    setIsLoading(true);
    try {
      const res = await getInventory(id);
      console.log(res);
      setVariations(res.variations);
      const inventoryDetails = {
        unitprice: res.rate,
        quantity: res.quantity,
        desc: res.description,
        name: res.name,
        date: res.createdAt,
        rate: res.rate,
        variations: res.variations,
      };
      setStats({
        ...stats,
        totalPurchase: res.totalPurchase ? res.totalPurchase : 0,
        totalCostPrice: res.totalCostPrice,
        totalSellingPrice: res.totalSellingPrice,
      });
      setRestock(res.restockHistory);
      setRemovedStock(res.removeStockHistory);
      setDetails(inventoryDetails);
      setIsArchived(res?.isArchived);
      if (res?.metadata) setAnnotations(res?.metadata?.annotations);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to fetch variations");
    }
    setIsLoading(false);
  };

  const [variations, setVariations] = React.useState([]);
  const [details, setDetails] = React.useState<
    | {
        unitprice: number;
        quantity: number;
        rate: number;
        desc: string;
        name: string;
        date: string;
        variations: any;
      }
    | undefined
  >();
  const [openModal, setOpenModal] = React.useState(false);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [showDeleteVariant, setShowDeleteVariant] = React.useState(false);
  const [showArchive, setShowArchive] = React.useState(false);
  const [variationId, setVariationId] = React.useState("");
  const [showDeleteInventory, setShowDeleteInventory] = React.useState(false);
  const [showStockRemoval, setShowStockRemoval] = React.useState(false);
  const [restock, setRestock] = React.useState([]);
  const [removedStock, setRemovedStock] = React.useState([]);
  const [isArchived, setIsArchived] = React.useState(false);
  const { t } = useTranslation("inventories");
  const [stats, setStats] = React.useState({
    totalCostPrice: 0,
    totalSellingPrice: 0,
    totalPurchase: 0,
  });

  const [annotations, setAnnotations] = React.useState<Annotation[]>([]);
  const [openDotmenu, setOpenDotmenu] = React.useState(false);
  const dotMenuRef = React.useRef<HTMLDivElement>(null);

  // Handle outside click
  React.useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dotMenuRef.current &&
        !dotMenuRef.current.contains(event.target as Node)
      ) {
        setOpenDotmenu(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleModal = () => navigate(`/inventories/${inventoryId}/restock`);

  const annotationsData = useAnnotations(annotations, "Inventory");
  const handleDeleteVariant = (variationId: string | undefined) => {
    setShowDeleteVariant(true);
    setVariationId(`${inventoryId}/${variationId}`);
  };

  const renderVariationData = (variations: Variation[]) => {
    return variations.map((variation: Variation, index) => {
      return {
        index: index + 1,
        name: variation.name,
        type: variation.type,
        price: formatMoney(variation.price),
        quantity: variation.quantity ? variation.quantity : 0,
        bin: (
          <div onClick={() => handleDeleteVariant(variation.id)}>
            <p className="text-[red]">Delete</p>
          </div>
        ),
      };
    });
  };
  const renderRestockData = (restock: RestockHistory[]) => {
    return restock?.map((restock: RestockHistory, index) => {
      return {
        index: index + 1,
        name: details?.name || "",
        quantityRestocked: parseInt(restock.quantityRestocked, 10) || 0,
        variation: restock.variations ? restock.variations : 0,
        date: formattedDate(restock.date),
        oldSellingPrice: restock.oldSellingPrice
          ? formatMoney(restock.oldSellingPrice)
          : details?.rate,
        newSellingPrice: restock.newSellingPrice
          ? formatMoney(restock.newSellingPrice)
          : details?.rate,
      };
    });
  };

  const renderRemovedStockData = (removedStock: RestockHistory[]) => {
    return removedStock?.map((item: any, index) => {
      return {
        date: formattedDate(item.date),
        variation: item.variationName,
        type: item.variationType,
        quantity: item.quantity,
        reason1: item.reason,
        reason: shortenText(item.reason, 12),
      };
    });
  };

  const modalState = variations && variations.length > 0 ? true : false;

  const viewportWidth = useViewport();
  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          {viewportWidth.isDesktop && (
            <div className="md:mx-[14px] lg:mx-0 mb-10 pr-[1em]">
              <InventoryHeader annotations={annotations} />
              <div className="flex mb-8 items-center justify-between">
                <div className="hidden md:flex !mt-0 md:items-start">
                  <p className="font-semibold text-[20px]">{details?.name}</p>
                </div>
                {isNotAccountant(roles) && (
                  <InventoryActionButtons
                    onEdit={() => setShowPrompt(true)}
                    onArchive={() => setShowArchive(true)}
                    onRemoveStock={() => setShowStockRemoval(true)}
                    onDelete={
                      isAdmin(roles)
                        ? () => setShowDeleteInventory(true)
                        : undefined
                    }
                    onRestock={handleModal}
                  />
                )}
              </div>

              <InventoryDetails
                details={details}
                getPriceRange={getPriceRange}
                stats={stats}
              />
              <InventoryInfoCards stats={stats} isLoading={isLoading} />
            </div>
          )}
          {!viewportWidth.isDesktop && (
            <div>
              <InventoryHeader annotations={annotations} />
              <InventoryInfoCards stats={stats} isLoading={isLoading} />
              <div className="bg-white  md:bg-transparent md:p-0">
                <div className=" md:items-center md:justify-between lg:mt-[64px] border border-gray-200  rounded-md px-4 pt-2 mt-[1em] lg:hidden md:hidden">
                  <div className="flex mt-0 md:items-start justify-between border-b-gray-100">
                    <p className="font-semibold text-[20px]">{details?.name}</p>
                    {/* <div className="relative" onClick={() => setOpenDotmenu(!openDotmenu)}> */}
                    <div className="relative" ref={dotMenuRef}>
                      <div onClick={() => setOpenDotmenu((prev) => !prev)}>
                        <DotMenu />
                        {openDotmenu && (
                          <div className="absolute shadow-md top-[2em] rounded-[4px] right-[-0.2em] w-[169px] bg-white p-[12px] space-y-[24px] font-medium text-[16px] text-[#212121]  border border-gray-200 ">
                            <div
                              className=" flex gap-4"
                              onClick={() => setShowPrompt(true)}
                            >
                              <EditIcon color="#344054" size="18" />
                              <p>Edit</p>
                            </div>
                            <div className=" flex gap-4" onClick={handleModal}>
                              <RestockIcon />
                              <p className="mt-[-0.2em]">Restock</p>
                            </div>
                            <div
                              className=" flex gap-4"
                              onClick={() => setShowArchive(true)}
                            >
                              <ArchivedIcon size="18" />
                              <p>{t("archive")}</p>
                            </div>
                            <div
                              className=" flex gap-4"
                              onClick={() => setShowStockRemoval(true)}
                            >
                              <RemoooveIcon size="16" />
                              <p className="mt-[-0.2em]">Remove Stock</p>
                            </div>
                            {isAdmin(roles) && (
                              <div
                                className=" flex gap-4"
                                onClick={() => setShowDeleteInventory(true)}
                              >
                                <DeleteIcon size="22" />
                                <p className="text-[red]">Delete</p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr className="w-[110%] mx-auto mt-2 ml-[-1em]" />
                  <div className="flex items-center mt-[12px] md:!mt-0 justify-between md:flex-col md:items-start">
                    <h3 className="text-[14px] text-[#667085] font-medium">
                      {" "}
                      Quantity Sold
                    </h3>
                    <p className="font-semibold">{stats?.totalPurchase}</p>
                  </div>
                  <div className="flex items-center mt-[12px] md:!mt-0 justify-between md:flex-col md:items-start">
                    <h3 className="text-[14px] text-[#667085] font-medium">
                      {" "}
                      {t("inventory.quantityLeft")}
                    </h3>
                    <p className="font-semibold">{details?.quantity}</p>
                  </div>

                  <div className="flex items-center mt-[12px] md:!mt-0 justify-between md:flex-col md:items-start">
                    <h3 className="text-[14px] text-[#667085] font-medium">
                      {t("inventory.unitPrice")}
                    </h3>
                    <p className="font-semibold">
                      {details?.variations?.length > 0
                        ? `${getPriceRange(details?.variations)}`
                        : details?.unitprice
                          ? formatMoney(details.unitprice)
                          : "--"}
                    </p>
                  </div>
                  <div className="flex items-center mb-4 justify-between md:flex-col md:items-start mt-[12px] md:!mt-0">
                    <h3 className="text-[14px] text-[#667085] font-medium">
                      {" "}
                      {t("inventory.dateAdded")}
                    </h3>
                    <p className="font-semibold">
                      {formattedDate(details?.date)}
                    </p>
                  </div>
                  {/* <div className="flex items-center mt-[12px] md:!mt-0 justify-between md:flex-col md:items-start">
                    <h3 className="text-[14px] text-[#667085] font-medium">{details?.desc ? 'Description' : ''}</h3>
                    <p className="font-semibold md:!ml-0">
                      {details?.desc ? <TruncatedText text={details?.desc} maxLength={20} /> : ''}
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          )}

          <ViewInventoryTable
            variationData={renderVariationData(variations)}
            isLoading={isLoading}
            restockData={renderRestockData(restock)}
            removedStockData={renderRemovedStockData(removedStock)}
            isArchived={isArchived}
          />
        </>
      )}

      {showDeleteInventory && (
        <div>
          <SubscriptionModal>
            <InventoryDeletePrompt
              setShowPrompt={setShowDeleteInventory}
              inventoryId={inventoryId}
            />
          </SubscriptionModal>
        </div>
      )}

      {openModal &&
        createPortal(
          <InventoryModal
            modalSize={modalState}
            children={
              <Restock
                restockId={inventoryId}
                setOpenModal={setOpenModal}
                fetchDetail={getInventoryDetail}
              />
            }
            setOpenModal={setOpenModal}
          />,
          document.body,
        )}

      {showStockRemoval && (
        <SubscriptionModal>
          <StockRemoval
            id={inventoryId}
            fetchDetail={getInventoryDetail}
            variations={variations}
            onClose={() => setShowStockRemoval(false)}
            setShowStockRemoval={setShowStockRemoval}
          />
        </SubscriptionModal>
      )}

      {showPrompt && (
        <SubscriptionModal>
          <EditInventory
            fetchDetail={getInventoryDetail}
            onClose={() => setShowPrompt(false)}
            setShowPrompt={setShowPrompt}
            id={inventoryId}
            details={details}
          />
        </SubscriptionModal>
      )}

      {showDeleteVariant && (
        <SubscriptionModal>
          <VariantDeletePrompt
            id={inventoryId}
            fetchDetail={getInventoryDetail}
            variationId={variationId}
            setShowPrompt={setShowDeleteVariant}
          />
        </SubscriptionModal>
      )}

      {showArchive && (
        <SubscriptionModal>
          <ArchivePrompt
            id={inventoryId}
            setShowPrompt={setShowArchive}
            isArchived={isArchived}
            fetchDetail={getInventoryDetail}
          />
        </SubscriptionModal>
      )}
    </>
  );
};

export default ViewInventoryPage;
