export * from "./invoice";
export * from "./auth";
export * from "./payment";
export * from "./expense";
export * from "./user";

export const FILTER_LIST = [
  { value: "Recent", label: "Recent" },
  { value: "Last Week", label: "Last week" },
  { value: "Last Month", label: "Last month" },
  { value: "Last Quarter", label: "Last Quarter" },
];

export const REPORT_LIST = [
  { value: "Invoice-Report", label: "Invoice-Report" },
  { value: "Expense-Report", label: "Expense-Report" },
  { value: "Customer-Report", label: "Customer-Report" },
  { value: "Inventory-Report", label: "Inventory-Report" },
  { value: "ProfitLoss-Report", label: "ProfitLoss-Report" },
];

export const CUSTOMER_LIST = [
  { value: "INV001", label: "INV001" },
  { value: "INV002", label: "INV002" },
  { value: "INV003", label: "INV003" },
];

export const ROLE_ARRAY = [
  { value: "Admin", label: "Admin" },
  { value: "Operator", label: "Operator" },
  { value: "Accountant", label: "Accountant" },
];

export const BUSINESS_TYPE_RETAIL = "retail";
