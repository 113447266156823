import React from "react";

const InventoryBarIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="17"
        viewBox="0 0 24 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0.0742188V0.584857V4.66996H1.02128V1.0955H4.59574V0.0742188H0.510638H0ZM19.4043 0.0742188V1.0955H22.9787V4.66996H24V0.584857V0.0742188H23.4894H19.4043ZM2.04255 2.11677V14.8827H3.57447V2.11677H2.04255ZM4.08511 2.11677V14.8827H4.59574V2.11677H4.08511ZM5.3617 2.11677V14.8827H7.91489V2.11677H5.3617ZM8.93617 2.11677V14.8827H9.44681V2.11677H8.93617ZM9.95745 2.11677V14.8827H10.4681V2.11677H9.95745ZM10.9787 2.11677V14.8827H11.4894V2.11677H10.9787ZM12 2.11677V14.8827H14.0426V2.11677H12ZM14.5532 2.11677V14.8827H16.0851V2.11677H14.5532ZM17.1064 2.11677V14.8827H17.617V2.11677H17.1064ZM18.1277 2.11677V14.8827H18.6383V2.11677H18.1277ZM19.4043 2.11677V14.8827H20.4255V2.11677H19.4043ZM20.9362 2.11677V14.8827H21.9574V2.11677H20.9362ZM0 12.3295V16.4146V16.9253H0.510638H4.59574V15.904H1.02128V12.3295H0ZM22.9787 12.3295V15.904H19.4043V16.9253H23.4894H24V16.4146V12.3295H22.9787Z"
          fill="#044E97"
        />
      </svg>
    </div>
  );
};

export { InventoryBarIcon };
