// thermalPrinterUtils.ts

export interface Printer {
  name: string;
  device: BluetoothDevice | USBDevice;
  [key: string]: any;
}

export const checkApiSupport = (): boolean => {
  return "bluetooth" in navigator || "usb" in navigator;
};

export const detectBluetoothPrinters = async (): Promise<
  BluetoothDevice | undefined | null
> => {
  try {
    const bluetoothDevice = await navigator.bluetooth?.requestDevice({
      acceptAllDevices: true,
    });
    return bluetoothDevice?.name.includes("Printer") ? bluetoothDevice : null;
  } catch (error) {
    console.error("Bluetooth error:", error);
    return null;
  }
};

export const detectUSBPrinters = async (): Promise<USBDevice | null> => {
  try {
    const usbDevice = await navigator.usb.requestDevice({
      filters: [{ classCode: 7, subclassCode: 1 }],
    });
    return usbDevice?.productName?.includes("Printer") ? usbDevice : null;
  } catch (error) {
    console.error("USB error:", error);
    return null;
  }
};
