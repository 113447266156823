import axios, { AxiosInstance } from "axios";
import config from "../config";
import { setupAuthInterceptor } from "../utils";

export const createNotificationService = (): AxiosInstance => {
  const notificationService = axios.create({
    baseURL: config.notificationServiceURL,
  });

  setupAuthInterceptor(notificationService);

  return notificationService;
};

const notificationService = createNotificationService();

export { notificationService };
