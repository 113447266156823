import * as React from "react";

interface SearchIconProps {
  color?: string;
  className?: string;
}

const SearchIcon: React.FC<SearchIconProps> = ({ color, className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="11.1538"
        cy="11.5947"
        rx="7.0979"
        ry="7.37838"
        stroke="#A4A4A4"
        strokeWidth="2"
      />
      <path
        d="M20.2798 21.0811L17.2378 17.9189"
        stroke="#A4A4A4"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { SearchIcon };
