import React from "react";

const PreviewDownloadIcon = () => {
  return (
    <div>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.9405 8.90039C20.5405 9.21039 22.0105 11.0604 22.0105 15.1104V15.2404C22.0105 19.7104 20.2205 21.5004 15.7505 21.5004H9.24047C4.77047 21.5004 2.98047 19.7104 2.98047 15.2404V15.1104C2.98047 11.0904 4.43047 9.24039 7.97047 8.91039"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.5 2V14.88"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.8484 12.6504L12.4984 16.0004L9.14844 12.6504"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default PreviewDownloadIcon;
