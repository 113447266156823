import toast from "react-hot-toast";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  InventoryItem,
  VariationError,
  VariationItem,
} from "../../../../@types/inventory";
import { useInventoryStore } from "../../../../state/useInventoryStore";
import { convertToFloat } from "../../../../utils/convertToFloat";
import {
  validateCreateInventory,
  ValidateVariation,
} from "../../../../modules/inventory/inventory";
import { InventoryForm } from "../../../inventory/Create";
import { SidebarDrawer } from "../../../common";
import VariationList from "../../../inventory/Variations/VariationList";
import VariationForm from "../../../inventory/Variations/VariationForm";
import { IInventory, ItemInput } from "../../../../@types";
import { createInventory } from "../../../../backend-services";
import { CloseIcon } from "../../../../assets/icons";

interface InventoryPageProps {
  showTitle?: boolean;
  showSidebar?: boolean;
  showVariationList?: boolean;
  showCreateForm?: boolean;
  showForms?: boolean;
  onCreateSuccess: (item: ItemInput) => void;
  setShowCreateInventory: (show: boolean) => void;
  handleCreateInventory?: (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  onclose?: () => void;
}

const InventoryOverlay: React.FC<InventoryPageProps> = ({
  showSidebar = true,
  showVariationList = true,
  showCreateForm: initialShowCreateForm = false,
  showTitle = true,
  showForms = true,
  setShowCreateInventory,
  onCreateSuccess,
  onclose,
}) => {
  const navigate = useNavigate();
  const [error, setError] = useState({
    name: "",
    rate: "",
    costPrice: "",
    quantity: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [variationList, setVariationList] = useState<VariationItem[]>([]);
  const [inventories, setInventories] = useState<InventoryItem>({
    name: "",
    rate: "",
    costPrice: "",
    quantity: "",
    description: "",
    variations: [],
  });
  const [variationError, setVariationError] = useState<VariationError[]>([]);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [showCreateForm, setShowCreateForm] = useState(initialShowCreateForm);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [variationState, setVariationState] = useState([]);

  const { variations, fetchVariations } = useInventoryStore((state) => ({
    variations: state.variations,
    fetchVariations: state.fetchVariation,
  }));

  useEffect(() => {
    fetchVariations();
  }, [fetchVariations]);

  const handleCreateInventory = async () => {
    setError({
      name: "",
      rate: "",
      costPrice: "",
      quantity: "",
    });

    const newRate = convertToFloat(inventories.rate);
    const newCostPrice = convertToFloat(inventories.costPrice);
    const newQuantity = convertToFloat(inventories.quantity);

    const newVariationList = variationList.map((item) => ({
      ...item,
      price: convertToFloat(item.price),
      costPrice: convertToFloat(item.costPrice),
      quantity: convertToFloat(item.quantity),
    }));

    const { hasErrors, errors } = validateCreateInventory({
      rate: newRate,
      costPrice: newCostPrice,
      name: inventories.name,
      variationLen: newVariationList.length,
    });

    const variationErrorMsg = ValidateVariation(newVariationList);
    setVariationError([...variationErrorMsg]);

    if (hasErrors || variationErrorMsg.length > 0) {
      setError(errors);
      setErrorMessage("Cannot create inventory with duplicate variations.");
      return;
    }

    try {
      setIsLoading(true);
      let payload: any = {
        name: inventories.name,
      };

      if (newVariationList.length > 0) {
        payload.variations = newVariationList;
      } else {
        payload.rate = newRate || 0;
        payload.costPrice = newCostPrice || 0;
        payload.quantity = newQuantity || 0;
      }

      const response: IInventory = await createInventory(payload);

      const newItem: ItemInput = {
        id: response.id,
        name: response.name,
        rate: response.rate ?? 0,
        variations:
          response.variations && response.variations.length > 0
            ? response.variations.map((variation) => ({
                type: variation.type || "",
                name: variation.name || "",
                id: variation.id || "",
                price:
                  typeof variation.price === "number" ? variation.price : 0,
                quantity:
                  typeof variation.quantity === "number"
                    ? variation.quantity
                    : 0,
              }))
            : undefined,
      };

      toast.success("New Inventory Added Successfully!");
      setShowCreateInventory(false);
      onCreateSuccess(newItem);
    } catch (error) {
      toast.error("Failed to create inventory");
      setShowCreateInventory(false);
      console.error("Error creating inventory:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchVariations = useCallback(() => {
    fetchVariations();
  }, [showCreateForm]);

  const showForm = () => {
    setShowCreateForm(true);
  };

  const onClose = () => {
    setIsSideBarOpen(false);
  };

  const title =
    !showCreateForm && variations.length >= 1
      ? "List Of Your Variations"
      : "Add a Variation";

  return (
    <div>
      <div className="flex items-center justify-between ">
        <div className="flex items-center gap-4"></div>
      </div>
      <>
        <div className="flex items-start justify-between gap-[6em]">
          <div className="mt-3">
            <h1 className="text-[14px] md:text-[18px] lg:text-[24px] font-normal">
              Create New Inventories and their Variations
            </h1>
          </div>
          <button
            className="lg:hidden md:hidden rounded-lg shadow-sm py-2 pr-3 bg-[#F4F7FF] text-[black] w-35 h-35 "
            onClick={onclose}
          >
            <CloseIcon />
          </button>
          <button
            style={{ fontWeight: 600 }}
            className=" hidden md:block px-[3em] py-3 bg-white cursor-pointer border border-[#044E97] text-[#044E97] text-[16px] rounded-md mt-[1em]"
            onClick={() => {
              setIsSideBarOpen(!isSideBarOpen);
            }}
          >
            Input variations
          </button>
        </div>
        <InventoryForm
          handleCreateInventory={handleCreateInventory}
          inventory={inventories}
          variations={variationList}
          setVariations={setVariationList}
          setInventories={setInventories}
          setIsSideBarOpen={setIsSideBarOpen}
          isLoading={isLoading}
          error={error}
          setError={setError}
          variationList={variations}
          variationError={variationError}
          errorMessage={errorMessage}
          setVariationError={setVariationError}
          onClose={() => setShowCreateInventory(false)}
        />
      </>
      {showSidebar &&
      showVariationList &&
      variations.length !== 0 &&
      !showCreateForm ? (
        <SidebarDrawer
          title={title}
          show={isSideBarOpen}
          position="left"
          onClose={onClose}
        >
          <VariationList closeForm={showForm} data={variations} />
        </SidebarDrawer>
      ) : (
        showSidebar && (
          <SidebarDrawer
            title={title}
            show={isSideBarOpen}
            position="left"
            onClose={onClose}
          >
            <VariationForm
              closeForm={onClose}
              closeCreateForm={() => setShowCreateForm(false)}
              close={setIsSideBarOpen}
            />
          </SidebarDrawer>
        )
      )}
    </div>
  );
};

export { InventoryOverlay };
