import { BlueInvoiceIcon } from "../../../assets/icons/BlueInvoiceIcon";
import React from "react";
import { CustomerIcon } from "../../../assets/icons";

interface InvoiceItemProps {
  invoiceNumber: string;
  customer: {
    firstName: string;
    lastName: string;
  };
}

export const InvoiceItemComponent: React.FC<InvoiceItemProps> = ({
  invoiceNumber,
  customer,
}) => (
  <div className="flex flex-col ">
    <div className="flex px-3">
      <BlueInvoiceIcon />
      <span className="text-[14px] font-normal pl-[1em]">{invoiceNumber}</span>
    </div>
    <div className="flex mt-2 px-2 mb-2">
      <CustomerIcon color="#044E97" />
      <span className="text-[14px] font-normal pl-[1em]">
        {customer.firstName} {customer.lastName}
      </span>
    </div>
  </div>
);
