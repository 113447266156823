import { Service } from "../@types/service";
import { toast } from "react-hot-toast";
import { serviceService } from "../services";
import { withRetry } from "../utils";

const BASE = `services`;

const handleError = (e: any) => {
  toast.error(e.response?.data || e.message);
  throw new Error(e.response?.data || e.message);
};

export const createNewService = async (data: Service) => {
  return withRetry(async () => {
    const res = await serviceService.post(`/${BASE}`, data);
    return res.data;
  }).catch(handleError);
};

export const getService = async (id: string) => {
  return withRetry(async () => {
    const res = await serviceService.get(`${BASE}/${id}`);
    return res.data;
  }).catch(handleError);
};

export const updateService = async (id: string, data: Service) => {
  return withRetry(async () => {
    const res = await serviceService.patch(`/${BASE}/${id}`, data);
    return res.data;
  }).catch(handleError);
};

export const deleteService = async (id: string) => {
  return withRetry(async () => {
    const res = await serviceService.delete(`/${BASE}/${id}`);
    return res.data;
  }).catch(handleError);
};
