import React from "react";
import { BlueInventoryIcon } from "../../../assets/icons/BlueInventoryIcon";
import { InventoryBarIcon } from "../../../assets/icons/InventorySeachIcon";

interface InventoryItemProps {
  name: string;
  quantity: string | number;
}

export const InventoryItemComponent: React.FC<InventoryItemProps> = ({
  name,
  quantity,
}) => (
  <div className="flex flex-col justify">
    <div className="flex px-3">
      <BlueInventoryIcon />
      <span className="text-[14px] font-normal pl-[1em]">{name}</span>
    </div>
    <div className="flex mt-2 px-3 mb-2">
      <InventoryBarIcon />
      <span className="text-[14px] font-normal  pl-[1em]">{quantity}</span>
    </div>
  </div>
);
