import React from "react";

const TopSellingEmptyState = () => {
  return (
    <>
      <svg
        width="55"
        height="61"
        viewBox="0 0 55 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.4905 40.5371C19.4384 40.9059 19.4115 41.2828 19.4115 41.666C19.4115 46.0843 22.9932 49.666 27.4115 49.666C31.8297 49.666 35.4115 46.0843 35.4115 41.666C35.4115 41.2828 35.3845 40.9059 35.3324 40.5371H54.7448V58.3327C54.7448 59.4373 53.8494 60.3327 52.7448 60.3327H2.07812C0.973556 60.3327 0.078125 59.4373 0.078125 58.3327V40.5371H19.4905Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.0781 40.3327C36.0781 45.1192 32.1979 48.9993 27.4115 48.9993C22.625 48.9993 18.7448 45.1192 18.7448 40.3327C18.7448 40.1776 18.7489 40.0235 18.7569 39.8704H0.078125L6.45175 21.0253C6.72652 20.2128 7.48869 19.666 8.34633 19.666H46.4766C47.3342 19.666 48.0964 20.2128 48.3712 21.0253L54.7448 39.8704H36.066C36.0741 40.0235 36.0781 40.1776 36.0781 40.3327Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M34.8098 40.969C34.8098 44.6677 31.4975 48.3327 27.4115 48.3327C23.3254 48.3327 20.0131 44.6677 20.0131 40.969C20.0131 40.8492 20.0166 40.0635 20.0234 39.9452H6.07812L11.519 26.7163C11.7536 26.0885 12.4042 25.666 13.1363 25.666H41.6866C42.4187 25.666 43.0693 26.0885 43.3039 26.7163L48.7448 39.9452H34.7995C34.8064 40.0635 34.8098 40.8492 34.8098 40.969Z"
          fill="#BBDDFC"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.914062 40.0072V57.6657C0.914062 58.31 1.4364 58.8324 2.08073 58.8324H52.7474C53.3917 58.8324 53.9141 58.31 53.9141 57.6657V40.0072L47.5844 21.2919C47.4241 20.818 46.9795 20.499 46.4792 20.499H8.34893C7.84865 20.499 7.40405 20.818 7.24376 21.2919L0.914062 40.0072Z"
          stroke="#0469C5"
          stroke-width="1.66667"
        />
        <path
          d="M9.41178 39.666C12.0362 39.666 14.8684 39.666 17.9084 39.666C19.1592 39.666 19.1592 40.5451 19.1592 40.9993C19.1592 45.4176 22.8234 48.9993 27.3433 48.9993C31.8632 48.9993 35.5274 45.4176 35.5274 40.9993C35.5274 40.5451 35.5274 39.666 36.7782 39.666H53.4118M4.46094 39.666H6.07845H4.46094Z"
          stroke="#0469C5"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M44.1471 4.53451L36.7474 12.8366M26.8138 1.66602V12.8366V1.66602ZM9.41406 4.53451L16.8138 12.8366L9.41406 4.53451Z"
          stroke="#E0EEFE"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default TopSellingEmptyState;
