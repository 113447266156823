import React from "react";
import AddSquare from "../../../assets/icons/AddSquare";
import { DownloadIcon } from "../../../assets/icons/DownloadIcon";
import ExpandLeft from "../../../assets/icons/ExpandLeft";
import { PrintIcon } from "../../../assets/icons/PrintIcon";
import ShareIcon from "../../../assets/icons/ShareIcon";
import InvoiceExample from "../../../components/Sales/invoice/InvoiceExample";
const SendInvoicePage = () => {
  return (
    <div>
      <h1 className="font-bold text-[20px] flex items-center ">
        <div className="mt-[5px]">
          <ExpandLeft />
        </div>{" "}
        SEND INVOICE
      </h1>{" "}
      {/* fORM FOR THE ADDRESS OF THE SENDER AND RECEIVER */}
      <form action="">
        <div className="mb-4 mt-[18px]">
          <label className="  font-semibold " htmlFor="email">
            From:
          </label>
          <div>
            <input
              className="w-[434px] h-[48px] rounded-[4px] border-1 border-[#A4A4A4] placeholder:text-[#A4A4A4] mt-[2px]"
              id="email"
              type="email"
              placeholder="Email"
            />{" "}
          </div>{" "}
        </div>{" "}
        <div className="mb-4 mt-[25px]">
          {" "}
          <label className="  font-semibold " htmlFor="email">
            {" "}
            To:
          </label>
          <div>
            <input
              className="w-[434px] h-[48px] rounded-[4px] border-1 border-[#A4A4A4] placeholder:text-[#A4A4A4] mt-[2px]"
              id="email"
              type="email"
              placeholder="Email"
            />{" "}
          </div>{" "}
          <a
            href="src/pages/invoices/create.tsx"
            className="inline-flex items-center gap-[7px] text-[#044E97] font-medium mt-[8px]"
          >
            {" "}
            Add Email <AddSquare />{" "}
          </a>{" "}
        </div>{" "}
        <div className="mb-4 mt-[25px]">
          {" "}
          <label className="  font-semibold " htmlFor="email">
            {" "}
            Email Subject{" "}
          </label>{" "}
          <div>
            {" "}
            <input
              className="w-[434px] h-[48px] rounded-[4px] border-1 border-[#A4A4A4] placeholder:text-[#A4A4A4] mt-[2px]"
              id="email"
              type="email"
              placeholder="Email"
            />{" "}
          </div>{" "}
        </div>{" "}
      </form>{" "}
      <div className="flex items-center mt-[58px]">
        {" "}
        <button className="w-[239px] rounded-[4px] h-[55px] bg-[#FF6702] text-white font-semibold">
          {" "}
          Send
        </button>
        <div className=" bg-[#D9D9D9] font-semibold text-[16px] text-[#044E97] bg-opacity-20  flex border ml-[66px] items-center rounded-l-[4px] h-[39px] rounded-r-[4px]">
          {" "}
          <div>
            {" "}
            <p className="flex border-r px-[18px] gap-[8px] items-center ">
              {" "}
              Download <DownloadIcon />
            </p>
          </div>
          <div>
            <p className="flex border-r  px-[18px] gap-[8px] items-center ">
              {" "}
              Share <ShareIcon />{" "}
            </p>{" "}
          </div>{" "}
          <div>
            {" "}
            <p className="flex border-r  px-[18px] gap-[8px] items-center ">
              {" "}
              Print <PrintIcon />{" "}
            </p>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
      <InvoiceExample />{" "}
    </div>
  );
};
export { SendInvoicePage };
