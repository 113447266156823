import React, { useState } from "react";
import { SERVICES } from "../../../constants/helpServices";
import { clearFieldError } from "../../../utils/handleErrors";
import { DropdownComponent, InputComponent } from "../Forms";
import * as UI from "../../common";
import { validateSupportForm } from "../../../modules/profile";
import { ICreateSupport } from "../../../@types";
import { Button } from "../../common";
import UploadIcon from "../../../assets/icons/UploadIcon";
import { CloseIcon } from "../../../assets/icons";
import { shortenFileName } from "../../../utils";

interface RequestFeatureProps {
  showSideDrawer: boolean;
  onClose?: () => void;
  onSubmit: (supportData: ICreateSupport) => void;
}

const RequestFeature: React.FC<RequestFeatureProps> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [file, setFile] = React.useState<File | null>();
  const [imagePreview, setImagePreview] = React.useState<string | null>(null);

  const initialFormData = {
    feature: "",
    subject: "",
    description: "",
    type: "feature_request",
    imageUrl: "",
  };

  const [formData, setFormData] = useState({
    feature: "",
    subject: "",
    description: "",
    type: "feature_request",
    imageUrl: "",
  });

  const [validationResults, setValidationResults] = useState({
    feature: "",
    subject: "",
    description: "",
    type: "feature_request",
    imageUrl: "",
  });

  const handleSelectFile = () => {
    inputRef.current?.click();
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Image = reader.result as string;
        setFile(selectedFile);
        setFormData((prevFormData) => ({
          ...prevFormData,
          imageUrl: base64Image,
        }));
        setImagePreview(base64Image);
      };

      reader.readAsDataURL(selectedFile);
    } else {
      setFile(null);
      setFormData((prevFormData) => ({
        ...prevFormData,
        imageUrl: "",
      }));
      setImagePreview(null);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const errors = validateSupportForm(formData);
      if (Object.keys(errors).some((key) => errors[key])) {
        setValidationResults(errors);
        return;
      }

      const supportData = {
        feature: formData.feature,
        subject: formData.subject,
        description: formData.description,
        type: "feature_request",
        imageUrl: formData.imageUrl,
      };

      setIsSubmitted(true);
      await props.onSubmit(supportData);
      setFile(null);
      setFormData(initialFormData);
      setImagePreview(null);
    } catch (error) {
      console.error("Error submitting report:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = (e: { preventDefault: any }) => {
    e.preventDefault;
    setIsOpen(false);
    props.onClose && props.onClose();
  };

  const dropDownArray = SERVICES;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setValidationResults({
      ...validationResults,
      [name]: "",
    });
    clearFieldError(name, validationResults, setValidationResults);
  };

  const handleDropDownChange = (name: string, value: string) => {
    clearFieldError(name, validationResults, setValidationResults);
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="h-screen">
      <form onSubmit={(event) => event.preventDefault()}>
        <div>
          <label
            htmlFor="Select "
            className="block font-sm text-[16px] mt-[4em] text-black mb-2"
          >
            Please select the page you have an issue with?
          </label>
          <DropdownComponent
            name="feature"
            options={dropDownArray}
            error={validationResults.feature}
            placeholder="Click to Select"
            value={formData.feature}
            selectedValue={formData.feature}
            onChange={handleDropDownChange}
            isSubmitted={isSubmitted}
            dropdownClassName="w-[100%] bg-white  rounded py-1 focus:ring-0 pointer-events placeholder-gray-400 placeholder-opacity-75"
            iconClassName="mt-[-4px] mr-[2em] "
          />
          {validationResults.feature && (
            <span className=" text-red-500">{validationResults.feature}</span>
          )}
        </div>
        <div className="mt-[2em]">
          <label
            htmlFor="addedFeature"
            className="block font-sm text-[16px] text-black mb-2"
          >
            What feature would you like us to add?
          </label>
          <InputComponent
            placeholder="Please Share"
            type="text"
            name="subject"
            value={formData.subject}
            handleChange={handleInputChange}
            error={validationResults.subject}
            className="w-[455px]"
          />
        </div>
        <div className="mt-[2em]">
          <label
            htmlFor="image"
            className="block font-sm text-[16px] text-black "
          >
            Upload Image
          </label>
          <p className="text-[12px] mb-2">
            Feel free to add an image that describes the bug
          </p>
          <div className="w-[455px] h-[180px] bg-opacity-[0.08] border border-gray rounded-[16px]  ">
            <div className="mt-[1em] text-center leading-5">
              <p className="font-semibold">Upload Image </p>
              <p>(It could be an XLS or CSV document) </p>
            </div>

            {file && (
              <div className="flex items-center justify-center text-lg mt-1">
                <span className="text-[#0500F9] mx-3 font-medium">
                  {shortenFileName(file)}
                </span>
                <a href="#close" onClick={() => setFile(undefined)}>
                  <CloseIcon color="red" />
                </a>
              </div>
            )}
            <div className="flex justify-center mt-[13px]">
              <button
                onClick={handleSelectFile}
                className="flex items-center border border-[#828282] px-[13px] py-[8px] rounded-[4px] bg-[#EDF2F5] "
              >
                <p>Upload document</p>
                <div className="bg-[#D6DADD] w-[1px] h-full mx-[9px] "></div>
                <UploadIcon />
              </button>
              <input
                className="hidden"
                type="file"
                ref={inputRef}
                accept="jpg,jpeg,png"
                onChange={handleUpload}
              />
            </div>
          </div>
        </div>
        <div className=" mt-[2em]">
          <label
            htmlFor="bug"
            className="block font-sm text-[16px] text-black my-[20px]"
          >
            <span className="font-bold text-[14px]">
              Please explain in detail how you would like this feature to work?
            </span>
            The more detail you share the more clarity we get
          </label>
          <textarea
            value={formData.description}
            className={`border min-h-[123px] border-[#A4A4A4]  h-[80px] w-full rounded-md resize-y overflow-y-auto word-wrap break-word ring-1 ring-[#138EFF]`}
            name="description"
            placeholder="Please Share"
            onChange={(e: any) => handleInputChange(e)}
          />

          {validationResults.description && (
            <span className="text-red-500">
              {validationResults.description}
            </span>
          )}
        </div>
        <div className="w-full h-full flex items-start justify-end pt-[20px] pb-[40px]">
          <div className="">
            <Button
              text={isLoading ? "Submitting..." : "Submit"}
              isLoading={isLoading}
              onClick={handleSubmit}
              disabled={isLoading}
              size="small"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export { RequestFeature };
