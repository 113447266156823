import { FormEventHandler, useEffect, useState } from "react";
import { validate } from "email-validator";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useNavigate, useLocation, redirect } from "react-router-dom";
import LogoBlack from "../LogoBlack";
import * as UI from "../common";
import { PinCode } from "../common";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import userPool from "../../utils/userPool";
import { PinCodeMobile } from "../common/PinCode/mobile";

const ConfirmAccountView = () => {
  const { t } = useTranslation("onboarding");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const [code, setCode] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonText] = useState("Continue");
  const [resending, setResending] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isOtpDisabled, setIsOtpDisabled] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => setCountdown((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    } else {
      setIsOtpDisabled(false); // Enable button after countdown
    }
  }, [countdown]);

  if (!email) {
    redirect("/forget-password");
    return null;
  }

  const handleVerifyAccount: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");

    const user = new CognitoUser({ Username: email, Pool: userPool });
    user.confirmRegistration(code, true, (err, result) => {
      if (err) {
        switch (err.code) {
          case "UserNotFoundException":
            setErrorMessage(
              "User not found. Please ensure you have signed up.",
            );
            break;
          case "NotAuthorizedException":
            setErrorMessage("User is already confirmed.");
            break;
          case "CodeMismatchException":
            setErrorMessage(
              "Invalid confirmation code. Please check and try again.",
            );
            break;
          default:
            setErrorMessage(err.message);
        }
        setLoading(false);
      } else {
        toast.success("Account Verified Successfully!");
        navigate("/login");
      }
    });
  };
  const formatCountdown = (seconds: number) => {
    const minutes = String(Math.floor(seconds / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");
    return `${minutes}:${secs}`;
  };

  const handleResendCode = () => {
    if (isOtpDisabled || countdown > 0) return;

    setCountdown(60);
    setIsOtpDisabled(true);

    const user = new CognitoUser({ Username: email, Pool: userPool });
    user.resendConfirmationCode((err) => {
      if (err) {
        setErrorMessage(err.message);
      } else {
        toast.success("Confirmation code resent successfully.");
      }
    });
  };

  const isDisabled = !validate(email || "") || code.length < 6 || loading;

  return (
    <>
      {/* Desktop */}
      <div className="hidden lg:block md:block">
        <div className="flex justify-center items-center  min-h-screen md:mt-[-7em] lg:mt-0 bg-[#F2F9FD]">
          <div className="bg-white mx-10 lg:mx-auto md:w-[450px] 1280:w-[514px] xl:w-[578px] border lg:p-10 lg:text-[#1C3041]">
            {errorMessage && (
              <div className="text-[red] border bg-[red] bg-opacity-10 px-2 text-center">
                {errorMessage}
              </div>
            )}
            <div>
              <div className="flex justify-center xl:my-0 lg:flex lg:justify-center">
                <LogoBlack />
              </div>
              <h1 className="md:text-[24px] text-[20px] font-semibold text-center mt-4 mb-8">
                Confirm your email
              </h1>
            </div>
            <div>
              <p className="mt-4 text-[16px] lg:text-[#5D6D73] text-center">
                To activate your account, we need you to confirm{" "}
                <span className=" text-[#044E97]">{email} </span>as your email
                address. Input the code sent to your email address.{" "}
              </p>
              <div className="mt-4 md:mt-6 lg:mt-8">
                <form onSubmit={handleVerifyAccount}>
                  <div className="w-full">
                    <PinCode codeLength={6} onCodeComplete={setCode} />
                  </div>

                  <div className="mt-[1em]">
                    <UI.Button
                      text={buttonText}
                      isLoading={loading}
                      disabled={isDisabled}
                      variant="secondary"
                    />
                  </div>

                  <div className="text-center mt-4">
                    <button
                      type="button"
                      onClick={handleResendCode}
                      disabled={isOtpDisabled}
                      className={`text-blue-600  text-sm font-semibold ${
                        isOtpDisabled ? "cursor-not-allowed opacity-50" : ""
                      }`}
                    >
                      {isOtpDisabled
                        ? `Resending code in ${formatCountdown(countdown)}`
                        : "Resend OTP"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile */}

      <div className=" lg:hidden md:hidden bg-white mt-[3em]">
        <div className=" mx-10 lg:mx-auto md:w-[450px] 1280:w-[514px] xl:w-[578px] lg:p-10 lg:text-[#1C3041]">
          {errorMessage && (
            <div className="text-[red] border bg-[red] bg-opacity-10 px-2 text-center">
              {errorMessage}
            </div>
          )}
          <div>
            <h1 className="md:text-[24px] text-[20px] font-semibold text-center mt-4 mb-8">
              Confirm your email
            </h1>
          </div>
          <div>
            <p className="mt-4 text-[14px] lg:text-[#5D6D73] text-center">
              To activate your account, we need you to confirm{" "}
              <span className=" text-[#044E97]">{email} </span>as your email
              address. Input the code sent to your email address.{" "}
            </p>
            <div className="mt-4 md:mt-6 lg:mt-8">
              <form onSubmit={handleVerifyAccount}>
                <div className="w-full mt-[2em]">
                  <PinCodeMobile codeLength={6} onCodeComplete={setCode} />
                </div>

                <div className="mt-[2em]">
                  <UI.Button
                    text={buttonText}
                    isLoading={loading}
                    disabled={isDisabled}
                    variant="secondary"
                  />
                </div>

                <div className="text-center mt-4">
                  <button
                    type="button"
                    onClick={handleResendCode}
                    disabled={isOtpDisabled}
                    className={`text-blue-600  text-sm font-semibold ${
                      isOtpDisabled ? "cursor-not-allowed opacity-50" : ""
                    }`}
                  >
                    {isOtpDisabled
                      ? `Resending code in ${formatCountdown(countdown)}`
                      : "Resend OTP"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export { ConfirmAccountView };
