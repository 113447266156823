import React from "react";
import config from "../../config";

export const BetaBadge = () => {
  if (!config.beta) {
    return null;
  }
  return (
    <svg
      width="69"
      height="36"
      viewBox="0 0 69 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_28570_72089)">
        <rect
          x="8"
          y="4"
          width="53"
          height="20"
          rx="10"
          fill="white"
          shape-rendering="crispEdges"
        />
        <path
          d="M20.9609 18V9.59766H24.0195C24.957 9.59766 25.6777 9.76953 26.1816 10.1133C26.6855 10.457 26.9375 10.9414 26.9375 11.5664C26.9375 12.0195 26.7832 12.416 26.4746 12.7559C26.1699 13.0957 25.7793 13.332 25.3027 13.4648V13.4883C25.9004 13.5625 26.377 13.7832 26.7324 14.1504C27.0918 14.5176 27.2715 14.9648 27.2715 15.4922C27.2715 16.2617 26.9961 16.873 26.4453 17.3262C25.8945 17.7754 25.1426 18 24.1895 18H20.9609ZM22.8535 10.9922V12.9844H23.6855C24.0762 12.9844 24.3828 12.8906 24.6055 12.7031C24.832 12.5117 24.9453 12.25 24.9453 11.918C24.9453 11.3008 24.4844 10.9922 23.5625 10.9922H22.8535ZM22.8535 14.3906V16.6055H23.8789C24.3164 16.6055 24.6582 16.5039 24.9043 16.3008C25.1543 16.0977 25.2793 15.8203 25.2793 15.4688C25.2793 15.1328 25.1562 14.8691 24.9102 14.6777C24.668 14.4863 24.3281 14.3906 23.8906 14.3906H22.8535ZM33.6992 18H28.6602V9.59766H33.5059V11.1387H30.5527V13.0078H33.3008V14.543H30.5527V16.4648H33.6992V18ZM41.0527 11.1387H38.6562V18H36.7578V11.1387H34.373V9.59766H41.0527V11.1387ZM48.7285 18H46.666L46.0684 16.1309H43.0801L42.4883 18H40.4375L43.4961 9.59766H45.7402L48.7285 18ZM45.6348 14.6777L44.7324 11.8535C44.666 11.6426 44.6191 11.3906 44.5918 11.0977H44.5449C44.5254 11.3438 44.4766 11.5879 44.3984 11.8301L43.4844 14.6777H45.6348Z"
          fill="#FF6702"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_28570_72089"
          x="0"
          y="0"
          width="69"
          height="36"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0784314 0 0 0 0 0.0784314 0 0 0 0 0.168627 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_28570_72089"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_28570_72089"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default BetaBadge;
