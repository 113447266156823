import React from "react";

const SubscriptionArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
    >
      <g filter="url(#filter0_d_16100_24029)">
        <circle cx="25" cy="26" r="21" fill="#044E97" />
        <path
          d="M25.1235 22.9141L25.1235 40.1879"
          stroke="white"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.3045 12.9789L17.5882 22.5736C17.1243 23.2363 17.5984 24.147 18.4075 24.147L31.84 24.147C32.649 24.147 33.1231 23.2363 32.6592 22.5736L25.9429 12.9789C25.5448 12.4102 24.7026 12.4102 24.3045 12.9789Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_16100_24029"
          x="0"
          y="0"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_16100_24029"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_16100_24029"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default SubscriptionArrow;
