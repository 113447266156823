import { create } from "zustand";
import { IPaginationMeta, IService } from "../@types";
import { debounce } from "lodash";
import { Filter } from "../@types/invoice";
import { serviceService } from "../services/serviceService";

interface FetchOptions {
  actionType?: "initial" | "filter";
}

interface ServiceStore {
  services: IService[];
  newServices: IService[];
  isLoading: boolean;
  isLoadingInitial: boolean;
  isLoadingFilter: boolean;
  isLoadingMore: boolean;
  error: any;
  createService: (newServiceData: any) => Promise<void>;
  fetchServices: () => Promise<void>;
  fetchData: (options?: FetchOptions) => Promise<void> | undefined;
  fetchMoreData: () => Promise<void>;
  setSearchText: (text: string) => void;
  handleSort: (text: string) => void;
  setFilter: (filter: Filter) => void;
  removeService: (id: string) => void;
  addInvoice: (invoice: any) => void;
  pagination: IPaginationMeta;
  searchText: string;
  range: string;
  filter: Filter;
  getServiceById: (id: string) => Promise<IService | null>;
  clearAllFilters: () => void;
}

export const useServiceStore = create<ServiceStore>((set, get) => ({
  services: [],
  newServices: [],
  isLoading: false,
  isLoadingInitial: true,
  isLoadingFilter: false,
  isLoadingMore: false,
  error: null,
  searchText: "",
  range: "",
  filter: {
    status: "",
  },
  pagination: {
    cursor: null,
    backCursor: null,
    hasMoreData: false,
    limit: undefined,
    count: undefined,
  },
  fetchData: async (options?: FetchOptions) => {
    const { actionType = "initial" } = options || {};
    if (actionType === "initial") {
      set({ isLoadingInitial: true });
    } else if (actionType === "filter") {
      set({ isLoadingFilter: true });
    }
    const { searchText, filter, range } = get();

    try {
      let query = `/services`;

      if (
        range.length > 0 ||
        searchText.length > 0 ||
        ("status" in filter && filter.status.length > 0)
      ) {
        query += `?`;
        if (searchText.length > 0) query += `search=${searchText}&`;
        if (range.length > 0) query += `range=${range}&`;
        if ("status" in filter && filter.status.length > 0)
          query += `status=${filter.status}&`;
      }
      const res = await serviceService.get(query);
      set({
        services: res.data.data,
        pagination: res.data.meta,
        isLoadingInitial: false,
        isLoadingFilter: false,
      });
    } catch (e: any) {
      set({
        isLoadingInitial: false,
        isLoadingFilter: false,
        error: e.message,
      });
    }
  },

  fetchServices: async () => {
    set((prevState: ServiceStore) => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      const res = await serviceService.get(`/services`);
      set((prevState: ServiceStore) => ({
        ...prevState,
        inventory: res.data.data,
        pagination: res.data.meta,
        isLoading: false,
      }));
    } catch (e: any) {
      set((prevState: ServiceStore) => ({
        ...prevState,
        isLoading: false,
        error: e.message,
      }));
    }
  },
  createService: async (newServiceData: any) => {
    set({ isLoading: true });
    try {
      const res = await serviceService.post("/services", newServiceData);
      const newService = res.data.data;
      set((state) => ({
        newServices: [newService, ...state.newServices],
        isLoading: false,
      }));
    } catch (e: any) {
      set({ isLoading: false, error: e.message });
    }
  },
  fetchMoreData: async () => {
    const { filter } = get();

    if (!get().pagination.hasMoreData || get().isLoadingMore) return;
    set({ isLoadingMore: true });
    const cursor = get().pagination.cursor;
    try {
      let query = `/services?cursor=${cursor}`;
      if ("status" in filter && filter.status.length > 0) {
        if ("status" in filter && filter.status.length > 0)
          query += `&status=${filter.status}`;
      }

      const res = await serviceService.get(query);
      set((state) => ({
        services: [...state.services, ...res.data.data],
        pagination: res.data.meta,
        isLoadingMore: false,
      }));
    } catch (e: any) {
      set({ isLoadingMore: false, error: e.message });
    }
  },
  setSearchText: (text: string) => {
    set({ searchText: text });
  },
  handleSort: (text: string) => {
    set({ range: text });
  },
  setFilter: (filter: Filter) => {
    set({ filter: filter as Filter });
  },
  removeService: (id) => {
    const services = get().services;
    set({ services: services.filter((service) => service.id !== id) });
  },
  addInvoice: (service) => {
    set((state) => ({ services: [service, ...state.services] }));
  },
  getServiceById: async (id: string) => {
    set({ isLoading: true });
    try {
      const res = await serviceService.get(`/services/${id}`);
      const invoice = res.data.data;
      set({ isLoading: false });
      return invoice;
    } catch (e: any) {
      console.error(e.response.data);
      set({ isLoading: false, error: e.message });
      return null;
    }
  },
  clearAllFilters: () => {
    set({
      filter: {
        status: "",
      },
    });
  },
}));
