import React, { useEffect, useState } from "react";
import SubscriptionPlan from "./SubscriptionPlan";
import SubscriptionUsage from "./SubscriptionUsage";
import {
  useSubscriptionStore,
  subscriptions,
} from "../../../state/useSubscriptionStore";
import CancelSubscriptionPrompt from "./cancelSubscriptionPrompt";
import {
  getSubscriptionHistory,
  getActiveSubscription,
} from "../../../backend-services/subscription";
import SubscriptionHistory from "./SubscriptionHistory";
import PageLoader from "../../common/PageLoader";
import { CloseIcon } from "../../../assets/icons";
import { InfoCircle } from "../../../assets/icons/InfoCircle";
import "react-loading-skeleton/dist/skeleton.css";
import { Active } from "../../../constants/Subscription";
import { formatMoney } from "../../../utils";
import { formattedDate } from "../../../utils/formatDate";
import { useUserStore } from "../../../state";
import { useTranslation } from "react-i18next";

interface Props {
  submenuTab: string;
  onBack: () => void;
  bankSubmenuTab: string;
}

const Subscription = (props: Props) => {
  const { t } = useTranslation("subscription");
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowProcessing, setIsShowProcessing] = useState(false);
  const [subscriptionState, setSubscriptionState] =
    useState<subscriptions | null>(null);
  const [history, setHistory] = useState([]);
  const [activeSub, setActiveSub] = useState(true);
  const { subscriptionData, setSubscriptionData } = useSubscriptionStore();
  const fetchActiveSubscription = useSubscriptionStore(
    (state) => state.fetchActiveSubscription,
  );
  const [error, setError] = React.useState("");

  const getHistoryData = async () => {
    setIsLoading(true);
    try {
      const data = await getSubscriptionHistory();
      const updatedHistory = data.map((history: any) => {
        return {
          costPerMonth: formatMoney(history.costPerMonth),
          startDate: formattedDate(history.startDate),
          endDate: formattedDate(history.endDate),
          plan: history.plan,
        };
      });
      setHistory(updatedHistory);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(true);
      throw new Error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleActiveSubscription = React.useCallback(async () => {
    if (!(Object.keys(subscriptionData).length > 0)) {
      try {
        setIsLoading(true);
        const userSubHistory = await fetchActiveSubscription();
        setSubscriptionData(userSubHistory);
        setIsLoading(false);
      } catch (error: any) {
        console.error("Failed to fetch active subscription:", error);
        setError("Failed to load subscription data.");
      }
    }
  }, []);

  useEffect(() => {
    handleActiveSubscription();
  }, []);

  useEffect(() => {
    getHistoryData();
  }, [activeSub]);

  const handleSubscriptionDeleteSuccess = () => {
    setShowPrompt(false);
  };

  useEffect(() => {
    if (subscriptionData?.status === "processing") {
      setIsShowProcessing(true);
    }
  }, [subscriptionData]);

  return (
    <>
      {isLoading && !subscriptionState ? (
        <PageLoader />
      ) : (
        <div>
          {subscriptionState?.status === "processing" && isShowProcessing && (
            <div className="absolute top-[-18px] left-0">
              <div className="hidden md:flex justify-between items-center w-1/2 xl:w-[834px] h-[72px] p-4 bg-gray-100 border rounded-lg">
                <div className="flex flex-row gap-[16px] items-center">
                  <InfoCircle />
                  <div className="word whitespace-nowrap">
                    <p>Your subscription is in the works, hold tight.</p>
                  </div>
                </div>

                <button
                  onClick={() => setIsShowProcessing(false)}
                  className="ml-4 text-gray-600 hover:text-gray-800"
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
          )}

          <div className="flex flex-col justify-center items-center md:items-stretch md:justify-start">
            <div className="bg-[#FFFFFF] max-w-[350px] sm:max-w-full min-h-screen mx-[-70px] sm:mx-0 md:mx-0 rounded-[7px] mt-[32px]">
              <div className="flex flex-col gap-[7px]">
                <div className="pl-[16px] pr-[16px] sm:pl-[24px] lg:pl-[40px] 1280:pl-[20px] xl:pl-[64px]  sm:pr-[24px] lg:pr-[64px] pt-[16px] md:pt-[54px]">
                  <div className="flex justify-between items-center">
                    <span className="flex flex-col gap-[7px]">
                      <h3 className="text-[16px] md:text-[1.25rem] text-[#00000] leading-6  font-semibold">
                        {t("subscription.title1")}
                      </h3>
                      <p className="text-[12px] md:text-[1rem] md:leading-5  font-normal">
                        {t("subscription.manage")}
                      </p>
                    </span>
                    {subscriptionData?.status === Active && (
                      <div
                        onClick={() => setShowPrompt(true)}
                        className="w-[110px] h-[40px] md:h-[48px] md:w-[215px] flex justify-center items-center text-[14px] md:text-[16px] text-[#FB0303] font-semibold border border-[#FB0303] rounded-[4px]"
                      >
                        Cancel
                      </div>
                    )}
                  </div>
                </div>
                <hr className="mt-[13px] mb-[24px] md:mb-[29px]" />
                <div className="flex flex-col gap-[29px] lg:flex-row justify-between w-full px-[16px] sm:px-[24px] lg:px-0 lg:pl-[40px] xl:pl-[61px] 2xl:pl-[61px] lg:pr-[6.6%]">
                  <SubscriptionPlan subscriptionState={subscriptionData} />
                  <SubscriptionUsage subscriptionState={subscriptionData} />
                </div>
              </div>
              <div className="pl-[16px] pr-[16px] sm:pl-[24px] lg:pl-[40px] 1280:pl-[20px] xl:pl-[64px]  sm:pr-[24px] lg:pr-[64px] pt-[16px] md:pt-[54px]">
                <h3 className="text-[16px] md:text-[1.25rem] text-[#00000] leading-6  font-semibold">
                  {t("subscription.history")}
                </h3>
                <SubscriptionHistory isLoading={isLoading} history={history} />
              </div>
            </div>
          </div>

          {showPrompt && (
            <>
              <CancelSubscriptionPrompt
                handleGetActiveSubscription={handleActiveSubscription}
                id={subscriptionData.id}
                setShowPrompt={setShowPrompt}
                onClose={() => setShowPrompt(false)}
                onDeleteSuccess={handleSubscriptionDeleteSuccess}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Subscription;
