export const STORAGE_TOKEN = "@essemie/token";
export const STORAGE_REFRESH_TOKEN = "@essemie/refreshToken";
export const USERNAME = "@essemie/username";
export const STORAGE_USER_DATA = "@essemie/userData";
export const ROLE = "@essemie/role";
export const SUBSCRIPTION = "@essemie/subscription";
export const BUDGET = "@essemie/budget";
export const ROLES = {
  ADMIN: "Admin",
  OPERATOR: "Operator",
  ACCOUNTANT: "Accountant",
};
