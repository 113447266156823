import React from "react";
import { ICustomer, IInvoice } from "../../../@types";
import { CloseIcon } from "../../../assets/icons";
import { useCustomerSearch } from "../../../hooks";
import { SearchInput } from "../../common";
import { InputComponent } from "../../core/Forms";

interface SendReminderPromptProps {
  onSend: () => void;
  onClose?: () => void;
  isSidebarOpen: boolean;
  invoice: IInvoice | undefined;
  customerId: string;
  customerEmail?: string | undefined;
  customerPhone?: string | undefined;
}

const SendReminderPrompt: React.FC<SendReminderPromptProps> = ({
  onSend,
  onClose,
  invoice,
  customerId,
  customerEmail,
  customerPhone,
}) => {
  const {
    data: customers,
    isSearching,
    handleSearch: handleCustomerSearch,
  } = useCustomerSearch();
  const [customer, setCustomer] = React.useState<ICustomer | null>();
  const [, setSelectedCustomerId] = React.useState("");
  const [customerName, setCustomerName] = React.useState("");

  const [formData, setFormData] = React.useState({
    customerId: customer?.id || "",
    email: customerEmail || "",
    shouldSendWhatsApp: customerPhone || "",
    sendEmail: customerEmail || "",
  });

  const [errors, setErrors] = React.useState({
    customerId: "",
    email: "",
  });

  const [validationResults, setValidationResults] = React.useState({
    customerId: "",
    email: "",
  });

  const handleSend = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Validate the form fields
    if (!formData.customerId) {
      setValidationResults((prevValidationResults) => ({
        ...prevValidationResults,
        customerId: "Please select a customer",
      }));
      return;
    }

    if (!formData.email) {
      setValidationResults((prevValidationResults) => ({
        ...prevValidationResults,
        email: "Please enter an email",
      }));
      return;
    }

    // Perform the send action
    onSend();
    onClose && onClose();
  };

  const handleSetCustomer = (selectedCustomer: ICustomer | null) => {
    if (selectedCustomer) {
      setCustomer(selectedCustomer);
      setSelectedCustomerId(selectedCustomer.id);
      if (selectedCustomer.id) {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          customerId: selectedCustomer.id,
        }));
      }
    } else {
      setCustomer(null);
      setSelectedCustomerId("");
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        customerId: "",
      }));
    }
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      email: value,
    }));
  };

  const handleShouldSendWhatsAppChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      shouldSendWhatsApp: value,
    }));
  };

  const fetchInvoiceData = async () => {
    try {
      // if (invoice && invoice.customer && invoice.customer.id) {
      // const customerResponse = await GetCustomer(customerId);
      // if (customerResponse.id) {
      //   const customerData = {
      //     name: `${customerResponse.firstName} ${customerResponse.lastName}`,
      //     id: customerResponse.id,
      //     firstName: customerResponse.firstName,
      //     lastName: customerResponse.lastName,
      //     email: customerResponse.email,
      //     phone: customerResponse.phone,
      //     address: customerResponse.address,
      //     city: customerResponse.city,
      //     state: customerResponse.state,
      //   };
      //   setCustomer(customerData);
      // }
    } catch (error) {
      // Handle error if the API call fails
    }
  };

  React.useEffect(() => {
    fetchInvoiceData();
  }, []);

  return (
    <>
      <div className="h-screen ">
        <form onSubmit={handleSend}>
          <div className="flex justify-start ml-[3em] xl:ml-0 lg:ml-0 md:ml-0 biglg:ml-0 bigmd:ml-0 ">
            <h1 className="lg:text-[20px] xl:text-[20px] md:text-[20px] text-[16px] ">
              Send payment reminder to your <br /> customer via WhatsApp or mail
            </h1>
          </div>
          <div className="flex justify-start ml-[3em] xl:ml-0 lg:ml-0 md:ml-0 biglg:ml-0 bigmd:ml-0">
            <label htmlFor="whatsapp" className="block mb-3">
              <input
                type="radio"
                name="whatsapp"
                id="whatsapp"
                value={formData.sendEmail}
                onChange={handleChangeEmail}
                checked={formData.sendEmail === "true"}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <span className="ml-3 py-2 inline-block text-gray-900 lg:text-[20px] xl:text-[20px] md:text-[20px] text-[16px] xl:ml-3 lg:ml-3 md:ml-3 biglg:ml-3 bigmd:ml-3 ">
                Share via Mail
              </span>
            </label>
          </div>
          <div className="flex justify-start ml-[3em] xl:ml-0 lg:ml-0 md:ml-0 biglg:ml-0 bigmd:ml-0">
            <label htmlFor="whatsapp" className="block mb-3">
              <input
                type="radio"
                name="whatsapp"
                id="whatsapp"
                value={formData.shouldSendWhatsApp}
                onChange={handleShouldSendWhatsAppChange}
                checked={formData.shouldSendWhatsApp === "true"}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <span className="ml-3 py-2 inline-block text-gray-900 lg:text-[20px] xl:text-[20px] md:text-[20px] text-[16px] xl:ml-3 lg:ml-3 md:ml-3 biglg:ml-3 bigmd:ml-3 ">
                Share via Whatsapp
              </span>
            </label>
          </div>
          <div
            className="xl:w-[434px] w-[350px] relative ml-[3em] xl:ml-0 lg:ml-0 md:ml-0 biglg:ml-0 bigmd:ml-0"
            style={{ position: "relative" }}
          >
            <label
              htmlFor="customer"
              className=" font-sm text-[13px] mt-[3em] flex justify-start"
            >
              Customer Name
            </label>

            <SearchInput
              placeholder="Customer Name"
              data={customers}
              displayKey="name"
              isLoading={isSearching}
              value={customerName}
              selected={customer}
              onSelect={handleSetCustomer}
              handleChangeText={handleCustomerSearch}
              clearIcon={CloseIcon}
              error={validationResults.customerId}
              classNames="border border-gray-300 bg-white max-h-[48px] min-h-[48px] placeholder:text-[15.25px] h-[39.77px] rounded p-2 xl:w-[434px] md:w-[350px] lg:w-[350px] mt-[5px] focus:outline-none focus:shadow-outline appearance-none lg:focus:ring-0 leading-normal w-[300px] flex justify-start"
              onClearError={() => {
                setValidationResults((prevState: any) => ({
                  ...prevState,
                  customerId: undefined,
                }));
              }}
              searchKey={"name"}
            />
          </div>
          {/* <div className=" flex justify-start placeholder:text-[16px] h-[48px] rounded mt-[25px] ml-[-2em] "> */}
          <div className="w-[300px] mt-4 ml-[3em] xl:ml-0 lg:ml-0 md:ml-0 biglg:ml-0 bigmd:ml-0">
            <label
              htmlFor="Email"
              className=" font-sm text-[13px] text-black flex justify-start"
            >
              Email
            </label>
            <InputComponent
              placeholder="Email"
              name="email"
              type="text"
              value={formData.email}
              handleChange={handleChangeEmail}
              error={validationResults.email}
              className="xl:w-[434px] w-[300px] md:w-[350px] lg:w-[350px] flex justify-start"
            />
          </div>
          {/* </div> */}
          <div className="flex ml-[10px]">
            <button
              type="submit"
              className="w-[239px] rounded-[4px] h-[55px] bg-[#FF6702] text-white font-semibold ml-[4em] mt-[6em]"
            >
              Send Reminder
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export { SendReminderPrompt };
