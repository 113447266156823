import React from "react";
import { updateInventory } from "../../../backend-services";
import { toast } from "react-hot-toast";
interface ArchivePromptProps {
  setShowPrompt: (show: boolean) => void;
  id: string | undefined;
  isArchived: boolean;
  fetchDetail: (id: string) => void;
}
const ArchivePrompt = ({
  setShowPrompt,
  id,
  isArchived,
  fetchDetail,
}: ArchivePromptProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleArchive = async () => {
    if (!isArchived) {
      try {
        setIsLoading(true);
        if (id) {
          const response = await updateInventory(id, { isArchived: true });
          setIsLoading(false);
          setShowPrompt(false);
          fetchDetail(id);
          toast.success(" Inventory Archived Successfully!");
        }
      } catch (error: any) {
        throw new Error("Failed to archive Inventory");
        setIsLoading(false);
      }
    } else {
      try {
        setIsLoading(true);
        if (id) {
          const response = await updateInventory(id, { isArchived: false });
          setIsLoading(false);
          setShowPrompt(false);
          fetchDetail(id);
          toast.success(" Inventory Restore Successfully!");
        }
      } catch (error: any) {
        throw new Error("Failed to archive Inventory");
        setIsLoading(false);
      }
    }
  };
  return (
    <div>
      <div className="bg-[white] rounded-md w-[400px] lg:w-[500px]">
        <div className="flex justify-between  px-[1em] py-[1em]">
          <h1 className="hidden lg:block md:block text-[20px] font-semibold text-gray-900 text-center ">
            {isArchived ? "Restore Inventory ?" : "Archive Inventory ?"}
          </h1>
          <h1 className="lg:hidden md:hidden text-[25px] font-semibold text-gray-900 text-center whitespace-nowrap">
            {isArchived ? "Restore Inventory ?" : "Archive Inventory ?"}
          </h1>
        </div>
        <div className="flex justify-center mt-[13px]  px-[1em]">
          <h1 className="text-[#475467] text-[16px] font-medium">
            {!isArchived ? (
              <span>
                All archived inventory can be found in the archive directory for
                restoration.
              </span>
            ) : (
              <span>
                This inventory will be restored to your list of inventories and
                editable.
              </span>
            )}
          </h1>
        </div>
        <div
          className="flex mt-[1em] gap-5 justify-end pr-[1em] font-semibold"
          role="form"
          onSubmit={(e) => e.preventDefault()}
        >
          <div
            className="flex justify-end mb-5"
            onClick={() => setShowPrompt(false)}
          >
            <button className="w-[140px] rounded-[4px] h-[48px] bg-[#FFFFFF] text-[black] font-semibold border border-gray-300">
              Cancel
            </button>
          </div>
          <div className="flex justify-end mb-5">
            <button
              onClick={() => handleArchive()}
              className="w-[140px] rounded-[4px] h-[48px] bg-[#044E97] text-white  font-semibold"
            >
              {isLoading
                ? "Loading..."
                : `${isArchived ? "Restore" : "Archive"}`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export { ArchivePrompt };
