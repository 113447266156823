import React from "react";
import { NewPasswordView } from "../../components/auth";

interface Props {
  children?: any;
}

export const NewPasswordPage: React.FC<Props> = ({ children }) => {
  return <NewPasswordView />;
};
