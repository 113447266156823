import React, { useState, useRef, useEffect } from "react";
import { ICreateSalesData } from "../../../../@types";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { InvoiceErrors } from "../../../../modules/validateInvoiceForm";
import { Tooltip } from "../../../../assets/icons/Tooltip";
import DateIcon, { SmallDateIcon } from "../../../../assets/icons/Date";
import { InputComponent } from "../../../core/Forms";
import { useTranslation } from "react-i18next";

interface InvoiceDateProps {
  handleDropDownChange: (name: string, value: string) => void;
  formData: ICreateSalesData;
  setFormData: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
  selectedCurrencyLabel: string;
  validationResults: InvoiceErrors;
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>;
  toolTipText?: string;
  toolTipText2?: string;
  handleInputChange: (event: { target: { name: any; value: any } }) => void;
}

const InvoiceDate: React.FC<InvoiceDateProps> = ({
  formData,
  setFormData,
  selectedCurrencyLabel,
  handleDropDownChange,
  validationResults,
  setValidationResults,
  toolTipText,
  handleInputChange,
  toolTipText2,
}) => {
  const { t } = useTranslation("invoices");
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

  const [invoiceDate, setInvoiceDate] = useState<Date>(new Date());
  const [dueDate, setDueDate] = useState<Date | null>(null);

  const [showInvoiceDateCalendar, setShowInvoiceDateCalendar] = useState(false);
  const [showDueDateCalendar, setShowDueDateCalendar] = useState(false);

  const invoiceDateRef = useRef<HTMLDivElement>(null);
  const dueDateRef = useRef<HTMLDivElement>(null);

  const handleInvoiceDateChange = (value: Date | Date[] | null) => {
    if (value instanceof Date) {
      setInvoiceDate(value);
      setFormData({ ...formData, issueDate: value.toISOString() });
    }
    setShowInvoiceDateCalendar(false);
  };

  const handleDueDateChange = (value: Date | Date[] | null) => {
    if (value instanceof Date) {
      setDueDate(value);
      setFormData({ ...formData, invoiceDueAt: value.toISOString() });
      setValidationResults((prevResults) => ({
        ...prevResults,
        invoiceDueAt: "",
      }));
    }
    setShowDueDateCalendar(false);
  };

  return (
    <>
      {/* Desktop */}
      <div className="flex flex-col items-center mt-[3em] hidden lg:block md:block xl:mr-[0.5em] biglg:mr-[1.5em] lg:mr-[1.5em] md:mr-[-1em]">
        <div className="flex justify-between xl:gap-[1em] biglg:gap-[1em] lg:gap-[0.5em] md:gap-[1em] ">
          <div>
            <div className="ml-[3em] lg:ml-0 xl:ml-0 2xl:ml-0 biglg:ml-0 bigmd:ml-0 md:ml-0 mt-[13px] lg:mt-0 xl:mt-0 2xl:mt-0 biglg:mt-0 bigmd:mt-0 md:mt-0">
              <div className="flex gap-1">
                <label
                  htmlFor="issueDate"
                  className="lg:text-[16px] md:text-[14px] text-[9px]  font-normal cursor-pointer"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {t("invoiceDate")}
                </label>
                {toolTipText && (
                  <div
                    className="relative mt-[0.3em] md:mt-[0.16em] biglg:mt-[0.3em] xl:mt-[0.3em]"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Tooltip />
                    {isHovered && (
                      <div className="absolute w-[204px] left-[-5.7em] z-10 biglg:mt-[2em]">
                        <div className="flex items-center justify-center">
                          <svg
                            width="18"
                            height="15"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 0L17.6603 15H0.339746L9 0Z"
                              fill="#FFF4C6"
                            />
                          </svg>
                        </div>
                        <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                          {toolTipText}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="">
                <InputComponent
                  type="date"
                  placeholder="Issue Date"
                  value={formData.issueDate}
                  name="issueDate"
                  handleChange={handleInputChange}
                  restrictFutureDates={true}
                  className="lg:w-[150px]"
                />
              </div>
              {validationResults.issueDate && (
                <div className="mt-2 ml-4">
                  <span className="text-red-500">
                    {validationResults.issueDate}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="mt-[13px] lg:mt-1.5 xl:mt-0 2xl:mt-0 biglg:mt-1.5 bigmd:mt-0 md:mt-0 md:ml-[-0.3em] xl:ml-0 biglg:ml-0 lg:mr-[-1em] bigmd:ml-0">
            <div className="flex gap-1 xl:pr-[5em]">
              <label
                htmlFor="invoiceDueAt"
                className="xl:text-[16px] text-[12px] md:text-[14px] xl:mt-0 lg:mt-[-0.2em] 375:ml-[1.7em] 414:ml-[0.3em] 390:ml-[1em] 412:ml-[0.6em] 360:ml-[1.7em] font-normal cursor-pointer"
              >
                {t("dueDate")}
              </label>

              {toolTipText2 && (
                <div
                  className="relative mt-[0.3em] biglg:mt-[-0.1em] xl:mt-[0.2em] md:mt-[0.16em]"
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
                >
                  <Tooltip />
                  {isHovered2 && (
                    <div className="absolute w-[204px] left-[-5.7em] z-10">
                      <div className="flex items-center justify-center">
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 0L17.6603 15H0.339746L9 0Z"
                            fill="#FFF4C6"
                          />
                        </svg>
                      </div>
                      <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                        {toolTipText2}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div>
              <InputComponent
                type="date"
                placeholder="Due Date"
                value={formData.invoiceDueAt}
                name="invoiceDueAt"
                handleChange={handleInputChange}
                restrictPreviousDates={false}
                className="lg:w-[150px]"
              />
            </div>
            {validationResults.invoiceDueAt && (
              <div className="mt-2 xl:text-[14px] lg:text-[12px] biglg:text-[12px] md:text-[11px] ">
                <span className="text-red-500">
                  {validationResults.invoiceDueAt}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="flex flex-col  mt-[3em] lg:hidden md:hidden justify-end items-end text-right ">
        <div className="flex flex-col gap-[6em]">
          <div>
            <div className="ml-[3em] lg:ml-0 xl:ml-0 2xl:ml-0 biglg:ml-0 bigmd:ml-0 md:ml-0 mt-[13px] lg:mt-0 xl:mt-0 2xl:mt-0 biglg:mt-0 bigmd:mt-0 md:mt-0">
              <div className="flex gap-1">
                <label
                  htmlFor="issueDate"
                  className="lg:text-[16px] text-[14px]  font-normal 375:ml-[3.3em] 414:ml-[4em] 390:ml-[3em] 430:ml-[] 412:ml-[0.6em] 360:ml-[2em]"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Invoice date:
                </label>
              </div>
              <div className="">
                <InputComponent
                  type="date"
                  placeholder="Issue Date"
                  value={formData.issueDate}
                  name="issueDate"
                  handleChange={handleInputChange}
                  restrictFutureDates={true}
                  className="w-[130px]"
                />
              </div>
              {validationResults.issueDate && (
                <div className="mt-2 ml-4">
                  <span className="text-red-500">
                    {validationResults.issueDate}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className=" lg:mt-0 xl:mt-0 2xl:mt-0 biglg:mt-0 bigmd:mt-0 md:mt-0 md:ml-[-0.3em] xl:ml-0 biglg:ml-0 lg:ml-0 bigmd:ml-0 mt-[-5.6em] ml-[3em]">
            <div className="flex flex-col">
              <label
                htmlFor="invoiceDueAt"
                className="lg:text-[16px] text-[13px] xl:mt-0 lg:mt-[-0.7em] 375:ml-[5em] 414:ml-[4.3em] 390:ml-[1em] 412:ml-[0.6em] 360:ml-[1.7em] font-normal"
              >
                {t("dueDate")}
              </label>
            </div>
            <div>
              <InputComponent
                type="date"
                placeholder="Due Date"
                value={formData.invoiceDueAt}
                name="invoiceDueAt"
                handleChange={handleInputChange}
                restrictPreviousDates={false}
                className="w-[130px]"
              />
            </div>
            {validationResults.invoiceDueAt && (
              <div className="mt-2 text-[9px]">
                <span className="text-red-500">
                  {validationResults.invoiceDueAt}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceDate;
