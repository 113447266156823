import React, { useState } from "react";
import { CloseIcon } from "../../assets/icons";

interface PrivacyPolicyPromptProps {
  onClose?: () => void;
  setShowModal: (modal: boolean) => void;
}

export const PrivacyPolicy: React.FC<PrivacyPolicyPromptProps> = ({
  onClose,
  setShowModal,
}) => {
  const handleTerm = () => {
    onClose && onClose();
  };

  const array = [
    {
      title: "Personal Data:",
      copies:
        "This includes your name, email address, phone number, and location data. We collect this information when you register and use our services on our app.",
    },
    {
      title: "Usage Data:",
      copies:
        "We collect information on how the services are accessed and used. This data may include your computer's Internet Protocol (IP) address, browser type, browser version, the pages of our app, the time and date of your visit, the time spent on those pages, and other diagnostic data.",
    },
    {
      title: "Cookies and Tracking Data:",
      copies:
        "We use cookies and similar tracking technologies to track activity on our app and hold certain information.",
    },
  ];

  const array2 = [
    {
      title: "With Service Providers:",
      copies:
        "We may share your personal information with providers who perform services for us and help us operate our business.",
    },
    {
      title: "For Legal Requirements:",
      copies:
        "We may disclose your personal information where required by law or subpoena.",
    },
    {
      title: "With Affiliates:",
      copies:
        "We may share your data with our affiliates, in which case we will require those affiliates to honour this privacy policy.",
    },
  ];

  const array1 = [
    "To provide and maintain our service",
    "To notify you about changes to our service",
    "To allow you to participate in interactive features of our service when you choose to do so",
    "To provide customer support",
    "To gather analysis or valuable information so that we can improve our service",
    "To monitor the usage of our service",
    "To detect, prevent, and address technical issues",
  ];
  const array3 = [
    {
      title: "Access and Receive:",
      copies:
        "You can request to receive a copy of the personal data we hold about you.",
    },
    {
      title: "Correction",
      copies:
        "You can ask us to correct inaccurate or incomplete personal information.",
    },
    {
      title: "Deletion:",
      copies: "You can request that we delete your data.",
    },
    {
      title: "Restrict Processing:",
      copies:
        "You can request the restriction of processing of your personal data.",
    },
  ];

  return (
    <div className="fixed top-0 right-0 left-0 z-50 h-full items-start flex bg-gray-900 bg-opacity-50 rounded-lg shadow blur-background justify-center">
      <div className="lg:w-[900px] md:w-[600px] w-[350px] bg-white mt-[4em] shadow rounded-lg">
        <div className="w-full">
          <div
            className="py-[2em] px-[2em]"
            style={{ maxHeight: "65vh", overflowY: "auto" }}
          >
            <div className="flex justify-end">
              <div
                className="cursor-pointer"
                onClick={() => setShowModal(false)}
              >
                <CloseIcon />
              </div>
            </div>
            <div>
              <h1 className="text-[24px] lg:text-[40px] font-semibold text-[#212121]">
                Privacy Policy for{" "}
                <span className="text-[#044E97]">Esemie</span>
              </h1>
              <p className="text-[#4E4E4E] lg:text-[24px] mt-4 lg:mt-12">
                Effective Date: May 1, 2024
              </p>
            </div>

            <div>
              <h2 className="text-[18px] lg:text-[24px] font-semibold">
                1. Introduction
              </h2>
              <p className="mt-2 lg:text-[18px]">
                Welcome to Esemie. We are committed to protecting the privacy of
                all visitors to our website and app users. This Privacy Policy
                outlines our practices concerning collecting, using, and
                disclosing your information.
              </p>
            </div>

            <div>
              <h2 className="mt-[44px] text-[18px] lg:text-[24px] font-semibold">
                2. Information Collection
              </h2>
              <p className="text-[16px] font-semibold my-4 lg:text-[18px]">
                Types of Data Collected:
              </p>
              <div className="space-y-2 lg:text-[18px]">
                {array.map((arr, i) => (
                  <div className="flex items-start gap-4">
                    <div>
                      <div className="w-[6px] h-[6px] bg-black rounded-full mt-[10px] lg:mt-3"></div>
                    </div>
                    <div>
                      <p className="font-medium leading-[28px] lg:leading-8">
                        {arr.title}{" "}
                        <span className="font-normal">{arr.copies}</span>{" "}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-[36px] lg:text-[18px]">
                <p className="font-semibold">Methods of Collection:</p>
                <div className="mt-2">
                  <div className="flex items-start gap-3">
                    <div>
                      <div className="w-[6px] h-[6px] bg-black rounded-full mt-[10px] lg:mt-[13px]"></div>
                    </div>
                    <p className=" leading-[28px] lg:leading-8">
                      Directly from you when you provide it to us.
                    </p>
                  </div>
                  <div className="flex items-start gap-3 mt-1">
                    <div>
                      <div className="w-[6px] h-[6px] bg-black rounded-full mt-[12px]"></div>
                    </div>
                    <p className=" leading-[28px] lg:leading-8">
                      Automatically as you navigate through the site or use our
                      app.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h2 className="mt-[44px] text-[18px] lg:text-[24px] font-semibold">
                3. Use of Data
              </h2>
              <p className="text-[16px] lg:text-[18px] font-semibold my-3">
                We use the collected data for various purposes:
              </p>
              <div>
                {array1.map((arr, i) => (
                  <div className="flex items-start gap-3 lg:text-[18px]">
                    <div>
                      <div className="w-[6px] h-[6px] bg-black rounded-full mt-[10px] lg:mt-[13px]"></div>
                    </div>
                    <p className="leading-[28px] lg:leading-8">{arr}</p>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <h2 className="mt-[44px] text-[18px] lg:text-[24px] font-semibold">
                4. Data Sharing
              </h2>
              <p className="text-[16px] lg:text-[18px] font-semibold my-4">
                Sharing Details:
              </p>
              <div className="space-y-2">
                {array2.map((arr, i) => (
                  <div className="flex items-start gap-3">
                    <div>
                      <div className="w-[6px] h-[6px] bg-black rounded-full mt-[10px] lg:mt-[13px]"></div>
                    </div>
                    <div className="leading-[28px] lg:leading-[32px] font-medium text-[18px]">
                      {arr.title}{" "}
                      <span className="font-normal">{arr.copies}</span>{" "}
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-[36px]">
                <p className="font-semibold lg:text-[18px]">
                  International Transfer:
                </p>
                <div className="mt-2 lg:text-[18px] lg:leading-8">
                  Your information, including Personal Data, may be transferred
                  to — and maintained on — computers outside your state,
                  province, country or other governmental jurisdiction where the
                  data protection laws may differ from those of your
                  jurisdiction.
                </div>
              </div>
            </div>

            <div>
              <h2 className="mt-[44px] text-[18px] lg:text-[24px] font-semibold">
                5. Data Security
              </h2>
              <p className="text-[16px] lg:text-[18px] my-3">
                We take the security of your data seriously. We implement
                appropriate technical and organisational measures to protect
                your data from unauthorised access, alteration, disclosure, or
                destruction.
              </p>
            </div>

            <div>
              <h2 className="mt-[44px] text-[18px] lg:text-[24px] font-semibold">
                6. User Rights
              </h2>
              <p className="text-[16px] lg:text-[18px] font-semibold my-3">
                Under the NDPR, you have certain rights regarding your data:
              </p>
              <div>
                {array3.map((arr, i) => (
                  <div className="flex items-start gap-3">
                    <div>
                      <div className="w-[6px] h-[6px] bg-black rounded-full mt-[10px] lg:mt-[13px]"></div>
                    </div>
                    <div className="leading-[28px] lg:leading-[32px] lg:text-[18px]">
                      {arr.title} <span>{arr.copies}</span>{" "}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <h2 className="mt-[44px] text-[18px] lg:text-[24px] font-semibold">
                How to Exercise Rights:
              </h2>
              <p className="text-[16px] lg:text-[18px] mt-3 ">
                To exercise these rights, please get in touch with us at:
                <span className="text-[#044E97] underline ml-[4px]">
                  support@esemie.io
                </span>
              </p>
            </div>

            <div>
              <h2 className="mt-[44px] text-[18px] lg:text-[24px] font-semibold">
                7. Cookies and Tracking Technologies
              </h2>
              <p className="text-[16px] lg:text-[18px] my-3 leading-[28px] lg:leading-[32px]">
                We use cookies and similar tracking technologies to track the
                activity on our service and hold certain information. You can
                instruct your browser to refuse all cookies or to indicate when
                a cookie is being sent.
              </p>
            </div>
            <div>
              <h2 className="mt-[44px] text-[18px] lg:text-[24px] font-semibold">
                8. Data Retention
              </h2>
              <p className="text-[16px] lg:text-[18px] my-3">
                We retain your data only for as long as necessary for the
                purposes in this Privacy Policy. We will retain and use your
                Data to the extent required to comply with our legal
                obligations, resolve disputes, and enforce our legal agreements
                and policies.
              </p>
            </div>
            <div>
              <h2 className="mt-[44px] text-[18px] lg:text-[24px] font-semibold">
                9. Policy Updates
              </h2>
              <p className="text-[16px] lg:text-[18px] my-3">
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </p>
            </div>
            <div>
              <h2 className="mt-[44px] text-[18px] lg:text-[24px] font-semibold">
                10. Contact Information
              </h2>
              <p className="text-[16px] lg:text-[18px] my-3">
                If you have any questions about this Privacy Policy, please get
                in touch with us:
              </p>
            </div>

            <div className="mt-12 lg:mt-0 lg:text-[18px]">
              <p>
                Email:{" "}
                <a href="mailto:support@esemie.io" className="text-[#044E97] ">
                  support@esemie.io
                </a>
              </p>
              <p>
                Phone: <span className="">+234 8037889887</span>
              </p>
            </div>

            <div className="flex items-center justify-end mt-6 mb-4">
              <button
                onClick={handleTerm}
                className="bg-[#FF6702] text-[white] w-[375px] h-[48px] rounded-md"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
