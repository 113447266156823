import React, { useState, useEffect } from "react";
import { ICreateSalesData, ICustomer } from "../../../@types";
import { CloseIcon, CloseIconSmall, SearchIcon } from "../../../assets/icons";
import { useCustomerSearch } from "../../../hooks";
import { useCustomerStore } from "../../../state";
import { SearchInput } from "../../common";
import { CreateCustomerModal } from "./createCustomer";
import shallow from "zustand/shallow";
import { useTranslation } from "react-i18next";

interface CustomerInfoProps {
  handleSetCustomer: (selectedCustomer: ICustomer | null) => void;
  formData: ICreateSalesData;
  customer: ICustomer | null;
  onClose: () => void;
}

const SelectCustomer: React.FC<CustomerInfoProps> = ({
  handleSetCustomer,
  formData,
  customer,
  onClose,
}) => {
  const {
    data: customers,
    isSearching,
    handleSearch: handleCustomerSearch,
  } = useCustomerSearch();
  const [showCreateCustomer, setShowCreateCustomer] = useState(false);

  const handleAddNewCustomer = () => {
    setShowCreateCustomer(true);
  };
  const { t } = useTranslation("invoices");
  return (
    <>
      {/* Desktop */}
      <div className=" hidden lg:block md:block w-[480px] h-[440px] relative z-50">
        <button
          className="absolute top-2 right-2 "
          aria-label="Close modal"
          onClick={onClose}
        >
          <CloseIcon color="black" />
        </button>
        <h1 className="font-normal lg:text-[24px] text-[18px]">
          {t("selectCustomer")}
        </h1>
        <hr />
        <div className=" mt-6 md:mt-[3em] ">
          <SearchInput
            placeholder={t("searchCustomer")}
            data={customers.map((customer) => ({
              ...customer,
              display: `${customer.name}`,
            }))}
            displayKey="name"
            isLoading={isSearching}
            // value={formData.customerId !== null ? formData.customerId : ''}
            value={
              formData.customerId
                ? customers.find((cust) => cust.id === formData.customerId)
                    ?.name || ""
                : ""
            }
            selected={customer}
            onSelect={(selectedCustomer) =>
              handleSetCustomer(selectedCustomer as ICustomer)
            }
            handleChangeText={handleCustomerSearch}
            emptyMessage={"This customer is not found"}
            clearIcon={CloseIcon}
            clearIconClass="xl:mr-[1.5em] mr-[0.5em] md:mr-[3.2em] biglg:mr-[1.5em] lg:mr-[1em] biglg:mt-[0.6em] xl:mt-[-0.4em]"
            classNames="w-full border border-gray-300 bg-white max-h-[48px] min-h-[48px] placeholder:text-[15.25px] rounded  focus:outline-none focus:shadow-outline appearance-none focus:ring-0 leading-normal"
            dropdownClassName="w-full "
            dropdownStyle={{
              maxHeight: "200px",
              overflowY: "auto",
            }}
            searchKey={"name"}
            icon={<SearchIcon />}
          />
          <div className="flex justify-end mt-[15em] gap-5">
            <button
              className="text-[#044E97] border border-[#044E97] w-[150px] flex justify-center items-center text-center p-3 rounded-md font-semibold lg:mt-0 xl:mt-0 biglg:mt-0 mt-[1em] md:mt-[-0.3em]"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="text-[white] border bg-[#044E97] flex justify-center items-center text-center p-3 rounded-md font-semibold lg:mt-0 xl:mt-0 biglg:mt-0 mt-[1em] md:mt-[-0.3em]"
              onClick={handleAddNewCustomer}
            >
              {t("addNewCustomer")}
            </button>
          </div>
        </div>
        {showCreateCustomer && (
          <CreateCustomerModal
            showSideModal={showCreateCustomer}
            setShowSideModal={setShowCreateCustomer}
            handleSetCustomer={handleSetCustomer}
            onCustomerCreate={(customer) => {
              handleSetCustomer(customer);
              setShowCreateCustomer(false);
            }}
            onClose={() => setShowCreateCustomer(false)}
          />
        )}
      </div>

      {/* Mobile */}
      <div className="relative">
        <div className="lg:hidden md:hidden fixed bg-white w-[full] h-[491px] bottom-0 right-0 left-0 rounded-t-[2em]">
          <button
            className="absolute top-2 right-2 mt-[2em]  shadow-sm py-[0.2em] bg-[#F4F7FF] pr-4"
            aria-label="Close modal"
            onClick={onClose}
          >
            <CloseIcon color="black" />
          </button>
          <h1 className="font-normal lg:text-[25px] text-[18px] mt-[2em] pl-[2em]">
            Select A Customer
          </h1>
          <div className=" mt-6 flex flex-col justify-center items-center text-center">
            <SearchInput
              placeholder="Search for Customer"
              data={customers.map((customer) => ({
                ...customer,
                display: `${customer.name}`,
              }))}
              displayKey="name"
              isLoading={isSearching}
              value={formData.customerId !== null ? formData.customerId : ""}
              selected={customer}
              onSelect={(selectedCustomer) =>
                handleSetCustomer(selectedCustomer as ICustomer)
              }
              handleChangeText={handleCustomerSearch}
              emptyMessage={"This customer is not found"}
              clearIcon={CloseIcon}
              clearIconClass="xl:mr-[1em] mr-[0.5em] md:mr-[3.2em] biglg:mr-[2em] lg:mr-[1em]"
              classNames="w-[300px] border border-gray-300 bg-white max-h-[48px] min-h-[48px] placeholder:text-[15.25px] rounded  focus:outline-none focus:shadow-outline appearance-none focus:ring-0 leading-normal"
              dropdownClassName="w-[300px]"
              dropdownStyle={{
                maxHeight: "800px",
                overflowY: "auto",
              }}
              searchKey={"name"}
              icon={<SearchIcon />}
            />
            <div className="flex mt-[15em] gap-2">
              <button
                className="text-[#044E97] border border-[#044E97] w-[150px]  flex justify-center items-center text-center p-3 rounded-md font-semibold lg:mt-0 xl:mt-0 biglg:mt-0 mt-[1em] text-[12px]"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="text-[white] border bg-[#044E97] w-[150px]  flex justify-center items-center text-center p-3 rounded-md font-semibold lg:mt-0 xl:mt-0 biglg:mt-0 mt-[1em]  text-[12px]"
                onClick={handleAddNewCustomer}
              >
                {t("addNewCustomer")}
              </button>
            </div>
          </div>
          <CreateCustomerModal
            showSideModal={showCreateCustomer}
            setShowSideModal={setShowCreateCustomer}
            handleSetCustomer={handleSetCustomer}
          />
        </div>
      </div>
    </>
  );
};

export default SelectCustomer;
