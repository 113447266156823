import React from "react";

const Transfer = ({ color = "" }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill="#E0EEFE" />
        <g clip-path="url(#clip0_49913_23703)">
          <circle
            cx="12.0013"
            cy="11.9993"
            r="5.83333"
            stroke="#044E97"
            stroke-width="1.2"
          />
          <ellipse
            cx="12.0013"
            cy="11.9993"
            rx="2.33333"
            ry="5.83333"
            stroke="#044E97"
            stroke-width="1.2"
          />
          <path
            d="M6.16797 12H17.8346"
            stroke="#044E97"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_49913_23703">
            <rect
              width="14"
              height="14"
              fill="white"
              transform="translate(5 5)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default Transfer;
