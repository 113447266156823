import React from "react";
import { BankData } from "../../../types";
import { BankAccountForm } from "./BankAccountForm";
import ErrorComponent from "../../Error";

interface CreateProps {
  onCreate: (data: BankData) => void;
  showButton: boolean;
  isLoading: boolean;
  onClose?: () => void;
}

const CreateBankAccount: React.FC<CreateProps> = ({
  onCreate,
  onClose,
  isLoading,
}) => {
  const handleCreate = async (data: BankData) => {
    try {
      onCreate(data);
    } catch (error) {
      return <ErrorComponent errorCode={500} />;
    } finally {
    }
  };

  return (
    <>
      <BankAccountForm
        initialData={{
          bankName: "",
          accountNumber: "",
          accountName: "",
          isActive: true,
          currency: "NGN",
        }}
        title="Add Bank Account"
        onSubmit={handleCreate}
        onClose={onClose}
        isLoading={isLoading}
      />
    </>
  );
};

export default CreateBankAccount;
