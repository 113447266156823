import React from "react";
import { ICreateSalesData } from "../../../../@types";
import { formatMoney } from "../../../../utils";
import { useTranslation } from "react-i18next";

interface SalesCalcsProps {
  subTotal: number;
  formData: ICreateSalesData;
  calcDiscount: number;
  calcTax: number;
  total: number;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InvoiceCalculation: React.FC<SalesCalcsProps> = ({
  subTotal,
  formData,
  calcDiscount,
  calcTax,
  total,
  handleInputChange,
}) => {
  const { t } = useTranslation("invoices");
  return (
    <>
      {/* Desktop */}
      <div className="hidden lg:block md:block mt-[5em]">
        <div className="flex justify-between text-[18px] ml-[-2em] mt-[1em]  pl-[1em] gap-[9em] ">
          <p className="pl-3">Sub-Total:</p>
          <p className="">{formatMoney(subTotal, formData.currency)}</p>
        </div>
        <div className="flex justify-between text-[18px] ml-[-2em] mt-[1em]  pl-[1em] gap-[9em] ">
          <div className="flex ">
            <p className="pl-3 mt-[0.2em]">{t("discount")}:</p>
            <span className="flex">
              <div className="relative w-[76px] ml-[1em] h-[40px]">
                <input
                  type="number"
                  value={formData?.discount || ""}
                  name="discount"
                  onChange={handleInputChange}
                  className="w-full h-full pr-4 border-gray-300 rounded-lg"
                />
                <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-black text-[16px] font-semibold">
                  %
                </span>
              </div>
            </span>
          </div>
          <div className="flex gap-1  pl-3 py-2 h-[40px] mt-[]">
            <p className="">
              {formatMoney(calcDiscount.toFixed(2), formData.currency)}
            </p>
          </div>
        </div>
        <div className="flex justify-between text-[18px] ml-[-2em] mt-[1em]  pl-[1em] gap-[9em] ">
          <div className="flex">
            <p className="pl-3 mt-[0.2em]"> VAT:</p>
            <span className="flex">
              <div className="relative w-[76px] ml-[3.2em] h-[40px]">
                <input
                  type="number"
                  value={formData?.tax || ""}
                  name="tax"
                  onChange={handleInputChange}
                  className="w-full h-full pr-4 border-gray-300 rounded-lg"
                />
                <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-black text-[16px] font-semibold">
                  %
                </span>
              </div>
            </span>
          </div>
          <div className="flex gap-1 pl-3 py-2  h-[40px] mt-[]">
            <p className="">
              {" "}
              {formatMoney(calcTax.toFixed(2), formData.currency)}
            </p>
          </div>
        </div>
        <div className="flex justify-between text-[18px] ml-[-2em] mt-[1em]  pl-[1em] gap-[9em] font-semibold shadow-sm bg-[#DBE7FF] pr-[1em] py-2 text-[#044E97]">
          <p className="pl-3">Total(NGN):</p>
          <p className="">{formatMoney(total.toFixed(2), formData.currency)}</p>
        </div>
      </div>
      {/* Mobile */}
      <div className="lg:hidden md:hidden mt-[4em] text-[14px] pl-[1em]">
        <div className="flex justify-between text-[14px] gap-[8em] ">
          <p className=" pl-1">Sub Total:</p>
          <p className="">{formatMoney(subTotal, formData.currency)}</p>
        </div>
        <div className="flex justify-between text-[14px] 320:ml-[-1.5em] ml-[-2em] mt-[1em]  pl-[1em] gap-[7em] ">
          <div className="flex ">
            <p className="pl-3">{t("discount")}:</p>
            <span className="flex">
              <div className="relative w-[76px] ml-[1em] h-[40px]">
                <input
                  type="number"
                  value={formData?.discount || ""}
                  name="discount"
                  onChange={handleInputChange}
                  className="w-full h-full pr-4 border-gray-300 rounded-lg"
                />
                <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-black font-semibold">
                  %
                </span>
              </div>
            </span>
          </div>
          <div className="flex gap-1  pl-3 py-2 h-[40px] mt-4">
            <p className="">
              {formatMoney(calcDiscount.toFixed(2), formData.currency)}
            </p>
          </div>
        </div>
        <div className="flex justify-between text-[14px] 320:ml-[-1.5em] ml-[-2em] mt-[1em]  pl-[1em] gap-[7em] ">
          <div className="flex">
            <p className="pl-3"> VAT:</p>
            <span className="flex">
              <div className="relative w-[76px] ml-[3em] h-[40px]">
                <input
                  type="number"
                  value={formData?.tax || ""}
                  name="tax"
                  onChange={handleInputChange}
                  className="w-full h-full pr-4 border-gray-300 rounded-lg"
                />
                <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-black font-semibold">
                  %
                </span>
              </div>
            </span>
          </div>
          <div className="flex gap-1 pl-3 py-2  h-[40px] mt-4">
            <p className="">
              {" "}
              {formatMoney(calcTax.toFixed(2), formData.currency)}
            </p>
          </div>
        </div>
        <hr className="mt-2 w-100%" />
        <div className="bg-[#DBE7FF] px-[1em] py-[0.4em]">
          <div className="flex justify-between text-[14px]  ml-[-2em] mt-[em]  pl-[1em] gap-[9em] font-semibold text-[#044E97] ">
            <p className="pl-3">Total(NGN):</p>
            <p className="">
              {formatMoney(total.toFixed(2), formData.currency)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceCalculation;
