import {
  createBankAccount,
  editBankAccount,
  updatePayoutAccount,
} from "../../../backend-services/bank";
import React, { useEffect, useState } from "react";
import { BankData } from "../../../types";
import CreateBankAccount from "./create";
import toast from "react-hot-toast";
import { BankAccountView } from "./bankAccountView";
import { handleErrors } from "../../../utils/handleErrors";
import { useUserStore } from "../../../state";
import PageLoader from "../../common/PageLoader";
import SuccessBanner from "../../common/Banner/SuccessBanner";
import { EditBankAccount } from "./edit";
import { Button } from "../../common";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "../../../state/useCompanyStore";

interface Props {
  submenuTab: string;
  onBack: () => void;
  bankSubmenuTab: string;
  onClose?: () => void;
}

const BankAccountManager: React.FC<Props> = ({
  submenuTab,
  onBack,
  onClose,
}) => {
  const { t } = useTranslation("settings");
  const [bankAccounts, setBankAccounts] = useState<BankData[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const { companyBankAccounts ,setCompanyBankAccounts } = useCompanyStore()
  const [createSuccess, setCreateSuccess] = useState(false);

  useEffect(() => {
    setBankAccounts(companyBankAccounts);
  }, [companyBankAccounts]);

  useEffect(() => {
    if (createSuccess) {
      const timer = setTimeout(() => {
        setCreateSuccess(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [createSuccess]);

  const handleCreateBankAccount = async (formData: BankData) => {
    try {
      setIsLoading(true);
      const newBankAccount = await createBankAccount({
        ...formData,
        currency: "NGN",
        isActive: true,
      });
      setCompanyBankAccounts(newBankAccount?.data);
      setCreateSuccess(true);
      setShowCreateForm(false);
      setIsLoading(false);
    } catch (error) {
      handleErrors(error, setErrorMessage);
      toast.error("Unable to create Bank Account. Please try again");
    }
  };

  const handleToggleActive = async (index: number) => {
    try {
      const updatedBankAccounts = [...bankAccounts];
      const bankAccount = { ...updatedBankAccounts[index] };
      bankAccount.isActive = !bankAccount.isActive;
      const {
        id,
        bankName,
        accountName,
        accountNumber,
        currency,
        isActive,
        settlement_bank,
      } = bankAccount;
      const updatedBankAccount = {
        id,
        bankName,
        accountName,
        accountNumber,
        currency,
        isActive,
        settlement_bank,
      };

      updatedBankAccounts[index] = updatedBankAccount;
      setBankAccounts(updatedBankAccounts);
      const response = await editBankAccount(index, updatedBankAccount);
      const updatedUserBankAccounts = response?.bankAccounts;
      setCompanyBankAccounts(updatedUserBankAccounts);
    } catch (error) {
      handleErrors(error, setErrorMessage);
    }
  };

  const handleTogglePayout = async (index: number) => {
    try {
      const updatedBankAccounts = [...bankAccounts];
      const bankAccount = { ...updatedBankAccounts[index] };
      bankAccount.isPayoutAccount = !bankAccount.isPayoutAccount;
      const {
        id,
        bankName,
        accountName,
        accountNumber,
        currency,
        isActive,
        settlement_bank,
        isPayoutAccount,
      } = bankAccount;
      const updatedBankAccount = {
        id,
        bankName,
        accountName,
        accountNumber,
        currency,
        isActive,
        settlement_bank,
        isPayoutAccount,
      };

      updatedBankAccounts[index] = updatedBankAccount;
      setBankAccounts(updatedBankAccounts);
      await updatePayoutAccount(index, bankAccount.isPayoutAccount);
      setCompanyBankAccounts(updatedBankAccounts);
    } catch (error) {
      handleErrors(error, setErrorMessage);
    }
  };

  const handleAddNewAccount = () => {
    setShowCreateForm(true);
  };

  const handleEditAccount = (index: number) => {
    setEditIndex(index);
  };

  const handleEditSuccess = () => {
    setEditIndex(null);
  };

  return (
    <>
      {createSuccess && (
        <SuccessBanner message={t("Settings.bankaccounthasbeensaved")} />
      )}
      <div className="bg-[#FFFFFF] sm:w-full min-h-screen max-h-full rounded-lg p-[5.7%] md:px-10 md:pt-10 pb-[100px] overflow-auto">
        <div className="flex justify-between items-end">
          <div>
            <h1 className="hidden md:block text-[16px] lg:text-[20px] font-semibold">
              Bank Account
            </h1>
            <p className="text-[16px] font-medium">
              {t("Settings.addpaymentlinks")}
            </p>
            <p
              className="hidden md:mt-2 md:text-[16px]"
              style={{ padding: "0", lineHeight: "1.2" }}
            >
              {t("Settings.addpaymentlinks")}
            </p>
          </div>
          <button
            onClick={handleAddNewAccount}
            type="button"
            className="bg-[#044E97] hidden md:block w-[180px] lg:w-[210px] h-12 rounded font-semibold text-white text-[14px] lg:text-[16px]"
          >
            Add New Bank Account
          </button>
        </div>

        <hr className="mt-[10px] md:mt-[1.5em] mb-[30px] lg:w-full border border-[#000000] border-opacity-25" />
        {bankAccounts && bankAccounts.length > 0 && (
          <div>
            <div className="grid grid-cols-1 bigmd:grid-cols-2 lg:grid-cols-2 gap-4 md:gap-x-[2.5%] md:gap-y-[2%]">
              {bankAccounts.map((bankAccount, index) => (
                <BankAccountView
                  key={bankAccount.id}
                  accountIndex={index}
                  bankAccount={bankAccount}
                  onToggleActive={() => handleToggleActive(index)}
                  onTogglePayout={() => handleTogglePayout(index)}
                  onEdit={() => handleEditAccount(index)}
                />
              ))}
            </div>
            <div className="mt-8 md:hidden">
              <Button
                text="Add New Bank Account"
                font="semibold"
                variant="secondary"
                onClick={handleAddNewAccount}
                size="full"
              />
            </div>
          </div>
        )}

        {showCreateForm && (
          <CreateBankAccount
            onCreate={handleCreateBankAccount}
            showButton={true}
            onClose={() => setShowCreateForm(false)}
            isLoading={isLoading}
          />
        )}
      </div>
      {editIndex !== null && (
        <EditBankAccount
          bankAccount={bankAccounts[editIndex]}
          onEditSuccess={handleEditSuccess}
          accountIndex={editIndex}
          setCreateSuccess={setCreateSuccess}
          cancelEdit={() => setEditIndex(null)}
        />
      )}
    </>
  );
};

export default BankAccountManager;
