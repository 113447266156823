import React from "react";

const ToggleBar = () => {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 13L1.53349 7.64018C1.2784 7.33408 1.15085 7.18102 1.15085 7C1.15085 6.81898 1.2784 6.66592 1.53349 6.35982L6 1"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13 13L8.53349 7.64018C8.2784 7.33408 8.15085 7.18102 8.15085 7C8.15085 6.81898 8.2784 6.66592 8.53349 6.35982L13 1"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export { ToggleBar };
