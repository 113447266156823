import React, { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from "../../assets/icons/Tooltip";
import SubscriptionMarkDone from "../../assets/icons/SubscriptionMarkDone";
import { formatMoney } from "../../utils";
import { Button } from "../common";
import { useTranslation } from "react-i18next";
interface PricingPlanProps {
  title: string;
  duration: string;
  price: number;
  subPrice?: number;
  features: { name: string; tooltip?: string }[];
  buttonText: string;
  index: number;
  plan: string;
  show: (plan: string) => void;
}

const PricingPlan: React.FC<PricingPlanProps> = ({
  title,
  price,
  features,
  buttonText,
  index,
  show,
  plan,
  duration,
  subPrice,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const { t } = useTranslation("subscription");
  return (
    <div className="bg-white rounded-lg shadow-lg px-[18px] pt-[52px] pb-9 w-[317px] h-[876px] flex flex-col justify-between relative">
      <div>
        <div className="text-white flex flex-row items-center justify-center">
          <SubscriptionMarkDone />
          <p className="text-[#2B2B2B] text-[20px]" style={{ fontWeight: 700 }}>
            {title}{" "}
            <span
              className="text-[#979797]"
              style={{ fontWeight: 400, marginLeft: "3px" }}
            >
              Plan
            </span>{" "}
          </p>
        </div>
        <ul className="space-y-[19px] mt-[47px] ">
          {features.map((feature, index) => (
            <li key={index} className=" flex items-center gap-[25px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
              >
                <path
                  d="M9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25329 14.7467 0 9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19Z"
                  fill="white"
                />
                <path
                  d="M9.5 18.5C14.4706 18.5 18.5 14.4706 18.5 9.5C18.5 4.52944 14.4706 0.5 9.5 0.5C4.52944 0.5 0.5 4.52944 0.5 9.5C0.5 14.4706 4.52944 18.5 9.5 18.5Z"
                  stroke="#5064CC"
                />
                <path
                  d="M4.49982 10.0498L7.23615 13.2349L14.1815 6.5"
                  stroke="#5064CC"
                />
              </svg>
              <div className="flex items-center gap-1 text-[#2B2B2B]">
                <p className="text-[13px] xl:text-[16px] leading-[19.2px] font-normal text-[#2B2B2B]">
                  {feature.name}
                </p>
                {feature.tooltip && (
                  <div
                    className="cursor-pointer relative"
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <Tooltip />
                    {hoveredIndex === index && (
                      <div className="absolute w-[204px] z-20 left-[-5.7em]">
                        <div className="flex items-center justify-center">
                          <svg
                            width="18"
                            height="15"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 0L17.6603 15H0.339746L9 0Z"
                              fill="#FFF4C6"
                            />
                          </svg>
                        </div>
                        <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                          {feature.tooltip}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="text-center space-y-[24px]">
        <p className=" text-[#979797] lg:text-[16px]">
          <span
            style={{ fontWeight: 600 }}
            className="lg:text-[25px] text-[#000]"
          >
            {formatMoney(price)}
          </span>
          {/* @ts-ignore */}
          <span className="lg:text-[14px]  line-through pl-2">
            {" "}
            {formatMoney(subPrice)}
          </span>
          <span className="lg:text-[14px]">{duration} </span>
        </p>

        <div className="text-[14px] w-full flex justify-center md:text-[16px]">
          <Button
            onClick={() => show(plan)}
            isLoading={false}
            size="medium"
            variant={`${index % 2 === 0 ? "secondary" : "primary"}`}
            disabled={index === 0}
          >
            {buttonText}
          </Button>
        </div>
      </div>

      {index === 1 && (
        <div className="absolute left-[-2.5em] bottom-[-3.5em] z-10">
          <div className="flex items-center justify-center">
            <svg
              width="18"
              height="15"
              viewBox="0 0 18 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#FFF4C6" />
            </svg>
          </div>
          <div className="h-[59px] w-[394px] bg-[#FFF4C6] rounded-[12px] text-[#5B5B5B] font-medium leading-[19.2px] flex justify-center items-center">
            {t("subscription.mostPopular")}
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingPlan;
