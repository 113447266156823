import * as React from "react";
import { useInView } from "react-intersection-observer";
import { Loading } from "./Table/Loading";
import CardList from "./Table/MobileTableComponent/CardList";

export type HeadingType = {
  title?: string;
  icon?: any;
  onClick?: () => void;
};

export type TableItem = {
  [key: string]:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
};

const MobileTableBody = ({
  data,
  head,
  isLoading,
  isLoadingFilter,
  showMobileInvoiceTable,
  showMobileServiceTable,
  CustomerCard,
  expenseCard,
  invoiceCard,
  quoteCard,
  serviceCard,
  payHistoryTable,
  inventoryCard,
  variationCard,
  restockCard,
  removeStockCard,
  archiveCard,
  userCard,
  fetchMoreData,
  modules,
}: {
  data: TableItem[] | any;
  head?: { [head: string]: HeadingType };
  isLoading: boolean;
  isLoadingFilter?: boolean;
  showMobileInvoiceTable?: boolean | undefined;
  showMobileServiceTable?: boolean | undefined;
  CustomerCard?: boolean;
  expenseCard?: boolean | undefined;
  invoiceCard?: boolean | undefined;
  quoteCard?: boolean | undefined;
  serviceCard?: boolean | undefined;
  payHistoryTable?: boolean | undefined;
  inventoryCard?: boolean | undefined;
  variationCard?: boolean | undefined;
  restockCard?: boolean | undefined;
  removeStockCard?: boolean | undefined;
  archiveCard?: boolean | undefined;
  userCard?: boolean | undefined;
  fetchMoreData?: (direction: "up" | "down") => void | undefined;
  modules?: string;
}) => {
  return (
    <>
      {showMobileInvoiceTable && (
        // Render Invoice Table
        <tbody className="table  grid grid-cols-1 border-1 pr-[2em]">
          <tr className="flex justify-between">
            <td className="px-[1em]">
              <span className="text-black text-[12px] font-normal">
                {head && head["name"].title}
              </span>
            </td>
            <td className="px-[1em]">
              <span className="text-black text-[12px] font-normal">
                {head && head["quantity"].title}
              </span>
            </td>
            <td className="px-[1em]">
              <span className="text-black text-[12px] font-normal">
                {head && head["amount"].title}
              </span>
            </td>
          </tr>
          {Object.entries(data).map((key: any, i) => (
            <tr key={i} className="flex justify-between">
              <td className="px-[1em]">
                <span className="font-light text-[#000000] not-italic text-[11px] placeholder:#cccleading-6">
                  {key[1].name}
                </span>
              </td>
              <td className="">
                <span className="font-light text-[#000000] not-italic text-[11px] leading-6">
                  {key[1].quantity}
                </span>
              </td>
              <td className="px-[1em]">
                <span className="font-light text-[#000000] not-italic text-[11px] leading-6">
                  {key[1].amount}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      )}

      {showMobileServiceTable && (
        // Render Service Table
        <>
          <tbody className="table  grid grid-cols-1 border-1">
            <tr className="flex justify-between ">
              <td className="px-[1em]">
                <span className="text-black text-[12px] font-normal">
                  {head && head["name"].title}
                </span>
              </td>
              <td className="px-[1em]">
                <span className="text-black text-[12px] font-normal">
                  {head && head["duration"]?.title}
                </span>
              </td>
              <td className="px-[1em]">
                <span className="text-black text-[12px] font-normal">
                  {head && head["price"]?.title}
                </span>
              </td>
            </tr>
            {Object.entries(data).map((key: any, i) => (
              <tr key={i} className="flex justify-between">
                <td className="px-[1em]">
                  <span className="font-light text-[#000000] not-italic text-[11px] placeholder:#cccleading-6">
                    {key[1].name}
                  </span>
                </td>
                <td className="">
                  <span className="font-light text-[#000000] not-italic text-[11px] leading-6">
                    {key[1].duration}
                  </span>
                </td>
                <td className="px-[1em]">
                  <span className="font-light text-[#000000] not-italic text-[11px] leading-6">
                    {key[1].price}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </>
      )}

      <div>
        <CardList
          payHistoryCard={payHistoryTable}
          CustomerCard={CustomerCard}
          expenseCard={expenseCard}
          invoiceCard={invoiceCard}
          quoteCard={quoteCard}
          serviceCard={serviceCard}
          data={data}
          fetchMoreData={fetchMoreData}
          inventoryCard={inventoryCard}
          variationCard={variationCard}
          restockCard={restockCard}
          removeStockCard={removeStockCard}
          archiveCard={archiveCard}
          isLoading={isLoading}
          isLoadingFilter={isLoadingFilter}
          userCard={userCard}
          modules={modules}
        />
      </div>
    </>
  );
};

export { MobileTableBody };
