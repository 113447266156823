import React from "react";

interface ModalProps {
  isOpen?: boolean;
  onCancel?: () => void;
  onAction?: () => void;
  title?: string;
  children?: React.ReactNode;
  actionText?: string;
}

const RectangleModal: React.FC<ModalProps> = ({
  isOpen,
  onAction,
  title,
  children,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
      <div className="bg-white lg:w-[600px] md:w-[450px] h-[184px] w-[300px] rounded-lg shadow-lg p-4">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">{title}</h2>
        </div>
        <div className="mt-4">
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default RectangleModal;
