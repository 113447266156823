import * as React from "react";
import cx from "classnames";
import { CloseIcon } from "../../../assets/icons";

export interface ITabItem {
  id?: string;
  title: string;
  component: any;
}

interface TabProps {
  items: ITabItem[];
  initialTabIndex?: number;
  componentContainerClass?: string;
  containerClass?: string;
  tabContainerClass?: string;
  onChangeTab?: (item: ITabItem) => void;
}

const Tab: React.FC<TabProps> = ({
  items,
  initialTabIndex = 0,
  onChangeTab,
  containerClass,
  componentContainerClass,
  tabContainerClass,
}) => {
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  React.useEffect(() => {
    if (initialTabIndex <= items.length - 1) {
      setActiveTab(initialTabIndex);
    }
  }, [initialTabIndex, items.length]);

  const handleTabClick = (item: ITabItem, index: number) => {
    setActiveTab(index);
    onChangeTab && onChangeTab(item);
  };

  const handleClose = () => {
    setIsOpen(false); // Close the tab
  };

  if (!isOpen) {
    return null; // If not open, render nothing
  }

  return (
    <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh] flex items-end md:items-center justify-center">
      <div
        className={cx(
          "overflow-y-auto overflow-x-hidden fixed md:top-[10%] w-full md:w-[500px] lg:w-[538px] p-6 bg-white",
          "rounded-t-[16px] rounded-b-[16px] shadow-md", // Added rounded corners for both top and bottom
        )}
      >
        <div className="flex mb-4 justify-between">
          <p className="text-[18px] font-semibold">Record Payment</p>
          <button onClick={handleClose} className="">
            <CloseIcon />
          </button>
        </div>
        <ul
          className={cx(
            "flex border rounded-[5px] p-[3px] bg-[#F9FAFB] h-[48px] mb-4",
            tabContainerClass,
          )}
        >
          {items.map((item, index) => (
            <li
              key={`tab-${index}`}
              className={cx(
                "cursor-pointer h-full flex-1 flex items-center justify-center font-medium rounded-[3.31px] px-4",
                {
                  "bg-white": activeTab === index,
                  "bg-transparent": activeTab !== index,
                },
              )}
              onClick={() => handleTabClick(item, index)}
            >
              {item.title}
            </li>
          ))}
        </ul>
        <React.Suspense fallback="Loading...">
          <div className={cx("mt-2", componentContainerClass)}>
            {items[activeTab].component}
          </div>
        </React.Suspense>
      </div>
    </div>
  );
};

export { Tab };
