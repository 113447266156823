import { useState, useEffect } from "react";
import { ServiceStatsData } from "../../@types/service";
import { getServiceStats } from "../../backend-services";

export function useServiceStatsData(startDate: Date, endDate: Date) {
  const [serviceStats, setServiceStats] = useState<ServiceStatsData | null>(
    null,
  );
  const [error, setError] = useState<string | null>(null);
  const fetchData = async () => {
    const start = startDate.toISOString();
    const end = endDate.toISOString();

    try {
      const response = await getServiceStats(start, end);
      const data: ServiceStatsData = {
        totalServices: response.totalServices,
        totalBookedServices: response.totalBookedServices,
        mostRequestedService: response.mostRequestedService,
        totalNewServices: response.totalNewServices,
        dateRange: `${startDate.toDateString()} - ${endDate.toDateString()}`,
      };
      setServiceStats(data);
      setError(null);
    } catch (error) {
      setError("Failed to fetch service statistics");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return { serviceStats, error };
}
