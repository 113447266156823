import React from "react";
import { ICreateExpenseData } from "../../@types";
import ExpenseParent from "../../components/expense/ExpenseCreate/ExpenseParent";

const CreateNewExpensePage = () => {
  const [expense, setExpense] = React.useState<ICreateExpenseData | null>();
  return (
    <>
      <ExpenseParent expense={expense as ICreateExpenseData} />
    </>
  );
};

export { CreateNewExpensePage };
