// The type for a single customer data
import { useSearch } from "../useSearch";
import { customerService } from "../../services";
import { ICustomer } from "../../@types";

export const useCustomerSearch = () => {
  return useSearch({
    service: customerService,
    endpoint: "customers/search",
    queryParameter: "q",
    responseMapper: (data: any[]): any =>
      data.map((cu: any) => ({
        ...cu,
        id: cu.id,
        name: `${cu.firstName} ${cu.lastName}`,
      })) as unknown as ICustomer,
  });
};
