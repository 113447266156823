import { useNavigate } from "react-router-dom";
import RecButton from "../../assets/icons/svgicons/RecButton.svg";
import smallRecBottom from "../../assets/icons/svgicons/smallRecBottom.svg";
import midRecBottom from "../../assets/icons/svgicons/midRecBottom.svg";
import React, { useEffect, useState } from "react";
import { updateUserSettings } from "../../backend-services";
import { useUserStore } from "../../state";
import TagManager from "react-gtm-module";
import { EventDataLayerArgs } from "../../@types/onboarding";
import MobileProtipsModal from "../../components/common/JoyRide/MobileProtipsModal";
import { CreateModal } from "../../components/common/CreateModal";
import BusinessTypeSelector from "../../components/auth/BusinessTypeModal";
import { Modal } from "../../components/common/Modal";
import { useCompanyStore } from "../../state/useCompanyStore";

const WelcomePage = () => {
  const setUser = useUserStore((state) => state.setUser);
  const fetchUserProfile = useCompanyStore((state) => state.fetchCompanyProfile);
  const [businessModalVisible, setBusinessModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    updateUserSettings({ firstLogin: true })
      .then(() => {
        fetchUserProfile().then((updatedUser) => {
          setUser(updatedUser);

          if (!updatedUser.businessType) {
            setBusinessModalVisible(true);
          }
        });
      })
      .catch((error) => {
        throw new Error(`${error}`);
      });

    const eventData: { dataLayer: EventDataLayerArgs[] } = {
      dataLayer: [
        {
          event: "conversion",
          category: "Signup",
          action: "View",
          label: "Signup Success",
        },
      ],
    };
    TagManager.dataLayer(eventData);
  }, [setUser, fetchUserProfile]);

  const isMobile = window.innerWidth < 768;

  return (
    <>
      <div className="absolute z-10">
        <MobileProtipsModal />
      </div>
      <div>
        <Modal
          visible={businessModalVisible}
          onClose={() => setBusinessModalVisible(false)}
        >
          <BusinessTypeSelector
            onClose={() => setBusinessModalVisible(false)}
          />
        </Modal>
      </div>
      <div className="relative flex flex-col  min-h-[85vh] justify-center items-center margin-0  md:min-h-[45vh] lg:min-h-[35vh] xl:min-h-[75vh]">
        <div className="mt-[3em] hidden lg:block md:hidden">
          <iframe
            width="880"
            height="315"
            src="https://www.youtube.com/embed/E5H9ZYaWAEM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="mt-[3em] hidden md:block lg:hidden  ">
          <iframe
            width="648"
            height="400"
            src="https://www.youtube.com/embed/E5H9ZYaWAEM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="mt-[3em] block lg:hidden md:hidden">
          <iframe
            width="300"
            height="300"
            src="https://www.youtube.com/embed/E5H9ZYaWAEM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="text-center w-full flex flex-col justify-center items-center">
          <h1 className="text-[25px] mt-4 mb-4 font-bold text-[#000000]">
            Let’s get started!{" "}
          </h1>
          <p className="font-normal text-[16px] w-[300px] lg:w-full text-[#000000]">
            Start managing your business. What will you like to do ?{" "}
          </p>
        </div>

        <div className="w-[120%] lg:w-auto">
          <div className="flex mx-auto mt-8 gap-4 justify-center items-center">
            <button
              onClick={(e) => navigate("/invoices/create")}
              className="bg-[#044E97] w-[450px] md:w-auto xl mx-auto py-[19px]  md:py-[27px] md:px-[15px] text-[10px]  md:text-[14px] lg:py-[18px] cursor-pointer lg:px-[60px] rounded-md font-semibold text-[#FFFFFF] "
            >
              Create an invoice
            </button>
            <button
              onClick={(e) => navigate("/expenses/create")}
              className="bg-[#F6F6F6]  w-[450px] md:w-auto xl mx-auto py-[19px]  md:py-[27px] md:px-[15px] text-[10px]  md:text-[14px] lg:py-[18px] cursor-pointer lg:px-[60px] font-semibold  border-[1px] border-[#044E97] rounded-md text-[#044E97]"
            >
              Create an expense
            </button>
          </div>
        </div>

        <div className="flex w-full lg:w-[58%] min-h-[136px] mt-8 gap-4 bg-[#DEEAFD] border-l-[6px] rounded-md border-l-[#FF6702] justify-center items-center">
          <div className="w-[28%] hidden lg:block">
            <div className="relative mb-2">
              <img src={RecButton} className="" alt="" />
              <img
                src={smallRecBottom}
                className="absolute top-[5px] left-[10px]"
                alt=""
              />
              <img
                src={midRecBottom}
                className="absolute top-[11px] left-[10px] "
                alt=""
              />
              <div className="absolute top-[2px] left-[65px] w-[20px] text-center text-[12.32px] bg-[#FF6702] text-[#FFFFFF]">
                ?
              </div>
            </div>
            <div className="relative ml-6 mb-2">
              <img src={RecButton} className="" alt="" />
              <img
                src={smallRecBottom}
                className="absolute top-[5px] left-[10px]"
                alt=""
              />
              <img
                src={midRecBottom}
                className="absolute top-[11px] left-[10px] "
                alt=""
              />
              <div className="absolute top-[2px] left-[65px] w-[20px] text-center text-[12.32px] bg-[#FF6702] text-[#FFFFFF]">
                ?
              </div>
            </div>
            <div className="relative ">
              <img src={RecButton} className="" alt="" />
              <img
                src={smallRecBottom}
                className="absolute top-[5px] left-[10px]"
                alt=""
              />
              <img
                src={midRecBottom}
                className="absolute top-[11px] left-[10px] "
                alt=""
              />
              <div className="absolute top-[2px] left-[65px] w-[20px] text-center text-[12.32px] bg-[#FF6702] text-[#FFFFFF]">
                ?
              </div>
            </div>
          </div>
          <div className="flex w-full xs:w-auto lg:w-[90%] mt-4 md:px-[55px] xl:px-0 mb-4 md:gap-4  xs:flex xs:justify-center xs:items-center">
            <div className="w-[110%] lg:w-[65%] sm:flex sm:flex-col sm:mx-auto sm:justify-center sm:items-center">
              <div className="lg:text-[1rem] text-[75%] md:text-[120%] lg:w-[220%] xl:w-[100%] w-[100%] ml-2  lg:ml-0   text-[#000000] mb-4 font-semibold">
                “Tell me I forget, Teach me I remember”.{" "}
              </div>
              <div className="w-[90%] md:text-[120%] lg:w-[220%] xl:w-[100%] text-[14px] mx-auto lg:mx-0">
                Take a 3 minutes tutorial to learn about the easy to use tools
                available to you on SME.{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { WelcomePage };
