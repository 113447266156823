import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { deleteExpense } from "../../backend-services";
import { SidebarDrawer } from "../common";
import LeftNotificationPopup from "../core/NotificationPopup/LeftNotificationPopup";
import ProcessSuccessful from "../../assets/icons/ProcessSuccessful";
import { CloseIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";

interface ExpenseDeletePromptProps {
  showPrompt: boolean;
  onExpenseDelete?: (expenseId: any) => void;
  expenseId: string;
  onClose?: () => void;
  onDeleteSuccess?: () => void;
}

const ExpenseDeletePrompt = ({
  showPrompt,
  expenseId,
  onExpenseDelete,
  onClose,
  onDeleteSuccess,
}: ExpenseDeletePromptProps) => {
  const { t } = useTranslation("expenses");
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isDeleted, setIsDeleted] = React.useState(false);
  const [showSuccessSidebar, setShowSuccessSidebar] = React.useState(false);

  const navigate = useNavigate();

  const handleDeleteExpense = async (id: string) => {
    setIsDeleting(true);
    try {
      const data = await deleteExpense(id);
      setIsDeleting(false);
      setIsDeleted(true);
      onExpenseDelete && onExpenseDelete(id);
      onDeleteSuccess && onDeleteSuccess();
    } catch (error: any) {
      setErrorMessage("Unable to Delete Expense.");
      setIsDeleting(false);
      if (
        error.expense &&
        (error.expense.status === 400 || error.expense.status === 500)
      ) {
        navigate("/error");
      }
    }
  };

  const handleKeepExpense = () => {
    onClose && onClose();
  };

  useEffect(() => {
    if (isDeleted) {
      setShowSuccessSidebar(true);
      setTimeout(() => {
        navigate("/expenses");
      }, 3000);
    }
  }, [isDeleted, navigate]);

  const closeSuccessSidebar = () => {
    setShowSuccessSidebar(false);
  };

  return (
    <>
      <div className="h-screen w-[434px] mx-5 pt-9">
        <div onClick={onClose} className="w-full flex justify-end">
          <CloseIcon />
        </div>
        <div className="mt-[44px]">
          <h1 className="font-semibold text-[25px] leading-[30px] mb-4">
            {t("budget.deletePrompt")}
          </h1>
          <h1>
            You are about to delete an expense. This action <br /> cannot be
            reversed once confirmed.
          </h1>
        </div>
        <div className="flex w-full gap-6 mt-8">
          <button
            className="w-full rounded-[4px] h-[48px] bg-[#FFFFFF] text-[#044E97] font-semibold border border-blue-500"
            onClick={handleKeepExpense}
          >
            Cancel
          </button>
          <button
            className="w-full rounded-[4px] h-[48px] bg-[#FB0303] text-white font-semibold"
            onClick={() => handleDeleteExpense(expenseId)}
            disabled={isDeleting}
          >
            {isDeleting ? "Deleting..." : `${t("budget.confirmDelete")}`}
          </button>
        </div>
      </div>
      {showSuccessSidebar && (
        <SidebarDrawer
          title=""
          show={true}
          position="left"
          onClose={closeSuccessSidebar}
        >
          <LeftNotificationPopup
            title="Expense Deleted"
            icon={<ProcessSuccessful />}
            position="left"
            showSuccessModal={true}
          />
        </SidebarDrawer>
      )}
    </>
  );
};

export { ExpenseDeletePrompt };
