import React from "react";

const DropIcon = () => {
  return (
    <div>
      <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.18077 9.32967L0.541993 2.70285C-0.107529 1.77496 0.556286 0.499999 1.68892 0.499999L10.3111 0.5C11.4437 0.5 12.1075 1.77496 11.458 2.70285L6.81923 9.32967C6.42113 9.89839 5.57887 9.89838 5.18077 9.32967Z"
          fill="#044E97"
        />
      </svg>
    </div>
  );
};

export default DropIcon;
