import React, { useState } from "react";
import { ICreateExpenseData, IVendor } from "../../../@types";
import { InfoCircle } from "../../../assets/icons/InfoCircle";
import { Pen } from "../../../assets/icons/PenIcon";
import { Tooltip } from "../../../assets/icons/Tooltip";
import { CreateModal } from "../../common/CreateModal";
import { MiniCard } from "../../common/TooltipCards/miniCards";
import CreateVendorModal from "../Vendor/createVendor";
import SelectVendor from "../Vendor/selectVendor";
import { useTranslation } from "react-i18next";

interface VendorInfoProps {
  handleSelectedVendor: (selectedVendor: IVendor | null) => void;
  formData: ICreateExpenseData;
  vendorName: IVendor | undefined;
  validationResults: ICreateExpenseData;
  setValidationResults: React.Dispatch<
    React.SetStateAction<ICreateExpenseData>
  >;
  onClearError?: () => void;
  toolTipText?: string;
}

const VendorCreateInfo: React.FC<VendorInfoProps> = ({
  handleSelectedVendor,
  formData,
  validationResults,
  setValidationResults,
  onClearError,
  vendorName,
  toolTipText,
}) => {
  const { t } = useTranslation("expenses");
  const [showSelectCustomer, setShowSelectCustomer] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const toggleBankModal = () => {
    setShowSelectCustomer(!showSelectCustomer);
  };

  const handleSetCustomerWrapper = (selectedVendor: IVendor | null) => {
    handleSelectedVendor(selectedVendor);
    if (selectedVendor) {
      setValidationResults((prevResults: any) => ({
        ...prevResults,
        vendorId: "",
      }));
    }
  };
  return (
    <div>
      <div className="flex justify-start mt-[3em]">
        <div className="flex justify-start" onClick={toggleBankModal}>
          <p
            className="xl:text-[16px] biglg:text-[16px] md:text-[14px] text-[9.8px] font-normal"
            onClick={toggleBankModal}
          >
            {t("budget.vendorName")}
          </p>

          {toolTipText && (
            <div
              className="relative  lg:mt-1 pl-1 pr-12"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Tooltip />
              {isHovered && (
                <div className="absolute w-[204px] left-[-5.7em]">
                  <div className="flex items-center justify-center">
                    <svg
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#FFF4C6" />
                    </svg>
                  </div>
                  <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                    {toolTipText}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div
          className="px-[0.3em] cursor-pointer items-end text-right shadow-sm py-[0.2em] bg-[#F4F7FF]"
          onClick={toggleBankModal}
        >
          <Pen />
        </div>
      </div>
      <div
        className="lg:p-4 p-2 bg-[#F4F7FF] shadow-md cursor-pointer lg:text-[14px] text-[11px]  w-[150px] lg:w-[200px] mt-2"
        onClick={toggleBankModal}
      >
        {vendorName ? (
          <div>
            <p>
              <em>{vendorName.name}</em>
            </p>
            <p>
              <em>{vendorName.phone}</em>
            </p>
          </div>
        ) : (
          <div>
            <p>
              <em>Select a vendor</em>
            </p>
          </div>
        )}
      </div>
      {validationResults.vendorName && (
        <div className="mt-2 xl:text-[14px] lg:text-[12px] biglg:text-[12px] 2xl:text-[14px] md:text-[11px] text-[9px]">
          {validationResults.vendorName && (
            <span className="text-red-500">{validationResults.vendorName}</span>
          )}
        </div>
      )}
      {showSelectCustomer && (
        <CreateModal>
          <SelectVendor
            handleSelectedVendor={(selectedVendor) => {
              handleSetCustomerWrapper(selectedVendor);
              setShowSelectCustomer(false);
            }}
            formData={formData}
            vendor={vendorName}
            onClose={() => setShowSelectCustomer(false)}
            vendorName={undefined}
          />
        </CreateModal>
      )}
    </div>
  );
};

export default VendorCreateInfo;
