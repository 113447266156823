import React from "react";
import { useParams } from "react-router-dom";
import { ICreateSalesData } from "../../../@types";
import InvoiceFreeform from "../../../components/Sales/invoice/InvoiceFreeform";

const DraftFreeformInvoicePage: React.FC = () => {
  const { invoiceNo } = useParams<{ invoiceNo: string }>();
  const [, setInvoice] = React.useState(null);
  const [, setIsSubmitting] = React.useState(false);

  const [invoiceDataState, setInvoiceDataState] =
    React.useState<ICreateSalesData>({
      additionalInformation: "",
      customerId: "",
      description: "",
      discount: 0,
      inventories: [],
      invoiceDueAt: "",
      invoiceNumber: "",
      issueDate: new Date().toISOString().substring(0, 10),
      isReoccurring: false,
      isReoccurringEndDate: "",
      isReoccurringStartDate: "",
      reoccurringGroupName: "",
      status: "draft",
      tax: 0,
      exchangeRate: 0,
      currency: "",
    });

  return (
    <>
      <InvoiceFreeform
        customerId={invoiceDataState.customerId}
        invoiceData={invoiceDataState}
        setInvoiceData={setInvoiceDataState}
        headingText="Edit Invoice"
      />
    </>
  );
};

export { DraftFreeformInvoicePage };
