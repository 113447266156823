export const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const prevYear = currentYear - 1;

  return [
    {
      value: currentYear.toString(),
      label: currentYear.toString(),
      isCurrent: true,
    },
    {
      value: prevYear.toString(),
      label: prevYear.toString(),
      isCurrent: false,
    },
  ];
};
