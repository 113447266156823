import React from "react";

const ServiceIcon2 = () => {
  return (
    <>
      <svg
        width="133"
        height="135"
        viewBox="0 0 153 155"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_47258_13094)">
          <g clip-path="url(#clip0_47258_13094)">
            <rect x="44" y="34" width="64" height="64" rx="9" fill="white" />
            <rect
              x="57.332"
              y="58.5312"
              width="37.3333"
              height="25.6"
              rx="1.06667"
              fill="#909AE0"
            />
            <rect
              x="61.0664"
              y="67.6016"
              width="10.6667"
              height="4.8"
              fill="#044E97"
            />
            <path
              d="M99.4139 63.6786C100.045 64.3619 99.56 65.4688 98.6302 65.4688H89.8001C89.5024 65.4688 89.2182 65.3443 89.0163 65.1256L82.9331 58.5354H94.1994C94.4971 58.5354 94.7812 58.6598 94.9832 58.8786L99.4139 63.6786Z"
              fill="#AEB8FE"
            />
            <path
              d="M52.5861 63.6786C51.9553 64.3619 52.44 65.4688 53.3698 65.4688H76.0666C76.3643 65.4688 76.6484 65.3443 76.8504 65.1256L82.9336 58.5354H57.8006C57.5029 58.5354 57.2188 58.6598 57.0168 58.8786L52.5861 63.6786Z"
              fill="#AEB8FE"
            />
            <path
              d="M61.6016 46.7969L69.6016 54.7969"
              stroke="#AEB8FE"
              stroke-width="2.13333"
            />
            <path
              d="M90.3984 46.7969L82.3984 54.7969"
              stroke="#AEB8FE"
              stroke-width="2.13333"
            />
            <path
              d="M88.8008 54.7969L90.4008 56.3969"
              stroke="#AEB8FE"
              stroke-width="2.13333"
            />
            <path
              d="M61.6016 54.7969L63.2016 56.3969"
              stroke="#AEB8FE"
              stroke-width="2.13333"
            />
            <path
              d="M61.0664 80.3984H71.7331"
              stroke="#F1F2F6"
              stroke-width="1.6"
            />
            <path
              d="M61.0664 76.6641H67.4664"
              stroke="#F1F2F6"
              stroke-width="1.6"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_47258_13094"
            x="-0.999996"
            y="0.250003"
            width="154"
            height="154"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="11.25" />
            <feGaussianBlur stdDeviation="22.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0784314 0 0 0 0 0.0784314 0 0 0 0 0.168627 0 0 0 0.12 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_47258_13094"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_47258_13094"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_47258_13094">
            <rect x="44" y="34" width="64" height="64" rx="9" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default ServiceIcon2;

export const ServiceIconMobile = () => (
  <svg
    width="100"
    height="105"
    viewBox="0 0 153 155"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_47258_13094)">
      <g clip-path="url(#clip0_47258_13094)">
        <rect x="44" y="34" width="64" height="64" rx="9" fill="white" />
        <rect
          x="57.332"
          y="58.5312"
          width="37.3333"
          height="25.6"
          rx="1.06667"
          fill="#909AE0"
        />
        <rect
          x="61.0664"
          y="67.6016"
          width="10.6667"
          height="4.8"
          fill="#044E97"
        />
        <path
          d="M99.4139 63.6786C100.045 64.3619 99.56 65.4688 98.6302 65.4688H89.8001C89.5024 65.4688 89.2182 65.3443 89.0163 65.1256L82.9331 58.5354H94.1994C94.4971 58.5354 94.7812 58.6598 94.9832 58.8786L99.4139 63.6786Z"
          fill="#AEB8FE"
        />
        <path
          d="M52.5861 63.6786C51.9553 64.3619 52.44 65.4688 53.3698 65.4688H76.0666C76.3643 65.4688 76.6484 65.3443 76.8504 65.1256L82.9336 58.5354H57.8006C57.5029 58.5354 57.2188 58.6598 57.0168 58.8786L52.5861 63.6786Z"
          fill="#AEB8FE"
        />
        <path
          d="M61.6016 46.7969L69.6016 54.7969"
          stroke="#AEB8FE"
          stroke-width="2.13333"
        />
        <path
          d="M90.3984 46.7969L82.3984 54.7969"
          stroke="#AEB8FE"
          stroke-width="2.13333"
        />
        <path
          d="M88.8008 54.7969L90.4008 56.3969"
          stroke="#AEB8FE"
          stroke-width="2.13333"
        />
        <path
          d="M61.6016 54.7969L63.2016 56.3969"
          stroke="#AEB8FE"
          stroke-width="2.13333"
        />
        <path
          d="M61.0664 80.3984H71.7331"
          stroke="#F1F2F6"
          stroke-width="1.6"
        />
        <path
          d="M61.0664 76.6641H67.4664"
          stroke="#F1F2F6"
          stroke-width="1.6"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_47258_13094"
        x="-0.999996"
        y="0.250003"
        width="154"
        height="154"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="11.25" />
        <feGaussianBlur stdDeviation="22.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0784314 0 0 0 0 0.0784314 0 0 0 0 0.168627 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_47258_13094"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_47258_13094"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_47258_13094">
        <rect x="44" y="34" width="64" height="64" rx="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
