import React, { useEffect } from "react";
import SubscriptionArrow from "../../assets/icons/SubscriptionArrow";
import { CloseIcon, CloseIconSmall } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import { Button } from "../common";
import { useTranslation } from "react-i18next";

type SubscriptionBannerProps = {
  onClose: () => void;
  message?: string;
};

const SubscriptionBanner: React.FC<SubscriptionBannerProps> = ({
  onClose,
  message,
}: SubscriptionBannerProps) => {
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/Subscription");
  };
  return (
    <>
      <div className="flex md:hidden gap-2 py-4 px-2 bg-gray-100 border rounded-lg mt-[-2em]">
        <div className="flex items-center justify-start w-[18px] h-[18px] mt-[1.3em]">
          <SubscriptionArrow />
        </div>

        <div className="flex flex-col text-[12px]">
          <div>
            <h3 className="font-semibold xl:text-[16px] md:text-[14px] lg:text-[16px] biglg:text-[16px] text-[10px]">
              {t("moreWithEsemie")}
            </h3>
            <p className="xl:text-[16px] md:text-[14px] lg:text-[16px] biglg:text-[16px] text-[10px]">
              {t("WithJust5000")}
            </p>
          </div>

          <div className="mt-[-3em]">
            <button
              className=" text-[10px] xl:text-[14px] md:text-[12px] lg:text-[14px] biglg:text-[14px]  text-white mt-[] ml-[18em]  bg-[#FF6702] rounded-sm h-[38px] w-[92.3px]"
              onClick={handleNavigate}
            >
              {t("ssubscription.choose")}
            </button>
          </div>
        </div>

        <div className="mt-[0.5em] pr-2">
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800"
          >
            <CloseIconSmall />
          </button>
        </div>
      </div>

      <div className="hidden md:grid grid-cols-4 gap-0 md:gap-4 p-3 bg-[#DEEAFD] border rounded-lg mx-[10px] lg:mx-[10px] biglg:mx-[20px]">
        <div className="flex items-center justify-start">
          <SubscriptionArrow />
        </div>

        <div className="col-span-2 xl:ml-[-6em] biglg:ml-[-6em] lg:ml-[-5em] md:ml-[-5em]">
          <h3 className="text-xl font-semibold">{t("moreWithEsemie")}</h3>
          <p className="text-[14px] whitespace-nowrap">{t("WithJust5000")}</p>
        </div>

        <div className="flex items-center justify-between whitespace-nowrap md:ml-[-0.8em] md:w-[165px] xl:w-[200px] biglg:w-[200px] xl:ml-[-2em] biglg:ml-[-3em]">
          <Button size="full" variant="primary" onClick={handleNavigate}>
            Choose a plan
          </Button>
          <button
            onClick={onClose}
            className="cursor-pointer text-gray-600 hover:text-gray-800 pr-4"
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default SubscriptionBanner;
