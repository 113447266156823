import React from "react";

const AddSquare = () => {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.625 9C2.625 6.78337 2.625 5.67506 3.0521 4.82655C3.43793 4.06004 4.06004 3.43793 4.82655 3.0521C5.67506 2.625 6.78337 2.625 9 2.625V2.625C11.2166 2.625 12.3249 2.625 13.1734 3.0521C13.94 3.43793 14.5621 4.06004 14.9479 4.82655C15.375 5.67506 15.375 6.78337 15.375 9V9C15.375 11.2166 15.375 12.3249 14.9479 13.1734C14.5621 13.94 13.94 14.5621 13.1734 14.9479C12.3249 15.375 11.2166 15.375 9 15.375V15.375C6.78337 15.375 5.67506 15.375 4.82655 14.9479C4.06004 14.5621 3.43793 13.94 3.0521 13.1734C2.625 12.3249 2.625 11.2166 2.625 9V9Z"
          stroke="#044E97"
          strokeWidth="2"
        />
        <path
          d="M9 6.16797L9 11.8346"
          stroke="#044E97"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M11.832 9L6.16536 9"
          stroke="#044E97"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default AddSquare;
