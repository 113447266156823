import { STORAGE_TOKEN } from "../constants";
import { createBrowserHistory } from "history";
import React from "react";
import { FormData } from "../@types";
import { refreshIdToken } from "./refreshToken";
import { redirectToAuth } from "./navigation";
import { getToken, setToken } from "./token";

const isTokenExpired = (token: string): boolean => {
  try {
    const [, payload] = token.split(".");
    const decodedPayload = JSON.parse(atob(payload));
    const expirationTime = decodedPayload.exp * 1000;
    return Date.now() > expirationTime;
  } catch (error) {
    return true;
  }
};

export const getAuthToken = async (): Promise<string | null> => {
  try {
    const token = getToken();
    if (!token) {
      redirectToAuth();
      return null;
    }
    if (isTokenExpired(token)) {
      const newToken = await refreshIdToken();
      if (newToken) {
        setToken(newToken);
        return newToken;
      }
      redirectToAuth();
      return null;
    }
    return token;
  } catch (error) {
    redirectToAuth();
    return null;
  }
};

export const isPasswordValid = (password: string) => {
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  if (!pattern.test(password)) {
    return "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number";
  }
  return "";
};

/**
 * @param {string} min Minimum number to generate
 * @param {number} max Maximum number to generate
 * @returns {string}
 */
export const generateNumber = (min = 1, max = 9000000): string => {
  min = Math.ceil(min);
  max = Math.floor(max);
  const num = Math.floor(Math.random() * (max - min + 1)) + min;
  return num.toString().padStart(12, "0");
};

export const sortData = <T extends Object>(
  data: Array<T>,
  sortColumn: keyof T,
  sortDirection: "ASC" | "DESC",
): Array<T> => {
  return data.sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) {
      return sortDirection === "ASC" ? -1 : 1;
    }
    if (a[sortColumn] > b[sortColumn]) {
      return sortDirection === "ASC" ? 1 : -1;
    }
    return 0;
  });
};

export const copyToClipboard = async (data: string) => {
  await navigator.clipboard.writeText(data);
};

export const history = createBrowserHistory();

export const shortenFileName = (file: File, length = 15) => {
  const name = file.name;
  if (name.length > length) {
    const fileType = file.type.split("/")[1];
    const fileName = name.substring(0, length - 4);

    return fileName + "..." + fileType;
  }

  return name;
};

export const ValidateFormSubmit = (
  formData: FormData,
  setValidationResults: React.Dispatch<React.SetStateAction<FormData>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
) => {
  const errors = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    createPassword: "",
    confirmPassword: "",
    businessName: "",
  };

  // Check for required fields
  if (!formData.businessName) {
    errors.businessName = "Business Name is required";
  }

  if (!formData.email) {
    errors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    errors.email = "Email is invalid";
  }

  // Password validation
  if (!formData.createPassword) {
    errors.createPassword = "Password is required";
  } else if (formData.createPassword.length < 8) {
    errors.createPassword = "Password must be at least 8 characters";
  } else if (!/[A-Z]/.test(formData.createPassword)) {
    errors.createPassword =
      "Password must contain at least one uppercase letter";
  } else if (!/[a-z]/.test(formData.createPassword)) {
    errors.createPassword =
      "Password must contain at least one lowercase letter";
  } else if (!/[0-9]/.test(formData.createPassword)) {
    errors.createPassword = "Password must contain at least one number";
  } else if (!/[^A-Za-z0-9]/.test(formData.createPassword)) {
    errors.createPassword =
      "Password must include at least one special character";
  }

  // Set validation results
  setValidationResults(errors);

  // Check for errors and set error state
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      if (errors[key as keyof typeof errors]) {
        setError(errors[key as keyof typeof errors]);
        return true; // Errors are found
      }
    }
  }

  return false; // No errors found
};

type barChartData = {
  [key: string]: {
    expense: number;
    invoice: number;
  };
};

export function shortenText(
  text: string | number | undefined | null,
  maxLength: number,
  ellipsis: string = "...",
): string {
  if (text === null || text === undefined) {
    return ""; // Handle undefined or null input gracefully
  }

  const textString = typeof text === "number" ? String(text) : text;

  if (textString.length <= maxLength) {
    return textString;
  }

  return textString.substring(0, maxLength - ellipsis.length) + ellipsis;
}

export const calculateChartHeight = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth >= 1280) {
    return 500;
  } else if (screenWidth >= 768) {
    return 700;
  } else {
    return 650;
  }
};

export const calculateAdjustedRate = (
  rate: number,
  exchangeRate?: number,
): number => {
  console.error("Invalid rate or exchange rate", { rate, exchangeRate });
  return exchangeRate ? rate / exchangeRate : rate;
};
