import React from "react";
import { ICreateBudget } from "../../../@types";
import { createBudget, updateBudget } from "../../../backend-services";
import { validateBudgetForm } from "../../../modules/settings/settings";
import { clearFieldError, handleErrors } from "../../../utils/handleErrors";
import { Button } from "../../common";
import { DropdownComponent, InputComponent } from "../../core/Forms";
import ErrorComponent from "../../Error";
import { addCommasToNumber } from "../../../utils/convertToFloat";
import { getMonthLabel } from "../../../utils/DateTimeRange";
import { initialBudgetState } from "../../../constants";

interface CreateBudgetProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
  budgetData?: ICreateBudget;
}

export const CreateBudget: React.FC<CreateBudgetProps> = ({
  onSubmit,
  isLoading,
  budgetData,
}) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [selectedValue, setSelectedValue] = React.useState("");
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [, setIsLoading] = React.useState(false);
  const [, setErrorMessage] = React.useState<string>("");
  const [formData, setFormData] = React.useState(initialBudgetState);
  const [validationResults, setValidationResults] = React.useState({
    monthYear: "",
    allocatedBudget: null,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    clearFieldError(name, validationResults, setValidationResults);
  };

  const handleSubmit = async () => {
    setValidationResults({
      monthYear: "",
      allocatedBudget: null,
    });

    const hasErrors = validateBudgetForm(formData, setValidationResults);

    if (!hasErrors) {
      setIsLoading(true);
      try {
        const data = {
          ...formData,
          allocatedBudget: Number(formData.allocatedBudget),
        };
        const result = await updateBudget(data);
      } catch (error) {
        handleErrors(error, setErrorMessage);
        return <ErrorComponent errorCode={500} />;
      } finally {
        setIsLoading(false);
      }
    } else {
    }
  };

  React.useEffect(() => {
    if (budgetData) {
      setFormData(budgetData);
    }
  }, [budgetData]);

  const dropDownArray = [];

  for (let month = currentMonth; month <= 12; month++) {
    dropDownArray.push({
      value: `${month.toString().padStart(2, "0")}-${currentYear}`,
      label: getMonthLabel(month),
    });
  }

  for (let year = currentYear + 1; year <= currentYear + 0; year++) {
    for (let month = 1; month <= 12; month++) {
      dropDownArray.push({
        value: `${month.toString().padStart(2, "0")}-${year}`,
        label: getMonthLabel(month),
      });
    }
  }

  const handleDropDownChange = (name: string, selectedValue: string) => {
    const [month, year] = selectedValue.split("-");
    const formattedValue = `${month.padStart(2, "0")}-${year}`;

    setFormData({ ...formData, monthYear: formattedValue });
    clearFieldError(name, validationResults, setValidationResults);
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="w-full min-h-screen lg:h-screen">
          <div className="w-full space-y-4 md:space-y-6">
            <div className="w-full">
              <div className="text-[14px] md:text-[16px] mb-2">Amount</div>
              <InputComponent
                placeholder="Amount"
                name="allocatedBudget"
                type="text"
                pattern="[0-9]*"
                value={addCommasToNumber(formData.allocatedBudget)}
                handleChange={handleInputChange}
                className="w-full max-w-[434px]"
                error={validationResults.allocatedBudget}
              />
            </div>
            <div className="w-full max-w-[434px]">
              <div className="text-[14px] md:text-[16px] mb-2">
                Select Month
              </div>
              <DropdownComponent
                options={dropDownArray}
                placeholder="Select Month"
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                onChange={handleDropDownChange}
                isSubmitted={isSubmitted}
                error={validationResults.monthYear}
                dropdownClassName="w-full max-w-[434px] lg:bg-white  lg:rounded lg:py-1 lg:focus:ring-0 lg:pointer-events lg:placeholder-gray-400 lg:placeholder-opacity-75"
                iconClassName="mt-[-4px] "
              />
              {validationResults.monthYear && (
                <div className="text-red-500 text-[12px]">
                  {validationResults.monthYear}
                </div>
              )}
            </div>
          </div>

          <div className="w-full max-w-[434px] mt-10">
            <Button
              type="submit"
              text="Set Budget"
              isLoading={isLoading}
              disabled={isLoading}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
