import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { TooltipProps } from "recharts";
import { barChartData } from "../../../../@types";
import { generateYearOptions } from "../../../../utils/date";
import { DropdownComponent } from "../../Forms";
import EmptyStateChart from "./Empty";
import ArrowLeft from "../../../../assets/icons/ArrowLeft";
import ArrowRight from "../../../../assets/icons/ArrowRight";

interface BarChartProps {
  title: string;
  icon?: React.ReactNode;
  colorScale: { invoice: string; expense: string };
  offset: number;
  data: barChartData | any;
  isEmpty?: boolean;
  onChangeYear: (year: string, startDate: Date, endDate: Date) => void;
  selectedYear: string;
  setSelectedYear: (selectedYear: string) => void;
}

const MobileBarchart: React.FC<BarChartProps> = ({
  title,
  icon,
  data,
  colorScale,
  offset,
  isEmpty,
  onChangeYear,
  selectedYear,
  setSelectedYear,
}) => {
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 6 });
  const [activeArrow, setActiveArrow] = useState<"previous" | "next" | "">("");
  const [chartData, setChartData] = useState<
    { name: string; expense: number; invoice: number }[]
  >([]);

  const iconStyle = {
    color: "#0D8DBF",
  };

  const monthsOrder = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];

  useEffect(() => {
    const newData = monthsOrder.map((month) => ({
      name: month.toString().toUpperCase(),
      expense: data[month]?.expense,
      invoice: data[month]?.invoice,
    }));

    setChartData(newData);
  }, [data]);

  const handleSwipeNext = () => {
    const nextEnd = visibleRange.end + 6;
    if (nextEnd <= monthsOrder.length) {
      setVisibleRange((prevRange) => ({
        start: prevRange.start + 6,
        end: nextEnd,
      }));
      setActiveArrow("next");
    }
  };

  const handleSwipePrevious = () => {
    const nextStart = visibleRange.start - 6;
    if (nextStart >= 0) {
      setVisibleRange((prevRange) => ({
        start: nextStart,
        end: prevRange.end - 6,
      }));
      setActiveArrow("previous");
    }
  };

  const handleChangeYear = (name: string, value: string) => {
    const trimmedYear = value.trim().toUpperCase();
    if (!trimmedYear) {
      return;
    }
    const yearRegex = /^\d{4}$/;
    if (yearRegex.test(trimmedYear)) {
      const yearNumber = parseInt(trimmedYear, 10);
      const startDate = new Date(yearNumber, 0, 1);
      const endDate = new Date(yearNumber, 11, 31);
      setSelectedYear(trimmedYear);
      onChangeYear(trimmedYear, startDate, endDate);
    } else {
    }
  };

  const prev = activeArrow === "previous";
  const next = activeArrow === "next";

  const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      // Initialize values for expense and invoice
      let expenseValue = 0;
      let invoiceValue = 0;

      // Loop through payload to find expense and invoice data
      payload.forEach((entry) => {
        if (entry.dataKey === "expense" && entry?.value) {
          expenseValue = +entry?.value; // Expense data
        }
        if (entry.dataKey === "invoice" && entry?.value) {
          invoiceValue = +entry?.value; // Invoice data
        }
      });

      return (
        <div
          className="shadow-sm"
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            padding: "12px",
            fontSize: "14px",
          }}
        >
          <p>
            Expense: {""}
            <span className="font-semibold text-[#000000]">{`₦${expenseValue?.toLocaleString()}`}</span>
          </p>
          <p>
            Invoice: {""}
            <span className="font-semibold text-[#000000]">{`₦${invoiceValue?.toLocaleString()}`}</span>
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="bg-white px-4 py-4 rounded-lg">
      <div className="flex justify-between items-center font-semibold">
        <p className="text-[16px] text-[#1D2939] leading-6">{title}</p>

        <div className="">
          <DropdownComponent
            options={generateYearOptions()}
            placeholder="Filter"
            dropdownClassName="w-[85px] h-[36px]"
            iconClassName="mr-[6px] mt-1"
            onChange={(name: string, value: string) => {
              handleChangeYear(name, value);
            }}
            selectedValue={selectedYear}
            value={selectedYear}
          />
          <span className="icon" style={iconStyle}>
            {icon}
          </span>
        </div>
      </div>

      <div className="my-5 w-full flex gap-4 items-center justify-end text-[14px] text-[#344054] font-medium">
        <div className="flex gap-2 items-center">
          <div className="bg-[#7EC1FB] h-3 w-3 rounded-[2px]"></div>
          <p className="leading-6">Invoice</p>
        </div>
        <div className="flex gap-2 items-center">
          <div className="bg-[#044E97] h-3 w-3 rounded-[2px]"></div>
          <p className="leading-6">Expense</p>
        </div>
      </div>

      <div className="mt-4">
        {isEmpty || Object.keys(data).length === 0 ? (
          <EmptyStateChart />
        ) : (
          <div>
            <div className="w-full h-[350px] mt-10">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={chartData.slice(visibleRange.start, visibleRange.end)}
                  margin={{ top: -10, right: 10, left: -30, bottom: 1 }}
                >
                  <CartesianGrid
                    vertical={false}
                    stroke="#F0F2F5"
                    strokeDasharray="7 7"
                  />
                  <XAxis
                    dataKey="name"
                    tickLine={false}
                    tick={{ fontSize: 12, fill: "#344054", fontWeight: "500" }}
                    axisLine={{ stroke: "#E5E5E5" }}
                  />
                  <YAxis
                    tickFormatter={(value) => `${value / 1000}K`}
                    tick={{ fontSize: 12, fill: "#344054" }}
                    tickLine={false}
                    axisLine={{ stroke: "#E5E5E5" }}
                  />
                  <Tooltip
                    content={<CustomTooltip />}
                    cursor={false}
                    formatter={(value) => `₦${value.toLocaleString()}`}
                  />

                  <Line
                    type="monotone"
                    dataKey="expense"
                    strokeWidth={2}
                    stroke="#044E97"
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="invoice"
                    strokeWidth={2}
                    stroke="#7EC1FB"
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>

            <div className="flex justify-end mt-[1em]">
              <div className="flex gap-4">
                <div
                  onClick={handleSwipePrevious}
                  className={`rounded-full w-6 h-6 border ${
                    prev ? "border-[#A4A4A4]" : "border-[#044E97]"
                  } flex justify-center items-center`}
                >
                  {prev ? (
                    <ArrowLeft color="#A4A4A4" size="12" />
                  ) : (
                    <ArrowLeft color="#044E97" size="12" />
                  )}
                </div>
                <div
                  onClick={handleSwipeNext}
                  className={`rounded-full w-6 h-6 border ${
                    next ? "border-[#A4A4A4]" : "border-[#044E97]"
                  } flex justify-center items-center`}
                >
                  {next ? (
                    <ArrowRight color="#A4A4A4" size="12" />
                  ) : (
                    <ArrowRight color="#044E97" size="12" />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { MobileBarchart };
