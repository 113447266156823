import * as React from "react";

interface ArrowDownIconProps {
  color?: string;
}

const ArrowUp: React.FC<ArrowDownIconProps> = ({ color }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4612 3.48437L9.75413 2.77727L10.4612 2.07016L11.1683 2.77727L10.4612 3.48437ZM11.4612 16.5613C11.4612 17.1136 11.0135 17.5613 10.4612 17.5613C9.90895 17.5613 9.46124 17.1136 9.46124 16.5613L11.4612 16.5613ZM4.52336 8.00804L9.75413 2.77727L11.1683 4.19148L5.93758 9.42225L4.52336 8.00804ZM11.1683 2.77727L16.3991 8.00804L14.9849 9.42225L9.75413 4.19148L11.1683 2.77727ZM11.4612 3.48437L11.4612 16.5613L9.46124 16.5613L9.46124 3.48437L11.4612 3.48437Z"
        fill="#37C89A"
      />
    </svg>
  );
};

export { ArrowUp };
