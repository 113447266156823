import { toast } from "react-hot-toast";
import {
  validateExpenseBasicInfo,
  validateLineItems,
} from "../modules/expense/expense";
import { createExpense } from "../backend-services";
import { ExpenseLineItem } from "../@types";

interface HandleSavePreviewExpenseParams {
  data: any;
  setPreview: (preview: boolean) => void;
  setValidationResults: React.Dispatch<React.SetStateAction<any>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorLineItems: React.Dispatch<React.SetStateAction<any[]>>;
}

const handleSavePreviewExpense = async ({
  data,
  setPreview,
  // setValidationResults,
  setIsLoading,
  setErrorLineItems,
}: HandleSavePreviewExpenseParams) => {
  // let isValidated = true;
  // isValidated = validateExpenseBasicInfo(data, setValidationResults) && isValidated;
  const { allErrorsEmpty, errors } = validateLineItems(data.lineItems);
  setErrorLineItems(errors);

  // if (!isValidated || !allErrorsEmpty) {
  //   return;
  // }

  setIsLoading(true);
  try {
    const response = await createExpense(data);
    setPreview(false);
    toast.success("Expense Created Successfully");
    setIsLoading(false);
  } catch (error) {
    setPreview(false);
    toast.error("Failed to save Expense");
    setIsLoading(false);
  }
};

export default handleSavePreviewExpense;
