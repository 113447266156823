import React, { useState } from "react";
import {
  Inventory,
  InventoryItem,
  errorProps,
  VariationItem,
} from "../../../@types/inventory";
import { InventoryTable } from "./InventoryTable";
import { VariationDropdownForm } from "./VariationForm";

interface InventoryLineProps {
  inventory: InventoryItem;
  setInventories: any;
  variations: VariationItem[];
  setVariations: (Variations: VariationItem[]) => void;
  error: errorProps;
  setError: (error: errorProps) => void;
  variationList: any;
  variationError: any;
  setVariationError: (error: any[]) => void;
}

const InventoryLine = ({
  inventory,
  setInventories,
  variations,
  setVariations,
  error,
  setError,
  variationList,
  variationError,
  setVariationError,
}: InventoryLineProps) => {
  const [showVariationForm, setShowVariationForm] = useState(false);

  return (
    <>
      <div className="hidden md:block">
        <InventoryTable
          inventory={inventory}
          setInventories={setInventories}
          variations={variations}
          setVariations={setVariations}
          error={error}
          setError={setError}
          variationList={variationList}
          //@ts-ignore
          variationError={variationError}
          setVariationError={setVariationError}
          toolTipText="Inventory name refers to the name of the item."
          toolTipText2="The quantity entered will be added to your stock."
          toolTipText3="The cost price is the  price it costs to buy 1 unit of the item. This also helps Esemie calculate your profit and loss."
          toolTipText4="The selling price refers to the unit price of each item."
        />
      </div>
      <div className="md:hidden">
        <InventoryTable
          inventory={inventory}
          setInventories={setInventories}
          variations={variations}
          setVariations={setVariations}
          error={error}
          setError={setError}
          variationList={variationList}
          //@ts-ignore
          variationError={variationError}
          setVariationError={setVariationError}
        />
      </div>
      <div className="">
        {showVariationForm && (
          <VariationDropdownForm
            key={inventory.name}
            variations={inventory.variations}
          />
        )}
      </div>
    </>
  );
};
export { InventoryLine };
