import React, { useCallback, useEffect, useRef, useState } from "react";
import Bell from "../assets/icons/Bell";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Notification } from "./core/NotificationPopup/Active";
import { NotificationEmpty } from "./core/NotificationPopup/Empty";
import { ReportBug } from "./core/Help/ReportBug";
import { SearchInput, SidebarDrawer, Tab } from "./common";
import questionMark2 from "../assets/icons/svgicons/questionMark2.svg";
import { RequestFeature } from "./core/Help/RequestFeature";
import ProcessSuccessful from "../assets/icons/ProcessSuccessful";
import { createSupport } from "../backend-services";
import { validateSupportForm } from "../modules/profile";
import { ICreateSupport, ICustomer, NotificationEntity } from "../@types";
import SuccessNotification from "./core/Help/SuccessNotification";
import { useNotificationStore, useUserStore } from "../state";
import LogoBlack from "./LogoBlack";
import Menulogo from "../assets/icons/svgicons/menu.png";
import MobileSideMenu from "./sidemenu/MobileSideMenu";
import { CloseIcon, SearchIcon } from "../assets/icons";
import CombineButton from "./core/CombineButton/CombineButton";
import { useCustomerSearch } from "../hooks";
import BetaBadge from "../assets/icons/betaBadge";
import MobileProtipsModal from "./common/JoyRide/MobileProtipsModal";

interface TopbarProps {
  onSidebarToggle?: () => void;
  customer: ICustomer | null;
  customerId?: string;
}
const TopBar: React.FC<TopbarProps> = ({ customer, customerId }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showDrawer, setShowDrawer] = useState(false);
  const [showDraw, setShowDraw] = useState(false);
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showMobileModal, setShowMobileModal] = useState(false);
  const notifications = useNotificationStore((state) => state.notifications);
  const batchUpdate = useNotificationStore(
    (state) => state.batchUpdateNotifications,
  );

  const handleBellClick = () => {
    const updatedNotifcations = notifications?.map((notification) => ({
      ...notification,
      isRead: true,
    }));
    batchUpdate(updatedNotifcations);
    setShowDrawer(true);
  };

  const handleHelpClick = () => {
    setShowSideDrawer(true);
  };

  const handleSubmit = async (supportData: ICreateSupport) => {
    setIsLoading(true);

    try {
      const validationErrors = validateSupportForm(supportData);
      const response = await createSupport(supportData);
      setIsSubmitted(true);
      setShowSuccessModal(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Error creating support ticket:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setShowMobileMenu(false);
  }, [pathname]);

  const unreadNotificationsCount = useNotificationStore(
    (state) => state.notifications?.length,
  );

  const handleShowMobileMenu = useCallback(() => {
    setShowMobileMenu((prev) => !prev);
  }, [showMobileMenu]);

  const {
    data: customers,
    isSearching,
    handleSearch: handleCustomerSearch,
  } = useCustomerSearch();

  const handleSearchSelect = (item: any) => {
    navigate(`/customers/${item.id}/view`);
  };
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => !prev); // Toggle dropdown state
  };

  const pageTitleMap: {
    [key: string]: string;
  } = {
    "/dashboard": "Dashboard",
    "/invoices": "Invoices",
    "/quotes": "Quotes",
    "/expenses": "Expenses",
    "/customers": "Customers",
    "/inventories": "Inventory Management",
    "/reports": "Reports",
    "/user-management": "User Management",
    "/profile": "My Account",
    "/settings": "Settings",
  };

  const getPageTitle = (path: string) => {
    const matchingKey = Object.keys(pageTitleMap).find((key) =>
      path.startsWith(key),
    );
    return matchingKey ? pageTitleMap[matchingKey] : "Default Title";
  };

  const currentPageTitle = getPageTitle(location.pathname);

  return (
    <>
      {/* desktop navigation */}
      <div className="hidden lg:flex h-[80px] border py-4  items-start justify-between w-full px-[24px] sticky top-0 bg-[white] z-10 ">
        <h1 className="text-[22px] font-semibold whitespace-nowrap">
          {currentPageTitle}
        </h1>
        <div className="flex items-end justify-end">
          <div>
            <SearchInput
              placeholder="Search"
              data={customers.map((customer) => ({
                ...customer,
                display: `${customer.name} (${customer.phone})`,
              }))}
              displayKey="name"
              value={customerId}
              isLoading={isSearching}
              clearIcon={CloseIcon}
              clearIconClass="xl:mr-[1em] mr-[0.5em] md:mr-[3.2em] biglg:mr-[2em]"
              selected={customer}
              onSelect={(item: any) => handleSearchSelect(item)}
              icon={<SearchIcon />}
              handleChangeText={handleCustomerSearch}
              emptyMessage={"This customer is not found"}
              dropdownClassName="w-[280px] md:ml-[2.7em] lg:w-[300px] lg:ml-[0.1em] xl:w-[350px] xl:ml-[0.1em] 1024:w-[150px] "
              dropdownStyle={{
                maxHeight: "800px",

                overflowY: "auto",
              }}
              searchKey={"name"}
              classNames="xl:w-[350px] 2xl:w-[400px] h-[48px] lg:w-[300px] rounded-md 1024:w-[100px] border border-[#EAECF0] text-[16px] pl-[4em] items-start"
            />
          </div>
          <div className="flex items-center">
            <Link
              to="#"
              className="text-[#044E97] font-semibold pr-[4px] pl-[10px] "
              onClick={handleHelpClick}
            ></Link>
            <img
              src={questionMark2}
              alt=""
              className="pr-[20px]"
              onClick={handleHelpClick}
            />
            <div className="relative">
              <div
                className={`lg:pr-[12px] pr-[0px]  cursor-pointer ${
                  unreadNotificationsCount > 0 ? "text-red-600" : ""
                }`}
                onClick={handleBellClick}
              >
                <Bell />
              </div>
              {unreadNotificationsCount > 0 && (
                <span className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-6 h-6 text-center text-sm">
                  {unreadNotificationsCount}
                </span>
              )}
            </div>
            <div className="relative cursor-pointer">
              <CombineButton
                onClick={handleDropdownToggle}
                textColor="text-gray-500"
                size="medium"
              />
            </div>
          </div>
        </div>
      </div>

      {/*  mobile navigation */}
      <div className="flex flex-row relative">
        <div className="absolute z-30">
          {showMobileMenu && (
            <>
              <div
                className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-40"
                onClick={handleShowMobileMenu}
              />
              <MobileSideMenu handleShowMobileMenu={handleShowMobileMenu} />
            </>
          )}
        </div>
        <div className="h-[66px] flex lg:hidden border justify-between items-center w-full px-[20px] sm:px-[50px] sticky top-0 bg-[#F2F9FD] z-10">
          <div className="flex flex-row justify-between items-center gap-[35px] lg:gap-0  w-full">
            <div onClick={() => setShowMobileMenu(true)} className="">
              <img src={Menulogo} className="cursor-pointer" alt="" />
            </div>
            <div className="">
              <div className="mb-[-1.6em] flex justify-end items-end mr-[-2.6em]">
                <BetaBadge />
              </div>

              <LogoBlack />
            </div>

            <div className="flex items-center ">
              <div className="relative">
                <div
                  className={` pr-[0px]  cursor-pointer ${unreadNotificationsCount > 0 ? "text-red-600" : ""}`}
                  onClick={handleBellClick}
                >
                  <Bell />
                </div>
                {unreadNotificationsCount > 0 && (
                  <span className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-6 h-6 text-center text-sm">
                    {unreadNotificationsCount}
                  </span>
                )}
              </div>
              <div className="lg:hidden">
                <CombineButton
                  onClick={handleDropdownToggle}
                  textColor="text-gray-500"
                  size="small"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Notification show={showDrawer} onClose={() => setShowDrawer(false)} />
      <NotificationEmpty show={showDraw} onClose={() => setShowDraw(false)} />
      {showSideDrawer && (
        <>
          <div className="fixed top-0 left-0 right-0 bottom-0 z-50 bg-gray-500 bg-opacity-50"></div>
          <SidebarDrawer
            title="Report a Bug or Request a Feature "
            show={true}
            onClose={() => setShowSideDrawer(false)}
            position={"right"}
            topBarHeight={true}
            topBarCloseIcon={true}
          >
            <div className="mt-[-2em]">
              <h1>
                Anything you are missing in our product? Kindly drop <br /> a
                message here to let us know!
              </h1>
              <p className="font-bold text-[16px] mt-[2em] mb-[1em]">
                What would you like to do?
              </p>
              <Tab
                items={[
                  {
                    title: "Report a Bug",
                    component: (
                      <ReportBug
                        onClose={() => setShowSideDrawer(false)}
                        showSideDrawer={showSideDrawer}
                        onSubmit={handleSubmit}
                      />
                    ),
                  },
                  {
                    title: "Request a Feature",
                    component: (
                      <RequestFeature
                        showSideDrawer={showSideDrawer}
                        onClose={() => setShowSideDrawer(false)}
                        onSubmit={handleSubmit}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </SidebarDrawer>
        </>
      )}
      {showSuccessModal && (
        <>
          <div className="fixed top-0 left-0 right-0 bottom-0 z-50 bg-gray-500 bg-opacity-50"></div>
          <SidebarDrawer
            title={""}
            onClose={() => setShowSuccessModal(false)}
            show={true}
            position={"right"}
          >
            <SuccessNotification
              showSuccessModal={false}
              icon={<ProcessSuccessful />}
              onClose={() => setShowSuccessModal(false)}
            />
          </SidebarDrawer>
        </>
      )}
      {showMobileModal && (
        <div className="absolute lg:hidden md:hidden">
          <MobileProtipsModal />
        </div>
      )}
    </>
  );
};

export default TopBar;
