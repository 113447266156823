import React, { FormEventHandler, useState } from "react";
import { validate } from "email-validator";
import { CognitoUser } from "amazon-cognito-identity-js";
import userPool from "../../utils/userPool";
import { Link, useNavigate } from "react-router-dom";
import * as UI from "../common";
import { Alert } from "flowbite-react";
import { InputComponent } from "../core/Forms";
import { useTranslation } from "react-i18next";
import ForgetPasswordIcon from "../../assets/icons/forgetPasswordIcon";
import useCognito from "../../hooks/auth/useCognito";
import useViewport from "../../hooks/auth/useViewPort";

const ForgetPasswordView = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [buttonText, setButtonText] = useState("Continue");
  const { t } = useTranslation("onboarding");
  const { forgotPassword, loading } = useCognito();
  const [formData, setFormData] = useState({
    email: "",
  });
  const viewportWidth = useViewport();
  const handleInputChange = (event: {
    currentTarget: { name: any; value: any };
  }) => {
    const { name, value } = event.currentTarget;
    setFormData({ ...formData, [name]: value });
    setErrorMessage(""); // Clear error when the user starts typing
  };

  const handleForgetPassword: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const { email } = formData;

    if (!email) {
      setErrorMessage("Please enter your email address");
      return;
    }

    const isEmailValid = validate(email);
    if (!isEmailValid) {
      setErrorMessage("Please enter a valid email address");
      setIsSuccess(false);
      return;
    }

    try {
      setIsLoading(true);
      await forgotPassword(email);
      setIsSuccess(true);
      setButtonText("Password link sent");
      navigate(`/reset-password?email=${encodeURIComponent(email)}`);
    } catch (err: any) {
      setIsSuccess(false);
      setErrorMessage("Failed to send password reset link. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* Desktop */}
      {viewportWidth.isDesktop && (
        <div className="flex items-center justify-center min-h-screen bg-[#F2F9FD]">
          <div className="w-full max-w-lg p-5 bg-white rounded-lg shadow-md">
            <div className="flex justify-center">
              <ForgetPasswordIcon />
            </div>

            {/* Heading and Subtext */}
            <div className="text-center mb-4 mt-[1em]">
              <h1 className=" font-semibold text-[20px]">
                {t("onboarding.resetyourpassword")}
              </h1>
              <p className="lg:text-[16px] biglg:text-[14px] text-gray-600 mt-2">
                {t("onboarding.resetpasswordlink")}
              </p>
            </div>

            {/* Error Message */}
            {errorMessage && (
              <Alert color="failure" className="mt-4" role="alert">
                {errorMessage}
              </Alert>
            )}

            {/* Form Section */}
            <form onSubmit={handleForgetPassword}>
              <div className="mb-4">
                <InputComponent
                  placeholder="Enter your email"
                  name="email"
                  type="text"
                  value={formData.email}
                  handleChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  id="email-input"
                  data-testid="email-input"
                />
              </div>

              <div
                className="mb-6 mt-[1.5em] text-[16px]"
                data-testid="desktop-submit"
              >
                <UI.Button
                  text={buttonText}
                  variant="secondary"
                  isLoading={isLoading}
                  disabled={isLoading || loading}
                  data-testid="continue-button"
                />
              </div>

              <div className="text-center">
                <Link
                  to="/login"
                  className="text-blue-600 hover:underline text-[16px] font-semibold"
                >
                  Cancel
                </Link>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Mobile */}
      {!viewportWidth.isDesktop && (
        <div className="mt-[6em] ">
          <div className="flex justify-center">
            <ForgetPasswordIcon />
          </div>

          {/* Heading and Subtext */}
          <div className="text-center mb-4 mt-[1em]">
            <h1 className="text-2xl font-semibold lg:text-3xl">
              {t("onboarding.resetyourpassword")}
            </h1>
            <p className="text-sm lg:text-base text-gray-600 mt-2 px-[2em]">
              {t("onboarding.resetpasswordlink")}
            </p>
          </div>

          {/* Error Message */}
          {errorMessage && (
            <Alert color="failure" className="mt-4">
              {errorMessage}
            </Alert>
          )}

          {/* Form Section */}
          <form onSubmit={handleForgetPassword}>
            <div className="mb-4 px-[2em]">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                {t("onboarding.email")}
              </label>
              <InputComponent
                placeholder="Enter your email"
                name="email"
                type="text"
                value={formData.email}
                handleChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>

            <div className="mb-6 px-[2em]">
              <UI.Button
                text={buttonText}
                variant="secondary"
                isLoading={isLoading}
              />
            </div>

            <div className="text-center">
              <Link
                to="/"
                className="text-blue-600 hover:underline text-sm font-semibold"
              >
                Cancel
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export { ForgetPasswordView };
