import { NariaIcon, WidgetIcon } from "../../../assets/icons";
import React from "react";
import { CustomerInvoicesInfo } from "./CustomerInvoicesInfo";
import { CustomerT } from "../../../types";
import { formatMoney } from "../../../utils";
import Naira from "../../../assets/icons/Naira";
import { useTranslation } from "react-i18next";

interface CustomerInvoicesProps {
  customer: CustomerT;
}
export const CustomerInvoices: React.FC<CustomerInvoicesProps> = ({
  customer,
}) => {
  const { t } = useTranslation("customer");
  return (
    <div className="flex gap-3 w-full mt-3 md:mt-0 md:w-full">
      <div className="w-full">
        <CustomerInvoicesInfo
          title={t("customer.totalBilled")}
          value={
            customer?.totalBilled ? formatMoney(customer?.totalBilled) : "0"
          }
          color="active"
          icon={<Naira />}
        />
      </div>
      <div className="w-full">
        <CustomerInvoicesInfo
          title={t("customer.numInvoices")}
          value={customer?.totalInvoices ? `${customer?.totalInvoices}` : 0}
          color="secondary"
          icon={<WidgetIcon />}
        />
      </div>
    </div>
  );
};
