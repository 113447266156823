import * as React from "react";
import { sortData } from "../../../utils";
import { IPaginationMeta } from "../../../@types";
import Empty from "./Empty";
import { FloatingScrollButton } from "../../FloatingScrollButton";
import { Filter } from "../../../@types/invoice";
import { Head } from "./Head";
import DesktopTableBody from "./DesktopTableBody";
import { ReusableTable } from "../../table";
import { isLoading } from "../../core/InfoCard/InfoCard.stories";
import { ReusableTableColumn } from "../../../@types/table";

export type HeadingType = {
  /**
   * Title show on the column head.
   */
  title?: string;
  /**
   * Icon show on the column head.
   */
  icon?: any;
  /**
   * Callback function after the title/icon is clicked.
   * @returns void
   */
  onClick?: () => void;
};

type PaginationProps = {
  /**
   * Show the table footer pagination.
   * The meta prop and fetchMore Data prop will be required
   */
  showPagination?: true;
  /**
   * Pagination meta data
   */
  meta?: IPaginationMeta;
  /**
   * Fetch more data for pagination
   * @param offset The point to start fetching data.
   * @param limit Max number of data to fetch.
   * @param cursor Last data id.
   * @returns Void
   */
  fetchMoreData?: (direction: "up" | "down") => void;
};

type HeadProps =
  | {
      showHead?: false;
      buttons?: never;
      handleSearch?: never;
    }
  | {
      /**
       * Show the table head components.
       * @type boolean
       */
      showHead?: true;

      /**
       * List of grouped buttons to show in the head component
       */
      buttons?: Array<{
        text: string;
        icon: any;
        href?: string;
        onClick?: () => void;
      }>;
      /**
       * Function called when search text changes.
       * @param text search text
       * @returns void
       */
      modules?: string;
      handleSearch?: (text: string) => void;
      handleSort?: (text: string) => void;
      isSearching?: boolean | undefined;
      searchedDone?: boolean | undefined;
      setSearchedDone?: (searchedDone: boolean) => void;
      selectedFilter?: any;
      handleFilter?: (filter: Filter) => void;
      searchResult: any[];
      searchDisplayKey: string;
      searchPlaceHolder: string;
      handleSearchSelect?: (item: any) => void;
      showMobileInvoiceTable?: boolean;
      showMobileServiceTable?: boolean;
    };
export type TableItem = {
  [key: string]:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
};

export type TableData = {
  data: [];
};
type ItemProps<T> = {
  /**
   * A HeadingType Object displayed in the table header.
   * The key of this object will be used as key for the items displayed on the table data.
   */
  head?: T;
  /**
   * Array of object with keys of HeadingType as it's key.
   * Determines the data that will be rendered in the body of the table
   */
  data: Array<{ [key in keyof T]: any }>;
} & PaginationProps &
  HeadProps;

export type TableProps<T> = {
  /**
   * Indicates whether table data is loading or not.
   */
  isLoading: boolean;
  modules?: string;
  hideFloatingScrollButton?: boolean;
  showMobileInvoiceTable?: boolean | undefined | any;
  showMobileServiceTable?: boolean | undefined | any;
  CustomerCard?: boolean | any;
  expenseCard?: boolean | undefined;
  invoiceCard?: boolean | undefined;
  serviceCard?: boolean | undefined;
  payHistoryTable?: boolean;
  columns: ReusableTableColumn[];
} & ItemProps<T>;

const Table = <T extends { [key: string]: HeadingType }>(
  props: TableProps<T>,
) => {
  const [data, setData] = React.useState<typeof props.data>([]);
  const [sort, setSort] = React.useState<{
    column: string;
    direction: "ASC" | "DESC";
  }>({ column: "", direction: "DESC" });

  const fetchPageData = async (direction: "up" | "down") => {
    if (direction === "down" && props?.meta?.hasMoreData) {
      props.fetchMoreData ? props.fetchMoreData("down") : undefined;
    }
  };

  React.useEffect(() => {
    setData([...props.data]);
  }, [props.data]);

  React.useEffect(() => {
    setData(sortData(props.data, sort.column, sort.direction));
  }, [sort]);

  const handleSort = (column: string) => {
    if (sort.column === column) {
      setSort({ column, direction: sort.direction === "ASC" ? "DESC" : "ASC" });
    } else {
      setSort({ column, direction: "ASC" });
    }
  };

  return (
    <>
      <div className="w-full border border-gray-200 mt-10">
        {props.showHead && (
          <div className="">
            <Head
              handleSearchSelect={props.handleSearchSelect}
              buttons={props.buttons}
              handleSearch={props.handleSearch}
              handleSort={props.handleSort}
              searchResult={props.searchResult}
              searchDisplayKey={props.searchDisplayKey}
              handleFilter={props.handleFilter}
              filter={props.selectedFilter}
              searchPlaceHolder={props.searchPlaceHolder}
              isSearching={props.isSearching}
              searchedDone={props.searchedDone}
              setSearchedDone={props.setSearchedDone}
            />
          </div>
        )}

        <hr className="border border-gray-200 my-4" />

        <div className="rounded-[10px] lg:block" style={{ overflow: "hidden" }}>
          <ReusableTable
            hasActions={false}
            modules={props.modules}
            data={data}
            columns={props.columns}
            isLoading={props.isLoading}
            loadMore={fetchPageData}
          />
        </div>

        {data.length === 0 && !props.isLoading && <Empty />}
        {data.length !== 0 && !props.isLoading && (
          <>
            {!props.hideFloatingScrollButton && (
              <FloatingScrollButton
                className="hidden sticky bottom-8 lg:left-[1099px] xl:left-[1099px] biglg:left-[1099px] md:left-[1099px] 2xl:left-[1339px] z-10 text-[#044E97] text-[14px] font-semibold pr-[1em]"
                action={() => {
                  if (props.showPagination && props?.meta?.hasMoreData) {
                    fetchPageData("down");
                  }
                }}
                title="Scroll Down"
                direction="down"
                fadeMode={!props?.meta?.hasMoreData}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export { Table };
