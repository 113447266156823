import React from "react";
import CloseButton from "../CloseButton";

interface ModalHeaderProps {
  title: string;
  onClose: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, onClose }) => (
  <div className="flex justify-between items-center p-4 ">
    <h2 id="modal-title" className="text-xl font-semibold text-gray-800">
      {title}
    </h2>
    <CloseButton onClose={onClose} />
  </div>
);

export default ModalHeader;
