import React from "react";

interface InfoCircleProps {
  className?: string;
}

export const InfoCircle = ({ className }: InfoCircleProps) => {
  return (
    <div>
      <svg
        width="14"
        height="14"
        viewBox="0 0 24 25"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 11.5C11.7348 11.5 11.4804 11.6054 11.2929 11.7929C11.1054 11.9804 11 12.2348 11 12.5V16.5C11 16.7652 11.1054 17.0196 11.2929 17.2071C11.4804 17.3946 11.7348 17.5 12 17.5C12.2652 17.5 12.5196 17.3946 12.7071 17.2071C12.8946 17.0196 13 16.7652 13 16.5V12.5C13 12.2348 12.8946 11.9804 12.7071 11.7929C12.5196 11.6054 12.2652 11.5 12 11.5ZM12.38 7.58C12.1365 7.47998 11.8635 7.47998 11.62 7.58C11.4973 7.62759 11.3851 7.69896 11.29 7.79C11.2017 7.8872 11.1306 7.99882 11.08 8.12C11.024 8.23868 10.9966 8.36882 11 8.5C10.9992 8.63161 11.0245 8.76207 11.0742 8.88391C11.124 9.00574 11.1973 9.11656 11.29 9.21C11.3872 9.29833 11.4988 9.36936 11.62 9.42C11.7715 9.48224 11.936 9.50632 12.099 9.49011C12.2619 9.47391 12.4184 9.41792 12.5547 9.32707C12.691 9.23622 12.8029 9.11328 12.8805 8.96907C12.9582 8.82486 12.9992 8.66378 13 8.5C12.9963 8.23523 12.8927 7.98163 12.71 7.79C12.6149 7.69896 12.5028 7.62759 12.38 7.58ZM12 2.5C10.0222 2.5 8.08879 3.08649 6.4443 4.1853C4.79981 5.28412 3.51809 6.8459 2.76121 8.67317C2.00433 10.5004 1.8063 12.5111 2.19215 14.4509C2.578 16.3907 3.53041 18.1725 4.92894 19.5711C6.32746 20.9696 8.10929 21.922 10.0491 22.3079C11.9889 22.6937 13.9996 22.4957 15.8268 21.7388C17.6541 20.9819 19.2159 19.7002 20.3147 18.0557C21.4135 16.4112 22 14.4778 22 12.5C22 11.1868 21.7413 9.88642 21.2388 8.67317C20.7363 7.45991 19.9997 6.35752 19.0711 5.42893C18.1425 4.50035 17.0401 3.76375 15.8268 3.2612C14.6136 2.75866 13.3132 2.5 12 2.5ZM12 20.5C10.4178 20.5 8.87104 20.0308 7.55544 19.1518C6.23985 18.2727 5.21447 17.0233 4.60897 15.5615C4.00347 14.0997 3.84504 12.4911 4.15372 10.9393C4.4624 9.38743 5.22433 7.96197 6.34315 6.84315C7.46197 5.72433 8.88743 4.9624 10.4393 4.65372C11.9911 4.34504 13.5997 4.50346 15.0615 5.10896C16.5233 5.71447 17.7727 6.73984 18.6518 8.05544C19.5308 9.37103 20 10.9177 20 12.5C20 14.6217 19.1572 16.6566 17.6569 18.1569C16.1566 19.6571 14.1217 20.5 12 20.5Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export const InfoBigCircle = () => {
  return (
    <div>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 11.5C11.7348 11.5 11.4804 11.6054 11.2929 11.7929C11.1054 11.9804 11 12.2348 11 12.5V16.5C11 16.7652 11.1054 17.0196 11.2929 17.2071C11.4804 17.3946 11.7348 17.5 12 17.5C12.2652 17.5 12.5196 17.3946 12.7071 17.2071C12.8946 17.0196 13 16.7652 13 16.5V12.5C13 12.2348 12.8946 11.9804 12.7071 11.7929C12.5196 11.6054 12.2652 11.5 12 11.5ZM12.38 7.58C12.1365 7.47998 11.8635 7.47998 11.62 7.58C11.4973 7.62759 11.3851 7.69896 11.29 7.79C11.2017 7.8872 11.1306 7.99882 11.08 8.12C11.024 8.23868 10.9966 8.36882 11 8.5C10.9992 8.63161 11.0245 8.76207 11.0742 8.88391C11.124 9.00574 11.1973 9.11656 11.29 9.21C11.3872 9.29833 11.4988 9.36936 11.62 9.42C11.7715 9.48224 11.936 9.50632 12.099 9.49011C12.2619 9.47391 12.4184 9.41792 12.5547 9.32707C12.691 9.23622 12.8029 9.11328 12.8805 8.96907C12.9582 8.82486 12.9992 8.66378 13 8.5C12.9963 8.23523 12.8927 7.98163 12.71 7.79C12.6149 7.69896 12.5028 7.62759 12.38 7.58ZM12 2.5C10.0222 2.5 8.08879 3.08649 6.4443 4.1853C4.79981 5.28412 3.51809 6.8459 2.76121 8.67317C2.00433 10.5004 1.8063 12.5111 2.19215 14.4509C2.578 16.3907 3.53041 18.1725 4.92894 19.5711C6.32746 20.9696 8.10929 21.922 10.0491 22.3079C11.9889 22.6937 13.9996 22.4957 15.8268 21.7388C17.6541 20.9819 19.2159 19.7002 20.3147 18.0557C21.4135 16.4112 22 14.4778 22 12.5C22 11.1868 21.7413 9.88642 21.2388 8.67317C20.7363 7.45991 19.9997 6.35752 19.0711 5.42893C18.1425 4.50035 17.0401 3.76375 15.8268 3.2612C14.6136 2.75866 13.3132 2.5 12 2.5ZM12 20.5C10.4178 20.5 8.87104 20.0308 7.55544 19.1518C6.23985 18.2727 5.21447 17.0233 4.60897 15.5615C4.00347 14.0997 3.84504 12.4911 4.15372 10.9393C4.4624 9.38743 5.22433 7.96197 6.34315 6.84315C7.46197 5.72433 8.88743 4.9624 10.4393 4.65372C11.9911 4.34504 13.5997 4.50346 15.0615 5.10896C16.5233 5.71447 17.7727 6.73984 18.6518 8.05544C19.5308 9.37103 20 10.9177 20 12.5C20 14.6217 19.1572 16.6566 17.6569 18.1569C16.1566 19.6571 14.1217 20.5 12 20.5Z"
          fill="black"
        />
      </svg>
    </div>
  );
};
