import { InfoCard } from "../../core/InfoCard/InfoCard";
import React from "react";

interface CustomerInvoicesInfoProps {
  title: string;
  value: string | number;
  color: string;
  icon: JSX.Element;
}

export const CustomerInvoicesInfo: React.FC<CustomerInvoicesInfoProps> = ({
  title,
  value,
  color,
  icon,
}) => (
  <InfoCard
    title={title}
    // @ts-ignore
    value={value}
    // @ts-ignore
    color={color}
    icon={icon}
    isLoading={false}
    styles="w-full"
  />
);
