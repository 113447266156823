import React from "react";
import toast from "react-hot-toast";
import { useSubUserStore } from "../../../state/useSubUserStore";
import showToast from "../../../utils/toastUtils";
import { Button } from "../../common";

interface EnableProps {
  setShowPrompt: (show: boolean) => void;
  userId: string;
}

const EnablePrompt = ({ userId, setShowPrompt }: EnableProps) => {
  const [isEnabling, setIsEnabling] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const { disableUser, users } = useSubUserStore((state) => ({
    disableUser: state.disableUser,
    users: state.users,
  }));

  const user = users.find((u) => u.id === userId);

  const handleEnable = async () => {
    setIsEnabling(true);
    try {
      const newStatus = user?.status === "ENABLED" ? "DISABLED" : "ENABLED";
      await disableUser(userId, { status: newStatus });
      // showToast('User enabled!', 'success');
      toast.success("User enabled!");
      setShowPrompt(false);
    } catch (error: any) {
      // showToast(`${error.message}`, 'error');
    } finally {
      setIsEnabling(false);
    }
  };

  return (
    <div className="enable-prompt">
      <div className="">
        <div className="flex justify-start mt-[13px] ">
          <h1 className="text-[#475467] text-[13px] md:text-[18px] lg:text-[18px]">
            Are you sure you want to enable this user?
          </h1>
        </div>
        <div
          className="flex mt-[0.3em] justify-end items-end "
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="mt-[32px]  lg:w-[215px] w-[130px] lg:text-[16px] text-[12px] md:text-[14px] flex justify-end items-end mr-[1em] lg:mr-[-3em] rounded-lg ">
            <Button
              text={"Cancel"}
              size="small"
              onClick={() => setShowPrompt(false)}
              variant="white"
            />
          </div>
          <div className="mt-[32px] lg:w-[215px] w-[130px] flex justify-end items-end rounded-lg lg:text-[16px] text-[12px] md:text-[14px]">
            <Button
              isLoading={isEnabling}
              text={"Yes Enable"}
              size="small"
              onClick={handleEnable}
              variant="secondary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { EnablePrompt };
