import EscPosEncoder from "esc-pos-encoder";
import { IInvoice, IProfile } from "../@types";

export type ReceiptData = {
  isRetail?: boolean;
  companyName: string;
  receiptNo: string;
  invoiceNo: string | undefined;
  customerName: string;
  paymentMethod: string;
  datePaid: Date | string;
  balanceDue: string;
  items: Item[];
  total: string;
};

type Item = {
  name: string;
  amount: number;
  quantity?: number;
  rate?: number;
  duration?: string;
  price?: number;
};

export const encodeReceipt = async (
  receiptData: ReceiptData,
  profile: IProfile,
): Promise<Uint8Array> => {
  // @ts-ignore
  let encoder = new EscPosEncoder({
    width: 200,
    encoding: "CP437",
    font: "A",
    size: "normal",
    align: "left",
    lineSpacing: 4,
  });

  let receipt = encoder.initialize().align("center");
  let logoExists = false;

  if (profile.logoBase64) {
    logoExists = true;
    await new Promise<void>((resolve, reject) => {
      let img = new Image();
      img.onload = () => {
        try {
          receipt.image(img, 320, 320, "atkinson");
          resolve();
        } catch (error) {
          reject(error);
        }
      };
      img.onerror = reject;
      img.src = profile.logoBase64 as string;
    });
  }

  if (!logoExists) {
    receipt
      .width(2)
      .height(2)
      .bold()
      .size("normal")
      .text(` ${receiptData.companyName} `)
      .bold(false)
      .newline();
  }

  return receipt
    .width(1)
    .height(1)
    .text(` ${profile.address} `)
    .newline()
    .text(` ${profile.city}, ${profile.state} `)
    .newline()
    .text(` Phone #: ${profile.phone} `)
    .newline()
    .newline()
    .bold()
    .text(receiptData.isRetail ? " Retail Invoice " : " Service Invoice ")
    .newline()
    .newline()
    .align("left")
    .text(` DATE PAID: `)
    .bold(false)
    .text(` ${receiptData.datePaid ?? ""} `)
    .newline()
    .newline()
    .bold()
    .text(` Customer Name: ${receiptData.customerName} `)
    .newline()
    .newline()
    .bold(false)
    .text(` Receipt #: ${receiptData.invoiceNo} `)
    .newline()
    .text(` Invoice #: ${receiptData.invoiceNo} `)
    .newline()
    .bold(true)
    .text(` AMOUNT PAID:  `)
    .bold(false)
    .text(` NGN${parseFloat(receiptData.total).toFixed(2)} `)
    .newline()
    .bold(true)
    .text(` PAYMENT METHOD:  `)
    .bold(false)
    .text(` ${receiptData.paymentMethod} `)
    .newline()
    .bold(true)
    .newline()
    .bold(true)
    .text(" SUMMARY ")
    .bold(false)
    .newline()
    .newline()
    .table(
      [
        { width: 18, align: "left" },
        { width: 10, align: "right" },
        { width: 18, align: "right" },
      ],
      [
        ["-".repeat(18), "-".repeat(10), "-".repeat(18)],
        ["Item", "Qty", "Amount"],
        ["-".repeat(18), "-".repeat(10), "-".repeat(18)],
        ...receiptData.items.flatMap((item) => [
          [
            item.name,
            item.quantity ? item.quantity.toString() : "",
            `NGN${item.amount !== undefined ? item.amount.toFixed(2) : item.price?.toFixed(2)}`,
          ],
          ["", "", ""],
        ]),
        ...Array(1).fill(["-".repeat(18), "-".repeat(10), "-".repeat(19)]),
        [
          "Total",
          "",
          (enc: EscPosEncoder) =>
            enc.bold().text(`NGN${receiptData.total}`).bold(false),
        ],
        ["-".repeat(17), "-".repeat(9), "-".repeat(17)],
      ],
    )
    .newline()
    .newline()
    .align("center")
    .size("normal")
    .text("Thanks you for choosing us")
    .newline()
    .text("Powered by esemie.io")
    .qrcode("https://nielsleenheer.com")
    .newline()
    .newline()
    .newline()
    .newline()
    .newline()
    .newline()
    .newline()
    .newline()
    .cut()
    .encode();
};

export const invoiceToReceiptData = (
  invoice: IInvoice,
  user: IProfile,
  isRetail?: boolean,
): ReceiptData => {
  const items = isRetail ? invoice.inventories : invoice.services || [];

  return {
    isRetail,
    companyName: user.businessName ? user.businessName.toUpperCase() : " ",
    receiptNo: "00034",
    invoiceNo: invoice.invoiceNumber,
    customerName: `${invoice.customer.firstName} ${invoice.customer.lastName}`,
    paymentMethod: "Cash",
    datePaid: new Date(invoice.createdAt).toISOString(),
    balanceDue: invoice.balanceDue,
    items: (items || []).map((item) => {
      if (isRetail) {
        const retailItem = item as {
          id: string;
          quantity: number;
          rate: number;
          name: string;
        };
        return {
          name: retailItem.name,
          // Ensure amount is a double (in JS, this is implicit)
          amount: parseFloat(
            (retailItem.rate * retailItem.quantity).toFixed(2),
          ),
          quantity: retailItem.quantity,
          rate: retailItem.rate,
          price: undefined,
        };
      } else {
        const serviceItem = item as {
          id: string;
          duration: string;
          price: number;
          name: string;
        };
        return {
          name: serviceItem.name,
          // Ensure amount is treated as a double, even though this is implicit in JS
          amount: parseFloat(serviceItem.price.toFixed(2)),
          quantity: undefined,
          rate: undefined,
          price: serviceItem.price,
        };
      }
    }),
    //@ts-ignore
    total: items
      .reduce(
        (total: number, item: any) =>
          total +
          (isRetail
            ? parseFloat(
                ((item as any).rate * (item as any).quantity).toFixed(2),
              )
            : parseFloat(((item as any).price || 0).toFixed(2))),
        0,
      )
      .toString(),
  };
};
