export const validateCustomerForm = ({
  firstName,
  lastName,
  email,
  phone,
}: any) => {
  const errors = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  };
  if (!firstName) {
    errors.firstName = "First Name is required";
  }
  if (!lastName) {
    errors.lastName = "Last Name is required";
  }
  if (!phone) {
    errors.phone = "Phone is required";
  }

  // if (!email) {
  //   errors.email = 'Email is required';
  // } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
  //   errors.email = 'Invalid email format';
  // }

  // Check if any error messages are present
  const hasErrors = Object.values(errors).some((error) => error !== "");

  return { hasErrors, errors };
};
