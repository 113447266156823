import React from "react";
import { ICreateSalesData } from "../../../../@types";
import { Pen, PenSmall } from "../../../../assets/icons/PenIcon";
import { useTranslation } from "react-i18next";

interface NoteProps {
  formData: ICreateSalesData;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Notes: React.FC<NoteProps> = ({ formData, handleInputChange }) => {
  const { t } = useTranslation("invoices");
  return (
    <>
      {/* Desktop */}
      <div className="hidden lg:block md:block">
        <div className="flex justify-start mt-[1em]">
          <p className="xl:text-[16px] biglg:text-[16px] text-[16px]">
            {" "}
            {t("addNote")}
          </p>
          <div className="px-[1em] cursor-pointer"></div>
        </div>
        <div className="  cursor-pointer">
          <div className="">
            <textarea
              value={formData.additionalInformation}
              className={` bg-[#F5F6F7] text-[#212121] lg:w-[412px] shadow-sm md:w-[200px] lg:text-[14px] md:text-[12px] text-[11px] md:h-[10vh] bigmd:h-[10vh] lg:h-[10vh] biglg:h-[10vh] xl:h-[10vh] 2xl:h-[10vh] 4xl:h-[10vh]  rounded-md resize-y overflow-y-auto word-wrap break-word outline-none focus:outline-none focus:ring-0 appearance-none leading-normal no-border placeholder:text-[#212121] placeholder:italic`}
              name="additionalInformation"
              onChange={(e: any) => handleInputChange(e)}
              style={{ outline: "none" }}
              placeholder={`Add Payment terms or notes\nyou’d like the customer to be\naware of.`}
            />
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="lg:hidden md:hidden">
        <div className="flex justify-start mt-[1em]">
          <p className="xl:text-[16px] biglg:text-[16px] text-[9px]">
            {" "}
            {t("addNote")}
          </p>
          <div className="px-[1em] cursor-pointer"></div>
        </div>
        <div className="  cursor-pointer">
          <div className="">
            <textarea
              value={formData.additionalInformation}
              className={` bg-[#F4F7FF]  shadow-sm  w-full md:w-[200px] lg:text-[14px] md:text-[12px] text-[11px] md:h-[10vh] bigmd:h-[10vh] lg:h-[10vh] biglg:h-[10vh] xl:h-[10vh] 2xl:h-[10vh] 4xl:h-[10vh]  rounded-md resize-y overflow-y-auto word-wrap break-word outline-none focus:outline-none focus:ring-0 appearance-none leading-normal no-border`}
              name="additionalInformation"
              onChange={(e: any) => handleInputChange(e)}
              style={{ outline: "none" }}
              placeholder="Add Payment terms or notes you’d like the customer to be aware of."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Notes;
