import React from "react";
import { GroupButton } from "../common";
import {
  AddSquareIcon,
  ArrowDownIcon,
  DownloadIcon,
  FilterIcon,
  SearchIcon,
} from "../../assets/icons";
import AddSquare from "../../assets/icons/AddSquare";
import BinIcon from "../../assets/icons/BinIcon";
import ListInventoryBody from "../inventory/list/ListInventoryBody";
import { DropdownComponent } from "../core/Forms";
import { FILTER_LIST } from "../../constants";
import { MobileTableBody } from "../common/MobileTableBody";
import { ReusableTable } from "../table";
import { ReusableTableColumn } from "../inventory/view/Table/VariationTable";

const PurchaseTable = (props: any) => {
  const [data, setData] = React.useState<typeof props.data>([]);

  React.useEffect(() => {
    setData(props.restockData);
  }, [props.restockData]);

  const [selectedValue, setSelectedValue] = React.useState(" Recent");
  const dropDownArray = FILTER_LIST;
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const handleDropDownChange = (value: string) => {
    // handleSort ? handleSort(selectedValue) : null;
  };

  const tableHead: ReusableTableColumn[] = [
    {
      key: "index",
      dataIndex: "index",
      title: "ID",
      label: "ID",
      className: "text-start",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "Product Name",
      label: "Product Name",
    },
    {
      key: "quantityRestocked",
      dataIndex: "quantityRestocked",
      title: "Qty Restocked",
      label: "Quantity Restocked",
    },
    {
      key: "variation",
      dataIndex: "variation",
      title: "Variation",
      label: "Variation",
    },
    { key: "date", dataIndex: "date", title: "Date", label: "Date" },
    {
      key: "oldSellingPrice",
      dataIndex: "oldSellingPrice",
      title: "Old Selling Price",
      label: "Old Selling Price",
    },
    {
      key: "newSellingPrice",
      dataIndex: "newSellingPrice",
      title: "New Selling Price",
      label: "New Selling Price",
    },
  ];

  return (
    <>
      <div className="hidden md:block">
        <ReusableTable
          data={data}
          isLoading={props.isLoading}
          columns={tableHead}
        />
      </div>

      <div className="md:hidden">
        <MobileTableBody
          payHistoryTable={false}
          data={data}
          isLoading={false}
          CustomerCard={false}
          restockCard={true}
        />
      </div>
    </>
  );
};

export default PurchaseTable;
