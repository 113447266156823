import React from "react";

interface DownloadIconProps {
  color?: string;
  className?: string;
}

const DownloadPaymentIcon2: React.FC<DownloadIconProps> = ({
  color,
  className,
}) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0013 12.4993L10.0013 4.16602M10.0013 12.4993C9.41778 12.4993 8.32758 10.8374 7.91797 10.416M10.0013 12.4993C10.5848 12.4993 11.675 10.8374 12.0846 10.416"
          stroke="#D0D5DD"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.16797 15.832H15.8347"
          stroke="#D0D5DD"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export { DownloadPaymentIcon2 };
