import * as React from "react";

interface PinCodeProps {
  codeLength?: number;
  onChange?: (value: string) => void;
  onCodeComplete: (value: string) => void;
}

const fieldPrefix = "codefield_";

const PinCode: React.FC<PinCodeProps> = ({
  codeLength = 6,
  onCodeComplete,
  onChange,
}) => {
  const [code, setCode] = React.useState<{ [key: string]: string }>({});

  React.useEffect(() => {
    const inputFields = [];
    for (let i = 0; i < codeLength; i++) {
      inputFields.push(fieldPrefix + i);
    }
  }, [codeLength]);

  const handleChange = (i: number, value: string) => {
    const updatedCode = { ...code, [fieldPrefix + i]: value };
    setCode(updatedCode);

    const codeString = Object.values(updatedCode).join("");
    onChange && onChange(codeString);

    if (codeString.length === codeLength) {
      console.log(codeString);
      onCodeComplete(codeString);
    } else if (codeString.length < codeLength) {
      console.log(codeString);
      onCodeComplete(codeString);
      onChange && onChange(codeString);
    }
  };

  const stepForward = (i: number) => {
    if (code[fieldPrefix + i] && i !== codeLength - 1) {
      document.getElementById(`${fieldPrefix}${i + 1}`)?.focus();
    }
  };

  const stepBack = (i: number) => {
    if (i !== 0) {
      document.getElementById(`${fieldPrefix}${i - 1}`)?.focus();
    }

    setCode((prev) => {
      const updatedCode = { ...prev };
      delete updatedCode[fieldPrefix + i];
      return updatedCode;
    });
  };

  return (
    <div className="flex gap-3 md:gap-[8px] justify-between px-[13px] md:px-[18px]">
      {[...Array(codeLength)].map((_, i) => {
        return (
          <input
            id={`${fieldPrefix}${i}`}
            key={i}
            autoFocus={i === 0}
            pattern="^\d$"
            maxLength={1}
            type="tel"
            onKeyUp={() => stepForward(i)}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                stepBack(i);
              }
            }}
            className=" lg:h-[61px] border-[#044E97] text-[16px] lg:text-[26px] w-[35px] h-[48px] md:w-[40px]  lg:w-[50px] rounded-[6px] text-center font-medium"
            value={code[fieldPrefix + i] || ""}
            onChange={(e) => handleChange(i, e.target.value)}
          />
        );
      })}
    </div>
  );
};

export { PinCode };
