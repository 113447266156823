import React from "react";

const ArrowLeft = ({ color = "#044E97", size = "6" }) => {
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox="0 0 6 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.55554 1L0.555542 3.11111L2.55554 5.22222"
          stroke={color}
          stroke-width="1.00392"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.555555 3.21875H5"
          stroke={color}
          stroke-width="1.00392"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default ArrowLeft;
