import React from "react";
import { deleteCustomer } from "../../backend-services";
import { useNavigate } from "react-router";
import { SidebarDrawer } from "../common";
import LeftNotificationPopup from "../core/NotificationPopup/LeftNotificationPopup";
import ProcessSuccessful from "../../assets/icons/ProcessSuccessful";
import { CloseIcon, CloseIconBig } from "../../assets/icons";
import cx from "classnames";
import { useTranslation } from "react-i18next";

interface CustomereDeletePromptProps {
  showPrompt: boolean;
  show: any;
  onCustomerDelete?: (customer: any) => void;
  customerId: string;
  onClose?: () => void;
  onDeleteSuccess?: () => void;
}

const CustomerDeletePrompt = ({
  showPrompt,
  show,
  customerId,
  onCustomerDelete,
  onClose,
  onDeleteSuccess,
}: CustomereDeletePromptProps) => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isDeleted, setIsDeleted] = React.useState(false);
  const [showSuccessSidebar, setShowSuccessSidebar] = React.useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation("customer");
  const handleDeleteCustomer = async (id: string) => {
    setIsDeleting(true);
    try {
      const data = await deleteCustomer(id);
      setIsDeleting(false);
      setIsDeleted(true);
      onCustomerDelete && onCustomerDelete(id);
      onDeleteSuccess && onDeleteSuccess();
    } catch (error: any) {
      setErrorMessage("Unable to Delete Customer.");
      setIsDeleting(false);
      if (
        error.expense &&
        (error.expense.status === 400 || error.expense.status === 500)
      ) {
        navigate("/error");
      }
    }
  };

  const handleKeepCustomer = () => {
    onClose && onClose();
  };

  React.useEffect(() => {
    if (isDeleted) {
      setShowSuccessSidebar(true);
      setTimeout(() => {
        navigate("/customers");
      }, 3000);
    }
  }, [isDeleted, navigate]);

  const closeSuccessSidebar = () => {
    setShowSuccessSidebar(false);
  };

  return (
    <>
      <div className="hidden md:block fixed top-0 bottom-0 right-0 z-30 ">
        <div className="bg-white h-screen w-[440px] p-8">
          <div className="flex w-full justify-end">
            <div onClick={handleKeepCustomer}>
              <CloseIcon color="#212121" />
            </div>
          </div>

          <div className="mt-[51px]">
            <h1 className="font-semibold text-[24px] leading-[30px]"> </h1>
            <div className=" mt-4 mb-8">
              You are about to delete a customer ? This action <br /> cannot be
              reversed once confirmed.{" "}
            </div>
          </div>

          <div
            className="flex w-full gap-6"
            onSubmit={(e) => e.preventDefault()}
          >
            <button
              className="w-full rounded-[4px] h-[55px] bg-[#FFFFFF] text-[#044E97] font-semibold border border-blue-500"
              onClick={handleKeepCustomer}
            >
              {t("customer.goto")}
            </button>

            <button
              className="w-full rounded-[4px] h-[55px] bg-[#FB0303] text-white font-semibold"
              onClick={() => handleDeleteCustomer(customerId)}
              disabled={isDeleting}
            >
              {isDeleting ? "Deleting..." : "Delete"}
            </button>
          </div>
        </div>
        <div
          className={`md:hidden overflow-y-auto overflow-x-hidden fixed bottom-0 w-full z-50 px-4 pb-8 pt-4 bg-white rounded-t-[8px]`}
        >
          <div
            onClick={onClose}
            className="flex justify-end mb-[-3em] cursor-pointer"
          >
            <CloseIcon />
          </div>
        </div>
      </div>

      <div className="md:hidden fixed bottom-0 left-0 right-0 z-50">
        <div className="bg-white p-8">
          <div className="flex w-full justify-end">
            <div onClick={handleKeepCustomer}>
              <CloseIcon color="#212121" />
            </div>
          </div>

          <div className="mt-[51px]">
            <h1 className="font-semibold text-[24px] leading-[30px]">
              {" "}
              {t("customer.deletePrompt")}
            </h1>
            <div className=" mt-4 mb-8">
              {/* You are about to delete a customer ? This action <br /> cannot be reversed once confirmed.{' '} */}
              {t("customer.deletePrompt1")}
            </div>
          </div>

          <div
            className="flex w-full gap-6"
            onSubmit={(e) => e.preventDefault()}
          >
            <button
              className="w-full rounded-[4px] h-[55px] bg-[#FFFFFF] text-[#044E97] font-semibold border border-blue-500"
              onClick={handleKeepCustomer}
            >
              {t("customer.goto")}
            </button>

            <button
              className="w-full rounded-[4px] h-[55px] bg-[#FB0303] text-white font-semibold"
              onClick={() => handleDeleteCustomer(customerId)}
              disabled={isDeleting}
            >
              {isDeleting ? "Deleting..." : "Delete"}
            </button>
          </div>
        </div>
      </div>

      {showSuccessSidebar && (
        <SidebarDrawer
          title=""
          show={true}
          position="left"
          onClose={closeSuccessSidebar}
        >
          <LeftNotificationPopup
            title={t("customer.deleted")}
            icon={<ProcessSuccessful />}
            position="left"
            showSuccessModal={true}
          />
        </SidebarDrawer>
      )}
    </>
  );
};

export { CustomerDeletePrompt };
