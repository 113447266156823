import React from "react";

export const ChevronLeftIcon = () => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.41475 13.6959L0.202765 7.50231C0.129032 7.42858 0.0769276 7.3487 0.0464514 7.26268C0.0154837 7.17666 0 7.08449 0 6.98618C0 6.88787 0.0154837 6.7957 0.0464514 6.70968C0.0769276 6.62366 0.129032 6.54378 0.202765 6.47005L6.41475 0.258065C6.58679 0.0860215 6.80184 0 7.05991 0C7.31797 0 7.53917 0.092166 7.7235 0.276498C7.90783 0.46083 8 0.675884 8 0.92166C8 1.16744 7.90783 1.38249 7.7235 1.56682L2.30415 6.98618L7.7235 12.4055C7.89555 12.5776 7.98157 12.7894 7.98157 13.0411C7.98157 13.2933 7.8894 13.5115 7.70507 13.6959C7.52074 13.8802 7.30568 13.9724 7.05991 13.9724C6.81413 13.9724 6.59908 13.8802 6.41475 13.6959Z"
        fill="#212121"
      />
    </svg>
  );
};
