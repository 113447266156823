import React from "react";
import logo from "./Images/EsemesWhite.svg";

const LogoWhite = () => {
  return (
    <div>
      <img src={logo} className="w-[100px] h-[28px]  " alt="logo" />
    </div>
  );
};

export default LogoWhite;
