export const EXPENSE_CATEGORY = [
  { value: "Automobiles", label: "Automobiles" },
  { value: "Equipments", label: "Equipments" },
  { value: "Rent", label: "Rent" },
  { value: "Travel", label: "Travel" },
  { value: "Logistics", label: "Logistics" },
  { value: "Advertising", label: "Advertising" },
  { value: "Insurance", label: "Insurance" },
  { value: "Home office", label: "Home office" },
  { value: "Salary", label: "Salary" },
  { value: "Others", label: "Others" },
];

export const TABLE_HEAD = [
  { label: "Item/Description", key: "name", toLowerCase: () => "name" },
  { label: "Quantity", key: "quantity", toLowerCase: () => "quantity" },
  { label: "Rate", key: "rate", toLowerCase: () => "rate" },
  { label: "Amount", key: "amount", toLowerCase: () => "amount" },
  { label: "", key: "remove", toLowerCase: () => "amount" },
];

export const CURRENCY_Type = [
  { value: "ngn", label: "ngn" },
  { value: "usd", label: "usd" },
];
