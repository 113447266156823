import React from "react";

const EnableUserIcon = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0013 6.66699V13.3337M13.3346 10.0003H6.66797"
          stroke="#344054"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle
          cx="10.0013"
          cy="10.0003"
          r="8.33333"
          stroke="#344054"
          stroke-width="1.5"
        />
      </svg>
    </>
  );
};

export { EnableUserIcon };
