import React from "react";

interface SettingIconProps {
  color?: string;
}

const SettingsIcon: React.FC<SettingIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9193 9.00065C12.9193 10.6115 11.6134 11.9173 10.0026 11.9173C8.39177 11.9173 7.08594 10.6115 7.08594 9.00065C7.08594 7.38982 8.39177 6.08398 10.0026 6.08398C11.6134 6.08398 12.9193 7.38982 12.9193 9.00065Z"
          stroke="#344054"
          stroke-width="1.5"
        />
        <path
          d="M17.3268 6.62667C17.9987 7.7848 18.3346 8.36386 18.3346 9C18.3346 9.63614 17.9987 10.2152 17.3268 11.3733L15.7237 14.1365C15.0545 15.29 14.7199 15.8668 14.1696 16.1834C13.6193 16.5 12.9515 16.5 11.6157 16.5L8.38686 16.5C7.05115 16.5 6.3833 16.5 5.83302 16.1834C5.28275 15.8668 4.94813 15.29 4.27889 14.1365L2.67582 11.3733C2.00392 10.2152 1.66797 9.63614 1.66797 9C1.66797 8.36386 2.00392 7.7848 2.67582 6.62667L4.27889 3.86351C4.94813 2.70997 5.28275 2.1332 5.83302 1.8166C6.3833 1.5 7.05115 1.5 8.38686 1.5L11.6157 1.5C12.9515 1.5 13.6193 1.5 14.1696 1.8166C14.7199 2.1332 15.0545 2.70997 15.7237 3.86351L17.3268 6.62667Z"
          stroke="#344054"
          stroke-width="1.5"
        />
      </svg>
    </>
  );
};
export { SettingsIcon };
