import React, { ReactNode } from "react";

const CenterModal = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <div className="absolute top-0 left-0 w-screen h-[100%] bg-gray-500 bg-opacity-80 z-10">
        <div className="w-full flex justify-center h-full items-center">
          {children}
        </div>
      </div>
    </>
  );
};

export { CenterModal };
