import React, { useState } from "react";
import toast from "react-hot-toast";
import { ItemInput } from "../../../../@types";
import { ServiceData, ServicePriceData } from "../../../../@types/service";
import { createNewService } from "../../../../backend-services/service";
import { validateCreateService } from "../../../../modules/services/services";
import { convertToFloat } from "../../../../utils/convertToFloat";
import ServiceOverlay from "./serviceOverlay";
import { useTranslation } from "react-i18next";

interface CreateServiceProps {
  show: boolean;
  onClose?: () => void;
  setShowCreateService: (show: boolean) => void;
  // onCreateSuccess: (newService: ServiceData) => void;
  onCreateSuccess: (newItem: ItemInput) => void;
}

const CreateServiceModal = ({
  show,
  onClose,
  setShowCreateService,
  onCreateSuccess,
}: CreateServiceProps) => {
  const { t } = useTranslation("services");
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [price, setPrice] = useState<ServicePriceData[]>([]);
  const [serviceData, setServiceData] = useState<ServiceData>({
    name: "",
    duration: "",
    description: "",
    price: [],
    status: "Active",
  });

  const [error, setError] = React.useState({
    name: "",
    charge: {},
  });
  const handleCreateService = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newPrice = price.map((item) => ({
      ...item,
      amount: convertToFloat(item.amount),
    }));

    const { hasErrors, errors } = validateCreateService({
      name: serviceData.name,
      duration: serviceData.duration,
      description: serviceData.description,
      price: newPrice,
    });

    if (hasErrors) {
      setError(errors);
    } else {
      try {
        setIsLoading(true);
        const newServiceData = {
          ...serviceData,
          price: newPrice,
        };

        const response = await createNewService(newServiceData);
        toast.success(t("service.successToast"));
        setShowCreateService(false);
        setIsLoading(false);
        setServiceData({
          name: "",
          duration: "",
          description: "",
          price: [],
          status: "Active",
        });

        onCreateSuccess(response);
      } catch (error) {
        // toast.error('Failed to create service.');
        setIsLoading(false);
        console.error("Error creating service:", error);
      }
    }
  };
  const handleClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };
  return (
    <>
      <ServiceOverlay
        showBack={false}
        setShowCreateService={setShowCreateService}
        handleCreateService={handleCreateService}
        onCreateSuccess={onCreateSuccess}
      />
    </>
  );
};

export default CreateServiceModal;
