import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const namespaces = [
  "invoices",
  "dashboard",
  "services",
  "customer",
  "expenses",
  "inventories",
  "report",
  "subscription",
  "settings",
  "onboarding",
  "quotes",
];

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    ns: namespaces,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}/translation.json",
    },
    // resources,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage", "path", "htmlTag", "cookie"],
    },
    react: { useSuspense: false },
  });

export default i18n;
