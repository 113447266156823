import React from "react";

const EsemieLogo = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_51175_30904)">
          <path
            d="M25.2274 28.4682C23.5097 33.9672 17.2355 35.342 14.3131 35.342C18.0705 33.5331 20.0386 20.6899 20.3965 19.2428C20.7543 17.7956 22.1857 7.4849 27.0166 5.85689C30.8814 4.55448 36.3803 8.93202 38.6467 11.2836C36.052 9.1613 31.6508 8.67674 30.0583 10.7409C28.4944 12.7682 27.3745 21.5943 25.2274 28.4682Z"
            fill="white"
          />
          <path
            d="M13.0082 12.3367C14.7259 6.83765 21.0001 5.46289 23.9225 5.46289C20.1651 7.27179 18.197 20.115 17.8391 21.5621C17.4813 23.0092 16.0499 33.32 11.219 34.948C7.3542 36.2504 1.85529 31.8728 -0.41107 29.5213C2.18362 31.6436 6.58481 32.1281 8.17726 30.0639C9.7412 28.0367 10.8611 19.2105 13.0082 12.3367Z"
            fill="#FF6702"
          />
          <path
            d="M-14.6905 58.4648C-14.8255 57.4086 -15.208 56.6137 -15.8381 56.08C-16.4569 55.5349 -17.3064 55.2623 -18.3866 55.2623C-19.4667 55.2623 -20.333 55.5349 -20.9856 56.08C-21.627 56.6137 -22.0376 57.4086 -22.2177 58.4648H-14.6905ZM-9.93111 61.3946H-22.3696C-22.1783 62.5984 -21.7451 63.5012 -21.07 64.1031C-20.3949 64.6936 -19.4779 64.9888 -18.319 64.9888C-17.4414 64.9888 -16.6876 64.8185 -16.0575 64.4778C-15.4162 64.1371 -14.8817 63.6204 -14.4542 62.9277L-10.5724 64.8866C-11.4726 66.3516 -12.5639 67.4418 -13.8466 68.1572C-15.1293 68.8613 -16.6538 69.2133 -18.4203 69.2133C-21.0981 69.2133 -23.219 68.407 -24.783 66.7945C-26.3469 65.1819 -27.1289 63.0129 -27.1289 60.2874C-27.1289 57.6642 -26.3132 55.5065 -24.6817 53.8144C-23.039 52.1223 -20.9406 51.2763 -18.3866 51.2763C-15.7537 51.2763 -13.6722 52.0712 -12.142 53.6611C-10.6118 55.2396 -9.84673 57.3973 -9.84673 60.1341C-9.84673 60.2817 -9.85236 60.4464 -9.86361 60.6281C-9.87486 60.7984 -9.89736 61.0539 -9.93111 61.3946Z"
            fill="#040016"
          />
          <path
            d="M24.3673 59.8602C24.2322 58.8041 23.8497 58.0091 23.2196 57.4754C22.6008 56.9303 21.7513 56.6578 20.6712 56.6578C19.591 56.6578 18.7247 56.9303 18.0721 57.4754C17.4308 58.0091 17.0201 58.8041 16.8401 59.8602H24.3673ZM29.1266 62.7901H16.6882C16.8794 63.9938 17.3126 64.8966 17.9877 65.4985C18.6628 66.089 19.5798 66.3843 20.7387 66.3843C21.6163 66.3843 22.3701 66.2139 23.0002 65.8733C23.6415 65.5326 24.176 65.0159 24.6035 64.3231L28.4853 66.2821C27.5852 67.747 26.4938 68.8372 25.2111 69.5526C23.9285 70.2567 22.4039 70.6087 20.6374 70.6087C17.9596 70.6087 15.8387 69.8025 14.2747 68.1899C12.7108 66.5773 11.9288 64.4083 11.9288 61.6828C11.9288 59.0596 12.7445 56.9019 14.376 55.2099C16.0187 53.5178 18.1171 52.6718 20.6712 52.6718C23.304 52.6718 25.3855 53.4667 26.9157 55.0565C28.4459 56.635 29.211 58.7927 29.211 61.5295C29.211 61.6772 29.2054 61.8418 29.1941 62.0235C29.1829 62.1939 29.1604 62.4494 29.1266 62.7901Z"
            fill="#040016"
          />
          <path
            d="M36.132 69.9444H31.322V53.2339H36.132V55.8572C36.9196 54.7556 37.7578 53.9493 38.6467 53.4383C39.5468 52.9273 40.565 52.6718 41.7014 52.6718C43.0741 52.6718 44.188 52.9557 45.0431 53.5235C45.9094 54.0913 46.5226 54.9487 46.8827 56.0956C47.6478 54.96 48.5142 54.1083 49.4818 53.5405C50.4606 52.9613 51.5295 52.6718 52.6884 52.6718C53.8586 52.6718 54.8487 52.8932 55.6588 53.3361C56.4802 53.7676 57.1159 54.4206 57.5659 55.295C57.8022 55.772 57.971 56.3114 58.0722 56.9133C58.1735 57.5152 58.2241 58.4293 58.2241 59.6558V69.9444H53.4141V61.6828C53.4141 59.6501 53.206 58.2987 52.7897 57.6287C52.3846 56.9587 51.6702 56.6237 50.6463 56.6237C49.4086 56.6237 48.5085 57.0893 47.946 58.0205C47.3946 58.9517 47.119 60.4734 47.119 62.5857V69.9444H42.309V61.6828C42.309 59.7182 42.1177 58.3896 41.7352 57.6969C41.3526 57.0041 40.6944 56.6578 39.7606 56.6578C38.4891 56.6578 37.5665 57.129 36.9927 58.0716C36.4189 59.0028 36.132 60.5075 36.132 62.5857V69.9444Z"
            fill="#040016"
          />
          <path
            d="M61.1959 46.3691C61.1959 45.5969 61.4715 44.9326 62.0228 44.3761C62.5742 43.8197 63.2324 43.5414 63.9975 43.5414C64.7738 43.5414 65.4376 43.8197 65.989 44.3761C66.5515 44.9212 66.8328 45.5855 66.8328 46.3691C66.8328 47.1527 66.5515 47.8227 65.989 48.3791C65.4376 48.9356 64.7738 49.2138 63.9975 49.2138C63.2324 49.2138 62.5742 48.9299 62.0228 48.3621C61.4715 47.7943 61.1959 47.13 61.1959 46.3691ZM61.6178 69.9444V53.2339H66.4109V69.9444H61.6178Z"
            fill="#040016"
          />
          <path
            d="M81.3148 59.8602C81.1797 58.8041 80.7972 58.0091 80.1671 57.4754C79.5483 56.9303 78.6988 56.6578 77.6186 56.6578C76.5385 56.6578 75.6722 56.9303 75.0196 57.4754C74.3782 58.0091 73.9676 58.8041 73.7875 59.8602H81.3148ZM86.0741 62.7901H73.6357C73.8269 63.9938 74.2601 64.8966 74.9352 65.4985C75.6103 66.089 76.5273 66.3843 77.6862 66.3843C78.5638 66.3843 79.3176 66.2139 79.9477 65.8733C80.589 65.5326 81.1235 65.0159 81.551 64.3231L85.4328 66.2821C84.5326 67.747 83.4413 68.8372 82.1586 69.5526C80.8759 70.2567 79.3514 70.6087 77.5849 70.6087C74.9071 70.6087 72.7862 69.8025 71.2222 68.1899C69.6583 66.5773 68.8763 64.4083 68.8763 61.6828C68.8763 59.0596 69.692 56.9019 71.3235 55.2099C72.9662 53.5178 75.0646 52.6718 77.6186 52.6718C80.2515 52.6718 82.333 53.4667 83.8632 55.0565C85.3934 56.635 86.1585 58.7927 86.1585 61.5295C86.1585 61.6772 86.1528 61.8418 86.1416 62.0235C86.1303 62.1939 86.1078 62.4494 86.0741 62.7901Z"
            fill="#040016"
          />
        </g>
        <defs>
          <clipPath id="clip0_51175_30904">
            <rect
              width="38.5"
              height="38.5"
              fill="white"
              transform="translate(0.75 0.75)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default EsemieLogo;
