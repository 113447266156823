import React from "react";
import { CloseIcon } from "../../assets/icons";

interface CloseButtonProps {
  onClose: () => void;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClose }) => (
  <button
    onClick={onClose}
    className="rounded-md focus:outline-none p-1 text-black "
    aria-label="Close Modal"
  >
    <CloseIcon />
  </button>
);

export default CloseButton;
