import React, { useState } from "react";
import { IInvoice, ISetReminder } from "../../../../@types";
import { PenSmall } from "../../../../assets/icons/PenIcon";
import SendReminderIcon from "../../../../assets/icons/sendReminderIcon";
import SendReminderIcon2 from "../../../../assets/icons/sendReminderIcon2";
import InvoiceReminderModal from "../../create/Reminder/AutomateModal";
import SlideInComponent from "../ReminderHistory";
import SendReminderModal from "../SendReminderModal";
import ReminderViewModal from "./Reminder/InvoiceViewReminder";

interface DropdownButtonProps {
  invoice?: IInvoice | undefined;
  onOpenPopup: boolean;
  invoiceId?: string;
  close?: () => void;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  invoice,
  onOpenPopup,
  invoiceId,
  close,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSlideOpen, setIsSlideOpen] = useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentReminderType, setCurrentReminderType] = useState<
    "before" | "due" | "after"
  >("before");
  const [reminderDays, setReminderDays] = useState({
    before: 0,
    due: 0,
    after: 0,
  });

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleAction2 = (action: string) => {
    if (action === "Action 2") {
      setIsSlideOpen(true);
    }
    setIsOpen(false);
  };

  const handleAction = (action: string) => {
    if (action === "Action 1") {
      setShowReminder(true);
    }
    setIsOpen(false);
  };

  const handleAction3 = (action: string) => {
    if (action === "Action 3") {
      setIsModalVisible(true);
    }
    setIsOpen(false);
  };

  const handleReminderSave = async (reminderData: ISetReminder) => {
    // Save reminder logic
  };

  return (
    <>
      {/* Conditionally render dropdown button */}
      {!isModalVisible && (
        <div className="relative">
          <div className="absolute right-0 mt-[-10em] w-56 bg-white rounded-lg shadow-lg border ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <button
                onClick={() => handleAction("Action 1")}
                className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex justify-between"
                role="menuitem"
              >
                Send Reminder
                <SendReminderIcon />
              </button>
              <button
                onClick={() => handleAction2("Action 2")}
                className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex justify-between"
                role="menuitem"
              >
                Reminder History
                <SendReminderIcon2 />
              </button>
              <button
                onClick={() => handleAction3("Action 3")}
                className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex justify-between whitespace-nowrap"
                role="menuitem"
              >
                Edit Automated Reminder
                <PenSmall />
              </button>
            </div>
          </div>
        </div>
      )}

      {isSlideOpen && (
        <SlideInComponent
          isOpen={isSlideOpen}
          onClose={() => setIsSlideOpen(false)}
          invoice={invoice}
        />
      )}
      {showReminder && (
        <SendReminderModal
          setIsSending={setIsSending}
          invoiceId={invoiceId}
          //@ts-ignore
          customer={invoice?.customer}
          setShowSendModal={setShowReminder}
        />
      )}
      {isModalVisible && (
        <ReminderViewModal
          setShowSendModal={setIsModalVisible}
          onSaveReminder={handleReminderSave}
          reminderType={currentReminderType}
          days={reminderDays[currentReminderType]}
          invoiceId={invoiceId}
        />
      )}
    </>
  );
};

export default DropdownButton;
