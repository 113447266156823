import * as React from "react";

export interface ListInfoCardProps {
  title: string;
  mobileTitle?: string;
  value: string;
  className?: string; // Adding optional className prop
  bgColor: string;
}

const ListInfoCard: React.FC<ListInfoCardProps> = ({
  title,
  mobileTitle,
  value,
  bgColor,
  className = "",
}) => {
  return (
    <div
      style={{ background: bgColor }}
      className={` pl-3 pt-4 md:pt-5 md:pl-4 rounded-lg md:rounded-2xl h-[78px] md:h-[120px] ${className}`}
    >
      <p className="hidden md:block text-[16px] text-[#667085] font-medium leading-6">
        {title}
      </p>
      <p className="md:hidden text-[14px] leading-5 font-medium text-[#000000]">
        {mobileTitle}
      </p>
      <p
        className={`text-[16px] md:text-[19px] lg:text-[22px] leading-6 md:leading-8 text-[#000] md:text-[#212121] font-semibold mt-1`}
      >
        {value || "--"}
      </p>
    </div>
  );
};

export { ListInfoCard };
