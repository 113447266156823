import React, { useState, useEffect, useRef } from "react";
import ProfileIcon from "../ProfileIcon/ProfileIcon";
import { useUserStore } from "../../../state";
import ProfileDropdown from "../../profileDropdown";

interface CombineButtonProps {
  imageUrl?: string;
  size: "small" | "medium" | "large";
  textColor: string;
  onClick?: () => void;
}

function CombineButton({ size, textColor, onClick }: CombineButtonProps) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const { user } = useUserStore();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };
    if (isDropdownVisible) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownVisible]);

  const showDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };
  const firstName = user?.firstName || "";
  const lastName = user?.lastName || "";

  return (
    <div className="relative" ref={dropdownRef}>
      <div onClick={toggleDropdown}>
        <ProfileIcon
          imageUrl={user?.imageUrl}
          firstName={firstName}
          lastName={lastName}
          size={size}
        />
      </div>

      {isDropdownVisible && (
        <div className="absolute top-full right-0 mt-[1em] z-9999 ">
          <ProfileDropdown onClose={() => setIsDropdownVisible(false)} />
        </div>
      )}
    </div>
  );
}

export default CombineButton;
