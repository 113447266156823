import * as React from "react";
import { getQuote, convertQuote, expireQuote } from "../../../backend-services";
import { IQuote } from "../../../@types";
import PageLoader from "../../../components/common/PageLoader";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Annotation } from "../../../@types/annotation";
import { ViewQuote } from "../../../components/Sales/quotes/View/view";

const ViewQuotePage: React.FC = () => {
  const { quoteNo } = useParams();
  if (!quoteNo) {
    throw new Error("Quote Id is missing");
  }
  const [quote, setQuote] = React.useState<IQuote>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isRefunding, setIsRefunding] = React.useState(false);
  const [isConverting, setIsConverting] = React.useState(false);
  const [annotations, setAnnotations] = React.useState<Annotation[]>([]);
  const navigate = useNavigate();

  const handleConvertQuote = React.useCallback(async () => {
    setIsConverting(true);
    try {
      const convertedQuote = await convertQuote({ id: quoteNo });
      const invoiceId = convertedQuote.id;
      setQuote(convertedQuote);
      toast.success("Quote converted to Invoice successfully!");
      setTimeout(() => {
        navigate(`/invoices/${invoiceId}/view`);
      }, 5000);
    } catch (error: any) {
      toast.error("Unable to Convert quote.");
    } finally {
      setIsRefunding(false);
    }
  }, [quoteNo]);

  const handleMarkAsExpired = React.useCallback(async () => {
    setIsConverting(true);
    try {
      const quote = await expireQuote({ id: quoteNo });
      setQuote(quote);
      toast.success("Quote marked as Expired successfully!");
      setTimeout(() => {
        navigate("/quotes");
      }, 1000);
    } catch (error: any) {
      toast.error("Unable to mark as expired");
    } finally {
      setIsRefunding(false);
    }
  }, [quoteNo]);

  const fetchQuote = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getQuote(quoteNo);
      if (data && typeof data === "object") {
        setQuote(data);
        if (data.metadata && data.metadata.annotations) {
          setAnnotations(data.metadata.annotations);
        }
      } else {
        throw new Error("Invalid quote data received");
      }
    } catch (error) {
      toast.error("Failed to load quote");
    } finally {
      setIsLoading(false);
    }
  }, [quoteNo]);

  React.useEffect(() => {
    fetchQuote();
  }, [fetchQuote]);

  return (
    <div>
      {isLoading ? (
        <PageLoader></PageLoader>
      ) : (
        <ViewQuote
          quote={quote}
          annotations={annotations}
          isRefunding={isRefunding}
          handleConvertQuote={handleConvertQuote}
          handleMarkAsExpired={handleMarkAsExpired}
          customerId={""}
        />
      )}
    </div>
  );
};
export { ViewQuotePage };
