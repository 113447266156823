import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  FC,
  useRef
} from "react";
import useCognito from "../hooks/auth/useCognito";
import { useSubscriptionStore, useUserStore } from "../state";
import { useNavigate } from "react-router-dom";
import {
  CognitoUserSession,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { useSubUserStore } from "../state/useSubUserStore";
import jwtDecode from "jwt-decode";
import { getCurrentMonthData } from "../utils/DateTimeRange";
import useGoogleAuth from "../hooks/auth/useGoogleAuth";
import { ROLES, STORAGE_REFRESH_TOKEN, STORAGE_TOKEN } from "../constants";
import Cookies from "js-cookie";
import { Modal } from "../components/common/Modal";
import { ChangePassword } from "../components/core/ChangePassword";
import BusinessTypeSelector from "../components/auth/BusinessTypeModal";
import { SubscriptionModal } from "../components/common/Subscription/SubscriptionModal";
import { useBudgetStore } from "../state/useBudgetStore";
import { useUserDataLoader } from "../hooks/users/useUserDataLoader";
import { DecodedToken, handleTokenAndFetchUserData } from "../utils/token";
import { useCompanyStore } from "../state/useCompanyStore";

interface AuthContextType {
  user: CognitoUserSession | null;
  loading: boolean;
  error: string | null;
  login: (username: string, password: string) => Promise<void>;
  googleLogin: () => void;
  handleGoogleCallback: () => Promise<void>;
  logout: () => void;
  register: (
    username: string,
    password: string,
    attributes: CognitoUserAttribute[],
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const UseAuthContext = createContext<AuthContextType>({
  user: null,
  loading: false,
  error: null,
  googleLogin: () => {
    throw new Error("googleLogin function not implemented");
  },
  login: async () => {
    throw new Error("login function not implemented");
  },
  logout: () => {},
  register: async () => {
    throw new Error("register function not implemented");
  },
  handleGoogleCallback: async () => {
    throw new Error("handleGoogleCallback function not implemented");
  },
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const {
    user: cognitoUser,
    session,
    login: cognitoLogin,
    logout: cognitoLogout,
    register: cognitoRegister,
    loading: authLoading,
    error: authError,
  } = useCognito();

  const { handleGoogleSignIn, handleCodeExchange } = useGoogleAuth();
  const {
    fetchData,
    businessModalVisible,
    isPasswordModalShown,
    setBusinessModalVisible,
    setIsPasswordModalShown,
  } = useUserDataLoader();
  const { fetchBudgets } = useBudgetStore();

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(authError);
  const { setUser, setRole,  } = useUserStore();
  const { setCompanyBankAccounts } = useCompanyStore();
  const { setSubscriptionData } = useSubscriptionStore();
  const isPasswordChanged = useSubUserStore((state) => state.isPasswordChanged);
  const isRootUser = useSubUserStore((state) => state.isRootUser);
  const hasGoogleCallbackExecuted = useRef(false);
  /**
   * Fetches user data after successful authentication.
   */

  useEffect(() => {
    if (cognitoUser && session) {
      const token = session.getIdToken().getJwtToken();
      const decodedToken = jwtDecode<DecodedToken>(token);
      const userId = decodedToken["custom:custom_userid"];
      fetchData(userId);
    }
  }, [cognitoUser, session]);

  /**
   * Modified login function that authenticates with Cognito
   * and fetches user data upon successful authentication.
   * @param username
   * @param password
   */
  const login = async (username: string, password: string): Promise<void> => {
    setLoading(true);
    setError(null);
    try {
      const sessionResult: CognitoUserSession = await cognitoLogin(
        username,
        password,
      );
      const token = sessionResult.getIdToken().getJwtToken();

      const { userId, groups } = await handleTokenAndFetchUserData(
        token,
        setRole,
      );

      const userData = await fetchData(userId);
      await fetchBudgets();
      if (groups.includes(ROLES.ADMIN)) {
        if (!userData?.businessType || !userData.businessType.trim()) {
          navigate("/dashboard");
        } else {
          navigate("/dashboard");
        }
      } else if (groups.includes(ROLES.OPERATOR)) {
        navigate("/operator-dashboard");
      } else if (groups.includes(ROLES.ACCOUNTANT)) {
        navigate("/operator-dashboard");
      } else {
        setError("You do not have permission to access this application.");
      }
    } catch (err: any) {
      setError(err.message || "An unknown error occurred during login.");
    } finally {
      setLoading(false);
    }
  };
  /**
   * Modified logout function that signs out from Cognito
   * and clears user data from the store.
   */
  const logout = (): void => {
    cognitoLogout();

    setUser(null);
    setRole([]);
    setSubscriptionData(null);
    setCompanyBankAccounts([]);
    setLoading(false);
    setError(null);
    navigate("/login");
  };

  const googleLogin = () => {
    handleGoogleSignIn();
  };

 
  const handleGoogleCallback = async () => {
    if (hasGoogleCallbackExecuted.current) {
      return;
    }
  
    hasGoogleCallbackExecuted.current = true; 
    const searchParams = new URLSearchParams(window.location.search);

    setLoading(true);
    
    const code = searchParams.get("code");
    const token = searchParams.get("token");
    const refreshToken = searchParams.get("refreshToken");
    const navigationParam = Cookies.get("invoiceData");  // Fetching from URL query params
  
    console.log("Google Callback Parameters:", { code, token, navigationParam });
  
    try {
      // If code and navigationParam are present, handle the "freeInvoice" logic
      if (code && navigationParam) {
        console.log("Handling code exchange for freeInvoice...");
        const data = await handleCodeExchange(code);
        const { id_token, refresh_token } = data;
        
        if (id_token) {
          console.log("ID Token received:", id_token);
          sessionStorage.setItem(STORAGE_TOKEN, id_token);
          sessionStorage.setItem(STORAGE_REFRESH_TOKEN, refresh_token);
          
          const decodedToken: DecodedToken = jwtDecode(id_token);
          const userId = decodedToken["custom:custom_userid"];
          const user = await fetchData(userId);
  
          const groups = decodedToken["cognito:groups"] || [];
          setRole(groups);
          await fetchData(userId);
          navigate("/new-user"); 
        }
        Cookies.remove("navigation"); 
      }
  
      // Check for token and refreshToken, and handle the exchange
      if (token && refreshToken) {
        console.log("Token and refreshToken found.");
        sessionStorage.setItem(STORAGE_TOKEN, token);
        sessionStorage.setItem(STORAGE_REFRESH_TOKEN, refreshToken);
        
        const decodedToken: DecodedToken = jwtDecode(token);
        const userId = decodedToken["custom:custom_userid"];
        const user = await fetchData(userId);
  
        const groups = decodedToken["cognito:groups"] || [];
        setRole(groups);
        await fetchData(userId);
        navigate("/new-user");
      }
  
      // If only the code is available, handle the exchange
      if (code) {
        console.log("Handling code exchange...");
        const data = await handleCodeExchange(code);
        const { id_token, refresh_token } = data;
  
        if (id_token) {
          console.log("ID Token received:", id_token);
          sessionStorage.setItem(STORAGE_TOKEN, id_token);
          sessionStorage.setItem(STORAGE_REFRESH_TOKEN, refresh_token);
  
          const decodedToken: DecodedToken = jwtDecode(id_token);
          const userId = decodedToken["custom:custom_userid"];
          const user = await fetchData(userId);
          setUser(user);
  
          const groups = decodedToken["cognito:groups"] || [];
          setRole(groups);
          await fetchData(userId);
          navigate("/dashboard");
        }
      }
    } catch (error) {
      console.error("Error during Google Callback:", error);
    } finally {
      setLoading(false);
    }
  };
  
  

  /**
   * Modified register function that registers the user with Cognito
   * and can optionally fetch user data or handle post-registration steps.
   * @param username
   * @param password
   * @param attributes
   */
  const register = async (
    username: string,
    password: string,
    attributes: CognitoUserAttribute[],
  ): Promise<void> => {
    setLoading(true);
    setError(null);

    try {
      await cognitoRegister(username, password, attributes);
      navigate("/confirm-account");
    } catch (err: any) {
      setError(err.message || "An unknown error occurred during registration.");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authError) {
      setError(authError);
    }
  }, [authError]);

  return (
    <UseAuthContext.Provider
      value={{
        user: session,
        loading,
        error,
        login,
        googleLogin,
        handleGoogleCallback,
        logout,
        register,
      }}
    >
      {children}
      {/* Business Type Selector Modal */}
      <Modal
        visible={businessModalVisible}
        onClose={() => setBusinessModalVisible(false)}
      >
        <BusinessTypeSelector onClose={() => setBusinessModalVisible(false)} />
      </Modal>

      {!isPasswordChanged && !isRootUser && isPasswordModalShown && (
        <SubscriptionModal>
          <ChangePassword
            data-testid="change-password-modal"
            onClose={() => setIsPasswordModalShown(false)}
          />
        </SubscriptionModal>
      )}
    </UseAuthContext.Provider>
  );
};
