import React from "react";
import {
  CustomerIcon,
  ExpensesIcon,
  InvoiceIcon,
  ReportIcon,
} from "../../../assets/icons";
import { AlarmIcon } from "../../../assets/icons/alarm";
import { Button, SidebarDrawer } from "../../common";
import { useTranslation } from "react-i18next";

const subscriptionLimit = () => {
  const { t } = useTranslation("subscription");
  return (
    <div className="flex flex-col">
      <div className="flex justify-center pb-[60px]">
        <AlarmIcon />
      </div>
      <div className="px-[24px] flex flex-col gap-[16px]">
        <h1 className="text-[24px]" style={{ fontWeight: 600 }}>
          {t("subscription.limitExceeded")}
        </h1>
        <p>{t("subscription.limitMessage")}</p>
        <hr className="pt-[31px] pb-[44px]" />
      </div>
      <div className="flex flex-col justify-center items-center px-[24px]">
        <div className="w-[350px] mb-[58px]">
          <p className="word whitespace-nowrap text-center pb-[9px]">
            {t("subscription.activeUsage")}
          </p>
          <div className="border border-1 border-[#DFDFDF] xl:h-[194px] rounded-[7px] flex gap-[30px] w-full  flex-col justify-between px-[12px] py-[12px] lg:px-[30px] lg:pt-[22px] lg:pb-[31px] mt-[12px]">
            <div className="flex flex-col gap-[12px]">
              <div className="flex justify-between">
                <span className="flex">
                  <ExpensesIcon />
                  Expenses
                </span>
                <span>10/24</span>
              </div>
              <div className="flex justify-between">
                <span className="flex">
                  <InvoiceIcon />
                  Invoices
                </span>
                <span>21/24</span>
              </div>
              <div className="flex justify-between">
                <span className="flex">
                  <ReportIcon />
                  Reports
                </span>
                <span>23/24</span>
              </div>
              <div className="flex justify-between">
                <span className="flex">
                  <CustomerIcon />
                  Customers
                </span>
                <span>20/24</span>
              </div>
            </div>
          </div>
        </div>
        <Button variant="danger">{t("subscription.upgrade")}</Button>
      </div>
    </div>
  );
};

export default subscriptionLimit;
