import { useSearch } from "../useSearch";
import { profileService } from "../../services";
import { Bank } from "../../@types";

export const useBankAccountSearch = () => {
  return useSearch({
    service: profileService,
    endpoint: "companies/list-paystack-banks",
    queryParameter: "q",
    responseMapper: (data: any[]): any =>
      data.map((bankAccount: any) => ({
        ...bankAccount,
      })) as unknown as Bank,
  });
};
