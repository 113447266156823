import React, { useEffect, useState } from "react";
import { ItemInput } from "../../../../@types";
import {
  CloseIcon,
  CloseIconSmall,
  RemoveIcon,
  SearchIcon,
} from "../../../../assets/icons";
import ExpandDown from "../../../../assets/icons/ExpandDown";
import ExpandUp from "../../../../assets/icons/ExpandUp";
import { useInventorySearch } from "../../../../hooks/inventory";
import { calculateAdjustedRate, formatMoney } from "../../../../utils";
import { DropdownComponent } from "../../../core/Forms";
import { SearchInput } from "../../SearchInput";

interface Props {
  item: ItemInput;
  index: number;
  removeItem: (index: number) => void;
  updateCell: (
    rowIndex: number,
    columnId: string,
    value: string | number,
  ) => void;
  updateRow: (rowIndex: number, updatedRow: any) => void;
  errors: string[];
  updateError: (index: number, error: string[]) => void;
  isSearchInput: boolean;
  expanded: boolean;
  setExpanded: (index: number) => void;
  onUpdateVariation?: (id: number, field: string, value: string) => void;
  isInvoice: boolean;
  handleClearErrors: () => void;
  exchangeRate?: number;
  currency: string;
}

const LineItemMobile: React.FC<Props> = ({
  item,
  isSearchInput,
  index,
  removeItem,
  updateCell,
  updateRow,
  errors,
  updateError,
  expanded,
  setExpanded,
  onUpdateVariation,
  isInvoice,
  handleClearErrors,
  exchangeRate,
}) => {
  const handleUpdate = (field: keyof ItemInput, value: string | number) => {
    updateCell(index, field as string, value);
    if (errors && errors.includes(field as string)) {
      errors = errors.filter((err) => err !== field);
    }
  };
  const {
    data: inventories,
    isSearching: isInventorySearching,
    handleSearch: handleInventorySearch,
  } = useInventorySearch();
  const [selectedValue, setSelectedValue] = React.useState<string>("");
  const [showNewInventoryModal, setShowNewInventoryModal] =
    React.useState<boolean>(false);
  const handleInventorySearchDebounced = React.useCallback(
    handleInventorySearch,
    [],
  );
  const [isRowExpanded, setIsRowExpanded] = useState(true);
  const variationTypesOptions = Array.isArray(item.variations)
    ? item.variations.map((variation: { type: any }) => ({
        value: variation.type,
        label: variation.type,
      }))
    : [];

  const [variationError, setVariationError] = React.useState<string>("");

  // const removeItem = (index: number) => {
  //   // Only allow removal if there is more than one item in the array
  //   if (items.length > 1) {
  //     setItems(items.filter((_, i) => i !== index));
  //   }
  // };
  const handleItemSelect = React.useCallback(
    (itemData: ItemInput | null, selectedVariationType: string) => {
      if (itemData) {
        if (itemData.stockStatus !== "out-of-stock") {
          const { variation, ...rest } = itemData;

          const selectedVariationObject = itemData?.variations?.find(
            (v: { type: string }) => v.type === selectedVariationType,
          );
          const adjustedRate = calculateAdjustedRate(
            selectedVariationObject?.price || itemData.rate || 0,
            exchangeRate,
          );
          const updatedRow: any = {
            ...rest,
            quantity: 1,
            rate: selectedVariationObject
              ? selectedVariationObject.price
              : itemData.rate || 0,
            variation: selectedVariationObject || undefined,
          };
          const updatedRoww = itemData.variations
            ? {
                ...rest,
                id: itemData.id,
                name: itemData.name,
                variations: itemData.variations,
                quantity: 1,
                rate: adjustedRate,
                variation: {
                  id: selectedVariationObject?.id,
                  type: selectedVariationObject?.type,
                  quantity: selectedVariationObject?.quantity,
                  rate: adjustedRate,
                },
              }
            : {
                ...rest,
                name: itemData.name,
                id: itemData.id,
                quantity: 1,
                rate: adjustedRate,
              };

          // Call onUpdateVariation if available
          if (onUpdateVariation && selectedVariationObject) {
            onUpdateVariation(
              index,
              "price",
              String(selectedVariationObject.price),
            );
          }

          updateRow(index, updatedRoww);
          setVariationError("");
        } else {
          // Handle out-of-stock case
          setVariationError("Item is out of stock");
        }
      } else {
        // Handle clearing the table and selecting another item
        updateRow(index, { id: "", name: "", quantity: 0, rate: 0 });
        setVariationError("");
      }
    },
    [index, updateRow, onUpdateVariation, exchangeRate],
  );

  const handleSearchInputSelect = (item: ItemInput | null) => {
    const defaultVariationType = "DefaultType";
    handleItemSelect(item, defaultVariationType);
  };
  const [expandedTableIndex, setExpandedTableIndex] = useState<number | null>(
    0,
  );

  const handleToggleExpansion = () => {
    setExpanded(index);
  };

  return (
    <div className="relative items-center  ">
      <button
        type="button"
        className="ml-[20em] p-2 rounded mt-[-1em] "
        onClick={() => handleToggleExpansion()}
      >
        {expandedTableIndex === index ? <ExpandUp /> : <ExpandDown />}
        <span className="ml-2">{isRowExpanded ? "" : ""}</span>
      </button>

      {expanded && (
        // <div className="p-2  mt-[-4em] ml-[0.3em] items-center w-[] 320:ml-[5em] 360:ml-[2.5em] 375:ml-[1.5em] ">
        <div className="p-2 mt-[-2rem] ml-[1rem] items-center sm:ml-[2rem] w-[100%] ">
          <label className="text-gray-700 text-[12px] mt-[2em] pt-[4em] pl-4  ml-[-0.5em]">
            Item Name
          </label>
          {isSearchInput ? (
            <SearchInput
              placeholder="Enter Item Name"
              data={inventories}
              displayKey="name"
              isLoading={isInventorySearching}
              // classNames=" placeholder:text-[#B4B4B4] items-center ml-[0.4em]  placeholder:text-[13.25px] 320:w-[270px] 360:w-[300px] 375:w-[320px] 390:w-[320px] 412:w-[320px] 414:w-[320px] 430:w-[350px] bg-white placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4]"
              classNames="placeholder-gray-400 items-center ml-[0.2em] w-[90%] 390:w-[90%] 320:w-[80%] 360:w-[90%] 375:w-[90%] 390:w-[90%] 412:w-[90%] 414:w-[90%] 430:w-[90%] bg-white shadow-md rounded-md border-gray-300"
              value={item?.name || ""}
              selected={item.id ? item : null}
              clearIcon={CloseIconSmall}
              clearIconClass="mr-[0.9em] "
              handleChangeText={handleInventorySearchDebounced}
              onSelect={handleSearchInputSelect}
              dropdownClassName="ml-[em] w-[90%] 390:w-[90%] 320:w-[80%] 360:w-[80%] 375:w-[90%] 390:w-[90%] 412:w-[90%] 414:w-[90%] 430:w-[90%] "
              dropdownStyle={{
                maxHeight: "800px",
                overflowY: "auto",
              }}
              emptyMessage={"No Items yet"}
              searchKey={"name"}
              icon={<SearchIcon />}
            />
          ) : (
            <input
              type="text"
              placeholder="Enter Item Name"
              className="bg-white placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4]  p-2 ml-[-1.5em] placeholder:text-[13.25px] w-[90%] 320:w-[80%] 360:w-[80%] 375:w-[80%] 390:w-[90%] 412:w-[90%] 414:w-[90%] 430:w-[90%]"
              value={item?.name}
              onChange={({ target }) => handleUpdate("name", target.value)}
            />
          )}
          {errors.includes("name") && (
            <p className="text-red-500 text-xs mt-1">Please enter a value</p>
          )}

          <div className="  mt-[1em] ">
            {isInvoice && (
              <label className="text-gray-700 text-[12px] mt-[2em] pt-[4em] pl-3 ml-[-0.5em]">
                Variation
              </label>
            )}
            {isInvoice && (
              <DropdownComponent
                options={variationTypesOptions}
                value={selectedValue}
                onChange={(name, value) => {
                  handleItemSelect(item, value);
                }}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                placeholder="Select Variation"
                name="type"
                variationIndex={index}
                dropdownClassName="  placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4] items-center  placeholder:text-[11.25px]  placeholder:text-[#A4A4A4] w-[90%] 390:w-[90%] 320:w-[80%] 360:w-[90%] 375:w-[90%] 390:w-[90%] 412:w-[90%] 414:w-[90%] 430:w-[90%]"
                iconClassName=" mr-[10px] mt-[-0.4em] 360:mr-[3em] 320:mr-[5.3em]"
              />
            )}
          </div>

          {isInvoice && (
            <div className="flex ">
              <div className="flex flex-col ml-[1em]">
                <label className="text-gray-700 text-[12px] mt-[0.5em] ml-[-0.5em] ">
                  Quantity
                </label>
                <input
                  type="number"
                  className={` p-2 ml-[-0.8em] w-[75%] 390:w-[80%] 375:w-[90%] 414:w-[80%] 360:w-[75%] bg-white placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4]   ${
                    errors.includes("quantity") ? "border-red-500" : ""
                  }`}
                  placeholder="Quantity"
                  value={item?.quantity}
                  onChange={({ target }) =>
                    handleUpdate("quantity", target.value)
                  }
                />
                {errors.includes("quantity") && (
                  <p className="text-red-500 text-xs mt-1">
                    Please enter a value
                  </p>
                )}
              </div>
              <div className="flex flex-col">
                <label className="text-gray-700 text-[12px] mt-[0.5em]">
                  Rate
                </label>
                <input
                  type="number"
                  className={` p-2 ml-[-0.8em] w-[75%] 390:w-[80%] 375:w-[90%] 414:w-[80%] 360:w-[75%]  bg-white placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4] ${
                    errors.includes("rate") ? "border-red-500" : ""
                  }`}
                  placeholder="Rate"
                  value={item?.rate}
                  onChange={({ target }) => handleUpdate("rate", target.value)}
                />
                {errors.includes("rate") && (
                  <p className="text-red-500 text-xs mt-1">
                    Please enter a value
                  </p>
                )}
              </div>
            </div>
          )}
          {!isInvoice && (
            <div className="flex  ">
              <div className="flex flex-col ml-[-1em]">
                <label className="text-gray-700 text-[12px] mt-[0.5em] ml-[-0.5em] ">
                  Quantity
                </label>
                <input
                  type="number"
                  className={` p-2 ml-[-0.8em] w-[75%] 390:w-[80%] 375:w-[90%] 414:w-[80%] 360:w-[75%]  bg-white placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4]   ${
                    errors.includes("quantity") ? "border-red-500" : ""
                  }`}
                  placeholder="Quantity"
                  value={item?.quantity}
                  onChange={({ target }) =>
                    handleUpdate("quantity", target.value)
                  }
                />
                {errors.includes("quantity") && (
                  <p className="text-red-500 text-xs mt-1">
                    Please enter a value
                  </p>
                )}
              </div>
              <div className="flex flex-col ml-[-0.5em]">
                <label className="text-gray-700 text-[12px] mt-[0.5em]">
                  Rate
                </label>
                <input
                  type="number"
                  className={` p-2 w-[90%]  bg-white placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4] ${
                    errors.includes("rate") ? "border-red-500" : ""
                  }`}
                  placeholder="Rate"
                  value={item?.rate}
                  onChange={({ target }) => handleUpdate("rate", target.value)}
                />
                {errors.includes("rate") && (
                  <p className="text-red-500 text-xs mt-1">
                    Please enter a value
                  </p>
                )}
              </div>
            </div>
          )}
          {isInvoice && (
            <div className="flex flex-col  ">
              <label className="text-gray-700 text-[12px] mt-[2em] ml-[0.8em]">
                Total Amount
              </label>
              <input
                type="text"
                readOnly
                placeholder="Amount"
                className="bg-white placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4] ml-[0.4em] p-2 placeholder:text-[13.25px] w-[90%]  320:w-[80%] 360:w-[85%] 375:w-[90%] 390:w-[90%] 412:w-[90%] 414:w-[90%] 430:w-[90%]"
                value={
                  !isNaN(Number(item?.quantity)) && !isNaN(Number(item?.rate))
                    ? (
                        Number(item?.quantity) * Number(item?.rate)
                      ).toLocaleString()
                    : ""
                }
              />
            </div>
          )}
          {!isInvoice && (
            <div className="flex flex-col ml-[-1.5em]  ">
              <label className="text-gray-700 text-[12px] mt-[2em] ml-[0.5em]">
                Total Amount
              </label>
              <input
                type="text"
                readOnly
                placeholder="Amount"
                className="bg-white placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4] ml-[0.4em] p-2 placeholder:text-[13.25px] w-[90%] 390:w-[90%] 320:w-[80%] 360:w-[85%] 375:w-[90%] 412:w-[90%] 414:w-[90%] 430:w-[90%]"
                value={
                  !isNaN(Number(item?.quantity)) && !isNaN(Number(item?.rate))
                    ? (
                        Number(item?.quantity) * Number(item?.rate)
                      ).toLocaleString()
                    : ""
                }
              />
            </div>
          )}

          {index !== 0 && (
            <button
              type="button"
              className="ml-[20em] text-[red] p-2 rounded mt-[2em]"
              onClick={() => removeItem(index)}
            >
              <CloseIcon color="red" />
              <span className="ml-2"></span>
            </button>
          )}
        </div>
      )}
      {!expanded && (
        <div className="grid grid-cols-2  p-3 mt-[-3.5em] ml-[-0.3em]">
          <div className="">
            <input
              type="text"
              readOnly
              placeholder="Item Name"
              value={item?.name}
              className="bg-white placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4]  p-2 placeholder:text-[13.25px] w-[100px]"
            />
          </div>
          <div className="text-right">
            <input
              type="text"
              readOnly
              placeholder="Amount"
              className="bg-white placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4] p-2 placeholder:text-[13.25px] w-[100px]"
              value={
                !isNaN(Number(item?.quantity)) && !isNaN(Number(item?.rate))
                  ? (Number(item?.quantity) * Number(item?.rate)).toFixed(2)
                  : ""
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { LineItemMobile };
