import React, { useEffect } from "react";
import {
  subscriptions,
  useUserStore,
  useSubscriptionStore,
} from "../../../state";
import {
  ExpensesIcon,
  InventoryIcon,
  InvoiceIcon,
  ReportIcon,
} from "../../../assets/icons";
import { useReportStore } from "../../../state/useReportStore";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "../../../state/useCompanyStore";

const SubscriptionUsage = ({
  subscriptionState,
}: {
  subscriptionState: subscriptions | null;
}) => {
  const { t } = useTranslation("subscription");
  const userStore = useCompanyStore();
  const isRetail = userStore.isRetailBusiness();
  const { subscriptionData, fetchActiveSubscription } = useSubscriptionStore();

  useEffect(() => {
    fetchActiveSubscription();

    const interval = setInterval(() => {
      fetchActiveSubscription();
    }, 60000);

    return () => clearInterval(interval);
  }, [fetchActiveSubscription]);

  return (
    <div className="w-full lg:w-[72.4%]">
      <p className="word whitespace-nowrap">{t("subscription.activeUsage")}</p>
      <div className="border border-1 border-[#DFDFDF] xl:h-[194px] rounded-[7px] flex gap-[30px] w-full  flex-col justify-between px-[12px] py-[12px] lg:px-[30px] lg:pt-[22px] lg:pb-[31px] mt-[12px]">
        <div className="flex flex-col gap-[12px]">
          <div className="flex justify-between">
            <span className="flex items-center gap-[1px]">
              <ExpensesIcon />
              Expenses
            </span>
            <span>
              {subscriptionData?.usage?.expenses
                ? subscriptionData?.usage?.expenses
                : 0}
              /
              {subscriptionData?.limits?.expenses
                ? subscriptionData?.limits?.expenses
                : 0}
            </span>
          </div>

          <div className="flex justify-between">
            <span className="flex items-center gap-[1px]">
              <InvoiceIcon />
              Invoices
            </span>
            <span>
              {subscriptionData?.usage?.invoices
                ? subscriptionData?.usage?.invoices
                : 0}
              /
              {subscriptionData?.limits?.invoices
                ? subscriptionData?.limits?.invoices
                : 0}
            </span>
          </div>

          <div className="flex justify-between">
            <span className="flex items-center gap-[1px]">
              <ReportIcon />
              Report
            </span>
            <span>
              {subscriptionData?.usage?.reports
                ? subscriptionData?.usage?.reports
                : 0}
              /
              {subscriptionData?.limits?.reports
                ? subscriptionData?.limits?.reports
                : 0}
            </span>
          </div>

          <div className="flex justify-between ">
            <span className="flex items-center gap-[1px]">
              <InventoryIcon />
              {isRetail ? "Inventories" : "Services"}
            </span>
            <span>
              {isRetail
                ? subscriptionData?.usage?.inventories
                : subscriptionData?.usage?.services}{" "}
              /
              {isRetail
                ? subscriptionData?.limits?.inventories
                : subscriptionData?.limits?.services}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionUsage;
