import axios, { AxiosInstance } from "axios";
import config from "../config";
import { setupAuthInterceptor } from "../utils";

export const createServiceService = (): AxiosInstance => {
  const serviceService = axios.create({
    baseURL: config.serviceServiceURL,
  });

  setupAuthInterceptor(serviceService);

  return serviceService;
};

const serviceService = createServiceService();

export { serviceService };
