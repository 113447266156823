import PricingPlan from "./PricingPlan";
import { monthlyPlan, yearlyPlan } from "../../constants/Subscription";
import { useEffect, useState } from "react";
import { Plan } from "../../@types/subscripton";

interface SubscriptionBoxProps {
  show: (plan: string) => void;
  isChoosing: boolean;
  indexState: number | null;
}

const SubscriptionBox = ({
  show,
  isChoosing,
  indexState,
}: SubscriptionBoxProps) => {
  const [month, setMonth] = useState(true);
  const [year, setYear] = useState(false);
  const [plans, setPlans] = useState<Plan[]>([]);
  const handleMonthly = () => {
    setMonth(true);
    setYear(false);
    setPlans([...monthlyPlan]);
  };
  const handleYearly = () => {
    setMonth(false);
    setYear(true);
    setPlans([...yearlyPlan]);
  };
  useEffect(() => {
    handleMonthly();
  }, []);
  return (
    <>
      <div className="w-full">
        <div className="flex justify-center lg:mb-[64px]">
          <div className="text-center border inline-flex items-center border-[11, 61, 121, 0.16] rounded-full p-[5px]">
            <p
              onClick={handleMonthly}
              className={`${
                month && !year
                  ? "text-[#fff] bg-[#111827]"
                  : "bg-transparent text-[#111827] hover:bg-[#E5EAFF]"
              }  font-semibold text-[16px] px-[19px] py-[10px] rounded-full cursor-pointer`}
            >
              Monthly
            </p>
            <div
              onClick={handleYearly}
              className={`${
                year && !month
                  ? "text-[#fff] bg-[#111827]"
                  : "bg-transparent text-[#111827] hover:bg-[#E5EAFF]"
              } py-[8px] pl-[16px] pr-[8px] inline-flex gap-[11.08px] items-center rounded-full cursor-pointer`}
            >
              <span className="text-[16px] leading-[20px]">Yearly</span>
              <span className="bg-[#20A852] w-[124px] h-[24px] flex items-center justify-center text-[12px] font-bold leading-[20px] rounded-[36px] text-center tracking-[1.2px] whitespace-nowrap text-[#fff]">
                2 MONTHS FREE
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row flex-wrap justify-center gap-6 items-center w-full mt-[1em]">
          {plans.map((plan, index) => (
            <PricingPlan
              key={index}
              title={plan.title}
              price={plan.price}
              features={plan.features}
              buttonText={plan.buttonText}
              plan={plan.plan}
              index={index}
              show={show}
              duration={plan.duration}
              subPrice={plan.subPrice}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SubscriptionBox;
