import React, { useState } from "react";

interface Option {
  value: string;
  label: string;
  icon?: React.ReactNode;
}

export interface ToggleButtonProps {
  options: Option[];
  value?: string;
  name?: string;
  iconClassName?: string;
  dropdownClassName?: string;
  onChange?: (name: string, value: string) => void;
  optionStyles?: {
    [key: string]: React.CSSProperties; // key is the option value
  };
  setSelectedValue?: (value: string) => void;
}

const ToggleButton = ({
  iconClassName,
  name,
  value,
  dropdownClassName,
  options,
  setSelectedValue,
  onChange,
  optionStyles,
}: any) => {
  const [showOptions, setShowOptions] = useState(false);

  //   const iconContainerClassName =
  return (
    <>
      <div className="relative">
        <button
          onClick={() => setShowOptions(!showOptions)}
          className="border border-[#A4A4A4] border-opacity-30 rounded-md p-[10px]"
        >
          {showOptions ? (
            <svg
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.31068 1.12248L8.39689 5.3957C9.06557 6.32156 8.40402 7.61539 7.26194 7.61539L1.73806 7.61539C0.595982 7.61539 -0.0655669 6.32156 0.603108 5.3957L3.68932 1.12248C4.08848 0.5698 4.91152 0.569801 5.31068 1.12248Z"
                fill="#212121"
              />
            </svg>
          ) : (
            <svg
              className={`h`}
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.68932 6.87752L0.603107 2.6043C-0.0655678 1.67844 0.595981 0.384615 1.73806 0.384615L7.26194 0.384615C8.40402 0.384615 9.06557 1.67844 8.39689 2.6043L5.31068 6.87752C4.91152 7.4302 4.08848 7.4302 3.68932 6.87752Z"
                fill="#212121"
              />
            </svg>
          )}
        </button>

        {showOptions && (
          <ul className={`z-50 absolute ${dropdownClassName}`}>
            {options.map((option: any) => (
              <li
                key={option.value}
                className="py-2 px-2 cursor-pointer items-center font-light flex"
                onClick={() => {
                  setShowOptions(false);
                  if (setSelectedValue) {
                    setSelectedValue(option.value);
                  }
                  if (onChange) {
                    onChange(name || "", option.value);
                  }
                }}
              >
                {option.icon && (
                  <span className="mr-2">
                    <span className="icon-container inline-block align-middle ine-height-[1em] h-4 lg:w-4 lg:">
                      {option.icon}
                    </span>
                  </span>
                )}
                <span style={optionStyles && optionStyles[option.value]}>
                  {option.label}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default ToggleButton;
