import React, { useState } from "react";
import ServiceForm from "./ServiceForm";
import { useNavigate } from "react-router-dom";
import { ServiceData, ServicePriceData } from "../../../@types/service";
import { convertToFloat } from "../../../utils/convertToFloat";
import { validateCreateService } from "../../../modules/services/services";
import { createNewService } from "../../../backend-services/service";
import { toast } from "react-hot-toast";
import { calculateDurationFromDate } from "../../../utils/duration";
import { useServiceStore } from "../../../state/useServiceStore";
import { useTranslation } from "react-i18next";

interface ServicePageProps {
  showBack?: boolean;
}

const ServicePage: React.FC<ServicePageProps> = ({ showBack = true }) => {
  const { t } = useTranslation("services");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [price, setPrice] = useState<ServicePriceData[]>([]);
  const [serviceData, setServiceData] = useState<ServiceData>({
    name: "",
    duration: "",
    description: "",
    price: [],
    status: "Active",
  });
  const [error, setError] = React.useState({
    name: "",
    charge: {
      NGN: "",
      EUR: "",
      USD: "",
    },
  });
  const navigate = useNavigate();
  const handleBack = () => navigate("/services");
  const { createService } = useServiceStore((state) => ({
    createService: state.createService,
  }));

  const handleCreateService = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newPrice = price.map((item) => ({
      ...item,
      amount: convertToFloat(item.amount),
    }));

    const { hasErrors, errors } = validateCreateService({
      name: serviceData.name,
      duration: serviceData.duration,
      description: serviceData.description,
      price: newPrice,
    });
    if (hasErrors) {
      setError(errors);
    } else {
      try {
        setIsLoading(true);
        let newServiceData = { ...serviceData, price: newPrice };
        if (!serviceData.duration) {
          const today = new Date();
          newServiceData = { ...newServiceData, duration: "0 hours" };
        }
        const res = await createService(newServiceData);
        toast.success("Service was created Successfully");
        navigate("/services");
        setIsLoading(false);
      } catch (e: any) {
        if (e) {
          setErrorMessage(e.message || "An error occurred");
          toast.error(e.message);
        } else {
          setErrorMessage("An error occurred while creating inventory");
          toast.error("An error occurred while creating inventory");
        }
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="mt-8 mb-12">
        <div className="flex items-center gap-4">
          <div className="py-2" onClick={handleBack}>
            <svg
              className="w-[24px] h-[24px]"
              width="10"
              height="16"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.5 1L1.5 8L8.5 15" stroke="#33363F" strokeWidth="2" />
            </svg>
          </div>
          <div className="font-medium text-[24px] hidden lg:block lg:mt-[-0.1em]">
            {t("service.createService")}
          </div>
          <h1 className="text-[18px] md:text-[20px] font-semibold lg:font-bold lg:hidden">
            {t("service.createService")}
          </h1>
        </div>
        <div className=" px-6 pt-2 pb-[80px] mt-4 md:px-10">
          <ServiceForm
            serviceData={serviceData}
            setServiceData={setServiceData}
            handleCreateService={handleCreateService}
            setPrice={setPrice}
            price={price}
            error={error}
            setError={setError}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default ServicePage;
