import jwtDecode from "jwt-decode";
import { STORAGE_TOKEN } from "../constants";

export interface DecodedToken {
  given_name: string;
  family_name: string;
  email: string;
  "custom:custom_business_name": string;
  phone_number: string;
  "custom:custom_businessType": string;
  locale: string;
  "custom:custom_FirstLogin": string;
  "cognito:groups": string[];
  sub: string;
  "custom:custom_userid": string;
}

/**
 * Retrieves the authentication token from sessionStorage.
 * @returns The auth token as a string or null if not found.
 */
export const getToken = (): string | null => {
  return sessionStorage.getItem(STORAGE_TOKEN);
};

/**
 * Stores the authentication token in sessionStorage.
 * @param token - The auth token to store.
 */
export const setToken = (token: string): void => {
  sessionStorage.setItem(STORAGE_TOKEN, token);
};

/**
 * Removes the authentication token from sessionStorage.
 */
export const removeToken = (): void => {
  sessionStorage.removeItem(STORAGE_TOKEN);
};
/**
 * Decodes a JWT token, extracts user information, and fetches user data.
 * This function should be used within a React component to set roles via Zustand.
 * @param token - The JWT token to decode.
 * @param setRole - Zustand store action to set the role.
 * @returns A promise that resolves to the user data.
 */
export const handleTokenAndFetchUserData = async (
  token: string,
  setRole?: (groups: string[]) => void,
): Promise<any> => {
  try {
    // Decode the token
    const decodedToken = jwtDecode<DecodedToken>(token);

    // Extract custom user ID and groups
    const userId = decodedToken["custom:custom_userid"];
    const groups = decodedToken["cognito:groups"] || [];

    // Set the user's role based on groups
    setRole?.(groups);

    // Fetch and return user data
    return { userId, groups };
  } catch (error) {
    console.error("Error handling token or fetching user data:", error);
    throw new Error("Failed to process token or fetch user data.");
  }
};
