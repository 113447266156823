import React from "react";

const ArrowPointer = () => {
  return (
    <svg
      width="42"
      height="44"
      viewBox="0 0 42 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.6288 2.90319C40.5761 1.52349 39.4149 0.447751 38.0352 0.500466L15.5516 1.35955C14.1719 1.41227 13.0961 2.57348 13.1488 3.95318C13.2016 5.33289 14.3628 6.40862 15.7425 6.35591L35.7279 5.59228L36.4915 25.5777C36.5442 26.9574 37.7054 28.0331 39.0851 27.9804C40.4649 27.9277 41.5406 26.7665 41.4879 25.3868L40.6288 2.90319ZM3.83397 43.699L39.9646 4.69763L36.2966 1.29966L0.166026 40.301L3.83397 43.699Z"
        fill="#088306"
      />
    </svg>
  );
};

export { ArrowPointer };
