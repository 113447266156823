export function formatTimeWithMeridiem(date: Date) {
  const hour = date.getHours();
  const minute = date.getMinutes();
  const meridiem = hour >= 12 ? "pm" : "am";

  // Format the hour part with a leading zero if needed
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
  const formattedMinute = minute < 10 ? `0${minute}` : minute;

  return `${formattedHour}:${formattedMinute}${meridiem}`;
}
