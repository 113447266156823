import React from "react";
import { CashBalanceIcon } from "../../assets/icons/cashBalanceIcon";
import { CustomerIcon, TransactionIcon } from "../../assets/icons";
import { TrendStatsCard } from "./TrendStatsCard";
import { useTranslation } from "react-i18next";
import { Calendar } from "../../assets/icons/calendar";
import { DashboardStats } from "../../@types";
import BudgetHistory from "./BudgetHistory/BudgetHistory";

interface OverviewProps {
  showCalendar: boolean;
  handleCalendarToggle: () => void;
  selectedLabel: string;
  dashboardStats: DashboardStats;
}

const Overview = ({
  showCalendar,
  handleCalendarToggle,
  selectedLabel,
  dashboardStats,
}: OverviewProps) => {
  const { t } = useTranslation("dashboard");

  return (
    <div className="py-3 md:py-6 bg-white mt-4 rounded-lg">
      <div className="px-3 md:px-6 flex flex-col-reverse gap-3 md:flex-row md:justify-between md:items-center w-full">
        <h2 className="text-[#1D2939] font-semibold leading-4 text-[16px] md:text-[20px]">
          Overview
        </h2>

        <div
          className="md:justify-end md:flex hidden"
          style={{ cursor: showCalendar ? "pointer" : "default" }}
        >
          <span
            className="mr-[-2em] mt-[0.8em] z-0"
            onClick={handleCalendarToggle}
          >
            <Calendar />
          </span>
          <div onClick={handleCalendarToggle}>
            <button
              className={`pt-[0.65em] bg-white text-[#98A2B3] text-[16px] border border-[#D0D5DD] h-[48px] rounded-lg right-0 flex justify-center z-0 ${
                selectedLabel?.length > 20
                  ? "w-[260px]"
                  : selectedLabel?.length > 7
                    ? "w-[190px]"
                    : "w-[140px]"
              }`}
              value={selectedLabel}
            >
              {selectedLabel}
            </button>
          </div>
        </div>

        <div className="md:hidden mb-[1em]">
          <div
            onClick={handleCalendarToggle}
            className="bg-white relative flex items-center gap-3 border border-[#A4A4A4] rounded-md  h-[48px] w-full"
            style={{ cursor: showCalendar ? "pointer" : "default" }}
          >
            <div className="ml-[0.5em] z-0" onClick={handleCalendarToggle}>
              <Calendar />
            </div>

            <div
              onClick={handleCalendarToggle}
              className={`text-[#A4A4A4] text-[16px] rounded-md z-0 mb-[-2px] w-[350px]`}
            >
              {selectedLabel}
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-3 mb-6 md:my-6 border border-gray-100" />
      <div className="px-3 md:px-6 flex flex-col lg:flex-row gap-4 mt-3 md:mt-4">
        {/* Cash balance */}
        <TrendStatsCard
          title={t("cashBalance")}
          titleIcon={<CashBalanceIcon />}
          isUptrend={dashboardStats?.cashBalance.percentageChange > 0}
          toolTipText={t("CashbalanceTooltip")}
          value={dashboardStats?.cashBalance?.value}
          isCashBalance={true}
          trendtitle={selectedLabel}
        />
        <div className="hidden lg:block w-full">
          <TrendStatsCard
            title={t("newCustomer")}
            titleIcon={<CustomerIcon color="#0469C5" />}
            isUptrend={dashboardStats?.customers.percentageChange > 0}
            toolTipText={t("NewCustomerTooltip", {
              selectedLabel: "Last 30 day",
            })}
            value={dashboardStats?.customers?.value}
          />
        </div>
        <div className="hidden lg:block w-full">
          <TrendStatsCard
            title={t("transactionLabel")}
            titleIcon={<TransactionIcon />}
            isUptrend={dashboardStats?.transactions.percentageChange > 0}
            toolTipText={t("TransactionsTooltip", {
              selectedLabel: "Last 30 day",
            })}
            value={dashboardStats?.transactions.value}
          />
        </div>

        {/* Mobile */}
        <div className="flex lg:hidden w-full gap-4 md">
          <TrendStatsCard
            title={t("newCustomer")}
            titleIcon={<CustomerIcon color="#0469C5" />}
            isUptrend={dashboardStats?.customers.percentageChange > 0}
            toolTipText={t("NewCustomerTooltip", {
              selectedLabel: "Last 30 day",
            })}
            value={dashboardStats?.customers?.value}
          />
          <TrendStatsCard
            title={t("transactionLabel")}
            titleIcon={<TransactionIcon />}
            isUptrend={dashboardStats?.transactions.percentageChange > 0}
            toolTipText={t("TransactionsTooltip", {
              selectedLabel: "Last 30 day",
            })}
            value={dashboardStats?.transactions.value}
          />
        </div>

        <BudgetHistory toolTipText={t("budgetTooltip")} />
      </div>
    </div>
  );
};

export default Overview;
