import { Table } from "flowbite-react";
import { ItemInput } from "../../../../@types";
import React, { useEffect, useState } from "react";
import ServiceLineItem from "./ServiceLineItem";
import PlusBoxIcon from "../../../../assets/icons/PlusBoxIcon";
import { ServiceLineItemMobile } from "./ServiceLineItemMobile";
import { useTranslation } from "react-i18next";

export interface TableColumn {
  toLowerCase(): string;
  label: string;
  key: keyof ItemInput;
}

export interface Props {
  columns: TableColumn[];
  items: ItemInput[];
  isSearchInput: boolean;
  addItem: () => void;
  removeItem: (index: any) => void;
  updateCell: (index: number, field: string, value: string | number) => void;
  updateRow: (index: number, item: ItemInput) => void;
  isImportedToInvoice?: boolean;
  selectedCurrencyLabel: string;
  handleClearErrors: () => void;
  isInvoice: boolean;
  currency: string;
  exchangeRate: number;
}

const ServiceLineItemTable: React.FC<Props> = ({
  columns,
  items,
  isSearchInput = false,
  addItem,
  removeItem,
  updateCell,
  updateRow,
  isImportedToInvoice,
  selectedCurrencyLabel,
  isInvoice = false,
  handleClearErrors,
  currency,
  exchangeRate,
}) => {
  const [errors, setErrors] = React.useState<string[][]>(items.map(() => []));
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { t } = useTranslation("invoices");
  const [isMobileTableExpanded, setIsMobileTableExpanded] = useState(true);
  const [isMiniCardVisible, setIsMiniCardVisible] = useState(false);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          const tableElement = document.querySelector(
            '[data-testid="table-element"]',
          ) as HTMLElement;
          if (tableElement) {
            tableElement.style.overflowX = "visible";
            observer.disconnect();
          }
        }
      }
    });

    observer.observe(document, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const clearErrorsForIndex = (index: number) => {
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = [];
      return newErrors;
    });
  };

  const updateRowAndValidate = (index: number, item: ItemInput) => {
    updateRow(index, item);
    clearErrorsForIndex(index);
  };

  // Function to update error state
  const updateError = (index: number, error: string[]) => {
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = error;
      return newErrors;
    });
  };

  const validateItems = (items: ItemInput[]) => {
    const newErrors = items.map((item) => {
      const fields: string[] = [];
      if (!item.name) fields.push("name");
      if (!item.duration) fields.push("duration");
      if (!item.price || item.price < 0) fields.push("price");
      return fields;
    });
    setErrors(newErrors);
    return items.every((item) => {
      return !(
        !item?.name ||
        !item?.duration ||
        !item?.price ||
        item.price < 0
      );
    });
  };

  const [expandedRowIndex, setExpandedRowIndex] = useState<number>(-1);
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedTableIndex, setExpandedTableIndex] = useState<number | null>(
    0,
  );
  const handleAddItem = () => {
    if (validateItems(items)) {
      setErrors([...errors, []]);
      addItem();
    }
  };

  const handleInfoCircleHover = () => {
    setIsMiniCardVisible(true);
  };

  const handleInfoCircleMouseLeave = () => {
    setIsMiniCardVisible(false);
  };
  return (
    <>
      <div>
        <Table className="w-full md:w-full lg:w-full xl:w-full 2xl:w-full 3xl:w-full 4xl:w-full  text-sm text-left text-gray-500 dark:text-gray-400">
          {isMobile ? null : (
            <Table.Head className="text-white ">
              {columns?.map((column, index) => (
                <Table.HeadCell
                  key={index}
                  className={`bg-[#044E97] md:text-white lg:text-white xl:text-white 2xl:text-white ${
                    index === columns.length - 1 ? "" : "border-r"
                  } text-sm capitalize md:w-full lg:w-full xl:w-full 2xl:w-full 3xl:w-full 4xl:w-full  `}
                >
                  {column.label}
                </Table.HeadCell>
              ))}
            </Table.Head>
          )}
          <br />
          <Table.Body className="divide-y">
            {items.map((item, index) => (
              <React.Fragment key={`${item.id}-${index}`}>
                {isMobile ? (
                  <ServiceLineItemMobile
                    key={`${item.id}-${index}`}
                    index={index}
                    isSearchInput={isSearchInput}
                    item={items[index]}
                    removeItem={removeItem}
                    updateCell={updateCell}
                    updateRow={updateRowAndValidate}
                    errors={errors[index] || []}
                    updateError={updateError}
                    expanded={expandedTableIndex === index}
                    setExpanded={setExpandedTableIndex}
                    selectedCurrencyLabel={selectedCurrencyLabel}
                    currency={currency}
                    exchangeRate={exchangeRate}
                  />
                ) : (
                  <ServiceLineItem
                    key={`${item.id}-${index}`}
                    index={index}
                    columns={columns}
                    isSearchInput={isSearchInput}
                    item={items[index]}
                    removeItem={removeItem}
                    updateCell={updateCell}
                    updateRow={updateRowAndValidate}
                    errors={errors[index] || []}
                    updateError={updateError}
                    isInvoice={isInvoice}
                    selectedCurrencyLabel={selectedCurrencyLabel}
                    items={[]}
                    currency={currency}
                    exchangeRate={exchangeRate}
                  />
                )}
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>

        <div
          className={
            isImportedToInvoice ? "flex justify-end" : "flex justify-end"
          }
        >
          <span
            onClick={handleAddItem}
            className="flex justify-end text-[#044E97] mt-[23px] font-medium cursor-pointer lg:text-[16px] mr-[1.5em] md:ml-[em] xl:ml-[em]  xl:mt-[0.8em] text-[12px] md:text-[14px] px-[0.8em]"
          >
            {t("addLine")}
            <div className="xl:mt-[0.2em] biglg:mt-[0.2em] ml-[0.3em]">
              <PlusBoxIcon />
            </div>
          </span>
        </div>
      </div>
    </>
  );
};

export { ServiceLineItemTable };
