import React, { useState } from "react";
import {
  RestockItem,
  Variation,
  VariationItem,
} from "../../../@types/inventory";
import { EditVariationForm } from "./EditVarationForm";
import { InputComponent } from "../../core/Forms";
import { commasToRestockNumber } from "../../../utils/convertToFloat";
import { clearFieldError } from "../../../utils/handleErrors";
import { useTranslation } from "react-i18next";

type errorProps = {
  quantity: string;
  rate: string;
  costPrice: string;
};

interface EditInventoryFormProps {
  inventory: RestockItem;
  setInventories: (inventories: RestockItem) => void;
  variations: VariationItem[];
  setVariations: (VariationItem: VariationItem[]) => void;
  variationList: any;
  error: errorProps;
  setError: (error: errorProps) => void;
  variationError: any;
}

const EditInventoryForm = ({
  inventory,
  setInventories,
  variations,
  setVariations,
  variationList,
  error,
  setError,
  variationError,
}: EditInventoryFormProps) => {
  const [restockDate, setRestockDate] = useState("");
  const { t } = useTranslation("inventories");
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "date") {
      const updatedInventory = { ...inventory, [name]: value };
      setInventories(updatedInventory);
      setRestockDate(value);
    }

    if (name == "quantity") {
      const numericValue = value.replace(/\D/g, "");
      const updatedInventory = { ...inventory, [name]: numericValue };
      setInventories(updatedInventory);
      clearFieldError(name, error, setError);
    }

    if (name === "rate" || name === "costPrice") {
      const resValue = commasToRestockNumber(value);
      const updatedInventory = { ...inventory, [name]: resValue };
      setInventories(updatedInventory);
      clearFieldError(name, error, setError);
    }
  };

  const handleAddVariation = (newVariation: any) => {
    setVariations([...variations, newVariation]);
  };

  const handleRemoveVariation = (id: any) => {
    const updatedVariations = variations.filter(
      (variation, index) => index !== id,
    );
    setVariations(updatedVariations);
  };

  const handleUpdateVariation = (id: number, field: string, value: string) => {
    const updatedVariations = variations.map((variation, index) => {
      if (index === id) {
        if (field === "costPrice" || field === "price") {
          const varVal = commasToRestockNumber(value);
          return { ...variation, [field]: varVal };
        } else if (field === "quantity") {
          const numericValue = value.replace(/\D/g, "");
          return { ...variation, [field]: numericValue };
        } else {
          return { ...variation, [field]: value };
        }
      }
      return variation;
    });
    setVariations(updatedVariations);
  };

  const [changedPrice, setChangedPrice] = useState(false);

  return (
    <div className="mt-8">
      <div className="">
        <div className="md:flex items-center gap-6">
          <div className="w-full mb-6 md:mb-0">
            <label className="block text-[#212121] tracking-wide text-[16px] font-normal">
              {variations?.length > 0 ? "Total Quantity" : " Quantity"}
            </label>
            <InputComponent
              className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4"
              type="text"
              name="quantity"
              placeholder="Quantity"
              value={inventory.quantity}
              handleChange={handleInputChange}
              error={error.quantity}
              disabled={variations?.length > 0 ? true : false}
            />
          </div>

          <div className="w-full mb-6 md:mb-0">
            <label className="block text-[#212121] tracking-wide text-[16px] font-normal">
              Date
            </label>
            <InputComponent
              type="date"
              className="w-full"
              name="date"
              defaultDate={inventory.date}
              value={restockDate}
              handleChange={handleInputChange}
            />
          </div>
        </div>

        {!(variations?.length > 0) && (
          <div className="flex gap-2 items-center cursor-pointer my-8">
            <InputComponent
              type="checkbox"
              handleChange={() => setChangedPrice(!changedPrice)}
            />
            <label className="whitespace-nowrap">
              The cost of this product has changed
            </label>
          </div>
        )}

        {changedPrice && (
          <div className="md:flex items-center gap-6">
            <div className="w-full mb-6 md:mb-0">
              <label className="block text-[#212121] tracking-wide text-[14px] font-normal">
                {t("inventory.newCostPrice")}
              </label>
              <InputComponent
                className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4"
                type="text"
                placeholder="N0.00"
                name="costPrice"
                value={inventory.costPrice}
                handleChange={handleInputChange}
                error={error.costPrice}
              />
            </div>
            <div className="w-full mb-6 md:mb-0">
              <label className="block text-[#212121] tracking-wide text-[14px] font-normal">
                {t("inventory.newSellingPrice")}
              </label>
              <InputComponent
                className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4"
                type="text"
                placeholder="N0.00"
                name="rate"
                value={inventory.rate}
                handleChange={handleInputChange}
                error={error.rate}
              />
            </div>
          </div>
        )}
      </div>

      {variations?.length > 0 && (
        <div>
          <EditVariationForm
            onAddVariation={handleAddVariation}
            variations={variations}
            onRemoveVariation={handleRemoveVariation}
            onUpdateVariation={handleUpdateVariation}
            variationList={variationList}
            variationError={variationError}
          />
        </div>
      )}
    </div>
  );
};

export { EditInventoryForm };
