import React, { useState, useEffect, useCallback, useRef } from "react";
import { ICreateProfile } from "../../../../@types";
import { Pen } from "../../../../assets/icons/PenIcon";
import { Tooltip } from "../../../../assets/icons/Tooltip";
import { updateProfile } from "../../../../backend-services";
import { useUserStore } from "../../../../state";
import { GroupButton } from "../../../common";
import { InputComponent } from "../../../core/Forms";
import { useCompanyStore } from "../../../../state/useCompanyStore";

interface CreateEditProfileProps {
  onCreateProfile?: (profile: ICreateProfile) => void;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  show: boolean;
  onClose?: () => void;
  toolTipText?: string;
  toolTipText2?: string;
  toolTipText3?: string;
  toolTipText4?: string;
}

const debounce = (func: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const CompanyUpdate = ({
  onCreateProfile,
  show,
  onClose,
  toolTipText,
  toolTipText2,
  toolTipText3,
  toolTipText4,
}: CreateEditProfileProps) => {
  const user = useUserStore((state) => state.user);
  // Create a ref to hold the previous user data
  const prevUser = useRef(user);

  const setUser = useUserStore((state) => state.setUser);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(
    user?.prefs?.isFirstInvoice || false,
  );
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    businessName: user?.businessName || "",
    address: user?.address || "",
    phone: user?.phone || "",
    code: user?.code || "",
  });

  const [errors, setErrors] = useState({
    businessName: "",
    address: "",
    phone: "",
  });

  const validateField = (name: string, value: string) => {
    let error = "";

    switch (name) {
      case "businessName":
        if (value.length < 3) {
          error = "Business name must be at least 3 characters long";
        }
        break;
      case "address":
        if (!value.trim()) {
          error = "Address is required";
        }
        break;
      case "phone":
        if (!/^\d{10,15}$/.test(value)) {
          error = "Phone number must be 10 to 15 digits";
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    validateField(name, value);
    debouncedHandleEditProfile(name as keyof ICreateProfile, value);
  };

  useEffect(() => {
    if (user) {
      console.log(user);
      setFormData((prevData) => {
        const newData = {
          businessName: user.businessName || "",
          address: user?.address || "",
          phone: user?.phone || "",
          code: user?.code || "",
        };
        // Only update if the new data is different from the previous data
        if (JSON.stringify(prevData) !== JSON.stringify(newData)) {
          return newData;
        }
        return prevData; // Return the previous state if no changes
      });
    }
  }, [user]);

  const debouncedHandleEditProfile = useCallback(
    debounce(async (field: keyof ICreateProfile, value: string) => {
      setIsLoading(true);
      try {
        const updatedFields: Partial<ICreateProfile> = { [field]: value };
        await useCompanyStore.getState().updateCompanyProfile(updatedFields);

        setIsLoading(false);
        setIsOpen(false);
        onCreateProfile && onCreateProfile(updatedFields as ICreateProfile);
        onClose && onClose();
      } catch (e) {
        setIsLoading(false);
        setErrorMessage("Unable to update profile.");
      }
    }, 500),
    [onCreateProfile, onClose],
  );

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

  const handleMouseEnter3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };
  const handleMouseEnter4 = () => {
    setIsHovered4(true);
  };

  const handleMouseLeave4 = () => {
    setIsHovered4(false);
  };

  const toggleEditing = () => setIsEditing(true);

  return (
    <div>
      {isEditing ? (
        <form>
          <div className="relative lg:w-[370px]">
            <InputComponent
              type="text"
              name="businessName"
              value={formData.businessName}
              handleChange={handleInputChange}
              placeholder="Your business name"
              className="w-full"
            />
            {errors.businessName && (
              <span className="text-red-500 text-sm">
                {errors.businessName}
              </span>
            )}
            {toolTipText && (
              <div
                className="absolute top-0 right-0 mt-[0.9em] md:mt-[0.16em] biglg:mt-[0.9em] xl:mt-[0.9em] pr-2"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Tooltip />
                {isHovered && (
                  <div className="absolute w-[204px] top-[-2.5em] right-[10px] z-10">
                    <div className="flex items-center justify-center">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L17.6603 15H0.339746L9 0Z"
                          fill="#FFF4C6"
                        />
                      </svg>
                    </div>
                    <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                      {toolTipText}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="relative lg:w-[370px] mt-[0.4em]">
            <InputComponent
              type="text"
              name="address"
              value={formData.address}
              handleChange={handleInputChange}
              placeholder="Business Address"
              className="w-full"
            />
            {errors.address && (
              <span className="text-red-500 text-sm">{errors.address}</span>
            )}
            {toolTipText2 && (
              <div
                className="absolute top-0 right-0 mt-[0.9em] md:mt-[0.16em] biglg:mt-[0.9em] xl:mt-[0.9em] pr-2"
                onMouseEnter={handleMouseEnter2}
                onMouseLeave={handleMouseLeave2}
              >
                <Tooltip />
                {isHovered2 && (
                  <div className="absolute w-[204px] left-[-5.7em] z-10 biglg:mt-[2em]">
                    <div className="flex items-center justify-center">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L17.6603 15H0.339746L9 0Z"
                          fill="#FFF4C6"
                        />
                      </svg>
                    </div>
                    <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                      {toolTipText2}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="relative lg:w-[370px] mt-[0.4em]">
            <InputComponent
              type="text"
              name="phone"
              value={formData.phone}
              handleChange={handleInputChange}
              placeholder="Phone Number"
              className="w-full"
            />
            {errors.phone && (
              <span className="text-red-500 text-sm">{errors.phone}</span>
            )}
            {toolTipText3 && (
              <div
                className="absolute top-0 right-0 mt-[0.9em] md:mt-[0.16em] biglg:mt-[0.9em] xl:mt-[0.9em] pr-2"
                onMouseEnter={handleMouseEnter3}
                onMouseLeave={handleMouseLeave3}
              >
                <Tooltip />
                {isHovered3 && (
                  <div className="absolute w-[204px] left-[-5.7em] z-10 biglg:mt-[2em]">
                    <div className="flex items-center justify-center">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L17.6603 15H0.339746L9 0Z"
                          fill="#FFF4C6"
                        />
                      </svg>
                    </div>
                    <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                      {toolTipText3}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="relative lg:w-[370px] mt-[0.4em]">
            <InputComponent
              type="text"
              name="code"
              value={formData.code}
              handleChange={handleInputChange}
              placeholder="Companys RC"
              className="w-full"
            />
            {toolTipText4 && (
              <div
                className="absolute top-0 right-0 mt-[0.9em] md:mt-[0.16em] biglg:mt-[0.9em] xl:mt-[0.9em] pr-2"
                onMouseEnter={handleMouseEnter4}
                onMouseLeave={handleMouseLeave4}
              >
                <Tooltip />
                {isHovered4 && (
                  <div className="absolute w-[204px] left-[-5.7em] z-10 biglg:mt-[2em]">
                    <div className="flex items-center justify-center">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L17.6603 15H0.339746L9 0Z"
                          fill="#FFF4C6"
                        />
                      </svg>
                    </div>
                    <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                      {toolTipText4}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </form>
      ) : (
        <GroupButton
          buttons={[
            {
              text: "Edit Business Details",
              icon: <Pen />,
              onClick: toggleEditing,
              fontSize: "text-[11px]",
            },
          ]}
        />
      )}
    </div>
  );
};

export default CompanyUpdate;
