import React, { ReactNode } from "react";

const SubscriptionModal = ({ children }: { children: ReactNode }) => {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-80 z-50 overflow-y-auto flex justify-center items-center">
      <div className="max-h-full w-full flex justify-center items-center">
        {children}
      </div>
    </div>
  );
};

export { SubscriptionModal };
