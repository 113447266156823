import React from "react";
import { Loading } from "../../common/Table/Loading";
import cx from "classnames";
import { IInventory, IPaginationMeta } from "../../../@types";
import { useInView } from "react-intersection-observer";
import { JsxElement } from "typescript";
import { TableHead } from "../../../@types/inventory";
import Empty from "../../common/Table/Empty";
import EmptyArchive from "../archive/EmptyArchive";

export type TableItem = {
  [key: string]:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
};

type BodyType = {
  inventory?: IInventory[];
  data: TableItem[];
  isLoading: boolean;
  showPagination: boolean;
  tableHead: any;
  meta?: IPaginationMeta;
  emptyText?: string;
  fetchMoreData?: (direction: "up" | "down") => void | undefined;
};

const ListInventoryBody = ({
  data,
  isLoading,
  showPagination,
  tableHead,
  meta,
  fetchMoreData,
  emptyText,
}: BodyType) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [showTooltip, setShowTooltip] = React.useState(false);
  const [tooltipContent, setTooltipContent] = React.useState<
    | string
    | number
    | boolean
    | undefined
    | null
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
  >("");

  React.useEffect(() => {
    if (inView && showPagination && meta?.hasMoreData && fetchMoreData) {
      fetchMoreData("down");
    }
  }, [inView, showPagination, meta?.hasMoreData]);

  return (
    <div className="mt-6 overflow-x-auto rounded-[10px]">
      <table className="w-full text-[14px]">
        <thead className="h-[63px] font-semibold text-white bg-[#044E97] border-b-2 border-gray-200">
          <tr className=" whitespace-nowrap">
            {tableHead.map((head: any) => (
              <th
                key={head.dataIndex}
                className={`py-3 px-2 ${head?.className}`}
              >
                {head.title || head.icon}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="font-medium text-[16px]">
          {data?.length === 0 ? (
            <tr>
              <td colSpan={tableHead.length}>
                <EmptyArchive EmptyText={emptyText} />
              </td>
            </tr>
          ) : (
            data?.map((dat, i) => {
              if (data?.length === i + 1) {
                return (
                  <tr
                    ref={ref}
                    key={i}
                    className={cx({
                      "bg-white dark:border-gray-700 dark:bg-gray-800 w-full h-[88px] whitespace-nowrap":
                        i % 2 === 0,
                      "bg-[#044E97]  bg-opacity-10 dark:border-gray-700 dark:bg-gray-800 w-full h-[88px] whitespace-nowrap":
                        i % 2 !== 0,
                    })}
                  >
                    {tableHead.map((item: any, i: any) => (
                      <td
                        key={`${item.name}${i}`}
                        onMouseEnter={() => {
                          setShowTooltip(true);
                          setTooltipContent(dat["reason1"]);
                        }}
                        onMouseLeave={() => setShowTooltip(false)}
                        onClick={() => {
                          setShowTooltip(true);
                          setTooltipContent(dat["reason1"]);
                        }}
                        className={`py-3 px-2 text-center ${item.className}`}
                      >
                        {item.dataIndex === "reason" && showTooltip ? (
                          <div className="relative">
                            {dat[item.dataIndex]}
                            <span className="absolute bg-white p-2 top-[-28px] whitespace-normal right-6 w-full text-[16px] z-50 shadow-lg rounded-[4px]">
                              {tooltipContent}
                            </span>
                          </div>
                        ) : (
                          dat[item.dataIndex]
                        )}
                      </td>
                    ))}
                  </tr>
                );
              } else {
                return (
                  <tr
                    key={i}
                    className={cx({
                      "bg-white dark:border-gray-700 dark:bg-gray-800 w-full h-[88px] whitespace-nowrap":
                        i % 2 === 0,
                      "bg-[#044E97]  bg-opacity-10 dark:border-gray-700 dark:bg-gray-800 w-full h-[88px] whitespace-nowrap":
                        i % 2 !== 0,
                    })}
                  >
                    {tableHead.map((item: any, i: any) => (
                      <td
                        key={`${item.name}${i}`}
                        className={`py-3 px-2 text-center ${item.className}`}
                      >
                        {dat[item.dataIndex]}
                      </td>
                    ))}
                  </tr>
                );
              }
            })
          )}
          {isLoading && <Loading data={tableHead} />}
        </tbody>
      </table>
      <div className="mb-[56px]"></div>
    </div>
  );
};

export default React.memo(ListInventoryBody);
