import { useEffect } from "react";
import Cookies from "js-cookie";
import {
  createBankAccount,
  createCustomer,
  createInvoice,
  createQuote,
  updateProfile,
  getActiveSubscription,
} from "../../backend-services";
import { BankData, CustomerT } from "../../types";
import { ICreateSalesData, IUpdateProfile } from "../../@types";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../state";
import AccountLoader from "../../components/auth/AccountLoader";
import { isObjectValuesEmpty } from "../../utils/ObjCheck";
import { handleError } from "../../utils/errorHandler";
import * as Sentry from "@sentry/react"; // Import Sentry
import { useCompanyStore } from "../../state/useCompanyStore";

const FreeInvoiceToolSetup = () => {
  const navigate = useNavigate();
  const {  setCompanyBankAccounts } = useCompanyStore();
  const { setUser } = useUserStore();

  useEffect(() => {
    const getParsedCookie = <T,>(cookieName: string): T | null => {
      const cookieValue = Cookies.get(cookieName);
      try {
        return cookieValue ? (JSON.parse(cookieValue) as T) : null;
      } catch (error) {
        handleError(
          error,
          `Parsing Cookie: ${cookieName} - Value: ${cookieValue}`,
        );
        return null;
      }
    };

    const type = Cookies.get("type") || "";
    const parsedInvoiceData = getParsedCookie<ICreateSalesData>("invoiceData");
    const parsedCustomerDetails = getParsedCookie<CustomerT>("customerDetails");
    const parsedBankDetails = getParsedCookie<BankData>("bankDetails");
    const parsedProfileData = getParsedCookie<IUpdateProfile>("profileData");

    const handleCreateData = async (
      customerData: CustomerT,
      invoiceData: ICreateSalesData,
      bankData: BankData,
      profileData: IUpdateProfile,
      parsedType: string,
    ) => {
      try {
        // First, get the active subscription
        const activeSubscription = await getActiveSubscription();

        // Check if the subscription is valid
        if (!activeSubscription || !activeSubscription.id) {
          const errorMessage = "No active subscription found.";
          console.error(errorMessage);
          Sentry.captureMessage(errorMessage, {
            contexts: {
              subscription: {
                activeSubscription,
              },
            },
          });
          navigate("/error");
          return;
        }

        const isBankDataValid =
          bankData.accountName && bankData.accountNumber && bankData.bankName;

        // Modify bankAccountPromise to handle errors gracefully
        const bankAccountPromise = isBankDataValid
          ? createBankAccount(bankData).catch((error) => {
              console.error("Bank account creation failed:", error);
              Sentry.captureException(error, {
                contexts: {
                  bankAccountCreation: {
                    bankData,
                  },
                },
              }); // Capture bank account creation error
              return null; // Return null to indicate failure, but continue
            })
          : Promise.resolve(null);

        // Proceed with other API calls
        const [profileResponse, customerResponse, bankAccountResponse] =
          await Promise.all([
            updateProfile(profileData),
            createCustomer(customerData),
            bankAccountPromise,
          ]);

        if (bankAccountResponse && bankAccountResponse.data) {
          setCompanyBankAccounts(bankAccountResponse.data);
        }

        const customerId = customerResponse.id;
        const updatedInvoiceData = { ...invoiceData, customerId };

        const updatedProfile = {
          ...profileResponse,
          bankAccounts: bankAccountResponse?.data || [],
        };

        setUser(updatedProfile);

        if (customerId) {
          if (parsedType === "invoice") {
            const invoiceResponse = await createInvoice(updatedInvoiceData);
            if (invoiceResponse && invoiceResponse.id) {
              removeCookies();
              navigate(
                `/invoices/${invoiceResponse.id}/view?freeForm=freeForm`,
              );
            }
          } else if (parsedType === "quote") {
            const quoteResponse = await createQuote(updatedInvoiceData);
            if (quoteResponse && quoteResponse.id) {
              removeCookies();
              navigate(`/quotes/${quoteResponse.id}/view?freeForm=freeForm`);
            }
          } else {
            const errorMessage = "Invalid type in cookies.";
            handleError(
              new Error(errorMessage),
              "FreeInvoiceToolSetup - Invalid Type",
            );
            navigate("/error");
          }
        }
      } catch (error) {
        handleError(error, "FreeInvoiceToolSetup - handleCreateData");
        navigate("/error");
      }
    };

    const removeCookies = () => {
      [
        "type",
        "invoiceData",
        "customerDetails",
        "bankDetails",
        "navigation",
      ].forEach((cookieName) => {
        Cookies.remove(cookieName);
      });
    };

    if (
      parsedCustomerDetails &&
      parsedInvoiceData &&
      parsedBankDetails &&
      parsedProfileData &&
      type
    ) {
      handleCreateData(
        parsedCustomerDetails,
        parsedInvoiceData,
        parsedBankDetails,
        parsedProfileData,
        type,
      );
    } else {
      const errorMessage = "Missing or invalid data in cookies.";
      handleError(
        new Error(errorMessage),
        "FreeInvoiceToolSetup - Missing Data",
      );
      navigate("/error");
    }
  }, [navigate, setUser, setCompanyBankAccounts]);

  return <AccountLoader />;
};

export default FreeInvoiceToolSetup;
