import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface PhoneInputComponentProps {
  placeholder?: string;
  initialPhoneNumber?: string;
  onPhoneNumberChange: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  inputClass?: string;
  containerClass?: string;
  value?: string;
  disableDropdown?: boolean;
}
const PhoneInputComponent: React.FC<PhoneInputComponentProps> = ({
  placeholder,
  initialPhoneNumber,
  onPhoneNumberChange,
  onKeyDown,
  disabled,
  inputClass,
  containerClass,
  value,
  disableDropdown,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(
    value || initialPhoneNumber || "",
  );

  useEffect(() => {
    if (value) {
      setPhoneNumber(value);
    }
  }, [value]);

  const handleChange = (value: string, data: any) => {
    let normalizedPhone = value;

    if (value.startsWith("+234") && data.dialCode !== "234") {
      normalizedPhone = `+${value.substring(4)}`;
    }
    setPhoneNumber(normalizedPhone);
    onPhoneNumberChange(normalizedPhone);
  };

  return (
    <PhoneInput
      placeholder={placeholder}
      value={phoneNumber}
      onChange={handleChange}
      inputProps={{ required: true }}
      disabled={disabled}
      inputClass={inputClass}
      containerClass={containerClass}
      disableDropdown={disableDropdown}
      enableSearch={true}
      enableAreaCodes={false}
      countryCodeEditable={false}
      country={"ng"}
    />
  );
};

export default PhoneInputComponent;
