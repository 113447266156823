import React from "react";
import {
  IExpense,
  IInvoice,
  IPayment,
  PaymentType,
  TransactionType,
} from "../../../@types";
import { recordManualPayment } from "../../../backend-services";
import { PAYMENT_PROVIDER_LIST } from "../../../constants";
import { Button, SearchInput } from "../../common";
import { DropdownComponent, InputComponent } from "../../core/Forms";
import RecordPaymentSuccess from "./SuccessModal";
import { useTranslation } from "react-i18next";

interface ManualRecordPaymentProps {
  entity: IInvoice | IExpense;
  onRecordEntity: (payment: IPayment) => void;
  invoiceId: string;
  balanceDue: string;
  setSuccess: (success: boolean) => void;
  closeModal: () => void;
}

const ManualRecordPayment: React.FC<ManualRecordPaymentProps> = ({
  entity,
  onRecordEntity,
  invoiceId,
  balanceDue,
  setSuccess,
  closeModal,
}) => {
  const { t } = useTranslation("invoices");
  const [isLoading, setIsLoading] = React.useState(false);
  const [amount, setAmount] = React.useState("");
  const [method, setMethod] = React.useState<PaymentType>();
  const [payment, setPayment] = React.useState<TransactionType>("revenue");
  const [error, setError] = React.useState<string>();
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const today = new Date();
  const todayString = today.toISOString().split("T")[0];
  const [date, setDate] = React.useState(todayString);

  const validateInput = (): boolean => {
    if (!amount || !selectedValue || !date) {
      setError("All fields are required.");
      return false;
    }
    const selectedDate = new Date(date);
    const currentDate = new Date();

    if (selectedDate > currentDate) {
      setError("Payment date cannot be in the future.");
      return false;
    }
    setError(undefined);
    return true;
  };

  const handleRecordPayment = (e: any) => {
    e.preventDefault();
    if (!validateInput()) return;

    if (parseFloat(balanceDue) <= 0) {
      // Display error message
      setErrorMessage("Balance due is zero. Cannot record payment.");
      return;
    }

    setIsLoading(true);
    recordManualPayment(invoiceId, {
      companyId: entity.companyId as string,
      entityId: entity.id,
      customerId: entity.customer.id,
      paymentType: selectedValue as string as PaymentType,
      paymentDate: date,
      amount: Number(amount),
      entityType: payment as TransactionType,
      outletId: entity.outletId as string,
    })
      .then((data) => {
        setIsLoading(false);
        setSuccess(true);
        closeModal();
        setTimeout(() => {
          onRecordEntity(data);
        }, 2000);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const dropDownPaymentMethod = PAYMENT_PROVIDER_LIST;

  const handleChange = (name: string, selectedValue: string) => {
    setSelectedValue(selectedValue);
  };
  const addCommasToNumber = (value: any) => {
    if (value === null) {
      return "";
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>
      <div className="mt-[23px] text-center leading-5"></div>

      <form className="mt-[20px]" onSubmit={handleRecordPayment}>
        {error ? (
          <div className="text-center p-2 bg-red-300 text-red-700 font-medium mb-4 ">
            {error}
          </div>
        ) : null}

        <label
          htmlFor="Amount Received"
          className="block font-medium text-[14px] text-black ml-[0.3em] lg:ml-0   md:ml-[-0.5em]"
        >
          {t("amountReceived")}
        </label>
        <InputComponent
          type="text"
          pattern="[0-9]*"
          value={addCommasToNumber(amount)}
          handleChange={(e: {
            target: { value: React.SetStateAction<string> };
          }) => setAmount(e.target.value)}
          placeholder="Amount Received"
          className="w-full xl:ml-[-0.2em] lg:ml-[-0.2em] md:ml-[-0.2em] "
          label={""}
        />

        <div className="mt-[1em]">
          <label
            htmlFor="Payment Date"
            className="block font-medium text-[14px] text-black  ml-[0.3em]  lg:ml-0  md:ml-[-0.5em]"
          >
            {t("paymentDate")}
          </label>
          <InputComponent
            type="date"
            value={date}
            handleChange={(e: {
              target: { value: React.SetStateAction<string> };
            }) => setDate(e.target.value)}
            placeholder="Payment Date"
            handleOnFocus={(e: { target: { type: string } }) => {
              e.target.type = "date";
            }}
            handleOnBlur={(e: { target: { type: string } }) => {
              e.target.type = "text";
            }}
            className="w-full  xl:ml-[-0.2em] lg:ml-[-0.2em] md:ml-[-0.2em] "
            label={""}
          />
        </div>

        <div className="mt-[1em]">
          <label
            htmlFor="Payment Method"
            className="block font-medium text-[14px] text-black  ml-[0.3em] lg:ml-0  md:ml-[-0.5em]"
          >
            {t("paymentMethod")}
          </label>
          <DropdownComponent
            options={dropDownPaymentMethod}
            value={selectedValue}
            placeholder="Payment Method"
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            isSubmitted={isSubmitted}
            onChange={handleChange}
            dropdownClassName="w-full xl:ml-[-0.2em] lg:ml-[-0.2em] md:ml-[-0.2em] bg-white  rounded py-1 focus:ring-0 pointer-events"
            iconClassName="lg:mt-[-1.4px] lg:mr-[-3px] md:mr-[12px] mt-[0.4em] md:mt-[-0.1em]"
          />
        </div>
        <div className="hidden lg:block md:block">
          <div className="flex justify-center pt-[20px] pb-[20px] text-[16px] font-semibold">
            <button className="flex justify-center py-[1em] text-[16px] font-semibold  text-[#044E97] bg-white border border-[#044E97] w-full text-center rounded-md">
              Record Purchase
            </button>
          </div>
        </div>
        <div className="flex justify-center pt-[20px] pb-[20px] text-[16px] mb-8 font-semibold lg:hidden md:hidden">
          <button className="flex justify-center py-[1em] text-[16px] font-semibold  text-[#044E97] bg-white border border-[#044E97] w-full text-center rounded-md">
            Record Purchase
          </button>
        </div>
      </form>
    </>
  );
};

export { ManualRecordPayment };
