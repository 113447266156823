import React, { useState } from "react";
import { GroupButton } from "../../common";
import EditIcon from "../../../assets/icons/EditIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import { SidebarDrawer } from "../../common";
import VariationDeletePrompt from "./VariationDeletePrompt";
import LeftNotificationPopup from "../../core/NotificationPopup/LeftNotificationPopup";
import ProcessSuccessful from "../../../assets/icons/ProcessSuccessful";
import EditVariationPrompt from "./EditVariationPrompt";
import { shortenText } from "../../../utils";
import { useTranslation } from "react-i18next";

const VariationCard = ({ item }: any) => {
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const { t } = useTranslation("inventories");
  const [editPrompt, setEditPrompt] = useState<boolean>(false);

  const [showSuccessfulModal, setShowSuccessfulModal] = React.useState(false);

  const handleEdit = () => {
    setEditPrompt(true);
  };

  const handleVariationDeleteSuccess = () => {
    setShowSuccessfulModal(true);
    setShowPrompt(false);
  };

  const handleCloseEdit = () => {
    setEditPrompt(false);
  };

  return (
    <>
      <div className="lg:w-[446px] w-[300px] bg-[#EDF2F5] ">
        <div className="flex flex-row gap-[40px] py-[25px] px-[36px]">
          <div className="flex flex-col">
            <p className="text-[14px] whitespace-nowrap">Variation Name</p>
            <span className="text-[16px] text-[#000] font-bold">
              {shortenText(item.variantName, 10)}
            </span>
          </div>
          <div className="text-[#044E97] text-[20px] md:text-[17px] lg:text-[14px] font-medium lg:ml-0 md:ml-0 ml-[-1em]">
            <GroupButton
              buttons={[
                {
                  text: t("inventory.edit"),
                  icon: <EditIcon />,
                  onClick: handleEdit,
                },
                {
                  text: t("inventory.delete"),
                  icon: <DeleteIcon />,
                  onClick: () => {
                    setShowPrompt(true);
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>
      <SidebarDrawer
        title={t("inventory.deleteVariationPrompt")}
        show={showPrompt}
        position={"left"}
        onClose={() => setShowPrompt(false)}
      >
        <VariationDeletePrompt
          showPrompt={showPrompt}
          show={() => setShowPrompt(false)}
          variationId={item.id}
          onClose={() => setShowPrompt(false)}
          onDeleteSuccess={handleVariationDeleteSuccess}
        />
      </SidebarDrawer>

      {showSuccessfulModal && (
        <>
          <SidebarDrawer
            title={"Variation Deleted"}
            show={true}
            position={"left"}
            onClose={() => setShowSuccessfulModal(false)}
          >
            <LeftNotificationPopup
              title={""}
              icon={<ProcessSuccessful />}
              onClose={() => setShowSuccessfulModal(false)}
            />
          </SidebarDrawer>
        </>
      )}

      {editPrompt && (
        <SidebarDrawer
          title="Edit Your Variation"
          show={editPrompt}
          position="left"
          onClose={handleCloseEdit}
        >
          <EditVariationPrompt item={item} close={handleCloseEdit} />
        </SidebarDrawer>
      )}
    </>
  );
};

export default VariationCard;
