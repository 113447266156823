import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomerT } from "../../types";
import {
  getCustomer,
  getInvoiceStats,
  getPaymentReminder,
} from "../../backend-services";
import toast from "react-hot-toast";
import { CustomerDetails } from "../../components/customers/view/customerDetail";
import { IInvoice, InvoiceStatsData } from "../../@types";
import PageLoader from "../../components/common/PageLoader";
import { GroupButton, SidebarDrawer } from "../../components/common";
import { CustomerDeletePrompt } from "../../components/customers/CustomerDelete";
import LeftNotificationPopup from "../../components/core/NotificationPopup/LeftNotificationPopup";
import ProcessSuccessful from "../../assets/icons/ProcessSuccessful";
import AnnotationComponent from "../../components/common/Annotation/AnnotationComponent";
import { Annotation } from "../../@types/annotation";
import useAnnotations from "../../hooks/annotation/useAnnotations";
import { SendReminderPrompt } from "../../components/Sales/invoice/SendReminderPrompt";
import { useLocation, useNavigate } from "react-router-dom";
import { AddSquareIcon } from "../../assets/icons";
import EditIcon from "../../assets/icons/EditIcon";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import usePreviousLocation from "../../hooks/usePreviousLocation";
import { useTranslation } from "react-i18next";
import ActionModal from "../../components/common/ActionModal";
import UploadIcon from "../../assets/icons/svgicons/UploadIcon.svg";
import { useUserStore } from "../../state";
import { BUSINESS_TYPE_RETAIL } from "../../constants";
import useRetailBusinessStatus from "../../hooks/invoice/useRetailBusinessStatus";
import generateActionButtons from "../../components/Sales/invoice/invoiceCreationModal";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  city: "",
  state: "",
};

export const ViewCustomers = () => {
  const { customerId } = useParams();
  if (!customerId) throw new Error("Invoice Id is missing");
  const { t } = useTranslation("customer");
  const [customer, setCustomer] = useState<CustomerT>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [DrawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [data, setData] = useState<InvoiceStatsData>();
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [paymentReminder, setPaymentReminder] = useState(null);
  const [isReminderSent, setIsReminderSent] = React.useState(false);
  const [invoice, setInvoice] = useState<IInvoice>();
  const [annotations, setAnnotations] = React.useState<Annotation[]>([]);
  const annotationsData = useAnnotations(annotations, "Customer");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isRetailBusiness = useRetailBusinessStatus();
  const containerRef = useRef<HTMLDivElement>(null);

  const [modalData, setModalData] = useState({
    customerId: "",
    customerName: "",
    customerPhone: "",
  });

  useEffect(() => {
    setIsLoading(true);
    Promise.all([fetchInvoiceStats(), getCustomerHandler()])
      .catch((e) => {
        console.error(e);
        toast.error("Failed to load customer data.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [customerId]);

  const fetchInvoiceStats = async () => {
    const currentTime = new Date().toISOString();
    const response = await getInvoiceStats(currentTime, currentTime);
    setData(response);
  };

  const getCustomerHandler = async () => {
    const data = await getCustomer(customerId);
    setCustomer(data);
    if (data?.metadata) setAnnotations(data?.metadata?.annotations);
  };

  const toggleDrawer = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const handleCustomerDeleteSuccess = () => {
    setShowSuccessModal(true);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const PaymentReminder = async () => {
    try {
      if (customerId) {
        const reminder = await getPaymentReminder(customerId);
        setPaymentReminder(reminder);
        setIsReminderSent(true);
      }
    } catch (error) {
      toast.error("Failed to send payment reminder.");
    }
  };
  const navigate = useNavigate();

  const handleCreateInvoice = () => {
    const customerId = customer?.id ?? "";
    const customerName = `${customer?.firstName} ${customer?.lastName}`;
    const customerPhone = customer?.phone ?? "";

    setModalData({
      customerId,
      customerName,
      customerPhone,
    });

    setIsModalOpen(true);
  };
  const handleInventoryInvoiceClick = (modalData: {
    customerId: any;
    customerName: any;
    customerPhone: any;
  }) => {
    const { customerId, customerName, customerPhone } = modalData;
    navigate(
      `/invoices/create?customerId=${encodeURIComponent(customerId)}&customerName=${encodeURIComponent(
        customerName,
      )}&customerPhone=${encodeURIComponent(customerPhone)}&invoiceType=inventory`,
    );
  };

  const handleFreeformInvoiceClick = (modalData: {
    customerId: any;
    customerName: any;
    customerPhone: any;
  }) => {
    const { customerId, customerName, customerPhone } = modalData;
    navigate(
      `/invoices/create?customerId=${encodeURIComponent(customerId)}&customerName=${encodeURIComponent(
        customerName,
      )}&customerPhone=${encodeURIComponent(customerPhone)}&invoiceType=freeform`,
    );
  };
  const actionButtons = generateActionButtons(
    isRetailBusiness,
    () => {
      const { customerId, customerName, customerPhone } = modalData;
      handleInventoryInvoiceClick({ customerId, customerName, customerPhone });
    },
    () => {
      const { customerId, customerName, customerPhone } = modalData;
      handleFreeformInvoiceClick({ customerId, customerName, customerPhone });
    },
  );

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div>
          <div className="flex items-center justify-between">
            <div className="flex lg:items-center gap-[1em] items-center">
              <a className="cursor-pointer" onClick={() => navigate(-1)}>
                <svg
                  className=""
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 1L1.5 8L8.5 15"
                    stroke="#33363F"
                    strokeWidth="2"
                  />
                </svg>
              </a>
              <h1 className="flex items-center text-[18px] md:text-[24px] md:leading-7 font-semibold">
                Customers
              </h1>
            </div>
            <div className="md:flex md:gap-4 md:items-center">
              <div className="hidden md:block bg-[#EDF2F5]">
                <GroupButton
                  buttons={[
                    {
                      text: t("customer.createInvoice"),
                      icon: <AddSquareIcon />,
                      onClick: handleCreateInvoice,
                      fontSize: "font-semibold text-[16px] gap-1",
                    },
                    {
                      text: t("customer.edit"),
                      icon: <EditIcon />,
                      onClick: toggleDrawer,
                      fontSize: "font-semibold text-[16px] gap-1",
                    },
                    {
                      text: "Delete",
                      icon: <DeleteIcon />,
                      onClick: () => setShowPrompt(true),
                      textColorClass: "text-[#FB0303]",
                      fontSize:
                        "font-semibold text-[16px] text-[#FB0303] gap-1",
                    },
                  ]}
                />
              </div>
              <AnnotationComponent
                type="customer"
                annotationData={annotationsData}
              />
            </div>
          </div>
          <div>
            <CustomerDetails
              handleCreateInvoice={handleCreateInvoice}
              customer={customer}
              data={data}
              DrawerOpen={DrawerOpen}
              showIsLoading={() => setIsLoading(true)}
              hideIsLoading={() => setIsLoading(false)}
              showPrompt={showPrompt}
              toggleDrawer={toggleDrawer}
              setShowPrompt={setShowPrompt}
              setIsSidebarOpen={setIsSidebarOpen}
            />
          </div>
        </div>
      )}
      <div ref={containerRef}>
        <ActionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title="Select an invoice creation option"
          icon={UploadIcon}
          actionButtons={actionButtons}
        />
      </div>

      {showPrompt && (
        <CustomerDeletePrompt
          showPrompt={showPrompt}
          show={() => setShowPrompt(false)}
          customerId={customerId}
          onClose={() => setShowPrompt(false)}
          onDeleteSuccess={handleCustomerDeleteSuccess}
        />
      )}
      {isSidebarOpen && (
        <SidebarDrawer title={"Send Reminder"} show={true} position={"left"}>
          <SendReminderPrompt
            onSend={PaymentReminder}
            onClose={() => setIsSidebarOpen(false)}
            isSidebarOpen={isSidebarOpen}
            invoice={invoice}
            customerId={customerId}
            //@ts-ignore
            customerEmail={customer.email}
          />
        </SidebarDrawer>
      )}
      {isReminderSent && (
        <SidebarDrawer title={""} show={true} position={"left"}>
          <LeftNotificationPopup
            title={"You sent a payment reminder"}
            icon={<ProcessSuccessful />}
          />
        </SidebarDrawer>
      )}
    </>
  );
};
