import usaIcon from "../assets/icons/svgicons/USA.svg";
import poundsIcon from "../assets/icons/svgicons/uk.svg";
import UsaIcon from "../assets/icons/UsaIcon";

// Constants
export const PAYMENT_PROVIDERS = {
  CASH: "Cash",
  TRANSFER: "Transfer",
  CARD: "Card",
};

export const PAYMENT_PROVIDER_LABELS = {
  CASH: "Cash",
  TRANSFER: "Transfer",
  CARD: "Card",
};

export const PAYMENT_PROVIDER_LIST = [
  { value: "cash", label: PAYMENT_PROVIDER_LABELS.CASH },
  { value: "transfer", label: PAYMENT_PROVIDER_LABELS.TRANSFER },
  { value: "card", label: PAYMENT_PROVIDER_LABELS.CARD },
];

// export const PAYMENT_CURRENCY_LIST = [
//   { value: 'NGN', label: '₦ Naira', icon: <UsaIcon/> },
//   { value: 'USD', label: '$ Dollar', icon: '' },
//   { value: 'GBP ', label: '£ Pounds Sterling', icon: '' },
//   { value: 'EUR ', label: '€ Euros', icon: '' },
// ];
export const EXPENSE_PAYMENT_CURRENCY_LIST = [
  { value: "ngn", label: "₦ Naira", icon: "" },
];

export const EXPENSETYPE_LIST = [
  { value: "All", label: "All" },
  { value: "Billable", label: "Billable" },
  { value: "Non-Billable", label: "Non-Billable" },
];
