import { Link } from "react-router-dom";
import { CustomerT } from "../../types";
import { formatMoney } from "../format";
import dayjs from "dayjs";
import { getDaySuffix } from "../invoice-list";

export const columns = [
  { key: "customer", label: "Customer", width: 25 },
  { key: "email", label: "Email", width: 22 },
  { key: "billed", label: "Total Billed", width: 13 },
  { key: "invoiceNumber", label: "Number of Invoices", width: 20 },
  { key: "createdAt", label: "Last Active", width: 20 },
];

export const renderCustomersData = (customers: CustomerT[]) => {
  return customers.map((cus) => ({
    customer: (
      <Link className="md:text-violet-800" to={`/customers/${cus.id}/view`}>
        {cus.firstName} {cus.lastName}
      </Link>
    ),
    id: cus.id,
    email: cus.email,
    billed: `${cus.totalBilled ? formatMoney(cus.totalBilled) : "₦0.00"}`,
    invoiceNumber: cus.totalInvoices || "0",
    createdAt: `${dayjs(cus.createdAt).format("MMM")} ${getDaySuffix(dayjs(cus.createdAt).date())} ${dayjs(
      cus.createdAt,
    ).format("YYYY")}`,
  }));
};
