import React from "react";
import { deleteInventory } from "../../backend-services";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface InventoryDeletePromptProps {
  setShowPrompt: (show: boolean) => void;
  inventoryId: string | undefined;
  fetchInventory?: () => Promise<void>;
}

const InventoryDeletePrompt = ({
  setShowPrompt,
  inventoryId,
  fetchInventory,
}: InventoryDeletePromptProps) => {
  const { t } = useTranslation("inventories");
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const navigate = useNavigate();

  const handleInventoryDelete = async (id: string | undefined) => {
    if (id) {
      setIsDeleting(true);
      try {
        const res = await deleteInventory(id);
        setIsDeleting(false);
        setShowPrompt(false);
        navigate("/inventories");
        if (fetchInventory) fetchInventory();
        toast.success("Inventory Deleted Successfully");
      } catch (error: any) {
        setErrorMessage("Unable to Delete Inventory.");
        setIsDeleting(false);
        if (
          error.expense &&
          (error.expense.status === 400 || error.expense.status === 500)
        ) {
          navigate("/error");
        }
      }
    }
  };

  return (
    <>
      <div className="bg-[white] rounded-md w-[350px] lg:w-[500px]">
        <div className="flex justify-between  px-[1em] py-[1em]">
          <h1 className="hidden lg:block md:block text-[20px] font-semibold text-gray-900 text-center ">
            Delete inventory
          </h1>
          <h1 className="lg:hidden md:hidden text-[20px] font-semibold text-gray-900 text-center whitespace-nowrap">
            Delete inventory
          </h1>
        </div>
        <div className="flex justify-center mt-[13px]  px-[1em] text-gray-600">
          <h1>
            {t("inventory.deleteVariationWarning")}{" "}
            {t("inventory.deleteVariationWarning2")}
          </h1>
        </div>
        <div
          className="flex mt-[1em] gap-5 justify-end pr-[1em] text-[16px] font-semibold"
          role="form"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="flex justify-end mb-5">
            <button
              onClick={() => setShowPrompt(false)}
              className="w-[140px] rounded-[4px] h-[55px] bg-[#FFFFFF] text-[black] font-semibold border border-gray-300"
            >
              {t("inventory.cancel")}
            </button>
          </div>
          <div className="flex justify-end mb-5">
            <button
              onClick={() => handleInventoryDelete(inventoryId)}
              className="w-[140px] rounded-[4px] h-[55px] bg-[#FB0303] text-white  font-semibold"
            >
              {isDeleting ? "Deleting..." : "Delete"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryDeletePrompt;
