import { CustomerDeletePrompt } from "../CustomerDelete";
import { RecentInvoices } from "./RecentInvoices";
import { EditCustomer } from "../Edit";
import { CustomerT } from "../../../types";
import React, { useState } from "react";
import { CustomerInfo } from "./CustomerInfo";
import { CustomerHeader } from "./CustomerHeader";
import { OutstandingAmount } from "./OutstandingAmount";
import { IInvoice } from "../../../@types";
import { useUserStore } from "../../../state";
import { isNotAccountant } from "../../../utils/isNotAccountant";
import { useTranslation } from "react-i18next";

interface CustomerDetailsProps {
  customer: CustomerT;
  // @ts-ignore
  data: InvoiceStatsData;
  DrawerOpen: boolean;
  showPrompt: boolean;
  toggleDrawer: () => void;
  setShowPrompt: (show: boolean) => void;
  setIsSidebarOpen: (show: boolean) => void;
  showIsLoading: any;
  hideIsLoading: any;
  handleCreateInvoice: () => void;
}
export const CustomerDetails: React.FC<CustomerDetailsProps> = ({
  customer,
  data,
  DrawerOpen,
  showPrompt,
  toggleDrawer,
  setShowPrompt,
  setIsSidebarOpen,
  showIsLoading,
  hideIsLoading,
  handleCreateInvoice,
}) => {
  const [customerState, setCustomerState] = useState<CustomerT>(customer);
  const { roles } = useUserStore();

  const handleEditSuccess = (updatedData: CustomerT) => {
    setCustomerState(updatedData);
  };
  const { t } = useTranslation("customer");
  return (
    <>
      {isNotAccountant(roles) && (
        <div>
          <CustomerHeader
            handleCreateInvoice={handleCreateInvoice}
            customer={customer}
            toggleDrawer={toggleDrawer}
            setShowPrompt={setShowPrompt}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        </div>
      )}

      <OutstandingAmount customer={customer} />

      <CustomerInfo customer={customer} data={data} />
      <RecentInvoices customerId={customer.id as string} />

      <EditCustomer
        showIsLoading={showIsLoading}
        hideIsLoading={hideIsLoading}
        show={DrawerOpen}
        customer={customer}
        customerId={customer.id as string}
        onEditSuccess={handleEditSuccess}
      />
    </>
  );
};
