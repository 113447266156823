import * as React from "react";
import { Outlet, useLocation } from "react-router-dom";
import SideMenu from "../../components/sidemenu/SideMenu";
import TopBar from "../../components/TopBar";
import { SidebarDrawer } from "../../components/common";
import SubscriptionAlert from "../../components/settings/Subscription/SubscriptionAlert";
import { ICustomer } from "../../@types";

interface DashboardLayoutProps {}

const DashboardLayout: React.FC<DashboardLayoutProps> = () => {
  const { pathname } = useLocation();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(true);
  const [showAlert, setShowAlert] = React.useState(false);
  const [isExpired, setIsExpired] = React.useState(false);
  const [selectedCustomer, setSelectedCustomer] =
    React.useState<ICustomer | null>(null);

  const handleSidebarToggle = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  let expired = false;
  let limitReached = false;

  if (expired) {
    setShowAlert(true);
    setIsExpired(true);
  }
  if (limitReached) {
    setShowAlert(true);
    setIsExpired(false);
  }

  return (
    <div className="max-w-screen min-h-screen">
      <div className="flex max-w-screen h-screen">
        <SideMenu />
        <div
          className={`w-${isSidebarCollapsed ? "full" : "full"} ${
            pathname === "/dashboard" ? "bg-[#F2F4F7]" : "bg-[#ffffff]"
          } w-full border  overflow-x-hidden`}
        >
          <TopBar customer={selectedCustomer} />
          <div className="mt-[16px] md:mt-6 px-4 md:px-6">
            <Outlet />
          </div>
        </div>
      </div>

      <SidebarDrawer title="" position="left" show={showAlert}>
        <SubscriptionAlert isExpired={isExpired} />
      </SidebarDrawer>
    </div>
  );
};

export default DashboardLayout;
