import React, { useCallback, useState, useRef } from "react";
import { Button } from "../common";
import { useNavigate } from "react-router-dom";
import PageLoader from "../common/PageLoader";
import { useUserStore } from "../../state";
import { SubscriptionModal } from "../common/Subscription/SubscriptionModal";
import { MobileSubscriptionModal } from "../common/Subscription/MobileSubscriptionModal";
import usePreviousLocation from "../../hooks/usePreviousLocation";
import { CreateCustomerModal } from "../Sales/customer/createCustomer";
import { ICustomer } from "../../@types";
import { useTranslation } from "react-i18next";
import ActionModal from "../common/ActionModal";
import UploadIcon from "../../assets/icons/svgicons/UploadIcon.svg";
import useRetailBusinessStatus from "../../hooks/invoice/useRetailBusinessStatus";
import generateActionButtons from "../Sales/invoice/invoiceCreationModal";

interface EmptyPageProps {
  pageTitle: string;
  description?: any;
  pageIcon?: any;
  href?: string;
  linkName: string;
  isLoading?: boolean;
  handleSetCustomer: (selectedCustomer: ICustomer | null) => void;
  onClose?: () => void;
  emptyMessage?: string;
}

const EmptyPage = ({
  pageTitle,
  linkName,
  description,
  pageIcon,
  href,
  isLoading,
  handleSetCustomer,
  onClose,
  emptyMessage,
}: EmptyPageProps) => {
  const { t } = useTranslation("inventories");
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const user = useUserStore((state) => state.user);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(true);
  const [hasFetchedSubscription, setHasFetchedSubscription] = useState(false);
  const prevLocation = usePreviousLocation();
  const [showCreateCustomer, setShowCreateCustomer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isRetailBusiness = useRetailBusinessStatus();
  const containerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const handleCreate = useCallback(
    async (pageName: string, query: string) => {
      try {
        if (pageTitle === "Quote") {
          navigate(`/${pageName}/create`);
          return;
        }
        if (pageTitle === "Customer") {
          setShowCreateCustomer(true);
          return;
        }
        if (pageTitle === "Invoice") {
          setIsModalOpen(true);
          return;
        }
        if (showSubscriptionModal) {
          return;
        }

        navigate(`/${pageName}/create`);
      } catch (error: any) {
        setIsSubscriptionLoading(false);
        if (error.response && error.response.status === 400) {
          setShowSubscriptionModal(true);
        }
      }
    },
    [navigate, hasFetchedSubscription, showSubscriptionModal, pageTitle],
  );

  const handleReset = () => {
    window.location.reload();
  };
  const isMobile = window.innerWidth < 768;
  const prefs = user?.prefs || {};
  const isFirstForPageTitle = prefs[`isFirst${pageTitle}`] || false;

  const excludePageTitles = ["Quote", "Customer"];

  const defaultEmptyMessage = isFirstForPageTitle
    ? `Create your First ${pageTitle}`
    : excludePageTitles.includes(pageTitle)
      ? ""
      : `You do not have any ${pageTitle} in this category`;

  const finalEmptyMessage = emptyMessage || defaultEmptyMessage;

  const handleInventoryInvoiceClick = async () => {
    navigate("/invoices/create");
  };

  const handleFreeformInvoiceClick = () => {
    navigate("/invoices/createFreeform");
  };

  const actionButtons = generateActionButtons(
    isRetailBusiness,
    handleInventoryInvoiceClick,
    handleFreeformInvoiceClick,
  );

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div>
          <p style={{ fontWeight: 700 }} className="text-[20px]">
            {pageTitle}
          </p>

          <div className="bg-[#ffffff] mt-[16px] h-screen mb-[43px] pt-[80px]">
            <div className="flex flex-col justify-center text-center items-center mb-[40px]">
              <span>{pageIcon}</span>
              <p className="text-[20px] text-[#000000] font-medium my-4">
                {prevLocation.search && prevLocation.search.length > 0
                  ? "You do not have any data for the selected date"
                  : finalEmptyMessage}
              </p>
              {isFirstForPageTitle || excludePageTitles.includes(pageTitle) ? (
                <div className="text-center w-[208px] sm:w-[434px] md:px-0 md:w-[24.3%]">
                  <Button
                    onClick={() => handleCreate(linkName, linkName)}
                    variant="secondary"
                  >
                    Create {pageTitle}
                  </Button>
                </div>
              ) : prevLocation.search && prevLocation.search.length > 0 ? (
                <div className="text-center w-[208px] sm:w-[434px] md:px-0 md:w-[24.3%]">
                  <Button
                    onClick={() => navigate("/dashboard")}
                    variant="primary"
                  >
                    Go back
                  </Button>
                </div>
              ) : (
                <div className="text-center w-[208px] sm:w-[434px] md:px-0 md:w-[24.3%]">
                  <Button onClick={handleReset} variant="primary">
                    Reset Filters
                  </Button>
                </div>
              )}
            </div>
          </div>

          <div className="mt-[-60em]">
            {showSubscriptionModal && (
              <SubscriptionModal>
                <MobileSubscriptionModal
                  onClose={() => setShowSubscriptionModal(false)}
                  titleText={`Need to access more features?`}
                  subtitleText={"You cannot use this feature without an active"}
                  subtitleText2={"subscription"}
                />
              </SubscriptionModal>
            )}
          </div>

          {showCreateCustomer && (
            <CreateCustomerModal
              showSideModal={showCreateCustomer}
              setShowSideModal={setShowCreateCustomer}
              handleSetCustomer={handleSetCustomer}
              onCustomerCreate={(customer) => {
                handleSetCustomer(customer);
                setShowCreateCustomer(false);
              }}
              onClose={() => setShowCreateCustomer(false)}
            />
          )}

          <div ref={containerRef}>
            <ActionModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              title="Select an invoice creation option"
              icon={UploadIcon}
              actionButtons={actionButtons}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default EmptyPage;
