import React from "react";
import { CloseIcon } from "../../assets/icons";

interface ActionButtonProps {
  label: string;
  onClick: () => void;
}

interface ActionModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  icon?: string;
  actionButtons?: ActionButtonProps[];
}

const ActionModal: React.FC<ActionModalProps> = ({
  isOpen,
  onClose,
  title,
  icon,
  actionButtons,
}) => {
  return (
    <>
      {isOpen && (
        <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh]">
          <div className="hidden md:flex justify-center items-center h-full w-full">
            <div
              className={`fixed md:top-[25%] z-50  md:w-[440px] lg:w-[500px] px-4 md:px-8 pb-8 pt-4 bg-white rounded-t-[8px] md:rounded-[8px]`}
            >
              <div
                onClick={onClose}
                className="flex justify-end mb-[-3em] cursor-pointer"
              >
                <CloseIcon />
              </div>
              <div className="flex justify-center items-center">
                <img src={icon} alt="upload expense" />
              </div>
              <div className="my-8">
                <p className="text-center">{title}</p>
              </div>
              <div className="flex gap-4 text-[16px] font-medium">
                {actionButtons?.map((button, i) => (
                  <button
                    key={i}
                    onClick={button.onClick}
                    className={`h-[48px] w-full flex justify-center items-center rounded-[4px] ${
                      i === 0
                        ? " bg-[#044E97] text-white"
                        : "border border-[#044E97] text-[#044E97]"
                    }`}
                  >
                    {button.label}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div
            className={`md:hidden overflow-y-auto overflow-x-hidden fixed bottom-0 w-full z-50 px-4 pb-8 pt-4 bg-white rounded-t-[8px]`}
          >
            <div
              onClick={onClose}
              className="flex justify-end mb-[-3em] cursor-pointer"
            >
              <CloseIcon />
            </div>
            <div className="flex justify-center items-center">
              <img src={icon} alt="upload expense" />
            </div>
            <div className="my-8">
              <p className="text-center">{title}</p>
            </div>
            <div className="flex gap-4 text-[16px] font-medium">
              {actionButtons?.map((button, i) => (
                <button
                  key={i}
                  onClick={button.onClick}
                  className={`h-[48px] w-full flex justify-center items-center rounded-[4px] ${
                    i === 0
                      ? " bg-[#044E97] text-white"
                      : "border border-[#044E97] text-[#044E97]"
                  }`}
                >
                  {button.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ActionModal;
