import React, { useState } from "react";
import ViewIcon from "../../assets/icons/ViewIcon";
import ViewHideIcon from "../../assets/icons/ViewHideIcon";

interface ToggleButtonProps {
  isInfoCardVisible: boolean;
  setIsInfoCardVisible: (newState: boolean) => void;
}
const ListToggleButton = ({
  isInfoCardVisible,
  setIsInfoCardVisible,
}: ToggleButtonProps) => {
  return (
    <div>
      <button
        className={`flex gap-2 items-center p-3 border border-gray-200 rounded-[4px]`}
        onClick={() => setIsInfoCardVisible(!isInfoCardVisible)}
      >
        <div>
          {isInfoCardVisible ? (
            <ViewIcon isWhite={false} />
          ) : (
            <ViewHideIcon isWhite={true} />
          )}
        </div>
        <div className="text-[16px] text-gray-700  font-medium leading-6">
          {isInfoCardVisible ? "Hide Metrics" : "Show Metrics"}
        </div>
      </button>
    </div>
  );
};
export default ListToggleButton;
