import { Button } from "flowbite-react";
import React, { useState } from "react";
import SubscriptionHistoryWidget from "./SubscriptionHistoryWidget";
import { subscriptions } from "../../../state/useSubscriptionStore";
import CancelSubscriptionPrompt from "./cancelSubscriptionPrompt";
import { SidebarDrawer } from "../../common";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { formatMoney } from "../../../utils";
import { useTranslation } from "react-i18next";

interface SubscriptionPlanProps {
  subscriptionState: subscriptions | null;
}

const SubscriptionPlan = ({ subscriptionState }: SubscriptionPlanProps) => {
  const { t } = useTranslation("subscription");
  const getDaySuffix = (day: number) => {
    if (day >= 11 && day <= 13) {
      return day + "th";
    }

    switch (day % 10) {
      case 1:
        return day + "st";
      case 2:
        return day + "nd";
      case 3:
        return day + "rd";
      default:
        return day + "th";
    }
  };

  const formattedDate = subscriptionState
    ? t("subscription.nextDate", {
        month: dayjs(subscriptionState?.endDate).format("MMM"),
        day: getDaySuffix(dayjs(subscriptionState?.endDate).date()),
        year: dayjs(subscriptionState?.endDate).format("YYYY"),
      })
    : t("subscription.expiredMessage");
  return (
    <div className="w-full lg:w-[105.4%]">
      <p className="text-[14px] sm:text-[16px] whitespace-nowrap">
        {formattedDate}
        {/* {subscriptionState
          ? `Your Next Subscription Plan Date is 
     ${dayjs(subscriptionState?.endDate).format('MMM')} 
     ${getDaySuffix(dayjs(subscriptionState?.endDate).date())} 
     ${dayjs(subscriptionState?.endDate).format('YYYY')}`
          : "You don't have an active subscription"} */}
      </p>{" "}
      <div className="bg-[#EDF2F5] rounded-[4px] w-full lg:h-[193px] flex gap-[30px] pb-[31px] flex-col justify-between px-[12px] md:px-[30px] pt-[36.5px] mt-[12px]">
        <div className="flex flex-row justify-between items-center">
          {subscriptionState?.status == "pending" ? (
            <SkeletonTheme baseColor="#BDD0DB" highlightColor="#fff">
              <Skeleton style={{ width: 70 }} height={"18px"} />
            </SkeletonTheme>
          ) : (
            <p
              className="text-[#212121] text-[16px]"
              style={{ fontWeight: 600 }}
            >
              {subscriptionState?.plan
                ? subscriptionState.plan
                : "No Active Plan"}
            </p>
          )}
          {subscriptionState?.status == "pending" ? (
            <SkeletonTheme baseColor="#BDD0DB" highlightColor="#fff">
              <Skeleton width={"129px"} height={"39px"} />
            </SkeletonTheme>
          ) : (
            <p>
              {" "}
              <span
                className="text-[#212121] text-[16px] lg:text-[30px]"
                style={{ fontWeight: 600 }}
              >
                {formatMoney(
                  subscriptionState?.costPerMonth as unknown as number,
                )
                  ? formatMoney(
                      subscriptionState?.costPerMonth as unknown as number,
                    )
                  : 0}
              </span>
              /month
            </p>
          )}
        </div>

        <div className="flex flex-row justify-between items-center">
          <SubscriptionHistoryWidget subscriptionData={subscriptionState} />
          <Link to={"/subscription"}>
            {subscriptionState?.status == "pending" ? (
              <SkeletonTheme baseColor="#EDF2F5" highlightColor="#fff">
                <Skeleton width={"300%"} height={"9px"} />
              </SkeletonTheme>
            ) : (
              <button
                className="border border-[#044E97] rounded-[4px] py-[11px] px-[13px] md:py-[16px] md:px-[34px] text-[#044E97] text-[16px] whitespace-nowrap"
                style={{ fontWeight: 600 }}
              >
                {subscriptionState?.status === "active" ? (
                  <p>Change Plan</p>
                ) : (
                  <p>Choose Plan</p>
                )}
              </button>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
