import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import DangerIcon from "../../../../assets/icons/danger";
import { useUserStore } from "../../../../state";
import { BankData, Customer } from "../../../../types";
import BankModal from "./BankModal";
import { useTranslation } from "react-i18next";

interface GeneratePaymentPromptProps {
  onFailed: (event: any) => void;
  onClose?: () => void;
  showFailedVerificationPrompt: boolean;
  invoiceId: string | undefined;
  bankAccounts: BankData;
  showPaymentPrompt: (show: boolean) => void;
}
const FailedVerifiicationModal: React.FC<GeneratePaymentPromptProps> = ({
  onFailed,
  onClose,
  showFailedVerificationPrompt,
  invoiceId,
  bankAccounts,
  showPaymentPrompt,
}) => {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  if (!user) {
    return null;
  }
  const bankAccount = user.bankAccounts || [];
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [showCreateBank, setShowCreateBank] = useState(false);
  const [bankAccountState, setBankAccountState] =
    useState<BankData>(bankAccount);
  const activeBankData = bankAccount.filter(
    (bankAccount: BankData) => bankAccount.isActive,
  );
  const [isActive, setIsActive] = useState(bankAccount.isActive);
  const [showVerificationModal, setShowVerificationModal] = useState(
    showFailedVerificationPrompt,
  );

  const handleAddBankAccount = (newBankAccount: BankData) => {
    user.bankAccounts.push(newBankAccount);
    setSelectedAccounts([...selectedAccounts, newBankAccount.accountNumber]);
  };
  const handleValidateSuccess = (updatedData: BankData) => {
    setBankAccountState(updatedData);
    setIsActive(updatedData.isActive);
    setShowVerificationModal(false);
  };

  const { t } = useTranslation("invoices");

  const mainAccountIndex = bankAccount.findIndex(
    (acc: BankData) => acc.isActive,
  );
  return (
    <>
      <div className="p-[32px] bg-[#ffffff] rounded-[16px] xl:max-h-[748px] 2xl:min-h-[748px] biglg:max-h-[648px] lg:max-h-[500px] w-[704px] xl:w-[440px] 2xl:w-[440px] biglg:w-[440px] lg:w-[440px] overflow-y-auto">
        <div className="flex flex-col justify-center items-center text-center">
          <DangerIcon />
          <h1 className="xl:text-[25px] biglg:text-[25px] 2xl:text-[25px] lg:text-[20px] text-[14px] md:text-[18px] font-semibold mt-[0.6em]">
            Oops!
          </h1>

          <p className="text-center mt-[1em] lg:text-[14px] text-[14px] md:text-[18px]">
            {" "}
            {t("bankAccountVer")}
            <br />
            {t("bankAccountVer1")}
            <br />
            {t("bankAccountVer2")}
          </p>
        </div>
        <div className="flex justify-center gap-8 mt-[1em] text-[10px] md:text-[14px]">
          <button
            className="border border-[#044E97] text-[#044E97] bg-[white] lg:text-[16px] py-2 px-[3em] rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-[#044E97] text-white lg:text-[16px] py-2 px-[1em] rounded-md"
            onClick={() => setShowCreateBank(true)}
          >
            Update Account
          </button>
        </div>
      </div>

      {showCreateBank && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-opacity-80 flex items-center justify-center z-10 ">
          <div className="bg-white pt-1  pl-2 pr-0 rounded-lg shadow-lg">
            <BankModal
              onCreate={handleAddBankAccount}
              setShowSideModal={setShowCreateBank}
              toolTipText={
                "Enter the first three letters to search and select your bank name."
              }
              toolTipText2={
                "Enter your accurate account number. This will be listed on invoices generated for customers."
              }
              toolTipText3={
                "Please note that for you to be able to use the payment link feature, your account name must be a business account and match your business name."
              }
              initialData={{
                bankName: "",
                accountNumber: "",
                accountName: "",
                isActive: true,
                currency: "NGN",
              }}
              onValidateSuccess={handleValidateSuccess}
              accountIndex={mainAccountIndex}
              showPaymentPrompt={showPaymentPrompt}
            />
          </div>
        </div>
      )}
    </>
  );
};

export { FailedVerifiicationModal };
