import React from "react";

const UploadIcon = () => {
  return (
    <div>
      <svg
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9216 17.875H8.25C5.97183 17.875 4.125 16.0282 4.125 13.75C4.125 11.4718 5.97183 9.625 8.25 9.625C8.87102 9.625 9.18153 9.625 9.40161 9.50803C9.46202 9.47592 9.49171 9.4567 9.54573 9.41474C9.74256 9.26185 9.90323 8.90385 10.2246 8.18786C11.2994 5.79298 13.7049 4.125 16.5 4.125C19.2516 4.125 21.6257 5.74151 22.7243 8.07668C23.1123 8.9013 23.3063 9.31362 23.5516 9.46931C23.7969 9.625 24.1563 9.625 24.875 9.625C27.0841 9.625 28.875 11.4159 28.875 13.625V13.875C28.875 16.0841 27.0841 17.875 24.875 17.875H22.0784L17.9142 13.7108L16.5 12.2966L15.0858 13.7108L10.9216 17.875Z"
          fill="#044E97"
        />
        <path
          d="M16.5 16.5L15.7929 15.7929L16.5 15.0858L17.2071 15.7929L16.5 16.5ZM17.5 28.875C17.5 29.4273 17.0523 29.875 16.5 29.875C15.9477 29.875 15.5 29.4273 15.5 28.875L17.5 28.875ZM10.2929 21.2929L15.7929 15.7929L17.2071 17.2071L11.7071 22.7071L10.2929 21.2929ZM17.2071 15.7929L22.7071 21.2929L21.2929 22.7071L15.7929 17.2071L17.2071 15.7929ZM17.5 16.5L17.5 28.875L15.5 28.875L15.5 16.5L17.5 16.5Z"
          fill="#044E97"
        />
      </svg>
    </div>
  );
};

export default UploadIcon;
