import { useState, useEffect } from "react";
import {
  checkApiSupport,
  detectUSBPrinters,
} from "../utils/thermalPrinterUtils";
import { savePrinterToLocalStorage } from "../utils";

export interface Printer {
  name: string;
  device: BluetoothDevice | USBDevice;
  [key: string]: any;
}

export const useThermalPrinter = () => {
  const [printer, setPrinter] = useState<any>({});
  const [isApiSupported, setIsApiSupported] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const isSupported = checkApiSupport();
    setIsApiSupported(isSupported);
    if (!isSupported) return;
  }, []);

  const handleDetectPrinters = async () => {
    try {
      const usbDevice = await detectUSBPrinters();
      if (usbDevice) {
        const detectedPrinter = {
          name: usbDevice.productName,
          vendorId: usbDevice.vendorId,
          productId: usbDevice.productId,
          type: "usb",
        };
        setPrinter(detectedPrinter);
        // @ts-ignore
        savePrinterToLocalStorage(usbDevice);
        return detectedPrinter;
      }
    } catch (error) {
      console.error("USB detection error:", error);
      throw error;
    }
  };

  return {
    isApiSupported,
    printer,
    error,
    setError,
    setPrinter,
    handleDetectPrinters,
  };
};
