import { ServiceStatus } from "../@types/service";
import { ServiceBadge } from "../components/common/Badge/serviceBage";

export const getServiceStatus = (status: ServiceStatus) => {
  const getStatusLabel = (): string => {
    switch (status.toLowerCase()) {
      case "inactive":
        return "Inactive";
      case "pending":
        return "Pending";
      case "active":
        return "Active";
      default:
        return "Secondary";
    }
  };

  return (
    <ServiceBadge
      label={getStatusLabel()}
      color={
        status === "inactive"
          ? "inactive"
          : status === "pending"
            ? "pending"
            : status === "active"
              ? "active"
              : "secondary"
      }
    />
  );
};
