import React from "react";

const PlusIcon = ({ color = "#044E97" }: { color?: string }) => {
  return (
    <>
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.00024 1.5L9.00025 15.5"
          stroke={color}
          stroke-width="3"
          stroke-linecap="square"
          stroke-linejoin="round"
        />
        <path
          d="M16 8.5L2 8.5"
          stroke={color}
          stroke-width="3"
          stroke-linecap="square"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default PlusIcon;
