import React from "react";

const CategoryIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5143 23.4857L0.514286 11.4857C0.171428 11.1429 0 10.7143 0 10.2V1.71429C0 0.771429 0.771429 0 1.71429 0H10.2C10.6286 0 11.0571 0.171428 11.4 0.514286L23.4857 12.6C24.1714 13.2857 24.1714 14.3143 23.4857 15L15 23.4857C14.3143 24.1714 13.2 24.1714 12.5143 23.4857ZM1.71429 1.71429V10.2L13.8 22.2857L22.2857 13.8L10.2 1.71429H1.71429Z"
          fill="#044E97"
        />
        <path
          d="M6.85631 10.2859C4.97059 10.2859 3.42773 8.743 3.42773 6.85728C3.42773 4.97157 4.97059 3.42871 6.85631 3.42871C8.74202 3.42871 10.2849 4.97157 10.2849 6.85728C10.2849 8.743 8.74202 10.2859 6.85631 10.2859ZM6.85631 5.143C5.91345 5.143 5.14202 5.91443 5.14202 6.85728C5.14202 7.80014 5.91345 8.57157 6.85631 8.57157C7.79916 8.57157 8.57059 7.80014 8.57059 6.85728C8.57059 5.91443 7.79916 5.143 6.85631 5.143Z"
          fill="#044E97"
        />
      </svg>
    </div>
  );
};

export default CategoryIcon;
