import React from "react";
import { Product } from "../../../@types";
import { formatMoney } from "../../../utils";

type TableRowProps = {
  product: any;
  onActionClick: (productId: string) => void;
};

const TableRow: React.FC<TableRowProps> = ({ product, onActionClick }) => {
  return (
    <div className="grid grid-cols-5 mx-[16px] text-center pt-[18px] pb-[15px] items-center">
      <div className="text-[12px] leading-[15.2px] ml-[-1em]">
        {product.name}
      </div>
      <div className="text-[12px] leading-[15.2px] ">{product.quantity}</div>
      <div className="text-[12px] leading-[15.2px] ">
        {formatMoney(product.sellingPrice)}
      </div>
      <div className="text-[12px] leading-[15.2px] col-span-2 flex justify-center">
        <button
          className="h-[34px] w-[86px] md:w-[117px] flex justify-center items-center text-[12px] font-semibold leading-[16px] border border-[#044E97] rounded-[4px] text-[#044E97]"
          onClick={() => onActionClick(product.id)}
        >
          Restock
        </button>
      </div>
    </div>
  );
};

export { TableRow };
