import dayjs from "dayjs";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DownloadIcon } from "../../assets/icons";
import BackArrow from "../../assets/icons/backArrow";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import EditIcon from "../../assets/icons/EditIcon";
import { GroupButton, SidebarDrawer, Table } from "../../components/common";
import PageLoader from "../../components/common/PageLoader";
import { ExpenseDeletePrompt } from "../../components/expense/ExpenseDelete";
import { formatMoney } from "../../utils";
import {
  convertExpense,
  editToggleProtected,
  getExpense,
} from "../../backend-services";
import toast from "react-hot-toast";
import LeftNotificationPopup from "../../components/core/NotificationPopup/LeftNotificationPopup";
import ProcessSuccessful from "../../assets/icons/ProcessSuccessful";
import { useExpense } from "../../hooks/expenses/useExpense";
import { MobileTable } from "../../components/common/mobileTable";
import { useUserStore } from "../../state";
import AnnotationComponent from "../../components/common/Annotation/AnnotationComponent";
import useAnnotations from "../../hooks/annotation/useAnnotations";
import AttachIcon from "../../assets/icons/AttachIcon";
import { UploadExpenseReceipt } from "../../components/expense/UploadReceipt";
import { isNotAccountant } from "../../utils/isNotAccountant";
import ProtectedExpenseModal from "../../components/expense/ExpenseCreate/ProtectedExpenseModal";
import { ExpenseDetails } from "../../components/expense/expenseDetails";
import AddSquare from "../../assets/icons/AddSquare";
import { DotMenu } from "../../assets/icons/DotMenu";
import { useTranslation } from "react-i18next";

interface IGroupButton {
  text: string;
  icon: ReactNode;
  textColorClass?: string;
  onClick?: () => void;
  href?: string;
}

interface GroupButtonProps {
  buttons: IGroupButton[];
}

const ViewExpense = () => {
  const { t } = useTranslation("expenses");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showSuccessfulModal, setShowSuccessfulModal] = React.useState(false);
  const [invoices, setInvoices] = React.useState<any[]>([]);
  const [isBillable, setIsBillable] = React.useState(false);
  const [isProtected, setIsProtected] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const [showUploadReceipt, setShowUploadReceipt] = React.useState(false);
  const { roles } = useUserStore();
  const [openDotMenu, setOpenDotMenu] = React.useState(false);

  const params = useParams();

  const { expenseId } = useParams<{ expenseId: any }>();
  if (!expenseId) {
    throw new Error("Expense Id is missing");
  }
  const { expense, isLoading, annotations } = useExpense(expenseId);
  const [isConverting, setIsConverting] = React.useState(false);
  const [isConverted, setIsConverted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const annotationsData = useAnnotations(annotations, "Expense");

  useEffect(() => {
    if (expense) {
      setIsProtected(expense.isProtected);
    }
  }, [expense]);

  const handleDownloadClick = useCallback(async () => {
    setDownloading(true);
    try {
      if (!expenseId) throw new Error("Expense Id is missing");
      const expense = await getExpense(expenseId);
      if (expense && expense.receiptUrl) {
        const receiptUrl = expense.receiptUrl;
        const formattedDate = dayjs(expense.date).format("YYYY-MM-DD");
        const fileName = `receipt_${expenseId}_${formattedDate}.pdf`;
        window.location.href = receiptUrl;
      }
    } catch (error) {
      setError("Error fetching expense. Please try again later.");
    } finally {
      setDownloading(false);
    }
  }, [expenseId]);

  const data = useMemo(() => {
    return (
      expense?.lineItems.map(
        (exp: { name: any; quantity: string; rate: string }, i: number) => ({
          id: i + 1,
          name: exp?.name,
          quantity: exp?.quantity,
          rate: formatMoney(`${exp?.rate}`),
          amount: formatMoney(`${parseInt(exp.rate) * parseInt(exp.quantity)}`),
        }),
      ) || []
    );
  }, [expense]);

  const renderLineItemsData = () => {
    return data;
  };

  const renderLineItemsTableHead = () => {
    return {
      id: { title: "#" },
      name: { title: "Items/Description" },
      quantity: { title: "Quantity" },
      rate: { title: "Rate" },
      amount: { title: "Amount" },
    };
  };

  const columns = [
    { key: "id", label: "#" },
    { key: "name", label: "Items/Description" },
    { key: "quantity", label: "Quantity" },
    { key: "rate", label: "Rate" },
    { key: "amount", label: "Amount" },
  ];

  const handleExpenseDeleteSuccess = () => {
    setShowSuccessModal(true);
  };

  const handleConvertExpense = async (id: string) => {
    setIsConverting(true);
    try {
      const createdInvoice = await convertExpense(expenseId);
      setInvoices((prevInvoices) => [...prevInvoices, createdInvoice]);
      setIsConverting(false);
      setIsConverted(true);
      setShowSuccessfulModal(true);
      setTimeout(() => {
        navigate("/invoices");
      }, 2000);
    } catch (error: any) {
      setErrorMessage("Unable to Convert Expense.");
      setIsConverting(false);
    }
  };

  const handleProtectedExpenseModal = () => {
    setIsOpenModal(true);
  };

  const handleIsProtectedChange = async () => {
    try {
      await editToggleProtected(expenseId, { isProtected: !isProtected });
      setIsOpenModal(false);
      setIsProtected((prevIsProtected) => !prevIsProtected);
      toast.success("Expense protection status updated successfully.");
    } catch (error) {
      toast.error(
        "Failed to update expense protection status. Please try again later.",
      );
      setErrorMessage("Error updating expense protection status:");
    }
  };

  const groupButtonProps = useMemo(
    () => ({
      buttons: [
        {
          text: "Edit",
          icon: <EditIcon />,
          href: `/expenses/edit/${expenseId}`,
        },
        // {
        //   text: downloading ? 'Downloading...' : 'Download',
        //   icon: <DownloadIcon />,
        //   onClick: handleDownloadClick,
        // },
        {
          text: `Attach Receipt`,
          icon: <AttachIcon />,
          onClick: () => setShowUploadReceipt(true),
        },
        {
          text: "Delete",
          icon: <DeleteIcon />,
          onClick: () => setShowPrompt(true),
          textColorClass: "text-[#FB0303]",
          fontSize: "text-[#FB0303]",
        },
      ].filter(Boolean),
    }),
    [downloading, expenseId, handleDownloadClick],
  );
  const MobileGroupButtonProps = useMemo(
    () => ({
      buttons: [
        {
          text: "Edit",
          icon: <EditIcon />,
          href: `/expenses/edit/${expenseId}`,
          fontSize: "text-[12px]",
        },
        // {
        //   text: downloading ? 'Downloading...' : 'Download',
        //   icon: <DownloadIcon />,
        //   fontSize: 'text-[12px]',
        //   onClick: handleDownloadClick,
        // },
      ].filter(Boolean),
    }),
    [downloading, expenseId, handleDownloadClick],
  );

  return (
    <div className="px-1 540:px-3 md:px-[30px] lg:px-0 lg:ml-[9.7%] lg:mr-[4.6%]">
      {isLoading ? (
        <PageLoader></PageLoader>
      ) : (
        <div className="lg:mt-16">
          <div className="flex md:justify-between items-center">
            <div>
              <a
                className="flex items-center gap-[10.75px] md:gap-3"
                href="/expenses"
              >
                <BackArrow />
                <h1 className="font-medium text-[20px] md:text-[24px]">
                  Expenses
                </h1>
              </a>
            </div>
            <div className="md:flex md:gap-4 md:items-center flex">
              <div className="hidden md:block bg-[#EDF2F5]">
                {isNotAccountant(roles) && (
                  <GroupButton {...groupButtonProps} />
                )}
              </div>
              <span className="lg:hidden md:hidden ml-[13em]">
                <AnnotationComponent
                  type="expense"
                  annotationData={annotationsData}
                />
              </span>
              <span className="hidden md:block">
                <AnnotationComponent
                  type="expense"
                  annotationData={annotationsData}
                />
              </span>
            </div>
          </div>

          <div className="mt-4 md:hidden">
            <div className="w-full flex gap-3 justify-end">
              <div className="bg-[#EDF2F5]">
                {isNotAccountant(roles) && (
                  <GroupButton {...MobileGroupButtonProps} />
                )}
              </div>
              <div
                className="relative px-1 py-2 bg-[#EDF3F6] rounded border border-[#212121] border-opacity-10"
                onClick={() => setOpenDotMenu(!openDotMenu)}
              >
                <DotMenu size="26" />
                {openDotMenu && (
                  <div className="absolute shadow-md top-12 rounded-[4px] right-0 w-[169px] bg-white p-[12px] space-y-[24px] text-[14px] text-[#212121]">
                    <p onClick={() => navigate(`/expenses/edit/${expenseId}`)}>
                      Edit
                    </p>
                    <p onClick={() => setShowUploadReceipt(true)}>
                      Attach Receipt
                    </p>
                    <p
                      onClick={() => setShowPrompt(true)}
                      className="text-[#FB0303]"
                    >
                      Delete
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4">
              <p className="text-[14px]">{t("budget.expenseAmount")}</p>
              <p className="font-semibold mt-[6px] text-[#FB0303] text-[16px]">{`N${expense?.amount?.toLocaleString()}`}</p>
            </div>
          </div>

          <div className="w-full mt-4 md:mt-[32px]">
            <ExpenseDetails
              expense={expense}
              isBillable={isBillable}
              isProtected={isProtected}
              handleIsProtectedChange={handleProtectedExpenseModal}
              handleDownloadClick={handleDownloadClick}
              downloading={downloading}
            />
          </div>

          <div className="mt-[1.5em]">
            <h3 className="text-[#00000] text-[16px] lg:text-[20px] font-semibold leading-6">
              {t("budget.expenseHistory")}
            </h3>
            <div className="md:hidden">
              <MobileTable
                expenseCard={true}
                searchPlaceHolder="Search"
                searchDisplayKey="id"
                searchResult={[]}
                head={renderLineItemsTableHead()}
                data={renderLineItemsData()}
                isLoading={isLoading}
                buttons={[]}
                fetchMoreData={undefined}
                meta={undefined}
              />
            </div>
            <div className="hidden md:block">
              <Table
                columns={columns}
                expenseCard={true}
                searchPlaceHolder="Search"
                searchDisplayKey="id"
                searchResult={[]}
                head={renderLineItemsTableHead()}
                data={renderLineItemsData()}
                isLoading={isLoading}
                buttons={[]}
                fetchMoreData={undefined}
                meta={undefined}
              />
            </div>
          </div>

          {showSuccessfulModal && (
            <>
              <SidebarDrawer
                title={"Invoice Created"}
                show={true}
                position={"left"}
                onClose={() => setShowSuccessfulModal(false)}
              >
                <LeftNotificationPopup
                  title={""}
                  icon={<ProcessSuccessful />}
                  onClose={() => setShowSuccessfulModal(false)}
                />
              </SidebarDrawer>
            </>
          )}
        </div>
      )}
      {showUploadReceipt && (
        <UploadExpenseReceipt
          expenseId={expenseId}
          closeModal={setShowUploadReceipt}
        />
      )}
      {isOpenModal && (
        <ProtectedExpenseModal
          setIsOpenModal={setIsOpenModal}
          handleProtectedChange={handleIsProtectedChange}
        />
      )}
      {showPrompt && (
        <>
          <SidebarDrawer
            title={""}
            showHeader={false}
            show={true}
            position={"right"}
            onClose={() => setShowPrompt(false)}
          >
            <ExpenseDeletePrompt
              showPrompt={showPrompt}
              expenseId={expenseId}
              onClose={() => setShowPrompt(false)}
              onDeleteSuccess={handleExpenseDeleteSuccess}
            />
          </SidebarDrawer>
        </>
      )}
    </div>
  );
};

export { ViewExpense };
