import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ISetReminder } from "../../../../@types";
import ArrowLeft from "../../../../assets/icons/ArrowLeft";
import ArrowRight from "../../../../assets/icons/ArrowRight";
import EditIcon from "../../../../assets/icons/EditIcon";
import { setReminders } from "../../../../backend-services";
import { useUserStore } from "../../../../state";
import AutomateReminderModal from "./AutomateModal";
import AutomateInvoice from "./ReminderSmallModal";

interface ReminderInvoiceOptionsProps {
  onReminderChange: (data: {
    activeStatus: { before: boolean; due: boolean; after: boolean };
    days: { before: number; due: number; after: number };
  }) => void;
}

const ReminderInvoiceOptions: React.FC<ReminderInvoiceOptionsProps> = ({
  onReminderChange,
}) => {
  const [reminderActiveStatus, setReminderActiveStatus] = useState({
    before: false,
    due: false,
    after: false,
  });

  const [reminderDays, setReminderDays] = useState({
    before: 0,
    due: 0,
    after: 0,
  });

  const [showReminderModal, setShowReminderModal] = useState(false);
  const [currentReminderType, setCurrentReminderType] = useState<
    "before" | "due" | "after"
  >("before");

  useEffect(() => {
    // Notify parent about initial state
    onReminderChange({
      activeStatus: reminderActiveStatus,
      days: reminderDays,
    });
  }, []);

  const handleToggleActive = (type: "before" | "due" | "after") => {
    // Open the modal without changing the status directly
    setCurrentReminderType(type);
    setShowReminderModal(true);
  };

  // Handle the saving of reminder data
  const handleReminderSave = (data: {
    days: number;
    remindBy?: "before" | "due" | "after";
    isActive: boolean;
  }) => {
    const remindBy = data.remindBy || currentReminderType;

    setReminderDays((prev) => {
      const updatedDays = { ...prev, [remindBy]: data.days };

      setReminderActiveStatus((prevStatus) => {
        const updatedStatus = { ...prevStatus, [remindBy]: data.isActive };

        // If days are set, make the reminder active
        if (data.days > 0) {
          updatedStatus[remindBy] = true; // Set active when days are set
        }

        // Notify parent with updated data
        onReminderChange({
          activeStatus: updatedStatus,
          days: updatedDays,
        });

        return updatedStatus;
      });

      return updatedDays;
    });

    setShowReminderModal(false);
  };

  const handleEditReminder = (type: "before" | "due" | "after") => {
    setCurrentReminderType(type);
    setShowReminderModal(true);
  };

  const renderReminderRow = (
    label: string,
    type: "before" | "due" | "after",
    description: string,
  ) => (
    <div className="flex gap-2 mt-[-0.6em] text-sm">
      <div className="bg-[#F9FAFB] px-3 py-2 rounded-[8px] border border-[#044E9733] border-opacity-10 mt-4 flex items-center justify-between w-full">
        <div className="flex gap-2 items-center">
          <p className="text-[#044E97]">{label}</p>
          <span className="pl-4">
            {type === "due"
              ? "Due date"
              : `${reminderDays[type]} days ${description}`}
          </span>
        </div>
        <div
          className={`w-12 h-6 p-1 rounded-[69.33px] border-[0.53px] border-[#044E97] flex items-center transition duration-200 ease-in ${
            reminderActiveStatus[type]
              ? "bg-[#044E97] justify-end"
              : "bg-[#ffffff] justify-start"
          }`}
          onClick={() => handleToggleActive(type)}
        >
          <div
            className={`${
              reminderActiveStatus[type] ? "bg-[#ffffff]" : "bg-[#D1D5DB]"
            } cursor-pointer w-4 h-4 rounded-full flex justify-center items-center`}
          >
            {reminderActiveStatus[type] ? <ArrowLeft /> : <ArrowRight />}
          </div>
        </div>
      </div>
      {reminderActiveStatus[type] && reminderDays[type] > 0 && (
        <div
          onClick={() => handleEditReminder(type)}
          className="cursor-pointer border border-[#044E9733] rounded-md bg-[#F3FBFB] border-opacity-10 mt-4 flex justify-center items-center py-1 px-1"
        >
          <EditIcon />
        </div>
      )}
    </div>
  );

  return (
    <div className="text-[10px] lg:text-[10px] mt-[0.5em] whitespace-nowrap cursor-pointer">
      {renderReminderRow("Reminder - 1", "before", "before due date")}
      {renderReminderRow("Reminder - 2", "due", " due date")}
      {renderReminderRow("Reminder - 3", "after", "after due date")}

      {showReminderModal && (
        <AutomateInvoice
          setShowSendModal={setShowReminderModal}
          onSaveReminder={(data) =>
            handleReminderSave({ ...data, remindBy: currentReminderType })
          }
          reminderType={currentReminderType}
          days={reminderDays[currentReminderType]}
        />
      )}
    </div>
  );
};

export default ReminderInvoiceOptions;
