import React from "react";

const LogoutIcon2 = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.832 2.5L11.3842 2.69487C13.5331 3.45333 14.6076 3.83256 15.2198 4.69785C15.832 5.56313 15.832 6.70258 15.832 8.98147V11.0185C15.832 13.2974 15.832 14.4369 15.2198 15.3022C14.6076 16.1674 13.5331 16.5467 11.3842 17.3051L10.832 17.5"
          stroke="#D92D20"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M2.4987 9.99935L10.832 9.99935M2.4987 9.99935C2.4987 9.41583 4.16062 8.32562 4.58203 7.91602M2.4987 9.99935C2.4987 10.5829 4.16062 11.6731 4.58203 12.0827"
          stroke="#D92D20"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export { LogoutIcon2 };
