export const convertToFloat = (value: string | number | undefined): number => {
  const stringValue = (value || "").toString();
  return parseFloat(stringValue.replace(/[^0-9.-]/g, ""));
};

export const commasToNumber = (value: any) => {
  let nums = value.toString();
  nums = nums.replace(/\D/g, "");
  nums = nums.replace(/(\d)(\d{2})$/, "$1.$2");
  nums = nums.replace(/(?=(\d{3})+(\D))\B/g, ",");
  value = nums;
  return value;
};
export const commasToRestockNumber = (value: any) => {
  let nums = value.toString();
  nums = nums.replace(/\D/g, "");
  nums = nums.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  value = nums;
  return value;
};

export const addCommasToNumber = (value: any) => {
  if (value === null) {
    return "";
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
