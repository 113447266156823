import { create } from "zustand";
import { expenseService } from "../services";
import { IExpense, IPaginationMeta } from "../@types";
import { debounce } from "lodash";
import { ExpenseFilter } from "../@types";
import { Filter } from "../@types/invoice";

interface FetchOptions {
  startDate?: string;
  endDate?: string;
  actionType?: "initial" | "filter";
}

interface ExpenseStore {
  expenses: IExpense[];
  isLoading: boolean;
  isLoadingInitial: boolean;
  isLoadingFilter: boolean;
  isLoadingMore: boolean;
  error: any;
  fetchExpenses: () => Promise<void>;
  fetchData: (options?: FetchOptions) => Promise<void> | undefined;
  fetchMoreData: () => Promise<void>;
  setSearchText: (text: string) => void;
  handleSort: (text: string) => void;
  setFilter: (filter: Filter) => void;
  removeInvoice: (id: string) => void;
  addInvoice: (invoice: any) => void;
  pagination: IPaginationMeta;
  searchText: string;
  range: string;
  filter: ExpenseFilter;
  clearAllFilters: () => void;
}

export const useExpenseStore = create<ExpenseStore>((set, get) => ({
  expenses: [],
  isLoading: false,
  isLoadingInitial: true,
  isLoadingFilter: false,
  isLoadingMore: false,
  error: null,
  searchText: "",
  range: "",
  filter: {
    paymentMethod: "",
    billable: null,
  },
  pagination: {
    cursor: null,
    backCursor: null,
    hasMoreData: false,
    limit: undefined,
    count: undefined,
  },
  fetchData: async (options?: FetchOptions) => {
    const { startDate, endDate, actionType = "initial" } = options || {};
    if (actionType === "initial") {
      set({ isLoadingInitial: true });
    } else if (actionType === "filter") {
      set({ isLoadingFilter: true });
    }

    const { searchText, filter, range } = get();
    try {
      let query = `/expenses`;
      if (startDate && endDate) {
        query += `?startDate=${startDate}&endDate=${endDate}`;
      }
      if (
        range.length > 0 ||
        searchText.length > 0 ||
        ("paymentMethod" in filter && filter.paymentMethod.length > 0) ||
        ("billable" in filter && filter.billable !== null)
      ) {
        query += `?`;
        if (searchText.length > 0) query += `search=${searchText}&`;
        if (range.length > 0) query += `range=${range.toLowerCase()}&`;
        if ("paymentMethod" in filter && filter.paymentMethod.length > 0)
          query += `paymentMethod=${filter.paymentMethod.toLowerCase()}&`;
        if ("billable" in filter && filter.billable !== null)
          query += `isBillable=${filter.billable}&`;
      }
      const res = await expenseService.get(query);
      set({
        expenses: res.data.data,
        pagination: res.data.meta,
        isLoadingInitial: false,
        isLoadingFilter: false,
      });
      query = `/expenses`;
    } catch (e: any) {
      set({
        isLoadingInitial: false,
        isLoadingFilter: false,
        error: e.message,
      });
    }
  },
  fetchExpenses: async () => {
    set({ isLoading: true });
    try {
      const res = await expenseService.get(`/expenses`);
      set({
        expenses: res.data.data,
        pagination: res.data.meta,
        isLoading: false,
      });
    } catch (e: any) {
      set({ isLoading: false, error: e.message });
    }
  },
  fetchMoreData: async () => {
    const { filter } = get();

    if (!get().pagination.hasMoreData || get().isLoadingMore) return; // Don't fetch if loading or no more data
    set({ isLoadingMore: true });
    const cursor = get().pagination.cursor;

    try {
      let query = `/expenses?cursor=${cursor}`;
      if (
        ("paymentMethod" in filter && filter.paymentMethod.length > 0) ||
        ("billable" in filter && filter.billable !== null)
      ) {
        if ("paymentMethod" in filter && filter.paymentMethod.length > 0)
          query += `&paymentMethod=${filter.paymentMethod.toLowerCase()}`;
        if ("billable" in filter && filter.billable !== null)
          query += `&isBillable=${filter.billable}`;
      }

      const res = await expenseService.get(query);
      set((state) => ({
        expenses: [...state.expenses, ...res.data.data],
        pagination: res.data.meta,
        isLoadingMore: false,
      }));
    } catch (e: any) {
      set({ isLoadingMore: false, error: e.message });
    }
  },
  setSearchText: (text: string) => {
    set({ searchText: text });
  },
  handleSort: (text: string) => {
    set({ range: text });
  },
  setFilter: (filter: Filter) => {
    set({ filter: filter as ExpenseFilter });
  },
  removeInvoice: (id) => {
    const expenses = get().expenses;
    set({ expenses: expenses.filter((invoice) => invoice.id !== id) });
  },
  addInvoice: (invoice) => {
    set((state) => ({ expenses: [invoice, ...state.expenses] }));
  },
  clearAllFilters: () => {
    set({
      filter: {
        paymentMethod: "",
        billable: null,
      },
    });
  },
}));
