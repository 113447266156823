import React from "react";

interface ViewIconProps {
  isWhite: boolean;
}
const ViewIcon: React.FC<ViewIconProps> = ({ isWhite }) => {
  return (
    <div>
      <svg
        width="20"
        height="14"
        viewBox="0 0 20 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 7C12.5 7.66304 12.2366 8.29893 11.7678 8.76777C11.2989 9.23661 10.663 9.5 10 9.5C9.33696 9.5 8.70107 9.23661 8.23223 8.76777C7.76339 8.29893 7.5 7.66304 7.5 7C7.5 6.33696 7.76339 5.70107 8.23223 5.23223C8.70107 4.76339 9.33696 4.5 10 4.5C10.663 4.5 11.2989 4.76339 11.7678 5.23223C12.2366 5.70107 12.5 6.33696 12.5 7Z"
          stroke="#344054"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.66797 6.99935C3.0013 3.58518 6.11464 1.16602 10.0013 1.16602C13.888 1.16602 17.0013 3.58518 18.3346 6.99935C17.0013 10.4135 13.888 12.8327 10.0013 12.8327C6.11464 12.8327 3.0013 10.4135 1.66797 6.99935Z"
          stroke="#344054"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default ViewIcon;
