import { useCallback, useEffect, useRef, useState } from "react";
import { defaultShortcuts } from "../constants/helpServices";
import {
  retrieveDateRangeToLocalStorage,
  saveDateRangeToLocalStorage,
} from "../utils/saveDateRange";
import { useFetchDashboardStats } from "./useFetchDashboardStats";
import { IProfile } from "../@types";
import { populatedValues } from "../utils/populatedValues";
import { useUserStore } from "../state";
import { pick } from "lodash";
import {
  getMostBookedServices,
  getTopSellingProducts,
} from "../backend-services";

export const useDashboardStats = () => {
  const { user } = useUserStore();
  const calendarRef = useRef(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<string>("Filter by date");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selected, setSelected] = useState<[Date, Date] | null>(null);
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [mostBookedServices, setMostBookedServices] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [productsError, setProductsError] = useState<null | string>(null);
  const [servicesError, setServicesError] = useState<null | string>(null);

  const profileKeys: Array<keyof IProfile> = [
    "address",
    "businessName",
    "city",
    "code",
    "industry",
    "state",
    "phone",
    "lastName",
    "firstName",
  ];
  const brandKey: Array<keyof IProfile> = ["brandColor", "brandLogo"];
  const profile = Object.fromEntries(
    profileKeys.map((key) => [key, key in user ? user[key] : null]),
  );
  const brand = Object.fromEntries(
    brandKey.map((key) => [key, key in user ? user[key] : null]),
  );
  const bankAccount = pick(user, ["bankAccounts"]);
  const bank = bankAccount.bankAccounts!;

  const { percent: profilePercent } = populatedValues(profile);
  const { percent: brandPercent } = populatedValues(brand);
  const bankPercent = bank?.length > 0 ? 100 : 5;
  const allComplete =
    profilePercent === 100 && brandPercent === 100 && bankPercent === 100;
  const businessType = user.businessType;
  const {
    dashboardStats,
    fetchDashboardStats,
    error: dashBoardStatError,
    isLoading: isLoadingDashBoardStat,
  } = useFetchDashboardStats({ startDate, endDate });

  const handleRangeSelect = useCallback(
    async (ranges: [Date, Date]) => {
      setShowCalendar(false);
      setSelected(ranges);
      const [start, end] = ranges;
      let label = "";

      for (const shortcut of defaultShortcuts) {
        const [shortcutStart, shortcutEnd] = shortcut.range();
        if (
          start.getTime() === shortcutStart.getTime() &&
          end.getTime() === shortcutEnd.getTime()
        ) {
          label = shortcut.label;
          break;
        }
      }

      if (label) {
        setSelectedLabel(label);
      } else {
        setSelectedLabel(
          `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`,
        );
      }
      await fetchDashboardStats(start, end);
    },
    [fetchDashboardStats],
  );

  const handleApplyButtonClick = useCallback(
    async (start: Date, end: Date | null) => {
      if (!end) {
        end = new Date(start);
        end.setHours(23, 59, 59, 999);
      }

      setStartDate(start);
      setEndDate(end);
      saveDateRangeToLocalStorage(start, end);
      await handleRangeSelect([start, end]);
      setShowCalendar(false);
    },
    [handleRangeSelect],
  );

  useEffect(() => {
    if (!showCalendar) {
      setSelected(null);
    }
  }, [showCalendar]);

  useEffect(() => {
    const today = new Date();
    setStartDate(today);
    setEndDate(today);
    setSelectedLabel("Filter by Date");
  }, []);

  const fetchProducts = async () => {
    setLoadingProducts(true);
    try {
      const data = await getTopSellingProducts();
      setTopSellingProducts(data.data);
    } catch (error: any) {
      setProductsError(error.message || "Failed to fetch top-selling products");
    } finally {
      setLoadingProducts(false);
    }
  };

  const fetchServices = async () => {
    setLoadingServices(true);
    try {
      const data = await getMostBookedServices();
      setMostBookedServices(data.data);
    } catch (error: any) {
      setServicesError(error.message || "Failed to fetch most-booked services");
    } finally {
      setLoadingServices(false);
    }
  };

  return {
    showCalendar,
    profilePercent,
    allComplete,
    brandPercent,
    bankPercent,
    isLoadingDashBoardStat,
    selected,
    selectedLabel,
    startDate,
    endDate,
    dashBoardStatError,
    dashboardStats,
    setStartDate,
    setEndDate,
    setShowCalendar,
    handleRangeSelect,
    handleApplyButtonClick,
    fetchDashboardStats,
    calendarRef,
    businessType,
    fetchProducts,
    fetchServices,
    topSellingProducts,
    mostBookedServices,
    loadingProducts,
    loadingServices,
    productsError,
    servicesError,
  };
};
