import React, { useState } from "react";
import {
  DropdownComponent,
  InputComponent,
} from "../../../components/core/Forms";
import { useReportStore } from "../../../state/useReportStore";
import { Button, SearchInput } from "../../../components/common";
import { CloseIconSmall, SearchIcon } from "../../../assets/icons";
import { useVendorSearch } from "../../../hooks";
import { IVendor } from "../../../@types";
import { EXPENSETYPE_LIST, PAYMENT_PROVIDER_LIST } from "../../../constants";
import { useTranslation } from "react-i18next";

interface ExpenseFilterProps {
  handleOnChange: (e: any) => void;
  formData: any;
  setFormData: any;
}

const ExpenseReportFilterForm = ({
  handleOnChange,
  formData,
  setFormData,
}: ExpenseFilterProps) => {
  const { t } = useTranslation("report");
  const [paymentValue, setPaymentValue] = useState(t("report.selectPayment"));
  const [expenseValue, setExpenseValue] = useState("All");
  const [vendorState, setVendorState] = useState<IVendor | null>(null);
  const { data: vendors, handleSearch, isSearching } = useVendorSearch();
  const dropDownOptions = PAYMENT_PROVIDER_LIST;
  const dropDownOptions2 = EXPENSETYPE_LIST;

  const handleDropDownChange = (name: string, value: string) => {
    const selectedStatus = dropDownOptions.find(
      (option) => option.value === value,
    );
    setPaymentValue(selectedStatus?.label as string);
    setFormData({ ...formData, [name]: value });
  };

  const handleDropDownChange2 = (name: string, value: string) => {
    const selectedStatus = dropDownOptions2.find(
      (option) => option.value === value,
    );
    setExpenseValue(selectedStatus?.label as string);
    setFormData({ ...formData, [name]: value });
  };

  const handleVendorSelect = (selectedVendor: IVendor | null) => {
    if (selectedVendor) {
      setVendorState(selectedVendor);
      if (selectedVendor.id) {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          vendorId: selectedVendor.id,
        }));
      }
    }
  };

  return (
    <div className="mt-[1em]">
      <div className="w-[100%] xsmd:w-[576px] xl:w-[434px]">
        <label>Generate by Vendor</label>
        <SearchInput
          placeholder={"Search a vendor"}
          displayKey={"name"}
          isLoading={isSearching}
          value={formData.vendor}
          handleChangeText={handleSearch}
          clearIcon={CloseIconSmall}
          onSelect={handleVendorSelect}
          icon={<SearchIcon />}
          data={vendors.map((ven) => ({
            ...ven,
            display: `${ven.id}`,
          }))}
          emptyMessage={"This Item is not found"}
          dropdownClassName="w-[100%] xsmd:w-[576px] xl:w-[434px] xl:ml-[-0em] biglg:ml-[-0.1em]"
          clearIconClass="xl:mr-[1em] mr-[0.47em] md:mr-[3.2em] biglg:mr-[0.1em] biglg:mb-[4em]"
          dropdownStyle={{
            maxHeight: "800px",
            overflowY: "auto",
          }}
          searchKey={"name"}
          classNames="w-[100%] xsmd:w-[576px] xl:w-[434px] border border-blue-500 bg-white max-h-[48px] min-h-[48px] placeholder:text-[15.25px]  rounded p-2  focus:outline-none focus:shadow-outline appearance-none focus:ring-0 leading-normal"
        />
      </div>
      <div className="flex flex-col md:flex-row w-[1/2] lg:w-[434px] justify-between gap-[16px] md:gap-[16px] pt-[20px] xl:pt-[33px]">
        <div className="md:w-[276px] lg:w-[209px]">
          <label className="tex whitespace-nowrap">
            {t("report.generateByExpenseType")}
          </label>
          <DropdownComponent
            name="expenseType"
            options={dropDownOptions2}
            value={formData.expenseType}
            selectedValue={expenseValue}
            setSelectedValue={setExpenseValue}
            onChange={(name, value) => handleDropDownChange2(name, value)}
            dropdownClassName="w-[100%] md:w-[276px] lg:w-[209px] cursor-pointer"
            iconClassName="mr-[2em] sm:mr-[7em] md:mr-[1.5em]"
          />
        </div>

        <div className="md:w-[276px] lg:w-[209px]">
          <label className="tex whitespace-nowrap">
            {t("report.generateByPaymentMethod")}
          </label>
          <DropdownComponent
            name="paymentMethod"
            options={dropDownOptions}
            value={formData.paymentMethod}
            selectedValue={paymentValue}
            setSelectedValue={setPaymentValue}
            onChange={(name, value) => handleDropDownChange(name, value)}
            dropdownClassName="w-[100%] md:w-[276px] lg:w-[209px] cursor-pointer"
            iconClassName="mr-[2em] sm:mr-[7em] md:mr-[1.5em]"
          />
        </div>
      </div>
    </div>
  );
};

export default ExpenseReportFilterForm;
