import { profileService } from "../services";
import { BankData } from "../types";
import { handleError, withRetry } from "../utils";

export const createBankAccount = async (data: BankData) => {
  return withRetry(async () => {
    const res = await profileService.post("/companies/accounts", data);
    return res.data;
  }).catch(handleError);
};

export const getBankAccounts = async () => {
  return withRetry(async () => {
    const res = await profileService.get("/company/bank-accounts");
    return res.data;
  }).catch(handleError);
};

export const editBankAccount = async (
  accountIndex: number,
  data: Partial<BankData>,
) => {
  return withRetry(async () => {
    const res = await profileService.patch(
      `/company/bank-account/${accountIndex}`,
      data,
    );
    return res.data;
  }).catch(handleError);
};

export const deleteBankAccount = async (accountIndex: number) => {
  return withRetry(async () => {
    const res = await profileService.delete(
      `/company/bank-account/${accountIndex}`,
    );
    return res.data;
  }).catch(handleError);
};

export const validateBankAccount = async (accountIndex: number) => {
  try {
    const res = await profileService.patch(
      `/company/validate-account/${accountIndex}`,
    );
    return res.data;
  } catch (e: any) {
    throw new Error(e.response?.data || e.message);
  }
};

export const updatePayoutAccount = async (
  accountIndex: number,
  isPayoutAccount: boolean,
) => {
  return withRetry(async () => {
    const res = await profileService.patch(
      `/company/set-payout/${accountIndex}`,
      { isPayoutAccount },
    );
    return res.data;
  }).catch(handleError);
};
