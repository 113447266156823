// The type for a single customer data
import { useSearch } from "../useSearch";
import { customerService, userService } from "../../services";
import { ICustomer } from "../../@types";
import { UserDetail } from "../../types";

export const useUserSearch = () => {
  return useSearch({
    service: userService,
    endpoint: "users/search",
    queryParameter: "q",
    responseMapper: (data: any[]): any =>
      data.map((cu: any) => ({
        ...cu,
        id: cu.id,
        name: `${cu.firstName} ${cu.lastName}`,
      })) as unknown as UserDetail,
  });
};
