import React, { useState } from "react";
import PayWithFlutterwave from "./Payments/PayWithFlutterwave";
import PayWithPaystack from "./Payments/PayWithPaystack";
import config from "../../config";
import { CloseIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";

interface PaymentProviderModalProps {
  companyId?: string;
  subscriptionId: number;
  provider?: "paystack" | "flutterwave";
  price?: number | undefined;
  email?: string;
  onClose?: () => void;
  plan: string;
  close: () => void;
}

const SubscriptionModal = ({
  companyId,
  //@ts-ignore
  provider = "paystack" || "flutterwave",
  price,
  email,
  plan,
  close,
  subscriptionId,
}: PaymentProviderModalProps) => {
  const { t } = useTranslation("subscription");
  const paystackConfig = {
    reference: new Date().getTime().toString(),
    email: email as string,
    publicKey: config.PayStackPublicKey as string,
    amount: (price as unknown as number) * 100,
    metadata: {
      companyId,
      subscriptionId,
    } as any,
  };

  return (
    <div className="bg-gray-600 bg-opacity-50 inset-0 fixed flex justify-center items-center">
      <div className="bg-white px-[24px] py-[24px] text-center">
        <div className="flex  flex-row gap-[67px] pl-[32px] pr-[27px] items-center">
          <span>{t("subscription.method")}</span>
          <button className="w-[24px] h-[24px]" onClick={() => close()}>
            <CloseIcon />
          </button>
        </div>

        <div className="flex flex-col space-y-16 mt-[39px]">
          {provider === "paystack" && (
            <PayWithPaystack
              close={close}
              id={subscriptionId}
              plan={plan}
              config={paystackConfig}
            />
          )}

          <PayWithFlutterwave />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionModal;
