import React, { useEffect, useState } from "react";
import SubscriptionBox from "../../components/Subscription/SubscriptionBox";
import ExpandLeft from "../../assets/icons/ExpandWhiteLeft";
import HeroImage from "../../assets/icons/svgicons/SubscriptionHeroImage.svg";
import SubscriptionModal from "../../components/Subscription/SubscriptionModal";
import { subscriptions, useSubscriptionStore, useUserStore } from "../../state";
import { createSubscriptionPaymentLink } from "../../backend-services/subscription";
import { useNavigate, Link } from "react-router-dom";
import sales from "../../components/Images/Ellipse1437.webp";
import { useTranslation } from "react-i18next";

const SubscriptionPage: React.FC = () => {
  const { t } = useTranslation("subscription");
  const [price, setPrice] = useState<number>(0);
  const [email, setEmail] = useState<string>("");
  const [companyId, setCompanyId] = useState<string>("");
  const [subscriptionId, setSubscriptionId] = useState<number>(0);
  const [plan, setPlan] = useState<string>("");
  const [showSubscriptionModal, setShowSubscriptionModal] =
    useState<boolean>(false);
  const [isChoosing, setIsChoosing] = useState<boolean>(false);
  const [indexState, setIndexState] = useState<number | null>(null);
  const { subscriptionData } = useSubscriptionStore();
  const navigate = useNavigate();
  const { user } = useUserStore((state) => ({ user: state.user }));
  const handleHideModal = () => {
    setShowSubscriptionModal(false);
  };

  const { subscriptionList, fetchListSubscription, isLoading } =
    useSubscriptionStore((state) => ({
      subscriptionList: state.subscriptionList,
      subscription: state.subscription,
      fetchListSubscription: state.fetchListSubscription,
      isLoading: state.isLoading,
    }));

  useEffect(() => {
    fetchListSubscription();
    setEmail(user.email);
    setCompanyId(user.id);
  }, [fetchListSubscription, user]);

  const callbackUrl = `${window.location.href}/processing`;

  const handlePaymentSucess = async (plan: string) => {
    try {
      const data = await createSubscriptionPaymentLink({
        paymentProvider: "paystack",
        plan: plan,
        callback_url: callbackUrl,
      });
      const { authorization_url } = data;
      window.location = authorization_url;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return (
    <div
      className="relative lg:bg-contain bg-no-repeat bg-[#F2F9FD] pb-[95px]"
      style={{ backgroundImage: `url(${HeroImage})` }}
    >
      <div className="flex flex-row pl-[20px] md:pl-[115px] pt-[64px] text-[#fff]">
        <span className="">
          <ExpandLeft />
        </span>
        <Link to="/dashboard">
          <span className="">Go Back</span>
        </Link>
      </div>

      <div className="text-[#ffffff] text-center md:mt-[78px]">
        <h1 className="lg:text-[24px] text-[24px]  md:text-[30px] leading-9 font-bold">
          {t("subscription.improveBusiness")}
        </h1>
        <h2 className="lg:text-[20px] text-[16px] leading-6 mt-3 ">
          {t("subscription.choosePlan")}
        </h2>
      </div>
      <div className="lg:mt-[92px] w-full mt-5">
        <SubscriptionBox
          show={handlePaymentSucess}
          isChoosing={isChoosing}
          indexState={indexState}
        />
      </div>

      <div className="px-[5.6%] px- md:py-[57.5px] bg-white flex justify-between items-center mt-[99px] shadow">
        <div className=" flex items-center gap-3 md:gap-6">
          <img
            src={sales}
            alt="sales"
            className="w-[80px] h-[80px] rounded-full"
          />
          <p className=" text-[14px] md:text-[18px] lg:text-[24px] font-semibold lg:leading-[28.8px]">
            Want an{" "}
            <span className="text-[#044E97] font-bold">Unlimited plan</span>{" "}
            that allows you to do more?
          </p>
        </div>
        <a
          href="mailto:sales@esemie.io"
          className="w-[150px] md:w-[193px] h-12 md:h-16 rounded-[8px] flex justify-center items-center text-center bg-[#FF6702] text-white text-[14px] md:text-[18px] font-bold"
        >
          Contact Sales
        </a>
      </div>

      {showSubscriptionModal && (
        <SubscriptionModal
          price={price}
          email={email}
          companyId={companyId}
          plan={plan}
          subscriptionId={subscriptionId}
          close={handleHideModal}
        />
      )}
    </div>
  );
};

export default SubscriptionPage;
