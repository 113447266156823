import React from "react";

const AttachIcon = () => {
  return (
    <>
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3328 1.41602C12.1602 1.41582 12.9696 1.65712 13.6618 2.11032C14.354 2.56352 14.8989 3.20891 15.2296 3.96732C15.5603 4.72573 15.6624 5.56417 15.5235 6.37978C15.3845 7.1954 15.0105 7.95272 14.4474 8.55885L14.3128 8.69627L8.13116 14.8779L8.09362 14.9133L8.05395 14.9452C7.55039 15.3793 6.90136 15.6071 6.23695 15.5828C5.57254 15.5586 4.94182 15.2842 4.47121 14.8146C4.00059 14.3449 3.72485 13.7148 3.69923 13.0504C3.67361 12.3861 3.90002 11.7366 4.33308 11.2321L4.43862 11.1159L4.44924 11.1081L9.61299 5.93447C9.74572 5.80147 9.92584 5.72663 10.1137 5.72643C10.3016 5.72624 10.4819 5.80069 10.6149 5.93341C10.7479 6.06614 10.8228 6.24626 10.823 6.43416C10.8232 6.62206 10.7487 6.80234 10.616 6.93535L5.45224 12.109L5.44374 12.1147C5.23533 12.3372 5.11788 12.6298 5.11458 12.9347C5.11128 13.2396 5.22236 13.5347 5.42591 13.7617C5.62946 13.9887 5.9107 14.1312 6.21414 14.1611C6.51759 14.191 6.82121 14.106 7.06512 13.9231L7.15153 13.8522L7.15224 13.8529L13.3162 7.69043L13.4288 7.57356C13.9247 7.02847 14.1887 6.31161 14.1649 5.57507C14.141 4.83853 13.8311 4.14027 13.3009 3.62844C12.7707 3.11661 12.062 2.83147 11.3251 2.83353C10.5882 2.83559 9.88105 3.12468 9.35374 3.63947L9.24395 3.75281L9.2312 3.76131L2.62528 10.3701C2.55947 10.4358 2.48135 10.488 2.39538 10.5236C2.30941 10.5591 2.21728 10.5774 2.12424 10.5774C2.0312 10.5774 1.93908 10.559 1.85314 10.5234C1.76719 10.4877 1.68911 10.4355 1.62335 10.3697C1.55758 10.3039 1.50542 10.2258 1.46985 10.1398C1.43428 10.0538 1.41598 9.9617 1.41602 9.86866C1.41608 9.68076 1.49079 9.50058 1.6237 9.36777L8.21758 2.77106L8.25016 2.7406C8.64729 2.32209 9.12547 1.98881 9.65557 1.76109C10.1857 1.53336 10.7559 1.41595 11.3328 1.41602Z"
          fill="#044E97"
        />
      </svg>
    </>
  );
};

export default AttachIcon;
