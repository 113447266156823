import React, { useState } from "react";
import { ICreateExpenseData } from "../../../@types";
import { Tooltip } from "../../../assets/icons/Tooltip";
import { EXPENSE_CATEGORY, PAYMENT_PROVIDER_LIST } from "../../../constants";
import { DropdownComponent } from "../../core/Forms";
import { useTranslation } from "react-i18next";

interface ExpenseDetailProps {
  formData: ICreateExpenseData;
  validationResults: ICreateExpenseData;
  handleDropDownChange: (name: string, value: string) => void;
  setValidationResults: React.Dispatch<
    React.SetStateAction<ICreateExpenseData>
  >;
  toolTipText?: string;
  toolTipText2?: string;
}

const ExpenseCategory: React.FC<ExpenseDetailProps> = ({
  handleDropDownChange,
  validationResults,
  formData,
  setValidationResults,
  toolTipText,
  toolTipText2,
}) => {
  const { t } = useTranslation("expenses");
  const dropDownCategory = EXPENSE_CATEGORY;
  const dropDownPaymentMethod = PAYMENT_PROVIDER_LIST;

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

  return (
    <>
      {/* Desktop */}
      <div className="hidden lg:block md:block xl:mt-[em]">
        <div className="mt-1 flex justify-end items-end gap-4 text-right xl:mr-[2.5em] lg:mr-[2em] md:mr-[2em] biglg:mr-[2.5em] bigmd:mr-[2em]">
          <div className="mt-[2em]">
            <div className="flex items-center gap-2">
              <label
                htmlFor="customer"
                className="text-[12px] md:text-[14px] cursor-pointer"
                style={{ whiteSpace: "nowrap" }}
              >
                {t("budget.paymentMethod")}
              </label>
              {toolTipText && (
                <div
                  className="relative"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Tooltip />
                  {isHovered && (
                    <div className="absolute w-[204px] left-[-5.7em] z-10">
                      <div className="flex items-center justify-center ">
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 0L17.6603 15H0.339746L9 0Z"
                            fill="#FFF4C6"
                          />
                        </svg>
                      </div>
                      <div className=" bg-[#FFF4C6] text-left pr-[1em] pl-[1em] pt-[1em] pb-[1em] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                        {toolTipText}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="lg:w-[170px] relative cursor-pointer mt-1">
              <DropdownComponent
                name={"paymentMethod"}
                options={dropDownPaymentMethod}
                placeholder="Payment Method"
                selectedValue={formData.paymentMethod}
                onChange={handleDropDownChange}
                dropdownClassName="lg:w-[170px] md:w-[150px] rounded placeholder:text-[10px] text-[16px]  cursor-pointer "
                iconClassName="mr-3 xl:mt-[-0.2em] biglg:mt-[-0.2em]"
              />
              {validationResults.paymentMethod && (
                <span
                  className=" absolute text-red-500 text-[11px] lg:mr-[5em] "
                  style={{
                    top: "100%",
                    left: "0",
                    whiteSpace: "nowrap",
                    lineHeight: "1.2",
                  }}
                >
                  {validationResults.paymentMethod}
                </span>
              )}
            </div>
          </div>
          <div className="">
            <div className="flex items-center gap-2">
              <label
                htmlFor="customer"
                className="text-[12px] lg:text-[14px] cursor-pointer"
                style={{ whiteSpace: "nowrap" }}
              >
                {t("budget.category")}
              </label>
              {toolTipText2 && (
                <div
                  className="relative"
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
                >
                  <Tooltip />
                  {isHovered2 && (
                    <div className="absolute w-[204px] left-[-5.7em] z-10">
                      <div className="flex items-center justify-center">
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 0L17.6603 15H0.339746L9 0Z"
                            fill="#FFF4C6"
                          />
                        </svg>
                      </div>
                      <div className="pr-[1em] text-left pl-[1em] pt-[1em] pb-[1em] bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                        {toolTipText2}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className=" relative cursor-pointer mt-1">
              <DropdownComponent
                name="category"
                options={dropDownCategory}
                value={formData.category}
                placeholder="Category"
                selectedValue={formData.category}
                onChange={handleDropDownChange}
                dropdownClassName="lg:w-[144px] md:w-[150px]  placeholder:text-[10px] text-[14px]  cursor-pointer"
                iconClassName="mr-4 xl:mt-[-0.1em] biglg:mt-[-0.2em]"
              />

              {validationResults.category && (
                <span
                  className=" absolute text-red-500 text-[11px] lg:mr-[5em]"
                  style={{
                    top: "100%",
                    left: "0",
                    whiteSpace: "nowrap",
                    lineHeight: "1.2",
                  }}
                >
                  {validationResults.category}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Mobile */}
      <div className="lg:hidden md:hidden pl-[1em]">
        <div className="mt-1 flex justify-between  gap-4 text-left pr-3">
          <div>
            <label
              htmlFor="customer"
              className=" inline-flex text-[12px] lg:text-[14px] mb-2 lg:mr-[4em]  cursor-pointer"
              style={{ whiteSpace: "nowrap" }}
            >
              Payment Method
            </label>
            <div className="w-[118px]">
              <DropdownComponent
                name={"paymentMethod"}
                options={dropDownPaymentMethod}
                placeholder="Select"
                selectedValue={formData.paymentMethod}
                onChange={handleDropDownChange}
                dropdownClassName="w-[118px]  rounded placeholder:text-[10px] text-[14px]  cursor-pointer "
                iconClassName="mr-3 mt-[-0.5em]"
              />
              <div className="xl:mt-[em]">
                {validationResults.paymentMethod && (
                  <span
                    className="text-red-500"
                    style={{
                      whiteSpace: "nowrap",
                      padding: "0",
                      lineHeight: "1.2",
                    }}
                  >
                    {validationResults.paymentMethod}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="mt-1.5">
            <label
              htmlFor="customer"
              className=" inline-flex gap-[em] text-[12px] md:w-[118px] ml-[1em]"
              style={{ whiteSpace: "nowrap" }}
            >
              Category
            </label>
            <div className="">
              <DropdownComponent
                name="category"
                options={dropDownCategory}
                value={formData.category}
                placeholder="Category"
                selectedValue={formData.category}
                onChange={handleDropDownChange}
                dropdownClassName="w-[118px]   placeholder:text-[10px] text-[14px] "
                iconClassName="mr-3 mt-[-0.4em]"
              />
              <div className="absolute">
                {validationResults.category && (
                  <span
                    className="text-red-500"
                    style={{
                      whiteSpace: "nowrap",
                      padding: "0",
                      lineHeight: "1.2",
                    }}
                  >
                    {validationResults.category}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpenseCategory;
