import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EmptyIcon } from "../../../assets/icons";
import cx from "classnames";

interface ExpenseUploadSuccessProps {
  closeModal: () => void;
  text: string;
  navigateTo: "expense" | "invoice";
}

const ExpenseUploadSuccess: React.FC<ExpenseUploadSuccessProps> = ({
  closeModal,
  text,
  navigateTo,
}) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    let pathToNavigate = "";
    if (navigateTo === "expense") {
      pathToNavigate = "/expenses";
    } else if (navigateTo === "invoice") {
      pathToNavigate = "/invoices";
    }
    navigate(pathToNavigate);
    closeModal();
  };

  return (
    <>
      <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh] flex items-end md:items-center justify-center">
        <div
          className={cx(
            "overflow-y-auto overflow-x-hidden fixed md:top-[10%] w-full md:w-[500px] lg:w-[538px] p-6 bg-white",
            "rounded-t-[16px] rounded-b-[16px] shadow-md", // Added rounded corners for both top and bottom
          )}
        >
          <div className="text-[22px] font-semibold text-center ">
            <h1>Your upload is being processed</h1>
            <div className="lg:text-[16px] text-[14px] mt-[1em] font-medium">
              <p>
                You’ll be notified if there’s an issue processing your <br />{" "}
                upload. Check your notifications shortly.
              </p>
            </div>
          </div>
          <div className=" flex flex-col gap-10 items-center mt-[32px]">
            <div>
              <EmptyIcon />
            </div>
            <button
              className="w-[215px] h-[48px] rounded-md text-[#FFFFFF] text-[16px] font-medium bg-[#044E97]"
              onClick={handleButtonClick}
            >
              {text}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpenseUploadSuccess;
