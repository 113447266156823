import {
  startOfWeek,
  endOfWeek,
  subWeeks,
  subMonths,
  subDays,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import { Shortcut } from "../@types";

export const SERVICES = [
  { value: "Invoice", label: "Invoice" },
  { value: "Expense", label: "Expense" },
  { value: "Customer", label: "Customer" },
  { value: "Settings", label: "Settings" },
  { value: "SideBar", label: "SideBar" },
];

export const TODAY = {
  date: new Date(),
  label: "Today",
};

export const defaultShortcuts: Shortcut[] = [
  {
    label: "Today",
    range: () => [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    label: "This Week",
    range: () => [
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      endOfWeek(new Date(), { weekStartsOn: 1 }),
    ],
  },
  {
    label: "Last Week",
    range: () => {
      const lastWeek = subWeeks(new Date(), 1);
      return [
        startOfWeek(lastWeek, { weekStartsOn: 1 }),
        endOfWeek(lastWeek, { weekStartsOn: 1 }),
      ];
    },
  },
  {
    label: "Last Month",
    range: () => {
      const today = new Date();
      const firstDayOfLastMonth = startOfMonth(subMonths(today, 1));
      const lastDayOfLastMonth = endOfMonth(subMonths(today, 1));
      return [firstDayOfLastMonth, lastDayOfLastMonth];
    },
  },
  {
    label: "Last 90 Days",
    range: () => [startOfDay(subDays(new Date(), 90)), endOfDay(new Date())],
  },
];
