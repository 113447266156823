import React from "react";

const CashBalanceIcon = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="18"
        height="12"
        rx="2"
        stroke="#0D8DBF"
        stroke-width="2"
      />
      <path
        d="M4 4L6 4"
        stroke="#0D8DBF"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M14 10H16"
        stroke="#0D8DBF"
        stroke-width="2"
        stroke-linecap="round"
      />
      <circle cx="10" cy="7" r="2" stroke="#0D8DBF" stroke-width="2" />
    </svg>
  );
};

export { CashBalanceIcon };
