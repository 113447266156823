import * as React from "react";
import PDF from "./svgicons/pdf.svg";

type PDFIconProps = React.ImgHTMLAttributes<HTMLImageElement>;

const PDFIcon: React.FC<PDFIconProps> = (props) => {
  return <img {...props} src={PDF} alt="pdf icons" />;
};

export { PDFIcon };
