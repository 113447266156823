import { toast } from "react-hot-toast";
import { ICreateSalesData, ISetReminder, PaymentType } from "../@types";
import { invoiceService } from "../services";
import { handleError, withRetry } from "../utils";

const BASE = `invoices`;

export const createInvoice = async (data: ICreateSalesData) => {
  return withRetry(async () => {
    const res = await invoiceService.post(`/${BASE}`, data);
    return res.data;
  }).catch(handleError);
};

export const createReoccurringInvoice = async (data: ICreateSalesData) => {
  return withRetry(async () => {
    const res = await invoiceService.post(`/${BASE}`, data);
    return res.data;
  }).catch(handleError);
};

export const getInvoice = async (id: string) => {
  return withRetry(async () => {
    const res = await invoiceService.get(`/${BASE}/${id}`);
    return res.data;
  }).catch((e: any) => {});
};

export const toggleReoccurringInvoice = async ({
  id,
  isReoccurringEndDate,
  reoccurringGroupName,
}: {
  id: string;
  reoccurringGroupName: string;
  isReoccurringEndDate?: string;
}) => {
  return withRetry(async () => {
    const res = await invoiceService.put(`/invoices/${id}/toggleReoccurring`, {
      reoccurringGroupName,
      isReoccurringEndDate,
    });
    return res.data;
  }).catch(handleError);
};

export const refundInvoice = async ({ id }: { id: string }) => {
  return withRetry(async () => {
    const res = await invoiceService.patch(`/invoices/${id}/refund`);
    return res.data;
  }).catch((e: any) => {
    toast.error(e.response?.data || e.message);
    throw new Error(e.response?.data || e.message);
  });
};

export const cancelInvoice = async ({ id }: { id: string }) => {
  return withRetry(async () => {
    const res = await invoiceService.patch(`/invoices/${id}/cancel`);
    return res.data;
  }).catch((e: any) => {
    toast.error(e.response?.data || e.message);
    throw new Error(e.response?.data || e.message);
  });
};

export const getCustomerInvoices = async ({
  customerId,
}: {
  customerId: string;
}) => {
  return withRetry(async () => {
    const res = await invoiceService.get(`/invoices/customers/${customerId}`);
    return res.data;
  }).catch((e: any) => {
    const errorMessage = "An error occurred while fetching customer invoices.";
    toast.error(errorMessage);
    throw new Error(errorMessage);
  });
};

export const createDraft = async (data: ICreateSalesData) => {
  return withRetry(async () => {
    const res = await invoiceService.post(`/${BASE}`, data);
    return res.data;
  }).catch(handleError);
};

export const updateToPending = async ({
  id,
  data,
}: {
  id: string;
  data: { status: string };
}) => {
  return withRetry(async () => {
    const res = await invoiceService.patch(`/invoices/${id}/save`, data);
    return res.data;
  }).catch(handleError);
};

export const getPaymentReminder = async (id: string) => {
  return withRetry(async () => {
    const res = await invoiceService.get(`/${BASE}/${id}/payment-reminder`);
    return res.data;
  }).catch((e: any) => {
    toast.error(e.response?.data || e.message);
    throw new Error(e.response?.data || e.message);
  });
};

export const getInvoiceDownload = async (id: string) => {
  return withRetry(async () => {
    const res = await invoiceService.get(`/${BASE}/${id}/generate-download`);
    return res.data;
  }).catch((e: any) => {
    const message =
      e.response?.data?.message ||
      "Failed to download the invoice. Please try again later.";
    toast.error(message);
    throw new Error(e.response?.data || e.message);
  });
};

export const getInvoiceReport = async (
  startDate: string,
  endDate: string,
  customerId: number,
  status: string,
  downloadType: "csv" | "pdf",
) => {
  return withRetry(async () => {
    const res = await invoiceService.get(
      `${BASE}/report?startDate=${startDate}&endDate=${endDate}&customerId=${customerId}&status=${status}&downloadType=${downloadType}`,
    );
    return res.data;
  }).catch((e: any) => {
    toast.error(e.response?.data || e.message);
    throw new Error(e.response?.data || e.message);
  });
};

export const recordManualPayment = async (
  id: string,
  input: {
    amount: number;
    paymentType: PaymentType;
    paymentDate: string | Date | number;
    entityId: string;
    customerId: string;
    entityType: string;
    companyId: string;
    outletId: string;
  },
) => {
  const data = {
    ...input,
  };

  return withRetry(async () => {
    const res = await invoiceService.post(
      `/invoices/${id}/record-payment`,
      data,
    );
    return res.data;
  }).catch((e: any) => {
    toast.error(e.response?.data || e.message);
    throw new Error(e.response?.data || e.message);
  });
};

export const createQuote = async (data: ICreateSalesData) => {
  return withRetry(async () => {
    const res = await invoiceService.post("/quotes", data);
    return res.data;
  }).catch(handleError);
};

export const getQuote = async (id: string) => {
  return withRetry(async () => {
    const res = await invoiceService.get(`/quotes/${id}`);
    return res.data;
  }).catch((e: any) => {
    toast.error(e.response?.data || e.message);
    throw new Error(e.response?.data || e.message);
  });
};

export const convertQuote = async ({ id }: { id: string }) => {
  return withRetry(async () => {
    const res = await invoiceService.patch(`/quotes/${id}/convert`);
    return res.data;
  }).catch((e: any) => {
    toast.error(e.response?.data || e.message);
    throw new Error(e.response?.data || e.message);
  });
};

export const updateQuote = async ({ id, data }: { id: string; data: any }) => {
  return withRetry(async () => {
    const res = await invoiceService.patch(`/quotes/${id}/`, data);
    return res.data;
  }).catch(handleError);
};

export const getQuoteDownload = async (id: string) => {
  return withRetry(async () => {
    const res = await invoiceService.get(`/quotes/${id}/generate-download`);
    return res.data;
  }).catch((e: any) => {
    toast.error(e.response?.data || e.message);
    throw new Error(e.response?.data || e.message);
  });
};

export const expireQuote = async ({ id }: { id: string }) => {
  return withRetry(async () => {
    const res = await invoiceService.patch(`/quotes/set-expiry/${id}`);
    return res.data;
  }).catch((e: any) => {
    toast.error(e.response?.data || e.message);
    throw new Error(e.response?.data || e.message);
  });
};

export const sendInvoiceReminder = async (
  id: string,
  medium: { whatsapp: boolean; email: boolean; sms: boolean },
) => {
  return withRetry(async () => {
    if (medium.whatsapp) {
      const res = await invoiceService.patch(`/invoices/${id}/send-reminder`, {
        shouldSendWhatsapp: true,
      });
      return res.data;
    }
    if (medium.email) {
      const res = await invoiceService.patch(`/invoices/${id}/send-reminder`, {
        shouldSendEmail: true,
      });
      return res.data;
    }
    if (medium.sms) {
      const res = await invoiceService.patch(`/invoices/${id}/send-reminder`, {
        shouldSendSMS: true,
      });
      return res.data;
    }
  }).catch((e: any) => {
    throw new Error(e.response?.data || e.message);
  });
};

export const updateIndividualReminders = async (
  id: string,
  data: ISetReminder,
) => {
  return withRetry(async () => {
    const res = await invoiceService.patch(`/invoices/${id}/reminder`, data);
    return res.data;
  }).catch(handleError);
};

// export const updateIndividualReminders  = async (id: string, medium: { whatsapp: boolean; email: boolean; sms: boolean }) => {
//   return withRetry(async () => {
//     if (medium.whatsapp) {
//       const res = await invoiceService.patch(`/invoices/${id}/reminder`, { shouldSendWhatsapp: true });
//       return res.data;
//     }
//     if (medium.email) {
//       const res = await invoiceService.patch(`/invoices/${id}/reminder`, { shouldSendEmail: true });
//       return res.data;
//     }
//     if (medium.sms) {
//       const res = await invoiceService.patch(`/invoices/${id}/reminder`, { shouldSendSMS: true });
//       return res.data;
//     }
//   }).catch((e: any) => {
//     throw new Error(e.response?.data || e.message);
//   });
// };
