import React from "react";
import { SalesStatus } from "../../../../@types/invoice";
import { ServiceStatus } from "../../../../@types/service";
import { ServiceStatuses, Statuses } from "../../../../constants";

export const getStatusClass = (status: SalesStatus) => {
  const normalizedStatus = (status ?? "").toLowerCase();

  switch (normalizedStatus) {
    case "paid":
      return "text-[#039855] cursor-pointer text-[14px] rounded-[20px] w-[45px] h-[30px] pt-1 ";
    case "pending":
      return "text-[#DC6803] cursor-pointer text-[14px] rounded-lg w-[69px] h-[30px] pt-1 ";
    case "draft":
      return "text-[#344054] cursor-pointer text-[14px] rounded-[20px] py-1 h-[30px] px-2 pt-1 ";
    case "due":
      return "text-[#D92D20] cursor-pointer text-[14px] rounded-[20px]  h-[30px] w-[45px] pt-1 ";
    case "cancelled":
      return "text-[#D92D20] cursor-pointer text-[14px] rounded-[20px] w-[85px] h-[30px] pt-1  ";
    case "refunded":
      return "text-[#044E97] cursor-pointer text-[14px] rounded-[20px] h-[30px] pt-1 w-[85px]";
    case "active":
      return "text-[#C89000] cursor-pointer text-[14px] rounded-[20px] py-1 h-[30px] px-2";
    case "expired":
      return "text-[#C800A8] cursor-pointer text-[14px] rounded-[20px] py-1 px-3 h-[30px] px-2";
    case "quote":
      return "text-[#B36100] cursor-pointer text-[14px] rounded-[20px] py-1 h-[30px] px-2";
    default:
      return "";
  }
};

export const getStatusColor = (status: SalesStatus) => {
  const normalizedStatus = (status ?? "").toLowerCase();

  switch (normalizedStatus) {
    case "paid":
      return "#ECFDF3";
    case "pending":
      return "#FFFAEB";
    case "draft":
      return "#F2F4F7";
    case "due":
      return "#FEF3F2";
    case "cancelled":
      return "#FEF3F2";
    case "refunded":
      return "#C9EBF3";
    case "active":
      return "#FFFCB0";
    case "expired":
      return "#FDB0FF";
    case "quote":
      return "#FFC785";
    default:
      return "";
  }
};

export const customTextColors = {
  paid: "#039855",
  pending: "#FF6702",
  draft: "##4E4E4E",
  overdue: "#D92D20",
  cancelled: "#101010",
  refunded: "#044E97",
  expired: "#C800A8",
  active: "#C89000",
  quote: "#B36100",
};

export const customColors = {
  paid: "#D1FADF",
  pending: "#FFFAEB",
  draft: "#F2F4F7",
  overdue: "#FEE4E2",
  cancelled: "#7D7D7D",
  refunded: "#C9EBF3",
  expired: "#FDB0FF",
  active: "#FFFCB0",
  quote: "#FFC785",
};

export const getServiceStatusClass = (status: ServiceStatus) => {
  switch (status) {
    case ServiceStatuses.ACTIVE:
      return "text-[#12B76A] text-[14px] w-fit px-[6px] py-[2px] rounded-[4px] border border-gray-300";
    case ServiceStatuses.PENDING:
      return "text-gray-700 text-[14px] w-fit px-[6px] py-[2px] rounded-[4px] border border-gray-300";
    case ServiceStatuses.INACTIVE:
      return "text-[#4E4E4E] text-[14px] w-fit px-[6px] py-[2px] rounded-[4px] border border-gray-300";
    default:
      return "";
  }
};

export const getServiceStatusColor = (status: ServiceStatus) => {
  switch (status) {
    case ServiceStatuses.ACTIVE:
      return "";
    case ServiceStatuses.PENDING:
      return "#E6C8B3";
    case ServiceStatuses.INACTIVE:
      return "#F2F4F7";
    default:
      return "";
  }
};
