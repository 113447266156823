import React from "react";
import { useTranslation } from "react-i18next";
import useCancel from "../../../hooks/invoice/useCancel";
import ModalButton from "../../common/Modal/ModalButton";
import ModalContent from "../../common/Modal/ModalContent";
import ModalFooter from "../../common/Modal/ModalFooter";
import ModalHeader from "../../common/Modal/ModalHeader";
import Modals from "../../common/Modal/Modals";

interface CancelConfirmationPromptProps {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => Promise<void>;
}

const CancelConfirmationPrompt: React.FC<CancelConfirmationPromptProps> = ({
  isOpen,
  onClose,
  onCancel,
}) => {
  const { t } = useTranslation("invoices");
  const { isProcessing, handleCancel } = useCancel({ onCancel });

  const handleKeepInvoice = () => {
    onClose();
  };

  return (
    <Modals isOpen={isOpen} onClose={onClose}>
      <ModalHeader title={t("cancelInvoice")} onClose={onClose} />
      <ModalContent>
        <p className="text-gray-600 text-base font-medium">
          {t("confirmCancelInvoice")}
          <span className="font-medium"> {t("confirmCancelInvoice2")}</span>
        </p>
      </ModalContent>
      <ModalFooter>
        <div className="flex gap-4">
          <ModalButton variant="secondary" onClick={handleKeepInvoice}>
            {t("Back")}
          </ModalButton>
          <ModalButton
            variant="primary"
            onClick={handleCancel}
            disabled={isProcessing}
          >
            {isProcessing ? t("processing") : "Cancel Invoice"}
          </ModalButton>
        </div>
      </ModalFooter>
    </Modals>
  );
};

export { CancelConfirmationPrompt };
