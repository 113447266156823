import * as React from "react";
import Loading from "./Loading";

export interface InfoCardProps {
  title: string;
  value: string;
  icon: any;
  isLoading: boolean;
  color: "primary" | "active" | "danger" | "secondary" | "warning" | "inactive";
  className?: string; // Adding optional className prop
  styles: string;
}

const InfoCard: React.FC<InfoCardProps> = ({
  title,
  value,
  color,
  icon,
  styles = "",
  isLoading,
  className = "",
}) => {
  const getColor = React.useCallback((): string => {
    switch (color) {
      case "danger":
        return "#FB0303";
      case "active":
        return "#088306";
      case "warning":
        return "#FF6702";
      case "inactive":
        return "#4E4E4E";
      case "secondary":
        return "#3A6EA7";
      case "primary":
        return "#FF6702";
      default:
        return color;
    }
  }, [color]);

  return (
    <div className={`info-card ${className}`}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex relative w-full bg-white rounded-sm">
          <div
            className={`h-[80px] md:h-[88px] gap-3 w-full rounded flex items-center lg:h-[70px] lg:gap-[12px] pl-2 pr-2 lg:pl-5 ${styles}`}
          >
            {/* <div className="bg-[#DBEEF6] w-[45px] h-[45px] flex justify-center items-center rounded-full font-semibold">
              <div>{icon}</div>
            </div> */}
            <div className="flex flex-col items-start gap-1">
              <p className="text-[14px] md:text-[16px] lg:text-[16px] biglg:text-[16px] xl:text-[16px] text-[#828282] font-['Fellix'] font-medium leading-[1.375rem]">
                {title}
              </p>
              <p
                className={` md:text-[19.4px] leading-[1.375rem] font-semibold text-[20px] [${getColor()}]`}
              >
                {value}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { InfoCard };
