import { removeDataProps, RestockTablekData } from "../@types/inventory";

function parseDate(dateString: string): Date {
  const [day, month, year] = dateString.split("/").map(Number);
  return new Date(year, month - 1, day); // Month is zero-based in JavaScript Date objects
}
export function filterDataByDuration(
  data: RestockTablekData[],
  duration: string,
): RestockTablekData[] {
  if (!Array.isArray(data)) {
    throw new Error("Data should be an array");
  }

  const currentDateTime = new Date();
  let startDate: Date;

  switch (duration.toLowerCase()) {
    case "recent":
      startDate = new Date(currentDateTime.getTime() - 1 * 24 * 60 * 60 * 1000);
      break;
    case "lastweek":
      startDate = new Date(currentDateTime.getTime() - 7 * 24 * 60 * 60 * 1000);
      break;
    case "lastmonth":
      startDate = new Date(
        currentDateTime.getTime() - 30 * 24 * 60 * 60 * 1000,
      );
      break;
    case "lastquarter":
      startDate = new Date(
        currentDateTime.getTime() - 90 * 24 * 60 * 60 * 1000,
      );
      break;
    default:
      throw new Error("Invalid duration");
  }

  if (duration === "recent") {
    const newData = data.sort(
      (a, b) => parseDate(b.date).getTime() - parseDate(a.date).getTime(),
    );
    return newData;
  }

  const filteredData = data.filter((entry) => {
    const entryDate = parseDate(entry.date);
    return entryDate >= startDate;
  });

  return filteredData;
}

export function filterDataByRemoval(
  data: removeDataProps[],
  duration: string,
): removeDataProps[] {
  const currentDateTime = new Date();
  let startDate: Date;

  switch (duration.toLowerCase()) {
    case "recent":
      startDate = new Date(currentDateTime.getTime() - 1 * 24 * 60 * 60 * 1000);
      break;
    case "lastweek":
      startDate = new Date(currentDateTime.getTime() - 7 * 24 * 60 * 60 * 1000);
      break;
    case "lastmonth":
      startDate = new Date(
        currentDateTime.getTime() - 30 * 24 * 60 * 60 * 1000,
      );
      break;
    case "lastquarter":
      startDate = new Date(
        currentDateTime.getTime() - 90 * 24 * 60 * 60 * 1000,
      );
      break;
    default:
      throw new Error("Invalid duration");
  }

  if (duration.toLowerCase() === "recent") {
    // Ensure each item in the array has a date property
    const newData = data
      .filter((item) => item.date)
      .sort(
        (a, b) => parseDate(b.date).getTime() - parseDate(a.date).getTime(),
      );
    return newData;
  }

  const filteredData = data.filter((entry) => {
    const entryDate = parseDate(entry.date);
    return entryDate >= startDate;
  });

  return filteredData;
}
