import React, { useState } from "react";
import { Button } from "../common";
import { Alert } from "flowbite-react";
import { VendorT } from "../../types";
import { formatNumber } from "../../utils";
import PhoneInput from "react-phone-number-input";
import { InputComponent } from "../core/Forms";
import { clearFieldError } from "../../utils/handleErrors";
import { validateVendorForm } from "../../modules/vendor/vendor";
import LocationSelector from "../common/Address";
import toast from "react-hot-toast";
import { IVendor } from "../../@types";
import PhoneInputComponent from "../common/PhoneInputComponent";
import { CloseIcon } from "../../assets/icons";

interface CreateFormProps {
  show: boolean;
  onSubmit: any;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClose?: () => void;
  buttontitle: string;
  isLoading: boolean;
  vendor?: VendorT;
  errorMessage?: string;
  setFormData: React.Dispatch<React.SetStateAction<IVendor>>;
}

const CreateVendorForm: React.FC<CreateFormProps> = (props) => {
  const { vendor, onSubmit, isLoading, onClose } = props;
  React.useState("");
  const [, setErrorMessage] = React.useState("");
  const [, setIsOpen] = React.useState(props.show);
  const [value, setValue] = useState("");
  const [state, setState] = React.useState(vendor?.state || "");
  const [city, setCity] = React.useState(vendor?.city || "");
  const [placeholder] = useState("+234 900 000 0000");
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [showAddressFields, setShowAddressFields] = useState(false);

  const [validationResults, setValidationResults] = useState({
    name: "",
  });

  function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(capitalizeFirstLetter(value));
        break;
      case "address":
        setAddress((prevAddress) => value);
        break;
      default:
        break;
    }
    clearFieldError(name, validationResults, setValidationResults);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { hasErrors, errors } = validateVendorForm({ name });
    setValidationResults(errors);

    if (!hasErrors) {
      const newVendor = { name };
      try {
        await onSubmit(newVendor);
      } catch (error) {
        props.errorMessage && setErrorMessage(props.errorMessage);
      }
    }
  };

  const handlePhoneNumberChange = (value: string) => {
    const trimmedValue = value.trim();

    if (trimmedValue.length === 14) {
      const lastChar = trimmedValue.charAt(trimmedValue.length - 1);
      if (lastChar === " ") {
        const formattedNumber = `${trimmedValue.substring(1, trimmedValue.length - 1)}`;
        setValue(formattedNumber);
        setPhone(formattedNumber);
      } else {
        setValue(trimmedValue);
        setPhone(trimmedValue.substring(4));
      }
    } else {
      setValue(trimmedValue);
      setPhone(trimmedValue.substring(4));
    }
  };

  React.useEffect(() => {
    setIsOpen(props.show);
  }, []);

  const handleLocationChange = (
    selectedState: string,
    cities: string | any[],
  ) => {
    setState(selectedState);
    setCity(cities.length > 0 ? cities[0] : "");

    props.setFormData((prevData) => ({
      ...prevData,
      state: selectedState,
      city: cities.length > 0 ? cities[0] : "",
    }));
  };

  const handlePhoneNumberKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.currentTarget.value.length >= 17) {
      event.preventDefault();
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowAddressFields(e.target.checked);
  };

  return (
    <div className=" xl:ml-[-1em] flex flex-col justify-start pl-[1em]">
      {" "}
      <form className="" action="" onSubmit={handleSubmit}>
        {props.errorMessage ? (
          <Alert className="text-center" color="failure">
            {props.errorMessage}
          </Alert>
        ) : null}

        <div className="hidden md-block lg:block">
          <div className="flex items-center justify-between">
            <h3 className="text-[24px] font-medium">{"Add New Vendor"}</h3>

            <button
              aria-label="Close"
              className="flex items-center justify-center bg-[#F4F7FF] h-11 w-11 pr-[1em]"
              type="button"
              onClick={onClose}
            >
              <CloseIcon color="#212121" />
            </button>
          </div>
          {/* line 1 */}

          <div className="col-span-1 mt-[1em]">
            <label className="font-normal text-[#000000] flex justify-between text-[14px]">
              Vendor Name
              <span className="text-red-500 ">**Required</span>
            </label>
            <InputComponent
              name="name"
              type="text"
              className="block w-full border border-gray-300 rounded-md shadow-sm "
              placeholder=" Name"
              value={name}
              handleChange={handleInputChange}
              error={validationResults.name}
            />
          </div>

          <div className="mt-[1em]">
            <label className="text-[14px] text-[#000000]"> Phone Number</label>
            <PhoneInputComponent
              onPhoneNumberChange={handlePhoneNumberChange}
              initialPhoneNumber={phone}
              containerClass="customer"
              inputClass="customer"
            />
          </div>
          <div>
            <div className="mt-[16px]">
              <label
                htmlFor="Address"
                className="block text-[13px] text-black text-left ml-[-0.1em]"
              >
                Address
              </label>
              <InputComponent
                placeholder="Billing Address"
                name="address"
                type="text"
                value={address}
                handleChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm "
              />
            </div>
            <div className="mt-[1em] text-[16px]">
              <LocationSelector
                onLocationChange={handleLocationChange}
                onError={(error: any) => {}}
                selectedState={state}
                selectedCity={city}
              />
            </div>
          </div>
          {/* )} */}
          <div className="mt-[32px] flex gap-6 w-full">
            <Button
              outline={true}
              variant="secondary"
              disabled={false}
              text={"Cancel"}
              size="full"
              onClick={onClose}
            />
            <Button
              isLoading={isLoading}
              text={props.buttontitle}
              size="full"
              variant="primary"
              onClick={onSubmit}
            />
          </div>
        </div>
        <div className="lg:hidden md:hidden">
          <div id="input-container" className=" xl:w-[50%] xl:px-[5px] m-[]   ">
            <label
              htmlFor=" First Name"
              className="block text-[13px] text-black text-left"
              style={{ whiteSpace: "nowrap" }}
            >
              Vendor Name
              <span className="text-red-500 ml-[13em] md:ml-[20.7em] xl:ml-[20.5em] biglg:ml-[19.4em] lg:ml-[21em] 2xl:ml-[21.9em]">
                **Required
              </span>
            </label>
            <InputComponent
              placeholder=" Name"
              name="name"
              type="text"
              value={name}
              handleChange={handleInputChange}
              error={validationResults.name}
              className="rounded-[5px] xl:w-[414px] lg:w-[400px] biglg:w-[400px] w-[320px] md:w-[420px] "
            />
          </div>
          <div className="mt-[16px]  lg:mt-[37px]">
            <label
              htmlFor="Phone Number"
              className="block font-sm font-weight-[400] text-[13px] text-black text-left "
              style={{ whiteSpace: "nowrap" }}
            >
              Phone Number
              <span className="text-red-500 ml-[12.7em] md:ml-[20.3em] xl:ml-[22.1em] biglg:ml-[21.5em] lg:ml-[20em] "></span>
            </label>
            <PhoneInputComponent
              onPhoneNumberChange={handlePhoneNumberChange}
              initialPhoneNumber={phone}
              containerClass="customer"
              inputClass="customer"
            />
          </div>

          <div className="flex items-center mt-[2em]">
            <input
              type="checkbox"
              id="toggleAddressFields"
              checked={showAddressFields}
              onChange={handleCheckboxChange}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="link-checkbox"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Click here to fill in more details about vendor .
            </label>
          </div>
          {showAddressFields && (
            <div>
              <div className=" mt-[16px] ml-[0.2em] lg:mt-[37px]">
                <label
                  htmlFor="Address"
                  className="block text-[13px] text-black text-left ml-[-0.1em]"
                >
                  Address
                </label>
                <InputComponent
                  placeholder="Billing Address"
                  name="address"
                  type="text"
                  value={address}
                  handleChange={handleInputChange}
                  className="rounded-[5px] xl:w-[414px] lg:w-[400px] w-[320px] md:w-[420px] xl:mt-[-0.1em] lg:ml-[-0.6em] xl:ml-[-0.3em] biglg:ml-[-0.2em] biglg:w-[400px] "
                />
              </div>
              <div className="w-[320px] mt-[1.3em] lg:w-[400px]  md:w-[420px] xl:w-[414px] lg:mt-[37px] biglg:w-[400px] xl:mt-[2em]  ">
                <LocationSelector
                  onLocationChange={handleLocationChange}
                  onError={(error: any) => {}}
                  selectedState={state}
                  selectedCity={city}
                />
              </div>
            </div>
          )}
          <div className="mt-[32px]  flex justify-between xl:mt-[1.5em] gap-9 lg:hidden md:hidden">
            <div className="mt-[32px] flex gap-6 w-full">
              <Button
                outline={true}
                variant="secondary"
                disabled={false}
                text={"Cancel"}
                size="full"
                onClick={onClose}
              />
              <Button
                isLoading={isLoading}
                text={props.buttontitle}
                size="full"
                variant="primary"
                onClick={onSubmit}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateVendorForm;
