import React from "react";

const EmptyLogo = () => {
  return (
    <div>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.96682 43.3327C3.74016 43.3327 2.71616 42.922 1.89482 42.1007C1.07171 41.2776 0.660156 40.2527 0.660156 39.026V4.97268C0.660156 3.74601 1.07171 2.72202 1.89482 1.90068C2.71616 1.07757 3.74016 0.666016 4.96682 0.666016H39.0202C40.2468 0.666016 41.2708 1.07757 42.0922 1.90068C42.9153 2.72202 43.3268 3.74601 43.3268 4.97268V39.026C43.3268 40.2527 42.9162 41.2767 42.0948 42.098C41.2717 42.9211 40.2468 43.3327 39.0202 43.3327H4.96682ZM4.96682 40.666H39.0202C39.429 40.666 39.805 40.4953 40.1482 40.154C40.4895 39.8109 40.6602 39.4349 40.6602 39.026V4.97268C40.6602 4.56379 40.4895 4.18779 40.1482 3.84468C39.805 3.50335 39.429 3.33268 39.0202 3.33268H4.96682C4.55793 3.33268 4.18193 3.50335 3.83882 3.84468C3.49749 4.18779 3.32682 4.56379 3.32682 4.97268V39.026C3.32682 39.4349 3.49749 39.8109 3.83882 40.154C4.18193 40.4953 4.55793 40.666 4.96682 40.666ZM12.1482 33.9993H32.2495C32.6797 33.9993 32.9944 33.8065 33.1935 33.4207C33.3926 33.0331 33.3659 32.6509 33.1135 32.274L27.7268 25.0393C27.4957 24.7531 27.2086 24.61 26.8655 24.61C26.5224 24.61 26.2353 24.7531 26.0042 25.0393L19.8922 32.7673L16.0868 28.1673C15.8539 27.9149 15.5748 27.7887 15.2495 27.7887C14.9242 27.7887 14.6459 27.9327 14.4148 28.2207L11.3375 32.274C11.0495 32.6509 11.005 33.0331 11.2042 33.4207C11.4015 33.8065 11.7162 33.9993 12.1482 33.9993ZM12.6602 15.3327C13.3944 15.3327 14.0228 15.0722 14.5455 14.5513C15.0664 14.0287 15.3268 13.4002 15.3268 12.666C15.3268 11.9318 15.0664 11.3033 14.5455 10.7807C14.0228 10.2598 13.3944 9.99935 12.6602 9.99935C11.9259 9.99935 11.2975 10.2598 10.7748 10.7807C10.2539 11.3033 9.99349 11.9318 9.99349 12.666C9.99349 13.4002 10.2539 14.0287 10.7748 14.5513C11.2975 15.0722 11.9259 15.3327 12.6602 15.3327Z"
          fill="#828282"
        />
      </svg>
    </div>
  );
};

export default EmptyLogo;
