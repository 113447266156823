import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { IPayment } from "../../@types";
import { getPaymentHistory } from "../../backend-services";

const usePaymentHistory = (reloadSignal?: number) => {
  const [paymentHistory, setPaymentHistory] = useState<IPayment[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const params = useParams<{ invoiceNo: string }>();

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      if (params && params.invoiceNo) {
        setLoading(true);
        try {
          const data = await getPaymentHistory(params.invoiceNo);
          setPaymentHistory(data);
          setError("");
        } catch (err: any) {
          setError("Error fetching payment history. Please try again later.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPaymentHistory();
  }, [params, reloadSignal]);

  return { paymentHistory, isLoading, error };
};

export default usePaymentHistory;
