import React from "react";
import { CustomerIcon } from "../../../assets/icons";
import PhoneIcon from "../../../assets/icons/phoneIcon";

interface CustomerItemProps {
  phone: string;

  firstName: string;
  lastName: string;
}

export const CustomerItemComponent: React.FC<CustomerItemProps> = ({
  firstName,
  lastName,
  phone,
}) => (
  <div className="flex flex-col justify">
    <div className="flex px-3">
      <CustomerIcon color="#044E97" />
      <span className="text-[14px] font-normal pl-[1em]">
        {firstName} {lastName}
      </span>
    </div>
    <div className="flex mt-2 px-3 mb-2">
      <PhoneIcon />
      <span className="text-[14px] font-normal  pl-[1em]">{phone}</span>
    </div>
  </div>
);
