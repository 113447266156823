// Reusable hook for handling line items
import { useState } from "react";
import { ExpenseLineItem } from "../../../@types";
import { clearFieldError } from "../../../utils/handleErrors";

const useLineItems = (initialLineItems: ExpenseLineItem[]) => {
  const [lineItems, setLineItems] =
    useState<ExpenseLineItem[]>(initialLineItems);
  const [errorLineItem, setErrorLineItems] = useState<any[]>([]);

  const handleLineItem = (id: number, field: string, value: string) => {
    const updatedLineItems = lineItems.map((item, index) => {
      if (index === id) {
        let updatedItem = { ...item, [field]: value };

        if (field === "quantity" || field === "rate") {
          const updatedQuantity =
            field === "quantity" ? Number(value) : item.quantity;
          const updatedRate = field === "rate" ? Number(value) : item.rate;
          const updatedAmount = updatedQuantity * updatedRate;
          updatedItem = { ...updatedItem, amount: updatedAmount };
        }
        clearFieldError(field, errorLineItem, setErrorLineItems);

        return updatedItem;
      } else {
        return item;
      }
    });
    setLineItems(updatedLineItems);
  };

  return {
    lineItems,
    setLineItems,
    handleLineItem,
    errorLineItem,
    setErrorLineItems,
  };
};

export default useLineItems;
