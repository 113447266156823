const config = {
  invoiceServiceURL: process.env.REACT_APP_INVOICE_SERVICE_URL,
  paymentServiceURL: process.env.REACT_APP_PAYMENT_SERVICE_URL,
  customerServiceURL: process.env.REACT_APP_CUSTOMER_SERVICE_URL,
  expenseServiceURL: process.env.REACT_APP_EXPENSE_SERVICE_URL,
  inventoryServiceURL: process.env.REACT_APP_INVENTORY_SERVICE_URL,
  notificationServiceURL: process.env.REACT_APP_NOTIFICATION_SERVICE_URL,
  vendorServiceURL: process.env.REACT_APP_VENDOR_SERVICE_URL,
  profileServiceURL: process.env.REACT_APP_PROFILE_SERVICE_URL,
  subscriptionServiceURL: process.env.REACT_APP_SUBSCRIPTION_SERVICE_URL,
  serviceServiceURL: process.env.REACT_APP_SERVICE_SERVICE_URL,
  CognitoUserPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
  CognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  PayStackPublicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
  userServiceURL: process.env.REACT_APP_USER_SERVICE_URL,
  beta: process.env.REACT_APP_BETA,
};

export default config;
