import React from "react";

interface HomeIconProps {
  color?: string;
}

const HomeIcon: React.FC<HomeIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 15V12.5"
          stroke={color || "currentColor"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.39172 2.34955L2.61672 6.97455C1.96672 7.49122 1.55006 8.58288 1.69172 9.39955L2.80006 16.0329C3.00006 17.2162 4.13339 18.1745 5.33339 18.1745L14.6667 18.1745C15.8584 18.1745 17.0001 17.2079 17.2001 16.0329L18.3084 9.39955C18.4417 8.58288 18.0251 7.49122 17.3834 6.97455L11.6084 2.35788C10.7167 1.64122 9.27506 1.64122 8.39172 2.34955Z"
          stroke={color || "currentColor"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
export { HomeIcon };

export const ActiveHomeIcon: React.FC<HomeIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.93781 0.663613C7.63437 0.272729 8.27958 0.0419922 9.00131 0.0419922C9.72303 0.0419922 10.3682 0.272729 11.0648 0.663613C11.7397 1.04235 12.5131 1.60153 13.4856 2.30468L13.4856 2.3047L14.7431 3.21394C15.5236 3.77822 16.1469 4.22891 16.6171 4.64605C17.1025 5.07677 17.4657 5.50616 17.6961 6.04627C17.9269 6.58756 17.9828 7.14236 17.9518 7.78206C17.9219 8.39937 17.8075 9.14417 17.6647 10.0729L17.402 11.7826C17.1992 13.1024 17.0377 14.1534 16.8004 14.9721C16.5551 15.8187 16.2095 16.4909 15.5918 17.0047C14.9767 17.5163 14.2439 17.7442 13.3488 17.8529C12.478 17.9587 11.3795 17.9587 9.99206 17.9587H8.01055C6.62309 17.9587 5.52464 17.9587 4.65385 17.8529C3.75872 17.7442 3.02595 17.5163 2.41081 17.0047C1.7931 16.4909 1.4475 15.8187 1.20218 14.9721C0.964925 14.1534 0.803432 13.1024 0.600621 11.7826L0.337908 10.073C0.195168 9.14422 0.080701 8.39939 0.0508128 7.78206C0.0198424 7.14236 0.0756802 6.58756 0.30655 6.04627C0.536918 5.50616 0.900094 5.07677 1.38555 4.64605C1.85571 4.2289 2.47905 3.77821 3.2595 3.21393L4.51703 2.30469C5.48952 1.60153 6.26289 1.04235 6.93781 0.663613ZM9.83463 11.5003C9.83463 11.0401 9.46154 10.667 9.0013 10.667C8.54106 10.667 8.16797 11.0401 8.16797 11.5003V14.0003C8.16797 14.4606 8.54106 14.8337 9.0013 14.8337C9.46154 14.8337 9.83463 14.4606 9.83463 14.0003V11.5003Z"
          fill="#0D3C6D"
        />
      </svg>
    </>
  );
};
