import React from "react";

const PoundsIcon = () => {
  return (
    <div>
      <svg
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.5021 0.949219H1.49785C0.670586 0.949219 0 1.6198 0 2.44707V12.5548C0 13.3821 0.670586 14.0527 1.49785 14.0527H18.5021C19.3294 14.0527 20 13.3821 20 12.5548V2.44707C20 1.6198 19.3294 0.949219 18.5021 0.949219Z"
          fill="#41479B"
        />
        <path
          d="M19.9793 2.19949C19.8612 1.49016 19.245 0.949219 18.5021 0.949219H18.1124L11.7241 5.13465V0.949258H8.27586V5.13469L1.88758 0.949258H1.49785C0.755 0.949258 0.138789 1.49016 0.0207422 2.19953L5.48082 5.77688H0V9.22516H5.48082L0.0207422 12.8024C0.138789 13.5118 0.755 14.0527 1.49785 14.0527H1.88758L8.27586 9.86727V14.0527H11.7241V9.86727L18.1124 14.0527H18.5021C19.245 14.0527 19.8612 13.5118 19.9793 12.8024L14.5192 9.22508H20V5.7768H14.5192L19.9793 2.19949Z"
          fill="#F5F5F5"
        />
        <path
          d="M11.0345 0.947266H8.96551V6.46449H0V8.53348H8.96551V14.0507H11.0345V8.53348H20V6.46449H11.0345V0.947266Z"
          fill="#FF4B55"
        />
        <path
          d="M0.967539 13.9527L8.25594 9.22168H6.98922L0.359375 13.5253C0.521602 13.7146 0.730078 13.863 0.967539 13.9527Z"
          fill="#FF4B55"
        />
        <path
          d="M13.5304 9.22168H12.2637L19.3229 13.8039C19.5204 13.6742 19.6853 13.4989 19.8029 13.2932L13.5304 9.22168Z"
          fill="#FF4B55"
        />
        <path
          d="M0.158203 1.77277L6.31953 5.77227H7.58625L0.604531 1.24023C0.41707 1.37977 0.263672 1.56219 0.158203 1.77277Z"
          fill="#FF4B55"
        />
        <path
          d="M12.9913 5.77383L19.6333 1.4623C19.4693 1.27359 19.2592 1.12609 19.0201 1.03809L11.7246 5.77383H12.9913Z"
          fill="#FF4B55"
        />
      </svg>
    </div>
  );
};

export default PoundsIcon;
