import { SalesStatus } from "../@types";
import { TableColumn } from "../components/common/LineItemTable/ServiceLineItemTable";
import { useTranslation } from "react-i18next";
// const { t } = useTranslation('invoices');

export const REPEAT_EVERY_WEEK = "Weekly";
export const REPEAT_EVERY_2_WEEKS = "2 weeks";
export const REPEAT_EVERY_MONTH = "Month";
export const REPEAT_EVERY_2_MONTHS = "2 months";
export const REPEAT_EVERY_3_MONTHS = "3 months";
export const REPEAT_EVERY_6_MONTHS = "6 months";
export const REPEAT_EVERY_YEAR = "Year";
export const REPEAT_EVERY_2_YEARS = "2 year";
export const REPEAT_EVERY_3_YEARS = "3 year";

// export const INVOICE_TABLE_HEADER: TableColumn[] = [
//   { label: t('itemsDescription'), key: 'name', toLowerCase: () => 'name' },
//   { label: t('variation'), key: 'variation', toLowerCase: () => 'variation' },
//   { label: 'Quantity', key: 'quantity', toLowerCase: () => 'quantity' },
//   { label: t('rate'), key: 'rate', toLowerCase: () => 'rate' },
//   { label:  t('amount'), key: 'amount', toLowerCase: () => 'amount' },
//   { label: '', key: 'amount', toLowerCase: () => 'remove' },
// ];

export const INVOICE_TABLE_HEADER: TableColumn[] = [
  { label: "Items/Description", key: "name", toLowerCase: () => "name" },
  { label: "variation", key: "variation", toLowerCase: () => "variation" },
  { label: "Quantity", key: "quantity", toLowerCase: () => "quantity" },
  { label: "Rate", key: "rate", toLowerCase: () => "rate" },
  { label: "Amount", key: "amount", toLowerCase: () => "amount" },
  { label: "", key: "amount", toLowerCase: () => "remove" },
];
export const EXPENSE_TABLE_HEADER: TableColumn[] = [
  { label: "Items/Description", key: "name", toLowerCase: () => "name" },
  { label: "Quantity", key: "quantity", toLowerCase: () => "quantity" },
  { label: "Rate", key: "rate", toLowerCase: () => "rate" },
  { label: "Amount", key: "amount", toLowerCase: () => "amount" },
  { label: "", key: "amount", toLowerCase: () => "remove" },
];
export const SERVICE_TABLE_HEADER: TableColumn[] = [
  { label: "Service Name", key: "name", toLowerCase: () => "name" },
  { label: "Duration(Hours)", key: "duration", toLowerCase: () => "duration" },
  {
    label: "Service Charge",
    key: "serviceCharge",
    toLowerCase: () => "serviceCharge",
  },
  { label: "", key: "amount", toLowerCase: () => "remove" },
];

export const REOCCURRING_FREQUENCY = [
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "yearly", label: "Yearly" },
];

export const Statuses: {
  CANCELLED: string;
  DRAFT: string;
  PAID: string;
  PENDING: string;
  DUE: string;
  REFUNDED: string;
  ACTIVE: string;
  EXPIRED: string;
  QUOTE: string;
} = {
  PAID: "Paid",
  PENDING: "Pending",
  DRAFT: "Draft",
  DUE: "Due",
  CANCELLED: "Cancelled",
  REFUNDED: "Refunded",
  ACTIVE: "Active",
  EXPIRED: "Expired",
  QUOTE: "Quote",
};

export const STATUS_TEXT = {
  paid: "Paid",
  cancelled: "Cancelled",
  pending: "Pending",
  due: "Due",
  draft: "Draft",
  refunded: "Refunded",
  active: "Active",
  expired: "Expired",
};

export const ServiceStatuses: {
  ACTIVE: string;
  INACTIVE: string;
  PENDING: string;
} = {
  ACTIVE: "Active",
  PENDING: "Pending",
  INACTIVE: "Inactive",
};

export const SERVICE_STATUS_TEXT = {
  active: "Active",
  pending: "Pending",
  inactive: "Inactive",
};
