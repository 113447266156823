import { create } from "zustand";
import {
  getNotifications,
  updateNotifications,
} from "../backend-services/notification";
import { NotificationEntity } from "../@types";

interface UserState {
  user: any;
  setUser: (user: any) => void;
}

export type Store = {
  notifications: NotificationEntity[];
  fetchNotifications: () => Promise<void>;
  batchUpdateNotifications: (
    notifications: NotificationEntity[],
  ) => Promise<void>;
};

export const useNotificationStore = create<Store>((set) => ({
  notifications: [],
  fetchNotifications: async () => {
    try {
      const notifications = await getNotifications();
      set({ notifications });
    } catch (error) {
      console.error(error);
    }
  },
  batchUpdateNotifications: async (notifications: NotificationEntity[]) => {
    try {
      const ids: any = [];
      for (let item of notifications) {
        if (item.isRead) {
          const hashTagIds = String(item["isRead#id"]).split("#")[1];
          ids.push(hashTagIds);
        }
      }
      const notification = await updateNotifications({
        ids: ids,
      });
      set({ notifications: notification });
    } catch (error) {
      console.error(error);
    }
  },
}));
