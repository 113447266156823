import React from "react";

const DangerIcon = () => {
  return (
    <>
      <svg
        width="65"
        height="64"
        viewBox="0 0 65 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M58.5266 42.4559L41.4599 11.7359C39.1666 7.6026 35.9933 5.33594 32.4999 5.33594C29.0066 5.33594 25.8333 7.6026 23.5399 11.7359L6.47327 42.4559C4.31327 46.3759 4.07327 50.1359 5.80661 53.0959C7.53994 56.0559 10.9533 57.6826 15.4333 57.6826H49.5666C54.0466 57.6826 57.4599 56.0559 59.1933 53.0959C60.9266 50.1359 60.6866 46.3493 58.5266 42.4559ZM30.4999 24.0026C30.4999 22.9093 31.4066 22.0026 32.4999 22.0026C33.5933 22.0026 34.4999 22.9093 34.4999 24.0026V37.3359C34.4999 38.4293 33.5933 39.3359 32.4999 39.3359C31.4066 39.3359 30.4999 38.4293 30.4999 37.3359V24.0026ZM34.3933 47.2293C34.2599 47.3359 34.1266 47.4426 33.9933 47.5493C33.8333 47.6559 33.6733 47.7359 33.5133 47.7893C33.3533 47.8693 33.1933 47.9226 33.0066 47.9493C32.8466 47.9759 32.6599 48.0026 32.4999 48.0026C32.3399 48.0026 32.1533 47.9759 31.9666 47.9493C31.8066 47.9226 31.6466 47.8693 31.4866 47.7893C31.3266 47.7359 31.1666 47.6559 31.0066 47.5493C30.8733 47.4426 30.7399 47.3359 30.6066 47.2293C30.1266 46.7226 29.8333 46.0293 29.8333 45.3359C29.8333 44.6426 30.1266 43.9493 30.6066 43.4426C30.7399 43.3359 30.8733 43.2293 31.0066 43.1226C31.1666 43.0159 31.3266 42.9359 31.4866 42.8826C31.6466 42.8026 31.8066 42.7493 31.9666 42.7226C32.3133 42.6426 32.6866 42.6426 33.0066 42.7226C33.1933 42.7493 33.3533 42.8026 33.5133 42.8826C33.6733 42.9359 33.8333 43.0159 33.9933 43.1226C34.1266 43.2293 34.2599 43.3359 34.3933 43.4426C34.8733 43.9493 35.1666 44.6426 35.1666 45.3359C35.1666 46.0293 34.8733 46.7226 34.3933 47.2293Z"
          fill="#FFBC1B"
        />
      </svg>
    </>
  );
};

export default DangerIcon;
