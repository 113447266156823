import React from "react";

const EnIcon = () => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.25 1.5H2.75C2.05964 1.5 1.5 2.05964 1.5 2.75V15.25C1.5 15.9404 2.05964 16.5 2.75 16.5H15.25C15.9404 16.5 16.5 15.9404 16.5 15.25V2.75C16.5 2.05964 15.9404 1.5 15.25 1.5Z"
          stroke="#111111"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.7513 6.08203H4.41797V11.9154H7.54297M4.41797 8.9987H7.54297M9.83464 6.91536V11.9154V9.20703C9.83464 8.70975 10.0322 8.23284 10.3838 7.88121C10.7354 7.52958 11.2124 7.33203 11.7096 7.33203C12.2069 7.33203 12.6838 7.52958 13.0355 7.88121C13.3871 8.23284 13.5846 8.70975 13.5846 9.20703V11.9154"
          stroke="#111111"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default EnIcon;
