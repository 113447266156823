import axios, { AxiosInstance } from "axios";
import config from "../config";
import { getAuthToken, setupAuthInterceptor } from "../utils";

export const createProfileService = (): AxiosInstance => {
  const profileService = axios.create({
    baseURL: config.profileServiceURL,
  });

  setupAuthInterceptor(profileService);

  return profileService;
};

const profileService = createProfileService();

export { profileService };
