import React, { useState, useEffect, FormEvent } from "react";
import { useInventoryStore } from "../../../state/useInventoryStore";
import { editVariationService } from "../../../backend-services/inventory";
import { v4 as uuidv4 } from "uuid";
import { InputComponent } from "../../core/Forms";
import * as UI from "../../common";
import { AddSquareIcon, CloseIcon } from "../../../assets/icons";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { SidebarDrawer } from "../../common";
import LeftNotificationPopup from "../../core/NotificationPopup/LeftNotificationPopup";
import ProcessSuccessful from "../../../assets/icons/ProcessSuccessful";
import { useTranslation } from "react-i18next";

interface editVariationPromptProps {
  item: any;
  close: () => void;
}
const EditVariationForm = ({ item, close }: editVariationPromptProps) => {
  const [formData, setFormData] = useState({ variationName: item.variantName });

  const [variationInputTypes, setVariationInputTypes] = useState<
    { id: number; placeholder: string; value: string }[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showSuccessfulModal, setShowSuccessfulModal] = React.useState(false);

  const [itemsArray, setItemsArray] = useState(item.types);

  const [indexState, setIndexState] = useState(0);

  const [error, setError] = useState("");
  const { t } = useTranslation("inventories");
  const { fetchVariation, variations } = useInventoryStore((state) => ({
    fetchVariation: state.fetchVariation,
    variations: state.variations,
  }));

  const handleSubmit = async (e: React.FormEvent) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const variationTypesArray = variationInputTypes.map((type) => type.value);
      const itemInputArray = itemsArray;
      const joinedArray = variationTypesArray.concat(itemInputArray);
      const data = {
        variantName: formData.variationName,
        types: joinedArray,
      };
      const res = await editVariationService(item.id, data);
      fetchVariation();
      if (res) {
        toast.success("variation edited successfully");
        close();
      }
    } catch (error: any) {
      toast.error(error.message);
      throw new Error(error.message);
    } finally {
      setIsLoading(false);
      setShowSuccessfulModal(true);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "variationName") {
      const isName = variations.some((item: any) => item.variantName === value);
      if (isName) {
        toast.error("Variation Name already exists");
        return;
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleInputChange = (index: any, newValue: any) => {
    const hasVariationInputValues = variationInputTypes.some(
      (type) => type.value === newValue,
    );
    const hasItemsValues = itemsArray.some(
      (value: string) => value === newValue,
    );
    if (hasVariationInputValues || hasItemsValues) {
      toast.error("The Variation already exists");
      newValue = "";
    }
    const updatedVariationInputTypes = [...variationInputTypes];
    updatedVariationInputTypes[index] = {
      ...updatedVariationInputTypes[index],
      value: newValue,
    };
    setVariationInputTypes(updatedVariationInputTypes);
  };

  const handleItemInputChange = (index: number, newValue: string) => {
    const hasValues = itemsArray.some((value: string) => value === newValue);
    if (hasValues) {
      toast.error("The Variation already exists");
      newValue = "";
      return;
    }
    const updatedItemsArray = [...itemsArray];
    updatedItemsArray[index] = newValue;
    setItemsArray(updatedItemsArray);
  };

  const handleAddVariationType = () => {
    const isMissingValue = itemsArray.includes("");
    if (item.types && !isMissingValue) {
      if (
        variationInputTypes.length === 0 ||
        variationInputTypes[variationInputTypes.length - 1]?.value !== ""
      ) {
        const newVariationInput = {
          id: parseInt(uuidv4()),
          placeholder: "Type",
          value: "",
        };
        setVariationInputTypes([...variationInputTypes, newVariationInput]);
        setError("");
      } else {
        const variationNameIndex = variationInputTypes.findIndex(
          (type) => type.value === "",
        );
        setIndexState(variationNameIndex);
        setError("Required");
      }
    }
  };

  const handleClearSelection = (index: number, value: string) => {
    const inputValue = variationInputTypes.filter(
      (inputType, indexInput) => indexInput !== index,
    );
    setVariationInputTypes(inputValue);
  };

  const handleItemClearSelection = (index: number, value: string) => {
    const newInputArray = itemsArray.filter(
      (itemValue: any, indexInput: any) => itemValue !== value,
    );
    setItemsArray(newInputArray);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col items-center">
          <div className="flex flex-col items-center gap-[16px] md:gap-[14px] mb-[] md:mb-[16px]">
            <div className="flex flex-col">
              <label
                htmlFor="first-name"
                className="text-[1rem] text-[#666666] font-normal"
              >
                Variation Name
              </label>
              <div className="max-w-[350px] md:min-w-[434px]">
                <InputComponent
                  name="variationName"
                  value={formData.variationName}
                  className="border bg-[#F2F2F2] placeholder:text-[1rem] placeholder:text-[#000000] placeholder:font-normal w-[280px] max-w-[350px] md:min-w-[434px] h-[48px] rounded"
                  type="text"
                  placeholder="Size"
                  handleChange={handleOnChange}
                />
              </div>
            </div>

            {itemsArray.map((value: any, index: any) => {
              return (
                <div key={index} className="flex flex-col">
                  <label
                    htmlFor="variation"
                    className="text-[1rem] text-[#666666] font-normal"
                  >
                    {t("inventory.variationType")}
                  </label>

                  <div className="flex flex-row relative">
                    <div className="max-w-[350px] md:min-w-[434px]">
                      <InputComponent
                        name="variation"
                        className="border bg-[#F2F2F2] placeholder:text-[1rem] placeholder:text-[#000000] placeholder:font-normal h-[48px] w-[280px] rounded max-w-[350px] md:min-w-[434px]"
                        type="text"
                        placeholder="Large"
                        value={value}
                        handleChange={(e) =>
                          handleItemInputChange(index, e.target.value)
                        }
                      />
                      {index == indexState && (
                        <span className="text-red-500 absolute">{error}</span>
                      )}
                    </div>
                    <div>
                      {value !== "" && (
                        <button
                          type="button"
                          onClick={(e) =>
                            handleItemClearSelection(index, value)
                          }
                          className={`absolute right-7 bottom-5 text-red-500`}
                        >
                          <CloseIcon />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            {variationInputTypes.map((type: any, index: any) => {
              return (
                <div key={index} className="flex flex-col">
                  <label
                    htmlFor="variation-type"
                    className="text-[1rem] text-[#666666] font-normal"
                  >
                    {t("inventory.variationType")}
                  </label>
                  <div className="flex flex-row relative">
                    <div className="max-w-[350px] md:min-w-[434px]">
                      <InputComponent
                        name="variationType"
                        className="border bg-[#F2F2F2] placeholder:text-[1rem] placeholder:text-[#000000] placeholder:font-normal h-[48px] w-[280px] rounded max-w-[350px] md:min-w-[434px]"
                        type="text"
                        placeholder="Large"
                        value={type.value}
                        handleChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                      />
                      {index == indexState && (
                        <span className="text-red-500 absolute">{error}</span>
                      )}
                    </div>

                    <div>
                      {type.value !== "" && (
                        <button
                          type="button"
                          onClick={(e) =>
                            handleClearSelection(index, type.value)
                          }
                          className={`absolute right-7 bottom-5 text-red-500`}
                        >
                          <CloseIcon />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="w-full justify-start">
              <button
                type="button"
                onClick={handleAddVariationType}
                className="flex gap-4 items-center"
              >
                <p className="text-[#044E97] text-[16px]">
                  {t("inventory.addVariationType")}
                </p>
                <AddSquareIcon />
              </button>
            </div>
          </div>

          <div className="xl:w-[300px] md:w-[300px] mt-[0em]" />
          <div className="flex justify-center items-center">
            <div className="xl:w-[300px] md:w-[300px] w-[215px] lg:w-[215px] flex justify-center xl:mt-[1em]">
              <UI.Button
                variant="secondary"
                type="submit"
                text="Save Changes"
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </form>
      {showSuccessfulModal && (
        <>
          <SidebarDrawer
            title={"Variation Edited"}
            show={true}
            position={"left"}
            onClose={() => setShowSuccessfulModal(false)}
          >
            <LeftNotificationPopup
              title={""}
              icon={<ProcessSuccessful />}
              onClose={() => setShowSuccessfulModal(false)}
            />
          </SidebarDrawer>
        </>
      )}
    </div>
  );
};

export default EditVariationForm;
