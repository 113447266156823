// The type for a single customer data
import { useSearch } from "../useSearch";
import { expenseService } from "../../services";
import { IExpense } from "../../@types";

export const useExpenseSearch = () => {
  return useSearch({
    service: expenseService,
    endpoint: "expenses/search",
    queryParameter: "q",
    responseMapper: (data: any[]): any =>
      data.map((cu: any) => ({
        ...cu,
        id: cu.id,
        amount: `${cu.amount}`,
      })) as unknown as IExpense,
  });
};
