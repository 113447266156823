import React from "react";

const UpArrowClose = () => {
  return (
    <>
      <svg
        width="14"
        height="7"
        viewBox="0 0 14 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.0575 0.74914L5.9475 5.63914C6.525 6.21664 7.47 6.21664 8.0475 5.63914L12.9375 0.74914"
          stroke="#344054"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default UpArrowClose;
