import axios from "axios";
import config from "../config";
import { setupAuthInterceptor } from "../utils";

export const createSubscriptionService = (history?: History) => {
  const subscriptionService = axios.create({
    baseURL: config.subscriptionServiceURL,
  });

  setupAuthInterceptor(subscriptionService);

  return subscriptionService;
};

const subscriptionService = createSubscriptionService();

export { subscriptionService };
