import React, { useEffect } from "react";
import {
  InventoryItem,
  errorProps,
  VariationItem,
} from "../../../@types/inventory";
import { InventoryLine } from "./InventoryLine";
import { Spinner } from "flowbite-react";
import { AddSquareIcon2 } from "../../../assets/icons";
import { useTranslation } from "react-i18next";

interface InventoryFormProps {
  inventory: InventoryItem;
  setInventories: (inventories: InventoryItem) => void;
  setIsSideBarOpen: (id: boolean) => void;
  handleCreateInventory?: (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  isLoading: boolean;
  variations: VariationItem[];
  setVariations: (Variations: VariationItem[]) => void;
  error: errorProps;
  setError: (error: errorProps) => void;
  errorMessage: string;
  variationList: any;
  variationError: any;
  setVariationError: (error: any[]) => void;
  onClose?: () => void;
}

const InventoryForm: React.FC<InventoryFormProps> = ({
  inventory,
  setInventories,
  setIsSideBarOpen,
  handleCreateInventory,
  variations,
  setVariations,
  isLoading,
  error,
  setError,
  errorMessage,
  variationList,
  variationError,
  setVariationError,
  onClose,
}) => {
  const errorRef = React.useRef<HTMLSpanElement | null>(null);
  const { t } = useTranslation("inventories");
  useEffect(() => {
    if (errorMessage) {
      errorRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [errorMessage]);
  return (
    <div>
      <InventoryLine
        inventory={inventory}
        setInventories={setInventories}
        variations={variations}
        setVariations={setVariations}
        error={error}
        setError={setError}
        variationList={variationList}
        variationError={variationError}
        setVariationError={setVariationError}
      />
      {errorMessage && (
        <span className="text-red-600 " ref={errorRef}>
          {errorMessage}
        </span>
      )}

      <div className="flex items-center gap-4 mt-8">
        <div className="w-full md:hidden">
          <button
            onClick={() => setIsSideBarOpen(true)}
            className=" font-semibold rounded-md w-full h-[48px] bg-white border border-[#044E97] inline-flex items-center justify-center whitespace-nowrap"
          >
            {t("inventory.inputVariations")}
          </button>
        </div>
        <div className="relative md:flex md:justify-end w-full md:mt-4 gap-2">
          {onClose && (
            <button
              className="md:w-[180px] w-full h-[48px] md:h-[52px] inline-flex items-center bg justify-center border border-[#044E97] text-[#044E97] rounded-[4px] text-[14px] hidden lg:block md:block"
              onClick={onClose}
            >
              {t("inventory.cancel")}
            </button>
          )}

          <button
            onClick={handleCreateInventory}
            className="md:w-[180px] w-full h-[48px] md:h-[52px] inline-flex items-center bg justify-center bg-[#044E97] text-[#ffffff] rounded-[4px]"
          >
            {isLoading ? (
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
            ) : (
              <div className="inline-flex items-center gap-[10px] text-[14px] md:text-[16px] font-semibold ">
                {t("inventory.add")}
                <AddSquareIcon2 />
              </div>
            )}
          </button>
        </div>
      </div>
      <div className="mb-[44px]"></div>
    </div>
  );
};

export { InventoryForm };
