export const invoiceCategories = [
  { type: "status", label: "Paid", value: "paid" },
  { type: "status", label: "Pending", value: "pending" },
  { type: "status", label: "Overdue", value: "due" },
  { type: "status", label: "Cancelled", value: "cancelled" },
  { type: "status", label: "Refunded", value: "refunded" },
];
export const expCategories = [
  { type: "paymentMethod", label: "Card", value: "card" },
  { type: "paymentMethod", label: "Cash", value: "cash" },
  { type: "paymentMethod", label: "Transfer", value: "transfer" },
  { type: "billable", label: "Billable", value: true },
  { type: "billable", label: "Non-Billable", value: false },
];
export const serviceCategories = [
  { type: "status", label: "Active", value: "Active" },
  { type: "status", label: "Inactive", value: "Inactive" },
];
export const invCategories = [
  { type: "stockStatus", label: "In stock", value: "in-stock" },
  { type: "stockStatus", label: "Out of stock", value: "out-of-stock" },
  { type: "variantStatus", label: "With Variation", value: "has-variation" },
  { type: "variantStatus", label: "No Variation", value: "has-no-variation" },
];

export const mobileExpCategories = [
  {
    title: "Expense Type",
    type: "billable",
    options: [
      { type: "billable", label: "Billable", value: true },
      { type: "billable", label: "Non-Billable", value: false },
    ],
  },
  {
    title: "Payment Method",
    type: "paymentMethod",
    options: [
      { type: "paymentMethod", label: "Cash", value: "cash" },
      { type: "paymentMethod", label: "Card", value: "card" },
      { type: "paymentMethod", label: "Transfer", value: "transfer" },
    ],
  },
];
export const mobileInvoiceCategories = [
  {
    title: "Invoice Status",
    type: "status",
    options: [
      { type: "status", label: "Paid", value: "paid" },
      { type: "status", label: "Pending", value: "pending" },
      { type: "status", label: "Overdue", value: "due" },
      { type: "status", label: "Cancelled", value: "cancelled" },
      { type: "status", label: "Refunded", value: "refunded" },
    ],
  },
];
export const mobileServiceCategories = [
  {
    title: "Service Status",
    type: "status",
    options: [
      { type: "status", label: "Active", value: "Active" },
      { type: "status", label: "Inactive", value: "Inactive" },
    ],
  },
];

export const mobileInvCategories = [
  {
    title: "Availability",
    type: "stockStatus",
    options: [
      { label: "In stock", value: "in-stock" },
      { label: "Out of stock", value: "out-of-stock" },
    ],
  },
  {
    title: "Variations",
    type: "variantStatus",
    options: [
      { label: "With Variation", value: "has-variation" },
      { label: "No Variation", value: "has-no-variation" },
    ],
  },
];
