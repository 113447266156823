import React from "react";
import { useParams } from "react-router-dom";
import { ICreateSalesData } from "../../../@types";
import InvoiceParent from "../../../components/Sales/invoice/InvoiceParent";

const DraftInvoicePage: React.FC = () => {
  const { invoiceNo } = useParams<{ invoiceNo: string }>();
  const [, setInvoice] = React.useState(null);
  const [, setIsSubmitting] = React.useState(false);

  const [invoiceDataState, setInvoiceDataState] =
    React.useState<ICreateSalesData>({
      additionalInformation: "",
      customerId: "",
      description: "",
      discount: 0,
      inventories: [],
      invoiceDueAt: "",
      invoiceNumber: "",
      issueDate: new Date().toISOString().substring(0, 10),
      isReoccurring: false,
      isReoccurringEndDate: "",
      isReoccurringStartDate: "",
      reoccurringGroupName: "",
      status: "pending",
      tax: 0,
      exchangeRate: 0,
      currency: "",
    });

  return (
    <>
      <InvoiceParent
        customerId={invoiceDataState.customerId}
        invoiceData={invoiceDataState}
        setInvoiceData={setInvoiceDataState}
        headingText="Edit Invoice"
      />
    </>
  );
};

export { DraftInvoicePage };
