const TickIcon = ({ stroke = "#A4A4A4" }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99998 1.83594C4.32665 1.83594 1.33331 4.82927 1.33331 8.5026C1.33331 12.1759 4.32665 15.1693 7.99998 15.1693C11.6733 15.1693 14.6666 12.1759 14.6666 8.5026C14.6666 4.82927 11.6733 1.83594 7.99998 1.83594ZM11.1866 6.96927L7.40665 10.7493C7.31331 10.8426 7.18665 10.8959 7.05331 10.8959C6.91998 10.8959 6.79331 10.8426 6.69998 10.7493L4.81331 8.8626C4.61998 8.66927 4.61998 8.34927 4.81331 8.15594C5.00665 7.9626 5.32665 7.9626 5.51998 8.15594L7.05331 9.68927L10.48 6.2626C10.6733 6.06927 10.9933 6.06927 11.1866 6.2626C11.38 6.45594 11.38 6.76927 11.1866 6.96927Z"
        fill="#20A852"
      />
    </svg>
  );
};

export { TickIcon };
