import { AuthenticationDetails } from "amazon-cognito-identity-js";
import React, { useState } from "react";
import toast from "react-hot-toast";
import userPool from "../../../utils/userPool";
import { CurrentPassword } from "./currentPassword";
import { NewPassword } from "./NewPassword";
import { PasswordError } from "../../../@types/password";
import SuccessBanner from "../../common/Banner/SuccessBanner";
import { useTranslation } from "react-i18next";

interface Props {
  submenuTab: string;
  onBack: () => void;
}

const PasswordManager: React.FC<Props> = ({ submenuTab, onBack }) => {
  const { t } = useTranslation("settings");
  const [currentStep, setCurrentStep] = useState<string>("currentPassword");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<PasswordError>({});
  const [message, setMessage] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [, setValidationResults] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleCancel = () => {
    setOldPassword("");
    setNewPassword("");
    setCurrentStep("currentPassword");
  };

  React.useEffect(() => {
    if (!success) return;

    const timer = setTimeout(() => {
      setSuccess(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [success]);

  const handleFormSubmit = (formData: { currentPassword: string }) => {
    setIsLoading(true);

    const user = userPool.getCurrentUser();
    if (!user) {
      setIsLoading(false);
      setMessage("User is not authenticated");
      return;
    }

    const authenticationDetails = new AuthenticationDetails({
      Username: user.getUsername(),
      Password: formData.currentPassword,
    });

    user.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        setFormData((prevState) => ({
          ...prevState,
          currentPassword: formData.currentPassword,
        }));
        setCurrentStep("newpassword");
        setIsLoading(false);
      },
      onFailure: (error) => {
        setIsLoading(false);
        if (error.code === "NotAuthorizedException") {
          setValidationErrors({ currentPassword: "Incorrect Password" });
        } else {
          setValidationErrors({
            currentPassword: "Error occurred during authentication",
          });
          toast.error("Authentication error occurred!");
        }
        setMessage(error.message);
      },
    });
  };

  const handleChangePassword = (
    newPassword: string,
    confirmPassword: string,
  ) => {
    setIsLoading(true);

    const user = userPool.getCurrentUser();
    if (!user) {
      setIsLoading(false);
      setMessage("User is not authenticated");
      return;
    }

    const authenticationDetails = new AuthenticationDetails({
      Username: user.getUsername(),
      Password: formData.currentPassword,
    });

    user.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        setFormData((prevState) => ({
          ...prevState,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        }));

        user.changePassword(
          formData.currentPassword,
          newPassword,
          (error, result) => {
            setIsLoading(false);
            if (error) {
              setMessage(error.message);
            } else {
              setMessage("Password has been changed successfully!");
              setFormData({
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
              });
              setValidationResults({
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
              });
              setCurrentStep("currentPassword");
              setSuccess(true);
            }
          },
        );
      },
      onFailure: (error) => {
        setIsLoading(false);
        setMessage(error.message);
      },
    });
  };

  return (
    <>
      {success && <SuccessBanner message={t("passwordchangedsuccessfully")} />}
      {currentStep === "currentPassword" && (
        <CurrentPassword
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          onSubmit={handleFormSubmit}
          isLoading={isLoading}
        />
      )}
      {currentStep === "newpassword" && (
        <NewPassword
          onSubmit={handleChangePassword}
          onCancel={handleCancel}
          oldPassword={oldPassword}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default PasswordManager;
