import React, { useEffect, useState } from "react";
import {
  AddSquareIcon,
  CustomerIcon,
  ExpensesIcon,
} from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import Loading from "../InfoCard/InfoCard/Loading";
import { formatValue } from "../../../utils";
import { BlueInvoiceIcon } from "../../../assets/icons/BlueInvoiceIcon";
import { BlueInventoryIcon } from "../../../assets/icons/BlueInventoryIcon";
import { useCompanyStatsData } from "../../../hooks/Stats/comapnyStats";
import BusinessTypeChecker from "../../../utils/businessTypeChecker";

interface StatCardProps {
  title: string;
  icon: any;
  isLoading: boolean;
  buttonTitle: string;
  dataLoaded: boolean;
  value: string;
  onButtonClick: () => void;
}

const StatCard: React.FC<StatCardProps> = ({
  title,
  buttonTitle,
  icon,
  dataLoaded,
  value,
  isLoading,
  onButtonClick,
}) => {
  return (
    <div className="flex flex-col relative w-full h-[185px] items-center justify-center p-4 border border-gray-200 bg-white rounded-sm ">
      {isLoading && !dataLoaded ? (
        <Loading />
      ) : (
        <>
          <div className="text-[16px] md:text-[20px] lg:text-[20px] font-semibold">
            {value}
          </div>
          <div className="flex items-center mt-2">
            <span className="px-1">{icon}</span>
            <span className="#191919 text-[14px] md:text-[16px] lg:text-[16px]">
              {title}
            </span>
          </div>
          <div className="flex flex-col cursor-pointer mt-[2em]">
            <button
              className="border border-gray-200 text-[12px] text-[#044E97] font-normal flex px-[1em] py-1 rounded-md "
              onClick={onButtonClick}
            >
              {buttonTitle}
              <span className="pl-2">
                {" "}
                <AddSquareIcon />
              </span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const StatsContainer: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const today = new Date();
  const startDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    0,
    0,
    0,
  );
  const endDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    23,
    59,
    59,
  );
  const { companyData, error } = useCompanyStatsData(startDate, endDate);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="w-full py-5">
      <div className="lg:text-[16px] font-medium text-[#000000] mb-4  flex justify-end">
        Today
      </div>
      <div className="lg:flex lg:divide-x :divide-gray-200 grid grid-cols-2 grid-rows-2">
        <StatCard
          value={formatValue(companyData?.totalInvoices || 0)}
          title="Invoices"
          buttonTitle="Create Invoice"
          icon={<BlueInvoiceIcon />}
          dataLoaded={false}
          isLoading={isLoading}
          onButtonClick={() => navigate("/invoices/create")}
        />
        <StatCard
          value={formatValue(companyData?.totalExpenses || 0)}
          title="Expenses"
          buttonTitle="Create Expense"
          icon={<ExpensesIcon color="#044E97" />}
          dataLoaded={false}
          isLoading={isLoading}
          onButtonClick={() => navigate("/expenses/create")}
        />
        <StatCard
          value={formatValue(companyData?.totalCustomers || 0)}
          title="Customers"
          buttonTitle="Add Customer"
          icon={<CustomerIcon color="#044E97" />}
          dataLoaded={false}
          isLoading={isLoading}
          onButtonClick={() => navigate("/customers")}
        />
        <BusinessTypeChecker
          renderRetail={() => (
            <StatCard
              value={formatValue(companyData?.totalInventory || 0)}
              title="Inventory"
              buttonTitle="Add Inventory"
              icon={<BlueInventoryIcon />}
              dataLoaded={false}
              isLoading={isLoading}
              onButtonClick={() => navigate("/inventories/create")}
            />
          )}
          renderNonRetail={() => (
            <StatCard
              value={formatValue(companyData?.totalServices || 0)}
              title="Service"
              buttonTitle="Create Service"
              icon={<BlueInventoryIcon />}
              dataLoaded={false}
              isLoading={isLoading}
              onButtonClick={() => navigate("/services/create")}
            />
          )}
        />
      </div>
    </div>
  );
};

export default StatsContainer;
