import { useState } from "react";
import { ItemInput } from "../@types";

export default function useLineItems(initialItems: ItemInput[]) {
  const [items, setItems] = useState(initialItems);

  const addItem = () => {
    setItems([
      ...items,
      {
        id: "",
        name: "",
        quantity: 0,
        rate: 0,
      },
    ]);
  };

  const removeItem = (index: number) => {
    setItems(items.filter((_, itemIndex) => itemIndex !== index));
  };

  const updateCell = (
    index: number,
    field: keyof ItemInput,
    value: string | number,
  ) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  const updateRow = (index: number, item: ItemInput) => {
    const newItems = [...items];
    newItems[index] = item;
    setItems(newItems);
  };

  return { items, addItem, setItems, removeItem, updateCell, updateRow };
}
