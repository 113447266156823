import React, { useCallback, useEffect, useRef, useState } from "react";
import { getBudgetHistory } from "../../backend-services";
import { useExpenseStore } from "../../state";
import { formatMoney } from "../../utils";
import { useExpenseSearch } from "../../hooks/expenses";
import EmptyPage from "../../components/emptyPage/EmptyPage";
import ExpenseEmptyIcon from "../../assets/icons/InvCusExpEmptyIcon";
import { useExpenseStatsData } from "../../hooks/Stats/expense";
import { useLocation, useNavigate } from "react-router-dom";
import { checkSubscription } from "../../utils/subscription";
import { servicesType } from "../../constants/Subscription";
import { TODAY, defaultShortcuts } from "../../constants/helpServices";
import DatePickerWithShortcuts from "../../components/DatePicker";
import {
  expCategories,
  mobileExpCategories,
} from "../../constants/filterCategories";
import { ICustomer } from "../../@types";
import { useTranslation } from "react-i18next";
import { SubscriptionModal } from "../../components/common/Subscription/SubscriptionModal";
import { MobileSubscriptionModal } from "../../components/common/Subscription/MobileSubscriptionModal";
import ReusableListPage from "../../components/list-page";
import UploadIcon from "../../assets/icons/svgicons/UploadIcon.svg";
import ActionModal from "../../components/common/ActionModal";
import { columns, renderExpensesData } from "../../utils/expenses/list-page";
import { ListInfoCardProps } from "../../components/list-page/desktop/ListInfoCard";
import ExpenseUploadComp from "../../components/expense/expenseUpload";
import PageLoader from "../../components/common/PageLoader";

const ViewExpensePage = () => {
  const { t } = useTranslation("expenses");
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [isBudgetLoading, setIsBudgetLoading] = useState<boolean>(false);

  const currentDate = new Date();
  const [startOfMonth, setStartOfMonth] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
  );
  const [endOfMonth, setEndOfMonth] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
  );

  const { expenseData, error } = useExpenseStatsData(startOfMonth, endOfMonth);
  const fetchMoreData = useExpenseStore((state) => state.fetchMoreData);
  const onSearchData = useExpenseStore((state) => state.setSearchText);
  const handleFilter = useExpenseStore((state) => state.setFilter);
  const fetchData = useExpenseStore((state) => state.fetchData);
  const clearAllFilters = useExpenseStore((state) => state.clearAllFilters);

  const expenses = useExpenseStore((state) => state.expenses);
  const isLoading = useExpenseStore((state) => state.isLoading);
  const isLoadingInitial = useExpenseStore((state) => state.isLoadingInitial);
  const isLoadingFilter = useExpenseStore((state) => state.isLoadingFilter);
  const isLoadingMore = useExpenseStore((state) => state.isLoadingMore);
  const range = useExpenseStore((state) => state.range);
  const pagination = useExpenseStore((state) => state.pagination);
  const searchText = useExpenseStore((state) => state.searchText);
  const filter = useExpenseStore((state) => state.filter);
  const {
    data: searchResult,
    isSearching,
    setSearchedDone,
    searchedDone,
    handleSearch: handleExpenseSearch,
  } = useExpenseSearch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] =
    React.useState(false);
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
    React.useState<string>("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<string>(TODAY.label);
  const [selected, setSelected] = useState<[Date, Date] | null>(null);
  const [selectedRange, setSelectedRange] = useState([
    {
      startDate: TODAY.date,
      endDate: TODAY.date,
      key: "selection",
      label: TODAY.label,
    },
  ]);

  const handleCalendarToggle = () => {
    setShowCalendar(!showCalendar);
  };

  const handleCreateExpense = () => {
    const subscriptionCheck = checkSubscription(servicesType.Expenses);
    if (subscriptionCheck.status) {
      setIsModalOpen(true);
      return;
    }
    setShowSubscriptionModal(true);
    setSubscriptionErrorMessage(subscriptionCheck.message || "");
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const startDateRange = params.get("startDate") || "";
    const endDateRange = params.get("endDate") || "";
    setStartDate(startDateRange);
    setEndDate(endDateRange);
  }, [location.search]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchData({ startDate: startDate, endDate: endDate });
    } else {
      fetchData();
    }
  }, [searchText, range, startDate, endDate, fetchData]);

  useEffect(() => {
    if ("paymentMethod" in filter || "billable" in filter) {
      fetchData({ actionType: "filter" });
    }
  }, [filter, fetchData]);

  const getBudget = React.useCallback(async () => {
    const date = new Date().toISOString();
    const startMonthYear = date
      .split("T")[0]
      .slice(0, 7)
      .split("-")
      .reverse()
      .join("-");
    const endMonthYear = date
      .split("T")[0]
      .slice(0, 7)
      .split("-")
      .reverse()
      .join("-");
    setIsBudgetLoading(true);
    return await getBudgetHistory(startMonthYear, endMonthYear);
  }, []);

  const fetchAllData = useCallback(async () => {
    try {
      setIsBudgetLoading(true);
      await Promise.all([fetchData(), getBudget()]);
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data;
      }
    } finally {
      setIsBudgetLoading(false);
    }
  }, [fetchData, getBudget]);

  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  const ListInfoCardData = [
    {
      title: `${t("budget.total")}`,
      mobileTitle: `${t("budget.total")}`,
      value: `${expenseData?.totalExpenses || 0}`,
      bgColor: "#F0F7FF",
      className: "w-full",
    },
    {
      title: `${t("budget.average")}`,
      mobileTitle: `${t("budget.average")}`,
      value: `${formatMoney(expenseData?.totalExpenseValue)}`,
      bgColor: "#ECFDF3",
      className: "w-full",
    },
  ] as ListInfoCardProps[];

  const handleRangeSelect = async (ranges: any) => {
    setShowCalendar(false);
    setSelected(ranges);
    const startDate = ranges[0];
    const endDate = ranges[1];
    let label = "";
    for (const shortcut of defaultShortcuts) {
      const [shortcutStart, shortcutEnd] = shortcut.range();

      if (
        startDate.getTime() === shortcutStart.getTime() &&
        endDate.getTime() === shortcutEnd.getTime()
      ) {
        label = shortcut.label;
        break;
      }
    }
    if (label) {
      setSelectedLabel(label);
    } else {
      setSelectedLabel(
        `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`,
      );
    }
    setStartOfMonth(new Date(startDate));
    setEndOfMonth(new Date(endDate));
  };

  const handleApplyButtonClick = async (
    startDate: Date,
    endDate: Date | null,
  ) => {
    if (endDate === null) {
      endDate = new Date(startDate);
      endDate.setHours(23, 59, 59, 999);
    }
    let newStartDate = startDate?.toISOString().replace(".000Z", "");
    let newEndDate = endDate?.toISOString().replace(".000Z", "");
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    try {
      await handleRangeSelect([startDate, endDate]);
      handleCalendarToggle();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const openUploadModal = () => {
    setIsModalOpen(false);
    setShowUploadPopup(true);
  };

  const handleNewMobileExpenseClick = async () => {
    const subscriptionCheck = checkSubscription(servicesType.Invoices);
    if (subscriptionCheck.status) {
      navigate("/expenses/create");
      return;
    } else {
      setShowSubscriptionModal(true);
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  useEffect(() => {
    if (!showCalendar) {
      setSelected(null);
    }
  }, [showCalendar]);

  const handleFilterSubmit = (filter: Record<string, string | boolean>) => {
    if (filter.billable !== undefined || filter.paymentMethod !== undefined) {
      //@ts-ignore
      const newFilter: Partial<Filter> = {};
      if (filter.billable !== undefined) {
        newFilter.billable = filter.billable as boolean | null;
      }
      if (filter.paymentMethod !== undefined) {
        newFilter.paymentMethod = filter.paymentMethod as string;
      }
      //@ts-ignore
      handleFilter(newFilter as Filter);
    }
  };

  if (isLoadingInitial) {
    return (
      <>
        <PageLoader />
      </>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <div className="pb-8">
        <ReusableListPage
          categories={expCategories}
          mobileCategories={mobileExpCategories}
          createTitle="Create Expense"
          pageTitle="Expenses"
          showCalendar={showCalendar}
          showBudgetCard={true}
          infoCardData={ListInfoCardData}
          handleCalendarToggle={handleCalendarToggle}
          handleCreate={handleCreateExpense}
          selectedLabel={selectedLabel}
          fetchMoreData={fetchMoreData}
          onSearchData={onSearchData}
          handleFilter={handleFilter}
          handleSearch={handleExpenseSearch}
          meta={pagination}
          data={renderExpensesData(expenses)}
          modules="expenses"
          searchDisplayKey={"id"}
          searchPlaceHolder="Search expenses by vendor name"
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          isLoadingFilter={isLoadingFilter}
          isSearching={isSearching}
          searchResult={searchResult}
          selectedFilter={filter}
          searchedDone={searchedDone}
          setSearchedDone={setSearchedDone}
          columns={columns}
          onSubmit={handleFilterSubmit}
          mobileCard="expenseCard"
          clearAllFilters={clearAllFilters}
          expenseUpload={
            <ExpenseUploadComp
              setShowUploadPopup={setShowUploadPopup}
              showUploadPopup={showUploadPopup}
            />
          }
          emptyStateDescription="Creating an expense helps you keep track of what your business spends money on the most. Not sure how to create an expense?"
          emptyPageIcon={<ExpenseEmptyIcon />}
          emptyPageHref="https://www.youtube.com/watch?si=MqUzjg-b6k0u767M&v=G-6sAdSDvRM&feature=youtu.be"
          emptyPageLinkName="expenses"
          handleSetCustomer={function (
            selectedCustomer: ICustomer | null,
          ): void {
            throw new Error("Function not implemented.");
          }}
          emptyMessage={t("expense.emptyState")}
        />
      </div>

      <div ref={containerRef}>
        <ActionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title="Select an expense creation option"
          icon={UploadIcon}
          actionButtons={[
            {
              label: `${t("budget.uploadExpense")}`,
              onClick: openUploadModal,
            },
            {
              label: `${t("budget.manualCreate")}`,
              onClick: handleNewMobileExpenseClick,
            },
          ]}
        />
      </div>

      <div className="mt-[-30em]">
        {showSubscriptionModal && (
          <SubscriptionModal>
            <MobileSubscriptionModal
              onClose={() => setShowSubscriptionModal(false)}
              titleText={`Need to access more features?`}
              subtitleText={subscriptionErrorMessage}
            />
          </SubscriptionModal>
        )}
      </div>
      {showCalendar && (
        <>
          <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh]">
            <div className="md:flex justify-center items-center h-full w-full">
              <DatePickerWithShortcuts
                onRangeSelect={handleRangeSelect}
                selected={selected}
                onClose={handleCalendarToggle}
                onApplyButtonClick={(startDate, endDate) =>
                  handleApplyButtonClick(startDate, endDate)
                }
                fetchData={fetchData}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export { ViewExpensePage };
