import React from "react";
import { CreateForm } from "./CreateForm";
import { UserDetail } from "../../../../types";
import { validateUserForm } from "../../../../modules/usermanagement/user";
import { useSubUserStore } from "../../../../state/useSubUserStore";
import { useTranslation } from "react-i18next";
import showToast from "../../../../utils/toastUtils";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

interface CreateCustomerProps {
  show: boolean;
  onClose?: () => void;
}

const CreateUser = ({ show, onClose }: CreateCustomerProps) => {
  const { t } = useTranslation("user");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState({});
  const [userDetail, setUserDetail] = React.useState<UserDetail>({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    role: "",
  });

  const { addUser, fetchUsers } = useSubUserStore((state) => ({
    addUser: state.addUser,
    fetchUsers: state.fetchUsers,
  }));

  const handleClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  React.useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const handleSubmit = async () => {
    const { hasErrors, errors } = validateUserForm({
      firstname: userDetail.firstname,
      lastname: userDetail.lastname,
      role: userDetail.role,
      email: userDetail.email,
      phone: userDetail.phone,
    });
    if (hasErrors) {
      setError(errors);
      return;
    }
    setIsLoading(true);
    try {
      await addUser({
        firstname: userDetail.firstname ?? "",
        lastname: userDetail.lastname ?? "",
        email: userDetail.email ?? "",
        phone: userDetail.phone ?? "",
        role: userDetail.role ?? "",
      });
      handleClose();
      await fetchUsers();
      // showToast('User Created Successfully', 'success');
      toast.success("User Created Successfully");
      setIsLoading(false);
    } catch (error) {
      // showToast(`${error}`, 'error');
      handleClose();
      throw new Error("Failed to create user");
    }
  };

  const handleGoBack = () => {
    navigate("/dashboard");
  };

  return isOpen ? (
    <>
      {/* Modal Background */}
      <div
        aria-hidden="false"
        className="fixed inset-0 z-40 flex items-center justify-center bg-gray-900 bg-opacity-50"
      >
        {/* Modal Content */}
        <div
          className={`relative z-50 bg-white shadow-lg rounded-lg overflow-auto 
          w-full h-full sm:w-[470px] max-h-[80vh] sm:max-h-[75vh] md:max-h-[70vh] lg:max-h-[77vh] xl:max-h-[75vh] sm:rounded-lg sm:mt-16`}
        >
          {/* Modal Header */}
          <div className="md:hidden flex justify-start gap-[8.5px] ml-[2em] px-6 py-4 border-b border-gray-200">
            <a onClick={handleGoBack}>
              <svg
                className="mt-[5px] ml-[-2em] cursor-pointer"
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 1L1.5 8L8.5 15"
                  stroke="#33363F"
                  strokeWidth="2"
                />
              </svg>
            </a>
            <h3 className="flex ml-[4.5em] text-xl font-bold">
              {t("user.createUser")}
            </h3>
          </div>
          <div className="hidden md:block pt-4">
            <h3 className="text-xl font-bold items-start pl-[1em]">
              {t("user.createUser")}
            </h3>
          </div>
          {/* Modal Body */}
          <div className="px-6 pb-2 pt-2 items-center flex justify-center md:hidden ">
            <CreateForm
              error={error}
              setError={setError}
              isLoading={isLoading}
              user={userDetail}
              setUser={setUserDetail}
              handleSubmit={handleSubmit}
              close={handleClose}
            />
          </div>
          <div className="px-6 pb-2 pt- items-center hidden md:block">
            <CreateForm
              error={error}
              setError={setError}
              isLoading={isLoading}
              user={userDetail}
              setUser={setUserDetail}
              handleSubmit={handleSubmit}
              close={handleClose}
            />
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export { CreateUser };
