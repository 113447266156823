import React from "react";
import { MobileTableBody } from "../../../common/MobileTableBody";
import { IPaginationMeta } from "../../../../@types";
import { ReusableTable } from "../../../table";
import EditUserIcon from "../../../../assets/icons/editUser";
import { EnableUserIcon } from "../../../../assets/icons/enableUser";
import DeleteUserIcon from "../../../../assets/icons/deleteUser";

interface UsersTableProps {
  data: any;
  fetchMoreData: () => void;
  meta: IPaginationMeta;
  isLoading: boolean;
  handleDelete: (id: string) => void;
  handleDisable: (id: string) => void;
  handleEnable: (id: string) => void;
  handleEdit: (id: string) => void;
}

const UsersTable = ({
  data,
  fetchMoreData,
  meta,
  isLoading,
  handleDelete,
  handleEdit,
  handleDisable,
  handleEnable,
}: UsersTableProps) => {
  const fetchPageData = async (direction: "up" | "down") => {
    if (direction === "down" && meta.hasMoreData) {
      fetchMoreData();
    }
  };
  const columns = [
    { key: "user", label: "User Name", className: "text-start" },
    { key: "email", label: "Email" },
    { key: "role", label: "Role" },
    { key: "status", label: "Status" },
  ];

  return (
    <>
      <div className="hidden md:block">
        <ReusableTable
          data={data}
          columns={columns}
          hasActions={true}
          loadMore={fetchPageData}
          isLoading={isLoading}
          renderActionMenu={(row, toggleVisibility, isVisible) => (
            <div className="relative inline-block ">
              {/* Always visible button */}
              <button
                className="text-gray-500 hover:text-gray-700 rotate-90"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleVisibility();
                }}
              >
                •••
              </button>

              {/* Dropdown menu, conditionally visible */}
              {isVisible && (
                <div className="absolute z-99999 shadow-lg rounded-md right-[-0em] md:right-[-2.5em] top-4 md:top-6 right- w-[180px] bg-white text-[11px] md:text-[14px] text-[#4E4E4E] ">
                  <div
                    className="flex justify-start pl-3"
                    onClick={() => handleEdit(row.id)}
                  >
                    <span className="mt-[0.8em] pr-2">
                      <EditUserIcon />
                    </span>
                    <p
                      className="cursor-pointer lg:py-3 py-2 text-center text-[16px]"
                      onClick={() => handleEdit(row.id)}
                    >
                      Edit User
                    </p>
                  </div>
                  {row.userStatus === "ENABLED" && (
                    <div
                      className="flex items-center justify-start pl-3 cursor-pointer lg:py-3 pb-2 text-center border-[#A4A4A4] text-[16px]"
                      onClick={() => handleDisable(row.id)}
                    >
                      <EnableUserIcon />
                      <span className="ml-2">Disable</span>
                    </div>
                  )}
                  {row.userStatus === "DISABLED" && (
                    <div
                      className="flex items-center justify-start pl-3 cursor-pointer lg:py-3 pb-2 text-center border-[#A4A4A4] text-[16px]"
                      onClick={() => handleEnable(row.id)}
                    >
                      <EnableUserIcon />
                      <span className="ml-2">Enable</span>
                    </div>
                  )}
                  <div
                    className="flex justify-start pl-3"
                    onClick={() => handleDelete(row.id)}
                  >
                    <span className="lg:mt-[0.8em] mt-[0.2em] pr-2">
                      <DeleteUserIcon />
                    </span>
                    <p
                      className="cursor-pointer lg:py-3 pb-2 text-[#FB0303] text-center text-[16px]"
                      onClick={() => handleDelete(row.id)}
                    >
                      Delete
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        />
      </div>

      <div className="md:hidden relative z-1">
        <MobileTableBody
          isLoading={false}
          payHistoryTable={undefined}
          data={data}
          CustomerCard={false}
          userCard={true}
        />
      </div>
    </>
  );
};

export { UsersTable };
