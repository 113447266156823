import React, { useEffect } from "react";
import { FILTER_LIST } from "../../../constants";
import { useInventoryStore } from "../../../state/useInventoryStore";
import { IInventory } from "../../../@types";
import { formattedDate } from "../../../utils/formatDate";
import { formatMoney } from "../../../utils";
import ExpandLeft from "../../../assets/icons/ExpandLeft";
import { SearchInput } from "../../common";
import { SearchIcon } from "../../../assets/icons";
import { DropdownComponent } from "../../core/Forms";
import ListInventoryBody from "../list/ListInventoryBody";
import { MobileTableBody } from "../../common/MobileTableBody";
import { useInView } from "react-intersection-observer";
import { useInventorySearch } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../../../assets/icons";
import Empty from "../../common/Table/Empty";
import EmptyArchive from "./EmptyArchive";
import { useTranslation } from "react-i18next";
import { ReusableTable } from "../../table";

const ArchivePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("inventories");
  const [selectedValue, setSelectedValue] = React.useState(" Recent");
  const dropDownArray = FILTER_LIST;
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isSearching, setIsSearching] = React.useState(false);
  const [text, setText] = React.useState("");

  const archivedInventory = useInventoryStore(
    (state) => state.archivedInventory,
  );
  const isLoading = useInventoryStore((state) => state.isLoading);
  const range = useInventoryStore((state) => state.range);
  const searchText = useInventoryStore((state) => state.searchText);
  const pagination = useInventoryStore((state) => state.pagination);

  const fetchArchivedInventory = useInventoryStore(
    (state) => state.fetchArchivedInventory,
  );
  const handleSort = useInventoryStore((state) => state.handleSort);
  const onSearchData = useInventoryStore((state) => state.setSearchText);
  const fetchMoreArchivedData = useInventoryStore(
    (state) => state.fetchMoreArchivedData,
  );

  const { data: searchResult, handleSearch: handleInventorySearch } =
    useInventorySearch();

  const onSearch = (text: string) => {
    if (handleInventorySearch) handleInventorySearch(text);
  };

  const handleSearchText = async (text: string) => {
    if (!text || text.length < 2) return;

    try {
      setIsSearching(true);
      await onSearch?.(text);
    } catch (error) {
      throw new Error("Error occurred during search");
    } finally {
      setIsSearching(false);
    }
  };

  const handleSearchSelect = (item: any) => {
    navigate(`/inventories/${item.id}/view`);
  };

  useEffect(() => {
    fetchArchivedInventory();
  }, [range, fetchArchivedInventory]);

  const fetchPageData = async (direction: "up" | "down") => {
    if (direction === "down" && pagination.hasMoreData) {
      fetchMoreArchivedData();
    }
  };
  const fetchPageDataMobile = (direction: "up" | "down") => {
    if (direction === "down" && pagination.hasMoreData) {
      fetchMoreArchivedData();
    }
  };

  const columns = [
    {
      key: "id",
      label: "ID",
    },
    {
      key: "name",
      label: "Customer",
    },
    {
      key: "date",
      label: "Date",
    },
    {
      key: "variations",
      label: "Variations",
    },
    {
      key: "price",
      label: t("inventory.unitPrice"),
    },
    {
      key: "quantityLeft",
      label: t("inventory.quantityLeft"),
    },
  ];

  const handleDropDownChange = (value: string) => {
    let sortValue = "";
    if (value === "Recent") sortValue = "recent";
    if (value === "Last Week") sortValue = "last_week";
    if (value === "Last Month") sortValue = "last_month";
    if (value === "Last Quarter") sortValue = "last_quarter";

    handleSort ? handleSort(sortValue) : null;
  };

  const renderArchiveInventoryData = (inventory: IInventory[]) => {
    return inventory.map((inventory: any) => {
      return {
        id: inventory.id.substring(0, 7),
        name: (
          <a
            href={`/inventories/${inventory.id}/view`}
            className="text-violet-800 hover:cursor-pointer"
          >
            {inventory.name}
          </a>
        ),
        date: formattedDate(inventory.updatedAt),
        variations: Array.isArray(inventory.variations)
          ? inventory.variations.length
          : 0,
        price: inventory.costPrice ? formatMoney(inventory.costPrice) : `₦0.00`,
        quantityLeft: !inventory.quantity ? 0 : inventory.quantity,
      };
    });
  };

  return (
    <div className="mt-[40px]">
      <a href="/inventories">
        <p className="font-semibold text-[24px] flex items-center gap-2">
          <span className="">
            <ExpandLeft />
          </span>
          {t("archive")}
        </p>
      </a>
      <div className="hidden md:block">
        <div className="flex justify-between items-center mb-6 mt-[60px]">
          <div className="relative lg:block">
            <SearchInput
              dropdownWidth="w-[202px] md:w-[292px] lg:w-[434px]"
              placeholder="Search for Customer"
              displayKey={"name"}
              isLoading={isSearching}
              value={text}
              onSelect={handleSearchSelect}
              handleChangeText={handleSearchText}
              clearIcon={CloseIcon}
              data={searchResult}
              emptyMessage={"This Item is not found"}
              dropdownStyle={{
                maxHeight: "800px",
                overflowY: "auto",
              }}
              classNames="w-[202px] block p-4 pl-10 lg:w-[434px] md:mt-[0em] lg:ml-[0em] !xl:ml-[0em] border border-gray-300 bg-white max-h-[48px] min-h-[48px] placeholder:text-[15.25px] h-[39.77px] rounded p-2 mt-[5px] focus:outline-none focus:shadow-outline appearance-none lg:focus:ring-0 leading-normal"
              searchKey={"name"}
            />
            <div className="absolute inset-y-0 left-[-1px] flex items-center pl-3 pointer-events-none">
              <SearchIcon />
            </div>
          </div>
          <DropdownComponent
            options={dropDownArray}
            value={selectedValue}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            isSubmitted={isSubmitted}
            onFilter={handleDropDownChange}
            dropdownClassName="xl:w-[290px] 2xl:w-[400px] lg:w-[185px] 1024:w-[100px] text-[16px] font-normal text-black  "
            iconClassName="xl:mr-[1em] md:mr-[1em]"
          />
        </div>
        <ReusableTable
          columns={columns}
          data={renderArchiveInventoryData(archivedInventory)}
          isLoading={false}
          loadMore={fetchPageData}
        />
      </div>

      {/* Mobile */}

      <div className="md:hidden mt-[40px]">
        <div className="mb-[24px]">
          <div className="flex justify-between items-center">
            <div className="relative lg:block">
              <SearchInput
                dropdownWidth="w-[140px] lg:w-[434px]"
                placeholder="Search"
                displayKey={"name"}
                isLoading={isSearching}
                value={text}
                onSelect={handleSearchSelect}
                handleChangeText={handleSearchText}
                clearIcon={CloseIcon}
                data={searchResult}
                emptyMessage={"This Item is not found"}
                dropdownStyle={{
                  maxHeight: "800px",
                  overflowY: "auto",
                }}
                classNames="w-[202px] block p-4 pl-10 placeholder:text-[14px] lg:w-[434px] md:mt-[0em] lg:ml-[0em] !xl:ml-[0em] border border-gray-300 bg-white max-h-[48px] min-h-[48px] placeholder:text-[15.25px] rounded focus:outline-none focus:shadow-outline appearance-none lg:focus:ring-0 "
                searchKey={"name"}
              />
              <div className="absolute inset-y-0 left-[-1px] flex items-center pl-3 pointer-events-none">
                <SearchIcon />
              </div>
            </div>
            <DropdownComponent
              options={dropDownArray}
              value={selectedValue}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              isSubmitted={isSubmitted}
              onFilter={handleDropDownChange}
              dropdownClassName="xl:w-[290px] w-[140px] 2xl:w-[400px] lg:w-[185px] 1024:w-[100px] border border-gray-300 text-[16px] font-normal text-black"
              iconClassName="xl:mr-[1em] mr-[1em]"
            />
          </div>
        </div>

        <div>
          <div className="relative md:rounded-lg position-relative ">
            <div className="flex justify-stretch lg:block w-full text-center "></div>
            <table className="w-full">
              <tbody className="divide-y font-black ">
                {archivedInventory.length === 0 ? (
                  <div>
                    <EmptyArchive
                      EmptyText={
                        "You do not have any archived inventory yet. A list of all archived Inventory will appear here."
                      }
                    />
                  </div>
                ) : (
                  <MobileTableBody
                    payHistoryTable={undefined}
                    data={renderArchiveInventoryData(archivedInventory)}
                    fetchMoreData={fetchPageDataMobile}
                    isLoading={isLoading}
                    CustomerCard={false}
                    archiveCard={true}
                  />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export { ArchivePage };
