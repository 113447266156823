import React, { useState } from "react";
import { IInvoice } from "../../@types";
import { getInvoiceDownload } from "../../backend-services";

interface DownloadInvoiceProps {
  invoice: IInvoice;
  invoiceId: string;
  onUpdate: (updatedInvoice: IInvoice) => void;
}

function useDownloadInvoice({
  invoice,
  invoiceId,
  onUpdate,
}: DownloadInvoiceProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const downloadPDF = async () => {
    setIsLoading(true);
    const currentTimestamp = Math.floor(Date.now() / 1000);

    try {
      const response = await getInvoiceDownload(invoiceId);

      if (!response || !response.url) {
        throw new Error("Invalid response received");
      }

      const blob = await fetch(response.url).then((res) => res.blob());

      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = `Invoice-${invoiceId}.pdf`;
      a.click();

      const expirationTimestamp = currentTimestamp + 7 * 24 * 60 * 60;
      const updatedInvoice = {
        ...invoice,
        pdfUrl: response.url,
        expirationTimestamp,
      };
      onUpdate(updatedInvoice);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    downloadPDF,
    isLoading,
    error,
  };
}

export { useDownloadInvoice };
