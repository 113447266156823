import { create } from "zustand";
import { invoiceService } from "../services";
import { IInvoice, IPaginationMeta } from "../@types";
import { debounce } from "lodash";
import { Filter } from "../@types/invoice";

interface FetchOptions {
  startDate?: string;
  endDate?: string;
  actionType?: "initial" | "filter";
}

interface InvoiceStore {
  invoices: IInvoice[];
  isLoading: boolean;
  isLoadingInitial: boolean;
  isLoadingFilter: boolean;
  isLoadingMore: boolean;
  error: any;
  fetchInvoices: () => Promise<void>;
  fetchData: (options?: FetchOptions) => Promise<void> | undefined;
  fetchMoreData: () => Promise<void>;
  setSearchText: (text: string) => void;
  handleSort: (text: string) => void;
  setFilter: (filter: Filter) => void;
  removeInvoice: (id: string) => void;
  addInvoice: (invoice: any) => void;
  pagination: IPaginationMeta;
  searchText: string;
  range: string;
  filter: Filter;
  getInvoiceById: (id: string) => Promise<IInvoice | null>;
  clearAllFilters: () => void;
}

export const useInvoiceStore = create<InvoiceStore>((set, get) => ({
  invoices: [],
  isLoading: false,
  isLoadingInitial: true,
  isLoadingFilter: false,
  isLoadingMore: false,
  error: null,
  searchText: "",
  range: "",
  filter: {
    status: "",
    isReoccurring: null,
  },
  pagination: {
    cursor: null,
    backCursor: null,
    hasMoreData: false,
    limit: undefined,
    count: undefined,
  },
  fetchData: async (options?: FetchOptions) => {
    const { startDate, endDate, actionType = "initial" } = options || {};
    if (actionType === "initial") {
      set({ isLoadingInitial: true });
    } else if (actionType === "filter") {
      set({ isLoadingFilter: true });
    }

    const { searchText, filter, range } = get();

    try {
      let query = `/invoices`;
      const hasFilters =
        range.length > 0 ||
        searchText.length > 0 ||
        ("status" in filter && filter.status.length > 0) ||
        ("isReoccurring" in filter && filter.isReoccurring !== null);

      if (startDate && endDate)
        query += `?startDate=${startDate}&endDate=${endDate}`;
      if (hasFilters) {
        query += startDate && endDate ? `&` : `?`;
        if (searchText.length > 0) query += `search=${searchText}&`;
        if (range.length > 0) query += `range=${range}&`;
        if ("status" in filter && filter.status.length > 0)
          query += `status=${filter.status.toLowerCase()}&`;
        if ("isReoccurring" in filter && filter.isReoccurring !== null)
          query += `isReoccurring=${filter.isReoccurring}&`;
      }

      const res = await invoiceService.get(query);

      set({
        invoices: res.data.data,
        pagination: res.data.meta,
        isLoadingInitial: false,
        isLoadingFilter: false,
      });
    } catch (e: any) {
      set({
        isLoadingInitial: false,
        isLoadingFilter: false,
        error: e.message,
      });
    }
  },

  fetchInvoices: async () => {
    set({ isLoading: true });
    try {
      const res = await invoiceService.get(`/invoices`);
      set({
        invoices: res.data.data,
        pagination: res.data.meta,
        isLoading: false,
      });
    } catch (e: any) {
      set({ isLoading: false, error: e.message });
    }
  },
  fetchMoreData: async () => {
    const { filter } = get();
    if (!get().pagination.hasMoreData || get().isLoadingMore) return; // Don't fetch if loading or no more data
    set({ isLoadingMore: true });
    const cursor = get().pagination.cursor;
    try {
      let query = `/invoices?cursor=${cursor}`;
      if ("status" in filter && filter.status.length > 0) {
        if ("status" in filter && filter.status.length > 0)
          query += `&status=${filter.status.toLowerCase()}`;
      }

      const res = await invoiceService.get(query);
      set((state) => ({
        invoices: [...state.invoices, ...res.data.data],
        pagination: res.data.meta,
        isLoadingMore: false,
      }));
    } catch (e: any) {
      set({ isLoadingMore: false, error: e.message });
    }
  },
  setSearchText: (text: string) => {
    set({ searchText: text });
  },
  handleSort: (text: string) => {
    set({ range: text });
  },
  setFilter: (filter: Filter) => {
    set({ filter: filter as Filter });
  },
  removeInvoice: (id) => {
    const invoices = get().invoices;
    set({ invoices: invoices.filter((invoice) => invoice.id !== id) });
  },
  addInvoice: (invoice) => {
    set((state) => ({ invoices: [invoice, ...state.invoices] }));
  },
  getInvoiceById: async (id: string) => {
    set({ isLoading: true });
    try {
      const res = await invoiceService.get(`/invoices/${id}`);
      const invoice = res.data.data;
      set({ isLoading: false });
      return invoice;
    } catch (e: any) {
      console.error(e.response.data);
      set({ isLoading: false, error: e.message });
      return null;
    }
  },
  clearAllFilters: () => {
    set({
      filter: {
        status: "",
        isReoccurring: null,
      },
    });
  },
}));
