import { create } from "zustand";
import { invoiceService } from "../services";
import { IPaginationMeta, IQuote } from "../@types";
import { debounce } from "lodash";
import { Filter } from "../@types/invoice";

interface FetchOptions {
  startDate?: string;
  endDate?: string;
  actionType?: "initial" | "filter";
}

interface QuotesStore {
  quotes: IQuote[];
  isLoading: boolean;
  isLoadingInitial: boolean;
  isLoadingFilter: boolean;
  isLoadingMore: boolean;
  error: any;
  fetchQuotes: () => Promise<void>;
  fetchData: (options?: FetchOptions) => Promise<void> | undefined;
  fetchMoreData: () => Promise<void>;
  setSearchText: (text: string) => void;
  handleSort: (text: string) => void;
  setFilter: (filter: Filter) => void;
  removeQuote: (id: string) => void;
  addQuote: (invoice: any) => void;
  pagination: IPaginationMeta;
  searchText: string;
  range: string;
  filter: Filter;
}

export const useQuoteStore = create<QuotesStore>((set, get) => ({
  quotes: [],
  isLoading: false,
  isLoadingInitial: true,
  isLoadingFilter: false,
  isLoadingMore: false,
  error: null,
  searchText: "",
  range: "",
  filter: {
    status: "",
    isReoccurring: null,
  },
  pagination: {
    cursor: null,
    backCursor: null,
    hasMoreData: false,
    limit: undefined,
    count: undefined,
  },
  fetchData: async (options?: FetchOptions) => {
    const { startDate, endDate, actionType = "initial" } = options || {};
    if (actionType === "initial") {
      set({ isLoadingInitial: true });
    } else if (actionType === "filter") {
      set({ isLoadingFilter: true });
    }

    const { searchText, filter, range } = get();
    try {
      let query = `/quotes`;
      if (
        range.length > 0 ||
        searchText.length > 0 ||
        ("status" in filter && filter.status.length > 0) ||
        ("isReoccurring" in filter && filter.isReoccurring !== null)
      ) {
        query += `?`;
        if (searchText.length > 0) query += `search=${searchText}&`;
        if (range.length > 0) query += `range=${range}&`;
        if ("status" in filter && filter.status.length > 0)
          query += `status=${filter.status.toLowerCase()}&`;
        if ("isReoccurring" in filter && filter.isReoccurring !== null)
          query += `isReoccurring=${filter.isReoccurring}&`;
      }
      const res = await invoiceService.get(query);
      set({
        quotes: res.data.data,
        pagination: res.data.meta,
        isLoading: false,
        isLoadingInitial: false,
        isLoadingFilter: false,
      });
    } catch (e: any) {
      set({
        isLoading: false,
        isLoadingInitial: false,
        isLoadingFilter: false,
        error: e.message,
      });
    }
  },

  fetchQuotes: async () => {
    set({ isLoading: true });
    try {
      const res = await invoiceService.get(`/quotes`);
      set({
        quotes: res.data.data,
        pagination: res.data.meta,
        isLoading: false,
      });
    } catch (e: any) {
      set({ isLoading: false, error: e.message });
    }
  },
  fetchMoreData: async () => {
    if (!get().pagination.hasMoreData || get().isLoadingMore) return;
    set({ isLoadingMore: true });
    const cursor = get().pagination.cursor;
    try {
      const res = await invoiceService.get(`/quotes?cursor=${cursor}`);
      set((state) => ({
        invoices: [...state.quotes, ...res.data.data],
        pagination: res.data.meta,
        isLoadingMore: false,
      }));
    } catch (e: any) {
      set({ isLoadingMore: false, error: e.message });
    }
  },
  setSearchText: (text: string) => {
    set({ searchText: text });
  },
  handleSort: (text: string) => {
    set({ range: text });
  },
  setFilter: (filter: Filter) => {
    set({ filter: filter as Filter });
  },
  removeQuote: (id) => {
    const quotes = get().quotes;
    set({ quotes: quotes.filter((quote: { id: string }) => quote.id !== id) });
  },
  addQuote: (quote) => {
    set((state) => ({ quotes: [quote, ...state.quotes] }));
  },
  getQuoteById: async (id: string) => {
    set({ isLoading: true });
    try {
      const res = await invoiceService.get(`/quotes/${id}`);
      const quote = res.data.data;
      set({ isLoading: false });
      return quote;
    } catch (e: any) {
      console.error(e.response.data);
      set({ isLoading: false, error: e.message });
      return null;
    }
  },
}));
