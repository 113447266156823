import React from "react";

const AlertTag = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.333984 2.33301C0.333984 1.80257 0.544698 1.29387 0.919771 0.918794C1.29484 0.543722 1.80355 0.333008 2.33398 0.333008H6.44865C6.97881 0.333298 7.48716 0.544072 7.86198 0.919008L13.1953 6.25234C13.5703 6.6274 13.7809 7.13601 13.7809 7.66634C13.7809 8.19667 13.5703 8.70529 13.1953 9.08034L9.08198 13.195C8.89626 13.3808 8.67577 13.5281 8.4331 13.6286C8.19042 13.7292 7.93032 13.7809 7.66765 13.7809C7.40498 13.7809 7.14488 13.7292 6.90221 13.6286C6.65953 13.5281 6.43904 13.3808 6.25332 13.195L0.919984 7.86167C0.544886 7.48669 0.334098 6.97806 0.333984 6.44767V2.33301ZM3.66732 2.99967C3.49051 2.99967 3.32094 3.06991 3.19591 3.19494C3.07089 3.31996 3.00065 3.48953 3.00065 3.66634C3.00065 3.84315 3.07089 4.01272 3.19591 4.13775C3.32094 4.26277 3.49051 4.33301 3.66732 4.33301C3.84413 4.33301 4.01436 4.26277 4.13939 4.13775C4.26441 4.01272 4.33465 3.84315 4.33465 3.66634C4.33465 3.48953 4.26441 3.31996 4.13939 3.19494C4.01436 3.06991 3.84413 2.99967 3.66732 2.99967Z"
        fill="#2D9CDB"
      />
    </svg>
  );
};

export { AlertTag };
