import React, { ChangeEvent, useState } from "react";
import { CloseIcon } from "../../../assets/icons";
import PhoneInputComponent from "../../common/PhoneInputComponent";
import { validatePhoneNumber } from "../../../utils";
import { ICustomer } from "../../../@types";
import { sendReceipt } from "../../../backend-services/receipt";
import { Spinner } from "flowbite-react";
import { useTranslation } from "react-i18next";

interface SendReceiptModalProps {
  setShowSendModal: (showSendModal: boolean) => void;
  setIsSending: (showSendModal: boolean) => void;
  customer: ICustomer;
  invoiceId?: string;
}

const SendReceiptModal = ({
  setShowSendModal,
  customer,
  invoiceId,
  setIsSending,
}: SendReceiptModalProps) => {
  const { t } = useTranslation("invoices");
  const [medium, setMedium] = useState({
    sms: true,
    whatsapp: false,
    email: false,
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [validatePhone, setValidatePhone] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const handleMediumChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setMedium({
      sms: value === "sms",
      whatsapp: value === "whatsapp",
      email: value === "email",
    });
  };

  const handlePhoneNumberChange = (value: string) => {
    if (value) {
      const trimmedValue = value.trim();
      const normalizedValue = trimmedValue.replace(/\D/g, "");

      const isValidPhoneNumber = validatePhoneNumber(normalizedValue);

      if (isValidPhoneNumber) {
        setPhoneNumber("+" + normalizedValue);
        setValidatePhone("");
      } else {
        setValidatePhone("Invalid phone number format");
      }
    } else {
      setPhoneNumber("");
      setValidatePhone("");
    }
  };
  const handlePhoneNumberKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.currentTarget.value.length >= 17) {
      event.preventDefault();
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (invoiceId) {
        const res = await sendReceipt(invoiceId, medium);
        setIsSending(true);
        setShowSendModal(false);
      }
      setIsLoading(false);
    } catch (error) {}
  };

  return (
    <>
      <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh]">
        <div className="flex items-end justify-center md:items-center h-full w-full">
          <div
            className={`overflow-y-auto overflow-x-hidden fixed md:top-[25%] w-full md:w-[500px] lg:w-[538px] p-6  bg-white rounded-[16px]`}
          >
            <div className="flex justify-between items-center cursor-pointer mb-2">
              <h1 className="text-[16px] md:text-[24px] text-[#292D32] leading-7 font-bold">
                Send Receipt To Customer
              </h1>
              <div onClick={() => setShowSendModal(false)}>
                <CloseIcon color="#FB0303" />
              </div>
            </div>
            <p className="text-[12px] md:text-[16px] leading-5 text-[#0A0A0A]">
              Select the medium
            </p>

            <div className="p-4 bg-[#ECF1F4] mt-4 mb-6 md:my-6rounded-[4px]">
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="sms"
                    value="sms"
                    checked={medium.sms}
                    onChange={handleMediumChange}
                    className="cursor-pointer"
                  />
                  <span className="ml-2 text-[14px] md:text-[16px]">
                    {t("SendSMS")}
                  </span>
                </label>
                {medium.sms && (
                  <div className="mt-4">
                    <label className="block text-[12px] md:text-[14px] text-gray-600">
                      Phone number
                    </label>
                    <PhoneInputComponent
                      disabled={true}
                      value={customer?.phone}
                      onPhoneNumberChange={handlePhoneNumberChange}
                      onKeyDown={handlePhoneNumberKeyDown}
                      inputClass="onboarding"
                      containerClass="sendreceipt"
                      disableDropdown
                    />
                  </div>
                )}
              </div>
              {/* 
              <div className="mt-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="medium"
                    value="whatsapp"
                    checked={medium.whatsapp}
                    onChange={handleMediumChange}
                    className="cursor-pointer"
                  />
                  <span className="ml-2 text-[14px] md:text-[16px]">Share via Whatsapp</span>
                </label>
                {medium.whatsapp && (
                  <div className="mt-4">
                    <label className="block text-[12px] md:text-[14px] text-gray-600">WhatsApp number</label>
                    <PhoneInputComponent
                      disabled={true}
                      value={customer?.phone}
                      onPhoneNumberChange={handlePhoneNumberChange}
                      onKeyDown={handlePhoneNumberKeyDown}
                      inputClass="onboarding"
                      containerClass="sendreceipt"
                      disableDropdown
                    />
                  </div>
                )}
              </div> */}

              {customer?.email && (
                <div className="mt-4">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="medium"
                      value="email"
                      checked={medium.email}
                      onChange={handleMediumChange}
                      className="cursor-pointer"
                    />
                    <span className="ml-2 text-[14px] md:text-[16px]">
                      Share via Mail
                    </span>
                  </label>
                  {medium.email && (
                    <div className="mt-2">
                      <label className="block text-[12px] md:text-[14px] text-gray-600">
                        Email
                      </label>
                      <input
                        type="email"
                        value={customer?.email}
                        disabled={true}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full cursor-not-allowed"
                        placeholder="Enter your mail"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            <button
              onClick={handleSubmit}
              className="bg-[#044E97] w-full h-[48px] font-semibold text-white flex justify-center items-center rounded-[4px] mb-[8em] lg:mb-0"
            >
              {isLoading ? <Spinner /> : "Send Receipt"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default SendReceiptModal;
