import { useState, useEffect } from "react";
import { getCustomerStats } from "../../backend-services";
import { CustomerStatsData } from "../../pages";

export function useCustomerStatsData(startDate: Date, endDate: Date) {
  const [customerStats, setCustomerStats] = useState<CustomerStatsData | null>(
    null,
  );
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    const start = startDate.toISOString();
    const end = endDate.toISOString();

    try {
      const response = await getCustomerStats(start, end);
      const data: CustomerStatsData = {
        totalInvoiceValue: response.totalInvoiceValue,
        totalDeletedCustomers: response.totalDeletedCustomers,
        totalNewCustomers: response.totalNewCustomers,
        totalOverDueInvoices: response.totalOverDueInvoices,
        totalBilled: response.totalBilled,
        totalTransactions: response.totalTransactions,
        totalCustomers: response.totalCustomers,
        dateRange: `${startDate.toDateString()} - ${endDate.toDateString()}`,
      };
      setCustomerStats(data);
      setError(null);
    } catch (error) {
      setError("Failed to fetch customer statistics");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { customerStats, error };
}
