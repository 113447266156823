import React from "react";

interface ModalFooterProps {
  children: React.ReactNode;
}

const ModalFooter: React.FC<ModalFooterProps> = ({ children }) => (
  <div className=" p-4 bg-[white] flex justify-end">{children}</div>
);

export default ModalFooter;
