export const formatLocalDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export const dashboardInitialState = {
  invoices: {
    totalInvoices: 0,
    totalPaidInvoiceValue: 0,
    totalInvoiceValue: 0,
    totalUnPaidInvoiceValue: 0,
  },
  expenses: {
    totalExpenses: 0,
    totalBillableExpenseValue: 60000,
    totalExpenseValue: 0,
    totalNonBillableExpenseValue: 35000,
  },
  cashflowTrend: {
    trend: {
      jan: { invoice: 0, expense: 0 },
      feb: { invoice: 0, expense: 0 },
      mar: { invoice: 0, expense: 0 },
      apr: { invoice: 0, expense: 0 },
      may: { invoice: 0, expense: 0 },
      jun: { invoice: 0, expense: 0 },
      jul: { invoice: 0, expense: 0 },
      aug: { invoice: 0, expense: 0 },
      sep: { invoice: 0, expense: 0 },
      oct: { invoice: 0, expense: 0 },
      nov: { invoice: 0, expense: 0 },
      dec: { invoice: 0, expense: 0 },
    },
    fill: { invoice: "#37C89A", expense: "#EB5757" },
  },
  cashBalance: { value: 0, percentageChange: 0 },
  customers: { value: 0, percentageChange: 0 },
  expenseCategories: {
    Equipments: { value: 0, fill: "" },
    Automobiles: { value: 0, fill: "" },
    Logistics: { value: 0, fill: "" },
    Rent: { value: 0, fill: "" },
  },
  invoiceStatuses: {
    totalOverDueInvoices: 0,
    totalPaidInvoices: 0,
    totalDraftInvoices: 0,
    totalPendingInvoices: 0,
    overdue: { value: 0, fill: "" },
    paid: { value: 0, fill: "" },
    draft: { value: 0, fill: "" },
    pending: { value: 0, fill: "" },
    cancelled: { value: 0, fill: "" },
  },
  transactions: {
    value: 0,
    percentageChange: 0,
    totalExpenses: 0,
    totalInvoices: 0,
    expense: { value: 0, fill: "" },
    invoice: { value: 0, fill: "" },
  },
};
