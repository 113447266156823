import { CustomerInvoices } from "./CustomerInvoices";
import { InvoiceStatsData } from "../../../@types";
import { CustomerT } from "../../../types";
import { CustomerContact } from "./CustomerContact";

interface CustomerInfoProps {
  customer: CustomerT;
  data: InvoiceStatsData;
}

export const CustomerInfo: React.FC<CustomerInfoProps> = ({
  customer,
  data,
}) => (
  <div className="md:flex md:items-start md:gap-12 md:mt-8">
    <CustomerContact customer={customer} />
    <CustomerInvoices customer={customer} />
  </div>
);
