import React, { useState } from "react";
import { InfoCircle } from "../../../assets/icons/InfoCircle";

const QuoteDownloadNotification = () => {
  const [isVisible, setIsVisible] = useState(true);

  const closeNotification = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <>
      <div className="relative hidden lg:block md:block bg-[#fff] text-black p-4 rounded-lg shadow-lg max-w-sm lg:w-[300px] xl:max-w-md biglg:max-w-md  mx-auto border border-[#C2C2C2]">
        <div className="relative before:absolute before:left-[-80px] before:top-1/2 before:transform before:-translate-y-1/2 before:border-[30px] before:border-transparent before:border-r-[white]">
          <div className="flex justify-between items-start">
            <div className="flex items-center text-center justify-center">
              <span className="text-xl mr-2 mt-[-1em]">
                <InfoCircle />
              </span>
              <div className="flex flex-col text-center justify-center">
                <h2 className="text-lg font-semibold text-[#404040]">
                  Your Quote is ready!
                </h2>
                <p className="text-[10px] lg:text-[14px]">
                  Click to download here
                </p>
              </div>
            </div>
            <button
              className="ml-4 shadow-md bg-[#F4F7FF] w-6 text-[10px] font-normal mt-[-1em]"
              onClick={closeNotification}
            >
              ✖️
            </button>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className="relative lg:hidden md:hidden bg-[#fff] text-black p-2 sm:p-4 rounded-lg shadow-lg max-w-xs sm:max-w-sm  mx-auto border border-[#C2C2C2]">
        <div className="relative before:absolute before:left-[-40px] sm:before:left-[-80px] before:top-1/2 before:transform before:-translate-y-1/2 before:border-[15px] sm:before:border-[30px] before:border-transparent before:border-r-[#fff]">
          <div className="flex justify-between items-start">
            <div className="flex items-center text-center justify-center">
              <span className="text-lg sm:text-xl mr-1 sm:mr-2 mt-[-0.5em] sm:mt-[-1em]">
                <InfoCircle />
              </span>
              <div className="flex flex-col text-center justify-center">
                <h2 className="text-[10px] sm:text-sm font-semibold text-[#404040]">
                  Your Quote is ready!
                </h2>
                <p className="text-[8px] sm:text-[10px] lg:text-[14px]">
                  Click to download here
                </p>
              </div>
            </div>
            <button
              className="ml-2 sm:ml-4 shadow-md bg-[#F4F7FF] w-4 sm:w-6 text-[8px] sm:text-[10px] font-normal mt-[-0.5em] sm:mt-[-1em]"
              onClick={closeNotification}
            >
              ✖️
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuoteDownloadNotification;
