import axios, { AxiosInstance } from "axios";
import config from "../config";
import { setupAuthInterceptor } from "../utils";

export const createUserService = (): AxiosInstance => {
  const userService = axios.create({
    baseURL: config.userServiceURL,
  });

  setupAuthInterceptor(userService);

  return userService;
};

const userService = createUserService();
export { userService };
