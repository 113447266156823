import React from "react";
import { useNavigate } from "react-router-dom";
import { deleteVariation } from "../../../backend-services";
import { toast } from "react-hot-toast";
interface VariationDeletePromptProps {
  setShowPrompt: (show: boolean) => void;
  variationId: string;
  fetchDetail: (id: string) => void;
  id: string | undefined;
}

const VariantDeletePrompt = ({
  variationId,
  setShowPrompt,
  fetchDetail,
  id,
}: VariationDeletePromptProps) => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const navigate = useNavigate();

  const handleVariationDelete = async (variationId: string | undefined) => {
    if (variationId && id) {
      try {
        setIsDeleting(true);
        const res = await deleteVariation(variationId);
        setIsDeleting(false);
        fetchDetail(id);
        setShowPrompt(false);
      } catch (e: any) {
        setIsDeleting(false);
        setErrorMessage("Unable to Delete Variation.");
        toast.error(e.response?.data || e.message);
      }
    }
  };
  return (
    <div>
      <div className="bg-[white] rounded-md w-[350px] lg:w-[500px]">
        <div className="flex justify-between  px-[1em] py-[1em]">
          <h1 className="hidden lg:block md:block text-[20px] font-semibold text-gray-900 text-center ">
            Delete variation
          </h1>
          <h1 className="lg:hidden md:hidden text-[20px] font-semibold text-gray-900 text-center whitespace-nowrap">
            Delete variation
          </h1>
        </div>
        <div className="flex justify-center mt-[13px]  px-[1em]">
          <h1 className="">
            You are about to delete a variation?
            <span>This action cannot be reversed once confirmed.</span>
          </h1>
        </div>
        <div
          className="flex mt-[1em] gap-5 justify-end pr-[1em] font-semibold"
          role="form"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="flex justify-end mb-5">
            <button
              onClick={() => setShowPrompt(false)}
              className="w-[140px] text-[14px]  rounded-[4px] h-[44px] bg-[#FFFFFF] text-[black] font-semibold border border-gray-300"
            >
              Cancel
            </button>
          </div>
          <div className="flex justify-end mb-5">
            <button
              onClick={() => handleVariationDelete(variationId)}
              className="w-[140px] text-[14px]   rounded-[4px] h-[44px] bg-[#FB0303] text-white  font-semibold"
            >
              {isDeleting ? "Deleting..." : "Delete"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VariantDeletePrompt;
