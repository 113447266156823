import { create } from "zustand";
import reportService from "../services/ReportService";
import { Reports } from "../constants/Report";
import { toast } from "react-hot-toast";
import { expenseService, inventoryService } from "../services";
import { invoiceService } from "../services";
import { customerService } from "../services";

interface ReportStore {
  reportData: any;
  isLoading: boolean;
  range: string;
  generateReport: (
    type: string,
    startDate: string,
    endDate: string,
  ) => Promise<void>;
}

//mock an api

export const useReportStore = create<ReportStore>((set) => ({
  reportData: [],
  isLoading: false,
  range: "",
  generateReport: async (type, startDate, endDate) => {
    set({ isLoading: true });
    let service;
    switch (type) {
      case Reports.INVOICE_REPORT:
        service = invoiceService;
        break;
      case Reports.EXPENSE_REPORT:
        service = expenseService;
        break;
      case Reports.CUSTOMER_REPORT:
        service = customerService;
        break;
      case Reports.INVENTORY_REPORT:
        service = inventoryService;
        break;
      default:
        throw new Error("Report Type Not Found");
        break;
    }
    try {
      const data = await service.get(startDate);

      set({ reportData: data });
    } catch (error: any) {
      toast.error(error?.message);
    }
  },
}));
