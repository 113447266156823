import { vendorService } from "../services";
import { VendorT } from "../types";
import { handleError, withRetry } from "../utils";

export const createVendor = async (data: VendorT) => {
  return withRetry(async () => {
    const res = await vendorService.post("/vendors", data);
    return res.data;
  }).catch(handleError);
};

export const getVendor = async (id: string) => {
  return withRetry(async () => {
    const res = await vendorService.get(`/vendors/${id}`);
    return res.data;
  }).catch(handleError);
};
