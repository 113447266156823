// helpers.ts (or any utility file)
type Role = "Accountant" | "Operator" | "Admin";

export const getRoleStyle = (role: Role): string => {
  const roleStyles: Record<Role, string> = {
    Accountant: "bg-[#E3ECFD] text-[#2056B6]", // Light blue
    Operator: "bg-[#F2FBF5] text-[#12B76A]", // Light green
    Admin: "bg-[#FEF6E7] text-[#B54708]", // Light yellow
  };

  return roleStyles[role];
};
