import React, { useState, useEffect } from "react";
import { Annotation } from "../../../@types/annotation";
import { formatTimeWithMeridiem } from "../../../modules/annotations";
import AnnotationIcon from "../../../assets/icons/AnnotationIcon";

interface AnnotationProps {
  annotationData: { [date: string]: Annotation[] };
  type: string;
}

const AnnotationComponent = ({ annotationData, type }: AnnotationProps) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <div className="relative">
        <div
          onMouseEnter={() => setIsVisible(true)}
          onMouseLeave={() => setIsVisible(false)}
          onClick={() => setIsVisible(!isVisible)}
        >
          <div
            className={`md:w-[38px] flex items-center 
            `}
          >
            {isVisible ? (
              <AnnotationIcon color="#044E97" />
            ) : (
              <AnnotationIcon />
            )}
          </div>
        </div>
        {isVisible && (
          <div
            className={`absolute ${type === "invoice" && ` md:left-[-17.5em]`} top-10 right-0 z-50`}
          >
            <div className="bg-[#fff] py-[10px] rounded-[4px] border-[0.5px] text-[#4E4E4E] border-[#A4A4A4] shadow-md w-[280px] md:w-[320px]">
              {Object.entries(annotationData).map(
                ([date, annotationsForDate], index, array) => (
                  <React.Fragment key={date}>
                    <div className="px-[10px]">
                      <div className="text-[11px] mb-[4px]">{date}</div>
                      <div className="space-y-[8px]">
                        {annotationsForDate.map((annotation) => (
                          <div
                            key={annotation.id}
                            className="text-[13px] flex items-center justify-between"
                          >
                            <div className="flex items-center gap-2">
                              <div className="font-medium text-[#044E97]">
                                {annotation.name}
                              </div>
                              <div>
                                {annotation.action === "removed stock"
                                  ? annotation.action
                                  : `${annotation.action} ${type}`}
                              </div>
                            </div>
                            <div className="font-medium">
                              {formatTimeWithMeridiem(annotation.date)}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {index !== array.length - 1 && (
                      <div className="border-[0.5px] border-[#A4A4A4] w-full my-[2px]" />
                    )}
                  </React.Fragment>
                ),
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AnnotationComponent;
