import { IInventory } from "../../@types";
import BinIcon from "../../assets/icons/BinIcon";
import { formatMoney } from "../format";
import { useTranslation } from "react-i18next";

export const renderInventoryData = (
  inventory: IInventory[],
  handleDeleteInventory: (id: string) => void,
) => {
  return inventory.map((inventory: any) => {
    return {
      id: inventory.id,
      mobileName: inventory.name,
      name: (
        <a
          href={`/inventories/${inventory.id}/view`}
          className="text-violet-800 hover:cursor-pointer"
        >
          {inventory.name}
        </a>
      ),
      variations: Array.isArray(inventory.variations)
        ? inventory.variations.length
        : 0,
      price:
        inventory.variations.length > 0
          ? findPriceRange(inventory.variations)
          : formatMoney(inventory.costPrice),
      quantityLeft: !inventory.quantity ? 0 : inventory.quantity,
      action: (
        <button className="text-[#044E97] border py-2 px-2.5 rounded-lg">
          <a href={`/inventories/${inventory.id}/restock`}>{"Restock"}</a>
        </button>
      ),

      bin: (
        <div onClick={() => handleDeleteInventory(inventory.id)}>
          <BinIcon color="red" />
        </div>
      ),
    };
  });
};

export const columns = [
  { key: "name", label: "Products", width: 24 },
  { key: "variations", label: "Types", width: 19 },
  { key: "price", label: "Unit price", width: 19 },
  { key: "quantityLeft", label: "Quantities left", width: 19 },
];

export const columnsAccountant = [
  { key: "name", label: "Product", className: "text-center" },
  { key: "variations", label: "Types" },
  { key: "price", label: "Unit Price" },
  { key: "quantityLeft", label: "Qty Left" },
];

type Item = {
  name: string;
  costPrice: number;
  quantity: number;
  id: string;
  type: string;
  price: number;
};

export function findPriceRange<T extends { price: number }>(
  items: T[],
): string {
  if (items.length === 0) return ""; // Handle empty array

  const { lowest, highest } = items.reduce<{
    lowest: T | null;
    highest: T | null;
  }>(
    (acc, item) => {
      if (!acc.lowest || item.price < acc.lowest.price) acc.lowest = item;
      if (!acc.highest || item.price > acc.highest.price) acc.highest = item;
      return acc;
    },
    { lowest: null, highest: null }, // Explicit initial value
  );

  // Safely access prices
  const lowestPrice = lowest?.price ?? 0;
  const highestPrice = highest?.price ?? 0;

  return `₦${lowestPrice} - ${highestPrice}`;
}
