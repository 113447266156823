import React, { useLayoutEffect } from "react";
import { ICreateSalesData } from "../../../@types";
import { useLocation, useParams } from "react-router-dom";
import InvoiceParent from "../../../components/Sales/invoice/InvoiceParent";

const CreateInvoicePage = () => {
  const { expenseId } = useParams<{ expenseId: string }>();
  const location = useLocation();
  const [invoiceDataState, setInvoiceDataState] =
    React.useState<ICreateSalesData>({
      additionalInformation: "",
      customerId: "",
      description: "",
      discount: 0,
      inventories: [],
      invoiceDueAt: "",
      invoiceNumber: "",
      issueDate: new Date().toISOString().substring(0, 10),
      isReoccurring: false,
      isReoccurringEndDate: "",
      isReoccurringStartDate: "",
      reoccurringGroupName: "",
      status: "pending",
      tax: 0,
      isPaymentCompleted: false,
      shouldSendEmail: true,
      exchangeRate: 0,
      currency: "",
      reminderSettings: [
        { days: 0, remindBy: "before", remindMe: false, isActive: false },
        { days: 0, remindBy: "due", remindMe: false, isActive: false },
        { days: 0, remindBy: "after", remindMe: false, isActive: false },
      ],
    });

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <>
      <InvoiceParent
        invoiceData={invoiceDataState}
        setInvoiceData={setInvoiceDataState}
        headingText="Add Invoice"
        customerId={""}
      />
    </>
  );
};

export { CreateInvoicePage };
