import axios, { AxiosInstance } from "axios";
import config from "../config";
import { setupAuthInterceptor } from "../utils";

export const createInventoryService = (history?: History): AxiosInstance => {
  const inventoryService = axios.create({
    baseURL: config.inventoryServiceURL,
  });

  setupAuthInterceptor(inventoryService);

  return inventoryService;
};

const inventoryService = createInventoryService();

export { inventoryService };
