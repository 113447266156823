import React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary";
}

const ModalButton: React.FC<ButtonProps> = ({
  children,
  variant = "primary",
  className,
  ...props
}) => {
  const baseClasses = "px-6 py-3 rounded-lg font-semibold focus:outline-none";
  const variantClasses =
    variant === "primary"
      ? "bg-[#FB0303] text-white hover:bg-[#d90303]"
      : "bg-white text-gray-700 border border-gray-300 hover:bg-gray-100";

  return (
    <button
      className={`${baseClasses} ${variantClasses} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default ModalButton;
