import { ICreateSupport } from "../@types";

interface ProfileFormData {
  firstName: string;
  lastName: string;
  businessName: string;
  industry: string;
  email: string;
  phone: string;
  address: string;
  state: string;
  city: string;
}

interface ProfileValidationResults {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  address?: string;
  businessName?: string;
  industry?: string;
}

export const validateProfileForm = (
  formData: ProfileFormData,
): ProfileValidationResults => {
  const validationResults: ProfileValidationResults = {};

  // validation logic  and the validationResults object

  if (!formData.firstName) {
    validationResults.firstName = "First name is required";
  }

  if (!formData.lastName) {
    validationResults.lastName = "Last name is required";
  }

  if (!formData.email) {
    validationResults.email = "Email is required";
  }

  if (!formData.phone) {
    validationResults.phone = "Phone is required";
  }

  if (!formData.businessName) {
    validationResults.businessName = "Name is required";
  }

  return validationResults;
};

export const validateSupportForm = (formData: ICreateSupport) => {
  const errors: any = {
    feature: "",
    subject: "",
    description: "",
  };

  if (!formData.feature) {
    errors.feature = "Please select a feature.";
  }

  if (!formData.subject) {
    errors.subject = "Please enter a subject.";
  }

  if (!formData.description) {
    errors.description = "Please enter a description.";
  }

  return errors;
};
