import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ISetReminder } from "../../../@types";
import ArrowLeft from "../../../assets/icons/ArrowLeft";
import ArrowRight from "../../../assets/icons/ArrowRight";
import EditIcon from "../../../assets/icons/EditIcon";
import { setReminders } from "../../../backend-services";
import { useUserStore } from "../../../state";
import PageLoader from "../../common/PageLoader";
import AutomateReminderModal from "./AutomateModal";
import { useCompanyStore } from "../../../state/useCompanyStore";

const ReminderOptions = () => {
  const { user, setUser, } = useUserStore();
  const { setReminder } = useCompanyStore()
  const userId = user?.id;

  const [reminderActiveStatus, setReminderActiveStatus] = useState({
    before: false,
    due: false,
    after: false,
  });

  const [reminderDays, setReminderDays] = useState({
    before: 0,
    due: 0,
    after: 0,
  });

  const [showReminderModal, setShowReminderModal] = useState(false);
  const [currentReminderType, setCurrentReminderType] = useState<
    "before" | "due" | "after"
  >("before");
  const [isSending, setIsSending] = useState(false);

  const getReminderStorageKey = (userId: string | undefined) => {
    return userId ? `reminderData_${userId}` : "";
  };

  useEffect(() => {
    if (userId) {
      const storageKey = getReminderStorageKey(userId);
      if (storageKey) {
        const savedReminderData = localStorage.getItem(storageKey);
        if (savedReminderData) {
          try {
            const parsedData = JSON.parse(savedReminderData);

            setReminderActiveStatus({
              before: parsedData.before?.isActive ?? false,
              due: parsedData.due?.isActive ?? false,
              after: parsedData.after?.isActive ?? false,
            });

            setReminderDays({
              before: parsedData.before?.days ?? 0,
              due: parsedData.due?.days ?? 0,
              after: parsedData.after?.days ?? 0,
            });
          } catch (error) {
            console.error("Error parsing reminder:", error);
          }
        }
      }
    }
  }, [userId]);

  const handleEditReminder = (type: "before" | "due" | "after") => {
    setCurrentReminderType(type);
    setShowReminderModal(true);
  };

  const handleToggleActive = (type: "before" | "due" | "after") => {
    const isActive = reminderActiveStatus[type];

    setReminderActiveStatus((prev) => {
      const updatedStatus = {
        ...prev,
        [type]: !isActive,
      };
      saveRemindersToLocalStorage(updatedStatus, reminderDays);
      return updatedStatus;
    });

    if (!isActive) {
      setCurrentReminderType(type);
      setShowReminderModal(true);
    } else {
      setShowReminderModal(false);
    }
  };

  const handleReminderSave = async (reminderData: ISetReminder) => {
    try {
      setIsSending(true);
      const payload: Omit<ISetReminder, "type"> = {
        days: reminderData.days,
        remindBy: currentReminderType,
        remindMe: reminderActiveStatus[currentReminderType],
        isActive: reminderActiveStatus[currentReminderType],
      };

      await setReminders(payload);

      setReminderActiveStatus((prev) => {
        const updatedStatus = {
          ...prev,
          [currentReminderType]: true,
        };
        saveRemindersToLocalStorage(updatedStatus, reminderDays);
        return updatedStatus;
      });

      setReminderDays((prev) => {
        const updatedDays = {
          ...prev,
          [currentReminderType]: reminderData.days,
        };
        saveRemindersToLocalStorage(reminderActiveStatus, updatedDays);
        return updatedDays;
      });

      setReminder({
        remindBy: currentReminderType,
        days: reminderData.days,
        remindMe: reminderActiveStatus[currentReminderType],
        isActive: true,
      });

      toast.success("Reminder set successfully!");
      setShowReminderModal(false);
    } catch (error) {
      toast.error("Failed to set reminder");
      console.error("Failed to set reminder:", error);
    } finally {
      setIsSending(false);
    }
  };

  const savedReminderData = localStorage.getItem(getReminderStorageKey(userId));
  if (savedReminderData) {
    try {
      const parsedData = JSON.parse(savedReminderData);
      if (parsedData.activeStatus && parsedData.days) {
        setReminderActiveStatus(parsedData.activeStatus);
        setReminderDays(parsedData.days);
      } else {
        console.warn("Incomplete reminder data:", parsedData);
      }
    } catch (error) {
      console.error("Error parsing reminder data:", error);
    }
  }

  const saveRemindersToLocalStorage = (
    activeStatus: { before: boolean; due: boolean; after: boolean },
    days: { before: number; due: number; after: number },
  ) => {
    if (!userId) return;

    const storageKey = getReminderStorageKey(userId);
    if (!storageKey) return;

    const reminderData = {
      before: {
        days: days.before ?? 0,
        isActive: activeStatus.before ?? false,
        remindBy: "before",
      },
      due: {
        days: days.due ?? 0,
        isActive: activeStatus.due ?? false,
        remindBy: "due",
      },
      after: {
        days: days.after ?? 0,
        isActive: activeStatus.after ?? false,
        remindBy: "after",
      },
    };

    localStorage.setItem(storageKey, JSON.stringify(reminderData));
  };

  const renderReminderRow = (
    label: string,
    type: "before" | "due" | "after",
    description: string,
  ) => {
    const days = reminderDays[type] ?? 0;
    const isActive = reminderActiveStatus[type] ?? false;

    return (
      <div className="flex gap-2 mt-[-0.6em]">
        <div className="bg-[#F3FBFB] p-3 rounded-[8px] border border-[#044E9733] border-opacity-10 mt-4 flex items-center justify-between w-full">
          <div className="flex gap-2 items-center">
            <p className="text-[#044E97]">{label}</p>
            <span className="pl-4">
              {type === "due" ? "Due date" : `${days} days ${description}`}
            </span>
          </div>
          <div
            className={`w-16 h-8 p-1 rounded-[69.33px] border-[0.53px] border-[#044E97] flex items-center transition duration-200 ease-in ${
              isActive
                ? "bg-[#044E97] justify-end"
                : "bg-[#ffffff] justify-start"
            }`}
          >
            <div
              onClick={() => handleToggleActive(type)}
              className={`${
                isActive ? "bg-[#ffffff]" : "bg-[#D1D5DB]"
              } cursor-pointer w-6 h-6 rounded-full flex justify-center items-center`}
            >
              {isActive ? <ArrowLeft /> : <ArrowRight />}
            </div>
          </div>
        </div>
        {isActive && (
          <div
            onClick={() => handleEditReminder(type)}
            className="cursor-pointer border border-[#044E9733] rounded-md bg-[#F3FBFB] border-opacity-10 mt-4 flex justify-center items-center py-1 px-4"
          >
            <EditIcon />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="text-[10px] lg:text-[14px]">
      {renderReminderRow("Reminder - 1", "before", "before due date")}
      {renderReminderRow("Reminder - 2", "due", " due date")}
      {renderReminderRow("Reminder - 3", "after", "after due date")}

      {showReminderModal && (
        <AutomateReminderModal
          setShowSendModal={setShowReminderModal}
          setIsSending={() => {}}
          onSaveReminder={handleReminderSave}
          reminderType={currentReminderType}
          days={reminderDays[currentReminderType]}
        />
      )}
    </div>
  );
};

export default ReminderOptions;
