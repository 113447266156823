import { Annotation } from "../@types/annotation";

export const formatAnnotation = (
  annotation: any,
  serviceName: string,
): Annotation => ({
  action:
    annotation.action === "create"
      ? `created`
      : annotation.action === "update"
        ? `updated`
        : annotation.action,
  id: annotation.createdAtCognitoUserId,
  date: new Date(annotation.timestamp),
  name: annotation.name,
});
