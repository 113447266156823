import * as React from "react";

const Loading = () => {
  return (
    <>
      <div className="bg-white  360:w-[165px]  375:w-[165px]  sm:ml-[-4em] w-[165px] biglg:w-[210px] h-[80px] lg:ml-[-1.9em] sm:w-[220px] md:w-[272px] bigmd:w-[415px]  md:h-[88px] md:ml-[em] 1180:w-[135px]  xl:w-[266px]  lg:w-[210px] 1220:w-[155px]  2xl:w-[290px] xl:ml-[0.2em]  xl:h-[121px] lg:h-[121px] xl:gap-[10px] lg:gap-[12px]  md:gap-[22px]lg:pl-[50px] pl-[30px] xl:pl-[50px] rounded flex items-center  gap-[16px] biglg:ml-[0.2em]">
        <div className=" bg-blue-50 w-11  h-11 flex rounded-full justify-center items-center">
          <p></p>
        </div>
        <div className="flex flex-col justify-center  gap-[9px] ">
          <div className="bg-blue-50 w-24 h-4 rounded-lg">
            <p></p>
          </div>
          <div className="bg-blue-50 w-24 h-4 rounded-lg">
            <p></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loading;
