import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useUserStore } from "../../../state";
import { DisablePrompt } from "../../Sales/invoice/view/Reminder/DisableReminder";
import ReminderOptions from "./ReminderOptions";
import { useCompanyStore } from "../../../state/useCompanyStore";

const ReminderPrompt = () => {
  const { hasReminder, reminder } = useCompanyStore((state) => ({
    hasReminder: state.hasReminder,
    reminder: state.reminder,
  }));
  const [isEnabled, setIsEnabled] = useState(hasReminder);
  const [isPromptDisabled, setIsPromptDisabled] = useState(false);

  useEffect(() => {
    setIsEnabled(hasReminder);
  }, [hasReminder]);

  const toggleReminder = () => {
    if (isEnabled) {
      setIsPromptDisabled(true);
    } else {
      setIsEnabled(true);
    }
  };

  const handleDisableReminder = () => {
    setIsEnabled(false);
    if (reminder) {
      //@ts-ignore
      useCompanyStore.getState().setReminder(null);
    }
    toast.success("Reminder disabled successfully!");
    setIsPromptDisabled(false);
  };

  const handleCancelPrompt = () => {
    setIsPromptDisabled(false);
  };

  return (
    <div className="bg-[#FFFFFF] w-full h-auto p-[5.7%] md:p-11 text-[#000000]">
      <p className="mt-[-1em] lg:text-[16px] font-medium whitespace-nowrap">
        Enable automated reminders based on your preferred frequency
      </p>

      {/* Toggle Section */}
      <div className="flex items-center justify-between border border-gray-300 py-2 px-[1em] md:max-w-[580px] rounded-md">
        <span className="text-sm text-gray-700">
          {isEnabled
            ? "Disable Automated Reminders"
            : "Enable Automated Reminders"}
        </span>
        <div
          onClick={toggleReminder}
          className={`w-16 h-8 p-1 rounded-[69.33px] flex items-center transition duration-200 ease-in cursor-pointer ${
            isEnabled
              ? "bg-[#044E97] justify-end border-[#044E97]"
              : "bg-[#ffffff] justify-start border border-gray-300"
          }`}
          style={{
            borderWidth: "1px",
          }}
        >
          <div
            className={`w-6 h-6 rounded-full shadow-md transition-transform duration-200 ease-in ${
              isEnabled ? "bg-[#ffffff]" : "bg-[#D1D5DB]"
            }`}
          ></div>
        </div>
      </div>

      {/* Conditional Div */}
      {isEnabled && (
        <div className="bg-[#DBE7FF] p-5 rounded-[4px] md:max-w-[540px] border border-[#044E9733] border-opacity-20 mt-[2em] lg:ml-[2.2em]">
          <p className="text-[#0A0A0A] mb-8 text-[14px]">
            Automatically send timely reminders to clients before or after
            invoice due dates to improve payment collection and reduce manual
            follow-ups.
            <span className="text-[#044E97]">
              {" "}
              Please note this only affects invoices created after automation
              was set.
            </span>
          </p>
          <ReminderOptions />
        </div>
      )}

      {/* Disable Prompt */}
      {isPromptDisabled && (
        <DisablePrompt
          onDisable={handleDisableReminder}
          showPrompt={isPromptDisabled}
          onClose={handleCancelPrompt}
        />
      )}
    </div>
  );
};

export default ReminderPrompt;
