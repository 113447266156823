import React, { useState, useRef, useEffect } from "react";
import { Button } from "../../common";
import { Alert } from "flowbite-react";
import { validateCustomerForm } from "../../../modules/customers/customers";
import { CustomerT } from "../../../types";
import { formatNumber, validatePhoneNumber } from "../../../utils";
import { InputComponent } from "../../core/Forms";
import { clearFieldError, handleErrors } from "../../../utils/handleErrors";
import toast from "react-hot-toast";
import LocationSelector from "../../common/Address";
import { ICustomer } from "../../../@types";
import PhoneInputComponent from "../../common/PhoneInputComponent";
import "react-phone-input-2/lib/style.css";
import throttle from "lodash/throttle";
import { CloseIcon } from "../../../assets/icons";
import CustomerLocationSelector from "../../common/Address/CustomerAddress";
import { useTranslation } from "react-i18next";

interface CreateFormProps {
  show: boolean;
  onSubmit: any;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClose?: () => void;
  buttontitle: string;
  isLoading: boolean;
  customer?: CustomerT;
  errorMessage?: string;
  backendErrorMessage?: string;
  setIsLoadingPrompt?: any;
  setFormData: React.Dispatch<React.SetStateAction<ICustomer>>;
  handleClose?: () => void;
  title?: string;
}
interface ValidationErrors {
  firstName: string;
  lastName: string;
  phone: string;
}

const CreateForm: React.FC<CreateFormProps> = (props) => {
  const { t } = useTranslation("customer");
  const {
    customer,
    onSubmit,
    isLoading,
    backendErrorMessage,
    setIsLoadingPrompt,
    setFormData,
    onClose,
  } = props;
  const [, setErrorMessage] = React.useState("");
  const [, setIsOpen] = React.useState(props.show);
  const [value, setValue] = useState("");
  const [backendErrorMessageHandler, setBackendErrorMessage] = React.useState(
    backendErrorMessage || "",
  );

  const [firstName, setFirstName] = React.useState(customer?.firstName);
  const [lastName, setlastName] = React.useState(customer?.lastName);
  const [email, setEmail] = React.useState(customer?.email);
  const [phone, setPhone] = React.useState(customer?.phone);
  const [address, setAddress] = React.useState(customer?.address);
  const [state, setState] = React.useState(customer?.state || "");
  const [city, setCity] = React.useState(customer?.city || "");

  const [businessName, setBusinessName] = React.useState(
    customer?.businessName,
  );
  const errorFieldRef = useRef<HTMLInputElement | null>(null);
  const [placeholder] = useState("+234 900 000 0000");
  const initialPhoneNumber = "+234";
  const [firstErrorField, setFirstErrorField] = useState<string | null>(null);
  const [showAddressFields, setShowAddressFields] = useState(false);
  const inputRefs: Record<string, React.RefObject<HTMLInputElement>> = {
    firstName: useRef(null),
    lastName: useRef(null),
    phone: useRef(null),
  };

  const errorRefs: Record<string, React.RefObject<HTMLDivElement>> = {
    firstName: useRef(null),
    lastName: useRef(null),
    phone: useRef(null),
  };

  const [validationResults, setValidationResults] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  });

  function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    setBackendErrorMessage(backendErrorMessage || "");
  }, [backendErrorMessage]);

  React.useEffect(() => {
    setBackendErrorMessage(backendErrorMessage || "");
  }, [backendErrorMessage]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    switch (name) {
      case "firstName":
        setFirstName(capitalizeFirstLetter(value));
        break;
      case "lastName":
        setlastName(capitalizeFirstLetter(value));
        break;
      case "email":
        setEmail(value.toLowerCase());
        // Email validation: check if the email contains "@"
        if (!value.includes("@")) {
          setValidationResults((prevResults) => ({
            ...prevResults,
            email: 'Invalid email address. Please include "@" symbol.',
          }));
        } else {
          clearFieldError(name, validationResults, setValidationResults);
        }
        break;
      case "phone":
        setPhone(formatNumber(value));
        break;
      case "address":
        setAddress(value);
        break;
      case "city":
        setCity(value);
        break;
      case "state":
        setState(value);
        break;
      case "businessName":
        setBusinessName(value);
        break;
      default:
        break;
    }
    clearFieldError(name, validationResults, setValidationResults);
  };

  const throttledSubmit = useRef(
    throttle((newCustomer: any) => {
      onSubmit(newCustomer);
    }, 2000),
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  
    const { hasErrors, errors } = validateCustomerForm({
      firstName,
      lastName,
      phone,
      email,
    });
  
    setValidationResults(errors);
  
    if (!hasErrors) {
      let phoneNumberToSend = phone || "";
  
      const newCustomer = {
        firstName,
        lastName,
        phone: phoneNumberToSend,
        address,
        email,
      };
  
      try {
        await throttledSubmit.current(newCustomer);
        if (setIsLoadingPrompt) {
          setIsLoadingPrompt(true); // Ensure `setIsLoadingPrompt` is a function
        } else {
          console.warn("setIsLoadingPrompt is not defined");
        }
      } catch (error: any) {
        let errorMessage = "An unknown error occurred";
  
        if (error.response && error.response.data?.message) {
          errorMessage = error.response.data.message;
        } else if (error.message) {
          errorMessage = error.message;
        }
  
        console.error("Submission error:", errorMessage);
        toast.error(errorMessage); // Show toast notification
      }
    }
  };

  const handlePhoneNumberChange = (value: string) => {
    const normalizedValue = value.replace(/\D/g, "");

    const isValidPhoneNumber = validatePhoneNumber(normalizedValue);

    if (isValidPhoneNumber) {
      // Format to include country code if necessary
      const formattedPhoneNumber = normalizedValue.startsWith("+")
        ? normalizedValue
        : `+${normalizedValue}`;

      setPhone(formattedPhoneNumber);
      setValidationResults({ ...validationResults, phone: "" });
    } else {
      setValidationResults({
        ...validationResults,
        phone: "Invalid phone format",
      });
    }
  };

  React.useEffect(() => {
    setIsOpen(props.show);
  }, []);

  React.useEffect(() => {
    setPhone(customer?.phone || "");
  }, [customer]);

  useEffect(() => {
    let intervalId: any;
    if (backendErrorMessageHandler) {
      intervalId = setTimeout(() => {
        setBackendErrorMessage("");
      }, 7000);
    }

    return () => {
      clearTimeout(intervalId);
    };
  }, [backendErrorMessageHandler]);

  const handleLocationChange = (
    selectedState: string,
    cities: string | any[],
  ) => {
    setState(selectedState);
    setCity(cities.length > 0 ? cities[0] : "");

    props.setFormData((prevData) => ({
      ...prevData,
      state: selectedState,
      city: cities.length > 0 ? cities[0] : "",
    }));
  };

  const handlePhoneNumberKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.currentTarget.value.length >= 17) {
      event.preventDefault();
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowAddressFields(e.target.checked);
  };
  const [phoneNumber, setPhoneNumber] = useState("");
  const handleChange = (value: React.SetStateAction<string>) => {
    setPhoneNumber(value);
  };

  return (
    <>
      <div className="text-center 360:text-center ">
        {" "}
        <form className="" action="" onSubmit={handleSubmit}>
          {backendErrorMessageHandler && (
            <Alert className="text-center" color="failure">
              {backendErrorMessageHandler}
            </Alert>
          )}
          {/* mobile */}
          <div className=" lg:hidden md:hidden">
            <div
              id="input-container"
              className="xl:px-[5px] xl:w-[434px] w-[320px] md:w-[420px]"
              ref={errorFieldRef}
            >
              <label
                htmlFor=" First Name"
                className="flex justify-between text-[13px] text-black"
                style={{ whiteSpace: "nowrap" }}
              >
                First Name
                <span className="text-red-500">**Required</span>
              </label>
              <InputComponent
                placeholder="First Name"
                name="firstName"
                type="text"
                value={firstName}
                handleChange={handleInputChange}
                error={validationResults.firstName}
                className="rounded-[5px] xl:w-[324px] w-[320px] md:w-[324px]"
                ref={inputRefs.firstName}
              />
            </div>
            <div className=" mt-[16px] xl:w-[324px] w-[320px] md:w-[324px]">
              <label
                htmlFor="Last Name"
                className="flex justify-between text-[13px] text-black"
                style={{ whiteSpace: "nowrap" }}
              >
                Last Name
                <span className="text-red-500">**Required</span>
              </label>
              <InputComponent
                placeholder="Last Name"
                name="lastName"
                type="text"
                value={lastName}
                handleChange={handleInputChange}
                error={validationResults.lastName}
                className="rounded-[5px] xl:w-[324px] w-[320px] md:w-[324px]"
                ref={inputRefs.lastName}
              />
            </div>
            <div className="mt-[16px]  lg:mt-[37px] xl:w-[434px] w-[320px] md:w-[420px]">
              <label
                htmlFor="Phone Number"
                className="flex justify-between text-[13px] text-black"
                style={{ whiteSpace: "nowrap" }}
              >
                {t("customer.phone")}
                <span className="text-red-500 mb-3">**Required</span>
              </label>

              <PhoneInputComponent
                onPhoneNumberChange={handlePhoneNumberChange}
                initialPhoneNumber={phone}
                containerClass="customer"
                inputClass="customer"
              />
            </div>
            <div className="relative ml-[-8.1em] md:ml-[-14.1em] lg:ml-[-13.8em] xl:ml-[-16em] 2xl:ml-[-16em] 3xl:ml-[-16em] biglg:ml-[-14em]  ">
              {validationResults.phone &&
                validationResults.phone.length > 0 && (
                  <p className="text-red-500 text-sm">
                    {validationResults.phone}
                  </p>
                )}
            </div>
            <div className=" mt-[16px] lg:mt-[37px]">
              <label
                htmlFor="Email"
                className="flex text-[13px] text-black justify-between"
              >
                Email
              </label>
              <InputComponent
                name="email"
                placeholder="Email Address"
                type="text"
                value={email}
                handleChange={handleInputChange}
                className="rounded-[5px] xl:w-[324px] w-[320px] md:w-[324px]"
              />
            </div>
            <div className="flex items-center mt-[2em] biglg:ml-[1em] xl:ml-[0.3em]">
              <input
                type="checkbox"
                id="toggleAddressFields"
                checked={showAddressFields}
                onChange={handleCheckboxChange}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="link-checkbox"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Click here to fill in more details.
              </label>
            </div>
            {showAddressFields && (
              <div>
                <div className="mt-[16px]  lg:mt-[37px]">
                  <label
                    htmlFor="BusinessName"
                    className="block text-[13px] text-black text-left "
                  >
                    {t("customer.businessName")}
                  </label>
                  <InputComponent
                    name="businessName"
                    placeholder="Business Name"
                    type="text"
                    value={businessName}
                    handleChange={handleInputChange}
                    className="rounded-[5px] xl:w-[434px] w-[320px] md:w-[420px] lg:ml-[-0.6em]"
                  />
                </div>
                <div className=" mt-[16px]  lg:mt-[37px]">
                  <label
                    htmlFor="Address"
                    className="block text-[13px] text-black text-left biglg:ml-[1em] xl:ml-[0.3em]"
                  >
                    {t("customer.address")}
                  </label>
                  <InputComponent
                    placeholder="Billing Address"
                    name="address"
                    type="text"
                    value={address}
                    handleChange={handleInputChange}
                    className="rounded-[5px] xl:w-[324px] w-[320px] md:w-[324px]"
                  />
                </div>
                <div className="w-[320px] mt-[1em] md:w-[420px] xl:w-[434px] lg:mt-[1em] xl:mt-[2.5em]">
                  <LocationSelector
                    onLocationChange={handleLocationChange}
                    onError={(error: any) => {}}
                    selectedState={state}
                    selectedCity={city}
                  />
                </div>
              </div>
            )}
            <div className="mt-[32px] flex gap-6 w-full">
              <Button
                outline={true}
                variant="secondary"
                disabled={false}
                text={"Cancel"}
                size="full"
                onClick={onClose}
              />
              <Button
                isLoading={isLoading}
                text={props.buttontitle}
                size="full"
                variant="primary"
                onClick={onSubmit}
              />
            </div>
          </div>
        </form>
      </div>

      {/* Desktop */}
      <div className="text-center 360:text-center ">
        {" "}
        <form className="" action="" onSubmit={handleSubmit}>
          {backendErrorMessageHandler && (
            <Alert className="text-center" color="failure">
              {backendErrorMessageHandler}
            </Alert>
          )}
          <div className="hidden md:block lg:block">
            <div className="flex items-center justify-between">
              <h3 className="text-[24px] font-medium">{props.title}</h3>

              <button
                aria-label="Close"
                className="flex items-center justify-center bg-[#F4F7FF] h-11 w-11 pr-[1em]"
                type="button"
                onClick={onClose}
              >
                <CloseIcon color="#212121" />
              </button>
            </div>
            {/* line 1 */}
            <div className="grid grid-cols-2 gap-4 mt-[1em] text-[16px]">
              <div className="col-span-1">
                <label className="  font-normal text-gray-700 flex justify-between ">
                  First Name
                  <span className="text-red-500 ">**Required</span>
                </label>
                <InputComponent
                  name="firstName"
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
                  placeholder="First Name"
                  value={firstName}
                  handleChange={handleInputChange}
                  error={validationResults.firstName}
                  ref={inputRefs.firstName}
                />
              </div>

              <div className="col-span-1">
                <label className=" font-normal text-gray-700 flex justify-between text-[16px]">
                  Last Name
                  <span className="text-red-500 text-[16px]">**Required</span>
                </label>
                <InputComponent
                  name="lastName"
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
                  placeholder="Last Name"
                  value={lastName}
                  handleChange={handleInputChange}
                  error={validationResults.lastName}
                  ref={inputRefs.lastName}
                />
              </div>
            </div>
            {/* Line 2 */}

            <div className="grid grid-cols-2 gap-4 text-[16px] mt-[1em]">
              <div className="col-span-1">
                <label className="  font-normal text-gray-700 flex justify-between ">
                  {" "}
                  {t("customer.phone")}
                  <span className="text-red-500 ">**Required</span>
                </label>
                <PhoneInputComponent
                  onPhoneNumberChange={handlePhoneNumberChange}
                  initialPhoneNumber={phone}
                  containerClass="customer"
                  inputClass="customer"
                />
                <div className="relative ml-[-8.1em] md:ml-[-14.1em] lg:ml-[-19em] xl:ml-[-19em] 2xl:ml-[-16em] 3xl:ml-[-16em] biglg:ml-[-19em]  ">
                  {validationResults.phone &&
                    validationResults.phone.length > 0 && (
                      <p className="text-red-500 text-sm">
                        {validationResults.phone}
                      </p>
                    )}
                </div>
              </div>
              <div className="col-span-1">
                <label className=" font-normal text-gray-700 flex justify-between ">
                  Email
                  <span className="text-red-500 "></span>
                </label>
                <InputComponent
                  name="email"
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
                  placeholder="Enter Email"
                  value={email}
                  handleChange={handleInputChange}
                  error={validationResults.email}
                />
              </div>
            </div>

            {/* Line 3 */}
            <div className="grid grid-cols-2 gap-4 text-[16px] mt-[1em]">
              <div className="col-span-1">
                <label className=" font-normal text-gray-700 flex justify-between ">
                  {t("customer.businessName")}
                  <span className="text-red-500 "></span>
                </label>
                <InputComponent
                  name="businessName"
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
                  placeholder="Business Name"
                  value={businessName}
                  handleChange={handleInputChange}
                />
              </div>
              <div className="col-span-1">
                <label className=" font-normal text-gray-700 flex justify-between  ">
                  {t("customer.address")}
                  <span className="text-red-500 "></span>
                </label>
                <InputComponent
                  name="address"
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
                  placeholder="Billing address"
                  value={address}
                  handleChange={handleInputChange}
                />
              </div>
            </div>

            {/* Line 4 */}
            <div className="grid grid-cols-2 gap-4   text-[16px] mt-[1em] w-[400px]">
              <div className="col-span-1">
                <CustomerLocationSelector
                  onLocationChange={handleLocationChange}
                  onError={(error: any) => {}}
                  selectedState={state}
                  selectedCity={city}
                />
              </div>
            </div>
            <div className="mt-[32px] flex gap-6 w-full">
              <Button
                outline={true}
                variant="secondary"
                disabled={false}
                text={"Cancel"}
                size="full"
                onClick={onClose}
              />
            <Button
  isLoading={isLoading}
  text={props.buttontitle}
  size="full"
  variant="primary"
  type="submit"
/>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateForm;
