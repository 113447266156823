import React, { useState } from "react";
import { FilterOption } from "../desktop/ListFilter";
import { Filter } from "../../../@types/invoice";
import { SearchInput } from "../../common";
import { CloseIcon, SearchIcon } from "../../../assets/icons";
import MobileFilterIcon from "../../../assets/icons/MobileFilterIcon";
import { Calendar } from "../../../assets/icons/calendar";
import MobileFilter, { FilterCategory } from "./MobileFilter";
import { expCategories } from "../../../constants/filterCategories";

interface HeadProps {
  handleSearch?: (text: string) => void;
  handleFilter?: (filter: Filter) => void;
  filter?: Filter;
  handleSearchSelect?: (item: any) => void;
  isSearching?: boolean | undefined;
  searchedDone?: boolean | undefined;
  setSearchedDone?: (searchedDone: boolean) => void;
  searchResult: any[];
  searchDisplayKey: string;
  searchPlaceHolder: string;
  categories: FilterCategory[];
  onSubmit?: (filter: Record<string, string | boolean>) => void;
  filterByDate?: boolean;
  handleCalendarToggle?: () => void;
  showCalendar?: boolean;
  showFilter?: boolean;
  selectedLabel?: string;
  modules?: string;
  clearAllFilters?: () => void;
}

const MobileHead: React.FC<HeadProps> = (props) => {
  const [text, setText] = React.useState("");
  const [openFilter, setOpenFilter] = useState(false);

  const handleSearch = (text: string) => {
    if (!text || text.length < 2) return;
    try {
      props.handleSearch?.(text);
    } catch (error) {
      console.error("Error occurred during search:", error);
    }
  };

  return (
    <div className="mt-6">
      <div className="w-full flex gap-3 items-center">
        <div className={`w-full`}>
          <SearchInput
            placeholder={props.searchPlaceHolder}
            displayKey={props.searchDisplayKey}
            isLoading={props.isSearching}
            searchedDone={props.searchedDone}
            setSearchedDone={props.setSearchedDone}
            value={text}
            onSelect={props.handleSearchSelect}
            handleChangeText={handleSearch}
            clearIcon={CloseIcon}
            icon={<SearchIcon />}
            data={props.searchResult}
            emptyMessage={"This Item is not found"}
            dropdownClassName="w-full"
            dropdownStyle={{
              maxHeight: "400px",

              overflowY: "auto",
            }}
            classNames="h-[48px] rounded-xl text-[16px] w-full border border-gray-200"
            searchKey={["customer.firstName", "vendorName", "name"]}
          />
        </div>
        {props.showFilter && (
          <div
            onClick={() => setOpenFilter(true)}
            className="bg-gray-50 p-[10px] border border-gray-200 rounded-lg"
          >
            <MobileFilterIcon />
          </div>
        )}
      </div>

      {props.filterByDate && (
        <div className="md:hidden mt-[1em]">
          <div
            onClick={props.handleCalendarToggle}
            className="relative flex items-center justify-between border border-gray-300 rounded-lg h-[48px] w-full"
            style={{ cursor: props.showCalendar ? "pointer" : "default" }}
          >
            <div
              onClick={props.handleCalendarToggle}
              className={`text-gray-800 text-[16px] rounded-md ml-[0.75em]`}
            >
              {props.selectedLabel}
            </div>

            <div
              className="mr-[0.75em] z-0"
              onClick={props.handleCalendarToggle}
            >
              <Calendar />
            </div>
          </div>
        </div>
      )}

      {openFilter && (
        <MobileFilter
          title={`Filter ${props.modules} by`}
          categories={props.categories}
          onApply={props.onSubmit}
          setOpenFilter={setOpenFilter}
          clearAllFilters={props.clearAllFilters}
        />
      )}
    </div>
  );
};

export default MobileHead;
