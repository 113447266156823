import React from "react";

const ExpandLeft = () => {
  return (
    <>
      <svg
        className="ml-[-8px]"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17.5 7L10.5 14L17.5 21" stroke="#33363F" strokeWidth="2" />
      </svg>
    </>
  );
};

export default ExpandLeft;
