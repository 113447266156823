import { create } from "zustand";
import { IProfile, ISetReminder, IUpdateProfile } from "../@types";
import {
  BUSINESS_TYPE_RETAIL,
  STORAGE_USER_DATA,
  ROLE,
  BANK_ACCOUNTS,
  BUDGET,
  REMINDER,
} from "../constants";
import { BankData, BusinessType, UserDetail } from "../types";
import { subUserModule } from "../modules/subUserModule";
import { createBankAccount, updateProfile } from "../backend-services";

interface UserState {
  roles: ("Accountant" | "Admin" | "Operator")[];
  user: any;
  users: UserDetail[];
  firstLogin: boolean;
  setFirstLogin: (status: boolean) => void;
  setRole: (role: any) => void;
  setUser: (user: any) => void;
  isPasswordChanged: boolean;
  updateUserPasswordChangedFlag: (
     userId: string,
     value: boolean,
  ) => Promise<void>;
}
export const useUserStore = create<UserState>((set, get) => {
  const parseJSON = (item: string | null, defaultValue: any) => {
    try {
      return item ? JSON.parse(item) : defaultValue;
    } catch (e) {
      console.error("Error parsing JSON from local storage:", e);
      return defaultValue;
    }
  };
  const budget = parseJSON(localStorage.getItem(BUDGET), {});
  const roles = [parseJSON(localStorage.getItem(ROLE), [])];
  const userBankAccounts = parseJSON(localStorage.getItem(BANK_ACCOUNTS), []);
  const reminder = parseJSON(
    localStorage.getItem(REMINDER),
    null,
  ) as ISetReminder | null;

  return {
    user: {
      businessName: "",
      email: "",
      phone: "",
      address: "",
      id: "",
      firstName: "",
      lastName: "",
      brandLogo: null,
      logoUrl: "",
      state: "",
      city: "",
      code: "",
      industry: "",
      brandColor: "",
      bankAccounts: [],
    },
    firstLogin: true,
    roles,
    userBankAccounts,
    reminder,
    locale: localStorage.getItem("locale") || "en",
    brandColor: "#000000",
    users: [],
    isPasswordChanged: false,
    hasReminder: reminder !== null,
    setUser: (user: IProfile & { logoBase64?: string }) => {
      const hasLogoData = user.logoBase64 !== undefined;
      const updatedUser = { ...get().user, ...user };
      if (hasLogoData) updatedUser.logoBase64 = user.logoBase64;
      localStorage.setItem(STORAGE_USER_DATA, JSON.stringify(updatedUser));
      set({ user: updatedUser });
    },

  

    setFirstLogin: (status: boolean) => {
      const updatedUser = { ...get().user, firstLogin: status };
      localStorage.setItem(STORAGE_USER_DATA, JSON.stringify(updatedUser));
      set({ user: updatedUser, firstLogin: status });
    },

    isRetailBusiness: () => {
      const { user } = get();
      return user?.businessType === BUSINESS_TYPE_RETAIL;
    },


    setRole: (roles) => {
      if(roles) {
        localStorage.setItem(ROLE, JSON.stringify(roles));
        set({ roles });
      }
    },


    updateBusinessType: async (type: BusinessType) => {
      try {
        await updateProfile({ businessType: type });
        const updatedUser = { ...get().user, businessType: type };
        set({ user: updatedUser });
      } catch (err) {
        throw err;
      }
    },

    updateUserPasswordChangedFlag: async (userId, value: boolean) => {
      try {
        await subUserModule.updateUser(userId, { firstPasswordChanged: value });
        const { user } = get();
        if (user.id === userId) {
          const updatedUser = { ...user, firstPasswordChanged: value };
          set({
            user: updatedUser,
            isPasswordChanged: value,
          });
          localStorage.setItem(STORAGE_USER_DATA, JSON.stringify(updatedUser));
        }
      } catch (error) {
        throw new Error("Error updating firstPasswordChanged flag");
      }
    },
    updateProfile: async (profileData: IUpdateProfile) => {
      try {
        await updateProfile(profileData);
        const updatedUser = { ...get().user, ...profileData };
        set({ user: updatedUser });
      } catch (err) {
        throw err;
      }
    }
  };
});


