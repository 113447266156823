import { getExpense } from "../../backend-services";
import toast from "react-hot-toast";
import React from "react";
import { ExpenseCategory, ICreateExpenseData } from "../../@types";
import { Annotation } from "../../@types/annotation";

export const useExpense = (expenseId: string) => {
  const [expense, setExpense] = React.useState<ICreateExpenseData>({
    billedTo: "",
    companyId: "",
    description: "",
    id: "",
    customerId: "",
    //  type: 'non-billable',
    category: "" as ExpenseCategory,
    currency: "",
    date: "",
    lineItems: [],
    notes: "",
    vendorName: "",
    additionalInformation: "",
    amount: 0,
    paymentMethod: "",
    reference: "",
    createdAt: 0,
    isBillable: false,
    isProtected: false,
    exchangeRate: 0,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [annotations, setAnnotations] = React.useState<Annotation[]>([]);

  React.useEffect(() => {
    const fetchExpense = async () => {
      setIsLoading(true);
      try {
        const expense = await getExpense(expenseId);
        setExpense(expense);
        if (expense?.metadata) setAnnotations(expense?.metadata?.annotations);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchExpense();
  }, [expenseId]);

  return { expense, isLoading, annotations };
};
