export const durationOptions = [
  { value: "30min", label: "30 min" },
  { value: "1hour", label: "1 hr" },
  { value: "1hr 30min", label: "1hr 30min" },
  { value: "2hours", label: "2h" },
  { value: "2h 30min", label: "2h 30min" },
  { value: "3hours", label: "3h" },
];

export const optionStatus = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

export const optionStyles = {
  Active: {
    color: "#4CAF50",
    backgroundColor: "#C9DAC8",
    fontSize: "14px",
    fontWeight: "500",
    padding: "4px 8px",
    borderRadius: "4px", 
  },
  Inactive: {
    color: "#4E4E4E",
    backgroundColor: "#CCCCCC",
    fontSize: "14px",
    fontWeight: "500",
    padding: "4px 8px",
    borderRadius: "4px", 
  },
};

export const SERVICE_STATUS = [
  { value: "Active", label: "Active" },
  { value: "Pending", label: "Pending" },
  { value: "Inactive", label: "Inactive" },
];


