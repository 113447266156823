import React from "react";
const RemoooveIcon = ({ size = "24" }) => {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_52455_95388)">
          <path d="M3.75 9.22656H16.25V10.7891H3.75V9.22656Z" fill="#344054" />
          <path
            d="M10 0.625904C7.43126 0.545019 4.93541 1.48685 3.06048 3.24459C1.18556 5.00233 0.0848356 7.43229 0 10.0009C0.0848356 12.5695 1.18556 14.9995 3.06048 16.7572C4.93541 18.515 7.43126 19.4568 10 19.3759C12.5687 19.4568 15.0646 18.515 16.9395 16.7572C18.8144 14.9995 19.9152 12.5695 20 10.0009C19.9152 7.43229 18.8144 5.00233 16.9395 3.24459C15.0646 1.48685 12.5687 0.545019 10 0.625904ZM10 17.8134C7.84646 17.8912 5.74976 17.113 4.16855 15.6489C2.58734 14.1848 1.65033 12.1541 1.5625 10.0009C1.65033 7.84775 2.58734 5.81703 4.16855 4.35294C5.74976 2.88886 7.84646 2.1106 10 2.1884C12.1535 2.1106 14.2502 2.88886 15.8314 4.35294C17.4127 5.81703 18.3497 7.84775 18.4375 10.0009C18.3497 12.1541 17.4127 14.1848 15.8314 15.6489C14.2502 17.113 12.1535 17.8912 10 17.8134Z"
            fill="#344054"
          />
        </g>
        <defs>
          <clipPath id="clip0_52455_95388">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
export default RemoooveIcon;
