import React from "react";
import { ResetPasswordView } from "../../components/auth";

interface Props {
  children?: any;
}

export const ResetPasswordPage: React.FC<Props> = ({ children }) => {
  return <ResetPasswordView />;
};
