export const validateForm = (name: string, value: string, formData: any) => {
  const errors: any = {};

  // Example validation rules for email
  if (name === "email" || !name) {
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = "Email is invalid";
    }
  }

  // Validation rules for password
  if (name === "createPassword" || !name) {
    if (!formData.createPassword) {
      errors.createPassword = "Password is required";
    } else if (formData.createPassword.length < 8) {
      errors.createPassword = "Password must be at least 8 characters";
    } else if (!/[A-Z]/.test(formData.createPassword)) {
      errors.createPassword =
        "Password must contain at least one uppercase letter";
    } else if (!/[a-z]/.test(formData.createPassword)) {
      errors.createPassword =
        "Password must contain at least one lowercase letter";
    } else if (!/[0-9]/.test(formData.createPassword)) {
      errors.createPassword = "Password must contain at least one number";
    } else if (!/[!@#$%^&*]/.test(formData.createPassword)) {
      errors.createPassword =
        "Password must contain at least one special character (e.g., @, #, $, %, &, *)";
    } else if (formData.createPassword !== formData.confirmPassword) {
      errors.createPassword = "Passwords must match";
    }
  }

  return errors;
};

export const validateInfoForm = (
  name: string,
  value: string,
  formData: any,
  validationResults: any,
) => {
  const errors = {
    ...validationResults,
    [name]: "",
  };

  if (name === "industry") {
    if (!value) {
      errors.industry = "Industry is required";
    }
  }

  if (name === "firstName") {
    if (!value) {
      errors.firstName = "First Name is required";
    }
  }
  if (name === "lastName") {
    if (!value) {
      errors.lastName = "Last Name is required";
    }
  }
  if (name === "businessName") {
    if (!value) {
      errors.businessName = "Business Name is required";
    }
  }

  if (name === "address") {
    if (!value) {
      errors.address = "Address is required";
    }
  }
  if (name === "state") {
    if (!value) {
      errors.state = "State is required";
    }
  }
  if (name === "city") {
    if (!value) {
      errors.city = "City is required";
    }
  }

  return errors;
};
