import React from "react";

const AnnotationIcon = ({ color = "#292D32" }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.571429"
        y="0.571429"
        width="30.8571"
        height="30.8571"
        rx="4"
        fill="white"
      />
      <rect
        x="0.571429"
        y="0.571429"
        width="30.8571"
        height="30.8571"
        rx="4"
        stroke="#EAECF0"
        stroke-width="1.14286"
      />
      <circle
        cx="15.998"
        cy="15.9989"
        r="7.61905"
        stroke="#344054"
        stroke-width="1.37143"
      />
      <path
        d="M16 12.9512V15.9988L17.5238 17.5226"
        stroke="#344054"
        stroke-width="1.37143"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AnnotationIcon;
