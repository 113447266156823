import React from "react";
import cx from "classnames";
import { CloseIcon, CloseIconBig } from "../../../assets/icons";
import { Button } from "../../common";

interface ProtectedExpenseModalProps {
  setIsOpenModal: (open: boolean) => void;
  handleProtectedChange: () => void;
}

const ProtectedExpenseModal = ({
  handleProtectedChange,
  setIsOpenModal,
}: ProtectedExpenseModalProps) => {
  return (
    <>
      <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh]">
        <div className="hidden md:flex justify-center items-center h-full w-full">
          <div
            className={`overflow-y-auto overflow-x-hidden fixed md:top-[25%] w-full z-50  md:w-[440px] lg:w-[500px] px-4 md:px-8 pb-8 pt-4 bg-white rounded-t-[8px] md:rounded-[8px]`}
          >
            <div className="flex justify-end w-full">
              <div
                className="cursor-pointer flex justify-center items-center w-11 h-11 bg-[#F4F7FF] pr-[1em]"
                onClick={() => setIsOpenModal(false)}
              >
                <CloseIcon />
              </div>
            </div>
            <div className="mt-10">
              <p className="text-[25px] font-semibold leading-[30px] text-[#000000]">
                Protected Expense
              </p>
              <p className="text-[16px] leading-[22.4px] mt-[32px] mb-[28px]">
                Marking your expense as protected ensures that only admins can
                view this expense. Are you sure you want to mark expense as
                protected?
              </p>
            </div>

            <div className="flex w-full gap-6">
              <Button
                onClick={() => setIsOpenModal(false)}
                outline
                variant="secondary"
                text="Cancel"
                font="semibold"
              />
              <Button
                onClick={handleProtectedChange}
                variant="secondary"
                text="Mark as protected"
                font="semibold"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div
        className={cx({
          "md:hidden fixed top-0 right-0 bottom-0 left-0 z-50 overflow-y-auto overflow-x-hidden items-start flex justify-end bg-gray-900 bg-opacity-50 h-full":
            true,

          "blur-background": true,
        })}
      >
        <div className="md:hidden h-[400px] rounded-t-[32px] bg-white z-10 w-full absolute bottom-0 p-8">
          <div className="flex justify-between items-center">
            <p className="text-[22px] 360:text-[25px] font-bold leading-[30px] text-[#000000]">
              Protected Expense
            </p>
            <div onClick={() => setIsOpenModal(false)}>
              <CloseIconBig color="#FB0303" />
            </div>
          </div>
          <p className="text-[14px] leading-[19.6px] font-medium mt-[35px] mb-[43px]">
            Marking your expense as protected ensures that only admins can view
            this expense. Are you sure you want to mark expense as protected?
          </p>
          <div className="flex justify-center">
            <div className="w-[302px] space-y-3">
              <Button
                onClick={handleProtectedChange}
                variant="secondary"
                text="Yes, Mark as protected"
                size="full"
              />
              <Button
                onClick={() => setIsOpenModal(false)}
                outline
                variant="secondary"
                text="Cancel"
                size="full"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProtectedExpenseModal;
