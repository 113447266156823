import { notificationService } from "../services";
import { toast } from "react-hot-toast";
import { handleError, withRetry } from "../utils";

export const getNotifications = async () => {
  return withRetry(async () => {
    const res = await notificationService.get("/notifications/unread");
    return res.data;
  }).catch(handleError);
};

export const updateNotifications = async (notifications: { ids: string[] }) => {
  return withRetry(async () => {
    const res = await notificationService.post("/notifications", notifications);
    return res.data;
  }).catch(handleError);
};
