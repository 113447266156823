import * as React from "react";
import { EmptyIcon } from "../../../assets/icons/NotificationsEmptyIcon";
import { useLocation } from "react-router-dom";

const Empty = () => {
  const location = useLocation();
  let text = "";

  if (location.pathname.startsWith("/customers/")) {
    const pathSegments = location.pathname.split("/");
    const customerId = pathSegments[2];
    if (pathSegments.length === 4 && pathSegments[3] === "view") {
      text = `You do not have any invoices. Add an invoice to start.`;
    } else {
      const type = location.pathname.includes("invoice")
        ? "invoice"
        : location.pathname.includes("expense")
          ? "expense"
          : location.pathname.includes("customer")
            ? "customer"
            : undefined;
      text = type
        ? type === "customer"
          ? `You do not have any ${type}s. Add a ${type} to start.`
          : `You do not have any ${type}s. Create an ${type} to view data.`
        : "";
    }
  }

  return (
    <div className="mt-[1em]">
      <div className="w-full bg-white p-24 pt-10 flex flex-col items-center justify-center gap-12">
        <EmptyIcon />
        <div className="flex items-center justify-center ">
          <p className="font-medium text-black">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default Empty;
