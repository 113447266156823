import "intl";
import "intl/locale-data/jsonp/en";

export const formatMoney = (
  amount: string | number | null | undefined,
  currencyCode: string = "NGN",
  raw?: boolean,
) => {
  const parsedAmount =
    amount === null || amount === undefined
      ? 0
      : typeof amount === "string"
        ? Number(amount)
        : amount;
  const fixedAmount = isNaN(parsedAmount) ? 0 : parsedAmount.toFixed(2);
  const parts = fixedAmount.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const currency = `${currencyCode} ${parts.join(".")}`;

  if (raw) {
    return currency;
  }

  let currencySymbol;
  switch (currencyCode) {
    case "N":
      currencySymbol = "N";
      break;
    case "NGN":
      currencySymbol = "\u20A6";
      break;
    case "EUR":
      currencySymbol = "€";
      break;
    case "GBP":
      currencySymbol = "£";
      break;
    case "USD":
      currencySymbol = "$";
      break;
    default:
      currencySymbol = currencyCode;
  }

  const formattedCurrency = `${currencySymbol}${parts.join(".")}`;

  return formattedCurrency.replace(currencyCode, currencySymbol);
};

export const formatValue = (value: number) => {
  return value === 0 ? "00" : value.toString();
};
