import React from "react";

interface BlueInvoiceIconProps {
  color?: string;
}

const BlueInvoiceIcon: React.FC<BlueInvoiceIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width="14"
        height="20"
        viewBox="0 0 14 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 19V1L10 3L7 1L4 3L1 1V19L4 17.5L7 19L10 17.5L13 19Z"
          stroke="#044E97"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M5 7H9"
          stroke="#044E97"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M5 13H9"
          stroke="#044E97"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M5 10H9"
          stroke="#044E97"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </>
  );
};
export { BlueInvoiceIcon };
