import React, { useState, useMemo } from "react";
import _ from "lodash";
import { handleError, withRetry } from "../utils";

export type ApiResponseMapper<T, R extends any[]> = (response: T) => R;

interface SearchOptions<T, R extends any[]> {
  service: any;
  endpoint: string;
  queryParameter: string;
  responseMapper: ApiResponseMapper<T, R>;
  searchFunction?: (searchText: string) => R;
}

export const useSearch = <T, R extends any[]>({
  service,
  endpoint,
  queryParameter,
  responseMapper,
  searchFunction,
}: SearchOptions<T, R>) => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchedDone, setSearchedDone] = useState(false);
  const [data, setData] = useState<R[]>([]);

  const debouncedSearch = useMemo(
    () =>
      _.debounce(async (text: string) => {
        try {
          setIsSearching(true);
          const res = await withRetry(async () => {
            return service.get(`/${endpoint}?${queryParameter}=${text}`);
          }).catch(handleError); // Handle errors

          if (res) {
            const mappedData = responseMapper(res.data);
            setData(mappedData);
          }

          setIsSearching(false);
          setSearchedDone(true);
        } catch (e: any) {
          setIsSearching(false);
        }
      }, 2000),
    [service, endpoint, queryParameter, responseMapper, searchFunction],
  );

  const handleSearch = (text: string) => {
    if (!text || text.length < 0) return;
    debouncedSearch(text);
  };

  return { data, isSearching, setSearchedDone, searchedDone, handleSearch };
};
