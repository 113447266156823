import React from "react";
import dayjs from "dayjs";
import { ICreateExpenseData } from "../../@types";
import { DownloadIcon } from "../../assets/icons";
import { CheckboxComponent } from "../core/Forms";
import InfoIcon from "../../assets/icons/InfoIcon";
import ArrowRight from "../../assets/icons/ArrowRight";
import ArrowLeft from "../../assets/icons/ArrowLeft";
import { useTranslation } from "react-i18next";
interface expenseDetailsProp {
  expense: ICreateExpenseData;
  isBillable: boolean;
  isProtected: boolean;
  downloading: boolean;
  handleIsProtectedChange: () => void;
  handleDownloadClick: () => void;
}
export const ExpenseDetails: React.FC<expenseDetailsProp> = ({
  expense,
  isBillable,
  isProtected,
  downloading,
  handleIsProtectedChange,
  handleDownloadClick,
}) => {
  const { t } = useTranslation("expenses");
  return (
    <>
      <div className="hidden md:block p-8 bg-[#ffffff] shadow-md shadow-color-gray-200 rounded-[8px]">
        <div className="sm:flex md:gap-[4.4%]">
          <div className="flex gap-12">
            <div className=" flex flex-col gap-8 whitespace-nowrap">
              <p className="font-semibold text-[#6A32C6] text-[20px] leading-6">
                {dayjs(expense?.date).format("MM/DD/YYYY")}
              </p>
              <div>
                <h3 className="font-medium text-[#808080] md:text-[18px] leading-6">
                  {t("budget.expenseAmount")}
                </h3>
                <p className="font-semibold text-[#E02523] leading-[21.6px] md:text-[26px] text-[16px] mt-4">
                  {`₦${expense?.amount?.toLocaleString()}`}
                </p>
              </div>
            </div>

            <div className="h-[185px] border border-[#E2E2E2]" />
          </div>

          <div className="w-full flex justify-between font-medium">
            <div className="space-y-8">
              <div>
                <h3 className="text-[#808080] md:text-[16px] leading-5">
                  {t("budget.vendor")}
                </h3>
                <p className="text-[#212121] leading-5 md:text-[18px] mt-3">
                  {expense.vendorName}
                </p>
              </div>
              <div>
                <h3 className="text-[#808080] md:text-[16px] leading-5">
                  Expense {t("budget.category")}:
                </h3>
                <p className="text-[#212121] leading-5 md:text-[18px] mt-3">
                  {expense?.category}
                </p>
              </div>
            </div>
            <div className="space-y-8">
              <div>
                <h3 className="text-[#808080] md:text-[16px] leading-5">
                  {t("budget.paymentMethod")}:
                </h3>
                <p className="text-[#212121] leading-5 md:text-[18px] mt-3">
                  {expense?.paymentMethod}
                </p>
              </div>
              <div>
                <h3 className="text-[#808080] md:text-[16px] leading-5">
                  {t("budget.billedTo")}
                </h3>
                <p className="text-[#212121] leading-5 md:text-[18px] mt-3">
                  {expense?.customerName ? expense?.customerName : "N/A"}
                </p>
              </div>
            </div>
            <div className="space-y-8">
              <div>
                <h3 className="text-[#808080] md:text-[16px] leading-5">
                  {t("budget.note")}
                </h3>
                <p className="text-[#212121] leading-5 md:text-[18px] mt-3">
                  {expense?.notes ? expense?.notes : "N/A"}
                </p>
              </div>

              {expense?.receiptUrl && (
                <div>
                  <h3 className="text-[#808080] md:text-[16px] leading-5">
                    Attachment
                  </h3>
                  <div className="flex gap-6 text-[#044E97] mt-3">
                    <a target="_blank" href={expense?.receiptUrl}>
                      <p>View Receipt</p>
                    </a>

                    <div
                      onClick={handleDownloadClick}
                      className="flex items-center justify-center rounded-[4px] gap-1 w-[113px] h-[27px] "
                    >
                      <p>
                        {downloading ? (
                          "Downloading..."
                        ) : (
                          <div>
                            Download
                            {/* <DownloadIcon /> */}
                          </div>
                        )}
                      </p>
                      <DownloadIcon />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="bg-[#F3FBFB] w-[438px] p-3 rounded-[8px] border border-[#044E9733] border-opacity-10 mt-8 flex items-center justify-between ">
          <div className="flex gap-2 items-center cursor-pointer">
            <span> Mark As Protected Expense</span>
            <InfoIcon color="#404040" />
          </div>

          <div
            className={`w-16 h-8 p-1 rounded-[69.33px] border-[0.53px] border-[#044E97] flex items-center transition duration-200 ease-in ${
              isProtected
                ? "bg-[#044E97] justify-end"
                : "bg-[#ffffff] justify-start"
            }`}
          >
            <div
              onClick={handleIsProtectedChange}
              className={`${
                isProtected ? "bg-[#ffffff]" : "bg-[#044E97]"
              } cursor-pointer w-6 h-6 rounded-full flex justify-center items-center`}
            >
              {isProtected ? <ArrowLeft /> : <ArrowRight />}
            </div>
          </div>
        </div>
      </div>

      {/* mobile view */}
      <div className="md:hidden bg-[#ffffff] border border-[#E4EAED] rounded-[4px] p-4 w-full text-[#000000]">
        <div className="flex justify-between">
          <div className="flex flex-col gap-5">
            <div className="">
              <h3 className="text-[12px] leading-[14.4px]">
                {t("budget.vendor")}
              </h3>
              <p className="font-semibold text-[15px] leading-[18px] mt-[6px]">
                {" "}
                {expense?.vendorName}
              </p>
            </div>
            <div className="">
              <h3 className="text-[12px] leading-[14.4px]">
                Expense {t("budget.category")}:
              </h3>
              <p className="font-semibold text-[15px] leading-[18px] mt-[6px]">
                {expense?.category}
              </p>
            </div>
            <div className="">
              <h3 className="text-[12px] leading-[14.4px]">
                {t("budget.note")}
              </h3>
              <p className="font-semibold text-[15px] leading-[18px] mt-[6px]">
                {expense?.notes ? expense?.notes : "N/A"}
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-5">
            <div className="">
              <h3 className="text-[12px] leading-[14.4px]">
                {t("budget.paymentMethod")}:
              </h3>
              <p className="font-semibold text-[15px] mt-[6px]">
                {expense?.paymentMethod}
              </p>
            </div>
            <div className="">
              <h3 className="text-[12px] leading-[14.4px]">
                {t("budget.billedTo")}
              </h3>
              <p className="font-semibold text-[15px] leading-[18px] mt-[6px]">
                {expense?.customerName ? expense?.customerName : "N/A"}
              </p>
            </div>

            {expense?.receiptUrl && (
              <div className="">
                <h3 className="text-[12px] leading-[14.4px]">Attachment</h3>
                <a
                  target="_blank"
                  href={expense?.receiptUrl}
                  className="font-semibold text-[#044E97] text-[14px] leading-5 tracking-wide whitespace-nowrap flex items-center gap-[2px]"
                >
                  <span>Download</span>
                  <DownloadIcon />
                </a>
              </div>
            )}
          </div>
        </div>

        <div className="bg-[#F3FBFB] w-full p-3 rounded-[8px] border border-[#044E9733] border-opacity-10 mt-8 flex items-center justify-between whitespace-nowrap text-[12px] 360:text-[13px] 390:text-[14px]">
          <div className="flex gap-2 items-center cursor-pointer">
            <span> Mark As Protected Expense</span>
            <InfoIcon color="#404040" />
          </div>

          <div
            className={`w-16 h-8 p-1 rounded-[69.33px] border-[0.53px] border-[#044E97] flex items-center transition duration-200 ease-in ${
              isProtected
                ? "bg-[#044E97] justify-end"
                : "bg-[#ffffff] justify-start"
            }`}
          >
            <div
              onClick={handleIsProtectedChange}
              className={`${
                isProtected ? "bg-[#ffffff]" : "bg-[#044E97]"
              } cursor-pointer w-6 h-6 rounded-full flex justify-center items-center`}
            >
              {isProtected ? <ArrowLeft /> : <ArrowRight />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
