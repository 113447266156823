import React, { useLayoutEffect } from "react";
import { ICreateSalesData } from "../../../@types";
import { useLocation, useParams } from "react-router-dom";
import InvoiceFreeform from "../../../components/Sales/invoice/InvoiceFreeform";

const InvoiceFreeformPage = () => {
  const { expenseId } = useParams<{ expenseId: string }>();
  const location = useLocation();
  const [invoiceDataState, setInvoiceDataState] =
    React.useState<ICreateSalesData>({
      additionalInformation: "",
      customerId: "",
      description: "",
      discount: 0,
      inventories: [],
      invoiceDueAt: "",
      invoiceNumber: "",
      issueDate: new Date().toISOString().substring(0, 10),
      isReoccurring: false,
      isReoccurringEndDate: "",
      isReoccurringStartDate: "",
      reoccurringGroupName: "",
      status: "pending",
      tax: 0,
      isPaymentCompleted: false,
      shouldSendEmail: true,
      exchangeRate: 0,
      currency: "",
    });

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <>
      <InvoiceFreeform
        invoiceData={invoiceDataState}
        setInvoiceData={setInvoiceDataState}
        headingText="Add Invoice"
        customerId={""}
      />
    </>
  );
};

export { InvoiceFreeformPage };
