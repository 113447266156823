import React, { useEffect } from "react";
import ExpandLeft from "../../assets/icons/ExpandLeft";
import { AddSquareIcon } from "../../assets/icons";
import EditIcon from "../../assets/icons/EditIcon";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import { DropDown, GroupButton, SidebarDrawer } from "../../components/common";
import AddSquare from "../../assets/icons/AddSquare";
import { TimeIcon } from "../../assets/icons/TimeIcon";
import ServiceDetailsTable from "../../components/services/view/ServiceDetailsTable";
import EditServicePage from "../../components/services/edit/EditServicePage";
import { DeleteServicePrompt } from "../../components/services/view/DeleteServicePrompt";
import { useParams } from "react-router-dom";
import { getService, updateService } from "../../backend-services/service";
import { Service } from "../../@types/service";
import { formatMoney } from "../../utils";
import PageLoader from "../../components/common/PageLoader";
import { DropdownComponent } from "../../components/core/Forms";
import { optionStatus } from "../../constants/service";
import { optionStyles } from "../../constants/service";
import ToggleButton from "../../components/services/view/ToggleButton";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../state";
import { Annotation } from "../../@types/annotation";
import AnnotationComponent from "../../components/common/Annotation/AnnotationComponent";
import useAnnotations from "../../hooks/annotation/useAnnotations";
import { isNotAccountant } from "../../utils/isNotAccountant";
import { useTranslation } from "react-i18next";

const ViewServicePage = () => {
  const { t } = useTranslation("services");
  const { serviceId } = useParams();
  const navigate = useNavigate();
  const { roles } = useUserStore();
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [showDeleteService, setShowDeleteService] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState("");
  const [statusColor, setStatusColor] = React.useState("");
  const [annotations, setAnnotations] = React.useState<Annotation[]>([]);
  const [serviceData, setServiceData] = React.useState<Service>({
    id: "",
    name: "",
    duration: "",
    price: [],
    description: "",
    status: "Active",
  });
  const annotationsData = useAnnotations(annotations, "Service");

  useEffect(() => {
    if (!serviceId) throw new Error("Inventory Id is missing");
    serviceDetails(serviceId);
  }, [serviceId]);

  const serviceDetails = async (id: string) => {
    setIsLoading(true);
    try {
      const res = await getService(id);
      setServiceData({
        ...serviceData,
        id: res.id,
        name: res.name,
        duration: res.duration,
        price: res.price,
        description: res.description,
        status: res.status,
      });
      if (res?.metadata) setAnnotations(res?.metadata?.annotations);
      if (res.status === "Active")
        setStatusColor("bg-[#C9DAC8] text-[#4CAF50]");
      else if (res.status === "Inactive")
        setStatusColor("bg-[#CCCCCC] text-[#4E4E4E]");
      else setStatusColor("bg-[#EBD3C2] text-[#FF6702]");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleStatus = async (field: string, value: "Active" | "Inactive") => {
    if (value === "Active") {
      setStatusColor("bg-[#C9DAC8] text-[#4CAF50]");
      setSelectedStatus(value);
    } else {
      setStatusColor("bg-[#CCCCCC] text-[#4E4E4E]");
      setSelectedStatus(value);
    }

    const data = {
      name: serviceData.name,
      duration: serviceData.duration,
      price: serviceData.price,
      description: serviceData.description,
      status: value,
    };
    if (serviceId) {
      const res = await updateService(serviceId, data);
    }
  };

  const price = serviceData.price.find((item) => item.currency === "NGN");
  const onClose = () => setShowPrompt(false);

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      {showPrompt && (
        <EditServicePage
          id={serviceId}
          onClose={onClose}
          setShowPrompt={setShowPrompt}
          fetchDetail={serviceDetails}
          serviceData={serviceData}
        />
      )}

      <div className="flex justify-between mt-11">
        <a href="/services">
          <p className="font-medium text-[16px] md:text-[20px] flex items-center gap-2 md:gap-4">
            <span className="">
              <ExpandLeft />
            </span>
            {t("service.serviceDetails")}
          </p>
        </a>
        <div className="flex items-center justify-end">
          {isNotAccountant(roles) && (
            <div className="flex items-center space-x-4 md:hidden">
              <div
                onClick={() =>
                  navigate(`/invoices/create`, { state: { serviceData } })
                }
              >
                <AddSquareIcon size="30" />
              </div>
              <div onClick={() => setShowPrompt(true)}>
                <EditIcon size="30" />
              </div>
              <div onClick={() => setShowDeleteService(true)}>
                <DeleteIcon size="30" />
              </div>
            </div>
          )}
          {isNotAccountant(roles) && (
            <div className="hidden md:flex gap-4 lg:gap-6 pr-4">
              <button className="w-[148px] lg:w-[215px] h-[48px] bg-[#ffffff] rounded-[4px] border border-[#044E97]">
                <div
                  onClick={() =>
                    navigate(`/invoices/create`, { state: { serviceData } })
                  }
                  className="text-[#044E97] font-semibold text-[17px]"
                >
                  {t("service.createInvoice")}
                </div>
              </button>
              <div className="bg-[#EDF2F5]">
                <GroupButton
                  buttons={[
                    {
                      text: t("service.edit"),
                      icon: <EditIcon />,
                      fontSize: "text-[20px]",
                      onClick: () => setShowPrompt(true),
                    },
                    {
                      text: t("service.delete"),
                      icon: <DeleteIcon />,
                      textColorClass: "text-[#FB0303]",
                      onClick: () => setShowDeleteService(true),
                      fontSize: "text-[#FB0303]",
                    },
                  ]}
                />
              </div>
            </div>
          )}
          <div className="hidden md:block lg:block  border-opacity-20 rounded-sm">
            {" "}
            <AnnotationComponent
              annotationData={annotationsData}
              type={"services"}
            />
          </div>
          <div className="lg:hidden md:hidden ">
            {" "}
            <AnnotationComponent
              annotationData={annotationsData}
              type={"services"}
            />
          </div>
        </div>
      </div>

      <div className="bg-white p-6 md:p-8 font-medium mt-8 rounded-[8px]">
        <div className="flex gap-6 items-center mb-4">
          <p className="text-[16px]">
            {serviceData.id?.slice(0, 5).toUpperCase()}
          </p>
          <div className="flex items-center gap-1">
            <p className={`text-[14px] py-1 px-2 rounded-[4px] ${statusColor}`}>
              {selectedStatus ? selectedStatus : serviceData.status}
            </p>
            {isNotAccountant(roles) && (
              <ToggleButton
                name="status"
                value={selectedStatus}
                options={optionStatus}
                setSelectedValue={setSelectedStatus}
                onChange={handleStatus}
                optionStyles={optionStyles}
                dropdownClassName="left-[-5rem] w-[98px] bg-white right-0"
              />
            )}
          </div>
        </div>
        <div className="mb-6">
          <p className="text-[24px] md:text-[32px] text-[#212121] font-semibold">
            {serviceData.name}
          </p>
        </div>
        <div className="mb-10">
          <p className="text-[14px] md:text-[16px] text-[#A4A4A4]">
            {t("service.serviceCharge")}:
          </p>
          <div className="flex items-center gap-[4rem]">
            {serviceData.price.map((item: any) => (
              <p className="text-[18px] text-[#212121] font-semibold">
                {formatMoney(item?.amount, item?.currency)}
              </p>
            ))}
          </div>
        </div>
        <div className="flex gap-3 items-center">
          <TimeIcon />
          <p className="text-[14px] md:text-[16px] text-[#212121]">{`${serviceData.duration} hour(s) Duration`}</p>
        </div>
        <div className="border border-[#A4A4A4]/40 w-full md:w-[310px] my-[24px] lg:w-full" />
        <div>
          <h4 className="text-[16px] md:text-[18px] font-semibold mb-4">
            {t("service.description")}
          </h4>
          <p className="text-[14px] md:text-[16px] font-normal leading-5 w-full lg:w-1/2">
            {serviceData.description}
          </p>
        </div>
      </div>

      {showDeleteService && (
        <div>
          <SidebarDrawer
            title={""}
            show={showDeleteService}
            position={"right"}
            onClose={() => setShowDeleteService(false)}
          >
            <DeleteServicePrompt
              id={serviceId}
              setShowPrompt={setShowDeleteService}
            />
          </SidebarDrawer>
        </div>
      )}
    </>
  );
};

export { ViewServicePage };
