import React from "react";
import toast from "react-hot-toast";
import { on } from "stream";

interface ConvertQuoteConfirmationPromptProps {
  onConvert: any;
  onClose?: () => void;
  showConvertPrompt: boolean;
}

const ConvertQuoteConfirmationPrompt: React.FC<
  ConvertQuoteConfirmationPromptProps
> = ({ onConvert, onClose }) => {
  const handleConvertQuote = async (e: React.MouseEvent<HTMLButtonElement>) => {
    onConvert(e);
    onClose && onClose();
  };

  const handleKeepInvoice = () => {
    onClose && onClose();
  };

  return (
    <>
      <div className="bg-[white] rounded-md w-[500px]">
        <div className="flex justify-between  px-[1em] py-[1em]">
          <h1 className="hidden lg:block md:block text-[25px] font-semibold text-gray-900 text-center ">
            Convert Quote
          </h1>
          <h1 className="lg:hidden md:hidden text-[25px] font-semibold text-gray-900 text-center whitespace-nowrap">
            Convert Quote
          </h1>
        </div>
        <div className="flex justify-center mt-[13px]  px-[1em]">
          <h1 className="text-[#475467] text-[16px] font-medium">
            Are you sure you want to convert this quote to an invoice, please
            note that this action is irreversible.
          </h1>
        </div>
        <div
          className="flex mt-[3em] gap-5 justify-end pr-[1em]"
          role="form"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="flex justify-end mb-5">
            <button
              className="w-[160px] rounded-[4px] h-[55px] bg-[#044E97] text-white font-semibold "
              onClick={handleConvertQuote}
            >
              Convert to Invoice
            </button>
          </div>
          <div className="flex ml-[10px] pb-[60px]">
            <button
              className="w-[160px] rounded-[4px] h-[55px] bg-[#FFFFFF] text-[#044E97] font-semibold border border-blue-500"
              onClick={handleKeepInvoice}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { ConvertQuoteConfirmationPrompt };
