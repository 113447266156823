import React from "react";

const ExpandWhiteLeft = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15 6L9 12L15 18" stroke="white" strokeWidth="2" />
      </svg>
    </div>
  );
};

export default ExpandWhiteLeft;
