import React, { useEffect, useState } from "react";
import { ICreateSalesData, ICustomer } from "../../../../@types";
import { CloseIcon } from "../../../../assets/icons";
import { InfoCircle } from "../../../../assets/icons/InfoCircle";
import { Pen } from "../../../../assets/icons/PenIcon";
import { Tooltip } from "../../../../assets/icons/Tooltip";
import { InvoiceErrors } from "../../../../modules/validateInvoiceForm";
import { CustomerT } from "../../../../types";
import { CreateModal } from "../../../common/CreateModal";
import { MiniCard } from "../../../common/TooltipCards/miniCards";
import { CreateCustomerModal } from "../../customer/createCustomer";
import SelectCustomer from "../../customer/selectCustomer";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface CustomerInfoProps {
  handleSetCustomer: (selectedCustomer: ICustomer | null) => void;
  formData: ICreateSalesData;
  customer: ICustomer | null;
  validationResults: InvoiceErrors;
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>;
  onClearError?: () => void;
  toolTipText?: string;
}

const CustomerCreateInfo: React.FC<CustomerInfoProps> = ({
  handleSetCustomer,
  formData,
  customer,
  validationResults,
  setValidationResults,
  onClearError,
  toolTipText,
}) => {
  const [showSelectCustomer, setShowSelectCustomer] = useState(false);
  const [showCreateCustomer, setShowCreateCustomer] = useState(false);
  const location = useLocation();
  const { t } = useTranslation("invoices");
  const toggleBankModal = () => {
    setShowSelectCustomer(!showSelectCustomer);
  };

  const handleSetCustomerWrapper = (selectedCustomer: ICustomer | null) => {
    handleSetCustomer(selectedCustomer);
    if (selectedCustomer) {
      setValidationResults((prevResults) => ({
        ...prevResults,
        customerId: "",
      }));
    }
  };

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const customerId = searchParams.get("customerId");
    const customerName = searchParams.get("customerName");
    const customerPhone = searchParams.get("customerPhone");

    if (customerId && customerName && customerPhone) {
      handleSetCustomerWrapper({
        id: customerId,
        name: customerName,
        phone: customerPhone,
      } as ICustomer);
    }
  }, [location.search]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const customerId = searchParams.get("customerId");
    const customerFirstName = searchParams.get("customerFirstName");
    const customerLastName = searchParams.get("customerLastName");
    const customerPhone = searchParams.get("customerPhone");

    if (customerId && customerFirstName && customerLastName && customerPhone) {
      handleSetCustomerWrapper({
        id: customerId,
        firstName: customerFirstName,
        lastName: customerLastName,
        phone: customerPhone,
      } as ICustomer);
    }
  }, [location.search]);

  useEffect(() => {
    if (customer) {
      setValidationResults((prevResults) => ({
        ...prevResults,
        customerId: "",
      }));
    }
  }, [customer, setValidationResults]);

  return (
    <div>
      <div className="flex justify-between mt-[3em]">
        <div className="flex justify-start">
          <p
            className="xl:text-[16px] biglg:text-[16px] md:text-[14px] text-[9.8px] font-normal"
            onClick={toggleBankModal}
          >
            {t("billto")}
            {/* Bill To: */}
          </p>
          {toolTipText && (
            <div
              className="relative lg:mt-1 pl-1"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Tooltip />
              {isHovered && (
                <div className="absolute w-[204px] left-[-5.7em]">
                  <div className="flex items-center justify-center">
                    <svg
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#FFF4C6" />
                    </svg>
                  </div>
                  <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                    {toolTipText}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div
          className="px-[0.3em] cursor-pointer items-end text-right shadow-sm py-[0.2em] bg-[#F4F7FF]"
          onClick={toggleBankModal}
        >
          <Pen />
        </div>
      </div>
      <div
        className="lg:p-4 p-2 bg-[#F4F7FF] shadow-sm cursor-pointer lg:text-[14px] xl:text-[16px] text-[11px]  w-[full] lg:w-[370px] mt-2 pr-[1em]"
        onClick={toggleBankModal}
      >
        {customer ? (
          <div>
            {/* <p> */}
            {/* Use customer.name directly if it's available */}
            {/* {customer.name ? (
          <em>{customer.name}</em>
        ) : (
          <em>{`${customer.firstName || ''} ${customer.lastName || ''}`}</em>
        )}
      </p> */}
            <p>
              <em>
                {customer.name ||
                  `${customer.firstName || ""} ${customer.lastName || ""}`.trim()}
              </em>
            </p>

            <p>
              <em>
                {[customer.address, customer.city, customer.state]
                  .filter(Boolean)
                  .join(", ")}
              </em>
            </p>
            <p>
              <em>{customer.phone}</em>
            </p>
          </div>
        ) : (
          <div onClick={toggleBankModal}>
            <p>
              <em>Client Name</em>
            </p>
            <p>
              <em>Address, Town, City State</em>
            </p>
            <p>
              <em>Country</em>
            </p>
          </div>
        )}
      </div>
      {validationResults.customerId && (
        <div className="mt-2 xl:text-[14px] lg:text-[12px] biglg:text-[12px] 2xl:text-[14px] md:text-[11px] text-[9px]">
          <span className="text-red-500">{validationResults.customerId}</span>
        </div>
      )}
      {showSelectCustomer && (
        <CreateModal>
          <SelectCustomer
            handleSetCustomer={(selectedCustomer) => {
              handleSetCustomerWrapper(selectedCustomer);
              setShowSelectCustomer(false);
            }}
            formData={formData}
            customer={customer}
            onClose={() => setShowSelectCustomer(false)}
          />
        </CreateModal>
      )}

      {showCreateCustomer && (
        <CreateCustomerModal
          showSideModal={showCreateCustomer}
          setShowSideModal={setShowCreateCustomer}
          handleSetCustomer={(selectedCustomer) => {
            handleSetCustomerWrapper(selectedCustomer);
            setShowCreateCustomer(false);
          }}
        />
      )}
    </div>
  );
};

export default CustomerCreateInfo;
