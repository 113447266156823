import React from "react";

const RetailIcon2 = () => {
  return (
    <>
      <svg
        width="138"
        height="129"
        viewBox="0 0 158 149"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_47258_86200)">
          <rect
            x="44"
            y="37"
            width="64"
            height="64"
            rx="9"
            fill="white"
            shape-rendering="crispEdges"
          />
          <rect
            x="51.1289"
            y="54.3672"
            width="38.0343"
            height="24.381"
            rx="1.95048"
            fill="#909AE0"
          />
          <rect
            x="51.1289"
            y="57.2969"
            width="38.0343"
            height="5.85143"
            fill="#A5AFF5"
          />
          <rect
            x="56.9805"
            y="60.2188"
            width="38.0343"
            height="24.381"
            rx="1.95048"
            fill="#AAB4FA"
          />
          <path
            d="M59.4219 75.8281H75.5133"
            stroke="#F1F2F6"
            stroke-width="1.46286"
          />
          <rect
            x="59.4219"
            y="79.7266"
            width="6.82667"
            height="2.4381"
            fill="#044E97"
          />
          <rect
            x="59.4219"
            y="62.6641"
            width="8.77714"
            height="5.85143"
            fill="#758BFD"
          />
          <path
            d="M63.8086 62.6641V68.5155"
            stroke="#AEB8FE"
            stroke-width="0.487619"
          />
          <path
            d="M68.1992 65.5859L59.4221 65.5859"
            stroke="#AEB8FE"
            stroke-width="0.487619"
          />
          <rect
            x="68.1992"
            y="79.7266"
            width="6.82667"
            height="2.4381"
            fill="#044E97"
          />
          <rect
            x="76.9727"
            y="79.7266"
            width="6.82667"
            height="2.4381"
            fill="#044E97"
          />
          <rect
            x="85.75"
            y="79.7266"
            width="6.82667"
            height="2.4381"
            fill="#044E97"
          />
          <path
            d="M59.4219 64.125H62.3476L63.8104 65.5879"
            stroke="#AEB8FE"
            stroke-width="0.487619"
          />
          <path
            d="M68.1992 67.0469H65.2735L63.8106 65.584"
            stroke="#AEB8FE"
            stroke-width="0.487619"
          />
          <path
            d="M68.1992 64.125H65.2735L63.8106 65.5879"
            stroke="#AEB8FE"
            stroke-width="0.487619"
          />
          <path
            d="M59.4219 67.0469H62.3476L63.8104 65.584"
            stroke="#AEB8FE"
            stroke-width="0.487619"
          />
          <circle cx="95.0167" cy="59.2472" r="7.80191" fill="#AEB8FE" />
          <path
            d="M91.6016 59.2506L94.0397 61.6887L98.9158 56.8125"
            stroke="#F1F2F6"
            stroke-width="1.46286"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_47258_86200"
            x="-5.21875"
            y="0.0859394"
            width="162.438"
            height="162.438"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="12.3047" />
            <feGaussianBlur stdDeviation="24.6094" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0784314 0 0 0 0 0.0784314 0 0 0 0 0.168627 0 0 0 0.12 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_47258_86200"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_47258_86200"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default RetailIcon2;

export const RetailIconMobile = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 158 149"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_47258_86200)">
      <rect
        x="44"
        y="37"
        width="64"
        height="64"
        rx="9"
        fill="white"
        shape-rendering="crispEdges"
      />
      <rect
        x="51.1289"
        y="54.3672"
        width="38.0343"
        height="24.381"
        rx="1.95048"
        fill="#909AE0"
      />
      <rect
        x="51.1289"
        y="57.2969"
        width="38.0343"
        height="5.85143"
        fill="#A5AFF5"
      />
      <rect
        x="56.9805"
        y="60.2188"
        width="38.0343"
        height="24.381"
        rx="1.95048"
        fill="#AAB4FA"
      />
      <path
        d="M59.4219 75.8281H75.5133"
        stroke="#F1F2F6"
        stroke-width="1.46286"
      />
      <rect
        x="59.4219"
        y="79.7266"
        width="6.82667"
        height="2.4381"
        fill="#044E97"
      />
      <rect
        x="59.4219"
        y="62.6641"
        width="8.77714"
        height="5.85143"
        fill="#758BFD"
      />
      <path
        d="M63.8086 62.6641V68.5155"
        stroke="#AEB8FE"
        stroke-width="0.487619"
      />
      <path
        d="M68.1992 65.5859L59.4221 65.5859"
        stroke="#AEB8FE"
        stroke-width="0.487619"
      />
      <rect
        x="68.1992"
        y="79.7266"
        width="6.82667"
        height="2.4381"
        fill="#044E97"
      />
      <rect
        x="76.9727"
        y="79.7266"
        width="6.82667"
        height="2.4381"
        fill="#044E97"
      />
      <rect
        x="85.75"
        y="79.7266"
        width="6.82667"
        height="2.4381"
        fill="#044E97"
      />
      <path
        d="M59.4219 64.125H62.3476L63.8104 65.5879"
        stroke="#AEB8FE"
        stroke-width="0.487619"
      />
      <path
        d="M68.1992 67.0469H65.2735L63.8106 65.584"
        stroke="#AEB8FE"
        stroke-width="0.487619"
      />
      <path
        d="M68.1992 64.125H65.2735L63.8106 65.5879"
        stroke="#AEB8FE"
        stroke-width="0.487619"
      />
      <path
        d="M59.4219 67.0469H62.3476L63.8104 65.584"
        stroke="#AEB8FE"
        stroke-width="0.487619"
      />
      <circle cx="95.0167" cy="59.2472" r="7.80191" fill="#AEB8FE" />
      <path
        d="M91.6016 59.2506L94.0397 61.6887L98.9158 56.8125"
        stroke="#F1F2F6"
        stroke-width="1.46286"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_47258_86200"
        x="-5.21875"
        y="0.0859394"
        width="162.438"
        height="162.438"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12.3047" />
        <feGaussianBlur stdDeviation="24.6094" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0784314 0 0 0 0 0.0784314 0 0 0 0 0.168627 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_47258_86200"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_47258_86200"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
