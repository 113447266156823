import { toast } from "react-hot-toast";
import { ICreateInventoryData } from "../@types";
import {
  InventoryInput,
  RestockInput,
  editInventory,
} from "../@types/inventory";
import { inventoryService } from "../services";
import { handleError, withRetry } from "../utils";
const BASE = `inventories`;

export const deleteInventory = async (id: string) => {
  return withRetry(async () => {
    const res = await inventoryService.delete(`/${BASE}/${id}`);
    return res.data;
  }).catch(handleError);
};

export const deleteVariation = async (id: string) => {
  return withRetry(async () => {
    const res = await inventoryService.patch(`/${BASE}/variation/${id}`);
    return res.data;
  }).catch(handleError);
};

export const updateInventory = async (
  id: string,
  data: editInventory | { isArchived: boolean },
) => {
  return withRetry(async () => {
    const res = await inventoryService.patch(`/${BASE}/${id}`, data);
    return res.data;
  }).catch(handleError);
};

export const restockInventory = async (id: string, data: RestockInput) => {
  return withRetry(async () => {
    const res = await inventoryService.patch(`/${BASE}/restock/${id}`, data);
    return res.data;
  }).catch(handleError);
};

export const createInventory = async (data: any) => {
  return withRetry(async () => {
    const res = await inventoryService.post(`/${BASE}`, data);
    return res.data;
  }).catch(handleError);
};

export const getInventory = async (id: string) => {
  return withRetry(async () => {
    const res = await inventoryService.get(`/${BASE}/${id}`);
    return res.data;
  }).catch(handleError);
};

export const createVariationService = async (data: {
  variantName: string;
  types: string[];
}) => {
  return withRetry(async () => {
    const res = await inventoryService.post(`/${BASE}/variant`, data);
    return res?.data;
  }).catch((err: any) => {
    toast.error(err.message);
    throw new Error(err.response?.data || err.message);
  });
};

export const editVariationService = async (
  id: number,
  data: { variantName: string; types: string[] },
) => {
  return withRetry(async () => {
    const res = await inventoryService.patch(
      `/inventories/variant/${id}`,
      data,
    );
    return res.data;
  }).catch((error: any) => {
    throw new Error(error?.message || error.response?.data);
  });
};

export const deleteVariationService = async (id: number) => {
  return withRetry(async () => {
    const res = await inventoryService.delete(`/${BASE}/variant/${id}`);
    return res.data;
  }).catch((e: any) => {
    toast.error(e.message || e.response?.data);
    throw new Error(e.message || e.response?.data);
  });
};

export const removeStock = async (id: string, data: any) => {
  return withRetry(async () => {
    const res = await inventoryService.patch(
      `/${BASE}/remove-stock/${id}`,
      data,
    );
    return res.data;
  }).catch(handleError);
};

export const getInventoryReport = async (
  startDate: string,
  endDate: string,
  variation: string,
  downloadType: "csv" | "pdf",
) => {
  return withRetry(async () => {
    const res = await inventoryService.get(
      `${BASE}/report?startDate=${startDate}&endDate=${endDate}&variation=${variation}&downloadType=${downloadType}`,
    );
    return res.data;
  }).catch(handleError);
};
