import { create } from "zustand";
import { subUserModule } from "../modules/subUserModule";
import { UserDetail, UserT } from "../types";
import { IPaginationMeta } from "../@types";
import { userService } from "../services";

interface SubUserStore {
  users: UserDetail[];
  pagination: IPaginationMeta;
  isLoading: boolean;
  fetchUsers: () => Promise<void | UserDetail[]>;
  fetchUser: (userId: string) => Promise<void | UserDetail[]>;
  addUser: (userDetails: UserDetail) => Promise<void>;
  updateUser: (userId: string, userDetails: Partial<UserT>) => Promise<void>;
  disableUser: (
    userId: string,
    userStatus: { status: "DISABLED" | "ENABLED" },
  ) => Promise<void>;
  deleteUser: (userId: string) => Promise<void>;
  fetchMoreData: () => Promise<void>;
  isPasswordChanged: boolean;
  setPasswordChanged: (value: boolean) => void;
  isRootUser: boolean;
  setIsRootUser: (value: boolean) => void;
  error?: string;
}

export const useSubUserStore = create<SubUserStore>((set, get) => ({
  users: [],
  isLoading: false,
  pagination: {
    cursor: null,
    backCursor: null,
    hasMoreData: false,
    limit: undefined,
    count: undefined,
  },
  isPasswordChanged: false,
  isRootUser: false,

  // fetchUsers: async () => {
  //   try {
  //     const users = await subUserModule.fetchUsers();

  //     const isPasswordChanged = users.some((user: UserDetail) => user.firstPasswordChanged);
  //     const isRootUser = users.some((user: { isRootUser: any }) => user.isRootUser);

  //     set(() => ({
  //       users: users.sort(
  //         (a: UserDetail, b: UserDetail) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime(),
  //       ),
  //       isRootUser,
  //       isPasswordChanged,
  //     }));

  //     return users;
  //   } catch (error) {
  //     set({ error: error instanceof Error ? error.message : 'Failed to fetch user' });
  //     return [];
  //   }
  // },
  fetchUsers: async () => {
    set({ isLoading: true }); // Start loading
    try {
      const users = await subUserModule.fetchUsers();

      // Filter out root users before setting state
      const filteredUsers = users.filter(
        (user: { isRootUser: boolean }) => !user.isRootUser,
      );

      const isPasswordChanged = filteredUsers.some(
        (user: UserDetail) => user.firstPasswordChanged,
      );

      set(() => ({
        users: filteredUsers.sort(
          (a: UserDetail, b: UserDetail) =>
            new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime(),
        ),
        isPasswordChanged,
        isRootUser: false,
        isLoading: false, // Loading complete
      }));

      return filteredUsers;
    } catch (error) {
      set({
        error: error instanceof Error ? error.message : "Failed to fetch user",
        isLoading: false, // Loading complete even if an error occurs
      });
      return [];
    }
  },

  fetchUser: async (userId: string) => {
    try {
      const user = await subUserModule.getUser(userId);
      const isPasswordChanged = user.firstPasswordChanged ?? false;
      const isRootUser = user.isRootUser ?? false;

      set({
        users: [user],
        isRootUser,
        isPasswordChanged,
      });

      return [user];
    } catch (error) {
      set({
        error: error instanceof Error ? error.message : "Error fetching user",
      });
      return [];
    }
  },

  addUser: async (userDetails) => {
    try {
      await subUserModule.addUser(userDetails);
      set((state) => ({ users: [...state.users, userDetails] }));
    } catch (e: any) {
      throw new Error(e.response?.data || e.message);
    }
  },

  updateUser: async (userId, userDetails) => {
    await subUserModule.updateUser(userId, userDetails);
    set((state) => ({
      users: state.users.map((user) =>
        user.id === userId ? { ...user, ...userDetails } : user,
      ),
    }));
  },

  disableUser: async (userId, userStatus) => {
    await subUserModule.disableUser(userId, userStatus);
    set((state) => ({
      users: state.users.map((user) =>
        user.id === userId ? { ...user, status: userStatus.status } : user,
      ),
    }));
  },

  deleteUser: async (userId) => {
    await subUserModule.deleteUser(userId);
    set((state) => ({
      users: state.users.filter((user) => user.id !== userId),
    }));
  },

  fetchMoreData: async () => {
    if (!get().pagination.hasMoreData || get().isLoading) return;
    set({ isLoading: true });
    const cursor = get().pagination.cursor;
    try {
      const res = await userService.get(`/users?cursor=${cursor}`);
      set((state) => ({
        users: [...state.users, ...res.data.data],
        pagination: res.data.meta,
        isLoading: false,
      }));
    } catch (e: any) {
      set((state) => ({
        isLoading: false,
        error: e.message,
      }));
    }
  },

  setPasswordChanged: (value: boolean) => {
    set({ isPasswordChanged: value });
  },

  setIsRootUser: (value: boolean) => {
    set({ isRootUser: value });
  },
}));
