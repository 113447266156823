// import { Navigate, Outlet } from "react-router-dom";
// import { STORAGE_TOKEN } from "../constants";

// export default function Root() {
//   const token = sessionStorage.getItem(STORAGE_TOKEN);

//   return (
//     <>
//       {/* all the other elements */}
//       <div id="detail">
//         <Outlet />
//       </div>
//       {token && <Navigate to="/" />}
//     </>
//   );
// }


import { Navigate, Outlet } from "react-router-dom";
import { STORAGE_TOKEN } from "../constants";

export default function Root() {
  const token = sessionStorage.getItem(STORAGE_TOKEN);

  // If token exists, redirect the user
  if (token) {
    return <Navigate to="/" />;
  }

  return (
    <>
      {/* all the other elements */}
      <div id="detail">
        <Outlet />
      </div>
    </>
  );
}
