import React from "react";
import { useSearchParams } from "react-router-dom";
import { IInvoice } from "../../@types";
import toast from "react-hot-toast";
import { history } from "../../utils";
import _ from "lodash";
import { IPaginationMeta } from "../../@types";
import { getCustomerInvoices } from "../../backend-services";
import Empty from "../common/Table/Empty";
import CustomerTable from "../Sales/invoice/Tables/CustomerTable";

const CustomerInvoiceTable = ({ customerId, invoiceId }: any) => {
  const [invoices, setInvoices] = React.useState<IInvoice[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSearching] = React.useState(false);
  const [meta, setMeta] = React.useState<IPaginationMeta>({
    cursor: "",
    limit: 0,
    hasMoreData: false,
    count: 0,
  });
  const [params] = useSearchParams();

  React.useEffect(() => {
    fetchInvoices(params.get("page") || "1");
  }, [params]);

  const fetchInvoices = async (
    _cursor?: string | null,
    _backCursor?: string | null,
  ) => {
    setIsLoading(true);
    try {
      let url = "/invoices/customers";
      if (_cursor) {
        url += `?cursor=${_cursor}`;
      } else if (_backCursor) {
        url += `?backCursor=${_backCursor}`;
      }
      const data = await getCustomerInvoices({ customerId });
      setInvoices(data);
    } catch (error) {
      setIsLoading(false);
      toast.error("Unable to fetch customers");
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  if (invoices.length === 0) {
    return <Empty />;
  }

  return (
    <div className="w-full">
      <CustomerTable
        searchResult={[]}
        showHead={false}
        invoices={invoices}
        isLoading={isLoading}
        fetchMoreData={fetchInvoices}
        onSearchData={fetchInvoices}
        meta={meta}
      />
    </div>
  );
};

export { CustomerInvoiceTable };
