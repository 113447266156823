import React, { useState } from "react";
import { CloseIcon } from "../../../assets/icons";
import { BankData } from "../../../types";
import { SidebarDrawer } from "../../common";
import { CreateModal } from "../../common/CreateModal";
import ErrorComponent from "../../Error";
import BankAccountForm from "../../settings/bank/BankAccountForm";

interface CreateProps {
  onCreate: (data: BankData) => void;
  showSideModal: boolean;
  setShowSideModal: (show: boolean) => void;
  onClose?: () => void;
}

const CreateBank: React.FC<CreateProps> = ({
  onCreate,
  showSideModal,
  setShowSideModal,
  onClose,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [error, setError] = useState<null | string>(null);
  const handleCreate = async (data: BankData) => {
    try {
      await onCreate(data);
      setShowSideModal(false);
    } catch (error) {
      setError("An error occurred while creating the bank account.");
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setShowSideModal(false);
    onClose && onClose();
  };

  return (
    <>
      <div className="hidden md:block lg:block">
        <div className="fixed top-0 left-0 w-screen h-screen bg-opacity-80 flex items-center justify-center z-10 ">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <BankAccountForm
              initialData={{
                bankName: "",
                accountNumber: "",
                accountName: "",
                isActive: true,
                currency: "NGN",
              }}
              title="Add Bank Account"
              onSubmit={handleCreate}
              onClose={handleClose}
            />
          </div>
        </div>
      </div>

      <div className="md:hidden lg:hidden">
        {/* Modal Background */}
        <div className="relative ">
          <div className="lg:hidden fixed z-50 bg-white w-[full] h-[491px] bottom-0 right-0 left-0 rounded-t-[4em] shadow-md">
            {/* MODAL HEADER */}
            <div className="flex px-[30px] items-center justify-between">
              <div className="flex items-start justify-between p-3 mt-[1em]">
                <h3 className="text-[25px] font-normal ml-[-1em]">
                  {"Add Bank Account"}
                </h3>
              </div>

              <button
                aria-label="Close"
                className="xl:inline-flex xl:items-center rounded-lg shadow-sm py-2 pr-3 bg-[#F4F7FF] text-[black]"
                type="button"
                onClick={handleClose}
              >
                <CloseIcon />
              </button>
            </div>

            {/* MODAL BODY */}
            <div className="p-4 pt-10 w-[350px] sm:w-full ml-[1em]">
              <BankAccountForm
                initialData={{
                  bankName: "",
                  accountNumber: "",
                  accountName: "",
                  isActive: true,
                  currency: "NGN",
                }}
                title="Add Bank Account"
                onSubmit={handleCreate}
                onClose={handleClose}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateBank;
