import React, { FC, useState } from "react";
import { PageSubmenu } from "../../components/common/Submenu";
import PasswordManager from "../../components/settings/password";
import { ViewProfile } from "../../components/settings/profile/View";
import { BudgetManager } from "../../components/settings/expense";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import BankAccountManager from "../../components/settings/bank";
import Subscription from "../../components/settings/Subscription";
import { useUserStore } from "../../state";
import { isProtectedTabs } from "../../utils/isProtectedTabs";
import { TabComponent } from "../../components/settings/TabComponent";
import { SubscriptionModal } from "../../components/common/Subscription/SubscriptionModal";
import { MobileSubscriptionModal } from "../../components/common/Subscription/MobileSubscriptionModal";
import DesktopSubscriptionModal from "../../components/common/Subscription/DesktopSubscriptionModal";
import LanguageSwitcher from "../../components/settings/language";
import ReminderPrompt from "../../components/settings/invoice";

export const Settings = () => {
  const [tab, setTab] = useState<string>("password");
  const [submenuTab, setSubmenuTab] = useState<string>("password");
  const [budgetSubmenuTab, setBudgetSubmenuTab] = useState<string>("expense");
  const [bankSubmenuTab, setBankSubmenuTab] = useState<string>("bankAccount");
  const [activeTab, setActiveTab] = useState<string>("profile");
  const { roles } = useUserStore();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = window.innerWidth < 768;
  const [showSubscriptionModal, setShowSubscriptionModal] =
    React.useState(false);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const activeTab = searchParams.get("tab");
    if (activeTab) {
      setTab(activeTab);
      setActiveTab(activeTab);
    }
  }, [location]);

  const componentMapper = () => {
    switch (tab) {
      case "password":
        return (
          <PasswordManager
            submenuTab={submenuTab}
            onBack={() => setSubmenuTab("password")}
          />
        );

      case "expense":
        return (
          <BudgetManager
            // @ts-ignore
            submenuTab={submenuTab}
            budgetSubmenuTab={budgetSubmenuTab}
            onBack={() => setSubmenuTab("expense")}
          />
        );
      case "bankAccount":
        return (
          <BankAccountManager
            submenuTab={submenuTab}
            bankSubmenuTab={bankSubmenuTab}
            onBack={() => setSubmenuTab("bankAccount")}
          />
        );
      case "invoice":
        return <ReminderPrompt />;

      case "subscription":
        return (
          <Subscription
            submenuTab={submenuTab}
            bankSubmenuTab={bankSubmenuTab}
            onBack={() => setSubmenuTab("bankAccount")}
          />
        );
      case "language":
        return <LanguageSwitcher />;
    }
  };

  const handleTabClick = (newTab: string) => {
    if (isProtectedTabs(roles, newTab)) {
      navigate("/unauthorized");
    } else {
      setTab(newTab);
      setSubmenuTab("password");
      navigate(`/settings?tab=${newTab}`);
    }
  };

  const handleTabChange = (activeTab: any) => {
    setActiveTab(activeTab);
  };

  return (
    <>
      <div className="container h-full relative">
        <div className="flex justify-start font-bold lg:text-[20px] text-[18px] md:text-[20px] text-[#000000]">
          Settings
        </div>

        <div
          className="w-full mt-9
        "
        >
          <div className="text-[14px] w-full relative">
            <PageSubmenu
              activeTab={activeTab}
              tabs={[
                {
                  label: "Change Password",
                  icon: (
                    <TabComponent
                      isActive={activeTab === "password"}
                      tabName={"password"}
                    />
                  ),
                  tab: "password",
                  onClick: () => handleTabClick("password"),
                },
                {
                  label: "Bank Account",
                  icon: (
                    <TabComponent
                      isActive={activeTab === "bankAccount"}
                      tabName={"bankAccount"}
                    />
                  ),
                  tab: "bankAccount",
                  onClick: () => handleTabClick("bankAccount"),
                },
                {
                  label: "Expense Budget",
                  icon: (
                    <TabComponent
                      isActive={activeTab === "expense"}
                      tabName={"expense"}
                    />
                  ),
                  tab: "expense",
                  onClick: () => handleTabClick("expense"),
                },
                {
                  label: "Invoice",
                  icon: (
                    <TabComponent
                      isActive={activeTab === "invoice"}
                      tabName={"invoice"}
                    />
                  ),
                  tab: "invoice",
                  onClick: () => handleTabClick("invoice"),
                },
                {
                  label: "Subscription",
                  icon: (
                    <TabComponent
                      isActive={activeTab === "subscription"}
                      tabName={"subscription"}
                    />
                  ),
                  tab: "subscription",
                  onClick: () => handleTabClick("subscription"),
                },
                {
                  label: "Languages",
                  icon: (
                    <TabComponent
                      isActive={activeTab === "language"}
                      tabName={"language"}
                    />
                  ),
                  tab: "language",
                  onClick: () => handleTabClick("language"),
                },
              ]}
              onTabChange={handleTabChange}
            />
            <hr className="absolute bottom-0 w-full h-[1.5px] bg-[#000000] bg-opacity-[25%]" />
          </div>
          <div className="w-full mt-[2em]" />
          {tab === "profile" ? <ViewProfile /> : componentMapper()}
        </div>
      </div>

      <div className="">
        {showSubscriptionModal && (
          <SubscriptionModal>
            <MobileSubscriptionModal
              onClose={() => setShowSubscriptionModal(false)}
              titleText={`Need to access more features?`}
              subtitleText={""}
            />
          </SubscriptionModal>
        )}
      </div>
    </>
  );
};
