import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  getInvoiceReport,
  getExpenseReport,
  getCustomerReport,
  getInventoryReport,
  getProfitReport,
} from "../backend-services";
import { Reports } from "../constants/Report";

const useDownloadCsvReport = ({
  type,
  startDate,
  endDate,
  onUpdate,
  customerId,
  status,
  paymentMethod,
  vendorId,
  variation,
}: any) => {
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleApiResponse = (csvData: string) => {
    try {
      const blob = new Blob([csvData], { type: "text/csv" });
      const urlObject = URL.createObjectURL(blob);

      // Open CSV in a new tab
      window.open(urlObject, "_blank");

      // Cleanup
      URL.revokeObjectURL(urlObject);
      onUpdate({ csvData });
    } catch (error) {
      console.error("Error handling CSV data:", error);
      setErrorMessage("Error handling CSV data");
      toast.error("Error handling CSV data");
    }
  };

  const downloadInvoiceCSV = async () => {
    setIsLoadingCSV(true);
    try {
      const csvData = await getInvoiceReport(
        startDate,
        endDate,
        customerId,
        status,
        "csv",
      );
      handleApiResponse(csvData);
    } catch (error: any) {
      setErrorMessage(error.message || "Error downloading the CSV");
      toast.error(error.message || "Error downloading the CSV");
    } finally {
      setIsLoadingCSV(false);
    }
  };

  const downloadExpenseCSV = async () => {
    setIsLoadingCSV(true);
    try {
      const csvData = await getExpenseReport(
        startDate,
        endDate,
        paymentMethod,
        vendorId,
        "csv",
      );
      handleApiResponse(csvData);
    } catch (error: any) {
      setErrorMessage(error.message || "Error downloading the CSV");
      toast.error(error.message || "Error downloading the CSV");
    } finally {
      setIsLoadingCSV(false);
    }
  };

  const downloadCustomerCSV = async () => {
    setIsLoadingCSV(true);
    try {
      const csvData = await getCustomerReport(startDate, endDate, "csv");
      handleApiResponse(csvData);
    } catch (error: any) {
      setErrorMessage(error.message || "Error downloading the CSV");
      toast.error(error.message || "Error downloading the CSV");
    } finally {
      setIsLoadingCSV(false);
    }
  };

  const downloadInventoryCSV = async () => {
    setIsLoadingCSV(true);
    try {
      const csvData = await getInventoryReport(
        startDate,
        endDate,
        variation,
        "csv",
      );
      handleApiResponse(csvData);
    } catch (error: any) {
      setErrorMessage(error.message || "Error downloading the CSV");
      toast.error(error.message || "Error downloading the CSV");
    } finally {
      setIsLoadingCSV(false);
    }
  };

  const downloadProfitLossCSV = async () => {
    setIsLoadingCSV(true);
    try {
      const csvData = await getProfitReport(startDate, endDate, "pdf");
      handleApiResponse(csvData);
    } catch (error: any) {
      setErrorMessage(error.message || "Error downloading the PDF");
      toast.error(error.message || "Error downloading the PDF");
    } finally {
      setIsLoadingCSV(false);
    }
  };

  const downloadCSV = () => {
    if (type === Reports.INVOICE_REPORT) {
      return downloadInvoiceCSV();
    }
    if (type === Reports.EXPENSE_REPORT) {
      return downloadExpenseCSV();
    }
    if (type === Reports.CUSTOMER_REPORT) {
      return downloadCustomerCSV();
    }
    if (type === Reports.INVENTORY_REPORT) {
      return downloadInventoryCSV();
    }
    if (type === Reports.PROFITLOSS_REPORT) {
      return downloadProfitLossCSV();
    }
  };

  return {
    downloadCSV,
    isLoadingCSV,
    errorMessage,
  };
};

export default useDownloadCsvReport;
