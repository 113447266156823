import React, { useEffect } from "react";
import VariationCard from "./VariationCard";
import { useInventoryStore } from "../../../state/useInventoryStore";
import PageLoader from "../../common/PageLoader";
import { useTranslation } from "react-i18next";

const VariationList = ({ data, closeForm }: any) => {
  const { t } = useTranslation("inventories");
  const handleCloseForm = () => {
    closeForm();
  };

  return (
    <div className="flex flex-col items-center">
      {!data.length && !data ? (
        <>
          <p>No data Avaliable</p>
        </>
      ) : (
        data?.map((item: any, index: any) => {
          return (
            <div key={index} className="mb-[9px]">
              <VariationCard key={index} item={item} />
            </div>
          );
        })
      )}

      <div>
        <button
          style={{ fontWeight: 600 }}
          onClick={handleCloseForm}
          className="w-[215px] border border-[#044E97] text-[#044E97] text-[16px] py-[16px]"
        >
          {t("inventory.createVariation")}
        </button>
      </div>
    </div>
  );
};

export default VariationList;
