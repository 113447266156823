import React, { useState } from "react";
import { SidebarDrawer } from "../../common";
import LeftNotificationPopup from "../../core/NotificationPopup/LeftNotificationPopup";
import ProcessSuccessful from "../../../assets/icons/ProcessSuccessful";
import { deleteVariationService } from "../../../backend-services/inventory";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useInventoryStore } from "../../../state/useInventoryStore";
import { useTranslation } from "react-i18next";

interface VariationDeletePromptProps {
  showPrompt: boolean;
  show: any;
  onVariationDelete?: (variationId: any) => void;
  variationId: number;
  onClose?: () => void;
  onDeleteSuccess?: () => void;
}

const VariaionDeletePrompt = ({
  showPrompt,
  show,
  variationId,
  onVariationDelete,
  onClose,
  onDeleteSuccess,
}: VariationDeletePromptProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation("inventories");
  const [isDeleting, setIsDeleting] = useState(false);
  const [showSuccessSidebar, setShowSuccessSidebar] = React.useState(false);

  const closeSuccessSidebar = () => {
    setShowSuccessSidebar(false);
  };

  const { fetchVariation } = useInventoryStore((state) => ({
    fetchVariation: state.fetchVariation,
  }));

  const handleDeleteVariation = async (id: number) => {
    setIsDeleting(true);
    try {
      await deleteVariationService(id);
      fetchVariation();
      setIsDeleting(false);
      if (onDeleteSuccess) {
        onDeleteSuccess();
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data || error.message);
      } else if (error.request) {
        toast.error("Network error. Please try again.");
      } else {
        toast.error("Error: " + error.message);
      }
      setIsDeleting(false);
    }
  };

  const handleKeepVariation = () => {
    onClose && onClose();
  };
  return (
    <>
      <div className="h-screen">
        <div className="flex flex-col justify-center mt-[13px] ">
          <h1> {t("inventory.deleteVariationWarning")}</h1>
          <h1> {t("inventory.deleteVariationWarning2")}</h1>
        </div>
        <div role="form" onSubmit={(e) => e.preventDefault()}>
          <div className="flex ml-[10px] pt-[79px] pb-[40px]">
            <button
              className="w-[239px] rounded-[4px] h-[55px] bg-[#FB0303] text-white font-semibold"
              onClick={() => handleDeleteVariation(variationId)}
              disabled={isDeleting}
            >
              {isDeleting ? "Deleting..." : "Yes, Delete"}
            </button>
          </div>
          <div className="flex ml-[10px] pb-[60px]">
            <button
              className="w-[239px] rounded-[4px] h-[55px] bg-[#FFFFFF] text-[#044E97] font-semibold border border-blue-500"
              onClick={handleKeepVariation}
            >
              Go back to Variation
            </button>
          </div>
        </div>
      </div>
      {showSuccessSidebar && (
        <SidebarDrawer
          title=""
          show={true}
          position="left"
          onClose={closeSuccessSidebar}
        >
          <LeftNotificationPopup
            title="Expense Deleted"
            icon={<ProcessSuccessful />}
            position="left"
            showSuccessModal={true}
          />
        </SidebarDrawer>
      )}
    </>
  );
};

export default VariaionDeletePrompt;
