import React from "react";
import EditServiceForm from "./EditServiceForm";
import ExpandLeft from "../../../assets/icons/ExpandLeft";
import { CloseIcon } from "../../../assets/icons";
import { commasToNumber, convertToFloat } from "../../../utils/convertToFloat";
import { ServicePriceData } from "../../../@types/service";
import { updateService } from "../../../backend-services/service";
import { toast } from "react-hot-toast";
import cx from "classnames";
import { validateEditService } from "../../../modules/services/services";

interface EditServicePageProps {
  id?: string;
  fetchDetail: (id: string) => void;
  serviceData: any;
  onClose: () => void;
  setShowPrompt: (string: boolean) => void;
}

const EditServicePage = ({
  id,
  fetchDetail,
  serviceData,
  setShowPrompt,
  onClose,
}: EditServicePageProps) => {
  const [error, setError] = React.useState({
    name: "",
    charge: {},
  });
  const initialState = {
    name: serviceData.name,
    duration: serviceData.duration,
    description: serviceData.description,
    price: serviceData.price,
    status: serviceData.status,
  };

  const initialPrice = serviceData ? serviceData.price : [];

  const [editDetail, setEditDetail] = React.useState(initialState);
  const [price, setPrice] = React.useState<ServicePriceData[]>(initialPrice);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const editService = async () => {
    const newPrice = price.map((item: any) => ({
      ...item,
      amount: convertToFloat(item.amount),
    }));

    const { hasErrors, errors } = validateEditService({
      name: serviceData.name,
      duration: serviceData.duration,
      description: serviceData.description,
      price: newPrice,
    });

    if (hasErrors) {
      setError(errors);
    } else {
      try {
        setIsLoading(true);
        if (id) {
          const data = {
            ...editDetail,
            price: newPrice,
          };

          const res = await updateService(id, data);
          fetchDetail(id);
          setShowPrompt(false);
          toast.success("Service is Successfully Updated");
          setIsLoading(false);
        }
      } catch (e: any) {
        if (e) {
          setErrorMessage(e.message || "An error occurred");
        } else {
          setErrorMessage("An error occurred while creating inventory");
        }
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div
        aria-hidden="false"
        className="fixed top-0 right-0 left-0 z-50 h-full  overflow-y-auto overflow-x-hidden items-start justify-end flex bg-gray-900 bg-opacity-50 "
      >
        <div
          className={cx({
            "relative z-50 overflow-y-auto": true,
            "p-8 max-w-lg bg-white shadow h-[100vh]": true,
            // 'animate-slide-in': isOpen,
          })}
        >
          {/* MODAL HEADER */}
          <div className="flex items-center justify-between">
            <h3 className="text-[24px] font-medium">{"Edit Service"}</h3>

            <button
              aria-label="Close"
              className="flex items-center justify-center bg-[#F4F7FF] h-11 w-11 pr-[1em]"
              type="button"
              onClick={onClose}
            >
              <CloseIcon color="#212121" />
            </button>
          </div>
          <div className="mt-10 w-[350px]">
            <EditServiceForm
              onClick={editService}
              isLoading={isLoading}
              editDetail={editDetail}
              setEditDetail={setEditDetail}
              setPrice={setPrice}
              price={price}
              onClose={onClose}
              error={error}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditServicePage;
