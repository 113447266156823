import React, { useEffect, useState } from "react";
import { DropdownComponent, InputComponent } from "../core/Forms";
import { commasToRestockNumber } from "../../utils/convertToFloat";
import { ICustomer, IVendor, PreviewExpense } from "../../@types";
import { formatMoney } from "../../utils";
import { formattedDate } from "../../utils/formatDate";
import useExpenseDetails from "../../hooks/expenses/previewExpense/useExpenseDetails";
import useLineItems from "../../hooks/expenses/previewExpense/useLineItems";
import { Spinner } from "flowbite-react";
import { EXPENSE_CATEGORY, PAYMENT_PROVIDER_LIST } from "../../constants";
import handleSavePreviewExpense from "../../utils/expense";
import { useCustomerSearch, useVendorSearch } from "../../hooks";
import { SearchInput } from "../common";
import { CloseIcon, SearchIcon } from "../../assets/icons";
import { AddVendors } from "../vendors/Addvendor";

interface PreviewModalProps {
  expenseData: PreviewExpense | undefined;
  setPreview: (preview: boolean) => void;
}

const PreviewModal = ({ expenseData, setPreview }: PreviewModalProps) => {
  const dropDownPaymentMethod = PAYMENT_PROVIDER_LIST;
  const dropDownCategory = EXPENSE_CATEGORY;
  const [isLoading, setIsLoading] = useState(false);
  const [expense, setExpense] = React.useState<PreviewExpense | null>(null);
  const { expenseDetails, setExpenseDetails, handleExpenseDetails } =
    useExpenseDetails();
  const {
    lineItems,
    setLineItems,
    handleLineItem,
    errorLineItem,
    setErrorLineItems,
  } = useLineItems([]);
  const {
    data: customers,
    isSearching: isCustomerSearching,
    handleSearch: handleCustomerSearch,
  } = useCustomerSearch();
  const [validationResults, setValidationResults] = React.useState<any>({
    date: "",
    lineItems: [],
    category: "",
    paymentMethod: "",
  });

  const {
    data: vendors,
    isSearching: isVendorSearching,
    setSearchedDone,
    searchedDone,
    handleSearch: handleVendorSearch,
  } = useVendorSearch();

  const [showNewVendorModal, setShowNewVendorModal] =
    React.useState<boolean>(false);

  const openNewVendor = () => {
    setShowNewVendorModal(true);
  };
  const [vendorName, setVendorName] = React.useState<IVendor | undefined>();
  const [customer, setCustomer] = React.useState<ICustomer | undefined>();

  const handleSelectedVendor = (selectedVendor: IVendor | null) => {
    if (selectedVendor) {
      setVendorName(selectedVendor);
      if (selectedVendor.name) {
        setExpenseDetails((prevFormData) => ({
          ...prevFormData,
          vendorId: selectedVendor.id,
          vendor: selectedVendor.name,
        }));
      }
    } else {
      setVendorName(undefined);
      setExpenseDetails((prevFormData) => ({
        ...prevFormData,
        vendorName: "",
      }));
    }
  };

  const handleSelectCustomer = (selectedCustomer: ICustomer | null) => {
    if (selectedCustomer) {
      setCustomer(selectedCustomer);
      setExpenseDetails((prevFormData) => ({
        ...prevFormData,
        customerId: selectedCustomer.id,
      }));
    } else {
      setCustomer(undefined);
      setExpenseDetails((prevFormData) => ({
        ...prevFormData,
        customerId: "",
      }));
    }
  };

  useEffect(() => {
    setExpense(expenseData as PreviewExpense);
    if (expenseData?.lineItems) {
      setLineItems(expenseData.lineItems);
    }
  }, []);

  const handleSaveExpense = async () => {
    const updatedLineItem = lineItems.map((item, i) => ({
      ...item,
      rate: Number(item.rate),
      quantity: Number(item.quantity),
    }));

    const data = {
      vendorName: expenseDetails.vendor,
      vendorId: expenseDetails.vendorId,
      customerId: expenseDetails.customerId,
      customerName: customer
        ? `${customer?.firstName} ${customer?.lastName}`
        : "",
      category: expenseDetails.category
        ? expenseDetails.category
        : expense?.category,
      paymentMethod: expenseDetails.paymentMethod
        ? expenseDetails.paymentMethod
        : expense?.paymentMethod,
      notes: expenseDetails.notes,
      description: expenseDetails.tag,
      date: expense?.date
        ? new Date(expense.date).toISOString()
        : new Date().toISOString(),
      amount: expense?.amount ?? 0,
      isBillable: expenseDetails?.isBillable,
      currency: expense?.currency,
      lineItems: updatedLineItem,
    };
    await handleSavePreviewExpense({
      data,
      setIsLoading,
      setPreview,
      setValidationResults,
      setErrorLineItems,
    });
  };

  return (
    <>
      <div className="bg-gray-900 bg-opacity-50 fixed inset-0 z-50 flex justify-center items-center">
        <div className="w-[700px] lg:w-[76%] max-w-[1011px] bg-white p-5 lg:p-10 rounded-[12px] overflow-hidden flex flex-col max-h-[100vh]">
          <div className="overflow-y-auto">
            <p className="text-[#6A32C6] lg:text-[24px] leading-[28.8px] font-semibold">
              {formattedDate(expense?.date)}
            </p>
            <div className="mt-[24px]">
              <span className="text-[16px] leading-[19.2px]">
                Expense Amount
              </span>
              <p className="text-[#FB0303] mt-[10px] text-[25px] font-bold leading-[30px]">
                {formatMoney(expense?.total || "")}
              </p>
            </div>

            <div className="py-8 pl-8 text-[14px] bg-[#EDF2F5] mt-8">
              <div className="flex gap-8">
                <div className="w-[30%]">
                  <p>Vendor</p>
                  <SearchInput
                    placeholder="Search for Vendor"
                    data={vendors}
                    displayKey="name"
                    isLoading={isVendorSearching}
                    value={expenseDetails.vendor}
                    selected={vendorName}
                    searchedDone={searchedDone}
                    setSearchedDone={setSearchedDone}
                    onSelect={handleSelectedVendor}
                    handleChangeText={handleVendorSearch}
                    emptyMessage={"This vendor is not found"}
                    clearIcon={CloseIcon}
                    clearIconClass="absolute mr-[1.5em] md:mr-[2.2em] lg:mr-[1.5em] biglg:mr-[1.5em] xl:mr-[1em] mt-[0.75em] md:mt-[0.75em]"
                    classNames="w-full border border-gray-300 bg-white h-full min-h-[48px] placeholder:text-[15.25px] rounded  focus:outline-none focus:shadow-outline appearance-none focus:ring-0 leading-normal"
                    dropdownClassName="w-full"
                    dropdownStyle={{
                      maxHeight: "500px",
                      overflowY: "auto",
                    }}
                    searchKey={"name"}
                    icon={<SearchIcon />}
                    onClearError={() => {
                      setValidationResults((prevState: any) => ({
                        ...prevState,
                        vendorName: undefined,
                      }));
                    }}
                  />
                  {/* {validationResults?.vendorName && (
                    <div className="text-[#FB0303]">{validationResults.vendorName}</div>
                  )} */}
                </div>
                <div className="w-[30%]">
                  <p>Payment Method</p>
                  <DropdownComponent
                    name="paymentMethod"
                    options={dropDownPaymentMethod}
                    onChange={handleExpenseDetails}
                    selectedValue={
                      expenseDetails.paymentMethod
                        ? expenseDetails.paymentMethod
                        : expense?.paymentMethod
                    }
                    value={
                      expenseDetails.paymentMethod
                        ? expenseDetails.paymentMethod
                        : expense?.paymentMethod
                    }
                    dropdownClassName="w-full h-12"
                    iconClassName="mr-3"
                  />
                  <div>
                    {validationResults.paymentMethod && (
                      <span
                        className="text-red-500"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0",
                          lineHeight: "1.2",
                        }}
                      >
                        {validationResults.paymentMethod}
                      </span>
                    )}
                  </div>
                </div>
                <div className="w-[30%]">
                  <p>Notes</p>
                  <InputComponent
                    type="text"
                    handleChange={(e) =>
                      handleExpenseDetails("notes", e.target.value)
                    }
                    name="notes"
                    value={expenseDetails.notes}
                    className="h-[48px] w-full rounded"
                  />
                </div>
              </div>

              <div className="flex gap-8 mt-6">
                <div className="w-[30%]">
                  <p>Expense Category</p>
                  <DropdownComponent
                    name="category"
                    options={dropDownCategory}
                    onChange={handleExpenseDetails}
                    selectedValue={
                      expenseDetails.category
                        ? expenseDetails.category
                        : expense?.category
                    }
                    value={
                      expenseDetails.category
                        ? expenseDetails.category
                        : expense?.category
                    }
                    dropdownClassName="w-full h-12"
                    iconClassName="mr-3"
                  />
                  <div className="">
                    {validationResults.category && (
                      <span className="text-red-500 whitespace-nowrap leading-[16px] p-0">
                        {validationResults.category}
                      </span>
                    )}
                  </div>
                </div>
                <div className="w-[30%]">
                  <p>Billed to:</p>
                  <SearchInput
                    placeholder="Search"
                    data={customers.map((customer) => ({
                      ...customer,
                      display: `${customer.name} (${customer.phone})`,
                    }))}
                    displayKey="name"
                    isLoading={isCustomerSearching}
                    value={expenseDetails.customer}
                    selected={customer}
                    searchedDone={searchedDone}
                    setSearchedDone={setSearchedDone}
                    onSelect={handleSelectCustomer}
                    handleChangeText={handleCustomerSearch}
                    emptyMessage={"This customer is not found"}
                    clearIcon={CloseIcon}
                    clearIconClass="absolute mr-[1.5em] md:mr-[2.2em] lg:mr-[1.5em] biglg:mr-[1.5em] xl:mr-[1em] mt-[0.75em] md:mt-[0.75em]"
                    classNames="w-full border border-gray-300 bg-white h-full min-h-[48px] placeholder:text-[15.25px] rounded  focus:outline-none focus:shadow-outline appearance-none focus:ring-0 leading-normal"
                    dropdownClassName="w-full"
                    dropdownStyle={{
                      maxHeight: "500px",
                      overflowY: "auto",
                    }}
                    searchKey={"name"}
                    icon={<SearchIcon />}
                    onClearError={() => {
                      setValidationResults((prevState: any) => ({
                        ...prevState,
                        vendorName: undefined,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>

            <p className="mt-10 text-[20px] leading-[24px] font-bold">
              Expense Breakdown
            </p>

            <div className="mt-[25px]">
              <div className="flex justify-between pl-[24px] bg-[#044E97] py-4 text-white text-left text-[16px] font-bold leading-[28px]">
                <p className="w-[3%]">#</p>
                <p className="w-[29%]">Items/Description</p>
                <p className="w-[16%]">Quantity</p>
                <p className="w-[20%]">Rate</p>
                <p className="w-[20%]">Amount</p>
              </div>
              {lineItems?.map((item, index) => (
                <div
                  key={index + 1}
                  className={`flex ${
                    index % 2 === 0 ? "bg-white" : "bg-[#044E971A]"
                  } justify-between px-[24px] py-[18px]`}
                >
                  <div className="flex items-center justify-center">
                    <span>{index + 1}</span>
                  </div>
                  <div className="w-[27%]">
                    <InputComponent
                      className="w-full border-[0.5px] border-[#A4A4A4] rounded"
                      value={item.name}
                      name="name"
                      type="text"
                      handleChange={(e) =>
                        handleLineItem(index, "name", e.target.value)
                      }
                      error={errorLineItem[index]?.name}
                    />
                  </div>
                  <div className="w-[14%]">
                    <InputComponent
                      className="w-full border-[0.5px] border-[#A4A4A4] rounded"
                      value={commasToRestockNumber(item.quantity)}
                      name="quantity"
                      type="text"
                      handleChange={(e) =>
                        handleLineItem(index, "quantity", e.target.value)
                      }
                      error={errorLineItem[index]?.quantity}
                    />
                  </div>
                  <div className="w-[19%]">
                    <InputComponent
                      className="w-full border-[0.5px] border-[#A4A4A4] rounded"
                      value={commasToRestockNumber(item.rate)}
                      name="rate"
                      type="text"
                      handleChange={(e) =>
                        handleLineItem(index, "rate", e.target.value)
                      }
                      error={errorLineItem[index]?.rate}
                    />
                  </div>
                  <div className="w-[19%]">
                    <InputComponent
                      className="w-full border-[0.5px] border-[#A4A4A4] rounded"
                      value={
                        item.amount
                          ? commasToRestockNumber(item.amount)
                          : commasToRestockNumber(item.quantity * item.rate)
                      }
                      name="quantity"
                      type="text"
                      handleChange={(e) =>
                        handleLineItem(index, "amount", e.target.value)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-end mt-11 gap-4 text-[16px] font-semibold">
              <button
                onClick={() => setPreview(false)}
                className="border border-[#044E97] w-[239px] h-12 rounded flex justify-center items-center text-[#044E97] leading-[19.2px]"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveExpense}
                disabled={isLoading}
                className="bg-[#044E97] w-[239px] h-12 rounded flex justify-center items-center text-white text-[16px] font-semibold leading-[19.2px]"
              >
                {isLoading ? <Spinner size="sm" light={true} /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <AddVendors
        show={showNewVendorModal}
        onClose={() => setShowNewVendorModal(false)}
        onVendorCreate={handleSelectedVendor}
      />
    </>
  );
};

export { PreviewModal };
