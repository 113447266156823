import React from "react";
import { ICreateBudget } from "../../../@types";
import EditIcon from "../../../assets/icons/EditIcon";
import { formatMoney } from "../../../utils";
import { useUserStore } from "../../../state";

interface ViewBudgetProps {
  monthYear: string;
  allocatedBudget: any;
  setShowModal: (showModal: boolean) => void;
}

export const ViewBudget: React.FC<ViewBudgetProps> = ({
  monthYear,
  allocatedBudget,
  setShowModal,
}) => {
  const getMonthFromYear = (yearMonth: string) => {
    const [month, year] = yearMonth.split("-");
    const date = new Date(`${year}-${month}-01`);
    const monthWord = date.toLocaleString("en-NG", { month: "long" });
    return monthWord;
  };

  return (
    <div className="">
      <div className="h-[170px] w-full max-w-[580px] bg-[#DBE7FF] py-[26px] px-[20px] rounded flex flex-col justify-between">
        <div className="flex items-start justify-between w-full">
          <div>
            <h3 className="text-[16px] font-normal">Amount:</h3>
            <p className="font-semibold text-[16px]">
              {formatMoney(allocatedBudget)}
            </p>
          </div>
          <a
            href="#edit-modal"
            className="inline-flex justify-center items-center w-10 h-10 rounded bg-[#FFFF]"
            onClick={() => setShowModal(true)}
          >
            <EditIcon />
          </a>
        </div>
        <div>
          <h3 className="text-[16px]">Month:</h3>
          <p className="font-semibold text-[16px]">
            {getMonthFromYear(monthYear)}
          </p>
        </div>
      </div>
    </div>
  );
};
