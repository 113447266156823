import React from "react";
import { ICreateExpenseData } from "../../../@types";
import { Pen } from "../../../assets/icons/PenIcon";

interface NoteProps {
  formData: ICreateExpenseData;
  handleTextAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const ExpenseNote: React.FC<NoteProps> = ({
  formData,
  handleTextAreaChange,
}) => {
  return (
    <div>
      <div className="flex justify-start mt-[1em]">
        <p className="xl:text-[16px] biglg:text-[16px] text-[9px]">Add Note</p>
        <div className="px-[1em] cursor-pointer"></div>
      </div>
      <div className="w-full   cursor-pointer">
        <div className="">
          <textarea
            value={formData.notes ?? ""}
            className={` bg-[#F3FBFB] xl:w-full biglg:w-full lg:w-full 2xl:w-full w-full  lg:text-[14px] md:text-[12px] text-[11px] md:h-[10vh] bigmd:h-[10vh] lg:h-[10vh] biglg:h-[10vh] xl:h-[10vh] 2xl:h-[10vh] 4xl:h-[10vh]  rounded-md resize-y overflow-y-auto word-wrap break-word outline-none focus:outline-none focus:ring-0 appearance-none leading-normal no-border`}
            name="notes"
            placeholder="Add Payment terms or notes you’d like the customer to be aware of."
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              handleTextAreaChange(e)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ExpenseNote;
