import { IService } from "../../@types";
import { ServiceStatus } from "../../@types/service";
import {
  getServiceStatusClass,
  getServiceStatusColor,
} from "../../components/Sales/invoice/Status/status";
import { formatMoney } from "../format";
import { getServiceStatus } from "../service";

const shortenServiceId = (id: string) => id.substring(0, 10);

export const renderServiceData = (services: IService[]) => {
  if (!services) {
    return [];
  }

  const renderedData = services.map((service: IService) => {
    const nairaPrices = service.price
      .filter((p: any) => p.currency === "NGN")
      .map((p: any) => formatMoney(p.amount, p.currency))
      .join(", ");

    const ServicestatusCapitalized: ServiceStatus = service.status
      ? (service.status as ServiceStatus)
      : "pending";

    const statusColor = getServiceStatusColor(ServicestatusCapitalized);
    const statusClass = getServiceStatusClass(ServicestatusCapitalized);

    return {
      duration: service.duration,
      id: (
        <a className="md:text-violet-800" href={`/services/${service.id}/view`}>
          {shortenServiceId(service.id)}
        </a>
      ),
      price: nairaPrices,
      status: (
        <div
          className={`${statusClass}`}
          style={{ backgroundColor: statusColor }}
        >
          {getServiceStatus(ServicestatusCapitalized)}
        </div>
      ),
      name: service.name,
      serviceId: service.id,
    };
  });

  return renderedData;
};

export const columns = [
  { key: "id", label: "Service ID", width: 10 },
  { key: "name", label: "Services", width: 20 },
  { key: "duration", label: "Duration", width: 25 },
  { key: "status", label: "Status", width: 20 },
  { key: "price", label: "Amount", width: 25 },
];
