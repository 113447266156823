export const USER_ROLE = {
  ADMIN: "Admin" as const,
  OPERATOR: "Operator" as const,
  ACCOUNTANT: "Accountant" as const,
};

export const BANK_ACCOUNTS = "bankAccounts";

export const initialBudgetState = {
  monthYear: "",
  allocatedBudget: 0,
};
export const REMINDER = "REMINDER";
