import React, { useState, useEffect } from "react";
import { AddSquareIconSmall } from "../../../../assets/icons";
import { useUserStore } from "../../../../state";

interface LogoComponentProps {
  logoUrl: string;
  setLogoUrl: (url: string) => void;
  error?: string;
  onUploadSuccess: () => void;
}

const MAX_FILE_SIZE_KB = 100;

const LogoComponent: React.FC<LogoComponentProps> = ({
  logoUrl,
  setLogoUrl,
  error,
  onUploadSuccess,
}) => {
  const user = useUserStore((state) => state.user);
  const [localLogoUrl, setLocalLogoUrl] = useState<string | undefined>(logoUrl);
  const [localError, setLocalError] = useState<string | undefined>(error);

  useEffect(() => {
    setLocalError(error);
  }, [error]);

  useEffect(() => {
    setLocalLogoUrl(logoUrl);
  }, [logoUrl]);

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size / 1024 > MAX_FILE_SIZE_KB) {
        setLocalError(`File size exceeds ${MAX_FILE_SIZE_KB} KB`);
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setLocalError(undefined);
        const result = reader.result as string;
        setLocalLogoUrl(result);
        setLogoUrl(result);
        onUploadSuccess();
      };
      reader.readAsDataURL(file);
    }
  };

  const displayLogo = localLogoUrl || user?.logoBase64 || user?.logoUrl;

  return (
    <>
      {/* Desktop */}
      <div className="hidden lg:block md:block border-2 border-dashed rounded-md bg-[#F4F7FF] border-[#83B0DC] cursor-pointer lg:w-[197px] lg:h-[75px]">
        <input
          type="file"
          accept="image/*"
          onChange={handleLogoUpload}
          style={{ display: "none" }}
          id="logoUpload"
        />
        <label
          htmlFor="logoUpload"
          className="w-full h-full flex justify-center items-center cursor-pointer"
        >
          {displayLogo ? (
            <img
              src={displayLogo}
              alt="Logo"
              className="lg:w-[197px] lg:h-[65px] w-[71px] h-[27px] bg-white object-contain"
            />
          ) : (
            <div className="flex flex-col justify-center items-center text-center p-4">
              <span className="mt-1">
                <AddSquareIconSmall />
              </span>
              <p className="text-[#044E97] text-[12px] lg:text-[14px]">
                Upload Logo
              </p>
            </div>
          )}
        </label>
        {localError && <p className="text-red-500">{localError}</p>}
      </div>

      {/* Mobile  */}
      <div className="lg:hidden md:hidden border-2 border-dashed rounded-md bg-[#F4F7FF] border-[#83B0DC] cursor-pointer w-[105px] h-[60px]">
        <input
          type="file"
          accept="image/*"
          onChange={handleLogoUpload}
          style={{ display: "none" }}
          id="logoUpload"
        />
        <label
          htmlFor="logoUpload"
          className="w-full h-full flex justify-center items-center cursor-pointer"
        >
          {displayLogo ? (
            <img
              src={displayLogo}
              alt="Logo"
              className="lg:w-[197px] lg:h-[65px] w-[71px] h-[27px] bg-white object-contain"
            />
          ) : (
            <div className="flex flex-col justify-center items-center text-center p-4">
              <span className="mt-1">
                <AddSquareIconSmall />
              </span>
              <p className="text-[#044E97] text-[12px] lg:text-[14px]">
                Upload Logo
              </p>
            </div>
          )}
        </label>
        {localError && <p className="text-red-500">{localError}</p>}
      </div>
    </>
  );
};

export default LogoComponent;
