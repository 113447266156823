import React from "react";
import WelcomeImage, {
  WelcomeImageSmall,
} from "../../../assets/icons/welcomePage";
import { useUserStore } from "../../../state";

interface WelcomeModalProps {
  onShowMeClick: () => void;
  onClose: () => void;
  message1?: string;
  message2?: string;
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({
  onClose,
  onShowMeClick,
  message1,
  message2,
}) => {
  const { user } = useUserStore();
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg max-w-md w-full relative p-6">
        <button
          className="absolute top-2 right-2 rounded-lg shadow-sm py-2 pr-3 pl-3 bg-[#E0E3EB]"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="mt-[2em] ">
          <WelcomeImage />
        </div>

        <div className="text-center mb-6">
          <div className="flex justify-center mb-2"></div>
          <h2 className="text-[#044E97] text-[18px] font-semibold mt-3">
            Hi {user?.firstName || ""}
          </h2>
          <p className=" text-[#044E97] text-[18px] font-semibold mt-3">
            Welcome To Esemie! 🎉
          </p>
          {/* <p className="mt-2 text-sm text-[#4E4E4E]">
            <span className="text-[#044E97]"> </span>
          </p> */}
          <p className="mt-2 text-sm lg:text-[16px] text-[#4E4E4E]">
            <span className="text-[#044E97]">{message1}</span>
            {message2}
          </p>
        </div>
        <div className="text-center">
          <button
            className="hidden lg:block md:block bg-[#044E97] w-[400px] text-white px-6 py-2 rounded-md text-lg font-semibold hover:bg-blue-700"
            onClick={onShowMeClick}
          >
            Show Me
          </button>
          <button
            className="lg:hidden md:hidden bg-[#044E97] w-[200px] text-white px-6 py-2 rounded-md text-lg font-semibold hover:bg-blue-700"
            onClick={onShowMeClick}
          >
            Show Me
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;
