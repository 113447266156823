import React, { useEffect, useState } from "react";
import { subscriptions } from "../../../state";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
interface SubscriptionProps {
  subscriptionData?: subscriptions | null;
}

const SubscriptionHistoryWidget = ({ subscriptionData }: SubscriptionProps) => {
  const [subscriptionPercentage, setSubscriptionPercentage] =
    useState<number>(0);
  const { t } = useTranslation("subscription");
  useEffect(() => {
    const actualSubscriptionPercentage =
      ((subscriptionData?.daysUsed as unknown as number) /
        (subscriptionData?.daysTotal as unknown as number)) *
      100;
    setSubscriptionPercentage(
      actualSubscriptionPercentage > 100 ? 100 : actualSubscriptionPercentage,
    );
  }, [subscriptionData]);

  if (subscriptionData?.plan === undefined)
    return (
      <div className="relative flex flex-col gap-[12px] w-full lg:w-[50%]">
        <p>{t("subscription.noActive")}</p>
        <div className="relative bg-[#ffffff] w-[80%] md:w-[70%] h-[7px]" />
      </div>
    );

  return (
    <div className="relative flex flex-col gap-[12px] w-full lg:w-[50%]">
      {subscriptionData?.status == "pending" ? (
        <SkeletonTheme baseColor="#EDF2F5" highlightColor="#fff">
          <Skeleton width={"129px"} height={"12px"} />
        </SkeletonTheme>
      ) : (
        <p className="text-[16px]" style={{ fontWeight: 600 }}>
          Day {subscriptionData.daysUsed} of {subscriptionData.daysTotal} days
        </p>
      )}
      {subscriptionData?.status == "pending" ? (
        <SkeletonTheme baseColor="#EDF2F5" highlightColor="#fff">
          <Skeleton width={"50%"} height={"8px"} />
        </SkeletonTheme>
      ) : (
        <div
          className={`relative bg-[#ffffff] shadow-lg h-[7px] rounded-md w-[50%] md:w-[100%]`}
        >
          <div
            className="absolute bg-[#044E97] rounded-md h-full"
            style={{ width: `${subscriptionPercentage}%` }}
          />
        </div>
      )}
    </div>
  );
};

export default SubscriptionHistoryWidget;
