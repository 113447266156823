import React from "react";
import { formatMoney } from "../../../utils";
import { getDurationFromDate } from "../../../utils/invoicedue";

const DueInvoices = ({ invoice, key }: any) => {
  return (
    <div key={key} className="flex justify-between items-center">
      <div>
        <p className="font-medium text-[12px] md:text-[14px] leading-[20px]">{`${invoice.customer.firstName} ${invoice.customer.firstName}`}</p>
        <div className="flex items-center gap-1 mt-2">
          <div className="text-[13px] md:text-[16px] 1024:text-[13px] 1180:text-[16px] font-bold text-[#2E2E2E]">
            {formatMoney(invoice.balanceDue)}
            <span className=" ml-[4px] h-[12.5px] border border-[#00000026] border-opacity-[15%]"></span>
          </div>
          <span className="text-[11px] md:text-[14px] lg:text-[12px] font-medium leading-[20px] text-[#FB0303]">
            {getDurationFromDate(invoice.invoiceDueAt)}
          </span>
        </div>
      </div>
      {/* <div>
        <button className="text-[12px] cursor-pointer font-semibold px-[11px] py-[9px] border border-[#044E97] rounded-[4px] leading-[16px] text-[#044E97]">
          Send Reminder
        </button>
      </div> */}
    </div>
  );
};

export default DueInvoices;
