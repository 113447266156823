import React from "react";

export const ChevronRightIcon = () => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.58525 13.6959L7.79724 7.50231C7.87097 7.42858 7.92307 7.3487 7.95355 7.26268C7.98452 7.17666 8 7.08449 8 6.98618C8 6.88787 7.98452 6.7957 7.95355 6.70968C7.92307 6.62366 7.87097 6.54378 7.79724 6.47005L1.58525 0.258065C1.41321 0.0860215 1.19816 0 0.940092 0C0.682027 0 0.46083 0.092166 0.276498 0.276498C0.0921659 0.46083 0 0.675884 0 0.92166C0 1.16744 0.0921659 1.38249 0.276498 1.56682L5.69585 6.98618L0.276498 12.4055C0.104455 12.5776 0.0184331 12.7894 0.0184331 13.0411C0.0184331 13.2933 0.110599 13.5115 0.294931 13.6959C0.479262 13.8802 0.694316 13.9724 0.940092 13.9724C1.18587 13.9724 1.40092 13.8802 1.58525 13.6959Z"
        fill="#212121"
      />
    </svg>
  );
};
