import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IInventory, ItemInput } from "../../../../@types";
import {
  InventoryItem,
  VariationError,
  VariationItem,
} from "../../../../@types/inventory";
import { createInventory } from "../../../../backend-services";
import {
  validateCreateInventory,
  ValidateVariation,
} from "../../../../modules/inventory/inventory";
import { useInventoryStore } from "../../../../state/useInventoryStore";
import { convertToFloat } from "../../../../utils/convertToFloat";
import { InventoryOverlay } from "./InventoryOverlay";

interface CreateInventoryProps {
  show: boolean;
  onClose?: () => void;
  setShowCreateInventory: (show: boolean) => void;
  onCreateSuccess: (item: ItemInput) => void;
}

const CreateInventoryModal: React.FC<CreateInventoryProps> = ({
  show,
  onClose,
  setShowCreateInventory,
  onCreateSuccess,
}) => {
  const [isOpen, setIsOpen] = useState(show);
  const [error, setError] = useState({
    name: "",
    rate: "",
    costPrice: "",
    quantity: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [variationList, setVariationList] = useState<VariationItem[]>([]);
  const [inventories, setInventories] = useState<InventoryItem>({
    name: "",
    rate: "",
    costPrice: "",
    quantity: "",
    description: "",
    variations: [],
  });
  const [variationError, setVariationError] = useState<VariationError[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { fetchVariations } = useInventoryStore((state) => ({
    fetchVariations: state.fetchVariation,
  }));

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const handleCreateInventory = async () => {
    setError({
      name: "",
      rate: "",
      costPrice: "",
      quantity: "",
    });

    const newRate = convertToFloat(inventories.rate);
    const newCostPrice = convertToFloat(inventories.costPrice);
    const newQuantity = convertToFloat(inventories.quantity);

    const newVariationList = variationList.map((item) => ({
      ...item,
      price: convertToFloat(item.price),
      costPrice: convertToFloat(item.costPrice),
      quantity: convertToFloat(item.quantity),
    }));

    const { hasErrors, errors } = validateCreateInventory({
      rate: newRate,
      costPrice: newCostPrice,
      name: inventories.name,
      variationLen: newVariationList.length,
    });

    const variationErrorMsg = ValidateVariation(newVariationList);
    setVariationError([...variationErrorMsg]);

    if (hasErrors || variationErrorMsg.length > 0) {
      setError(errors);
      return;
    }

    try {
      let payload: any = {
        name: inventories.name,
      };

      if (newVariationList.length > 0) {
        payload.variations = newVariationList;
      } else {
        payload.rate = newRate || 0;
        payload.costPrice = newCostPrice || 0;
        payload.quantity = newQuantity || 0;
      }

      const response: IInventory = await createInventory(payload);

      const newItem: ItemInput = {
        id: response.id,
        name: response.name,
        rate: response.rate ?? 0,
        // costPrice: response.costPrice ?? 0,
        quantity: response.quantity ?? 0,
        variations:
          response.variations && response.variations.length > 0
            ? response.variations.map((variation) => ({
                type: variation.type || "",
                name: variation.name || "",
                id: variation.id || "",
                price:
                  typeof variation.price === "number" ? variation.price : 0,
                quantity:
                  typeof variation.quantity === "number"
                    ? variation.quantity
                    : 0,
              }))
            : undefined,
      };

      toast.success("New Inventory Added Successfully!");
      setShowCreateInventory(false);
      onCreateSuccess(newItem);
    } catch (error) {
      toast.error("Failed to create inventory");
      setShowCreateInventory(false);
      console.error("Error creating inventory:", error);
    }
  };

  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [variationState, setVariationState] = useState([]);

  const { variations, Loading, createVariation } = useInventoryStore(
    (state) => ({
      variations: state.variations,
      Loading: state.isLoading,
      createVariation: state.createVariation,
    }),
  );

  const handleFetchVariations = useCallback(() => {
    fetchVariations();
  }, [showCreateForm]);

  useEffect(() => {
    handleFetchVariations();
    setVariationState(variations);

    if (variations.length > 0) {
      setShowCreateForm(false);
    }
  }, [fetchVariations, createVariation, isSideBarOpen, setIsSideBarOpen]);

  return (
    <div>
      <InventoryOverlay
        showSidebar={true}
        showVariationList={true}
        // showCreateForm={true}
        // showTitle={false}
        // showForms={false}
        handleCreateInventory={handleCreateInventory}
        setShowCreateInventory={setShowCreateInventory}
        onCreateSuccess={onCreateSuccess}
        onclose={() => setShowCreateInventory(false)}
      />
    </div>
  );
};

export default CreateInventoryModal;
