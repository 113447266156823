import * as React from "react";

interface AccordionArrowProps {
  direction: "up" | "down";
}

const AccordionArrow: React.FC<AccordionArrowProps> = ({ direction }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{
        transform: direction === "up" ? "rotate(0deg)" : "rotate(180deg)",
        transition: "transform 0.2s ease-in-out",
      }}
    >
      <path d="M18 15l-6-6-6 6" />
    </svg>
  );
};

export { AccordionArrow };
