import React, { useState } from "react";
import { InputComponent } from "../../../core/Forms";
import { convertImageToBase64 } from "../../../../utils/convertToBase64";
import EmptyLogo from "../../../../assets/icons/emptyLogo";
import PhoneInputComponent from "../../../common/PhoneInputComponent";
import NewEditIcon from "../../../../assets/icons/NewEditIcon";
import { useTranslation } from "react-i18next";

interface ViewProps {
  formData: {
    firstName: string;
    lastName: string;
    businessName: string;
    industry: string;
    email: string;
    phone: string;
    address: string;
    state: string;
    city: string;
    businessSize: string;
    code: string;
    brandColor: string;
    logoBase64: string;
  };
}

export const View: React.FC<ViewProps> = ({ formData }) => {
  const { t } = useTranslation("settings");
  return (
    <>
      <div className="border-[0.5px] border-[#A4A4A4] py-3 px-4 rounded-[8px]">
        <div className="py-3 font-medium text-[14px] leading-[16.8px] border-b-[0.5px] border-[#A4A4A4]">
          Business & Contact information
        </div>

        <div className="mt-4 flex justify-between items-start md:gap-[80px] md:justify-start">
          <div className="space-y-4">
            <div className="text-[14px] leading-[16.8px] text-[#101010]">
              <p>{t("Settings.firstname")}</p>
              <p className="font-medium mt-2">{formData.firstName}</p>
            </div>
            <div className="text-[14px] leading-[16.8px] text-[#101010]">
              <p>{t("Settings.phonenumber")}</p>
              <p className="font-medium mt-2">{formData.phone}</p>
            </div>
            <div className="text-[14px] leading-[16.8px] text-[#101010]">
              <p>Business Size</p>
              <p className="font-medium mt-2">{formData.businessSize}</p>
            </div>
            <div className="text-[14px] leading-[16.8px] text-[#101010]">
              <p>{t("Settings.address")}</p>
              <p className="font-medium mt-2">{formData.address}</p>
            </div>
          </div>

          <div className="space-y-4">
            <div className="text-[14px] leading-[16.8px] text-[#101010]">
              <p>{t("Settings.lastname")}</p>
              <p className="font-medium mt-2">{formData.lastName}</p>
            </div>
            <div className="text-[14px] leading-[16.8px] text-[#101010]">
              <p>{t("Settings.category")}</p>
              <p className="font-medium mt-2">{formData.industry}</p>
            </div>
            <div className="text-[14px] leading-[16.8px] text-[#101010]">
              <p>CAC code</p>
              <p className="font-medium mt-2">{formData.code || "N/A"}</p>
            </div>
            <div className="text-[14px] leading-[16.8px] text-[#101010]">
              <p>{`${t("Settings.city")} / ${t("Settings.state")}`}</p>
              <p className="font-medium mt-2">
                {`${formData.city},`}
                <br />
                {formData.state}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="border-[0.5px] border-[#A4A4A4] py-3 px-4 rounded-[8px] mt-4">
        <div className="py-3 font-medium text-[14px] leading-[16.8px] border-b-[0.5px] border-[#A4A4A4]">
          Brand Identity
        </div>

        <div className="mt-4 space-y-4">
          <div>
            <p>Logo</p>
            <div className="mt-3">
              {formData.logoBase64 ? (
                <img
                  src={formData.logoBase64}
                  alt="Logo"
                  className="w-64 h-[100px] object-center"
                />
              ) : (
                <div className=" border border-gray-300 flex flex-col justify-center items-center p-3 bg-[#EDF2F5]">
                  <span className="">
                    <EmptyLogo />
                  </span>
                  <p className="text-gray-500 mt-[10px]">No logo uploaded</p>
                </div>
              )}
            </div>
          </div>

          <div>
            <p>Brand</p>
          </div>
          {formData.brandColor && (
            <div
              style={{ backgroundColor: formData.brandColor || "#044E97" }}
              className={`mt-7 h-10 w-10 rounded-full ${
                formData.brandColor
                  ? `bg-[${formData.brandColor}]`
                  : "bg-[#044E97]"
              }`}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};
