import { isValidPhoneNumber } from "react-phone-number-input";

export const formatNumber = (phone: string) => {
  return `${phone}`;
};

interface ValidationResult {
  isValid: boolean;
  errorMessage: string;
}

export const validatePhoneNumber = (value: string) => {
  if (value) {
    const normalizedValue = value.replace(/\D/g, "");
    console.log("Normalized value for validation:", normalizedValue);

    // Example: for Nigerian numbers
    if (normalizedValue.startsWith("234")) {
      console.log("Validating Nigerian number...");
      return /^(234)?[789][01]\d{8}$/.test(normalizedValue);
    } else if (normalizedValue.startsWith("+234")) {
      console.log("Validating international format with +234...");
      return /^\+234[789][01]\d{8}$/.test(normalizedValue);
    } else {
      console.log("Validating international phone format...");
      return /^\+?\d{1,3}\d{4,14}$/.test(normalizedValue);
    }
  }
  return false;
};
