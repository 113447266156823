import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorComponent = ({ errorCode }: { errorCode: number }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    switch (errorCode) {
      case 404:
        navigate("/404");
        break;
      case 500:
        navigate("/error");
        break;
      default:
        navigate("/error");
    }
  }, [errorCode, navigate]);

  return <div>Loading error page...</div>;
};

export default ErrorComponent;
