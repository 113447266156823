import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ICreateExpenseData,
  ICustomer,
  IExpense,
  IVendor,
} from "../../../@types";
import {
  manageExpense,
  validateExpenseBasicInfo,
  validateExpenseItemDetails,
  validateExpenseTotalPayment,
} from "../../../modules/expense/expense";

import { EXPENSE_PAYMENT_CURRENCY_LIST } from "../../../constants";
import { clearFieldError } from "../../../utils/handleErrors";
import useLineItems from "../../../modules/lineItem";
import { useVendorSearch } from "../../../hooks/vendor";
import { useCustomerSearch } from "../../../hooks/customer/useCustomerSearch";
import VendorCreateInfo from "./VendorCreateInfo";
import ExpenseDate from "./ExpenseDate";
import ExpenseCategory from "./ExpenseCategory";
import ExpenseLineTable from "./ExpenseLineTable";
import BillableExpense from "./BillableExpense";
import ProtectedExpense from "./ProtectedExpense";
import ExpenseNote from "./ExpenseNote";
import { Button } from "../../common";
import SuccessBanner from "../../common/Banner/SuccessBanner";
import ProtectedExpenseModal from "./ProtectedExpenseModal";

type Props = {
  expense: ICreateExpenseData;
  expenses?: IExpense[];
};

const ExpenseParent: React.FC<Props> = ({ expense }) => {
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [total, setTotal] = React.useState<number>(0);
  const [customer, setCustomer] = React.useState<ICustomer | undefined>();
  const [vendorName, setVendorName] = React.useState<IVendor | undefined>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isBillable, setIsBillable] = React.useState<boolean>(false);
  const [isProtected, setIsProtected] = React.useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] =
    React.useState<boolean>(false);
  const [, setMessage] = React.useState<string>("");
  const [updatedId, setUpdatedExpenseId] = React.useState<string | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [showRateInput, setShowRateInput] = useState(false);
  const [selectedCurrencyLabel, setSelectedCurrencyLabel] = React.useState("");
  const [currencyLabel, setCurrencyLabel] = useState("₦ Naira");
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerLinkText, setBannerLinkText] = useState("");

  const [formData, setFormData] = React.useState<ICreateExpenseData>({
    customerId: undefined,
    category: "",
    currency: "ngn",
    date: new Date().toISOString().substring(0, 10),
    description: "",
    lineItems: [],
    amount: 0,
    additionalInformation: "",
    notes: "",
    isBillable: false,
    isProtected: false,
    isExpense: true,
    vendorName: "",
    paymentMethod: "",
    vendorId: "",
  });

  const initialFormData: ICreateExpenseData = {
    customerId: undefined,
    category: "",
    currency: "ngn",
    date: new Date().toISOString().substring(0, 10),
    description: "",
    lineItems: [],
    amount: 0,
    additionalInformation: "",
    notes: "",
    isBillable: false,
    isProtected: false,
    isExpense: true,
    vendorName: "",
    paymentMethod: "",
    vendorId: "",
  };

  const resetForm = () => {
    setFormData({
      ...(initialFormData as ICreateExpenseData),
      vendorId: "",
      paymentMethod: "",
      vendorName: "",
    });
    //@ts-ignore
    setValidationResults({});
    setErrorMessage("");
    setItems([{ id: "", name: "", rate: 0, quantity: 0 }]);
    handleSelectedVendor(null);
  };

  const [validationResults, setValidationResults] =
    React.useState<ICreateExpenseData>({
      customerId: "",
      date: "",
      lineItems: [],
      category: "",
      currency: "",
      description: "",
      vendorName: "",
      amount: 0,
      paymentMethod: "",
      isBillable: false,
      isProtected: false,
    });
  const { items, addItem, setItems, removeItem, updateCell, updateRow } =
    useLineItems(expense?.lineItems.length ? expense.lineItems : [{}]);

  const handleNextTab = () => {
    let isValid = true;

    // Validate based on the current tab
    if (currentTab === 0) {
      isValid = validateExpenseBasicInfo(formData, setValidationResults);
    } else if (currentTab === 1) {
      isValid = validateExpenseItemDetails(formData, setValidationResults);
    } else if (currentTab === 2) {
      isValid = validateExpenseTotalPayment(formData, setValidationResults);
    }

    if (isValid) {
      setCurrentTab(currentTab + 1);
    }
  };

  const {
    data: vendors,
    isSearching: isVendorSearching,
    handleSearch: handleVendorSearch,
  } = useVendorSearch();

  const handleSelectedVendor = (selectedVendor: IVendor | null) => {
    if (selectedVendor) {
      setVendorName(selectedVendor);
      if (selectedVendor.name) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          vendorId: selectedVendor.id,
          vendorName: selectedVendor.name,
        }));
      }
      setValidationResults((prevValidationResults) => ({
        ...prevValidationResults,
        vendorName: "",
      }));
    } else {
      setVendorName(undefined);
      setFormData((prevFormData) => ({
        ...prevFormData,
        vendorName: "",
      }));
      setValidationResults((prevValidationResults) => ({
        ...prevValidationResults,
        vendorName: "",
      }));
      clearFieldError(
        "selectedVendor",
        validationResults,
        setValidationResults,
      );
    }
  };

  const handleDropDownChange = (name: string, value: string) => {
    clearFieldError(name, validationResults, setValidationResults);
    const selectedLabel =
      EXPENSE_PAYMENT_CURRENCY_LIST.find((currency) => currency.value === value)
        ?.label || "";

    setFormData({ ...formData, [name]: value });
    setSelectedCurrencyLabel(selectedLabel);
    setShowRateInput(value !== "NGN");
  };

  const handleInputChange = (event: {
    target: { name: any; value: any };
  }): void => {
    const { name, value } = event.target;

    if (name === "currency") {
      const newValue = value === "₦ Naira" ? "NGN" : value;

      setCurrencyLabel(value === "NGN" ? "₦ Naira" : "");
      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    const updatedItems = items.map((item) => {
      if (item.id === name) {
        return { ...item, quantity: value };
      }
      return item;
    });

    setItems(updatedItems);
    setFormData((prevState: ICreateExpenseData) => ({
      ...prevState,
      lineItems: updatedItems,
    }));

    const _total = updatedItems.reduce(
      (total, exp) => total + (exp.rate || 0),
      0,
    );
    setTotal(_total);
    clearFieldError(name, validationResults, setValidationResults);
  };

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    clearFieldError(name, validationResults, setValidationResults);
  };

  React.useEffect(() => {
    clearFieldError("lineItems", validationResults, setValidationResults);
    setFormData((prevState: ICreateExpenseData) => ({
      ...prevState,
      lineItems: items.map((item) => ({
        ...item,
        amount: (item?.rate ?? 0) * (item?.quantity ?? 0),
      })),
    }));

    const total = items.reduce(
      (total, exp) => total + (exp?.rate ?? 0) * (exp?.quantity ?? 0),
      0,
    );
    setTotal(total);
  }, [items]);

  const {
    data: customers,
    isSearching: isCustomerSearching,
    handleSearch: handleCustomerSearch,
  } = useCustomerSearch();

  const handleSelectCustomer = (selectedCustomer: ICustomer | null) => {
    if (selectedCustomer) {
      setCustomer(selectedCustomer);
      if (selectedCustomer.id) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          customerId: selectedCustomer.id,
        }));
      }
      setValidationResults((prevValidationResults) => ({
        ...prevValidationResults,
        customerId: "",
      }));
    } else {
      setCustomer(undefined);
      setFormData((prevFormData) => ({
        ...prevFormData,
        customerId: "",
      }));
      setValidationResults((prevValidationResults) => ({
        ...prevValidationResults,
        customerId: "",
      }));
    }
  };

  const handleIsBillableChange = () => {
    setIsBillable(!isBillable);
    setFormData((prevFormData) => ({
      ...prevFormData,
      isBillable: !isBillable,
    }));
  };

  const handleProtectedExpenseModal = () => {
    setIsOpenModal(true);
  };
  const handleIsProtectedChange = () => {
    setIsProtected(!isProtected);
    setFormData((prevFormData) => ({
      ...prevFormData,
      isProtected: !isProtected,
    }));
    setIsOpenModal(false);
  };

  const handleViewExpense = () => {
    if (updatedId) {
      const viewExpenseRoute = `/expenses/${updatedId}/view`;
      navigate(viewExpenseRoute);
    }
  };

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  const handleConfirmCreateExpense = async (
    event?: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event?.preventDefault();

    try {
      const basicInfoValid = validateExpenseBasicInfo(
        formData,
        setValidationResults,
      );
      const itemDetailsValid = validateExpenseItemDetails(
        formData,
        setValidationResults,
      );
      const totalPaymentValid = validateExpenseTotalPayment(
        formData,
        setValidationResults,
      );

      // Validate payment method and category
      let paymentMethodValid = true;
      let categoryValid = true;

      if (!formData.paymentMethod) {
        setValidationResults((prevValidationResults) => ({
          ...prevValidationResults,
          paymentMethod: "Payment method is required",
        }));
        paymentMethodValid = false;
      }

      if (!formData.category) {
        setValidationResults((prevValidationResults) => ({
          ...prevValidationResults,
          category: "Category is required",
        }));
        categoryValid = false;
      }

      let isValidated =
        basicInfoValid &&
        itemDetailsValid &&
        totalPaymentValid &&
        paymentMethodValid &&
        categoryValid;

      if (formData.isBillable && !formData.customerId) {
        setValidationResults((prevValidationResults) => ({
          ...prevValidationResults,
          customerId: "Customer selection is required for billable expenses",
        }));
        isValidated = false;
      }

      if (!formData.vendorName) {
        setValidationResults((prevValidationResults) => ({
          ...prevValidationResults,
          vendorName: "Vendor selection is required",
        }));
        isValidated = false;
      }

      if (!isValidated) {
        setErrorMessage("Please fill out all required fields correctly.");

        const errorElements = [
          { id: "vendor-info", hasError: !basicInfoValid },
          { id: "expense-date", hasError: !basicInfoValid },
          { id: "expense-category", hasError: !categoryValid },
          { id: "line-table", hasError: !itemDetailsValid },
          {
            id: "billable",
            hasError: formData.isBillable && !formData.customerId,
          },
          { id: "protected-expense", hasError: !totalPaymentValid },
          { id: "payment-method", hasError: !paymentMethodValid },
        ];

        const firstErrorElement = errorElements.find((el) => el.hasError);

        if (firstErrorElement) {
          const element = document.getElementById(firstErrorElement.id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }
        return;
      }

      setIsLoading(true);

      // Determine if the operation is creation or update
      const isNewExpense = !expense;

      const updatedId = await manageExpense({
        expenseId: expense?.id,
        customer,
        formData,
        isBillable,
        isProtected,
      });

      if (typeof updatedId === "string") {
        setUpdatedExpenseId(updatedId);
      }

      setMessage("Expense update successful!");
      setShowSuccessModal(true);
      setIsLoading(false);

      if (isNewExpense) {
        setBannerMessage(
          "New Expense Created Successfully. To view expense, Click",
        );
      } else {
        setBannerMessage(
          "Expense updated successfully. To view expense, Click",
        );
      }
      setBannerLinkText("View Expense");
      setIsBannerVisible(true);
      resetForm();
      setIsBillable(false);
      setIsProtected(false);
      setErrorMessage("");
    } catch (error: any) {
      console.error("Error:", error);
      if (
        error.response &&
        (error.response.status === 400 || error.response.status === 500)
      ) {
        navigate("/error");
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleCancel = () => {
    navigate("/expenses");
  };

  useEffect(() => {
    if (expense) {
      setFormData((prevState) => ({
        ...prevState,
        notes: expense.notes,
        customerId: expense.customerId,
        customerName: expense.customerName,
        vendorName: expense.vendorName,
        amount: expense.amount,
        description: expense.description,
        paymentMethod: expense.paymentMethod,
        lineItems: expense.lineItems,
        currency: expense.currency,
        category: expense.category,
        isBillable: expense.isBillable,
        isProtected: expense.isProtected,
      }));

      const newItems = expense.lineItems.map((lineItem: any) => ({
        id: lineItem.id,
        name: lineItem.name,
        quantity: lineItem.quantity,
        rate: parseFloat(lineItem.rate),
      }));

      setItems(newItems);

      handleSelectedVendor({
        name: expense.vendorName as string,
        id: expense.vendorId as string,
      });
    }
  }, [expense]);

  return (
    <>
      {isBannerVisible && (
        <SuccessBanner
          message={bannerMessage}
          linkText={bannerLinkText}
          onLinkClick={handleViewExpense}
          onClose={handleCloseBanner}
        />
      )}

      <div>
        <div className="flex items-center gap-[10px]">
          <a href="/expenses">
            <svg
              className="mt-[-2px] ml-[-2em] md:ml-0"
              width="10"
              height="16"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.5 1L1.5 8L8.5 15" stroke="#33363F" strokeWidth="2" />
            </svg>
          </a>
          <h1 className="hidden md:block xl:text-[20px] text-[16px] font-bold">
            Add an expense
          </h1>
          <h1 className="lg:hidden md:hidden flex items-center xl:text-[20px] text-[16px] ml-[-1em] mt-[-0.4em] xl:mt-[-0.4em] font-bold">
            Add an expense
          </h1>
        </div>
        <div
          className="mx-auto h-full bg-white lg:ml-0 border-2 border-gray-100 mt-3 "
          id="expense-parent"
        >
          {errorMessage && (
            <p className="text-[red] lg:pl-[3em] pl-3 md:pl-[3em] mt-3">
              {errorMessage}
            </p>
          )}
          <div className="flex justify-end lg:pt-[4em] pt-[2em] ">
            <h1 className="xl:text-[24px] biglg:text-[24px] text-[14px] font-medium lg:px-[3em] pr-3 md:pr-[3em]">
              EXPENSE
            </h1>
          </div>
          <div className="flex justify-between ">
            <div id="vendor-info" className="lg:pl-[3em] pl-3 md:pl-[3em]">
              <VendorCreateInfo
                handleSelectedVendor={handleSelectedVendor}
                formData={formData}
                vendorName={vendorName}
                setValidationResults={setValidationResults}
                validationResults={validationResults}
                toolTipText="Vendor name refers to the person who sold the item/ service to you."
              />
            </div>
            <div id="expense-date" className="pr-[4em] md:pr-[3em]">
              <ExpenseDate
                formData={formData}
                setFormData={setFormData}
                validationResults={validationResults}
                setValidationResults={setValidationResults}
                toolTipText="Expense date refers to the date the item was purchased"
                handleInputChange={handleInputChange}
              />
            </div>
          </div>
          <div id="expense-category" className="mt-[-1em]">
            <ExpenseCategory
              formData={formData}
              validationResults={validationResults}
              handleDropDownChange={handleDropDownChange}
              setValidationResults={setValidationResults}
              toolTipText="Payment method refers to how payment was made to the  vendor. E.g cash, transfer, etc."
              toolTipText2="Category is to ensure users select the best category that best fits their expense for proper expense tracking."
            />
          </div>

          <hr className="lg:hidden md:hidden mt-[2em]" />
          <p className="lg:hidden md:hidden text-[9px] font-semibold flex justify-center text-center mt-3">
            Item Details
          </p>

          <div
            id="line-table"
            className="lg:mt-[4em] mt-[1em] md:px-[3em] md:mt-[3em]"
          >
            <ExpenseLineTable
              formData={formData}
              expense={expense}
              setFormData={setFormData}
              validateCurrentStep={() =>
                validateExpenseItemDetails(formData, setValidationResults)
              }
              setValidationResults={setValidationResults}
              validationResults={validationResults}
              items={items}
              addItem={addItem}
              setItems={setItems}
              removeItem={removeItem}
              updateCell={updateCell}
              updateRow={updateRow}
            />
          </div>
          <hr className="border lg:mt-[3em] mt-[1em] " />
          <div className="lg:flex justify-between mt-[2em] md:flex">
            <div
              id="billable"
              className="lg:pl-[3em] pl-3 md:pl-[3em] mb-[1em] lg:mb-0"
            >
              <BillableExpense
                validationResults={validationResults}
                handleTextAreaChange={handleTextAreaChange}
                formData={formData}
                total={total}
                expense={expense}
                handleSelectCustomer={handleSelectCustomer}
                validateCurrentStep={() =>
                  validateExpenseTotalPayment(formData, setValidationResults)
                }
                customer={customer}
                isBillable={isBillable}
                errorMessage={errorMessage}
                handleIsBillableChange={handleIsBillableChange}
                toolTipText="Mark an expense as 'Billable' to invoice your client for this cost, ensuring you get reimbursed for client-related expenses"
              />
            </div>
            <div
              id="protected-expense"
              className="lg:pr-[3em] pl-3 md:pr-[3em] "
            >
              <ProtectedExpense
                isProtected={isProtected}
                handleIsProtectedChange={handleProtectedExpenseModal}
                validationResults={validationResults}
                formData={formData}
                toolTipText="Mark as protected Expense allows only the admin to view and have access to this expense."
              />
            </div>
          </div>
          <hr className="border mt-[3em]" />
          <div id="expense-note" className="px-[3em] mt-[2em]">
            <ExpenseNote
              handleTextAreaChange={handleTextAreaChange}
              formData={formData}
            />
          </div>
          <div className="flex justify-end mt-[2em] mb-[2em] gap-[2%] ">
            {handleCancel && (
              <div className=" hidden md:block lg:block ml-[em]   md:ml-[4em] lg:ml-[9em] mt-[] lg:mt-[] w-[167px] lg:w-[215px] biglg:w-[215px] xl:w-[215px] md:w-[215px] bigmd:ml-[5em] 540:ml-[4em] 360:ml-[-3em] p-2">
                <Button
                  text="Cancel"
                  size="medium"
                  onClick={handleCancel}
                  type="button"
                  variant="danger"
                  outline
                />
              </div>
            )}
            {handleConfirmCreateExpense && (
              <div className="hidden md:block mt-[0.5em] lg:mt-[] w-[167px] lg:w-[215px] biglg:w-[215px] xl:w-[215px] md:w-[215px] ml-[em] mr-[3em]">
                <Button
                  text="Save"
                  size="medium"
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                  onClick={handleConfirmCreateExpense}
                />
              </div>
            )}
          </div>
          <div className="flex justify-center md:hidden lg:hidden mb-[2em]">
            {handleCancel && (
              <div className="md:hidden lg:hidden sm:block w-[167px] mt-[1em] lg:mt-[-2.5em]  md:mr-[6em] ml-[-3.5em] p-2 540:mr-[4em]  ">
                <Button
                  text="Cancel"
                  size="small"
                  onClick={handleCancel}
                  type="button"
                  variant="danger"
                  outline
                />
              </div>
            )}
            {handleConfirmCreateExpense && (
              <div className="md:hidden lg:hidden sm:block mr-[-4em] mt-[1.5em]">
                <Button
                  text="Save"
                  size="small"
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                  onClick={handleConfirmCreateExpense}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {isOpenModal && (
        <ProtectedExpenseModal
          setIsOpenModal={setIsOpenModal}
          handleProtectedChange={handleIsProtectedChange}
        />
      )}
    </>
  );
};
export default ExpenseParent;
