import React from "react";

const LogoWithBetaBadge = () => {
  return (
    <>
      <svg
        width="203"
        height="49"
        viewBox="0 0 203 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_129_768)">
          <path
            d="M49.5077 39.2918C47.0818 47.0584 38.2205 49 34.093 49C39.3997 46.4452 42.1794 28.3063 42.6848 26.2625C43.1902 24.2186 45.2118 9.65639 52.0347 7.35709C57.4931 5.51765 65.2594 11.7002 68.4603 15.0214C64.7957 12.024 58.5797 11.3397 56.3306 14.255C54.1218 17.1181 52.5401 29.5837 49.5077 39.2918Z"
            fill="url(#paint0_linear_129_768)"
          />
          <path
            d="M32.2501 16.5088C34.676 8.74227 43.5374 6.80064 47.6648 6.80064C42.3581 9.35542 39.5784 27.4943 39.073 29.5382C38.5676 31.582 36.546 46.1442 29.7231 48.4435C24.2647 50.283 16.4984 44.1004 13.2976 40.7792C16.9621 43.7766 23.1781 44.4609 25.4272 41.5456C27.636 38.6825 29.2177 26.217 32.2501 16.5088Z"
            fill="#FF6702"
          />
          <path
            d="M18.5673 25.0767C18.3766 23.5851 17.8363 22.4624 16.9464 21.7086C16.0724 20.9388 14.8727 20.5538 13.3472 20.5538C11.8216 20.5538 10.598 20.9388 9.67638 21.7086C8.7706 22.4624 8.19059 23.5851 7.93633 25.0767H18.5673ZM25.2891 29.2147H7.72181C7.99195 30.9148 8.60375 32.1899 9.5572 33.0399C10.5106 33.8739 11.8057 34.2909 13.4425 34.2909C14.682 34.2909 15.7467 34.0504 16.6365 33.5692C17.5423 33.088 18.2971 32.3583 18.901 31.3799L24.3833 34.1466C23.112 36.2156 21.5706 37.7553 19.7591 38.7657C17.9475 39.7601 15.7943 40.2573 13.2995 40.2573C9.51747 40.2573 6.52205 39.1186 4.31323 36.8411C2.10441 34.5636 1 31.5002 1 27.6509C1 23.946 2.15208 20.8987 4.45625 18.5089C6.77631 16.1191 9.73994 14.9243 13.3472 14.9243C17.0656 14.9243 20.0054 16.047 22.1665 18.2924C24.3277 20.5217 25.4083 23.5691 25.4083 27.4344C25.4083 27.6429 25.4003 27.8755 25.3844 28.1321C25.3685 28.3727 25.3368 28.7335 25.2891 29.2147Z"
            fill="black"
          />
          <path
            d="M73.73 27.0476C73.5393 25.556 72.999 24.4333 72.1091 23.6794C71.2351 22.9096 70.0354 22.5247 68.5098 22.5247C66.9843 22.5247 65.7607 22.9096 64.8391 23.6794C63.9333 24.4333 63.3533 25.556 63.099 27.0476H73.73ZM80.4518 31.1855H62.8845C63.1546 32.8856 63.7664 34.1607 64.7199 35.0108C65.6733 35.8448 66.9684 36.2618 68.6052 36.2618C69.8447 36.2618 70.9093 36.0212 71.7992 35.54C72.705 35.0589 73.4598 34.3291 74.0637 33.3508L79.546 36.1174C78.2747 38.1864 76.7333 39.7261 74.9218 40.7366C73.1102 41.731 70.957 42.2282 68.4622 42.2282C64.6802 42.2282 61.6847 41.0894 59.4759 38.8119C57.2671 36.5344 56.1627 33.471 56.1627 29.6218C56.1627 25.9168 57.3148 22.8695 59.6189 20.4797C61.939 18.09 64.9026 16.8951 68.5098 16.8951C72.2283 16.8951 75.1681 18.0178 77.3292 20.2632C79.4904 22.4926 80.571 25.5399 80.571 29.4052C80.571 29.6137 80.563 29.8463 80.5471 30.1029C80.5312 30.3435 80.4994 30.7044 80.4518 31.1855Z"
            fill="black"
          />
          <path
            d="M90.3457 41.2899H83.5524V17.689H90.3457V21.3939C91.4581 19.8382 92.6419 18.6994 93.8973 17.9777C95.1686 17.256 96.6067 16.8951 98.2116 16.8951C100.15 16.8951 101.724 17.2961 102.931 18.098C104.155 18.8999 105.021 20.1108 105.529 21.7307C106.61 20.1269 107.834 18.924 109.2 18.122C110.583 17.3041 112.092 16.8951 113.729 16.8951C115.382 16.8951 116.78 17.2078 117.924 17.8333C119.084 18.4428 119.982 19.365 120.618 20.6C120.951 21.2736 121.19 22.0355 121.333 22.8855C121.476 23.7356 121.547 25.0267 121.547 26.7589V41.2899H114.754V29.6218C114.754 26.7508 114.46 24.8422 113.872 23.896C113.3 22.9497 112.291 22.4765 110.845 22.4765C109.097 22.4765 107.826 23.1341 107.031 24.4493C106.252 25.7645 105.863 27.9136 105.863 30.8968V41.2899H99.0697V29.6218C99.0697 26.8471 98.7996 24.9706 98.2593 23.9922C97.719 23.0138 96.7894 22.5247 95.4705 22.5247C93.6748 22.5247 92.3718 23.1903 91.5613 24.5215C90.7509 25.8366 90.3457 27.9618 90.3457 30.8968V41.2899Z"
            fill="black"
          />
          <path
            d="M125.744 7.99363C125.744 6.903 126.134 5.96474 126.912 5.17884C127.691 4.39295 128.621 4 129.701 4C130.798 4 131.735 4.39295 132.514 5.17884C133.308 5.9487 133.706 6.88696 133.706 7.99363C133.706 9.1003 133.308 10.0466 132.514 10.8325C131.735 11.6184 130.798 12.0113 129.701 12.0113C128.621 12.0113 127.691 11.6103 126.912 10.8084C126.134 10.0065 125.744 9.06822 125.744 7.99363ZM126.34 41.2899V17.689H133.11V41.2899H126.34Z"
            fill="black"
          />
          <path
            d="M154.159 27.0476C153.968 25.556 153.428 24.4333 152.538 23.6794C151.664 22.9096 150.464 22.5247 148.939 22.5247C147.413 22.5247 146.19 22.9096 145.268 23.6794C144.362 24.4333 143.782 25.556 143.528 27.0476H154.159ZM160.881 31.1855H143.314C143.584 32.8856 144.195 34.1607 145.149 35.0108C146.102 35.8448 147.397 36.2618 149.034 36.2618C150.274 36.2618 151.338 36.0212 152.228 35.54C153.134 35.0589 153.889 34.3291 154.493 33.3508L159.975 36.1174C158.704 38.1864 157.162 39.7261 155.351 40.7366C153.539 41.731 151.386 42.2282 148.891 42.2282C145.109 42.2282 142.114 41.0894 139.905 38.8119C137.696 36.5344 136.592 33.471 136.592 29.6218C136.592 25.9168 137.744 22.8695 140.048 20.4797C142.368 18.09 145.332 16.8951 148.939 16.8951C152.657 16.8951 155.597 18.0178 157.758 20.2632C159.919 22.4926 161 25.5399 161 29.4052C161 29.6137 160.992 29.8463 160.976 30.1029C160.96 30.3435 160.928 30.7044 160.881 31.1855Z"
            fill="black"
          />
          <g filter="url(#filter0_d_129_768)">
            <rect
              x="149"
              width="53"
              height="20"
              rx="10"
              fill="white"
              shape-rendering="crispEdges"
            />
            <path
              d="M161.961 14V5.59766H165.02C165.957 5.59766 166.678 5.76953 167.182 6.11328C167.686 6.45703 167.938 6.94141 167.938 7.56641C167.938 8.01953 167.783 8.41602 167.475 8.75586C167.17 9.0957 166.779 9.33203 166.303 9.46484V9.48828C166.9 9.5625 167.377 9.7832 167.732 10.1504C168.092 10.5176 168.271 10.9648 168.271 11.4922C168.271 12.2617 167.996 12.873 167.445 13.3262C166.895 13.7754 166.143 14 165.189 14H161.961ZM163.854 6.99219V8.98438H164.686C165.076 8.98438 165.383 8.89062 165.605 8.70312C165.832 8.51172 165.945 8.25 165.945 7.91797C165.945 7.30078 165.484 6.99219 164.562 6.99219H163.854ZM163.854 10.3906V12.6055H164.879C165.316 12.6055 165.658 12.5039 165.904 12.3008C166.154 12.0977 166.279 11.8203 166.279 11.4688C166.279 11.1328 166.156 10.8691 165.91 10.6777C165.668 10.4863 165.328 10.3906 164.891 10.3906H163.854ZM174.699 14H169.66V5.59766H174.506V7.13867H171.553V9.00781H174.301V10.543H171.553V12.4648H174.699V14ZM182.053 7.13867H179.656V14H177.758V7.13867H175.373V5.59766H182.053V7.13867ZM189.729 14H187.666L187.068 12.1309H184.08L183.488 14H181.438L184.496 5.59766H186.74L189.729 14ZM186.635 10.6777L185.732 7.85352C185.666 7.64258 185.619 7.39062 185.592 7.09766H185.545C185.525 7.34375 185.477 7.58789 185.398 7.83008L184.484 10.6777H186.635Z"
              fill="#FF6702"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_129_768"
            x="141"
            y="-4"
            width="69"
            height="36"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0784314 0 0 0 0 0.0784314 0 0 0 0 0.168627 0 0 0 0.24 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_129_768"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_129_768"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_129_768"
            x1="178.325"
            y1="4"
            x2="165.436"
            y2="70.3178"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#044E97" />
            <stop offset="1" stop-color="#11ABD1" />
          </linearGradient>
          <clipPath id="clip0_129_768">
            <rect width="203" height="49" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default LogoWithBetaBadge;
