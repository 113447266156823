import React from "react";
import Error403Icon from "../../assets/icons/svgicons/Error403Icon.svg";
import Arrow_Left from "../../assets/icons/svgicons/Arrow_left.svg";
import { useNavigate } from "react-router-dom";

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center h-screen whitespace-nowrap">
      <div className="md:w-[385px]">
        <div>
          <img src={Error403Icon} alt="Unauthorized Page" />
        </div>
        <div className="mt-[48px] text-center flex flex-col items-center">
          <h2 className="text-[28px] font-bold text-center">Access Denied</h2>
          <p className="text-[20px] mt-[12px] mb-[56px] font-normal whitespace-nowrap">
            Oops, sorry! You do not have access to this page.
          </p>

          <div
            onClick={() => navigate("/")}
            className="flex bg-[#044E97] items-center justify-center cursor-pointer h-12 text-white w-[220px]"
          >
            <div className="flex items-center gap-3">
              <img src={Arrow_Left} alt="Go back " />
              <span className="text-[16px] font-semibold">Go back</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { UnauthorizedPage };
