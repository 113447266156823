import { useSearch } from "../useSearch";
import { inventoryService } from "../../services";
import { ICreateInventoryData, IVendor } from "../../@types";

export const useInventorySearch = () => {
  return useSearch({
    service: inventoryService,
    endpoint: "inventories/search",
    queryParameter: "q",
    responseMapper: (data: any[]): any =>
      data.map((inventory: ICreateInventoryData) => ({
        ...inventory,
      })) as unknown as ICreateInventoryData,
  });
};
