import React, { useState } from "react";
import PaystackIcon from "../../../assets/icons/Paystack";
import {
  createSubscription,
  createSubscriptionPaymentLink,
  processSubscription,
} from "../../../backend-services/subscription";
import { useTranslation } from "react-i18next";

interface PayStackConfig {
  reference: string;
  email: string;
  publicKey: string;
  amount: number;
  metadata: any;
}

interface PayWithPaystackProps {
  config: PayStackConfig;
  plan: string;
  id: number;
  close: () => void;
}

const PayWithPaystack = ({ config, plan, id, close }: PayWithPaystackProps) => {
  const callbackUrl = `${window.location.href}/processing`;
  const { t } = useTranslation("subscription");
  const handlePaymentSucess = async () => {
    try {
      const data = await createSubscriptionPaymentLink({
        paymentProvider: "paystack",
        plan,
        callback_url: callbackUrl,
      });
      const { authorization_url } = data;
      window.location = authorization_url;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return (
    <div className="flex flex-none ">
      <button
        className="bg-[#EDF2F5] rounded-[4px] py-[20px] px-[20px] flex flex-row gap-[72px]"
        onClick={() => handlePaymentSucess()}
      >
        <span>{t("subscription.subscribeWith")} </span> <PaystackIcon />
      </button>
    </div>
  );
};

export default PayWithPaystack;
