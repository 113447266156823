import React from "react";

const WidgetIcon = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2.5625"
          y="2.5625"
          width="5.97917"
          height="5.97917"
          rx="0.854167"
          stroke="#044E97"
          stroke-width="1.70833"
          stroke-linecap="round"
        />
        <rect
          x="2.5625"
          y="11.959"
          width="5.97917"
          height="5.97917"
          rx="0.854167"
          stroke="#044E97"
          stroke-width="1.70833"
          stroke-linecap="round"
        />
        <rect
          x="11.957"
          y="2.5625"
          width="5.97917"
          height="5.97917"
          rx="0.854167"
          stroke="#044E97"
          stroke-width="1.70833"
          stroke-linecap="round"
        />
        <path
          d="M14.9492 11.959L14.9492 17.9383"
          stroke="#044E97"
          stroke-width="1.70833"
          stroke-linecap="round"
        />
        <path
          d="M17.9375 14.9473L11.9582 14.9473"
          stroke="#044E97"
          stroke-width="1.70833"
          stroke-linecap="round"
        />
      </svg>
    </>
  );
};

export { WidgetIcon };
