import useRetailBusinessStatus from "../hooks/invoice/useRetailBusinessStatus";

interface BusinessTypeCheckerProps {
  renderRetail: () => React.ReactElement;
  renderNonRetail: () => React.ReactElement;
}

const BusinessTypeChecker: React.FC<BusinessTypeCheckerProps> = ({
  renderRetail,
  renderNonRetail,
}) => {
  const isRetailBusiness = useRetailBusinessStatus();

  return isRetailBusiness ? renderRetail() : renderNonRetail();
};

export default BusinessTypeChecker;
