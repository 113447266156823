import React from "react";

const UsaIcon = () => {
  return (
    <div>
      <svg
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.8889 0.373047H1.11111C0.497461 0.373047 0 0.870508 0 1.48416V2.40499H20V1.48416C20 0.870508 19.5025 0.373047 18.8889 0.373047Z"
          fill="#DC4437"
        />
        <path d="M20 4.45312H0V6.48507H20V4.45312Z" fill="#DC4437" />
        <path
          d="M20 12.5957H0V13.5165C0 14.1302 0.497461 14.6276 1.11111 14.6276H18.8889C19.5025 14.6276 20 14.1302 20 13.5165V12.5957Z"
          fill="#DC4437"
        />
        <path d="M20 8.51562H0V10.5476H20V8.51562Z" fill="#DC4437" />
        <path d="M20 2.4043H0V4.43624H20V2.4043Z" fill="white" />
        <path d="M20 6.48438H0V8.51632H20V6.48438Z" fill="white" />
        <path d="M20 10.5645H0V12.5964H20V10.5645Z" fill="white" />
        <path
          d="M10.7692 0.373047H1.11111C0.497461 0.373047 0 0.870508 0 1.48416V10.5633H10.7692V0.373047Z"
          fill="#2A66B7"
        />
        <path
          d="M2.07767 0.985352L2.43152 1.73396L3.23152 1.85618L2.64691 2.45202L2.78537 3.27702L2.07767 2.8798L1.36998 3.27702L1.50844 2.45202L0.923828 1.85618L1.72383 1.73396L2.07767 0.985352Z"
          fill="white"
        />
        <path
          d="M5.23002 0.985352L5.58386 1.73396L6.38386 1.85618L5.81463 2.45202L5.93771 3.27702L5.23002 2.8798L4.52233 3.27702L4.6454 2.45202L4.07617 1.85618L4.87617 1.73396L5.23002 0.985352Z"
          fill="white"
        />
        <path
          d="M8.38431 0.985352L8.73816 1.73396L9.53816 1.85618L8.95355 2.45202L9.09201 3.27702L8.38431 2.8798L7.67662 3.27702L7.81508 2.45202L7.23047 1.85618L8.03047 1.73396L8.38431 0.985352Z"
          fill="white"
        />
        <path
          d="M3.69291 4.04102L4.04675 4.78963L4.84675 4.91185L4.27752 5.50768L4.4006 6.33268L3.69291 5.93546L2.98522 6.33268L3.10829 5.50768L2.53906 4.91185L3.33906 4.78963L3.69291 4.04102Z"
          fill="white"
        />
        <path
          d="M6.84525 4.04102L7.1991 4.78963L7.9991 4.91185L7.41448 5.50768L7.55294 6.33268L6.84525 5.93546L6.13756 6.33268L6.27602 5.50768L5.69141 4.91185L6.49141 4.78963L6.84525 4.04102Z"
          fill="white"
        />
        <path
          d="M2.07767 7.27832L2.43152 8.02693L3.23152 8.14915L2.64691 8.72971L2.78537 9.56999L2.07767 9.17276L1.36998 9.56999L1.50844 8.72971L0.923828 8.14915L1.72383 8.02693L2.07767 7.27832Z"
          fill="white"
        />
        <path
          d="M5.23002 7.27832L5.58386 8.02693L6.38386 8.14915L5.81463 8.72971L5.93771 9.56999L5.23002 9.17276L4.52233 9.56999L4.6454 8.72971L4.07617 8.14915L4.87617 8.02693L5.23002 7.27832Z"
          fill="white"
        />
        <path
          d="M8.38431 7.27832L8.73816 8.02693L9.53816 8.14915L8.95355 8.72971L9.09201 9.56999L8.38431 9.17276L7.67662 9.56999L7.81508 8.72971L7.23047 8.14915L8.03047 8.02693L8.38431 7.27832Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default UsaIcon;
