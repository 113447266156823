import { useState } from "react";
import { toast } from "react-hot-toast";
import { handleRecordPayment } from "../../hooks/payment/handleRecordPayment";
import { fileToBase64 } from "../../utils/fileToBase64";
import { ReceiptUpload } from "../../backend-services";
import useNotifications from "../../hooks/UseNotifications";
import { IExpense, PreviewExpense, TransactionType } from "../../@types";

interface UploadExpenseProps {
  entity?: IExpense;
  onRecordEntity: (data: any) => void;
  paymentType: TransactionType;
  setPreview: (preview: boolean) => void;
  setShowPopup: (preview: boolean) => void;
  preview: boolean;
  setExpenseData: (expense: PreviewExpense) => void;
}

const useUploadExpense = ({
  entity,
  onRecordEntity,
  preview,
  paymentType,
  setShowPopup,
  setPreview,
  setExpenseData,
}: UploadExpenseProps) => {
  const [file, setFile] = useState<File | null>();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [previewSuccess, setPreviewSuccess] = useState(false);
  const [triggerAction, setTriggerAction] = useState<boolean>(false);
  useNotifications(triggerAction);

  const handleUpload = async (uploadedFile: File) => {
    setFile(uploadedFile);
    setError(undefined);
  };

  const handleAutoSave = async () => {
    if (!file) {
      return setError("Please upload receipt.");
    }
    setError(undefined);
    try {
      setIsLoading(true);
      await handleRecordPayment(file, entity, paymentType);
      setIsLoading(false);
      setSuccess(true);
      setTriggerAction(true);
    } catch (e: any) {
      setError(e.message);
      toast.error("An error occurred while uploading image: " + e.message);
      setIsLoading(false);
    }
  };

  const handlePreviewAndSave = async () => {
    if (!file) {
      return setError("Please upload receipt.");
    }
    setIsPreviewing(true);
    try {
      const base64 = await fileToBase64(file);
      const response = await ReceiptUpload({ image: base64 });
      setPreviewSuccess(true);
      if (response) {
        setExpenseData(response);
      }
      setShowPopup(false);
      setPreview(!preview);
      setIsPreviewing(false);
      setPreviewSuccess(false);
    } catch (error) {
      setIsPreviewing(false);
      throw new Error("Error converting file to base64");
    }
  };

  return {
    file,
    setFile,
    error,
    isLoading,
    isPreviewing,
    success,
    previewSuccess,
    triggerAction,
    handleUpload,
    handleAutoSave,
    handlePreviewAndSave,
  };
};

export default useUploadExpense;
