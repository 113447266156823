import React from "react";
import Flutterwave from "../../../assets/icons/svgicons/flutterwave.svg";

const PayWithFlutterwave = () => {
  return (
    <div className="flex flex-none ">
      <button className="bg-[#EDF2F5] rounded-[4px] py-[20px] px-[20px] flex flex-row gap-[72px]">
        <span>Subscribe with </span> <img src={Flutterwave} alt="flutter" />
      </button>
    </div>
  );
};

export default PayWithFlutterwave;
