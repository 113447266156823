import React, { ReactNode } from "react";
import { Filter } from "../../../@types/invoice";
import ListHead from "./ListHead";
import { ReusableTable } from "../../table";
import { ReusableTableColumn } from "../../../@types/table";
import { ICustomer, IPaginationMeta } from "../../../@types";
import { FilterOption } from "./ListFilter";
import EmptyPage from "../../emptyPage/EmptyPage";

interface ListDesktopTableProps {
  data: any[];
  handleSearch?: (text: string) => void;
  handleFilter?: (filter: Filter) => void;
  handleSearchSelect?: (item: any) => void;
  isSearching?: boolean | undefined;
  searchedDone?: boolean | undefined;
  setSearchedDone?: (searchedDone: boolean) => void;
  searchResult: any[];
  searchDisplayKey: string;
  searchPlaceHolder: string;
  columns: ReusableTableColumn[];
  modules?: string;
  isLoadingMore: boolean;
  isLoadingFilter: boolean;
  showFilter: boolean;
  fetchMoreData?: (direction: "up" | "down") => void;
  meta?: IPaginationMeta;
  selectedFilter?: Filter | undefined;
  categories?: FilterOption[];
  onSubmit?: (filter: Record<string, string | boolean>) => void;
  clearAllFilters?: () => void;
  renderActionMenu?: (
    row: Record<string, any>,
    toggleVisibility: () => void,
    isVisible: boolean,
  ) => React.ReactNode;
  emptyStateDescription?: string;
  emptyPageIcon?: ReactNode;
  emptyPageHref?: string;
  emptyPageLinkName?: string;
  emptyMessage?: string;
  handleSetCustomer: (selectedCustomer: ICustomer | null) => void;
  emptyPageOnClose?: () => void;
}

const ListDesktopTable: React.FC<ListDesktopTableProps> = (props) => {
  const [data, setData] = React.useState<typeof props.data>([]);

  React.useEffect(() => {
    setData([...props.data]);
  }, [props.data]);

  const fetchPageData = async (direction: "up" | "down") => {
    if (direction === "down" && props?.meta?.hasMoreData) {
      props.fetchMoreData ? props.fetchMoreData("down") : undefined;
    }
  };

  return (
    <>
      {data.length > 0 ? (
        <>
          <div className="border border-gray-200 mt-8 rounded-t-xl">
            <ListHead
              handleSearchSelect={props.handleSearchSelect}
              handleSearch={props.handleSearch}
              searchResult={props.searchResult}
              searchDisplayKey={props.searchDisplayKey}
              handleFilter={props.handleFilter}
              searchPlaceHolder={props.searchPlaceHolder}
              isSearching={props.isSearching}
              searchedDone={props.searchedDone}
              setSearchedDone={props.setSearchedDone}
              categories={props.categories}
              filter={props.selectedFilter}
              onSubmit={props.onSubmit}
              showFilter={props.showFilter}
              clearAllFilters={props.clearAllFilters}
            />
            <hr className="border border-gray-200 my-4" />

            <div
              className="rounded-[10px] lg:block"
              style={{ overflow: "hidden" }}
            >
              <ReusableTable
                hasActions={false}
                modules={props.modules}
                data={data}
                columns={props.columns}
                isLoading={props.isLoadingMore}
                isLoadingFilter={props.isLoadingFilter}
                loadMore={fetchPageData}
                renderActionMenu={props.renderActionMenu}
              />
            </div>
          </div>
        </>
      ) : (
        <EmptyPage
          pageTitle={""}
          description={props.emptyStateDescription}
          pageIcon={props.emptyPageIcon}
          href={props.emptyPageHref}
          linkName={props.emptyPageLinkName ? props.emptyPageLinkName : ""}
          handleSetCustomer={props.handleSetCustomer}
          onClose={props.emptyPageOnClose}
          emptyMessage={props.emptyMessage}
        />
      )}
    </>
  );
};

export default ListDesktopTable;
