import { BUSINESS_TYPE_RETAIL } from "../../constants";
import { useUserStore } from "../../state";

const useRetailBusinessStatus = () => {
  const user = useUserStore((state) => state.user);
  const businessType = user?.businessType || "";
  const isRetailBusiness = businessType === BUSINESS_TYPE_RETAIL;
  return isRetailBusiness;
};

export default useRetailBusinessStatus;
