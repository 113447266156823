import { CustomerInvoiceTable } from "../CustomerInvoiceTable";

export const RecentInvoices = ({ customerId }: { customerId: string }) => (
  <div className="gap-[28px] mt-[40px]">
    <h3 className="text-[18px] font-semibold">Recent Invoices</h3>
    <div className="mt-[1em]">
      <CustomerInvoiceTable customerId={customerId} />
    </div>
  </div>
);
