import React, { ReactNode } from "react";

const DocumentModal = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <div className="fixed top-0 left-0 w-screen h-[100%] bg-black bg-opacity-80 z-10">
        <div className="w-full flex justify-center h-full items-center">
          {children}
        </div>
      </div>
    </>
  );
};

export { DocumentModal };
