import React from "react";

interface ProfileProps {
  imageUrl?: string;
  firstName?: string;
  lastName?: string;
  size: "small" | "medium" | "large";
}

const ProfileIcon = ({
  imageUrl,
  firstName,
  lastName,
  size = "medium",
}: ProfileProps) => {
  const sizes = {
    small: "h-8 w-8 text-sm",
    medium: "h-12 w-12 text-base",
    large: "h-16 w-16 text-lg",
  };

  const firstInitial = firstName?.charAt(0).toUpperCase();
  const lastInitial = lastName?.charAt(0).toUpperCase();

  return (
    <div
      className={`rounded-full bg-[#FEE4E2] text-[#B42318] flex items-center justify-center mx-2 font-medium ${sizes[size]}`}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="Profile"
          className={`rounded-full ${sizes[size]} `}
        />
      ) : (
        <span>{(firstInitial ?? "") + (lastInitial ?? "")}</span>
      )}
    </div>
  );
};

export default ProfileIcon;
