import React from "react";

const DangerIcon = () => {
  return (
    <>
      <svg
        width="57"
        height="53"
        viewBox="0 0 57 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M54.5266 37.452L37.4599 6.73203C35.1666 2.5987 31.9933 0.332031 28.4999 0.332031C25.0066 0.332031 21.8333 2.5987 19.5399 6.73203L2.47327 37.452C0.313274 41.372 0.0732737 45.132 1.80661 48.092C3.53994 51.052 6.95327 52.6787 11.4333 52.6787H45.5666C50.0466 52.6787 53.4599 51.052 55.1933 48.092C56.9266 45.132 56.6866 41.3454 54.5266 37.452ZM26.4999 18.9987C26.4999 17.9054 27.4066 16.9987 28.4999 16.9987C29.5933 16.9987 30.4999 17.9054 30.4999 18.9987V32.332C30.4999 33.4254 29.5933 34.332 28.4999 34.332C27.4066 34.332 26.4999 33.4254 26.4999 32.332V18.9987ZM30.3933 42.2254C30.2599 42.332 30.1266 42.4387 29.9933 42.5454C29.8333 42.652 29.6733 42.732 29.5133 42.7854C29.3533 42.8654 29.1933 42.9187 29.0066 42.9454C28.8466 42.972 28.6599 42.9987 28.4999 42.9987C28.3399 42.9987 28.1533 42.972 27.9666 42.9454C27.8066 42.9187 27.6466 42.8654 27.4866 42.7854C27.3266 42.732 27.1666 42.652 27.0066 42.5454C26.8733 42.4387 26.7399 42.332 26.6066 42.2254C26.1266 41.7187 25.8333 41.0254 25.8333 40.332C25.8333 39.6387 26.1266 38.9454 26.6066 38.4387C26.7399 38.332 26.8733 38.2254 27.0066 38.1187C27.1666 38.012 27.3266 37.932 27.4866 37.8787C27.6466 37.7987 27.8066 37.7454 27.9666 37.7187C28.3133 37.6387 28.6866 37.6387 29.0066 37.7187C29.1933 37.7454 29.3533 37.7987 29.5133 37.8787C29.6733 37.932 29.8333 38.012 29.9933 38.1187C30.1266 38.2254 30.2599 38.332 30.3933 38.4387C30.8733 38.9454 31.1666 39.6387 31.1666 40.332C31.1666 41.0254 30.8733 41.7187 30.3933 42.2254Z"
          fill="#FB0303"
        />
      </svg>
    </>
  );
};

export default DangerIcon;
