import React, { useState } from "react";
import { ICreateExpenseData, ICustomer } from "../../../@types";
import { CloseIcon, SearchIcon } from "../../../assets/icons";
import { Tooltip } from "../../../assets/icons/Tooltip";
import { useCustomerSearch } from "../../../hooks";
import { SearchInput } from "../../common";
import { CheckboxComponent } from "../../core/Forms";

interface BillableExpenseProps {
  validationResults: ICreateExpenseData;
  formData: ICreateExpenseData;
  handleTextAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  total: number;
  expense: ICreateExpenseData;
  handleSelectCustomer: (selectedCustomer: ICustomer | null) => void;
  validateCurrentStep: () => boolean;
  customer: ICustomer | undefined;
  isBillable: boolean;
  toolTipText?: string;
  handleIsBillableChange: () => void;
  errorMessage: string | null;
}

const BillableExpense: React.FC<BillableExpenseProps> = ({
  validationResults,
  handleTextAreaChange,
  formData,
  expense,
  validateCurrentStep,
  handleSelectCustomer,
  customer,
  isBillable,
  toolTipText,
  handleIsBillableChange,
  errorMessage,
}) => {
  const {
    data: customers,
    isSearching: isCustomerSearching,
    handleSearch: handleCustomerSearch,
  } = useCustomerSearch();
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [searchValue, setSearchValue] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleCustomerSelection = (selectedCustomer: ICustomer | null) => {
    handleSelectCustomer(selectedCustomer); // Pass the selected customer to the parent handler
    setSearchValue(""); // Clear the search input value
    setIsDropdownOpen(false); // Close the dropdown
  };

  const toggleDropdown = (isOpen: boolean) => {
    setIsDropdownOpen(isOpen);
  };
  return (
    <>
      <div className="flex flex-col text-left bg-[#F3FBFB] px-[1em] py-[0.7em]">
        <div className="flex justify-between gap-[6em]">
          <div className="flex justify-start items-start gap-3">
            <label
              htmlFor="reoccurring"
              className="font-semibold block text-[14px] lg:text-[16px] lg:ml-0 lg:mt-[0.5em]"
            >
              Billable Expense
            </label>

            {toolTipText && (
              <div
                className="relative lg:mt-[0.8em]"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Tooltip />
                {isHovered && (
                  <div className="absolute w-[204px] left-[-5.7em] z-10">
                    <div className="flex items-center justify-center">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L17.6603 15H0.339746L9 0Z"
                          fill="#FFF4C6"
                        />
                      </svg>
                    </div>
                    <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                      {toolTipText}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <span className="inline-flex gap-[8.5px]">
            <div className="mt-[0.5em] ml-[]">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value="isBillable"
                  className="sr-only peer"
                  onChange={handleIsBillableChange}
                  name="isBillable"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
              </label>
            </div>
          </span>
        </div>
        <div>
          {isBillable && (
            <div className="mt-[3em]">
              <span className="text-red-500 ml-[] text-[12px] mt-[-2em]"></span>
              <div className="md:w-[100%] w-[100%] mt-[-2em] relative">
                <SearchInput
                  placeholder="Search Customer"
                  displayKey="name"
                  onSelect={handleCustomerSelection}
                  handleChangeText={(text) => {
                    setSearchValue(text);
                    handleCustomerSearch(text);
                    toggleDropdown(true);
                  }}
                  selected={customer}
                  data={
                    isDropdownOpen
                      ? customers.map((customer) => ({
                          ...customer,
                          display: `${customer.name} (${customer.phone})`,
                        }))
                      : []
                  }
                  isLoading={isCustomerSearching}
                  clearIcon={CloseIcon}
                  clearIconClass="lg:mr-[0.8em] mr-[2em] md:mt-[0.75em] md:mr-[1.5em] "
                  error={validationResults.customerId}
                  dropdownClassName="w-[100%]"
                  classNames="min-w-[100%] 390:w-[300px] md:w-[320px]  xl:w-[300px] lg:w-full border border-gray-300 bg-white max-h-[48px]  md:w-[100%] min-h-[48px] placeholder:text-[15.25px] h-[39.77px] rounded p-2 focus:outline-none focus:shadow-outline appearance-none lg:focus:ring-0 leading-normal"
                  searchKey={"name"}
                  icon={<SearchIcon />}
                  value={searchValue}
                />

                {validationResults.customerId && (
                  <span className="text-[red]">
                    {validationResults.customerId}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BillableExpense;
