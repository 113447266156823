import React from "react";
import { subscriptionHistory } from "../../../state";
import ListInventoryBody from "../../inventory/list/ListInventoryBody";
import { HistoryTable } from "../../../@types/subscripton";

interface SubscriptionHistoryProps {
  history: HistoryTable[];
  isLoading: boolean;
}

const SubscriptionHistory = ({
  history,
  isLoading,
}: SubscriptionHistoryProps) => {
  const [data, setData] = React.useState<typeof history>([]);

  React.useEffect(() => {
    setData(history);
  }, [history]);

  const tableHead = [
    {
      dataIndex: "plan",
      title: " ITEM NAME",
    },
    {
      dataIndex: "startDate",
      title: " START DATE",
    },
    {
      dataIndex: "endDate",
      title: "END DATE",
    },
    {
      dataIndex: "costPerMonth",
      title: "AMOUNT",
    },
  ];

  return (
    <div>
      <ListInventoryBody
        data={data}
        tableHead={tableHead}
        isLoading={isLoading}
        showPagination
      />
    </div>
  );
};

export default SubscriptionHistory;
