import React, { useEffect, useRef, useState } from "react";
import Joyride, { CallBackProps, Step } from "react-joyride";
import { InfoBigCircle, InfoCircle } from "../../../assets/icons/InfoCircle";
import InfoIcon from "../../../assets/icons/InfoIcon";
import { BUSINESS_TYPE_RETAIL } from "../../../constants";
import { useUserStore } from "../../../state";
import MobileSideMenu from "../../sidemenu/MobileSideMenu";
import WelcomeModal from "./StartModal";
import { useTranslation } from "react-i18next";

const ViewInvoiceTour: React.FC = () => {
  const { t } = useTranslation("invoices");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const desktopSteps: Step[] = [
    {
      target: ".menu-item-11",
      title: (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-1 flex-1 justify-center mt-3">
              <span className="mt-[0.2em]">
                {" "}
                <InfoCircle />
              </span>
              <p className="text-[16px] text-[#404040]">
                Generate Payment Link
              </p>
            </div>
            <button
              onClick={() => setIsTourActive(false)}
              className="text-[#404040] focus:outline-none shadow-md bg-[#F4F7FF] w-6 mt-[-1.5em]"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
        </>
      ),
      content: (
        <p
          style={{ color: "#000000" }}
          className="xl:text-[14px] lg:text-[14px] text-[12px]"
          dangerouslySetInnerHTML={{
            __html: t("viewtour1", {
              ease: '<span style="color: #044E97 !important;">ease</span>',
              easily: '<span style="color: #044E97 !important;">easily</span>',
              sharp: '<span style="color: #044E97 !important;">sharp</span>',
            }),
          }}
        />
      ),
      disableBeacon: true,
      spotlightPadding: 8,
      disableScrolling: true,
    },
    {
      target: ".menu-item-12",
      title: (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-1 flex-1 justify-center mt-3">
              <span className="mt-[0.2em]">
                {" "}
                <InfoCircle />
              </span>
              <p className="text-[16px] text-[#404040]">Record Purchase</p>
            </div>
            <button
              onClick={() => setIsTourActive(false)}
              className="text-[#404040] focus:outline-none shadow-md bg-[#F4F7FF] w-6 mt-[-1.5em]"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
        </>
      ),
      content: (
        <p
          style={{ color: "#000000" }}
          className="xl:text-[14px] lg:text-[14px] text-[12px]"
        >
          {t("viewtour2")}
        </p>
      ),
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: ".menu-item-13",
      title: (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-1 flex-1 justify-center mt-3">
              <span className="mt-[0.2em]">
                {" "}
                <InfoCircle />
              </span>
              <p className="text-[16px] text-[#404040]">Cancel</p>
            </div>
            <button
              onClick={() => setIsTourActive(false)}
              className="text-[#404040] focus:outline-none shadow-md bg-[#F4F7FF] w-6 mt-[-1.5em]"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
        </>
      ),
      content: (
        <p
          style={{ color: "#000000" }}
          className="xl:text-[14px] lg:text-[14px] text-[12px]"
        >
          {t("viewtour3")}
        </p>
      ),
      disableBeacon: true,
      disableScrolling: true,
    },
  ];

  const mobileSteps: Step[] = [
    {
      target: ".menu-item-14",
      title: (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-1 flex-1 justify-center mt-3">
              <span className="mt-[0.2em]">
                {" "}
                <InfoCircle />
              </span>
              <p className="text-[16px] text-[#404040]">
                Generate Payment Link
              </p>
            </div>
            <button
              onClick={() => setIsTourActive(false)}
              className="text-[#404040] focus:outline-none shadow-md bg-[#F4F7FF] w-6 mt-[-1.5em]"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
        </>
      ),
      content: (
        <p
          style={{ color: "#000000" }}
          className="xl:text-[14px] lg:text-[14px] text-[12px]"
          dangerouslySetInnerHTML={{
            __html: t("viewtour1", {
              ease: '<span style="color: #044E97 !important;">ease</span>',
              easily: '<span style="color: #044E97 !important;">easily</span>',
              sharp: '<span style="color: #044E97 !important;">sharp</span>',
            }),
          }}
        />
      ),
      disableBeacon: true,
    },
    {
      target: ".menu-item-15",
      title: (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-1 flex-1 justify-center mt-3">
              <span className="mt-[0.2em]">
                {" "}
                <InfoCircle />
              </span>
              <p className="text-[16px] text-[#404040]">Record Purchase</p>
            </div>
            <button
              onClick={() => setIsTourActive(false)}
              className="text-[#404040] focus:outline-none shadow-md bg-[#F4F7FF] w-6 mt-[-1.5em]"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
        </>
      ),
      content: (
        <p
          style={{ color: "#000000" }}
          className="xl:text-[14px] lg:text-[14px] text-[12px]"
        >
          {t("viewtour2")}
        </p>
      ),
      disableBeacon: true,
    },
    {
      target: ".menu-item-16",
      title: (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-1 flex-1 justify-center mt-3">
              <span className="mt-[0.2em]">
                {" "}
                <InfoCircle />
              </span>
              <p className="text-[16px] text-[#404040]">Cancel</p>
            </div>
            <button
              onClick={() => setIsTourActive(false)}
              className="text-[#404040] focus:outline-none shadow-md bg-[#F4F7FF] w-6 mt-[-1.5em]"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
        </>
      ),
      content: (
        <p
          style={{ color: "#000000" }}
          className="xl:text-[14px] lg:text-[14px] text-[12px]"
        >
          {t("viewtour3")}
        </p>
      ),
      disableBeacon: true,
    },
  ];
  const [isTourActive, setIsTourActive] = useState(false);
  const [isModalOpen, setModalOpen] = useState(true);
  const [stepIndex, setStepIndex] = useState(0);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const steps = isMobile ? mobileSteps : desktopSteps;

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    if (status === "finished" || status === "skipped") {
      setIsTourActive(false);
    }
  };

  useEffect(() => {
    if (isTourActive) {
      setStepIndex(0);
    }
  }, [isTourActive]);

  const handleShowMeClick = () => {
    setModalOpen(false);
    setIsTourActive(true);
    setShowMobileMenu(true);
  };
  const handleShowMobileMenu = () => {
    setShowMobileMenu((prev) => !prev);
  };

  return (
    <div className="">
      <Joyride
        steps={steps}
        continuous={true}
        showProgress={false}
        showSkipButton={false}
        hideCloseButton={true}
        run={isTourActive}
        disableScrollParentFix={true}
        disableOverlay={false}
        callback={handleJoyrideCallback}
        locale={{
          back: "Previous",
          next: "Next",
          last: "Finish",
          skip: "Skip",
        }}
        styles={{
          options: {
            arrowColor: "#fff",
            backgroundColor: "#ffffff",
            textColor: "#000000",
            width: 400,
            zIndex: 1000,
          },
          buttonNext: {
            backgroundColor: "#044E97",
            color: "#fff",
            borderRadius: "8px",
            padding: isMobile ? "10px 60px" : "15px 70px",
            fontSize: isMobile ? "14px" : "16px",
            fontWeight: "bold",
            marginLeft: "auto",
          },
          buttonBack: {
            backgroundColor: "#fff",
            marginRight: 10,
            padding: isMobile ? "10px 50px" : "15px 55px",
            borderRadius: "8px",
            color: "#044E97",
            border: "2px solid #044E97",
            fontSize: isMobile ? "14px" : "16px",
            fontWeight: "bold",
            alignSelf: "flex-start",
          },

          tooltip: {
            backgroundColor: "#fff",
            borderRadius: "8px",
            padding: "20px",
          },
          tooltipTitle: {
            fontSize: "18px",
            fontWeight: "bold",
            color: "#5D6D73",
          },
          tooltipContent: {
            fontSize: "16px",
            color: "#000000",
          },
        }}
      />
      {isModalOpen && (
        <WelcomeModal
          onClose={() => setModalOpen(false)}
          onShowMeClick={handleShowMeClick}
          message1=""
          message2="Access premium features like automated invoicing, expense tracking, and detailed financial reports. Lets explore together."
        />
      )}
    </div>
  );
};

export default ViewInvoiceTour;
