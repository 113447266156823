import React from "react";

const ProcessSuccessful = ({ width = "259", height = "250" }) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 259 250"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          cx="135.925"
          cy="138.704"
          rx="64.1709"
          ry="64.0594"
          fill="#088306"
        />
        <ellipse
          opacity="0.6"
          cx="53.6014"
          cy="74.6453"
          rx="10.6951"
          ry="10.6766"
          fill="#FF6702"
        />
        <ellipse
          opacity="0.2"
          cx="53.6014"
          cy="205.673"
          rx="10.6951"
          ry="10.6766"
          fill="#FF6702"
        />
        <ellipse
          opacity="0.6"
          cx="206.472"
          cy="10.6766"
          rx="10.6951"
          ry="10.6766"
          fill="#FF6702"
        />
        <ellipse
          opacity="0.2"
          cx="195.777"
          cy="216.35"
          rx="5.34757"
          ry="5.33828"
          fill="#FF6702"
        />
        <ellipse
          opacity="0.6"
          cx="141.273"
          cy="244.663"
          rx="5.34757"
          ry="5.33828"
          fill="#FF6702"
        />
        <ellipse
          opacity="0.2"
          cx="237.426"
          cy="86.9359"
          rx="5.34757"
          ry="5.33828"
          fill="#FF6702"
        />
        <ellipse
          opacity="0.2"
          cx="114.629"
          cy="50.643"
          rx="5.34757"
          ry="5.33828"
          fill="#FF6702"
        />
        <ellipse
          opacity="0.6"
          cx="250.978"
          cy="150.437"
          rx="8.02136"
          ry="8.00743"
          fill="#FF6702"
        />
        <ellipse
          opacity="0.2"
          cx="8.02136"
          cy="126.972"
          rx="8.02136"
          ry="8.00743"
          fill="#FF6702"
        />
        <path
          d="M135.925 181.413C159.552 181.413 178.706 162.292 178.706 138.706C178.706 115.12 159.552 96 135.925 96C112.298 96 93.1445 115.12 93.1445 138.706C93.1445 162.292 112.298 181.413 135.925 181.413Z"
          fill="white"
        />
        <path
          d="M133.209 158.443L114.625 143.99L119.909 137.221L131.321 146.096L150.155 118.965L157.223 123.852L133.209 158.443Z"
          fill="#088306"
        />
      </svg>
    </div>
  );
};

export default ProcessSuccessful;
