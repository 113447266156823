import React, { ReactNode } from "react";

const CreateModal = ({ children }: { children: ReactNode }) => {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-gray-800 bg-opacity-80 flex items-center justify-center z-30 ">
      <div className="bg-white p-4 rounded-lg shadow-lg">{children}</div>
    </div>
  );
};

export { CreateModal };
