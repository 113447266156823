// The type for a single customer data
import { useSearch } from "../useSearch";
import { IService } from "../../@types";
import { serviceService } from "../../services/serviceService";

export const useServiceSearch = () => {
  return useSearch({
    service: serviceService,
    endpoint: "services/search",
    queryParameter: "q",
    responseMapper: (data: any[]): any =>
      data.map((service: IService) => ({
        ...service,
      })) as unknown as IService,
  });
};
