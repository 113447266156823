import React, { useState } from "react";
import { BankData } from "../../../types";
import { editBankAccount } from "../../../backend-services/bank";
import BankAccountForm from "./BankAccountForm";
import ErrorComponent from "../../Error";
import { useUserStore } from "../../../state";
import { useCompanyStore } from "../../../state/useCompanyStore";

interface EditProps {
  bankAccount: BankData;
  onEditSuccess: () => void;
  setCreateSuccess: (createSuccess: boolean) => void;
  accountIndex: number;
  cancelEdit: () => void;
}

export const EditBankAccount: React.FC<EditProps> = ({
  bankAccount,
  onEditSuccess,
  accountIndex,
  setCreateSuccess,
  cancelEdit,
}) => {
  const { setCompanyBankAccounts } = useCompanyStore();
  const [formData, setFormData] = useState<BankData>(bankAccount);
  const [bankNameInput, setBankNameInput] = useState<string>(
    bankAccount.bankName,
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    setFormData(bankAccount);
    setBankNameInput(bankAccount.bankName);
  }, [bankAccount]);

  const handleEdit = async (data: BankData) => {
    setIsLoading(true);
    try {
      setFormData(data);
      setErrorMessage("");
      const {
        id,
        bankName,
        accountName,
        accountNumber,
        currency,
        isActive,
        settlement_bank,
      } = data;
      const updatedData = {
        id,
        bankName,
        accountName,
        accountNumber,
        currency,
        isActive,
        settlement_bank,
      };
      const res = await editBankAccount(accountIndex, updatedData);
      setCreateSuccess(true);
      const updatedUserBankAccounts = res?.bankAccounts;
      setCompanyBankAccounts(updatedUserBankAccounts);
      onEditSuccess();
      setIsLoading(false);
    } catch (error) {
      setErrorMessage("Error editing bank account");
      setIsLoading(false);
      return <ErrorComponent errorCode={500} />;
    }
  };

  return (
    <>
      <BankAccountForm
        initialData={formData}
        edit={true}
        onSubmit={handleEdit}
        onClose={cancelEdit}
        isLoading={isLoading}
        title="Edit Bank Account"
      />

      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </>
  );
};
