import React, { useLayoutEffect } from "react";
import { ICreateSalesData } from "../../../@types";
import { useLocation, useParams } from "react-router-dom";
import InvoiceFreeform from "../../../components/Sales/invoice/InvoiceFreeform";
import QuoteFreeform from "../../../components/Sales/quotes/QuoteFreeform";

const QuoteFreeformPage = () => {
  const { expenseId } = useParams<{ expenseId: string }>();
  const location = useLocation();
  const [quoteDataState, setQuoteDataState] = React.useState<ICreateSalesData>({
    additionalInformation: "",
    customerId: "",
    description: "",
    discount: 0,
    inventories: [],
    expiryDate: "",
    invoiceNumber: "",
    issueDate: new Date().toISOString().substring(0, 10),
    isReoccurring: false,
    isReoccurringEndDate: "",
    isReoccurringStartDate: "",
    reoccurringGroupName: "",
    status: "active",
    tax: 0,
    isPaymentCompleted: false,
    shouldSendEmail: true,
    exchangeRate: 0,
    currency: "",
  });

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <>
      <QuoteFreeform
        quoteData={quoteDataState}
        setQuoteData={setQuoteDataState}
        headingText="Add Quote"
        customerId={""}
      />
    </>
  );
};

export { QuoteFreeformPage };
