import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
interface ExpenseInfoWidgetProps {
  getBudget: () => Promise<
    { actualExpenses: number; allocatedBudget: number }[]
  >;
  Icon: React.ComponentType<{ className?: string }>;
}

const ExpenseInfoWidget: React.FC<ExpenseInfoWidgetProps> = ({
  getBudget,
  Icon,
}) => {
  const [expenseData, setExpenseData] = useState<
    { actualExpenses: number; allocatedBudget: number }[]
  >([]);

  useEffect(() => {
    const fetchExpenseData = async () => {
      try {
        const data = await getBudget();
        setExpenseData(data);
      } catch (error) {}
    };

    fetchExpenseData();
  }, [getBudget]);

  const latestExpenseData = expenseData[expenseData.length - 1] || {
    actualExpenses: 0,
    allocatedBudget: 0,
  };
  const expensePercentage: any =
    latestExpenseData.allocatedBudget !== 0
      ? (latestExpenseData.actualExpenses / latestExpenseData.allocatedBudget) *
        100
      : 0;

  return (
    <div className="">
      <div className="text-center xl:text-[22px] text-[16px] ">
        <span>
          Please note that you have used <br /> up{" "}
          {expensePercentage > 0 && (
            <span
              className={
                expensePercentage > 70 ? "text-red-500" : "text-blue-500"
              }
            >
              {expensePercentage.toFixed(2) + "% "}
            </span>
          )}
          of your monthly budget.
        </span>
      </div>
      <div className="text-center mt-2 md:ml-[12em] lg:ml-[15em] xl:ml-[20em]">
        <Icon className="w-6 h-6 mx-auto md:ml-[10em]" />
      </div>
    </div>
  );
};

export default ExpenseInfoWidget;
