import { END_DATE, SELECTED_LABEL, START_DATE } from "../constants/dashboard";

export function saveDateRangeToLocalStorage(startDate: any, endDate: any) {
  localStorage.setItem(START_DATE, startDate.toISOString());
  localStorage.setItem(END_DATE, endDate.toISOString());
}
export function retrieveDateRangeToLocalStorage() {
  const storedStartDate = localStorage.getItem(START_DATE);
  const storedEndDate = localStorage.getItem(END_DATE);
  const storedSelectedLabelString = localStorage.getItem(SELECTED_LABEL);
  let storedSelectedLabel = null;

  if (storedSelectedLabelString) {
    try {
      storedSelectedLabel = JSON.parse(storedSelectedLabelString);
    } catch (error) {
      console.error("Error parsing JSON from localStorage:", error);
      storedSelectedLabel = null;
    }
  }
  return { storedStartDate, storedEndDate, storedSelectedLabel };
}
