import React from "react";

interface BinIconProps {
  color?: string;
}

const BinIcon: React.FC<BinIconProps> = (props) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M6.66675 10L6.66675 8"
          stroke={props.color || "white"}
          strokeLinecap="round"
        />
        <path
          d="M9.33325 10L9.33325 8"
          stroke={props.color || "white"}
          strokeLinecap="round"
        />
        <path
          d="M2 4.66797H14V4.66797C12.8954 4.66797 12 5.5634 12 6.66797V9.33464C12 11.2203 12 12.1631 11.4142 12.7488C10.8284 13.3346 9.88562 13.3346 8 13.3346V13.3346C6.11438 13.3346 5.17157 13.3346 4.58579 12.7488C4 12.1631 4 11.2203 4 9.33463V6.66797C4 5.5634 3.10457 4.66797 2 4.66797V4.66797Z"
          stroke={props.color || "white"}
          strokeLinecap="round"
        />
        <path
          d="M6.71202 2.24706C6.78798 2.17618 6.95538 2.11355 7.18824 2.06888C7.4211 2.02421 7.70641 2 7.99992 2C8.29343 2 8.57874 2.02421 8.8116 2.06888C9.04446 2.11355 9.21185 2.17618 9.28782 2.24706"
          stroke={props.color || "white"}
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default BinIcon;
