import React from "react";

interface DeleteIconProps {
  size?: string;
}

const DeleteUserIcon: React.FC<DeleteIconProps> = ({ size = "24" }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.25 4.58398L15.7336 12.9382C15.6016 15.0727 15.5356 16.1399 15.0006 16.9072C14.7361 17.2866 14.3955 17.6067 14.0006 17.8473C13.2018 18.334 12.1325 18.334 9.99395 18.334C7.8526 18.334 6.78192 18.334 5.98254 17.8464C5.58733 17.6054 5.24666 17.2847 4.98224 16.9047C4.4474 16.1361 4.38288 15.0674 4.25384 12.9299L3.75 4.58398"
          stroke="#D92D20"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M2.5 4.58366H17.5M13.3798 4.58366L12.8109 3.4101C12.433 2.63054 12.244 2.24076 11.9181 1.99766C11.8458 1.94374 11.7692 1.89578 11.6892 1.85424C11.3283 1.66699 10.8951 1.66699 10.0288 1.66699C9.14069 1.66699 8.69665 1.66699 8.32974 1.86209C8.24842 1.90533 8.17082 1.95524 8.09774 2.0113C7.76803 2.26424 7.58386 2.66829 7.2155 3.47637L6.71077 4.58366"
          stroke="#D92D20"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M7.91797 13.75L7.91797 8.75"
          stroke="#D92D20"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M12.082 13.75L12.082 8.75"
          stroke="#D92D20"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );
};

export default DeleteUserIcon;
