import React from "react";
import { AuthInfo } from ".";

interface Props {
  children: any;
  backgroundClass: string;
}

const AuthLayout: React.FC<Props> = ({ children, backgroundClass }) => {
  return (
    <div className="w-full overflow-x-hidden ">
      <div className="flex min-h-screen border ">
        <div className="hidden lg:block w-full lg:w-1/2 ">
          <AuthInfo backgroundClass={backgroundClass} />
        </div>
        <div className="w-full lg:w-1/2 bg-[#fff] px-[4.1% lg:px-0]">
          {children}
        </div>
      </div>
    </div>
  );
};

export { AuthLayout };
