import * as React from "react";
import { EmptyIcon } from "../../../assets/icons/NotificationsEmptyIcon";

type EmptyArchiveProps = {
  EmptyText?: string;
};

const EmptyArchive = ({ EmptyText }: EmptyArchiveProps) => {
  return (
    <div>
      <div className="w-full bg-white pt-[86px] min-h-screen flex flex-col items-center gap-12">
        <div className="w-[160px] flex flex-col items-center gap-12 md:w-full">
          <EmptyIcon />
        </div>
        <div className="flex items-center justify-center w-[265px] md:w-[409px]">
          <p className="font-medium text-black text-center">{EmptyText}</p>
        </div>
      </div>
    </div>
  );
};

export default EmptyArchive;
