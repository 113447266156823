import { invoiceService } from "../services";
import { withRetry } from "../utils";

export const downloadReceipt = async (id: string) => {
  return withRetry(async () => {
    const res = await invoiceService.get(`/invoices/${id}/download-receipt`);
    return res.data;
  }).catch((e: any) => {
    throw new Error(e.response?.data || e.message);
  });
};

export const sendReceipt = async (
  id: string,
  medium: { whatsapp: boolean; email: boolean; sms: boolean },
) => {
  return withRetry(async () => {
    if (medium.whatsapp) {
      const res = await invoiceService.patch(`/invoices/${id}/send-receipt`, {
        shouldSendWhatsapp: true,
      });
      return res.data;
    }
    if (medium.email) {
      const res = await invoiceService.patch(`/invoices/${id}/send-receipt`, {
        shouldSendEmail: true,
      });
      return res.data;
    }
    if (medium.sms) {
      const res = await invoiceService.patch(`/invoices/${id}/send-receipt`, {
        shouldSendSMS: true,
      });
      return res.data;
    }
  }).catch((e: any) => {
    throw new Error(e.response?.data || e.message);
  });
};
