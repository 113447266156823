import React from "react";

const DownloadingLogo = () => {
  return (
    <div>
      <svg
        width="124"
        height="124"
        viewBox="0 0 124 124"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_47022_103647)">
          <circle
            opacity="0.3"
            cx="61.7524"
            cy="52.1782"
            r="41.7524"
            fill="#D9D9D9"
          />
          <circle cx="61.625" cy="52" r="32" fill="white" />
          <path
            d="M66.2054 56.3635C66.4726 56.6298 66.6228 56.9793 66.6329 57.3521C66.6295 57.3621 66.6295 58.4505 66.6295 58.4505H65.778C65.2538 58.4505 64.8297 58.8732 64.8297 59.3958V63.1204C62.3419 65.1241 58.996 65.7232 57.1094 65.7232C58.6554 64.981 59.8843 62.2882 60.8126 59.3492V59.3925C60.8126 59.7586 61.1098 60.0582 61.4804 60.0582H63.0699C63.4372 60.0582 63.7377 59.7586 63.7377 59.3925V57.3454C63.7444 56.976 63.8947 56.6298 64.1618 56.3635C64.4323 56.0973 64.7829 55.9475 65.1569 55.9375H65.217C65.5777 55.9475 65.9216 56.0906 66.1887 56.3436C66.1921 56.3502 66.1988 56.3569 66.2054 56.3635Z"
            fill="url(#paint0_linear_47022_103647)"
          />
          <path
            d="M80.4485 42.6367C77.9608 40.603 73.74 40.137 72.2106 42.1175C71.0218 43.6585 70.1002 49.297 68.7411 54.8556C68.6042 54.6592 68.4473 54.4761 68.2737 54.303C67.4555 53.4876 66.3736 53.0349 65.2216 53.0249C65.2116 53.0249 65.1982 53.0216 65.1849 53.0216C65.1715 53.0216 65.1582 53.0249 65.1448 53.0249C64.0128 53.0349 62.9442 53.4742 62.1328 54.2697C62.5636 52.286 62.8341 50.7149 62.9442 50.2689C63.2882 48.8809 64.6606 38.9953 69.2921 37.4343C73.002 36.1861 78.2747 40.3833 80.4485 42.6367Z"
            fill="url(#paint1_linear_47022_103647)"
          />
          <path
            d="M55.8571 43.6724C57.5067 38.4001 63.524 37.082 66.3256 37.082C62.7226 38.8162 60.8326 51.1316 60.492 52.5162C60.148 53.9042 58.7756 63.7898 54.1407 65.3508C50.4342 66.599 45.1582 62.4018 42.9844 60.1484C45.4721 62.1821 49.6929 62.6481 51.2223 60.6677C52.7216 58.7238 53.7968 50.2628 55.8571 43.6724Z"
            fill="#FF6702"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M74.5842 59.1092H68.9075V57.395C68.9075 56.3798 68.4901 55.4578 67.8223 54.7888C67.1511 54.1198 66.2261 53.707 65.2077 53.707C64.1892 53.707 63.2642 54.1231 62.5931 54.7921C61.9219 55.4611 61.5078 56.3831 61.5078 57.3983V59.3888H63.0973V57.3783C63.0973 56.7992 63.3344 56.2733 63.7184 55.8905C64.1024 55.5077 64.63 55.2714 65.211 55.2714C65.792 55.2714 66.3196 55.5077 66.7037 55.8905C67.0877 56.2733 67.3248 56.7992 67.3248 57.3783V59.1125H65.8021C65.6485 59.1125 65.5216 59.239 65.5216 59.3921V65.3135C65.5216 65.4666 65.6485 65.593 65.8021 65.593H74.5876C74.7412 65.593 74.8681 65.4666 74.8681 65.3135V59.3921C74.8681 59.239 74.7412 59.1125 74.5876 59.1125L74.5842 59.1092ZM70.083 62.3478L69.6388 64.3981H70.861L70.5037 62.3245C70.8376 62.218 71.078 61.9051 71.078 61.539C71.078 61.083 70.7074 60.7135 70.2499 60.7135C69.7924 60.7135 69.4218 61.083 69.4218 61.539C69.4218 61.9384 69.7056 62.2712 70.0863 62.3478H70.083Z"
            fill="#0F3822"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_47022_103647"
            x="0.30769"
            y="0.579626"
            width="122.889"
            height="122.889"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="9.84616" />
            <feGaussianBlur stdDeviation="9.84616" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.403922 0 0 0 0 0.00784314 0 0 0 0.16 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_47022_103647"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_47022_103647"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_47022_103647"
            x1="58.8391"
            y1="62.2783"
            x2="79.8957"
            y2="53.3626"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#044E97" />
            <stop offset="1" stop-color="#11ABD1" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_47022_103647"
            x1="62.1328"
            y1="46.0318"
            x2="80.4485"
            y2="46.0318"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#044E97" />
            <stop offset="1" stop-color="#11ABD1" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default DownloadingLogo;
