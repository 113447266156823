import * as React from "react";
import Empty from "./svgicons/Empty.svg";

interface EmptyIconProps {
  color?: string;
  style?: React.CSSProperties;
}

const EmptyIcon: React.FC<EmptyIconProps> = (props) => {
  return <img {...props} src={Empty} alt="notifications empty icon" />;
};

export { EmptyIcon };
