import React from "react";
import { useSubscriptionStore } from "../../state";
import { useNavigate } from "react-router-dom";
import SubscriptionBanner from "./banner";

const SubscribeTag = () => {
  const navigate = useNavigate();
  const { subscription, subscriptionType } = useSubscriptionStore();
  const [showBanner, setShowBanner] = React.useState(true);
  const daysLeft = subscription.daysTotal - subscription.daysUsed;

  return (
    <>
      {showBanner && subscriptionType === "free" && (
        <SubscriptionBanner onClose={() => setShowBanner(false)} />
      )}
    </>
  );
};

export default SubscribeTag;
