import React, { ReactNode } from "react";
import { ListInfoCard, ListInfoCardProps } from "../desktop/ListInfoCard";
import MobileHead from "./MobileHead";
import { Filter } from "../../../@types/invoice";
import { FilterOption } from "../desktop/ListFilter";
import { FilterCategory } from "./MobileFilter";
import BudgetHistoryWidget from "../../expense/BudgetHistoryWidget";

interface ListOverviewProps {
  showCalendar?: boolean;
  filterByDate?: boolean;
  handleCalendarToggle?: () => void;
  selectedLabel?: string;
  handleCreate: () => void;
  infoCardData: ListInfoCardProps[];
  showReOccurring?: boolean;
  showBudgetCard?: boolean;
  showFilter?: boolean;
  pageTitle: string;
  handleSearch?: (text: string) => void;
  handleFilter?: (filter: Filter) => void;
  handleSearchSelect?: (item: any) => void;
  isSearching?: boolean | undefined;
  searchedDone?: boolean | undefined;
  setSearchedDone?: (searchedDone: boolean) => void;
  searchResult: any[];
  searchDisplayKey: string;
  searchPlaceHolder: string;
  modules?: string;
  selectedFilter?: Filter | undefined;
  categories: FilterCategory[];
  onSubmit?: (filter: Record<string, string | boolean>) => void;
  clearAllFilters?: () => void;
}

const MobileListOverview: React.FC<ListOverviewProps> = ({
  showCalendar,
  handleCalendarToggle,
  selectedLabel,
  handleCreate,
  infoCardData,
  showReOccurring,
  showBudgetCard,
  pageTitle,
  filterByDate = true,
  handleFilter,
  handleSearchSelect,
  handleSearch,
  selectedFilter,
  searchDisplayKey,
  searchPlaceHolder,
  searchResult,
  searchedDone,
  setSearchedDone,
  isSearching,
  categories,
  onSubmit,
  showFilter = true,
  clearAllFilters,
  modules,
}) => {
  return (
    <div>
      <div className={`${showBudgetCard ? "flex gap-4" : "md:block"}`}>
        <div className={`flex gap-3 w-full`}>
          {infoCardData.slice(0, 2).map((cardData, index) => (
            <ListInfoCard key={index} {...cardData} />
          ))}
        </div>
        {infoCardData.length > 2 && (
          <div className="flex lg:hidden gap-3 mt-3 w-full">
            {infoCardData.slice(2, 4).map((cardData, index) => (
              <ListInfoCard key={index} {...cardData} />
            ))}
          </div>
        )}
      </div>
      {showReOccurring && (
        <div className="flex justify-between py-2 text-[16px] text-[#000] font-semibold mt-6">
          <p>All Invoices</p>
          <p className="text-[#0469C5]">Recurring</p>
        </div>
      )}

      {showBudgetCard && <BudgetHistoryWidget />}

      <MobileHead
        handleSearchSelect={handleSearchSelect}
        handleSearch={handleSearch}
        searchResult={searchResult}
        searchDisplayKey={searchDisplayKey}
        handleFilter={handleFilter}
        searchPlaceHolder={searchPlaceHolder}
        isSearching={isSearching}
        searchedDone={searchedDone}
        setSearchedDone={setSearchedDone}
        categories={categories}
        filter={selectedFilter}
        onSubmit={onSubmit}
        showCalendar={showCalendar}
        handleCalendarToggle={handleCalendarToggle}
        selectedLabel={selectedLabel}
        filterByDate={filterByDate}
        showFilter={showFilter}
        clearAllFilters={clearAllFilters}
        modules={modules}
      />
    </div>
  );
};

export default MobileListOverview;
