export const validateLoginForm = (formData: any) => {
  const errors = {
    email: "",
    password: "",
  };

  if (!formData.email) {
    // Check if email is empty
    errors.email = "Email is required";
  }
  if (!formData.password) {
    // Check if password is empty
    errors.password = "Password is required";
  }

  return errors;
};
