import { useState, useEffect } from "react";
import { CompanyStatsData } from "../../@types/invoice";
import { getCompanyStats } from "../../backend-services";

export function useCompanyStatsData(startDate: Date, endDate: Date) {
  const [companyData, setCompanyData] = useState<CompanyStatsData | null>(null);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    try {
      const response = await getCompanyStats(
        startDate.toISOString(),
        endDate.toISOString(),
      );
      const data: CompanyStatsData = {
        totalInvoices: response.invoices.totalInvoices,
        totalExpenses: response.expenses.totalExpenses,
        totalCustomers: response.customers.value,
        totalInventory: response.inventory.totalInventories,
        totalServices: response.services.totalServices,
        dateRange: `${startDate.toDateString()} - ${endDate.toDateString()}`,
      };
      setCompanyData(data);
      setError(null);
    } catch (error) {
      setError("Failed to fetch company statistics");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { companyData, error };
}
