import React from "react";
import { DropdownComponent, InputComponent } from "../../core/Forms";
import { addCommasToNumber } from "../../../utils/convertToFloat";
import { clearFieldError, handleErrors } from "../../../utils";
import { getMonthLabel } from "../../../utils/DateTimeRange";
import { Button } from "../Button";
import { validateBudgetForm } from "../../../modules/settings/settings";
import { initialBudgetState } from "../../../constants";
import { useBudgetStore } from "../../../state/useBudgetStore";

interface BudgetModalProps {
  setShowModal: (showModal: boolean) => void;
}

const BudgetModal = ({ setShowModal }: BudgetModalProps) => {
  const [selectedValue, setSelectedValue] = React.useState("");
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState(initialBudgetState);
  const { updateExistingBudget } = useBudgetStore();
  const [validationResults, setValidationResults] = React.useState({
    monthYear: "",
    allocatedBudget: "",
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    clearFieldError(name, validationResults, setValidationResults);
  };

  const handleDropDownChange = (name: string, selectedValue: string) => {
    const [month, year] = selectedValue.split("-");
    const formattedValue = `${month.padStart(2, "0")}-${year}`;
    setFormData({ ...formData, monthYear: formattedValue });
    clearFieldError(name, validationResults, setValidationResults);
  };

  const dropDownArray = [];
  for (let month = currentMonth; month <= 12; month++) {
    dropDownArray.push({
      value: `${month.toString().padStart(2, "0")}-${currentYear}`,
      label: getMonthLabel(month),
    });
  }
  for (let year = currentYear + 1; year <= currentYear + 0; year++) {
    for (let month = 1; month <= 12; month++) {
      dropDownArray.push({
        value: `${month.toString().padStart(2, "0")}-${year}`,
        label: getMonthLabel(month),
      });
    }
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const hasErrors = validateBudgetForm(formData, setValidationResults);
      if (!hasErrors) {
        const data = {
          ...formData,
          allocatedBudget: Number(formData.allocatedBudget),
        };
        await updateExistingBudget(data);
        setShowModal(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div
      data-testid="budget-modal"
      className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh]"
    >
      <div className="flex justify-center items-center h-full w-full">
        <div
          className={`fixed md:top-[25%] z-50 w-[91%] md:w-[482px] p-4 bg-white rounded-[8px]`}
        >
          <h1 className="text-[16px] md:text-[20px] font-semibold">
            Set Budget
          </h1>
          <p className="mt-2 mb-3 md:my-3 text-[14px] md:text-[16px]">
            Set a monthly budget for your expenses.
          </p>
          <hr className="border border-[#BCBCBC] w-full" />

          <div className="mt-4 space-y-4 md:mt-8 md:space-y-6">
            <div>
              <div className="text-[14px] md:text-[16px] mb-2">Amount</div>
              <InputComponent
                placeholder="Amount"
                name="allocatedBudget"
                type="text"
                pattern="[0-9]*"
                value={addCommasToNumber(formData.allocatedBudget)}
                handleChange={handleInputChange}
                className="w-full"
                error={validationResults.allocatedBudget}
              />
            </div>
            <div className="w-full">
              <div className="text-[14px] md:text-[16px] text-black mb-2">
                Select Month
              </div>
              <DropdownComponent
                options={dropDownArray}
                placeholder="Select Month"
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                onChange={handleDropDownChange}
                error={validationResults.monthYear}
                dropdownClassName="w-full bg-white rounded focus:ring-0 lg:pointer-events lg:placeholder-gray-400 lg:placeholder-opacity-75 border"
                iconClassName="mt-[2px]"
              />
              {validationResults.monthYear && (
                <div className="text-red-500">
                  {validationResults.monthYear}
                </div>
              )}
            </div>
          </div>

          <div className="flex gap-4 mt-8">
            <Button
              onClick={() => setShowModal(false)}
              text="Cancel"
              size="full"
              font="semibold"
              variant="secondary"
              outline={true}
            />
            <Button
              text="Save Changes"
              size="full"
              font="semibold"
              variant="primary"
              onClick={handleSubmit}
              disabled={isLoading}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BudgetModal;
