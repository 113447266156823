import * as React from "react";

interface CloseIconProps {
  color?: string;
  width?: string;
  height?: string;
}
const CloseIcon: React.FC<CloseIconProps> = ({
  color,
  width = "20",
  height = "20",
}) => {
  return (
    <div className="ml-[1em] cursor-pointer">
      <svg
        width={width}
        height={width}
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.25 8.75L8.75 26.25"
          stroke={color || "currentColor"}
          strokeWidth="3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M8.75 8.75L26.25 26.25"
          stroke={color || "currentColor"}
          strokeWidth="3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export { CloseIcon };

export const CloseIconBig: React.FC<CloseIconProps> = ({ color }) => {
  return (
    <div className="ml-[1em] cursor-pointer">
      <svg
        width="40"
        height="40"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.25 8.75L8.75 26.25"
          stroke={color || "currentColor"}
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M8.75 8.75L26.25 26.25"
          stroke={color || "currentColor"}
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export const CloseIconSmall: React.FC<CloseIconProps> = ({ color }) => {
  return (
    <div className="ml-[1em] cursor-pointer">
      <svg
        width="15"
        height="15"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.25 8.75L8.75 26.25"
          stroke={color || "currentColor"}
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M8.75 8.75L26.25 26.25"
          stroke={color || "currentColor"}
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
