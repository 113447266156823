import React, { useState } from "react";
import {
  InventoryItem,
  errorProps,
  VariationItem,
} from "../../../@types/inventory";
import { SubtractSquareIcon } from "../../../assets/icons/SubtractSquareIcon";
import { VariationTable } from "./VariationTable";
import { InputComponent } from "../../core/Forms";
import { formatMoney, toSentenceCase } from "../../../utils";
import { commasToNumber } from "../../../utils/convertToFloat";
import { clearFieldError } from "../../../utils/handleErrors";
import { Tooltip } from "../../../assets/icons/Tooltip";
import { useTranslation } from "react-i18next";

interface InventoryTableProps {
  inventory: InventoryItem;
  setInventories: (inventories: InventoryItem) => void;
  variations: VariationItem[];
  setVariations: (Variations: VariationItem[]) => void;
  error: errorProps;
  setError: (error: errorProps) => void;
  variationList: any;
  toolTipText?: string;
  toolTipText2?: string;
  toolTipText3?: string;
  toolTipText4?: string;
}

function InventoryTable({
  inventory,
  setInventories,
  variations,
  setVariations,
  error,
  setError,
  variationList,
  toolTipText,
  toolTipText2,
  toolTipText3,
  toolTipText4,
}: InventoryTableProps) {
  const [variationName, setVariationName] = React.useState("");
  const [variationError, setVariationError] = useState<any[]>([]);
  const [priceInput, setPriceInput] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const { t } = useTranslation("inventories");
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    if (name === "name" || name === "description") {
      const sentenceValue = toSentenceCase(value);
      const updatedInventory = { ...inventory, [name]: sentenceValue };
      setInventories(updatedInventory);
      name === "name" && clearFieldError(name, error, setError);
    }

    if (name === "rate" || name === "costPrice" || name === "quantity") {
      const resValue = commasToNumber(value);
      const updatedInventory = { ...inventory, [name]: resValue };
      setInventories(updatedInventory);
      clearFieldError(name, error, setError);
    }
  };

  const handleAddVariation = (newVariation: any) => {
    setPriceInput(false);
    setVariations([...variations, newVariation]);
  };

  const handleRemoveVariation = (id: number) => {
    const updatedVariations = variations.filter(
      (variation, index) => index !== id,
    );
    setVariations(updatedVariations);
    setVariationName("");
    if (variations.length === 1) {
      setPriceInput(true);
    }
  };

  const handleUpdateVariation = (id: number, field: string, value: string) => {
    const updatedVariations = variations.map((variation, index) => {
      if (index === id) {
        if (field === "costPrice" || field === "price") {
          const varVal = commasToNumber(value);
          return { ...variation, [field]: varVal };
        } else if (field === "quantity") {
          const numericValue = value.replace(/\D/g, "");
          return { ...variation, [field]: numericValue };
        } else {
          return { ...variation, [field]: value };
        }
      }
      return variation;
    });
    setInventories({ ...inventory, costPrice: "", rate: "" });
    setVariations(updatedVariations);
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleMouseEnter2 = () => setIsHovered2(true);
  const handleMouseLeave2 = () => setIsHovered2(false);
  const handleMouseEnter3 = () => setIsHovered3(true);
  const handleMouseLeave3 = () => setIsHovered3(false);
  const handleMouseEnter4 = () => setIsHovered4(true);
  const handleMouseLeave4 = () => setIsHovered4(false);

  return (
    <div className="mt-8">
      <div className="text-[16px] text-[#212121]">
        <div className="lg:flex lg:gap-6 mb-6">
          <div className="w-full">
            <div className="flex gap-2 mb-1 md:mb-2">
              {t("inventory.name")}
              {toolTipText && (
                <div
                  className="relative mt-[0.2em]"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Tooltip />
                  {isHovered && (
                    <div className="absolute w-[204px] left-[-5.7em] z-10 ">
                      <div className="flex items-center justify-center">
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 0L17.6603 15H0.339746L9 0Z"
                            fill="#FFF4C6"
                          />
                        </svg>
                      </div>
                      <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                        {toolTipText}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <InputComponent
              className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4"
              type="text"
              name="name"
              placeholder="Inventory Name"
              value={inventory.name}
              handleChange={handleInputChange}
              error={error.name}
            />
          </div>
          {priceInput && (
            <div className="w-full">
              <div className="flex gap-2 mb-1 md:mb-2">
                Quantity
                {toolTipText2 && (
                  <div
                    className="relative mt-[0.2em]"
                    onMouseEnter={handleMouseEnter2}
                    onMouseLeave={handleMouseLeave2}
                  >
                    <Tooltip />
                    {isHovered2 && (
                      <div className="absolute w-[204px] left-[-5.7em] z-10">
                        <div className="flex items-center justify-center">
                          <svg
                            width="18"
                            height="15"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 0L17.6603 15H0.339746L9 0Z"
                              fill="#FFF4C6"
                            />
                          </svg>
                        </div>
                        <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                          {toolTipText2}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <InputComponent
                className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4"
                type="text"
                name="quantity"
                placeholder="Quantity"
                value={inventory.quantity}
                handleChange={handleInputChange}
                error={error.quantity}
              />
            </div>
          )}
        </div>
        {priceInput && (
          <div className="flex gap-6 mb-6">
            <div className="w-full">
              <div className="flex gap-2 mb-1 md:mb-2">
                {t("inventory.costPrice")}
                {toolTipText3 && (
                  <div
                    className="relative mt-[0.2em]"
                    onMouseEnter={handleMouseEnter3}
                    onMouseLeave={handleMouseLeave3}
                  >
                    <Tooltip />
                    {isHovered3 && (
                      <div className="absolute w-[204px] left-[-5.7em] z-10">
                        <div className="flex items-center justify-center">
                          <svg
                            width="18"
                            height="15"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 0L17.6603 15H0.339746L9 0Z"
                              fill="#FFF4C6"
                            />
                          </svg>
                        </div>
                        <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                          {toolTipText3}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <InputComponent
                className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4"
                type="text"
                placeholder={`${formatMoney(0.0, "NGN")}`}
                name="costPrice"
                value={inventory.costPrice}
                handleChange={handleInputChange}
                error={error.costPrice}
              />
            </div>
            <div className="w-full">
              <div className="flex gap-2 mb-1 md:mb-2">
                {t("inventory.sellingPrice")}
                {toolTipText4 && (
                  <div
                    className="relative mt-[0.2em]"
                    onMouseEnter={handleMouseEnter4}
                    onMouseLeave={handleMouseLeave4}
                  >
                    <Tooltip />
                    {isHovered4 && (
                      <div className="absolute w-[204px] left-[-5.7em] z-10">
                        <div className="flex items-center justify-center">
                          <svg
                            width="18"
                            height="15"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 0L17.6603 15H0.339746L9 0Z"
                              fill="#FFF4C6"
                            />
                          </svg>
                        </div>
                        <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                          {toolTipText4}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <InputComponent
                className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4"
                type="text"
                name="rate"
                placeholder={`${formatMoney(0.0, "NGN")}`}
                value={inventory.rate}
                handleChange={handleInputChange}
                error={error.rate}
              />
            </div>
          </div>
        )}
        <div className="mb-6 md:mb-0">
          <label className="block mb-1 md:mb-2">
            {t("inventory.description")}
          </label>
          <textarea
            className="block w-full border-[0.5px] border-[#A4A4A4] h-[80px] md:h-[72px] rounded"
            id="grid-first-name"
            name="description"
            placeholder="Provide more details about the product.."
            value={inventory.description}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div>
        <VariationTable
          variationList={variationList}
          onAddVariation={handleAddVariation}
          variations={variations}
          onRemoveVariation={handleRemoveVariation}
          onUpdateVariation={handleUpdateVariation}
          variationError={variationError}
          variationName={variationName}
          setVariationName={setVariationName}
          setVariationError={setVariationError}
          toolTipText="Click the “add variation” button if this item comes in variants e.g different sizes, colors etc."
        />
      </div>
    </div>
  );
}

export { InventoryTable };
