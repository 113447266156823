export const INDUSTRIES = [
  { value: "Healthcare", label: "Healthcare" },
  { value: "Hospitality", label: "Hospitality" },
  { value: "Advertising", label: "Advertising" },
  { value: "Auto Industry", label: "Auto Industry" },
  { value: "Agriculture", label: "Agriculture" },
  { value: "Fashion & Beauty", label: "Fashion & Beauty" },
  { value: "Wholesale/Retail", label: "Wholesale/Retail" },
  { value: "Electronics", label: "Electronics" },
  { value: "Information Technology", label: "Information Technology" },
  { value: "Facility Management", label: "Facility Management" },
  { value: "Others", label: "Others" },
];

export const SIZES = [
  { value: "1-25", label: "1 - 25" },
  { value: "26-50", label: "26 - 50" },
  { value: "51-100", label: "51 - 100" },
  { value: "101 and Above", label: "101 and Above" },
];
