import React from "react";
import { CloseIconSmall } from "../../assets/icons";
import { useNavigate } from "react-router-dom";

interface SubscriptionBannerProps {
  onClose?: () => void;
}

const SubscriptionBanner: React.FC<SubscriptionBannerProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/Subscription");
  };

  return (
    <div
      data-testid="subscription-banner"
      className="w-full bg-gradient-to-l from-[#DDEAF5] to-[#D4E7F9] rounded-lg p-3 flex justify-between items-center"
    >
      <div className="text-[#0D3C6D] text-[14px] space-y-1">
        <h3 className="font-semibold leading-5">Do more with Esemie</h3>
        <p className="font-medium leading-5">
          With just N5,000 you can unlock your business potential.{" "}
        </p>
      </div>
      <div className="flex flex-col gap-4 items-end">
        <div data-testid="close-banner-button" onClick={onClose}>
          <CloseIconSmall color="#0D3C6D" />
        </div>
        <button
          data-testid="upgrade-button"
          onClick={handleNavigate}
          type="button"
          className="w-[103px] h-8 rounded-[8px] bg-[#044E97] text-[12px] leading-4 font-medium text-[#ffff]"
        >
          Choose a plan
        </button>
      </div>
    </div>
  );
};

export default SubscriptionBanner;
