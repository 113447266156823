import React from "react";

interface UserIconProps {
  color?: string;
}

const UsersIcon: React.FC<UserIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 18.3327H17.5C17.5 14.6508 14.1421 11.666 10 11.666C5.85786 11.666 2.5 14.6508 2.5 18.3327Z"
          stroke="#344054"
          stroke-width="1.5"
        />
        <path
          d="M13.75 5.41602C13.75 7.48708 12.0711 9.16602 10 9.16602C7.92893 9.16602 6.25 7.48708 6.25 5.41602C6.25 3.34495 7.92893 1.66602 10 1.66602C12.0711 1.66602 13.75 3.34495 13.75 5.41602Z"
          stroke="#344054"
          stroke-width="1.5"
        />
      </svg>
    </>
  );
};

export default UsersIcon;
