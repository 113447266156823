import axios, { AxiosInstance, AxiosError, AxiosRequestConfig } from "axios";
import { getAuthToken, refreshIdToken } from "../utils";
import { handleError } from "../utils/errorHandler";
import toast from "react-hot-toast";
import { addRefreshSubscriber, onRefreshed } from "../services/subscriber";
import * as Sentry from "@sentry/react";
import { setToken } from "./token";

let isRefreshing = false;

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

export const setupAuthInterceptor = (service: AxiosInstance) => {
  // Request Interceptor
  service.interceptors.request.use(
    async (config) => {
      const token = await getAuthToken();
      if (token) {
        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error: AxiosError) => {
      Sentry.captureException(error);
      handleError(error, "Request Interceptor");
      return Promise.reject(error);
    },
  );

  // Response Interceptor
  service.interceptors.response.use(
    (response) => {
      handleSubscriptionError(response.headers);
      return response;
    },
    async (error: AxiosError) => {
      const originalRequest = error.config as CustomAxiosRequestConfig;
      Sentry.captureException(error);

      if (error.response) {
        const { status, data } = error.response;

        if (status === 401 && !originalRequest._retry) {
          if (!isRefreshing) {
            isRefreshing = true;
            try {
              const newToken = await refreshIdToken();
              isRefreshing = false;
              if (newToken) {
                setToken(newToken); // Update token in sessionStorage
                onRefreshed(newToken);
                originalRequest._retry = true;
                originalRequest.headers = originalRequest.headers || {};
                originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
                return service(originalRequest);
              }
            } catch (err) {
              isRefreshing = false;
              handleError(err, "Token Refresh");
              return Promise.reject(err);
            }
          } else {
            return new Promise((resolve) => {
              addRefreshSubscriber((newToken: string) => {
                originalRequest._retry = true;
                originalRequest.headers = originalRequest.headers || {};
                originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
                resolve(service(originalRequest));
              });
            });
          }
        } else if (status === 500) {
          handleError(
            `HTTP Error: ${status}`,
            "Axios Response Interceptor 500",
          );
        }
        // Handle other status codes as needed
      } else if (error.request) {
        handleError(error, "Axios Response Interceptor - No Response");
      } else {
        handleError(error, "Axios Response Interceptor - Request Setup");
      }
      return Promise.reject(error);
    },
  );
};

function handleSubscriptionError(headers: any) {
  const activeSubscription = headers["x-active-subscription"] === "false";
  const subscriptionExpired = headers["x-subscription-elapsed"] === "true";
  const usageLimitReached = headers["x-usage-limit-reached"] === "true";

  if (activeSubscription) {
    showToast("No active or processing subscription found");
  }

  if (subscriptionExpired) {
    showToast("Subscription expired");
  }

  if (usageLimitReached) {
    showToast("Subscription limits reached");
  }
}

function showToast(message: string) {
  toast.error(message);
}
