import React, { useEffect } from "react";
import cx from "classnames";
import { EditForm } from "./EditForm";
import { getUser } from "../../../../backend-services/user";
import { UserDetail } from "../../../../types";
import { validateEditUserForm } from "../../../../modules/usermanagement/user";
import { useSubUserStore } from "../../../../state/useSubUserStore";
import showToast from "../../../../utils/toastUtils";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

interface EditUserProps {
  show: boolean;
  onClose?: () => void;
  userId: string;
}

const EditUser = ({ show, onClose, userId }: EditUserProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState({});
  const [userDetail, setUserDetail] = React.useState<UserDetail>({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    role: "",
  });
  const handleClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  React.useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const { updateUser } = useSubUserStore((state) => ({
    updateUser: state.updateUser,
  }));

  const getUserDetail = async (id: string) => {
    setIsLoading(true);
    const res = await getUser(id);
    const user = {
      firstname: res.firstname,
      lastname: res.lastname,
      email: res.email,
      phone: res.phone?.substring(1),
      role: res.role,
    };
    setUserDetail(user);
    setIsLoading(false);
  };

  useEffect(() => {
    getUserDetail(userId);
  }, []);

  const handleEdit = async () => {
    const { hasErrors, errors } = validateEditUserForm({
      firstname: userDetail.firstname,
      lastname: userDetail.lastname,
      role: userDetail.role,
      phone: userDetail.phone,
    });

    if (hasErrors) {
      setError(errors);
      return;
    }
    setIsLoading(true);
    try {
      setIsLoading(false);
      const updatedUser = {
        firstname: userDetail.firstname ?? "",
        lastname: userDetail.lastname ?? "",
        phone: userDetail.phone ?? "",
        role: userDetail.role ?? "",
      };
      const res = await updateUser(userId, updatedUser);
      handleClose();
      // showToast('User edited successfully!!', 'success');
      toast.success("User edited successfully!");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      // showToast(`${error}`, 'error');
    }
  };

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/dashboard");
    }
  };

  return isOpen ? (
    <>
      {/* Modal Background */}
      <div
        aria-hidden="false"
        className="fixed inset-0 z-40 flex items-center justify-center bg-gray-900 bg-opacity-50"
      >
        {/* Modal Content */}
        <div
          className={`relative z-50 bg-white shadow-lg rounded-lg overflow-auto 
          w-full h-full sm:w-[470px] xl:max-h-[calc(86vh-4rem)] biglg:max-h-[calc(85vh-4rem)] lg:max-h-[calc(80vh-4rem)]  sm:rounded-lg sm:mt-16`}
        >
          {/* Modal Header */}
          <div className="md:hidden flex justify-start gap-[8.5px] ml-[2em] px-6 py-4 border-b border-gray-200">
            <a onClick={handleGoBack}>
              <svg
                className="mt-[5px] ml-[-2em] cursor-pointer"
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 1L1.5 8L8.5 15"
                  stroke="#33363F"
                  strokeWidth="2"
                />
              </svg>
            </a>
            <h3 className="flex ml-[4.5em] text-xl font-bold">{"Edit user"}</h3>
          </div>
          <div className="hidden md:block pt-4">
            <h3 className="text-xl font-bold items-start pl-[1em]">
              {"Edit user"}
            </h3>
          </div>

          {/* Modal Body */}
          <div className="px-6 pb-2 pt-2 items-center flex justify-center md:hidden ">
            <EditForm
              error={error}
              setError={setError}
              isLoading={isLoading}
              user={userDetail}
              setUser={setUserDetail}
              handleEdit={handleEdit}
              close={handleClose}
            />
          </div>
          <div className="px-6 pb-2 pt- items-center hidden md:block">
            <EditForm
              error={error}
              setError={setError}
              isLoading={isLoading}
              user={userDetail}
              setUser={setUserDetail}
              handleEdit={handleEdit}
              close={handleClose}
            />
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export { EditUser };
