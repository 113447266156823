import * as React from "react";

interface CalendarIconProps {
  color?: string;
}

const Calendar: React.FC<CalendarIconProps> = ({ color }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 1.66663V3.33329M4 1.66663V3.33329"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99366 10.8333H9.00113M8.99366 14.1666H9.00113M12.3233 10.8333H12.3307M5.66406 10.8333H5.67154M5.66406 14.1666H5.67154"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.91406 6.66663H16.0807"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.08203 10.2027C1.08203 6.57161 1.08203 4.75607 2.12546 3.62803C3.1689 2.5 4.84827 2.5 8.20703 2.5H9.79036C13.1491 2.5 14.8285 2.5 15.8719 3.62803C16.9154 4.75607 16.9154 6.57161 16.9154 10.2027V10.6306C16.9154 14.2617 16.9154 16.0773 15.8719 17.2053C14.8285 18.3333 13.1491 18.3333 9.79036 18.3333H8.20703C4.84827 18.3333 3.1689 18.3333 2.12546 17.2053C1.08203 16.0773 1.08203 14.2617 1.08203 10.6306V10.2027Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 6.66663H16.5"
        stroke="#344054"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { Calendar };
