import { create } from "zustand";
import { Budget } from "../types";
import {
  createBudget,
  getBudgetHistory,
  updateBudget,
} from "../backend-services";

interface BudgetState {
  budgets: Budget[];
  isBudgetLoading: boolean;
  error: string | null;
  fetchBudgets: () => Promise<void>;
  clearBudgets: () => void;
  createNewBudget: (data: {
    monthYear: string;
    allocatedBudget: number;
  }) => Promise<void>;
  updateExistingBudget: (data: {
    monthYear: string;
    allocatedBudget: number;
  }) => Promise<void>;
  getBudgetById: (budgetId: string) => Budget | undefined;
}

const date = new Date().toISOString();
const startMonthYear = date
  .split("T")[0]
  .slice(0, 7)
  .split("-")
  .reverse()
  .join("-");
const endMonthYear = date
  .split("T")[0]
  .slice(0, 7)
  .split("-")
  .reverse()
  .join("-");

export const useBudgetStore = create<BudgetState>((set, get) => ({
  budgets: [],
  isBudgetLoading: false,
  error: null,
  fetchBudgets: async () => {
    set({ isBudgetLoading: true, error: null });
    try {
      const budgetsData = await getBudgetHistory(startMonthYear, endMonthYear);
      set({ budgets: budgetsData, isBudgetLoading: false });
    } catch (error: any) {
      console.error("Failed to fetch budgets:", error);
      set({
        isBudgetLoading: false,
        error: error.message || "Failed to fetch budgets",
      });
    }
  },
  clearBudgets: () => {
    set({ budgets: [] });
  },
  createNewBudget: async (data: {
    monthYear: string;
    allocatedBudget: number;
  }) => {
    set({ isBudgetLoading: true, error: null });
    try {
      await createBudget(data);
      const currentBudget = get().budgets;
      const updateBudget = [...currentBudget, data];
      get().fetchBudgets();
      set({
        budgets: updateBudget,
        isBudgetLoading: false,
      });
    } catch (error: any) {
      console.error("Failed to create budget:", error);
      set({
        isBudgetLoading: false,
        error: error.message || "Failed to create budget",
      });
    }
  },
  updateExistingBudget: async (data: {
    monthYear: string;
    allocatedBudget: number;
  }) => {
    set({ isBudgetLoading: true, error: null });
    try {
      const budgets = get().budgets;
      await updateBudget(data);
      if (budgets.length > 0) {
        budgets[0] = {
          ...budgets[0],
          allocatedBudget: data.allocatedBudget,
        };
      }
      set({ budgets, isBudgetLoading: false });
    } catch (error: any) {
      console.error("Failed to update budget:", error);
      set({
        isBudgetLoading: false,
        error: error.message || "Failed to update budget",
      });
    }
  },
  getBudgetById: (budgetId: string) => {
    return get().budgets.find((budget) => budget.id === budgetId);
  },
}));
