export const generateDistinctColorPalette = () => {
  const colors = [];
  const numColors = 32; // Total number of distinct colors
  for (let i = 0; i < numColors; i++) {
    const hue = (i * 360) / numColors; // Generate evenly spaced hues
    const saturation = 70 + (i % 3) * 10; // Vary saturation to add diversity
    const lightness = 50; // Constant lightness
    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`; // Generate color
    colors.push(color);
  }
  return colors;
};
