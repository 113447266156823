import * as React from "react";
import Page_Error from "./svgicons/Page_Error.svg";

interface ErrorIcon404Props {
  color?: string;
  style?: React.CSSProperties;
}

const ErrorIcon404: React.FC<ErrorIcon404Props> = (props) => {
  return <img {...props} src={Page_Error} alt="page error icons" />;
};

export { ErrorIcon404 };
