import React from "react";

const ShareIcon = () => {
  return (
    <div>
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.625 2.75V1.75H17.625V2.75H16.625ZM9.39608 10.976C8.99515 11.3558 8.36221 11.3387 7.98238 10.9377C7.60255 10.5368 7.61966 9.90388 8.02059 9.52405L9.39608 10.976ZM15.625 8.75V2.75H17.625V8.75H15.625ZM16.625 3.75H10.2917V1.75H16.625V3.75ZM17.3127 3.47595L9.39608 10.976L8.02059 9.52405L15.9373 2.02405L17.3127 3.47595Z"
          fill="#044E97"
        />
        <path
          d="M15.832 11.75V11.75C15.832 12.447 15.832 12.7955 15.7744 13.0853C15.5377 14.2753 14.6074 15.2056 13.4173 15.4424C13.1275 15.5 12.779 15.5 12.082 15.5H9.16537C6.33694 15.5 4.92272 15.5 4.04404 14.6213C3.16537 13.7426 3.16537 12.3284 3.16537 9.5V7.45833C3.16537 6.56723 3.16537 6.12168 3.25929 5.7552C3.5312 4.69427 4.35964 3.86583 5.42056 3.59393C5.78704 3.5 6.23259 3.5 7.1237 3.5V3.5"
          stroke="#044E97"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default ShareIcon;
