import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useInventoryStore } from "../../state/useInventoryStore";
import { SidebarDrawer } from "../../components/common";
import InventoryDeletePrompt from "../../components/inventory/InventoryDeletePrompt";
import { useInventorySearch } from "../../hooks/inventory";
import { formatMoney } from "../../utils";
import { useInventoryStatsData } from "../../hooks/Stats/inventory";
import { useUserStore } from "../../state";
import InventoryEmptyIcon from "../../assets/icons/svgicons/InventoryEmptyIcon.svg";
import {
  invCategories,
  mobileInvCategories,
} from "../../constants/filterCategories";
import { TODAY, defaultShortcuts } from "../../constants/helpServices";
import DatePickerWithShortcuts from "../../components/DatePicker";
import { checkSubscription } from "../../utils/subscription";
import { servicesType } from "../../constants/Subscription";
import { SubscriptionModal } from "../../components/common/Subscription/SubscriptionModal";
import { MobileSubscriptionModal } from "../../components/common/Subscription/MobileSubscriptionModal";
import { ICustomer } from "../../@types";
import { useTranslation } from "react-i18next";
import ReusableListPage from "../../components/list-page";
import { isNotAccountant } from "../../utils/isNotAccountant";
import {
  columns,
  columnsAccountant,
  renderInventoryData,
} from "../../utils/inventory/list-page";
import { ListInfoCardProps } from "../../components/list-page/desktop/ListInfoCard";
import PageLoader from "../../components/common/PageLoader";

const ListInventoryPage = () => {
  const { t } = useTranslation("inventories");
  const location = useLocation();
  const { roles } = useUserStore();
  const [params] = useSearchParams();
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [startDate, setStartDate] = React.useState<string>("");
  const [endDate, setEndDate] = React.useState<string>("");
  const [inventoryId, setInventoryId] = React.useState("");
  const currentDate = new Date();
  const [startOfMonth, setStartOfMonth] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
  );
  const [endOfMonth, setEndOfMonth] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
  );
  const { inventoryStats, error } = useInventoryStatsData(
    startOfMonth,
    endOfMonth,
  );

  const fetchInventory = useInventoryStore((state) => state.fetchInventory);
  const fetchMoreData = useInventoryStore((state) => state.fetchMoreData);
  const onSearchData = useInventoryStore((state) => state.setSearchText);
  const handleFilter = useInventoryStore((state) => state.setFilter);
  const fetchData = useInventoryStore((state) => state.fetchData);
  const handleSort = useInventoryStore((state) => state.handleSort);

  const inventory = useInventoryStore((state) => state.inventory);
  const isLoading = useInventoryStore((state) => state.isLoading);
  const isLoadingInitial = useInventoryStore((state) => state.isLoadingInitial);
  const isLoadingFilter = useInventoryStore((state) => state.isLoadingFilter);
  const isLoadingMore = useInventoryStore((state) => state.isLoadingMore);
  const range = useInventoryStore((state) => state.range);
  const pagination = useInventoryStore((state) => state.pagination);
  const searchText = useInventoryStore((state) => state.searchText);
  const filter = useInventoryStore((state) => state.filter);
  const navigate = useNavigate();
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [selectedLabel, setSelectedLabel] = React.useState<string>(TODAY.label);
  const [selected, setSelected] = React.useState<[Date, Date] | null>(null);
  const [showSubscriptionModal, setShowSubscriptionModal] =
    React.useState(false);
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
    React.useState<string>("");

  const handleCalendarToggle = () => {
    setShowCalendar(!showCalendar);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await Promise.all([fetchInventory(), fetchData()]);
      } catch (error: any) {
        if (error.response) {
          const errorMessage = error.response.data;
        }
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      fetchData({ startDate: startDate, endDate: endDate });
    } else {
      fetchData();
    }
  }, [searchText, range, startDate, endDate, fetchData]);

  useEffect(() => {
    if ("stockStatus" in filter || "variantStatus" in filter) {
      fetchData({ actionType: "filter" });
    }
  }, [filter, fetchData]);

  useEffect(() => {
    fetchInventory();
  }, [params, location.state, fetchInventory]);

  const handleDeleteInventory = (id: string) => {
    setShowPrompt(true);
    setInventoryId(id);
  };

  const handleRangeSelect = async (ranges: any) => {
    setShowCalendar(false);
    setSelected(ranges);
    const startDate = ranges[0];
    const endDate = ranges[1];
    let label = "";
    for (const shortcut of defaultShortcuts) {
      const [shortcutStart, shortcutEnd] = shortcut.range();

      if (
        startDate.getTime() === shortcutStart.getTime() &&
        endDate.getTime() === shortcutEnd.getTime()
      ) {
        label = shortcut.label;
        break;
      }
    }
    if (label) {
      setSelectedLabel(label);
    } else {
      setSelectedLabel(
        `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`,
      );
    }
    setStartOfMonth(new Date(startDate));
    setEndOfMonth(new Date(endDate));
  };

  const handleApplyButtonClick = async (
    startDate: Date,
    endDate: Date | null,
  ) => {
    if (endDate === null) {
      endDate = new Date(startDate);
      endDate.setHours(23, 59, 59, 999);
    }
    let newStartDate = startDate?.toISOString().replace(".000Z", "");
    let newEndDate = endDate?.toISOString().replace(".000Z", "");
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    try {
      await handleRangeSelect([startDate, endDate]);
      handleCalendarToggle();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    if (!showCalendar) {
      setSelected(null);
    }
  }, [showCalendar]);

  const {
    data: searchResult,
    isSearching,
    searchedDone,
    setSearchedDone,
    handleSearch: handleInventorySearch,
  } = useInventorySearch();
  const infoCardData = [
    {
      title: t("inventory.total"),
      mobileTitle: t("inventory.total"),
      value: `${inventoryStats?.totalInventory || 0}`,
      className: "w-full md:border-[1.5px] border-gray-100 bg-[#F0F7FF]",
    },
    {
      title: t("inventory.totalValue"),
      mobileTitle: t("inventory.totalValue"),
      value: `${formatMoney(inventoryStats?.totalValue)}`,
      className: "w-full md:border-[1.5px] border-gray-100 bg-[#ECFDF3]",
    },
    {
      title: t("inventory.totalUnitsSold"),
      mobileTitle: t("inventory.totalUnitsSold"),
      value: `${inventoryStats?.totalUnitsSold || 0}`,
      className: "w-full md:border-[1.5px] border-gray-100 bg-[#FFFAEB]",
    },
    {
      title: t("archiveInventory"),
      mobileTitle: t("archiveInventory"),
      value: `${inventoryStats?.totalDeletedInventories || 0}`,
      className: "w-full md:border-[1.5px] border-gray-100 bg-[#FEF3F2]",
    },
  ] as ListInfoCardProps[];

  const handleFilterSubmit = (filter: Record<string, string | boolean>) => {
    if (
      filter.stockStatus !== undefined ||
      filter.variantStatus !== undefined
    ) {
      handleFilter({
        stockStatus: filter.stockStatus ? (filter.stockStatus as string) : "",
        variantStatus: filter.variantStatus
          ? (filter.variantStatus as string)
          : "",
      });
    }
  };

  const InventoryCreate = async () => {
    const subscriptionCheck = checkSubscription(servicesType.Inventories);
    if (subscriptionCheck.status) {
      navigate("/inventories/create");
      return;
    } else {
      setShowSubscriptionModal(true);
    }
  };

  if (isLoadingInitial) {
    return (
      <>
        <PageLoader />
      </>
    );
  }

  return (
    <>
      <div>
        <ReusableListPage
          categories={invCategories}
          mobileCategories={mobileInvCategories}
          createTitle="Create Inventory"
          pageTitle="Inventory"
          showReOccurring={true}
          reOccurringText="Archived"
          showCalendar={showCalendar}
          infoCardData={infoCardData}
          handleCalendarToggle={handleCalendarToggle}
          handleCreate={InventoryCreate}
          selectedLabel={selectedLabel}
          fetchMoreData={fetchMoreData}
          onSearchData={onSearchData}
          handleFilter={handleFilter}
          handleSearch={handleInventorySearch}
          meta={pagination}
          data={renderInventoryData(inventory, handleDeleteInventory)}
          modules="inventories"
          searchDisplayKey="name"
          searchPlaceHolder="Search for inventory"
          isLoading={isLoading}
          isLoadingFilter={isLoadingFilter}
          isLoadingMore={isLoadingMore}
          isSearching={isSearching}
          searchResult={searchResult}
          selectedFilter={filter}
          searchedDone={searchedDone}
          mobileCard={"inventoryCard"}
          setSearchedDone={setSearchedDone}
          columns={isNotAccountant(roles) ? columns : columnsAccountant}
          onSubmit={handleFilterSubmit}
          renderActionMenu={(row, toggleVisibility, isVisible) => {
            if (!isVisible) {
              return (
                <>
                  <button
                    className="px-2 py-1 border border-blue-500 rounded text-blue-500"
                    onClick={() => navigate(`/inventories/${row.id}/restock`)}
                  >
                    Restock
                  </button>
                  <button
                    className="px-2 py-1 bg-red-100 rounded text-red-600"
                    onClick={() => handleDeleteInventory(row.id)}
                  >
                    Delete
                  </button>
                </>
              );
            }
            return null;
          }}
          emptyStateDescription={t("inventory.inventoryFirst")}
          emptyPageIcon={<img className="h-[250px]" src={InventoryEmptyIcon} />}
          emptyPageHref="https://www.youtube.com/embed/qZDZj45EjJg"
          emptyPageLinkName="inventories"
          handleSetCustomer={function (
            selectedCustomer: ICustomer | null,
          ): void {
            throw new Error("Function not implemented.");
          }}
          emptyMessage={t("inventory.emptyState")}
        />
      </div>

      <div className="">
        {showSubscriptionModal && (
          <SubscriptionModal>
            <MobileSubscriptionModal
              onClose={() => setShowSubscriptionModal(false)}
              titleText={`Need to access more features?`}
              subtitleText={subscriptionErrorMessage}
            />
          </SubscriptionModal>
        )}
      </div>

      {showCalendar && (
        <>
          <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh]">
            <div className="hidden md:flex justify-center items-center h-full w-full">
              <DatePickerWithShortcuts
                onRangeSelect={handleRangeSelect}
                selected={selected}
                onClose={handleCalendarToggle}
                onApplyButtonClick={(startDate, endDate) =>
                  handleApplyButtonClick(startDate, endDate)
                }
                fetchData={fetchData}
              />
            </div>
          </div>
          <div className="mt-[-30em]">
            {showSubscriptionModal && (
              <SubscriptionModal>
                <MobileSubscriptionModal
                  onClose={() => setShowSubscriptionModal(false)}
                  titleText={`Need to access more features?`}
                  subtitleText={subscriptionErrorMessage}
                />
              </SubscriptionModal>
            )}
          </div>
        </>
      )}

      <div className="mb-[32px]">
        {showPrompt && (
          <div>
            <SidebarDrawer
              title={"Delete Inventory"}
              show={true}
              position={"left"}
              onClose={() => setShowPrompt(false)}
            >
              <InventoryDeletePrompt
                setShowPrompt={setShowPrompt}
                inventoryId={inventoryId}
                fetchInventory={fetchInventory}
              />
            </SidebarDrawer>
          </div>
        )}
      </div>
    </>
  );
};

export { ListInventoryPage };
