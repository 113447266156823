import axios, { AxiosInstance } from "axios";
import config from "../config";
import { getAuthToken, setupAuthInterceptor } from "../utils";

export const createInvoiceService = (history?: History): AxiosInstance => {
  const invoiceService = axios.create({
    baseURL: config.invoiceServiceURL,
  });
  setupAuthInterceptor(invoiceService);

  return invoiceService;
};

const invoiceService = createInvoiceService();

export { invoiceService };
