function isValidEmail(email: string) {
  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const validateUserForm = ({
  firstname,
  lastname,
  email,
  phone,
  role,
}: any) => {
  const errors = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    role: "",
  };
  if (!firstname) {
    errors.firstname = "First Name is required";
  }
  if (!lastname) {
    errors.lastname = "Last Name is required";
  }
  if (!email) {
    errors.email = "Email is required"; // Make email required
  } else if (!isValidEmail(email)) {
    errors.email = "Invalid email address";
  }
  if (!role) {
    errors.role = "Role is Required";
  }

  // Check if any error messages are present
  const hasErrors = Object.values(errors).some((error) => error !== "");

  return { hasErrors, errors };
};
export const validateEditUserForm = ({
  firstname,
  lastname,
  phone,
  role,
}: any) => {
  const errors = {
    firstname: "",
    lastname: "",
    phone: "",
    role: "",
  };
  if (!firstname) {
    errors.firstname = "First Name is required";
  }
  if (!lastname) {
    errors.lastname = "Last Name is required";
  }
  if (!role) {
    errors.role = "Role is Required";
  }

  // Check if any error messages are present
  const hasErrors = Object.values(errors).some((error) => error !== "");

  return { hasErrors, errors };
};
