import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { TooltipProps } from "recharts";
import { barChartData } from "../../../../@types";
import { generateYearOptions } from "../../../../utils/date";
import { DropdownComponent } from "../../Forms";
import EmptyStateChart from "./Empty";
import NewTooltips from "../../../../assets/icons/NewTooltips";
interface BarChartProps {
  title: string;
  standalone: boolean;
  orientation: string;
  colorScale: { invoice: string; expense: string };
  data: barChartData | any;
  isEmpty?: boolean;
  onChangeYear: (year: string, startDate: Date, endDate: Date) => void;
  toolTipText?: string;
  selectedYear: string;
  setSelectedYear: (selectedYear: string) => void;
}

const CashFlowChart: React.FC<BarChartProps> = ({
  title,
  data,
  standalone,
  orientation,
  colorScale,
  isEmpty,
  onChangeYear,
  toolTipText,
  selectedYear,
  setSelectedYear,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const monthsOrder = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  const ChartData = monthsOrder.map((month) => ({
    name: month.toString().toUpperCase(),
    expense: data[month]?.expense,
    invoice: data[month]?.invoice,
  }));

  const isDataEmpty = Object.values(data).every((item) => item === 0);

  const handleChangeYear = (name: string, value: string) => {
    const trimmedYear = value.trim().toUpperCase();
    if (!trimmedYear) {
      return;
    }
    const yearRegex = /^\d{4}$/;
    if (yearRegex.test(trimmedYear)) {
      const yearNumber = parseInt(trimmedYear, 10);
      const startDate = new Date(yearNumber, 0, 1);
      const endDate = new Date(yearNumber, 11, 31);
      setSelectedYear(trimmedYear);
      onChangeYear(trimmedYear, startDate, endDate);
    } else {
    }
  };

  const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      // Initialize values for expense and invoice
      let expenseValue = 0;
      let invoiceValue = 0;

      // Loop through payload to find expense and invoice data
      payload.forEach((entry) => {
        if (entry.dataKey === "expense" && entry?.value) {
          expenseValue = +entry?.value; // Expense data
        }
        if (entry.dataKey === "invoice" && entry?.value) {
          invoiceValue = +entry?.value; // Invoice data
        }
      });

      return (
        <div
          className="shadow-sm"
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            padding: "12px",
            fontSize: "14px",
          }}
        >
          <p>
            Expense: {""}
            <span className="font-semibold text-[#000000]">{`₦${expenseValue?.toLocaleString()}`}</span>
          </p>
          <p>
            Invoice: {""}
            <span className="font-semibold text-[#000000]">{`₦${invoiceValue?.toLocaleString()}`}</span>
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <p className=" text-[16px] text-[#1D2939] leading-6 font-semibold">
            {title}
          </p>
          {toolTipText && (
            <div
              className="relative"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <NewTooltips />
              {isHovered && (
                <div className="absolute w-[204px] left-[-5.7em]">
                  <div className="flex items-center justify-center">
                    <svg
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#FFF4C6" />
                    </svg>
                  </div>
                  <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                    {toolTipText}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <DropdownComponent
          options={generateYearOptions()}
          placeholder="Filter"
          dropdownClassName="w-[85px] h-[36px] border rounded-[8px] border-[#D0D5DD] bg-[#F9FAFB] cursor-pointer"
          iconClassName="md:mr-[2.1em] lg:mr-[0.5em]"
          onChange={(name: string, value: string) => {
            handleChangeYear(name, value);
          }}
          selectedValue={selectedYear}
          value={selectedYear}
        />
      </div>

      <div>
        {isEmpty || isDataEmpty ? (
          <EmptyStateChart />
        ) : (
          <div>
            <div className="w-full h-[400px] mt-10">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={ChartData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
                >
                  <CartesianGrid
                    vertical={false}
                    stroke="#F0F2F5"
                    strokeDasharray="7 7"
                  />
                  <XAxis
                    dataKey="name"
                    tickLine={false}
                    tick={{ fontSize: 14, fill: "#344054", fontWeight: "500" }}
                    axisLine={{ stroke: "#E5E5E5" }}
                  />
                  <YAxis
                    tickFormatter={(value) => `${value / 1000}K`}
                    tick={{ fontSize: 12, fill: "#344054" }}
                    tickLine={false}
                    axisLine={{ stroke: "#E5E5E5" }}
                  />
                  <Tooltip
                    content={<CustomTooltip />}
                    cursor={false}
                    formatter={(value) => `₦${value.toLocaleString()}`}
                  />

                  <Line
                    type="monotone"
                    dataKey="expense"
                    strokeWidth={2}
                    stroke="#044E97"
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="invoice"
                    strokeWidth={2}
                    stroke="#7EC1FB"
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>

            <div className="flex gap-4 items-center text-[#344054] font-medium w-full justify-center mt-3 mb-2">
              <div className="flex gap-2 items-center">
                <div className="bg-[#7EC1FB] h-3 w-3 rounded-2px"></div>
                <p className="leading-6">Invoice</p>
              </div>
              <div className="flex gap-2 items-center">
                <div className="bg-[#044E97] h-3 w-3 rounded-2px"></div>
                <p className="leading-6">Expense</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { CashFlowChart };
