import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LogoutIcon2 } from "../assets/icons/Logout";
import { SettingsIcon } from "../assets/icons/SettingIscon";
import UsersIcon from "../assets/icons/UserIscon";
import {
  BANK_ACCOUNTS,
  BUDGET,
  ROLE,
  STORAGE_REFRESH_TOKEN,
  STORAGE_TOKEN,
  STORAGE_USER_DATA,
  SUBSCRIPTION,
  USERNAME,
} from "../constants";
import { END_DATE, SELECTED_LABEL, START_DATE } from "../constants/dashboard";
import { useSubscriptionStore, useUserStore } from "../state";
import { redirectToAuth } from "../utils/navigation";
import userPool from "../utils/userPool";
import { UseAuthContext } from "../context/useAuthContext";
import useCognito from "../hooks/auth/useCognito";

//@ts-ignore
const ProfileDropdown = ({ onClose }) => {
  const { user } = useUserStore((state) => ({
    user: state.user,
  }));
  const { subscriptionData } = useSubscriptionStore((state) => ({
    subscriptionData: state.subscriptionData,
  }));
  const firstName = user?.firstName || "";
  const lastName = user?.lastName || "";
  const firstInitial = firstName.charAt(0).toUpperCase();
  const lastInitial = lastName.charAt(0).toUpperCase();
  const { logout } = useCognito();

  const navigate = useNavigate();
  const handleSettings = () => {
    navigate("/settings");
    onClose();
  };

  const handleProfile = () => {
    navigate("/profile");
    onClose();
  };

  const handleSubscription = () => {
    navigate("/subscription");
    onClose();
  };

  
  const handleLogout = () => {
    logout();  
    sessionStorage.removeItem(STORAGE_TOKEN);
    sessionStorage.removeItem(STORAGE_REFRESH_TOKEN);
    sessionStorage.removeItem(USERNAME);
    localStorage.removeItem(SUBSCRIPTION);
    localStorage.removeItem(ROLE);
    localStorage.removeItem(STORAGE_USER_DATA);
    localStorage.removeItem("isRootUser");
    localStorage.removeItem("isPasswordChanged");
    localStorage.removeItem(START_DATE);
    localStorage.removeItem(END_DATE);
    localStorage.removeItem(SELECTED_LABEL);
    localStorage.removeItem(BANK_ACCOUNTS);
    localStorage.removeItem(BUDGET);
  
    redirectToAuth();
  };
  

  
  return (
    <div className="relative lg:w-[350px] bg-white  shadow-lg rounded-[20px] p-4 border z-9999">
      {/* User Info */}
      <div className="flex items-center space-x-4 lg:text-[20px] ">
        <div className="w-14 h-14 bg-[#FEE4E2] text-[#B42318] rounded-full flex items-center justify-center font-medium p-5">
          {firstInitial + lastInitial}
        </div>
        <div>
          <p className="lg:text-[16px] text-sm font-semibold text-gray-800 mb-2">
            {user.firstName} {user.lastName}
          </p>
          <p className="lg:text-[16px] text-sm  text-gray-500">{user.email}</p>
        </div>
      </div>

      {/* Options */}
      <div className="mt-4 space-y-4 lg:text-[16px] text-sm  border-t border-gray-200 pt-4">
        <div
          className="flex items-center lg:text-[16px] text-sm  space-x-2 text-gray-700 hover:text-blue-600 cursor-pointer"
          onClick={handleSettings}
        >
          <SettingsIcon />
          <span>Settings</span>
        </div>

        <div
          className="flex items-center space-x-2 text-gray-700 hover:text-blue-600 cursor-pointer"
          onClick={handleProfile}
        >
          <UsersIcon />
          <span>My account</span>
        </div>
      </div>

      {/* Plan Details */}
      <div className="mt-4 border-t border-gray-200 pt-4">
        <div className="flex justify-between items-center">
          <div>
            <p className="lg:text-[16px] text-sm  font-semibold text-gray-800 capitalize">
              {subscriptionData?.plan}
            </p>

            <span className="lg:text-[14px] text-sm  text-gray-700">
              {subscriptionData?.plan === "free"
                ? "Upgrade for more invoices"
                : `Create ${subscriptionData?.limits?.invoices || 0}+ invoices`}
            </span>
          </div>
          <button
            className="lg:text-[14px] text-sm  text-blue-600 border border-blue-600 rounded-md px-3 py-1  hover:bg-blue-100 whitespace-nowrap"
            onClick={handleSubscription}
          >
            Upgrade plan
          </button>
        </div>
      </div>

      {/* Logout */}
      <div
        className="mt-4 border-t border-gray-200 pt-4 lg:text-[16px] text-sm "
        onClick={handleLogout}
      >
        <div className="flex items-center space-x-2 text-red-600 hover:text-red-800 cursor-pointer">
          <LogoutIcon2 />
          <span>Log out</span>
        </div>
      </div>
    </div>
  );
};

export default ProfileDropdown;
