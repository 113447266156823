import React from "react";
import { ViewProfile } from "../../components/settings/profile/View";

const ProfilePage = () => {
  return (
    <div>
      <ViewProfile />
    </div>
  );
};

export default ProfilePage;
