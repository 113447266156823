import React from "react";

interface SubIconProps {
  color?: string;
}

const SubscriptionBlueCheck: React.FC<SubIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="9" fill={color || "currentColor"} />
        <path d="M8 12L11 15L13.5 12L16 9" stroke="white" stroke-width="2" />
      </svg>
    </>
  );
};

export { SubscriptionBlueCheck };
