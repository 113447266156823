import React, { useState, useRef, ChangeEvent } from "react";
import toast from "react-hot-toast";
import { InputComponent } from "../../../core/Forms/InputComponent";
// import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import * as UI from "../../../common";
import { validateProfileForm } from "../../../../modules/profile";
import { SidebarDrawer } from "../../../common/SidebarDrawer";
import { DropdownComponent } from "../../../core/Forms/DropdownComponent";
import { ICreateProfile } from "../../../../@types/profile";
import { INDUSTRIES, SIZES } from "../../../../constants/industries";
import { clearFieldError } from "../../../../utils/handleErrors";
import LocationSelector from "../../../common/Address";
import { shortenFileName } from "../../../../utils";
import { CloseIcon } from "../../../../assets/icons";
import UploadIcon from "../../../../assets/icons/UploadIcon";
import PhoneInputComponent from "../../../common/PhoneInputComponent";
import { useTranslation } from "react-i18next";

interface EditProfileProps {
  show: boolean;
  onSubmit: (profile: ICreateProfile) => Promise<JSX.Element | void>;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isLoading: boolean;
  onClose?: () => void;
  data: FormData;
}

interface FormData {
  firstName: string;
  lastName: string;
  businessName: string;
  industry: string;
  email: string;
  phone: string;
  address: string;
  state: string;
  city: string;
  businessSize: string;
  brandColor: string;
  code: string;
  logoBase64: string;
  isVerified: boolean;
}
const MAX_FILE_SIZE_KB = 100;

const EditProfile = ({
  show,
  onSubmit,
  onClose,
  data,
  isLoading,
}: EditProfileProps) => {
  const { t } = useTranslation("settings");
  const [formData, setFormData] = React.useState<FormData>({
    firstName: data.firstName,
    lastName: data.lastName,
    businessName: data.businessName,
    industry: data.industry,
    email: data.email,
    phone: data.phone,
    address: data.address,
    state: data.state,
    city: data.city,
    code: data.code,
    businessSize: data.businessSize,
    brandColor: data.brandColor,
    logoBase64: data.logoBase64,
    isVerified: data.isVerified,
  });

  const [selectedValue, setSelectedValue] = useState(data.industry);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [selectedValue2, setSelectedValue2] = useState(data.businessSize);
  const [state, setState] = React.useState(data?.state || "");
  const [city, setCity] = React.useState(data?.city || "");
  const [file, setFile] = React.useState<File | null>();
  const [fileName, setFileName] = React.useState<string>("");
  const [error, setError] = React.useState<string>();
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    //  setEditedData({ ...editedData, [name]: value });
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const validationResults = validateProfileForm(formData);

    if (Object.keys(validationResults).length === 0) {
      onSubmit(formData);
    } else {
      toast.error("Please fill in the required fields.");
    }
  };

  const dropDownArray = INDUSTRIES;
  const dropDownArray2 = SIZES;
  const handleDropDownChange = (name: string, selectedValue: string) => {
    setFormData({ ...formData, industry: selectedValue });
  };

  const handleDropDownChange2 = (name: string, selectedValue2: string) => {
    setFormData({ ...formData, businessSize: selectedValue2 });
  };

  const handleLocationChange = (
    selectedState: string,
    cities: string | any[],
  ) => {
    setState(selectedState);
    setCity(cities.length > 0 ? cities[0] : "");

    setFormData((prevData) => ({
      ...prevData,
      state: selectedState,
      city: cities.length > 0 ? cities[0] : "",
    }));
  };

  const validationResults = validateProfileForm(formData);
  const handleSelectFile = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    inputRef.current?.click();
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;
    if (selectedFile) {
      const fileSizeInKB = selectedFile.size / 1024;
      if (fileSizeInKB > MAX_FILE_SIZE_KB) {
        setError(`File size should not exceed ${MAX_FILE_SIZE_KB} KB`);
        setFile(null);
        setFileName("");
        return;
      }

      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowedTypes.includes(selectedFile.type)) {
        setError("Please upload a PNG, or JPG");
        setFile(null);
        setFileName("");
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && typeof event.target.result === "string") {
          const result = event.target.result as string;
          setFormData((prevData) => ({
            ...prevData,
            brandLogo: result,
          }));
        }
      };
      reader.onerror = (event) => {
        console.error("Error reading the file", event.target?.error);
      };
      reader.readAsDataURL(selectedFile);

      setFile(selectedFile);
      setFileName(selectedFile.name);
      setError(undefined);
    }
  };
  const handlePhoneNumberChange = (phone: any) => {
    setFormData({ ...formData, phone: phone || "" });
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center md:flex-row gap-[16px] md:gap-[14px] mb-[16px] md:mb-[16px]">
          <div className="flex flex-col">
            <label
              htmlFor="first-name"
              className="text-[12px] text-[#666666] font-normal"
            >
              {t("Settings.firstname")}
            </label>
            <div className="max-w-[350px] md:w-[210px]">
              <InputComponent
                name="firstName"
                value={formData.firstName}
                className="border bg-[#F2F2F2] placeholder:text-[1rem] placeholder:text-[#000000] placeholder:font-normal w-[280px] max-w-[350px] md:w-[210px] h-[48px] rounded"
                type="text"
                placeholder="Kelly"
                handleChange={handleInputChange}
              />
              {validationResults.firstName && (
                <p
                  className="error"
                  id="firstName-error"
                  style={{ color: "red" }}
                >
                  {validationResults.firstName}
                </p>
              )}
            </div>
          </div>

          <div className="flex flex-col mt-2 md:mt-0 lg:mt-0 xl:mt-0">
            <label
              htmlFor="last-name"
              className="text-[12px] text-[#666666] font-normal"
            >
              {t("Settings.lastname")}
            </label>
            <div className="max-w-[350px] md:w-[210px] ">
              <InputComponent
                name="lastName"
                className="border bg-[#F2F2F2] placeholder:text-[1rem] placeholder:text-[#000000] placeholder:font-normal h-[48px] w-[280px] rounded max-w-[350px] md:w-[210px]"
                type="text"
                placeholder="Audu"
                value={formData.lastName}
                handleChange={handleInputChange}
              />
              {validationResults.lastName && (
                <p
                  className="error"
                  id="lastName-error"
                  style={{ color: "red" }}
                >
                  {validationResults.lastName}
                </p>
              )}
            </div>
          </div>
        </div>

        <div>
          <div className="flex flex-col mb-[] mt-2">
            <label
              htmlFor="business-name"
              className="text-[12px] text-[#666666] font-normal"
            >
              Business Name
            </label>
            <div className="max-w-[350px] md:min-w-[434px]">
              <InputComponent
                name="businessName"
                className="border bg-[#F2F2F2] placeholder:text-[1rem] placeholder:text-[#000000] placeholder:font-normal h-[48px] rounded  w-[280px] max-w-[350px] md:min-w-[434px]"
                type="text"
                value={formData.businessName}
                handleChange={handleInputChange}
                placeholder="Business Name"
              />
              {validationResults.businessName && (
                <p className="error" id="name-error" style={{ color: "red" }}>
                  {validationResults.businessName}
                </p>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col mb-[] mt-2">
            <label
              htmlFor="code"
              className="text-[12px] text-[#666666] font-normal"
            >
              CAC Registration Code
            </label>
            <div className="max-w-[350px] md:min-w-[434px]">
              <InputComponent
                name="code"
                className="border bg-[#F2F2F2] placeholder:text-[1rem] placeholder:text-[#000000] placeholder:font-normal h-[48px] rounded  w-[280px] max-w-[350px] md:min-w-[434px]"
                type="text"
                value={formData.code}
                handleChange={handleInputChange}
                placeholder="Business Registration Code"
              />
              {validationResults.businessName && (
                <p className="error" id="name-error" style={{ color: "red" }}>
                  {validationResults.businessName}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col mb-[] mt-2 ">
          <label
            htmlFor="industry"
            className="text-[12px] text-[#666666] font-normal mt-[1em]"
          >
            {t("Settings.category")}
          </label>
          <div className="max-w-[350px] md:min-w-[434px]  w-[280px]">
            <DropdownComponent
              options={INDUSTRIES}
              placeholder="Select Industry"
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              onChange={handleDropDownChange}
              className="border bg-[#F2F2F2] placeholder:text-[1rem] placeholder:text-[#000000] placeholder:font-normal h-[48px]  w-[280px] rounded max-w-[350px] md:min-w-[434px]"
              iconClassName="ml-[-2em] mt-[4px]"
              dropdownClassName=" w-[280px] border-gray-300 rounded-md placeholder-gray-400 placeholder-opacity-75 md:w-[434px]  xl:w-[434px] bg-white  rounded py-1 focus:ring-0 pointer-events placeholder-gray-400 placeholder-opacity-75"
            />
          </div>
        </div>
        <div className="flex flex-col mb-[] mt-2 ">
          <label
            htmlFor="industry"
            className="text-[12px] text-[#666666] font-normal mt-[1em]"
          >
            Business Size
          </label>
          <div className="max-w-[350px] md:min-w-[434px]  w-[280px]">
            <DropdownComponent
              options={SIZES}
              placeholder="Select Business Size"
              selectedValue={selectedValue2}
              setSelectedValue={setSelectedValue2}
              onChange={handleDropDownChange2}
              className="border bg-[#F2F2F2] placeholder:text-[1rem] placeholder:text-[#000000] placeholder:font-normal h-[48px]  w-[280px] rounded max-w-[350px] md:min-w-[434px]"
              iconClassName="ml-[-2em] mt-[4px]"
              dropdownClassName=" w-[280px] border-gray-300 rounded-md placeholder-gray-400 placeholder-opacity-75 md:w-[434px]  xl:w-[434px] bg-white  rounded py-1 focus:ring-0 pointer-events placeholder-gray-400 placeholder-opacity-75"
            />
          </div>
        </div>
        <div className="flex flex-col  mb-[14px] mt-3">
          <label
            htmlFor="email"
            className="text-[12px] text-[#666666] font-normal"
          >
            {t("Settings.email")}
          </label>
          <div className="max-w-[350px] md:min-w-[434px]">
            <InputComponent
              name="email"
              className="border  placeholder:text-[1rem] placeholder:text-[#000000] placeholder:font-normal h-[48px] bg-[#F2F2F2] rounded  w-[280px] max-w-[350px] md:min-w-[434px]"
              type="text"
              placeholder="Kelz4U@primaboost.com"
              value={formData.email}
              disabled
            />
            {validationResults.email && (
              <p
                className="error"
                id="email-error ml-[4em]"
                style={{ color: "red" }}
              >
                {validationResults.email}
              </p>
            )}
          </div>
        </div>

        <div className="flex flex-col mb-[] mt-2">
          <label
            htmlFor="phone"
            className="text-[12px] text-[#666666] font-normal"
          >
            {t("Settings.phonenumber")}
          </label>
          <PhoneInputComponent
            onPhoneNumberChange={handlePhoneNumberChange}
            disableDropdown
            inputClass="text-[#00000]"
            containerClass="setting_edit"
            value={formData.phone}
          />

          {validationResults.phone && (
            <p
              className="error text-[16px]"
              id="phone-error"
              style={{ color: "red" }}
            >
              {validationResults.phone}
            </p>
          )}
        </div>

        <div className="flex flex-col lg:w-[434px] mb-[] mt-2">
          <label
            htmlFor="address"
            className="text-[12px] text-[#666666] font-normal mt-3 pl-[px]"
          >
            {t("Settings.address")}
          </label>
          <div className="flex flex-col items-center">
            <div className="max-w-[350px] md:min-w-[434px]">
              <InputComponent
                name="address"
                className="border bg-[#F2F2F2] placeholder:text-[1rem] placeholder:text-[#000000] placeholder:font-normal h-[48px]  w-[280px] rounded max-w-[350px] md:min-w-[434px]"
                type="text"
                value={formData.address}
                handleChange={handleInputChange}
                placeholder="Address"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="w-[280px] max-w-[350px] md:min-w-[434px] xl:w-[434px]  mt-2 xl:mt-[1.7em] lg:mt-[2em] md:mt-2 biglg:mt-[1em] ">
            <LocationSelector
              onLocationChange={handleLocationChange}
              onError={(error: any) => {}}
              selectedState={state}
              selectedCity={city}
            />
          </div>
        </div>

        <div className="mt-[1em] lg:ml-[-14em] xl:ml-[-15em]  ml-[-4em] md:ml-[-13.8em]">
          <label
            htmlFor="Logo"
            className="text-[13px] text-[#666666] font-medium mt-5"
          >
            Brand Logo
          </label>
          <div className="xl:text-[13px] font-light  xl:ml-[em] mt-[em]">
            <p>Upload Brand Logo</p>
            <div className="flex  mt-[13px]">
              <div
                onClick={handleSelectFile}
                className="flex items-center border border-[#828282] px-[13px] py-[8px] rounded-[4px] bg-[#EDF2F5] "
              >
                <p>Upload document</p>
                <div className="bg-[#D6DADD] w-[1px] h-full mx-[9px] "></div>
                <UploadIcon />
                <input
                  className="hidden"
                  type="file"
                  ref={inputRef}
                  accept="image/jpeg,image/jpg,image/png"
                  onChange={handleUpload}
                />
              </div>
            </div>
            {file && (
              <div className="flex items-center text-lg mt-3">
                <span className="text-[#0500F9] mx-3  font-medium">
                  {shortenFileName(file)}
                </span>
                <a href="#close" onClick={() => setFile(undefined)}>
                  <CloseIcon color="red" />
                </a>
              </div>
            )}
          </div>
        </div>

        <div className="mt-[1em] lg:ml-[-14em] xl:ml-[-13.6em]  ml-[-4em] md:ml-[-13.8em]">
          <label
            htmlFor="color-input"
            className="text-sm text-gray-600 font-medium mt-5 ml-1"
          >
            Brand Color
            <p className="text-[13px] font-light   mt-[em]">
              Choose a color that best fit the brand
            </p>
          </label>
          <div className="mt-[-0.2em]">
            <input
              type="color"
              id="color-input"
              className="block w-10 h-10 rounded-md border-gray-300"
              value={formData.brandColor || "#044E97"}
              onChange={(e) => {
                const hexColor = e.target.value;
                setFormData((prevData) => ({
                  ...prevData,
                  brandColor: hexColor,
                }));
              }}
            />
          </div>
        </div>

        <div className="xl:w-[300px] md:w-[300px] mt-[2em]" />
        <div className="flex justify-center items-center">
          <div className="xl:w-[300px] md:w-[300px] w-[215px] lg:w-[215px] flex justify-center mt-9">
            <UI.Button
              type="submit"
              text="Save Changes"
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </form>
    // </SidebarDrawer>
  );
};

export { EditProfile };
