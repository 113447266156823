import React, { useState } from "react";
import { IInvoice } from "../../../@types";
import { BankData } from "../../../types";
import { Statuses } from "../../../constants";
import { formatMoney } from "../../../utils";
import { useUserStore } from "../../../state";
import dayjs from "dayjs";
import { CloseIcon } from "../../../assets/icons";
import PreviewTable from "./PreviewTable";
import MobilePreviewTable from "./MobilePreviewTable";
import { useNavigate, useParams } from "react-router-dom";
import { useDownloadInvoice } from "../../../hooks";
import PreviewDownloadIcon from "../../../assets/icons/PreviewDownloadIcon";
import { useDownloadQuote } from "../../../hooks/invoice/useDownloadQuote";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "../../../state/useCompanyStore";

type MobileDataItem =
  | {
      name: any;
      quantity?: any;
      amount?: any;
    }
  | {
      name: any;
      duration?: any;
      price?: any;
    };

interface PreviewCopyProps {
  type: "invoice" | "quote";
  document: any;
  address: string;
  businessName: string;
  brandLogo: string;
  brandColor: string;
  code?: string;
  bankData: BankData[];
  phone: string;
  onClose: () => void;
  note: string | undefined;
}

const PreviewCopy: React.FC<PreviewCopyProps> = ({
  type,
  document,
  code,
  address,
  brandColor,
  businessName,
  phone,
  onClose,
  note,
}) => {
  const { t } = useTranslation("invoices");
  const isRetailBusiness = useCompanyStore((state) => state.isRetailBusiness());
  const params = useParams();
  const invoiceId = params.invoiceNo;
  const quoteId = params.quoteNo;
  const user = useUserStore((state) => state.user);
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice | null>(null);
  const bankAccount = user.bankAccounts || [];
  const [isDownloading, setIsDownloading] = useState(false);
  const renderLogo = () => {
    if (user && user.logoBase64) {
      return <img src={`${user.logoBase64}`} alt="Logo" />;
    } else if (user && user.logoUrl) {
      return <img src={user.logoUrl} alt="Logo" />;
    }
    return null;
  };
  const navigate = useNavigate();

  if (!document) {
    navigate("/error", { state: { status: 500 } });
    throw new Error("Invoice not found");
  }

  let mobileData: MobileDataItem[] = [];
  let data: any[] = [];
  if (document && document.inventories && isRetailBusiness) {
    // Retail business, show inventories data
    data = document.inventories.flatMap((inv: any, i: any) => {
      const inventoryData = [];
      if (Array.isArray(inv.variations) && inv.variations.length > 0) {
        inventoryData.push(
          ...inv.variations.map((variation: any) => [
            i + 1,
            inv.name + " - " + variation.type,
            variation?.quantity,
            variation?.rate,
            formatMoney(
              `${parseInt(variation.rate) * parseInt(variation.quantity)}`,
              document.currency,
            ),
          ]),
        );
      } else {
        inventoryData.push([
          i + 1,
          inv.name,
          inv?.quantity,
          inv?.rate,
          formatMoney(
            `${parseInt(inv.rate) * parseInt(inv.quantity)}`,
            document.currency,
          ),
        ]);
      }
      return inventoryData;
    });

    mobileData = document.inventories.flatMap((inv: any, i: any) => {
      const inventoryData = [];
      if (Array.isArray(inv.variations) && inv.variations.length > 0) {
        inventoryData.push(
          ...inv.variations.map((variation: any) => [
            i + 1,
            inv.name + " - " + variation.type,
            variation?.quantity,
            variation?.rate,
            formatMoney(
              `${parseInt(variation.rate) * parseInt(variation.quantity)}`,
              document.currency,
            ),
          ]),
        );
      } else {
        // Inventories without variations
        inventoryData.push([
          i + 1,
          inv.name,
          inv?.quantity,
          inv?.rate,
          formatMoney(
            `${parseInt(inv.rate) * parseInt(inv.quantity)}`,
            document.currency,
          ),
        ]);
      }
      return inventoryData;
    });
  } else if (document && document.services && !isRetailBusiness) {
    // Not a retail business, show services data
    data = document.services.map((ser: any, i: any) => [
      i + 1,
      ser.name,
      ser?.duration,
      formatMoney(`${parseInt(ser.price)}`, document.currency),
    ]);

    mobileData = document.services.map((ser: any, i: any) => ({
      name: ser.name,
      duration: ser?.duration,
      price: formatMoney(`${parseInt(ser.price)}`, document.currency),
    }));
  }
  const Servicecolumns = [
    "#",
    "Services(s) and Description",
    "Duration",
    "Service Charge",
  ];
  const Invoicecolumns = ["#", "Item(s)/Description", "Qty", "Rate", "Amount"];

  const renderLineItemsMobileTableHead = () => {
    return {
      name: { title: "Item" },
      quantity: { title: "Qty" },
      amount: { title: "Amount" },
    };
  };

  const renderLineItemsMobileTableHeadService = () => {
    return {
      name: { title: "Service" },
      duration: { title: "Duration" },
      price: { title: "Charge" },
    };
  };

  const calculateSubtotalWithVariations = () => {
    const subtotalWithVariations = (document.inventories || []).flatMap(
      (inv: any) => {
        if (Array.isArray(inv.variations) && inv.variations.length > 0) {
          // Inventories with variations
          return inv.variations.map((variation: any) => ({
            amount: parseInt(variation.rate) * parseInt(variation.quantity),
          }));
        } else {
          // Inventories without variations
          return {
            amount: parseInt(inv.rate) * parseInt(inv.quantity),
          };
        }
      },
    );

    return subtotalWithVariations.reduce(
      (prev: any, curr: { amount: any }) => prev + curr.amount,
      0,
    );
  };

  const subTotalWithVariations = calculateSubtotalWithVariations();

  const subTotal =
    subTotalWithVariations +
    ([] as { amount: number; quantity: number; rate: number }[])
      .concat(
        (document.services || []).map((ser: any) => ({
          amount: ser.price,
          quantity: 0,
          rate: 0,
        })),
      )
      .reduce((prev, curr) => prev + curr.amount, 0);

  let discount =
    document.discount !== undefined ? subTotal * document.discount : 0;
  let tax = document.tax !== undefined ? subTotal * document.tax : 0;
  let total = subTotal - discount + tax;

  const minHeight = "850px";
  const tableHeight = `${data.length * 500}px`;
  const containerHeight = tableHeight > minHeight ? tableHeight : minHeight;
  const activeBankData = bankAccount.filter(
    (bankAccount: BankData) => bankAccount.isActive,
  );

  const getBankInitials = (bankName: string) => {
    const words = bankName.split(" ");
    const initials = words.map((word) => word.charAt(0)).join("");
    return initials.toUpperCase();
  };

  const handleUpdate = (updatedInvoice: IInvoice) => {
    setSelectedInvoice(updatedInvoice);
  };

  const { downloadPDF, isLoading } = useDownloadInvoice({
    invoice: document,
    //@ts-ignore
    invoiceId: invoiceId,
    onUpdate: handleUpdate,
  });

  const { downloadQuotePDF, isLoadingQuote } = useDownloadQuote({
    quote: document,
    //@ts-ignore
    quoteId: quoteId,
    onUpdate: handleUpdate,
  });

  const handleDownloadPDF = async () => {
    try {
      setIsDownloading(true);
      await downloadPDF();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDownloadQuotePDF = async () => {
    try {
      setIsDownloading(true);
      await downloadQuotePDF();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      {/* Mobile screens */}

      <div
        className="relative bg-white  h-[748px] w-[704px]  overflow-y-auto  md:hidden z-99"
        id="invoice-document "
      >
        <div className="flex justify-end text-[red] bg-[white] mt-[1.7em] px-[1em] text-[12px] font-semibold">
          <button onClick={onClose} className="flex items-center">
            Close <CloseIcon />
          </button>
        </div>
        {type === "invoice" && (
          <div className="flex justify-center">
            <span className="mt-[0.4em] mr-[-2em] z-0 text-[white] pl-[1em]">
              <PreviewDownloadIcon />
            </span>
            <button
              className="border border-[#044E97] whitespace-nowrap w-[214px] h-[40px] bg-[#044E97] text-[12px] text-[white] rounded-md pl-[]"
              onClick={handleDownloadPDF}
              disabled={isDownloading}
            >
              {isDownloading
                ? "Generating PDF..."
                : "Click to download pdf file"}
            </button>
          </div>
        )}
        {type === "quote" && (
          <div className="flex justify-center">
            <span className="mt-[0.4em] mr-[-2em] z-0 text-[white] pl-[1em]">
              <PreviewDownloadIcon />
            </span>
            <button
              className="border border-[#044E97] whitespace-nowrap w-[214px] h-[40px] bg-[#044E97] text-[12px] text-[white] rounded-md pl-[]"
              onClick={handleDownloadQuotePDF}
              disabled={isDownloading}
            >
              {isDownloading
                ? "Generating PDF..."
                : "Click to download pdf file"}
            </button>
          </div>
        )}
        <div className="p-[1em]">
          <div className="bg-[#F5F6F7] py-[32px] ">
            <div className="bg-[#F5F6F7] px-[30px]">
              <div className="flex justify-between items-center mt-[em]">
                <div className="mt-[-1.5em]">
                  <div className="max-w-[25px] max-h-[11px]">
                    {renderLogo()}
                  </div>
                  <p
                    className="mt-[4.7em] text-[6.92px] font-semibold"
                    style={{
                      color: brandColor || "#044e97",
                      textTransform: "uppercase",
                    }}
                  >
                    {businessName}
                  </p>

                  <p className="w-[84px] mt-1 text-[6.34px]">{address}</p>
                  <p className="w-[84px] mt-1 text-[6.34px]">{phone}</p>
                  <p className="text-[6.34px]">{code}</p>
                </div>
                {type === "invoice" && (
                  <div className="text-right mt-[-3.5em]">
                    <span className="text-[10.38px] mt-[1em] font-semibold">
                      INVOICE
                    </span>
                    <p className="text-[6.92px] mt-[0.5em]">
                      {document.invoiceNumber}
                    </p>
                    {document.status === Statuses.PAID.toLowerCase() ? (
                      ""
                    ) : (
                      <>
                        <p className="text-[6.34px] font-normal mt-[2em]">
                          {t("balanceDue")}
                        </p>
                        <p
                          className="text-[6.92px] mt-[0.5em] font-semibold  "
                          style={{ color: brandColor || "#044e97" }}
                        >
                          {" "}
                          {formatMoney(document.balanceDue, document.currency)}
                        </p>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white py-[3px] px-[30px]">
          <div className="mt-4 bg-[white] flex justify-between ">
            <div className="bg-white">
              <p className="font-semibold  mb-3  text-[6.34px] text-[#4E4E4E] ">
                BILLED TO
              </p>
              <p className="text-[6.34px] font-semibold text-[#101010]">
                {document.customer.firstName + " " + document.customer.lastName}
                .
              </p>
              <p className="w-[66px] text-[6.34px]">
                {document.customer.address}
              </p>
            </div>
            <div>
              <p className="font-semibold mb-3 text-[6.34px] text-[#4E4E4E] ">
                Invoice Date:
              </p>
              <p className="text-right text-[6.34px] font-semibold text-[#101010]">
                {dayjs(document.issueDate).format("DD MMMM, YYYY")}
              </p>
            </div>
            {type === "invoice" && (
              <div>
                <p className="font-semibold mb-3 text-[6.34px] text-[#4E4E4E] ">
                  Due Date:
                </p>
                <p className="text-right text-[6.34px] font-semibold text-[#101010]">
                  {dayjs(document.invoiceDueAt).format("DD MMMM, YYYY")}
                </p>
              </div>
            )}
            {type === "quote" && (
              <div>
                <p className="font-semibold mb-3 text-[6.34px] text-[#4E4E4E] ">
                  Expiry Date:
                </p>
                <p className="text-right text-[6.34px] font-semibold text-[#101010]">
                  {dayjs(document.expiryDate).format("DD MMMM, YYYY")}
                </p>
              </div>
            )}
          </div>

          <div className="mt-10 bg-white"></div>

          <div className="" style={{ overflowX: "auto" }}>
            {isRetailBusiness
              ? data && (
                  <MobilePreviewTable columns={Invoicecolumns} data={data} />
                )
              : data && (
                  <MobilePreviewTable columns={Servicecolumns} data={data} />
                )}
          </div>

          <div className="mt-[2em] flex justify-between bg-white ">
            <div className=" border-t text-[11px] mt-[1em] bg-[#F5F6F7]  px-[1em] h-[80px] ">
              <p className="text-[#000000] text-[6.92px] font-semibold">
                {" "}
                Payment Details:{" "}
              </p>
              <p className=" ">
                {activeBankData && activeBankData.length > 0 ? (
                  <>
                    {activeBankData.map((bank: BankData, index: number) => (
                      <p
                        className="   whitespace-nowrap text-[6.92px] flex justify-start"
                        key={index}
                        style={{ textTransform: "uppercase" }}
                      >
                        {bank.accountNumber}&nbsp; {bank.accountName}&nbsp;{" "}
                        {getBankInitials(bank.bankName)}
                      </p>
                    ))}
                  </>
                ) : (
                  <span
                    className="px-[1em] py-[3em] text-[6.92px]"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Payment Details: No bank account added
                  </span>
                )}
              </p>
            </div>
            <div className="ml-[em]  bg-white ">
              <div className="flex justify-between  pl-[7em] pr-[5em]  text-[6.34px] gap-[em] ">
                <p>Sub Total:</p>
                <p className="text-right">
                  {formatMoney(subTotal, document.currency)}
                </p>
              </div>
              <hr className="ml-[2.5em] w-[130px] " />
              <div className="flex justify-between  pl-[7em] pr-[5em]  text-[6.34px] gap-[2em]  ">
                <p>Discount:</p>
                <p>{formatMoney(discount, document.currency)}</p>
              </div>
              {type === "invoice" && (
                <div className="flex justify-between   pl-[7em] pr-[5em]  text-[6.34px] gap-[2em] ">
                  <p>VAT:</p>
                  <p>{formatMoney(tax, document.currency)}</p>
                </div>
              )}
              <hr className="ml-[2.5em]  w-[130px]  " />
              <div className="flex justify-between   pl-[7em] pr-[5em]  text-[6.34px] gap-[em]  font-semibold ">
                <p>Total(NGN):</p>
                <p>{formatMoney(total, document.currency)}</p>
              </div>
              <hr
                className="ml-[2.5em]  w-[130px] "
                style={{ borderColor: brandColor || "#044e97" }}
              />
              {type === "invoice" && (
                <div className="mt-[em] text-[6.34px] pr-[5em] flex justify-between  pl-[7.1em] gap-[8em]  font-semibold py-[1em] top-0 left-0 rounded-[5.75px] ">
                  <p
                    className=""
                    style={{
                      whiteSpace: "nowrap",
                      color: brandColor || "#044e97",
                    }}
                  >
                    Balance Due:
                  </p>
                  <p
                    className="text-[6.34px]"
                    style={{ color: brandColor || "#044e97" }}
                  >
                    {formatMoney(document.balanceDue, document.currency)}
                  </p>
                </div>
              )}
              <hr
                className="ml-[2.5em]  w-[130px] "
                style={{ borderColor: brandColor || "#044e97" }}
              />
            </div>
          </div>
          <div className="py-[32px] px-[px]">
            <p className="text-[6.34px] mt-[1em]">
              Thank you for choosing {businessName}
            </p>
          </div>
        </div>

        <div className="bg-white border-t bottom-0  py-[1em] h-[90px] mt-[9em] text-[6.34px] py-[32px] px-[30px] ">
          <p>{note}</p>
        </div>
      </div>

      {/* Desktop screens */}
      <div
        className=" bg-white  h-[748px] w-[704px] xl:w-[946px] 2xl:w-[1046px] biglg:w-[846px] lg:w-[746px] overflow-y-auto  hidden lg:ml-0 md:block "
        id="invoice-document "
      >
        <div className="flex justify-end text-[red] bg-[white] 2xl:mt-[1em] xl:mt-[1.5em] biglg:mt-[6.5em] lg:mt-[5.5em] md:mt-[1.3em] px-[3em] text-[16px]">
          <button onClick={onClose} className="flex items-center">
            Close <CloseIcon />
          </button>
        </div>
        {type === "invoice" && (
          <div className="flex justify-center ">
            <span className="mt-[0.4em] mr-[-2em] z-0 text-[white] pl-[1em]">
              <PreviewDownloadIcon />
            </span>
            <button
              className="border border-[#044E97] w-[218px] h-[44px] bg-[#044E97] lg:text-[14px] md:text-[12px] text-[white] rounded-md pl-[1em]  "
              onClick={handleDownloadPDF}
              disabled={isDownloading}
            >
              {isDownloading
                ? "Generating PDF..."
                : "Click to download pdf file"}
            </button>
          </div>
        )}
        {type === "quote" && (
          <div className="flex justify-center ">
            <span className="mt-[0.4em] mr-[-2em] z-0 text-[white] pl-[1em]">
              <PreviewDownloadIcon />
            </span>
            <button
              className="border border-[#044E97] w-[218px] h-[44px] bg-[#044E97] lg:text-[14px] md:text-[12px] text-[white] rounded-md pl-[1em]  "
              onClick={handleDownloadQuotePDF}
              disabled={isDownloading}
            >
              {isDownloading
                ? "Generating PDF..."
                : "Click to download pdf file"}
            </button>
          </div>
        )}

        <div className="p-[1em]">
          <div className="bg-[#F5F6F7] py-[32px] ">
            <div className="bg-[#F5F6F7] px-[60px]">
              <div className="flex justify-between items-center mt-[em]">
                <div className="mt-[-1.5em]">
                  <div className="max-w-[90px] max-h-[60px]">
                    {renderLogo()}
                  </div>
                  <p
                    className="mt-[2.7em] text-[12px] font-semibold"
                    style={{
                      color: brandColor || "#044e97",
                      textTransform: "uppercase",
                    }}
                  >
                    {businessName}
                  </p>

                  <p className="w-[110px] mt-1 text-[11px]">{address}</p>
                  <p className="w-[110px] mt-1 text-[11px]">{phone}</p>
                  <p className="text-[11px]">{code}</p>
                </div>
                {type === "invoice" && (
                  <div className="text-right mt-[-3.5em]">
                    <span className="text-[18px] mt-[1em] font-semibold">
                      INVOICE
                    </span>
                    <p className="text-[12px] mt-[0.5em]">
                      {document.invoiceNumber}
                    </p>
                    {document.status === Statuses.PAID.toLowerCase() ? (
                      ""
                    ) : (
                      <>
                        <p className="text-[11px] font-normal mt-[2em]">
                          Balance Due
                        </p>
                        <p
                          className="text-[12px] mt-[0.5em] font-semibold  "
                          style={{ color: brandColor || "#044e97" }}
                        >
                          {" "}
                          {formatMoney(document.balanceDue, document.currency)}
                        </p>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white py-[32px] px-[80px]">
          <div className="mt-4 bg-[white] flex justify-between ">
            <div className="bg-white">
              <p className="font-semibold  mb-3  text-[11px] text-[#4E4E4E] ">
                BILLED TO
              </p>
              <p className="text-[11px] font-semibold text-[#101010]">
                {document.customer.firstName + " " + document.customer.lastName}
                .
              </p>
              <p className="w-[150px] text-[11px]">
                {document.customer.address}
              </p>
            </div>
            <div>
              <p className="font-semibold mb-3 text-[11px] text-[#4E4E4E] ">
                Invoice Date:
              </p>
              <p className="text-right text-[11px] font-semibold text-[#101010]">
                {dayjs(document.issueDate).format("DD MMMM, YYYY")}
              </p>
            </div>
            {type === "invoice" && (
              <div>
                <p className="font-semibold mb-3 text-[11px] text-[#4E4E4E] ">
                  Due Date:
                </p>
                <p className="text-right text-[11px] font-semibold text-[#101010]">
                  {dayjs(document.invoiceDueAt).format("DD MMMM, YYYY")}
                </p>
              </div>
            )}
            {type === "quote" && (
              <div>
                <p className="font-semibold mb-3 text-[11px] text-[#4E4E4E] ">
                  Expiry Date:
                </p>
                <p className="text-right text-[11px] font-semibold text-[#101010]">
                  {dayjs(document.expiryDate).format("DD MMMM, YYYY")}
                </p>
              </div>
            )}
          </div>

          <div className="mt-10 bg-white"></div>

          <div className="text-[11px]" style={{ overflowX: "auto" }}>
            {isRetailBusiness
              ? data && <PreviewTable columns={Invoicecolumns} data={data} />
              : data && <PreviewTable columns={Servicecolumns} data={data} />}
          </div>

          <div className="mt-[2em] flex justify-between bg-white ">
            <div className=" border-t text-[11px] mt-[1em] bg-[#F5F6F7] biglg:w-[300px] w-100% px-[1em] h-[120px] ">
              <p className="text-[#000000] text-[12px] font-semibold">
                {" "}
                Payment Details:{" "}
              </p>
              <p className=" ">
                {activeBankData && activeBankData.length > 0 ? (
                  <>
                    {activeBankData.map((bank: BankData, index: number) => (
                      <p
                        className="biglg:w-[380px] lg:w-[250px] w-100%  xl:whitespace-nowrap biglg:whitespace-nowrap 2xl:whitespace-nowrap md:whitespace-normal bigmd:whitespace-normal lg:whitespace-nowrap lg:text-[10px] xl:text-[12px] biglg:text-[12px]  2xl:text-[12px]  flex justify-start"
                        key={index}
                        style={{ textTransform: "uppercase" }}
                      >
                        {bank.accountNumber}&nbsp; {bank.accountName}&nbsp;{" "}
                        <br /> {bank.bankName}
                      </p>
                    ))}
                  </>
                ) : (
                  <span
                    className="px-[1em] py-[3em]"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Payment Details: No bank account added
                  </span>
                )}
              </p>
            </div>
            {type === "invoice" && (
              <div className=" md:ml-[-1em] lg:ml-[-2.6em] biglg:ml-[-3.5em] bg-white ">
                <div className="flex justify-between  pl-[7em] pr-[1.5em] lg:pl-[10em] lg:pr-[11em] biglg:pl-[16em] biglg:pr-[7em]  xl:pl-[14em]  xl:pr-[7em] lg:text-[11px] md:text-[11px] ">
                  <p>Sub Total:</p>
                  <p className="text-right">
                    {formatMoney(subTotal, document.currency)}
                  </p>
                </div>
                <hr className="ml-[5.5em] 2xl:ml-[5.5em] xl:ml-[8.5em] biglg:ml-[8.5em] md:ml-[4.5em] lg:ml-[5em] " />
                <div className="flex justify-between pl-[7em] pr-[1.5em] lg:text-[11px] biglg:pl-[16em] biglg:pr-[7em]  xl:pl-[14em]  xl:pr-[7em]  md:text-[11px] mt-[1.4em] lg:pl-[10em] lg:pr-[11em] ">
                  <p>Discount:</p>
                  <p>{formatMoney(discount, document.currency)}</p>
                </div>

                <div className="flex justify-between pl-[7em] pr-[1.5em] lg:text-[11px] biglg:pl-[16em] biglg:pr-[7em]  xl:pl-[14em]  xl:pr-[7em] md:text-[11px] mt-[0.5em] lg:pl-[10em] lg:pr-[11em] ">
                  <p>VAT:</p>
                  <p>{formatMoney(tax, document.currency)}</p>
                </div>

                <hr className="ml-[5.5em] 2xl:ml-[5.5em] xl:ml-[8.5em] biglg:ml-[8.5em] md:ml-[4.5em] lg:ml-[5em]" />
                <div className="flex justify-between pl-[7em] pr-[1.5em] font-semibold biglg:pl-[16em] biglg:pr-[7em]  xl:pl-[14em]  xl:pr-[7em]  lg:text-[11px] md:text-[11px] mt-[1.4em] lg:pl-[10em] lg:pr-[11em] ">
                  <p>Total(NGN):</p>
                  <p>{formatMoney(total, document.currency)}</p>
                </div>
                <hr
                  className="ml-[5.5em] 2xl:ml-[5.5em] xl:ml-[8.5em] biglg:ml-[8.5em] md:ml-[4.5em] lg:ml-[5em]"
                  style={{ borderColor: brandColor || "#044e97" }}
                />
                <div className="mt-[em]  md:ml-[3em] xl:ml lg:w-[423px] pr-[1.5em] md:w-[271px] md:h-[50px] biglg:pl-[13em] biglg:pr-[7em]  lg:h-[70px] lg:pl-[7em] lg:pr-[11em]  flex justify-between gap-[5.2em] pl-[4.1em] xl:pl-[11em]  xl:pr-[7em] font-semibold py-[1em] top-0 left-0 rounded-[5.75px] lg:text-[11px] md:text-[11px]">
                  <p
                    className=""
                    style={{
                      whiteSpace: "nowrap",
                      color: brandColor || "#044e97",
                    }}
                  >
                    Balance Due:
                  </p>
                  <p
                    className="lg:text-[11px]  text-[11px]"
                    style={{ color: brandColor || "#044e97" }}
                  >
                    {formatMoney(document.balanceDue, document.currency)}
                  </p>
                </div>

                <hr
                  className="ml-[5.5em] 2xl:ml-[5.5em] xl:ml-[8.5em] biglg:ml-[8.5em] md:ml-[4.5em] lg:ml-[5em] mt-[-1.4em]"
                  style={{ borderColor: brandColor || "#044e97" }}
                />
              </div>
            )}

            {type === "quote" && (
              <div className=" md:ml-[-1em] lg:ml-[-2.6em] biglg:ml-[-3.5em] bg-white ">
                <div className="flex justify-between gap-[7em] pl-[7em] pr-[1.5em] lg:pl-[10em] lg:pr-[11em] pb-[2em] biglg:pl-[16em] biglg:pr-[5em]  xl:pl-[14em]  xl:pr-[7em] lg:text-[11px] md:text-[11px] ">
                  <p>Sub Total:</p>
                  <p className="text-right">
                    {formatMoney(subTotal, document.currency)}
                  </p>
                </div>
                <hr className="ml-[5.5em] 2xl:ml-[5.5em] xl:ml-[8.5em] biglg:ml-[8.5em] md:ml-[4.5em] lg:ml-[5em] " />
                <div className="flex justify-between pl-[7em] pr-[1.5em] lg:text-[11px] biglg:pl-[16em] biglg:pr-[5em] pb-[2em]  xl:pl-[14em]  xl:pr-[7em]  md:text-[11px] mt-[1.4em] lg:pl-[10em] lg:pr-[11em] ">
                  <p>Discount:</p>
                  <p>{formatMoney(discount, document.currency)}</p>
                </div>

                <hr
                  className="ml-[5.5em] 2xl:ml-[5.5em] xl:ml-[8.5em] biglg:ml-[8.5em] md:ml-[4.5em] lg:ml-[5em] xl:mt-0 lg:mt-0 md:mt-0 mt-[-9em]"
                  style={{ borderColor: brandColor || "#044e97" }}
                />
                <div className="flex justify-between pl-[7em] pr-[1.5em] font-semibold biglg:pl-[16em] biglg:pr-[5em]  xl:pl-[14em]  xl:pr-[7em]  lg:text-[11px] md:text-[11px] mt-[1.4em] lg:pl-[10em] lg:pr-[11em] ">
                  <p>Total(NGN):</p>
                  <p>{formatMoney(total, document.currency)}</p>
                </div>
                <hr
                  className="ml-[5.5em] 2xl:ml-[5.5em] xl:ml-[8.5em] biglg:ml-[8.5em] md:ml-[4.5em] lg:ml-[5em] xl:mt-[1.8em] lg:mt-[1.8em] md:mt-[1.8em] "
                  style={{ borderColor: brandColor || "#044e97" }}
                />
              </div>
            )}
          </div>
          <div className="py-[32px] px-[px]">
            <p className="text-[11px] mt-[1em]">
              Thank you for choosing {businessName}
            </p>
          </div>
        </div>

        <div className="bg-white border-t bottom-0  py-[1em] h-[90px] mt-[9em] text-[11px] py-[32px] px-[80px] ">
          <p>{note}</p>
        </div>
      </div>
    </>
  );
};

export default PreviewCopy;
