import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import usePaymentLinkInfo from "../../../../hooks/invoice/usePaymentLinkInfo";
import ModalBig from "../../../common/Modal/ModalBig";
import ModalContent from "../../../common/Modal/ModalContent";
import ModalFooter from "../../../common/Modal/ModalFooter";
import ModalHeader from "../../../common/Modal/ModalHeader";
import Modals from "../../../common/Modal/Modals";

interface PaymentLinkInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onGenerate: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const PaymentLinkInfoModal: React.FC<PaymentLinkInfoModalProps> = ({
  isOpen,
  onClose,
  onGenerate,
}) => {
  const { t } = useTranslation("invoices");
  const { buttonText } = usePaymentLinkInfo();

  return (
    <ModalBig isOpen={isOpen} onClose={onClose}>
      <ModalHeader title={t("paymentlink")} onClose={onClose} />
      <ModalContent>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {/* Left Column */}
          <div>
            <Section
              title={t("paymentlink1")}
              content={
                <>
                  <p>
                    For users to be able to access the payment link feature,
                    they need to have a registered RC number. Esemie compares
                    the business name under the RC number with the business name
                    registered on Esemie and also the business name on their
                    bank account to ensure it matches.
                  </p>
                </>
              }
            />

            <Section
              title={t("paymentlink2")}
              content={<p>{t("paymentlink3")}</p>}
            />

            <Section
              title={t("paymentlink4")}
              content={<p>{t("paymentlink5")}</p>}
            />
          </div>

          {/* Right Column */}
          <div>
            <Section
              title={t("paymentlink6")}
              content={
                <p>
                  {t("paymentlink7")}
                  <span className="font-bold"> {t("paymentlink11")}</span>{" "}
                  {t("paymentlink12")}
                  <span className="font-bold"> {t("paymentlink13")}</span>{" "}
                  {t("paymentlink14")}
                </p>
              }
            />

            <Section
              title={t("paymentlink8")}
              content={<p>{t("paymentlink9")}</p>}
            />
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <button
          className="bg-[#044E97] text-white px-6 py-3 rounded-lg font-semibold focus:outline-none w-full"
          onClick={onGenerate}
        >
          {buttonText}
        </button>
      </ModalFooter>
    </ModalBig>
  );
};

interface SectionProps {
  title: string;
  content: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ title, content }) => (
  <div className="mb-4">
    <h3 className="font-semibold text-lg">{title}</h3>
    <div className="mt-2 text-sm">{content}</div>
  </div>
);

export default PaymentLinkInfoModal;
