import * as React from "react";
import Server_Error from "./svgicons/Server_error.svg";

interface ErrorIcon500Props {
  color?: string;
  style?: React.CSSProperties;
}

const ErrorIcon500: React.FC<ErrorIcon500Props> = (props) => {
  return <img {...props} src={Server_Error} alt="servor error icons" />;
};
export { ErrorIcon500 };
