import React from "react";
const ArchivedIcon = ({ size = "24" }) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.66797 13.3359C1.66797 11.3869 1.66797 10.4123 2.11502 9.70084C2.34815 9.32983 2.66186 9.01611 3.03288 8.78299C3.74436 8.33594 4.7189 8.33594 6.66797 8.33594H13.3346C15.2837 8.33594 16.2582 8.33594 16.9697 8.78299C17.3407 9.01611 17.6545 9.32983 17.8876 9.70084C18.3346 10.4123 18.3346 11.3869 18.3346 13.3359C18.3346 15.285 18.3346 16.2595 17.8876 16.971C17.6545 17.342 17.3407 17.6558 16.9697 17.8889C16.2582 18.3359 15.2837 18.3359 13.3346 18.3359H6.66797C4.7189 18.3359 3.74436 18.3359 3.03288 17.8889C2.66186 17.6558 2.34815 17.342 2.11502 16.971C1.66797 16.2595 1.66797 15.285 1.66797 13.3359Z"
          stroke="#344054"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.6693 8.33333C16.6693 7.16656 16.6693 6.58317 16.4422 6.13752C16.2425 5.74552 15.9238 5.42681 15.5318 5.22707C15.0861 5 14.5027 5 13.3359 5H6.66927C5.50249 5 4.91911 5 4.47346 5.22707C4.08145 5.42681 3.76274 5.74552 3.56301 6.13752C3.33594 6.58317 3.33594 7.16656 3.33594 8.33333"
          stroke="#344054"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15 5.0013C15 3.42995 15 2.64428 14.5118 2.15612C14.0237 1.66797 13.238 1.66797 11.6667 1.66797H8.33333C6.76198 1.66797 5.97631 1.66797 5.48816 2.15612C5 2.64428 5 3.42995 5 5.0013"
          stroke="#344054"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.5 11.668C12.5 12.5884 11.7538 13.3346 10.8333 13.3346H9.16667C8.24619 13.3346 7.5 12.5884 7.5 11.668"
          stroke="#344054"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </>
  );
};
export { ArchivedIcon };
