export const validateCreateService = ({
  name,
  duration,
  description,
  price,
}: any) => {
  const errors = {
    name: "",
    duration: "",
    description: "",
    charge: { NGN: "", USD: "", EUR: "" },
  };

  if (!name) {
    errors.name = "Service Name is required";
  }

  const servicePrice = price.find((p: any) => p.currency === "NGN");
  const servicePriceUSD = price.find((p: any) => p.currency === "USD");
  const servicePriceEUR = price.find((p: any) => p.currency === "EUR");

  if (servicePrice) {
    if (servicePrice.amount <= 0) {
      errors.charge.NGN = "amount must be greater than 0";
    }
  }
  if (servicePriceUSD) {
    if (servicePriceUSD.amount === 0) {
      errors.charge.USD = "amount must be greater than 0";
    }
  }
  if (servicePriceEUR) {
    if (servicePriceEUR.amount === 0) {
      errors.charge.EUR = "amount must be greater than 0";
    }
  }

  if (!servicePrice) {
    errors.charge.NGN = "Service charge is required";
  }
  const hasErrors = Object.values(errors).some((error) => {
    if (typeof error === "object") {
      // Check for nested errors
      return Object.values(error).some((nestedError) => nestedError !== "");
    }
    return error !== "";
  });

  return { hasErrors, errors };
};
export const validateEditService = ({
  name,
  duration,
  description,
  price,
}: any) => {
  const errors = {
    name: "",
    duration: "",
    description: "",
    charge: { NGN: "", USD: "", EUR: "" },
  };

  if (!name) {
    errors.name = "Service Name is required";
  }

  const servicePrice = price.find((p: any) => p.currency === "NGN");
  const servicePriceUSD = price.find((p: any) => p.currency === "USD");
  const servicePriceEUR = price.find((p: any) => p.currency === "EUR");

  if (servicePrice) {
    if (servicePrice.amount <= 0) {
      errors.charge.NGN = "amount must be greater than 0";
    }
  }
  if (servicePriceUSD) {
    if (servicePriceUSD.amount === 0) {
      errors.charge.USD = "amount must be greater than 0";
    }
  }
  if (servicePriceEUR) {
    if (servicePriceEUR.amount === 0) {
      errors.charge.EUR = "amount must be greater than 0";
    }
  }

  if (!servicePrice) {
    errors.charge.NGN = "Service charge is required";
  }

  const hasErrors = Object.values(errors).some((error) => {
    if (typeof error === "object") {
      // Check for nested errors
      return Object.values(error).some((nestedError) => nestedError !== "");
    }
    return error !== "";
  });

  return { hasErrors, errors };
};
