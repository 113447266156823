export function convertImageToBase64(
  url: string,
  callback: (base64: string) => void,
  errorCallback: (error: any) => void,
) {
  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    })
    .then((blob) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result as string);
      };
      reader.readAsDataURL(blob);
    })
    .catch((error) => {
      errorCallback(error);
    });
}
