import React, { forwardRef } from "react";
import { CustomerItemComponent } from "./customerItem";
import { ExpenseItemComponent } from "./expenseItem";
import { InventoryItemComponent } from "./InventoryItem";
import { InvoiceItemComponent } from "./invoiceItem";
import { ServiceItemComponent } from "./serviceItem";

interface DropdownItemProps<T> {
  item: T;
  displayKey: keyof T;
  onClick: (item: T, event: React.MouseEvent) => void;
  isLoading?: boolean;
}

export const CustomDropdownItem: React.FC<DropdownItemProps<any>> = forwardRef<
  HTMLDivElement,
  DropdownItemProps<any>
>(({ item, displayKey, onClick }, ref) => {
  const renderContent = () => {
    const { data } = item;

    if (data && data.firstName) {
      return (
        <CustomerItemComponent
          firstName={data.firstName}
          lastName={data.lastName}
          phone={data.phone}
        />
      );
    }

    if (data && data.invoiceNumber) {
      return (
        <InvoiceItemComponent
          invoiceNumber={data.invoiceNumber}
          customer={data.customer}
        />
      );
    }

    if (data && data.vendorName) {
      return (
        <ExpenseItemComponent
          vendorName={data.vendorName}
          category={data.category}
        />
      );
    }
    if (data && data.name) {
      return (
        <InventoryItemComponent name={data.name} quantity={data.quantity} />
      );
    }
    if (data && data.name) {
      return <ServiceItemComponent name={data.name} duration={data.duration} />;
    }

    return null;
  };
  return (
    <div className="p-2" ref={ref}>
      {renderContent()}
    </div>
  );
});
