import TestImg from "../../assets/TestImg.png";
import LogoWhite from "../LogoWhite";

interface Props {
  backgroundClass: string;
}

const AuthInfo: React.FC<Props> = ({ backgroundClass }) => {
  return (
    <div
      className={`px-[60px] ${backgroundClass} h-full text-white flex flex-col justify-end pb-12`}
    >
      <div className="w-full flex flex-col justify-end">
        <div>
          <LogoWhite />
        </div>
        <div className="mt-10 mb-[80px] w-full">
          <h1 className="text-[23px] 1180:text-[28px] 1280:text-[30px] xl:text-[32px] leading-12 font-semibold mb-4">
            Esemie Enforces Business Standards
          </h1>
          <p className="text-[16px] 1180:text-[18px] 1280:text-[20px] xl:text-[24px] font-medium leading-7 xl:leading-[30px]">
            Get organized and standardized with esemie. <br /> Manage your
            business easily and grow confidently.
          </p>
        </div>
        <div className="border border-[#9BA5B9] rounded-[12px] p-6">
          <p className="text-[20px] font-medium leading-[27px]">
            "Esemie helps me see if my business is making a profit or a loss. It
            tracks all my expenses, so I can cut down where needed. I get clear
            daily insights into my business operations. I highly recommend it!!"
          </p>
          <div className="flex mt-8 items-center">
            <span className="border-[1px] border-[#FFFFFF] w-[24px] h-0" />
            <img
              src={TestImg}
              alt=""
              className="h-9 w-9 rounded-full ml-6 mr-4"
            />
            <div>Annabelle | CEO Bubblesbasket</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AuthInfo };
