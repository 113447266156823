import dayjs from "dayjs";
import { CustomerT } from "../../../types";
import { CustomerContactInfo } from "./CustomerContactInfo";
import React from "react";

interface CustomerContactProps {
  customer: CustomerT;
}
export const CustomerContact: React.FC<CustomerContactProps> = ({
  customer,
}) => {
  const handleCall = (phoneNumber: string) => {
    window.open(`tel:${phoneNumber}`);
  };

  return (
    <div className="flex items-center gap-6 w-full p-4 bg-white rounded-[4px] md:w-[451px] mt-6 md:mt-0 md:border md:border-[#CECECE]">
      <div className="flex flex-col gap-6 w-full">
        <CustomerContactInfo
          title="Email Address"
          value={customer?.email || "N/A"}
        />
        <a
          href={`tel:${customer?.phone}`}
          onClick={() => handleCall(customer?.phone || "")}
        >
          <CustomerContactInfo
            title="Phone Number"
            value={customer?.phone || "N/A"}
          />
        </a>
      </div>
      <div className="flex flex-col gap-6 w-full">
        <CustomerContactInfo
          title="Address"
          value={customer.address || "N/A"}
        />
        <CustomerContactInfo
          title="Last Active"
          value={dayjs(customer?.createdAt).format("MM/DD/YYYY") || "N/A"}
        />
      </div>
    </div>
  );
};
