import * as React from "react";

interface AddSquareIconProps {
  color?: string;
  size?: string;
}

const AddSquareIcon: React.FC<AddSquareIconProps> = ({
  color,
  size = "17",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.125 8.5C2.125 6.28337 2.125 5.17506 2.5521 4.32655C2.93793 3.56004 3.56004 2.93793 4.32655 2.5521C5.17506 2.125 6.28337 2.125 8.5 2.125V2.125C10.7166 2.125 11.8249 2.125 12.6734 2.5521C13.44 2.93793 14.0621 3.56004 14.4479 4.32655C14.875 5.17506 14.875 6.28337 14.875 8.5V8.5C14.875 10.7166 14.875 11.8249 14.4479 12.6734C14.0621 13.44 13.44 14.0621 12.6734 14.4479C11.8249 14.875 10.7166 14.875 8.5 14.875V14.875C6.28337 14.875 5.17506 14.875 4.32655 14.4479C3.56004 14.0621 2.93793 13.44 2.5521 12.6734C2.125 11.8249 2.125 10.7166 2.125 8.5V8.5Z"
        stroke="#044E97"
        strokeWidth="2"
      />
      <path
        d="M8.5 5.66602L8.5 11.3327"
        stroke="#044E97"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M11.3335 8.5L5.66683 8.5"
        stroke="#044E97"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { AddSquareIcon };
const AddSquareIcon2: React.FC<AddSquareIconProps> = ({
  color,
  size = "17",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.125 8.5C2.125 6.28337 2.125 5.17506 2.5521 4.32655C2.93793 3.56004 3.56004 2.93793 4.32655 2.5521C5.17506 2.125 6.28337 2.125 8.5 2.125V2.125C10.7166 2.125 11.8249 2.125 12.6734 2.5521C13.44 2.93793 14.0621 3.56004 14.4479 4.32655C14.875 5.17506 14.875 6.28337 14.875 8.5V8.5C14.875 10.7166 14.875 11.8249 14.4479 12.6734C14.0621 13.44 13.44 14.0621 12.6734 14.4479C11.8249 14.875 10.7166 14.875 8.5 14.875V14.875C6.28337 14.875 5.17506 14.875 4.32655 14.4479C3.56004 14.0621 2.93793 13.44 2.5521 12.6734C2.125 11.8249 2.125 10.7166 2.125 8.5V8.5Z"
        stroke="#ffffff"
        strokeWidth="2"
      />
      <path
        d="M8.5 5.66602L8.5 11.3327"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M11.3335 8.5L5.66683 8.5"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { AddSquareIcon2 };
export const AddSquareIconSmall: React.FC<AddSquareIconProps> = ({
  color,
  size = "11",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.125 8.5C2.125 6.28337 2.125 5.17506 2.5521 4.32655C2.93793 3.56004 3.56004 2.93793 4.32655 2.5521C5.17506 2.125 6.28337 2.125 8.5 2.125V2.125C10.7166 2.125 11.8249 2.125 12.6734 2.5521C13.44 2.93793 14.0621 3.56004 14.4479 4.32655C14.875 5.17506 14.875 6.28337 14.875 8.5V8.5C14.875 10.7166 14.875 11.8249 14.4479 12.6734C14.0621 13.44 13.44 14.0621 12.6734 14.4479C11.8249 14.875 10.7166 14.875 8.5 14.875V14.875C6.28337 14.875 5.17506 14.875 4.32655 14.4479C3.56004 14.0621 2.93793 13.44 2.5521 12.6734C2.125 11.8249 2.125 10.7166 2.125 8.5V8.5Z"
        stroke="#044E97"
        strokeWidth="2"
      />
      <path
        d="M8.5 5.66602L8.5 11.3327"
        stroke="#044E97"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M11.3335 8.5L5.66683 8.5"
        stroke="#044E97"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
