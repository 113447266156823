import React from "react";

export type Props = {
  error?: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: boolean;
  checked?: boolean;
  name: string;
  className?: string;
  disabled?: boolean;
};

const CheckboxComponent = ({
  error,
  label,
  onChange,
  name,
  checked,
}: Props) => (
  <div className="">
    <label className="cursor-pointer">
      {label}
      <input
        type="checkbox"
        value=""
        className="sr-only peer "
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <div className=" relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
      {/* <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-500"></span> */}
    </label>

    {error && (
      <p className="text-red-500 text-xs italic relative bottom-0 right-0 mt-1">
        {error}
      </p>
    )}
  </div>
);

export { CheckboxComponent };
