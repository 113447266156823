import React, { useEffect, useState } from "react";
import { Pen, PenSmall } from "../../../../assets/icons/PenIcon";
import { useUserStore } from "../../../../state";
import { BankAccountData, BankData } from "../../../../types";
import { CreateModal } from "../../../common/CreateModal";
import BankAccountModal from "../../bank/bankAccountModal";
import CreateBank from "../../bank/createBank";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useCompanyStore } from "../../../../state/useCompanyStore";

const BankDetails = () => {
  const user = useUserStore((state) => state.user);
  const { t } = useTranslation("invoices");
  const setUser = useUserStore((state) => state.setUser);
  if (!user) {
    return null;
  }
  const bankAccounts = user.bankAccounts ? user.bankAccounts.flat() : [];
  const [showBankModal, setShowBankModal] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [showCreateBank, setShowCreateBank] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { companyBankAccounts, setCompanyBankAccounts } = useCompanyStore();
  const [toggleRender, setToggleRender] = useState(false);
  const createBankAccount = useCompanyStore((state) => state.createBankAccount);
  const activeBankData = bankAccounts.filter(
    (bankAccount: BankData) => bankAccount.isActive,
  );

  const toggleBankModal = () => {
    setShowBankModal(!showBankModal);
  };

  const handleAddBankAccount = (newBankAccount: BankData) => {
    const updatedUser = {
      ...user,
      bankAccounts: [...user.bankAccounts, newBankAccount],
    };
    setUser(updatedUser);
    setSelectedAccounts([...selectedAccounts, newBankAccount.accountNumber]);
    setToggleRender(!toggleRender);
  };
  const handleCreateBankAccount = async (formData: BankData) => {
    setIsLoading(true);

    try {
      await createBankAccount(formData);
      const updatedUser = user;

      if (
        updatedUser &&
        Array.isArray(updatedUser.bankAccounts) &&
        updatedUser.bankAccounts.length > 0
      ) {
        const lastBankAccount =
          updatedUser.bankAccounts[updatedUser.bankAccounts.length - 1];

        const updatedBankAccount = {
          ...lastBankAccount,
          isActive:
            lastBankAccount.isActive !== undefined
              ? lastBankAccount.isActive
              : true, // Set to true if undefined
        };

        handleAddBankAccount(updatedBankAccount);

        setUser((prevUser: { bankAccounts: BankAccountData[] }) => ({
          ...prevUser,
          bankAccounts: [...prevUser.bankAccounts, updatedBankAccount],
        }));

        setCompanyBankAccounts(updatedUser.bankAccounts);

        setToggleRender(!toggleRender);
        setShowCreateBank(false);

        toast.success("Bank account created successfully");
      } else {
        console.error("No bank accounts found in the updated user data");
      }
    } catch (error) {
      console.error("Error creating bank account:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Trigger a re-render whenever toggleRender changes
  }, [toggleRender]);

  const displayedBankData =
    selectedAccounts.length > 0
      ? bankAccounts.filter((bank: { accountNumber: string }) =>
          selectedAccounts.includes(bank.accountNumber),
        )
      : activeBankData;

  return (
    <>
      {/* Desktop */}
      <div className="hidden lg:block md:block  mt-[5em]">
        <div className="flex justify-between  bg-[#F5F6F7] py-2 px-2 lg:w-[308px]">
          <p
            className="lg:text-[16px] biglg:text-[16px]"
            onClick={toggleBankModal}
          >
            {" "}
            {t("addBank")}
          </p>
          <div
            className="px-[1em] cursor-pointer mt-1 "
            onClick={toggleBankModal}
          >
            <Pen />
          </div>
        </div>

        <div className=" cursor-pointer mt-2 ">
          <div>
            {displayedBankData.map((bank: BankData, index: number) => (
              <div
                className="flex flex-col whitespace-nowrap text-[#212121] bg-[#F5F6F7] lg:w-[308px] md:w-[200px] mb-2 pb-4 pt-4 px-4 text-[12px] font-normal"
                key={index}
                style={{
                  borderBottom:
                    index < displayedBankData.length - 1
                      ? "2px solid #F4F7FF"
                      : "none",
                }}
              >
                <p className="text-[14px] font-medium text-[#212121] mb-2">
                  {" "}
                  Payment Details
                </p>
                <span>{bank.bankName}</span>
                <span>{bank.accountNumber}</span>
                <span>{bank.accountName}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Mobile */}
      <div className="lg:hidden md:hidden">
        <div className="flex justify-start mt-[1em]">
          <p
            className="lg:text-[16px] biglg:text-[16px] text-[9px]"
            onClick={toggleBankModal}
          >
            {" "}
            {t("addBank")}
          </p>
        </div>
        <div className="flex gap-2 w-full">
          <div className="py-4 bg-[#F4F7FF] pl-4 320:pr-[7em] 360:pr-[10em] 375:pr-[10.5em] 390:pr-[11em] 412:pr-[12.3em] 414:pr-[12.5em] 430:pr-[14em] shadow-md cursor-pointer">
            <div>
              {displayedBankData.map((bank: BankData, index: number) => (
                <div
                  className="flex flex-col ml-[em] whitespace-nowrap lg:text-[14px] text-[9px] bg-[#F4F7FF] w-full "
                  key={index}
                >
                  <span>{bank.bankName}</span>
                  <span>{bank.accountNumber}</span>
                  <span>{bank.accountName}</span>
                  <hr className="border border-[#A4A4A4] w-full " />
                </div>
              ))}
            </div>
          </div>
          <div
            className="px-2 items-center text-center cursor-pointer mt-1 shadow-sm py-[1.5em] bg-[#F4F7FF]"
            onClick={toggleBankModal}
          >
            <Pen />
          </div>
        </div>
      </div>

      {showBankModal && !showCreateBank && (
        <CreateModal>
          <BankAccountModal
            activeBankData={activeBankData}
            selectedAccounts={selectedAccounts}
            setSelectedAccounts={setSelectedAccounts}
            toggleModal={toggleBankModal}
            onShowCreateBank={() => setShowCreateBank(true)}
          />
        </CreateModal>
      )}

      {showCreateBank && (
        <CreateModal>
          <CreateBank
            onCreate={handleCreateBankAccount}
            showSideModal={showCreateBank}
            setShowSideModal={setShowCreateBank}
          />
        </CreateModal>
      )}
    </>
  );
};

export default BankDetails;
