import React from "react";

export const ClipIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 10L15 8C16.3807 6.61929 16.3807 4.38071 15 3V3C13.6193 1.61929 11.3807 1.61929 10 3L8 5M5 8L3 10C1.61929 11.3807 1.61929 13.6193 3 15V15C4.38071 16.3807 6.61929 16.3807 8 15L10 13"
        stroke="#044E97"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const ClipSmallIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 10L15 8C16.3807 6.61929 16.3807 4.38071 15 3V3C13.6193 1.61929 11.3807 1.61929 10 3L8 5M5 8L3 10C1.61929 11.3807 1.61929 13.6193 3 15V15C4.38071 16.3807 6.61929 16.3807 8 15L10 13"
        stroke="#044E97"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};
