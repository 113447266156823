import React, { useState } from "react";

interface TruncatedTextProps {
  text: string;
  maxLength: number;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({ text, maxLength }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="relative">
      <p
        className="truncated"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {text.length > maxLength ? `${text.substring(0, maxLength)}...` : text}
      </p>
      {isHovered && <div className="full-text">{text}</div>}
    </div>
  );
};

export default TruncatedText;
