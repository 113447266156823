import React, { useEffect, useRef } from "react";
import cx from "classnames";
import { CloseIcon } from "../../../assets/icons/closeIcon";
import { EmptyIcon } from "../../../assets/icons/NotificationsEmptyIcon";

interface NotificationEmptyProps {
  show: boolean;
  onClose?: () => void;
}

const NotificationEmpty: React.FC<NotificationEmptyProps> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsOpen(props.show);
  }, [props.show]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
    props.onClose && props.onClose();
  };

  return isOpen ? (
    <>
      {/* Modal Background */}
      <div
        aria-hidden="false"
        className="fixed top-0 right-0 bottom-0 z-50 h-full overflow-y-auto overflow-x-hidden items-start flex justify-end"
      >
        <div
          ref={modalRef}
          className={cx({
            "relative z-50 h-full w-full": true,
            "p-3 bg-white shadow": true,
            "animate-slide-in-right": isOpen,
          })}
        >
          {/* MODAL HEADER */}
          <div className="flex items-start justify-between p-3  ml-[1em] xl:ml-[-3em]">
            <h3 className="text-[25px] font-bold">{"Notifications"}</h3>
            <button
              aria-label="Close"
              className="ml-auto inline-flex items-center rounded-lg bg-transparent text-red-400"
              type="button"
              onClick={handleClose}
            >
              <CloseIcon />
            </button>
          </div>

          {/* MODAL BODY */}
          <div className="p-24 pt-10 flex flex-col items-center justify-center gap-10">
            <EmptyIcon />
            <div className="flex flex-col items-center justify-center ">
              <h3 className="text-[18px] font-bold">{"You're up to date"}</h3>
              <p>You do not have any new notifications.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export { NotificationEmpty };
