import React, { useEffect, useState } from "react";
import { formatMoney } from "../../utils";
import { EmptyPieChart } from "./PieChart/Empty";
import { PieChart, Pie, Tooltip } from "recharts";

interface PieData {
  value: number;
  fill: string;
}

interface PieChartProps {
  title?: string;
  isLoaded?: boolean;
  data: Record<string, PieData> | any;
  toolTipText?: string;
}

const ExpenseCategories: React.FC<PieChartProps> = ({
  title,
  data = {},
  isLoaded,
}) => {
  const [chartData, setChartData] = useState<any[]>([]);

  useEffect(() => {
    const transformedData = Object.keys(data)
      .filter((key) => data[key].fill && data[key].value !== null)
      .slice(0, 5)
      .map((key) => ({
        name: key,
        fill: data[key].fill,
        value: data[key].value,
        amount: data[key].amount,
      }));
    setChartData(transformedData);
  }, [data]);

  return (
    <>
      {!isLoaded ? (
        <p>Loading...</p>
      ) : (
        <div>
          <div className="py-4 px-5">
            <p
              className={`font-semibold text-[16px] text-[##000000] leading-6`}
            >
              {title}
            </p>
          </div>
          <hr className="border border-[#EAECF0] mt-1 w-full" />

          <div className="flex flex-col items-center justify-center px-6 pb-5 w-full">
            {chartData.length > 0 ? (
              <PieChart width={220} height={200}>
                <Pie
                  data={chartData}
                  dataKey="amount"
                  nameKey="name"
                  innerRadius={60}
                  outerRadius={85}
                  paddingAngle={0}
                  style={{ outline: "none" }}
                />
                <Tooltip
                  formatter={(value: number, name: string, entry: any) =>
                    `${formatMoney(entry.payload.amount)}`
                  }
                  contentStyle={{
                    fontSize: "12px",
                    padding: "5px",
                    backgroundColor: "#FFFFFF",
                    border: "none",
                    borderRadius: "8px",
                  }}
                />
              </PieChart>
            ) : (
              <EmptyPieChart
                link={`/expenses/create`}
                buttonText="Create an Expense"
                paragraph={`Create your expense to start recording transactions`}
              />
            )}

            <div className="flex flex-wrap gap-3 justify-center">
              {chartData &&
                chartData.map((item, i) => (
                  <div
                    className="flex flex-row gap-1 items-center justify-between"
                    style={{ whiteSpace: "nowrap" }}
                    key={i}
                  >
                    <div
                      className="rounded-lg w-[8px] h-[8px]"
                      style={{ backgroundColor: item.fill }}
                    ></div>
                    <p className="text-[12px] leading-4 font-medium text-[#344054]">
                      <span>{item.name}</span> <span> - {item.value}%</span>
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { ExpenseCategories };
