import React, { useState, Dispatch, SetStateAction } from "react";
import { DropdownComponent, InputComponent } from "../../core/Forms";
import { useReportStore } from "../../../state/useReportStore";
import { CloseIcon } from "../../../assets/icons";
import { Button, SearchInput } from "../../../components/common";
import { useCustomerSearch } from "../../../hooks";
import { ICustomer, IInventory } from "../../../@types";

interface CustomerFilterProps {
  handleOnChange: (e: any) => void;
  formData: any;
  setFormData: any;
}

const CustomerReportFilterForm = ({
  handleOnChange,
  formData,
  setFormData,
}: CustomerFilterProps) => {
  const [text, setText] = React.useState("");
  const generateReport = useReportStore((state) => state.generateReport);
  const isLoading = useReportStore((state) => state.isLoading);
  const buttonText = "Generate Report as Pdf";
  const [customerState, setCustomerState] = useState<ICustomer | null>(null);
  const [customerValue, setCustomerValue] = useState("Select a Vendor");

  return <div></div>;
};

export default CustomerReportFilterForm;
