const UntickedIcon = ({ stroke = "#A4A4A4" }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99998 1.83594C4.32665 1.83594 1.33331 4.82927 1.33331 8.5026C1.33331 12.1759 4.32665 15.1693 7.99998 15.1693C11.6733 15.1693 14.6666 12.1759 14.6666 8.5026C14.6666 4.82927 11.6733 1.83594 7.99998 1.83594ZM10.24 10.0359C10.4333 10.2293 10.4333 10.5493 10.24 10.7426C10.14 10.8426 10.0133 10.8893 9.88665 10.8893C9.75998 10.8893 9.63331 10.8426 9.53331 10.7426L7.99998 9.20927L6.46665 10.7426C6.36665 10.8426 6.23998 10.8893 6.11331 10.8893C5.98665 10.8893 5.85998 10.8426 5.75998 10.7426C5.56665 10.5493 5.56665 10.2293 5.75998 10.0359L7.29331 8.5026L5.75998 6.96927C5.56665 6.77594 5.56665 6.45594 5.75998 6.2626C5.95331 6.06927 6.27331 6.06927 6.46665 6.2626L7.99998 7.79594L9.53331 6.2626C9.72665 6.06927 10.0466 6.06927 10.24 6.2626C10.4333 6.45594 10.4333 6.77594 10.24 6.96927L8.70665 8.5026L10.24 10.0359Z"
        fill="#D82020"
      />
    </svg>
  );
};

export { UntickedIcon };
