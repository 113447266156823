import { useUserStore } from "../state";

type UserRole = ("Accountant" | "Admin" | "Operator")[];

const useAccessControl = () => {
  const { roles } = useUserStore();

  const hasAccess = (requiredRoles: UserRole): boolean => {
    if (!Array.isArray(roles)) {
      return false; // or handle appropriately if roles is not an array
    }

    return requiredRoles.some((role) => roles.includes(role));
  };

  return { hasAccess };
};
export default useAccessControl;
