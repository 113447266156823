import cx from "classnames";

export const bgClassName = (bgColorClass: string) => {
  return cx(
    "w-[30px] h-[30px] lg:w-9 lg:h-9 flex rounded-full justify-center items-center",
    {
      ["bg-[#37C89A26] bg-opacity-15"]: bgColorClass === "invoice",
      ["bg-[#D8202026] bg-opacity-15"]: bgColorClass === "expense",
    },
  );
};
