import React, { useEffect, useState } from "react";
import { formatMoney } from "../../../utils";
import BudgetModal from "../../common/BudgetModal";
import { useTranslation } from "react-i18next";
import NewTooltips from "../../../assets/icons/NewTooltips";
import { useBudgetStore } from "../../../state/useBudgetStore";

export interface BudgetHistoryWidgetProps {
  toolTipText?: string;
}

const BudgetHistory: React.FC<BudgetHistoryWidgetProps> = ({ toolTipText }) => {
  const { t } = useTranslation("dashboard");
  const {
    budgets,
    fetchBudgets,
    isBudgetLoading: isLoading,
    error,
  } = useBudgetStore();
  const [actualExpensePercentage, setActualExpensePercentage] = useState<
    null | number
  >(null);
  const [monthlyBudgetPercentage, setMonthlyBudgetPercentage] = useState<
    null | number
  >(null);
  const [isHovered, setIsHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const monthlyBudget = budgets[0]?.allocatedBudget ?? null;
  const actualExpenses = budgets[0]?.actualExpenses ?? null;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleOnClick = () => {
    setIsHovered(!isHovered);
  };

  const formatValue = (value: number) => {
    return value.toLocaleString();
  };

  useEffect(() => {
    fetchBudgets();
  }, []);

  useEffect(() => {
    if (actualExpenses !== null || monthlyBudget !== null) {
      let percentage;
      if (actualExpenses && monthlyBudget) {
        percentage = (actualExpenses / monthlyBudget) * 100;
      } else {
        percentage = 0;
      }
      setActualExpensePercentage(percentage > 100 ? 100 : percentage);
      setMonthlyBudgetPercentage(100 - percentage);
    }
  }, [actualExpenses, monthlyBudget]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (budgets.length == 0) {
    return (
      <>
        <div className="p-2 border border-[#EAECF0] rounded-lg w-full">
          <div className="flex justify-between items-center">
            <div className="font-normal flex items-center gap-2">
              <h2
                className="text-[#344054] text-[14px] font-medium leading-5"
                style={{ padding: "0" }}
              >
                Budget
              </h2>
              {toolTipText && (
                <div
                  className="relative cursor-pointer"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <div data-testid="budget-tooltip">
                    <NewTooltips />
                  </div>
                  {isHovered && (
                    <div className="absolute w-[204px] left-[-5.7em]">
                      <div className="flex items-center justify-center">
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 0L17.6603 15H0.339746L9 0Z"
                            fill="#FFF4C6"
                          />
                        </svg>
                      </div>
                      <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                        {toolTipText}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <button
              type="button"
              onClick={() => setShowModal(true)}
              className="text-[#044E97] font-semibold text-[12px]"
            >
              Set a budget
            </button>
          </div>

          <div className="bg-[#FFFFFF] rounded-md mt-[6px] text-[#98A2B3]">
            <div className="text-end text-[12px]">
              <h1 className="font-medium leading-4">{t("monthlyBudget")}</h1>
              <div className="font-semibold text-[#344054] leading-4">
                {"₦0"}
              </div>
            </div>

            <div className="mt-[2px] flex items-center justify-between w-full">
              <div className=" flex-grow-0 bg-[#D0D5DD] w-[100%] h-[4px] rounded-[4px]" />
            </div>

            <div className="text-[12px] mt-1">
              <h1 className="leading-4">Actual Expense</h1>
              <div className="font-semibold text-[#344054] leading-4">{`₦${formatValue(0)}`}</div>
            </div>
          </div>
        </div>

        {showModal && <BudgetModal setShowModal={setShowModal} />}
      </>
    );
  }
  return (
    <>
      <div className="p-2 border border-[#EAECF0] rounded-lg w-full">
        <div className="flex justify-between items-center">
          <div className="font-normal flex items-center gap-2">
            <h2 className="lg:text-[#000000] text-[14px]">Budget</h2>
            {toolTipText && (
              <div
                className="relative cursor-pointer"
                onClick={handleOnClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <NewTooltips />
                {isHovered && (
                  <div className="absolute w-[204px] left-[-5.7em]">
                    <div className="flex items-center justify-center">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L17.6603 15H0.339746L9 0Z"
                          fill="#FFF4C6"
                        />
                      </svg>
                    </div>
                    <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                      {toolTipText}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {monthlyBudget === null && (
            <button
              type="button"
              onClick={() => setShowModal(true)}
              className="text-[#044E97] font-semibold text-[12px]"
            >
              Set a budget
            </button>
          )}
        </div>

        <div className="rounded-md mt-[6px]">
          <div className="text-[#044E97] text-end text-[12px]">
            <h1 className="text-[#828282] font-medium leading-4">
              {t("monthlyBudget")}
            </h1>
            <div className="font-semibold leading-4">
              {monthlyBudget ? `${formatMoney(monthlyBudget)}` : "₦0"}
            </div>
          </div>

          <div className="mt-[2px] flex items-center justify-between w-full">
            {monthlyBudget ? (
              <>
                <div
                  className=" flex-grow-0 bg-[#D82020] w-[100%] h-[4px] border-r border-[#D82020]"
                  style={{ width: `${actualExpensePercentage}%` }}
                />
                <div
                  className=" flex-grow-0 bg-blue-500 w-[100%] h-[4px]"
                  style={{ width: `${monthlyBudgetPercentage}%` }}
                />
              </>
            ) : (
              <div
                className=" flex-grow-0 bg-[#D82020] w-[100%] h-[7px] rounded-md"
                style={{ width: "100%" }}
              />
            )}
          </div>

          <div className="text-[12px]">
            <h1 className="text-[#828282] font-medium leading-4">
              Actual Expense
            </h1>
            <div
              className={`font-semibold leading-4 text-[#D82020] ${!monthlyBudget ? "text-[#D82020]" : ""}`}
            >
              {`₦${formatValue(actualExpenses || 0)}`}
            </div>
          </div>
        </div>
      </div>
      {showModal && <BudgetModal setShowModal={setShowModal} />}
    </>
  );
};

export default BudgetHistory;
