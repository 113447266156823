import React, { Children } from "react";
import { Navigate } from "react-router-dom";
import useAccessControl from "./useAccessControl";

interface ProtectedRouteProps {
  children: JSX.Element;
  requiredRoles: ("Accountant" | "Admin" | "Operator")[];
}

const ProtectedRoute = ({ children, requiredRoles }: ProtectedRouteProps) => {
  const { hasAccess } = useAccessControl();

  const rolesArray = Array.isArray(requiredRoles)
    ? requiredRoles
    : [requiredRoles];
  return hasAccess(rolesArray) ? children : <Navigate to="/unauthorized" />;
};
export { ProtectedRoute };
