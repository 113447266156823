// The type for a single customer data
import { useSearch } from "../useSearch";
import { invoiceService } from "../../services";
import { IInvoice } from "../../@types";

export const useInvoiceSearch = () => {
  return useSearch({
    service: invoiceService,
    endpoint: "invoices/search",
    queryParameter: "q",
    responseMapper: (data: any[]): any =>
      data.map((cu: any) => ({
        ...cu,
        id: cu.id,
        amount: `${cu.amount}`,
      })) as unknown as IInvoice,
  });
};
