import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import QuoteParent from "../../../components/Sales/quotes/quoteParent";
import { ICreateSalesData, ICustomer } from "../../../@types";
import PageLoader from "../../../components/common/PageLoader";

const EditQuotePage = () => {
  const location = useLocation();
  const { state } = location;
  const quote: ICreateSalesData = state?.quote || {
    customerId: "",
    description: "",
    discount: 0,
    inventories: [],
    expiryDate: "",
    invoiceNumber: "",
    issueDate: new Date().toISOString().substring(0, 10),
    status: "active",
    tax: 0,
    shouldSendEmail: true,
    exchangeRate: 0,
    currency: "",
  };

  const [customerName, setCustomerName] = React.useState<string>("");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      if (quote.customer) {
        const { firstName, lastName } = quote.customer as ICustomer;
        setCustomerName(`${firstName} ${lastName}`);
      }

      setIsLoading(false);
    }, 1000);
  }, [quote]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <QuoteParent
      quoteData={quote}
      setQuoteData={() => {}}
      headingText="Edit Quote"
      customerId={quote.customerId}
    />
  );
};

export { EditQuotePage };
