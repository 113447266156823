import React from "react";

const BannerIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 12C0 18.6168 5.3832 24 12 24C18.6168 24 24 18.6168 24 12C24 5.3832 18.6168 0 12 0C5.3832 0 0 5.3832 0 12ZM18.2485 9.84853C18.7172 9.3799 18.7172 8.6201 18.2485 8.15147C17.7799 7.68284 17.0201 7.68284 16.5515 8.15147L10.8 13.9029L8.04853 11.1515C7.5799 10.6828 6.8201 10.6828 6.35147 11.1515C5.88284 11.6201 5.88284 12.3799 6.35147 12.8485L9.95147 16.4485C10.4201 16.9172 11.1799 16.9172 11.6485 16.4485L18.2485 9.84853Z"
          fill="#007F5F"
        />
      </svg>
    </div>
  );
};

export default BannerIcon;
