import React from "react";

const ArrowDirection = () => {
  return (
    <svg
      className="mt-[4px] "
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.5 1L1.5 8L8.5 15" stroke="#33363F" strokeWidth="2" />
    </svg>
  );
};

export { ArrowDirection };
