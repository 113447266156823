import axios, { AxiosInstance } from "axios";
import config from "../config";
import { getAuthToken, setupAuthInterceptor } from "../utils";
import { History } from "history";

export const createExpenseService = (history?: History): AxiosInstance => {
  const expenseService = axios.create({
    baseURL: config.expenseServiceURL,
  });

  setupAuthInterceptor(expenseService);

  return expenseService;
};

const expenseService = createExpenseService();
export { expenseService };
