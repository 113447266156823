import React, {
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Table } from "../../../common";
import { Link } from "react-router-dom";
import {
  getInvoiceStatus,
  getInvoiceReoccur,
  formatMoney,
} from "../../../../utils";
import { IInvoice, SalesStatus } from "../../../../@types";
import dayjs from "dayjs";
import {
  AddSquareIcon,
  DownloadIcon,
  FilterIcon,
} from "../../../../assets/icons";
import { IPaginationMeta } from "../../../../@types";
import InvoiceFilter from "../InvoiceFilter";
import { DropDown } from "../../../common";
import HeadingIcon from "../../../../assets/icons/HeadingIcon";
import { BUSINESS_TYPE_RETAIL, Statuses } from "../../../../constants";
import { useNavigate } from "react-router-dom";
import { Filter } from "../../../../@types/invoice";
import { getStatusClass, getStatusColor } from "../Status/status";
import DropdownButton from "../../../common/Table/MobileTableComponent/DropdownOptions";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { MobileTable } from "../../../common/mobileTable";
import { getActiveSubscriptionWithHandling } from "../../../../backend-services/subscription";
import { SubscriptionModal } from "../../../common/Subscription/SubscriptionModal";
import { MobileSubscriptionModal } from "../../../common/Subscription/MobileSubscriptionModal";
import { useSubscriptionStore, useUserStore } from "../../../../state";
import { checkSubscription } from "../../../../utils/subscription";
import { servicesType } from "../../../../constants/Subscription";
import ActionModal from "../../../common/ActionModal";
import UploadIcon from "../../../../assets/icons/svgicons/UploadIcon.svg";
import { useTranslation } from "react-i18next";
import useRetailBusinessStatus from "../../../../hooks/invoice/useRetailBusinessStatus";
import generateActionButtons from "../invoiceCreationModal";

interface InvoicesTableProps {
  invoices: IInvoice[];
  isLoading: boolean;
  fetchMoreData: () => void;
  meta: IPaginationMeta;
  onSearchData?: (text: string) => void;
  handleSort?: (text: string) => void;
  handleSearch?: (text: string) => void;
  showHead?: boolean;
  handleFilter?: (filter: Filter) => void;
  newLink?: string;
  title?: string;
  error?: boolean;
  isSearching?: boolean;
  searchedDone?: boolean;
  setSearchedDone?: (searchedDone: boolean) => void;
  headings?: {
    [key: string]: string;
  };
  data?: any[];
  searchResult: any[];
  selectedFilter?: Filter | undefined;
}

const CustomerTable = ({
  selectedFilter,
  invoices,
  isLoading,
  fetchMoreData,
  meta,
  handleSort,
  handleSearch,
  searchResult,
  onSearchData,
  showHead = true,
  handleFilter,
  isSearching,
  searchedDone,
  setSearchedDone,
}: InvoicesTableProps) => {
  const { t } = useTranslation("invoices");
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [showFilter, setShowFilter] = React.useState(false);
  const [isReoccurring, setIsReoccurring] = React.useState<boolean | null>(
    null,
  );
  const [showSubscriptionModal, setShowSubscriptionModal] =
    React.useState(false);
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
    React.useState<string>("");
  const [status, setStatus] = React.useState<SalesStatus | "">("");
  const [dropdown, setDropdown] = React.useState(false);
  const { subscriptionData } = useSubscriptionStore();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isRetailBusiness = useRetailBusinessStatus();
  const handleFilterIconClick = () => {
    setShowFilter(true);
  };

  const handleCloseModal = () => {
    setShowFilter(false);
  };

  const getDaySuffix = (day: number) => {
    if (day >= 11 && day <= 13) {
      return day + "th";
    }

    switch (day % 10) {
      case 1:
        return day + "st";
      case 2:
        return day + "nd";
      case 3:
        return day + "rd";
      default:
        return day + "th";
    }
  };
  const navigate = useNavigate();
  const handleSearchSelect = (item: any) => {
    item.status === Statuses.DRAFT.toLowerCase()
      ? navigate(`/invoices/${item.id}`)
      : navigate(`/invoices/${item.id}/view`);
  };
  const observer = useRef<IntersectionObserver | null>(null);
  const lastInvoiceRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && meta.hasMoreData) {
          fetchMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, meta],
  );

  const fetchData = () => {
    const subscriptionCheck = checkSubscription(servicesType.Invoices);
    if (subscriptionCheck.status) {
      setIsModalOpen(true);
      return;
    }
    setShowSubscriptionModal(true);
    setSubscriptionErrorMessage(subscriptionCheck.message || "");
  };

  const memoizedSelectedFilter = useMemo(
    () => selectedFilter,
    [
      selectedFilter && "status" in selectedFilter
        ? selectedFilter.status
        : null,
      selectedFilter && "isReoccurring" in selectedFilter
        ? selectedFilter.isReoccurring
        : null,
    ],
  );

  const renderInvoicesData = (invoices: IInvoice[]) => {
    return invoices
      .filter((invoice: IInvoice) => {
        if (isReoccurring !== null && invoice.isReoccurring !== isReoccurring) {
          return false;
        }
        return true;
      })
      .map((invoice: IInvoice, index) => {
        const statusCapitalized =
          invoice.status.charAt(0).toUpperCase() + invoice.status?.slice(1);
        const isDraft = statusCapitalized === Statuses.DRAFT;

        const isFreeform =
          invoice.invoiceType === "freeform" || !invoice.invoiceType;

        const linkTo = isDraft
          ? isFreeform
            ? `/invoices/freeform/${invoice.id}`
            : `/invoices/${invoice.id}`
          : `/invoices/${invoice.id}/view`;

        const linkText = isDraft
          ? invoice.invoiceNumber
          : invoice.invoiceNumber;
        const statusClass = getStatusClass(statusCapitalized);
        const statusColor = getStatusColor(statusCapitalized);
        const invoiceId = invoice.id;
        return {
          invoiceNumbers: (
            <div ref={index === invoices.length - 1 ? lastInvoiceRef : null}>
              <Link className="text-violet-800" to={linkTo}>
                {linkText}
              </Link>
            </div>
          ),
          customer: (
            <Link
              className="xl:text-violet-800"
              to={`/customers/${invoice?.customer?.id}/view`}
            >{`${invoice?.customer?.firstName} ${invoice?.customer?.lastName}`}</Link>
          ),
          issueDate: `${dayjs(invoice.issueDate).format("MMM")} ${getDaySuffix(
            dayjs(invoice.issueDate).date(),
          )} ${dayjs(invoice.issueDate).format("YYYY")}`,
          invoiceNumber: invoice.invoiceNumber,
          status: (
            <div
              className={`${statusClass}  `}
              style={{ backgroundColor: statusColor }}
            >
              <Link className="block" to={linkTo}>
                {getInvoiceStatus(statusCapitalized)}
              </Link>
            </div>
          ),
          isReoccurring: getInvoiceReoccur(invoice.isReoccurring),
          inventories: formatMoney(invoice.balanceDue, invoice.currency),
          invoiceId: invoiceId,
        };
      });
  };

  const renderInvoicesTableHead = useMemo(
    () => ({
      invoiceNumbers: { title: `${t("invoiceId")}` },
      customer: { title: "Customer", icon: <HeadingIcon /> },
      issueDate: { title: t("issueDate"), icon: <HeadingIcon /> },
      isReoccurring: { title: t("typeLabel") },
      status: { title: t("statusLabel") },
      inventories: { title: t("balanceDue"), icon: <HeadingIcon /> },
    }),
    [],
  );

  const columns = [
    { key: "invoiceNumbers", label: "Invoice" },
    { key: "customer", label: "Customer" },
    { key: "isReoccurring", label: "Type" },
    { key: "issueDate", label: "Issue Date" },
    { key: "status", label: "Status" },
    { key: "inventories", label: "Balance Due" },
  ];

  const handleStatusChange = (newStatus: SalesStatus) => {
    setStatus(newStatus.toLowerCase() as SalesStatus);
  };

  const handleIsReoccurringChange = (newIsReoccurring: boolean) => {
    setIsReoccurring(newIsReoccurring);
  };

  const onInvoiceFilterSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (handleFilter) {
      handleFilter({ status, isReoccurring });
    }
    setShowFilter(false);
  };

  const fetchPageData = (direction: "up" | "down") => {
    if (direction === "down" && meta.hasMoreData) {
      // fetchMoreData();
    }
  };

  const onSearch = (text: string) => {
    if (onSearchData && handleSearch) {
      handleSearch(text);
    }
  };

  const handleNewInvoiceClick = () => {
    const subscriptionCheck = checkSubscription(servicesType.Invoices);
    if (subscriptionCheck.status) {
      navigate("/invoices/create");
      return;
    }
    setShowSubscriptionModal(true);
    setSubscriptionErrorMessage(subscriptionCheck.message || "");
  };

  const handleInventoryInvoiceClick = async () => {
    const subscriptionCheck = checkSubscription(servicesType.Invoices);
    if (subscriptionCheck.status) {
      navigate("/invoices/create");
      return;
    } else {
      setShowSubscriptionModal(true);
    }
  };

  const handleFreeformInvoiceClick = () => {
    const subscriptionCheck = checkSubscription(servicesType.Invoices);
    if (subscriptionCheck.status) {
      navigate("/invoices/createFreeform");
    } else {
      setShowSubscriptionModal(true);
      setSubscriptionErrorMessage(subscriptionCheck.message || "");
    }
  };
  const actionButtons = generateActionButtons(
    isRetailBusiness,
    handleInventoryInvoiceClick,
    handleFreeformInvoiceClick,
  );

  const isMobile = window.innerWidth < 768;
  return (
    <>
      {isDesktop ? (
        <div className="hidden md:block whitespace-nowrap w-full">
          <Table
            columns={columns}
            searchPlaceHolder="Search invoices by customer name"
            selectedFilter={selectedFilter}
            handleFilter={handleFilter}
            searchDisplayKey="customer.firstName"
            searchResult={searchResult}
            head={renderInvoicesTableHead}
            data={renderInvoicesData(invoices)}
            isLoading={isLoading}
            isSearching={isSearching}
            searchedDone={searchedDone}
            setSearchedDone={setSearchedDone}
            showHead={showHead || undefined}
            handleSearchSelect={handleSearchSelect}
            buttons={[
              {
                text: "",
                icon: (
                  <DropDown
                    onChange={handleFilterIconClick}
                    show={showFilter}
                    component={
                      <div className="flex ">
                        {" "}
                        <span>Filter</span> <FilterIcon color="#044E97" />{" "}
                      </div>
                    }
                  >
                    <InvoiceFilter
                      isReoccurring={
                        memoizedSelectedFilter &&
                        "isReoccurring" in memoizedSelectedFilter
                          ? memoizedSelectedFilter.isReoccurring
                          : undefined
                      }
                      setStatus={handleStatusChange}
                      setIsReoccurring={handleIsReoccurringChange}
                      status={
                        memoizedSelectedFilter &&
                        "status" in memoizedSelectedFilter
                          ? memoizedSelectedFilter.status
                          : undefined
                      }
                      onSubmit={onInvoiceFilterSubmit}
                      onClose={handleCloseModal}
                      showFilter
                      //@ts-ignore
                      handleFilter={handleFilter}
                    />
                  </DropDown>
                ),
              },
            ]}
            showPagination={true}
            handleSearch={onSearch}
            handleSort={handleSort}
            fetchMoreData={fetchPageData}
            meta={meta}
          />
        </div>
      ) : (
        <div className="md:hidden mt-[12px] ">
          <div className="md:hidden">
            <MobileTable
              searchPlaceHolder="Search"
              selectedFilter={selectedFilter}
              handleFilter={handleFilter}
              searchDisplayKey="customer.firstName"
              searchResult={searchResult}
              head={renderInvoicesTableHead}
              data={renderInvoicesData(invoices)}
              isLoading={isLoading}
              isSearching={isSearching}
              showHead={showHead || undefined}
              handleSearchSelect={handleSearchSelect}
              hideFloatingScrollButton={true}
              buttons={[
                {
                  text: "",
                  icon: (
                    <div className="ml-[-25em]">
                      <DropDown
                        onChange={handleFilterIconClick}
                        show={showFilter}
                        component={
                          <div className="flex items-center h-[38px] ml-[26em] ">
                            <span className="text-[14px]">Filter</span>
                            <FilterIcon color="#044E97" />
                          </div>
                        }
                      >
                        <div className="">
                          <InvoiceFilter
                            isReoccurring={
                              memoizedSelectedFilter &&
                              "isReoccurring" in memoizedSelectedFilter
                                ? memoizedSelectedFilter.isReoccurring
                                : undefined
                            }
                            setStatus={handleStatusChange}
                            setIsReoccurring={handleIsReoccurringChange}
                            status={
                              memoizedSelectedFilter &&
                              "status" in memoizedSelectedFilter
                                ? memoizedSelectedFilter.status
                                : undefined
                            }
                            onSubmit={onInvoiceFilterSubmit}
                            onClose={handleCloseModal}
                            showFilter
                            //@ts-ignore
                            handleFilter={handleFilter}
                          />
                        </div>
                      </DropDown>
                    </div>
                  ),
                },
              ]}
              showPagination={true}
              handleSearch={onSearch}
              handleSort={handleSort}
              fetchMoreData={fetchPageData}
              meta={meta}
              invoiceCard={true}
            />
          </div>
        </div>
      )}
      <div ref={containerRef}>
        <ActionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title="Select an invoice creation option"
          icon={UploadIcon}
          actionButtons={actionButtons}
        />
      </div>
      <div className="mt-[]">
        {isMobile && showSubscriptionModal && (
          <SubscriptionModal>
            <MobileSubscriptionModal
              onClose={() => setShowSubscriptionModal(false)}
              titleText={`Need to access more features?`}
              subtitleText={subscriptionErrorMessage}
            />
          </SubscriptionModal>
        )}
      </div>
    </>
  );
};

export default CustomerTable;
