import React from "react";

const BrandIdentity = ({ color = "" }) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0026 0.833008C15.4628 0.833008 15.8359 1.2061 15.8359 1.66634V4.16634H18.3359C18.7962 4.16634 19.1693 4.53944 19.1693 4.99967C19.1693 5.45991 18.7962 5.83301 18.3359 5.83301H15.8359V8.33301C15.8359 8.79325 15.4628 9.16634 15.0026 9.16634C14.5424 9.16634 14.1693 8.79325 14.1693 8.33301V5.83301H11.6693C11.209 5.83301 10.8359 5.45991 10.8359 4.99967C10.8359 4.53944 11.209 4.16634 11.6693 4.16634H14.1693V1.66634C14.1693 1.2061 14.5424 0.833008 15.0026 0.833008Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5239 1.66602L9.58594 1.66602C10.0462 1.66602 10.4193 2.03911 10.4193 2.49935C10.4193 2.95959 10.0462 3.33268 9.58594 3.33268C7.6964 3.33268 6.36309 3.33445 5.35361 3.47017C4.36811 3.60267 3.81694 3.84893 3.4179 4.24797C3.01885 4.64702 2.77259 5.19819 2.6401 6.18369C2.50437 7.19316 2.50261 8.52648 2.50261 10.416C2.50261 12.3056 2.50437 13.6389 2.6401 14.6483C2.77259 15.6338 3.01885 16.185 3.4179 16.5841C3.81694 16.9831 4.36811 17.2294 5.35361 17.3619C6.36309 17.4976 7.6964 17.4994 9.58594 17.4994C11.4755 17.4994 12.8088 17.4976 13.8183 17.3619C14.8038 17.2294 15.3549 16.9831 15.754 16.5841C16.153 16.185 16.3993 15.6338 16.5318 14.6483C16.6675 13.6389 16.6693 12.3056 16.6693 10.416V9.99935C16.6693 9.53911 17.0424 9.16602 17.5026 9.16602C17.9628 9.16602 18.3359 9.53911 18.3359 9.99935V10.478C18.336 12.2914 18.336 13.737 18.1836 14.8704C18.0262 16.0407 17.6928 17.0022 16.9325 17.7626C16.1722 18.5229 15.2107 18.8563 14.0403 19.0137C12.9069 19.1661 11.4613 19.166 9.64796 19.166H9.52392C7.71059 19.166 6.26498 19.1661 5.13153 19.0137C3.96121 18.8563 2.99971 18.5229 2.23939 17.7626C1.47906 17.0022 1.14564 16.0407 0.988291 14.8704C0.835903 13.737 0.835918 12.2914 0.835938 10.4781V10.354C0.835918 8.54066 0.835903 7.09506 0.988291 5.96161C1.14564 4.79129 1.47906 3.82979 2.23939 3.06946C2.99971 2.30914 3.96121 1.97571 5.13153 1.81837C6.26498 1.66598 7.71058 1.666 9.5239 1.66602Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.17895 10.9592C5.52313 10.9113 7.81456 11.6421 9.64502 13.029C11.3354 14.3099 12.5335 16.0758 13.0544 18.0577C13.1026 18.2409 13.065 18.4361 12.9523 18.5884C12.8395 18.7407 12.6638 18.8336 12.4744 18.8411C11.6423 18.8737 10.6734 18.8737 9.55218 18.8737H9.494C7.66842 18.8737 6.23453 18.8737 5.11502 18.7232C3.9678 18.569 3.05759 18.2464 2.34243 17.5313C1.72088 16.9097 1.39311 16.1472 1.21655 15.2011C1.04364 14.2744 1.00807 13.1256 1.00001 11.6984C0.998248 11.3866 1.22658 11.1212 1.53516 11.0764C2.07888 10.9974 2.62847 10.9582 3.17895 10.9592ZM12.0989 13.6305C11.9077 13.4073 11.9826 13.057 12.2623 12.9672C12.9767 12.7379 13.7082 12.621 14.4482 12.6237C15.545 12.6229 16.6273 12.8878 17.6634 13.3991C17.8918 13.5118 18.029 13.752 18.01 14.0061C17.8973 15.5176 17.6052 16.6674 16.7413 17.5313C16.0894 18.1832 15.2755 18.5088 14.2677 18.6782C14.345 18.3738 14.3456 18.0511 14.2638 17.7399C13.865 16.2226 13.1204 14.8231 12.0989 13.6305Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default BrandIdentity;
