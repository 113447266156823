import React from "react";
import ReportForm from "../../components/Report/ReportForm";
import { useTranslation } from "react-i18next";

const reports = () => {
  const { t } = useTranslation("report");
  return (
    <>
      <div className="mx-[-30px] md:mx-0">
        <p style={{ fontWeight: 700 }} className="360:pl-[50px] lg:pl-[0px]">
          Reports
        </p>
      </div>

      <div className="lg:mt-[28px]  md:bg-[#fff] min-h-screen  mx-[-30px] xsmd:mx-[0%] lg:mx-0">
        <div className="pt-[20px] 360:pl-[50px] lg:pl-[64px] lg:pt-[55px] pb-[22px]">
          <h1
            style={{ fontWeight: 600 }}
            className="lg:text-[20px] text-[#000] whitespace-nowrap"
          >
            {t("report.generateTitle")}
          </h1>
          <p className="whitespace-wrap 360:whitespace-nowrap">
            {t("report.selectionPrompt")}
          </p>
        </div>
        <hr />
        <ReportForm />
      </div>
    </>
  );
};

export default reports;
