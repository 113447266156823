import * as React from "react";

interface ArrowDownIconProps {
  color?: string;
}

const ArrowDown: React.FC<ArrowDownIconProps> = ({ color }) => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.46124 14.5156L5.75413 15.2227L6.46124 15.9298L7.16834 15.2227L6.46124 14.5156ZM7.46124 1.4387C7.46124 0.886417 7.01352 0.438702 6.46124 0.438702C5.90895 0.438702 5.46124 0.886417 5.46124 1.4387L7.46124 1.4387ZM0.523362 9.99196L5.75413 15.2227L7.16834 13.8085L1.93758 8.57775L0.523362 9.99196ZM7.16834 15.2227L12.3991 9.99196L10.9849 8.57775L5.75413 13.8085L7.16834 15.2227ZM7.46124 14.5156L7.46124 1.4387L5.46124 1.4387L5.46124 14.5156L7.46124 14.5156Z"
        fill="#D82020"
      />
    </svg>
  );
};

export { ArrowDown };
