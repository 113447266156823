import * as React from "react";

interface LoadingProps {
  data: {};
}

const Loading: React.FC<LoadingProps> = ({ data }) => {
  return (
    <>
      <tr className="bg-white border-y border-gray-200">
        {Object.entries(data).map((d) => (
          <td
            key={d[0]}
            className="whitespace-nowrap font-medium text-gray-900 animate-pulse px-4 py-2"
          >
            <span className="h-2 bg-[#E6EEF5] inline-block rounded-full w-full"></span>
          </td>
        ))}
      </tr>
      <tr className="bg-white border-y border-gray-200">
        {Object.entries(data).map((d) => (
          <td
            key={d[0]}
            className="whitespace-nowrap font-medium text-gray-900 animate-pulse px-3 py-[18px]"
          >
            <span className="h-2 bg-[#E6EEF5] inline-block rounded-full w-full"></span>
          </td>
        ))}
      </tr>
      <tr className="bg-white border-y border-gray-200">
        {Object.entries(data).map((d) => (
          <td
            key={d[0]}
            className="whitespace-nowrap font-medium text-gray-900 animate-pulse px-3 py-[18px]"
          >
            <span className="h-2 bg-[#E6EEF5] inline-block rounded-full w-full"></span>
          </td>
        ))}
      </tr>
      <tr className="bg-white border-y border-gray-200">
        {Object.entries(data).map((d) => (
          <td
            key={d[0]}
            className="whitespace-nowrap font-medium text-gray-900 animate-pulse px-3 py-[18px]"
          >
            <span className="h-2 bg-[#E6EEF5] inline-block rounded-full w-full"></span>
          </td>
        ))}
      </tr>
      <tr className="bg-white border-y border-gray-200">
        {Object.entries(data).map((d) => (
          <td
            key={d[0]}
            className="whitespace-nowrap font-medium text-gray-900 animate-pulse px-3 py-[18px]"
          >
            <span className="h-2 bg-[#E6EEF5] inline-block rounded-full w-full"></span>
          </td>
        ))}
      </tr>
      <tr className="bg-white border-y border-gray-200">
        {Object.entries(data).map((d) => (
          <td
            key={d[0]}
            className="whitespace-nowrap font-medium text-gray-900 animate-pulse px-3 py-[18px]"
          >
            <span className="h-2 bg-[#E6EEF5] inline-block rounded-full w-full"></span>
          </td>
        ))}
      </tr>
      <tr className="bg-white border-y border-gray-200">
        {Object.entries(data).map((d) => (
          <td
            key={d[0]}
            className="whitespace-nowrap font-medium text-gray-900 animate-pulse px-3 py-[18px]"
          >
            <span className="h-2 bg-[#E6EEF5] inline-block rounded-full w-full"></span>
          </td>
        ))}
      </tr>
      <tr className="bg-white border-t border-gray-200">
        {Object.entries(data).map((d) => (
          <td
            key={d[0]}
            className="whitespace-nowrap font-medium text-gray-900 animate-pulse px-3 py-[18px]"
          >
            <span className="h-2 bg-[#E6EEF5] inline-block rounded-full w-full"></span>
          </td>
        ))}
      </tr>
    </>
  );
};

export { Loading };
