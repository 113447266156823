import React from "react";
import logo from "./Images/EssemieBlack.svg";

const LogoBlack = () => {
  return (
    <div>
      <img src={logo} className="w-[160px] h-[45px]  " alt="logo" />
    </div>
  );
};

export default LogoBlack;
