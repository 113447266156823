import React, { useState, useSyncExternalStore } from "react";
import { DropdownComponent, InputComponent } from "../../core/Forms";
import { CloseIcon } from "../../../assets/icons";
import ExpandLeft from "../../../assets/icons/ExpandLeft";
import { Button } from "../../common";
import { Variation } from "../../../@types/inventory";
import { commasToNumber, convertToFloat } from "../../../utils/convertToFloat";
import { toast } from "react-hot-toast";
import { removeStock } from "../../../backend-services";
import { Spinner } from "flowbite-react";
import { validateRemoveStock } from "../../../modules/inventory/inventory";
import { clearFieldError } from "../../../utils/handleErrors";

interface StockRemovalProps {
  id: string | undefined;
  onClose: () => void;
  setShowStockRemoval: (showPromt: boolean) => void;
  variations: Variation[];
  fetchDetail: (id: string) => void;
}
const StockRemoval = ({
  onClose,
  setShowStockRemoval,
  variations,
  id,
  fetchDetail,
}: StockRemovalProps) => {
  const variationOptionsSet = new Set<string>();
  const [removedItem, setRemovedItem] = useState({ quantity: "", reason: "" });
  const [variationId, setVariationId] = useState<string | undefined>("");
  const [itemVariation, setItemVariation] = useState({
    name: "",
    type: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState("");
  const [selectedName, setSelectedName] = React.useState("");
  const [error, setError] = React.useState({
    quantity: "",
    reason: "",
    name: "",
    type: "",
  });

  variations.forEach((item: any) => variationOptionsSet.add(item.name));
  const variationOptions = Array.from(variationOptionsSet).map((value) => ({
    label: value,
    value,
  }));

  const variant = variations.filter((item: any) => item.name === selectedName);
  const typesOptions =
    variant?.map((item: any) => ({ value: item.type, label: item.type })) || [];

  const handleVariation = (name: string, value: string) => {
    if (name === "name") {
      setSelectedName(value);
      setItemVariation((prevState) => ({
        ...prevState,
        name: value,
      }));
      clearFieldError(name, error, setError);
    }
    if (name === "type") {
      setSelectedType(value);
      setItemVariation((prevState) => ({
        ...prevState,
        type: value,
      }));
      clearFieldError(name, error, setError);
    }
  };

  React.useEffect(() => {
    if (selectedName && selectedType) {
      const selectedVariation = variations.find(
        (item) => item.name === selectedName && item.type === selectedType,
      );
      setVariationId(selectedVariation?.id);
    }
  }, [selectedName, selectedType]);

  const handleItem = (name: string, value: string) => {
    if (name === "quantity") {
      const resValue = value.replace(/\D/g, "");
      setRemovedItem((prevState) => ({
        ...prevState,
        quantity: resValue,
      }));
      clearFieldError(name, error, setError);
    }
    if (name === "reason") {
      setRemovedItem((prevState) => ({
        ...prevState,
        reason: value,
      }));
      clearFieldError(name, error, setError);
    }
  };

  const stockRemoval = async () => {
    const hasVariation = variations && variations.length > 0;
    const newQuantity = Number(removedItem.quantity);

    const { errors, hasErrors } = validateRemoveStock({
      quantity: newQuantity,
      reason: removedItem.reason,
      name: itemVariation.name,
      type: itemVariation.type,
      isVariation: hasVariation,
    });

    if (hasErrors) {
      setError(errors);
      return;
    }
    const removedStock = hasVariation
      ? {
          reason: removedItem.reason,
          variation: {
            name: itemVariation.name,
            id: variationId,
            type: itemVariation.type,
            quantity: newQuantity,
          },
        }
      : { reason: removedItem.reason, quantity: newQuantity };
    try {
      setIsLoading(true);
      if (id) {
        const response = await removeStock(id, removedStock);
        setIsLoading(false);
        setShowStockRemoval(false);
        fetchDetail(id);
        toast.success(`${removedItem.quantity} Stocks Removed Successfully!`);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      throw new Error("Failed to removed stock");
    }
  };

  return (
    <div className="bg-[white] rounded-[12px] w-[500px]">
      <div className="  px-[1em] py-[1em]">
        <div className="">
          <div className="flex mb-8 items-center justify-between">
            <p className="font-medium text-[20px] flex items-center gap-2">
              Stock Removal
            </p>
            <div className="cursor-pointer" onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
          <form action="" className="mt-[48px]">
            {variations && variations.length > 0 && (
              <div className="mb-[32px] flex items-center gap-6">
                <div className="space-y-2 w-full text-[14px] font-normal">
                  <label htmlFor="variation">Variation</label>
                  <DropdownComponent
                    options={variationOptions}
                    value={selectedName}
                    selectedValue={
                      itemVariation.name ? itemVariation.name : "Variation"
                    }
                    setSelectedValue={setSelectedName}
                    onChange={handleVariation}
                    name="name"
                    dropdownClassName="z-10 max-h-52 overflow-y-auto bg-white border border-gray-300 left-0 right-0 top-full w-full"
                    iconClassName="xl:mt-[3px] mr-[16px] mt-[0em]  md:mr-[8px] lg:mr-[8px]"
                  />
                  <span style={{ color: "red" }}>{error.name}</span>
                </div>
                <div className="space-y-2 w-full text-[14px] font-normal">
                  <label htmlFor="variation">Variation Type</label>
                  <DropdownComponent
                    options={typesOptions}
                    value={itemVariation.type}
                    selectedValue={
                      itemVariation.type ? itemVariation.type : "Type"
                    }
                    setSelectedValue={setSelectedType}
                    onChange={handleVariation}
                    name="type"
                    dropdownClassName="z-10 max-h-52 overflow-y-auto bg-white border border-gray-300 left-0 right-0 top-full w-full"
                    iconClassName="xl:mt-[3px] mr-[16px] mt-[0em]  md:mr-[8px] lg:mr-[8px]"
                  />
                  <span style={{ color: "red" }}>{error.name}</span>
                </div>
              </div>
            )}
            <div className="mb-[32px] space-y-2">
              <label className="font-medium text-[16px]">
                How many quantities are you removing ?{" "}
              </label>
              <InputComponent
                handleChange={(e) => handleItem("quantity", e.target.value)}
                value={removedItem.quantity}
                placeholder="2"
                name="quantity"
                type={"text"}
                className="w-full"
                error={error.quantity}
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label className="font-normal text-[16px]">
                Reason for removal <span style={{ color: "red" }}> *</span>
                <span style={{ color: "red" }}>{error.reason}</span>
              </label>
              <textarea
                onChange={(e) => handleItem("reason", e.target.value)}
                value={removedItem.reason}
                className={`block w-full border-[0.5px] h-[80px] md:h-[96px] rounded`}
                id="grid-first-name"
                name="reasons"
                placeholder="Provide more details about the product.."
              />
            </div>
            <div className=" flex gap-6 justify-end text-[16px] font-semibold mt-[2em]">
              <div
                onClick={onClose}
                className="text-[black]  h-[48px] border cursor-pointer border-gray-300 bg-white w-[140px] rounded-md flex items-center justify-center"
              >
                Cancel
              </div>
              <div
                onClick={stockRemoval}
                className="text-[#ffffff] h-[48px] cursor-pointer bg-[#FB0303] w-[140px] rounded-md flex items-center justify-center"
              >
                {isLoading ? (
                  <div className="mr-3">
                    <Spinner size="sm" light={true} />
                  </div>
                ) : (
                  "Remove"
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export { StockRemoval };
