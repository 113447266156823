import React from "react";
import { useTranslation } from "react-i18next";
import TransactionItem from "../core/operatorDashboard/TransactionItem";
import EmptyTransaction from "../core/operatorDashboard/EmptyTransaction";
import { ImSpinner } from "react-icons/im";
import { RecentTransactionType } from "../../@types";

interface RecentTransactionsProps {
  transactions: RecentTransactionType[];
  isLoading: boolean;
}

const RecentTransactions = ({
  transactions,
  isLoading,
}: RecentTransactionsProps) => {
  const { t } = useTranslation("dashboard");

  return (
    <div className="rounded-lg bg-white py-3 lg:py-6 w-full">
      <h2 className="text-[#1D2939] font-medium leading-6 text-[16px] px-3 md:px-6">
        {t("recentTransactions")}
      </h2>
      <hr className="border border-[#EAECF0] w-full mt-3 mb-2" />

      <div className="mt-3 px-3 md:px-6">
        {isLoading ? (
          <div className="h-[240px] w-full flex justify-center items-center">
            <ImSpinner className="h-16 w-20 mx-auto text-slate-300 animate-spin" />
          </div>
        ) : transactions.length > 0 ? (
          <div className="space-y-2">
            {transactions.map(({ data }: any, index: number) => (
              <TransactionItem key={data.id} data={data} />
            ))}
          </div>
        ) : (
          <EmptyTransaction text="You do not have any transactions yet." />
        )}
      </div>
    </div>
  );
};
export default RecentTransactions;
