import axios, { AxiosInstance } from "axios";
import config from "../config";
import { setupAuthInterceptor } from "../utils";
import { History } from "history";

export const createPaymentService = (history?: History): AxiosInstance => {
  const paymentService = axios.create({
    baseURL: config.paymentServiceURL,
  });

  setupAuthInterceptor(paymentService);

  return paymentService;
};

const paymentService = createPaymentService();

export { paymentService };
