import React from "react";
import { ForgetPasswordView } from "../../components/auth";

interface Props {
  children?: any;
}

export const ForgetPasswordPage: React.FC<Props> = ({ children }) => {
  return (
    <div className="">
      <ForgetPasswordView />
    </div>
  );
};
