import React from "react";

const DocumentIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.933594" width="24" height="24" rx="12" fill="#FFCDCD" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9342 6.35317C12.5597 6.35317 12.2007 6.50191 11.9359 6.76667C11.6711 7.03142 11.5224 7.39051 11.5224 7.76494H14.3459C14.3459 7.39051 14.1972 7.03142 13.9324 6.76667C13.6677 6.50191 13.3086 6.35317 12.9342 6.35317ZM11.0664 5.64729C11.5641 5.20823 12.2184 4.94141 12.9342 4.94141C13.6499 4.94141 14.3036 5.20823 14.8019 5.64729H17.1695C17.5439 5.64729 17.903 5.79603 18.1677 6.06078C18.4325 6.32554 18.5812 6.68463 18.5812 7.05905V17.6473C18.5812 18.0217 18.4325 18.3808 18.1677 18.6456C17.903 18.9103 17.5439 19.0591 17.1695 19.0591H8.69887C8.32445 19.0591 7.96536 18.9103 7.70061 18.6456C7.43585 18.3808 7.28711 18.0217 7.28711 17.6473V7.05905C7.28711 6.68463 7.43585 6.32554 7.70061 6.06078C7.96536 5.79603 8.32445 5.64729 8.69887 5.64729H11.0664ZM10.1996 7.05905H8.69887V17.6473H17.1695V7.05905H15.6688C15.7266 7.28494 15.7577 7.52141 15.7577 7.76494V8.47082C15.7577 8.65803 15.6833 8.83757 15.5509 8.96995C15.4186 9.10233 15.239 9.1767 15.0518 9.1767H10.8165C10.6293 9.1767 10.4498 9.10233 10.3174 8.96995C10.185 8.83757 10.1106 8.65803 10.1106 8.47082V7.76494C10.1106 7.52141 10.1417 7.28494 10.1996 7.05905ZM10.1106 11.2943C10.1106 11.1071 10.185 10.9276 10.3174 10.7952C10.4498 10.6628 10.6293 10.5885 10.8165 10.5885H15.0518C15.239 10.5885 15.4186 10.6628 15.5509 10.7952C15.6833 10.9276 15.7577 11.1071 15.7577 11.2943C15.7577 11.4816 15.6833 11.6611 15.5509 11.7935C15.4186 11.9259 15.239 12.0002 15.0518 12.0002H10.8165C10.6293 12.0002 10.4498 11.9259 10.3174 11.7935C10.185 11.6611 10.1106 11.4816 10.1106 11.2943ZM10.1106 14.1179C10.1106 13.9307 10.185 13.7511 10.3174 13.6187C10.4498 13.4864 10.6293 13.412 10.8165 13.412H12.9342C13.1214 13.412 13.3009 13.4864 13.4333 13.6187C13.5657 13.7511 13.6401 13.9307 13.6401 14.1179C13.6401 14.3051 13.5657 14.4846 13.4333 14.617C13.3009 14.7494 13.1214 14.8238 12.9342 14.8238H10.8165C10.6293 14.8238 10.4498 14.7494 10.3174 14.617C10.185 14.4846 10.1106 14.3051 10.1106 14.1179Z"
        fill="#FF4848"
      />
    </svg>
  );
};

export { DocumentIcon };
