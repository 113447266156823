import React from "react";
import useAnnotations from "../../hooks/annotation/useAnnotations";
import AnnotationComponent from "../../components/common/Annotation/AnnotationComponent";
import { useNavigate } from "react-router-dom";
import { Annotation } from "../../@types/annotation";
import { useTranslation } from "react-i18next";
import ExpandLeft from "../../assets/icons/ExpandLeft";

interface InventoryHeaderProps {
  annotations: Annotation[];
}

const InventoryHeader: React.FC<InventoryHeaderProps> = ({ annotations }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("inventories");
  const annotationsData = useAnnotations(annotations, "Inventory");

  return (
    <div className="flex items-center justify-between mb-4">
      <div className="hidden lg:block md:block">
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => navigate("/inventories")}
        >
          <ExpandLeft />
          <span className="font-medium text-lg">{t("back")}</span>
        </div>
      </div>
      <div
        className="flex lg:hidden md:hidden items-center gap-2 cursor-pointer"
        onClick={() => navigate("/inventories")}
      >
        <ExpandLeft />
        <span className="font-medium text-lg">Inventory Details</span>
      </div>
      <AnnotationComponent type="inventory" annotationData={annotationsData} />
    </div>
  );
};

export default InventoryHeader;
