import React from "react";
import { useTranslation } from "react-i18next";
import useRefund from "../../../hooks/invoice/useRefund";
import ModalButton from "../../common/Modal/ModalButton";
import ModalContent from "../../common/Modal/ModalContent";
import ModalFooter from "../../common/Modal/ModalFooter";
import ModalHeader from "../../common/Modal/ModalHeader";
import Modals from "../../common/Modal/Modals";

interface RefundConfirmationPromptProps {
  isOpen: boolean;
  onClose: () => void;
  onRefund: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
}

const RefundConfirmationPrompt: React.FC<RefundConfirmationPromptProps> = ({
  isOpen,
  onClose,
  onRefund,
}) => {
  const { t } = useTranslation("invoices");
  const { isProcessing, handleRefund } = useRefund({ onRefund });

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modals isOpen={isOpen} onClose={onClose}>
      <ModalHeader title="Refund Invoice" onClose={onClose} />
      <ModalContent>
        <p className="text-gray-600 text-base font-medium">
          {t("confirmRefundInvoice")}
        </p>
      </ModalContent>
      <ModalFooter>
        <div className="flex gap-4">
          <ModalButton variant="secondary" onClick={handleCancel}>
            {t("cancel")}
          </ModalButton>
          <ModalButton
            variant="primary"
            onClick={handleRefund}
            disabled={isProcessing}
          >
            {isProcessing ? t("processing") : "Yes Refund"}
          </ModalButton>
        </div>
      </ModalFooter>
    </Modals>
  );
};

export { RefundConfirmationPrompt };
