import React from "react";

interface ExpensesIconProps {
  color?: string;
}

const ExpensesIcon: React.FC<ExpensesIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.83464 4.55778C3.94637 4.55778 3.10104 4.45835 2.33464 4.27895C1.53466 4.09169 0.667969 4.66776 0.667969 5.5065V14.6806C0.667969 15.3543 0.667969 15.6911 0.829732 16.0384C0.921992 16.2366 1.13211 16.506 1.30046 16.6422C1.59563 16.8809 1.84197 16.9385 2.33464 17.0539C3.10104 17.2333 3.94637 17.3327 4.83464 17.3327C6.43219 17.3327 7.89085 17.0111 9.0013 16.481C10.1118 15.951 11.5704 15.6294 13.168 15.6294C14.0562 15.6294 14.9016 15.7288 15.668 15.9082C16.4679 16.0955 17.3346 15.5194 17.3346 14.6806V5.5065C17.3346 4.83286 17.3346 4.49604 17.1729 4.14869C17.0806 3.95059 16.8705 3.68111 16.7021 3.54497C15.668 2.69828 14.0013 3.53454 14.0013 3.53454"
          stroke={color || "currentColor"}
          stroke-width="1.2"
          stroke-linecap="round"
        />
        <path
          d="M11.0846 10.2493C11.0846 11.3999 10.1519 12.3327 9.0013 12.3327C7.85071 12.3327 6.91797 11.3999 6.91797 10.2493C6.91797 9.09876 7.85071 8.16602 9.0013 8.16602C10.1519 8.16602 11.0846 9.09876 11.0846 10.2493Z"
          stroke={color || "currentColor"}
          stroke-width="1.2"
        />
        <path
          d="M3.58594 11.082L3.58594 11.0895"
          stroke={color || "currentColor"}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.418 9.41016L14.418 9.41764"
          stroke={color || "currentColor"}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.91797 2.74935C7.32758 2.32793 8.41778 0.666016 9.0013 0.666016M11.0846 2.74935C10.675 2.32793 9.58483 0.666016 9.0013 0.666016M9.0013 0.666016V5.66602"
          stroke={color || "currentColor"}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
export { ExpensesIcon };

export const ActiveExpensesIcon: React.FC<ExpensesIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.92269 6.77214C6.92269 7.92273 7.85543 8.85547 9.00602 8.85547C10.1566 8.85547 11.0894 7.92273 11.0894 6.77214V6.31147C11.0894 6.1212 11.0894 6.02607 11.1347 5.96142C11.1801 5.89677 11.2849 5.8589 11.4945 5.78316C11.8943 5.63871 12.4195 5.33093 12.7394 4.6715L12.7394 4.67147C12.7595 4.63003 12.7696 4.6093 12.7775 4.59686C12.82 4.53053 12.8777 4.49396 12.9558 4.4838C12.9704 4.48189 12.9879 4.48169 13.0229 4.48129C13.0712 4.48074 13.1195 4.48047 13.168 4.48047C14.1012 4.48047 14.9934 4.58261 15.8075 4.76908C16.5717 4.94411 17.1534 5.28228 17.5195 5.84501C17.8703 6.38424 17.9596 7.0364 17.9596 7.70044V15.8438C17.9596 17.1498 16.6338 17.9074 15.5284 17.6542C14.8097 17.4896 14.0113 17.3971 13.168 17.3971C11.6466 17.3971 10.2807 17.6977 9.26578 18.1717C8.05977 18.735 6.50838 19.0638 4.83464 19.0638C3.90144 19.0638 3.00921 18.9617 2.1951 18.7752C1.46537 18.6081 0.889001 18.2814 0.515001 17.7294C0.153319 17.1956 0.0429688 16.5401 0.0429688 15.8438V7.70044C0.0429688 6.39445 1.3688 5.6369 2.47417 5.89008C3.19287 6.05469 3.9913 6.14714 4.83464 6.14714C5.35095 6.14714 5.84936 6.11251 6.32222 6.04824C6.60845 6.00933 6.75157 5.98988 6.83713 6.0646C6.92269 6.13931 6.92269 6.27857 6.92269 6.55709V6.5571V6.77214ZM9.0013 14.2721C10.382 14.2721 11.5013 13.1528 11.5013 11.7721C11.5013 10.3914 10.382 9.27214 9.0013 9.27214C7.62059 9.27214 6.5013 10.3914 6.5013 11.7721C6.5013 13.1528 7.62059 14.2721 9.0013 14.2721ZM3.58464 11.7721C4.04487 11.7721 4.41797 12.1452 4.41797 12.6055V12.613C4.41797 13.0732 4.04487 13.4463 3.58464 13.4463C3.1244 13.4463 2.7513 13.0732 2.7513 12.613V12.6055C2.7513 12.1452 3.1244 11.7721 3.58464 11.7721ZM14.418 10.099C14.8782 10.099 15.2513 10.4721 15.2513 10.9323V10.9398C15.2513 11.4 14.8782 11.7731 14.418 11.7731C13.9577 11.7731 13.5846 11.4 13.5846 10.9398V10.9323C13.5846 10.4721 13.9577 10.099 14.418 10.099Z"
          fill="#0D3C6D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.83952 6.77214C9.83952 7.23237 9.46642 7.60547 9.00618 7.60547C8.54595 7.60547 8.17285 7.23237 8.17285 6.77214L8.17285 4.68877L7.67764 4.68879C7.53127 4.68889 7.35156 4.68902 7.20453 4.67063L7.20174 4.67028C7.09637 4.65714 6.61633 4.59728 6.3877 4.12595C6.15857 3.65359 6.41018 3.23681 6.46461 3.14664L6.46664 3.14328C6.54349 3.01576 6.65528 2.87336 6.74723 2.75623L6.76701 2.73103C7.01253 2.41784 7.33084 2.01433 7.64787 1.68849C7.80606 1.5259 7.98711 1.3582 8.17954 1.22583C8.35059 1.10816 8.64241 0.93877 9.0013 0.93877C9.36019 0.93877 9.65202 1.10816 9.82307 1.22583C10.0155 1.3582 10.1965 1.5259 10.3547 1.68849C10.6718 2.01433 10.9901 2.41784 11.2356 2.73103L11.2554 2.75623C11.3473 2.87336 11.4591 3.01576 11.536 3.14328L11.538 3.14664C11.5924 3.23681 11.844 3.65359 11.6149 4.12595C11.3863 4.59728 10.9062 4.65714 10.8009 4.67028L10.7981 4.67063C10.651 4.68902 10.4713 4.68889 10.325 4.68879L9.83952 4.68877L9.83952 6.77214Z"
          fill="#0D3C6D"
        />
      </svg>
    </>
  );
};
