import axios, { AxiosInstance } from "axios";
import config from "../config";
import { setupAuthInterceptor } from "../utils";

export const createVendorService = (): AxiosInstance => {
  const vendorService = axios.create({
    baseURL: config.vendorServiceURL,
  });

  setupAuthInterceptor(vendorService);

  return vendorService;
};

const vendorService = createVendorService();

export { vendorService };
