export function getDurationFromDate(inputDate: string) {
  const currentDate = new Date();
  const dueDate = new Date(inputDate);

  const timeDiff = Math.abs(currentDate.getTime() - dueDate.getTime());
  const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
  const weeksDiff = Math.floor(daysDiff / 7);
  const monthsDiff = Math.ceil(daysDiff / 30);

  if (monthsDiff > 1) {
    return `${monthsDiff} months overdue`;
  } else if (weeksDiff > 1) {
    return `${weeksDiff} weeks overdue`;
  } else {
    return `${daysDiff} days overdue`;
  }
}
