import React from "react";
import { CloseIcon, CloseIconBig } from "../../../assets/icons";
import cx from "classnames";

interface SidebarDrawerProps {
  /**
   * Header title of the Drawer
   */
  title: string;
  /**
   * Whether to show the drawer or not.
   */
  show: boolean;
  /**
   * Called when close button is clicked
   * @returns void
   */
  onClose?: () => void;
  /**
   * The position of the Sidebar Drawer (left or right)
   */
  position: "left" | "right" | "middle";
  children: React.ReactNode;
  navigate?: () => void;
  buttonText?: any;
  onClick?: () => void;
  showFooter?: boolean;
  showHeader?: boolean;
  icon?: React.ReactNode;
  topBarCloseIcon?: boolean;
  topBarHeight?: boolean;
  /** Test ID for testing purposes */
  "data-testid"?: string;
}

const SidebarDrawer: React.FC<SidebarDrawerProps> = ({
  title,
  show,
  onClose,
  position,
  children,
  navigate,
  buttonText,
  onClick,
  showFooter,
  showHeader = true,
  icon,
  topBarCloseIcon,
  topBarHeight,
}: SidebarDrawerProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClose = () => {
    setTimeout(() => {});
    setIsOpen(false);
    onClose && onClose();
  };

  React.useEffect(() => {
    setIsOpen(show);
  }, [show, position]);

  const handleButtonClick = () => {
    onClick && onClick();
    // Additional logic for submenu navigation
  };

  return isOpen ? (
    <>
      {/* Modal Background */}
      <div
        aria-hidden="false"
        className={cx({
          "fixed top-0 right-0 bottom-0 left-0 z-50 items-start flex bg-gray-900 bg-opacity-50 h-full":
            true,

          "blur-background": true,
          "justify-start": position === "left",
          "justify-end": position === "right",
          "justify-center": position === "middle",
          "h-full": topBarHeight,
        })}
        data-testid={["data-testid"]}
      >
        <div
          onClick={() => {}}
          className={cx({
            " relative z-50 w-full": true,
            "p-3 max-w-lg bg-white w-[95%] md:w-auto mt-3 ml-2 md:mt-0 md:ml-0  h-[150vh] shadow":
              true,
            "animate-slide-in-left": isOpen && position === "left",
            "animate-slide-in-right": isOpen && position === "right",
            "animate-slide-in-middle": isOpen && position === "middle",
            "animate-slide-in":
              isOpen &&
              position !== "left" &&
              position !== "right" &&
              position !== "middle",
          })}
        >
          {/* MODAL HEADER */}
          {showHeader && (
            <div className="flex items-left justify-between py-8">
              <h3 className="hidden lg:block md:block text-[25px] font-semibold text-gray-900 text-center  ">
                {title}
              </h3>
              <h3 className="lg:hidden md:hidden  text-[25px] font-semibold text-gray-900 text-center whitespace-nowrap ">
                {title}
              </h3>
              <button
                aria-label="Close"
                className={`rounded-lg shadow-sm py-2 pr-3 bg-[#F4F7FF] text-[black] w-35 h-35 lg:ml-[12em] ml-[2em] `}
                type="button"
                onClick={handleClose}
              >
                {topBarCloseIcon ? <CloseIconBig /> : <CloseIcon />}
              </button>
            </div>
          )}

          {/* MODAL BODY */}
          <div className="flex justify-center items-center">
            {" "}
            {icon && <div className="mr-2">{icon}</div>}
            {children}
          </div>

          {/* MODAL FOOTER */}
          {showFooter && (
            <div className="flex justify-end p-4">
              {buttonText && onClick && (
                <button
                  className="bg-[#FF6702] text-white rounded mr-[6em] w-[215px] mt-[1em] h-[48px] flex justify-center p-4 text-[16px]"
                  onClick={handleButtonClick}
                >
                  {buttonText}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  ) : null;
};

export { SidebarDrawer };
