import React from "react";
import {
  ExpenseLineItem,
  ICreateExpenseData,
  ICustomer,
  IExpense,
} from "../../@types";
import { createExpense, editExpense } from "../../backend-services";
import { clearFieldError } from "../../utils/handleErrors";
import { error } from "console";

export const validateExpenseBasicInfo = (
  formData: ICreateExpenseData,
  setValidationResults: React.Dispatch<
    React.SetStateAction<ICreateExpenseData>
  >,
) => {
  const errors = {
    customerId: "",
    category: "",
    currency: "",
    date: "",
    lineItems: "",
    isBillable: false,
    reference: "",
    vendorName: "",
    paymentMethod: "",
    exchangeRate: 0,
  };
  let isValid = true;

  if (!formData.date) {
    errors.date = "Expense Date required";
    isValid = false;
  }

  if (!formData.category) {
    errors.category = "Category is required";
    isValid = false;
  }
  if (!formData.paymentMethod) {
    errors.paymentMethod = "Payment Method is required";
    isValid = false;
  }
  if (!formData.vendorName) {
    errors.vendorName = "Vendor is required";
    isValid = false;
  }

  clearFieldError("vendorName", errors, setValidationResults);
  clearFieldError("customerId", errors, setValidationResults);

  setValidationResults(errors as ICreateExpenseData);
  return isValid;
};

export const validateExpenseItemDetails = (
  formData: ICreateExpenseData,
  setValidationResults: React.Dispatch<
    React.SetStateAction<ICreateExpenseData>
  >,
) => {
  const errors: { lineItems: string } = {
    lineItems: "",
  };
  let isValid = true;

  if (
    formData.lineItems.length === 0 ||
    formData.lineItems.every(
      (item: { name: any; rate: any; quantity: any }) =>
        !item.name || !item.rate || !item.quantity,
    )
  ) {
    errors.lineItems = "Add at least one Line Item is required";
    isValid = false;
  } else {
    let lineItemsErrors = "";

    // Validate each line item
    formData.lineItems.forEach(
      (item: { name: any; rate: any; quantity: any }, index: number) => {
        let errorMessage = "";
        if (!item.name) errorMessage += "Name is required. ";
        if (!item.rate) errorMessage += "Rate is required. ";
        if (!item.quantity) errorMessage += "Quantity is required. ";

        if (errorMessage) {
          lineItemsErrors += `Line item ${index + 1}: ${errorMessage.trim()} `;
          isValid = false;
        }
      },
    );

    errors.lineItems = lineItemsErrors.trim();
  }

  setValidationResults(errors as ICreateExpenseData);
  return isValid;
};

export const validateExpenseTotalPayment = (
  formData: ICreateExpenseData,
  setValidationResults: React.Dispatch<
    React.SetStateAction<ICreateExpenseData>
  >,
) => {
  const errors = {
    amount: 0,
    customerId: "",
  };
  let isValid = true;

  if (formData.isBillable === true && !formData.customerId) {
    errors.customerId = "Select a Customer";
    isValid = false;
  }

  setValidationResults((prevResults) => ({
    ...prevResults,
    ...errors,
  }));

  return isValid;
};

interface ExpenseParams {
  expenseId?: string;
  customer?: ICustomer;
  formData: ICreateExpenseData;
  isBillable: boolean;
  isProtected: boolean;
}
export async function manageExpense({
  expenseId,
  customer,
  formData,
  isBillable,
  isProtected,
}: ExpenseParams): Promise<void> {
  if (
    formData.lineItems.some(
      (item: { quantity: string }) => parseFloat(item.quantity) === 0,
    )
  ) {
    return;
  }
  const amount = formData.lineItems
    .map(
      (exp: { rate: string; quantity: string }) =>
        parseFloat(exp.rate) * parseFloat(exp.quantity) || 0,
    )
    .reduce((prev: any, curr: any) => prev + curr, 0);
  const lineItems = formData.lineItems.map(
    (item: { name: any; rate: any; amount: any; quantity: any }) => ({
      name: item.name,
      rate: Number(item.rate),
      amount: Number(item.amount),
      quantity: Number(item.quantity),
    }),
  );
  const commonFields = {
    amount,
    isBillable,
    isProtected,
    date: new Date(formData.date).toISOString(),
    currency: formData.currency,
    category: formData.category,
    vendorName: formData.vendorName,
    vendorId: formData.vendorId,
    description: formData.description,
    paymentMethod: formData.paymentMethod,
    lineItems: lineItems.length > 0 ? lineItems : undefined,
    notes: formData.notes,
    customerName: customer
      ? `${customer?.firstName} ${customer?.lastName}`
      : "",
    customerId: customer?.id ?? "",
  };
  if (!expenseId) {
    const expenseData = {
      ...commonFields,
      customerId: customer?.id ?? "",
      customerName: customer
        ? `${customer?.firstName} ${customer?.lastName}`
        : "",
    };
    const response = await createExpense(expenseData as ICreateExpenseData);
    const newExpenseId = response.id;
    return newExpenseId;
  } else {
    const updatedExpense = await editExpense(expenseId, commonFields);
    const updatedExpenseId = updatedExpense?.id;
    return updatedExpenseId;
  }
}

export const validateLineItems = (lineItems: ExpenseLineItem[]) => {
  const errors = [];

  for (let i = 0; i < lineItems.length; i++) {
    const item: ExpenseLineItem = lineItems[i];
    const error = {
      rate: "",
      quantity: "",
      name: "",
    };

    if (isNaN(item.rate) || item.rate === 0) {
      error.rate = "Rate must not be a zero.";
    }
    if (isNaN(item.quantity) || item.quantity < 1) {
      error.quantity = "Quantity must not be a zero.";
    }
    if (item.name.trim() === "") {
      error.name = "Name cannot be empty.";
    }
    errors.push(error);
  }

  const allErrorsEmpty = errors.every((error) =>
    Object.values(error).every((value) => value === ""),
  );

  return { allErrorsEmpty, errors };
};
