import React from "react";

const TransactionIcon = () => {
  return (
    <div>
      <svg
        width="19"
        height="22"
        viewBox="0 0 19 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 5.32724C0 5.04493 0.100036 4.77421 0.278162 4.5746C0.456289 4.37481 0.698012 4.26271 0.949954 4.26271H15.7607L13.5756 1.82477C13.3345 1.55476 13.2407 1.16106 13.3288 0.792312C13.4171 0.423391 13.6742 0.135216 14.0033 0.0364833C14.3325 -0.0624324 14.6838 0.0430775 14.9248 0.313087L18.7248 4.57139C18.8014 4.66496 18.8626 4.77314 18.9053 4.89077C18.9105 4.92605 18.9105 4.96206 18.9053 4.99734C18.9151 5.08556 18.9151 5.17503 18.9053 5.26343V5.32723C18.9048 5.4404 18.8855 5.55233 18.8483 5.6573C18.8089 5.77689 18.7473 5.88561 18.6678 5.97668L14.8678 10.235C14.6896 10.4366 14.4465 10.5499 14.1932 10.5499C13.94 10.5499 13.6971 10.4366 13.5187 10.235C13.3389 10.0352 13.2377 9.76303 13.2377 9.47915C13.2377 9.19542 13.3389 8.92325 13.5187 8.72332L15.7607 6.39196H0.949962C0.69804 6.39196 0.456297 6.27968 0.27817 6.08007C0.100044 5.88046 0 5.6097 0 5.32724ZM18.05 14.9086H3.23925L5.42433 12.4706C5.66544 12.2006 5.75927 11.8071 5.67116 11.4382C5.58289 11.0692 5.32572 10.7812 4.99668 10.6823C4.66747 10.5834 4.31614 10.6889 4.07519 10.9589L0.27521 15.2172C0.198553 15.3106 0.137322 15.419 0.094698 15.5368C0.0894495 15.5719 0.0894495 15.6079 0.094698 15.6432C0.0687742 15.7293 0.0528719 15.8187 0.0471434 15.9093V15.9733C0.0476205 16.0862 0.0670234 16.1982 0.104237 16.3031C0.14368 16.4227 0.205071 16.5316 0.28475 16.6225L4.08473 20.8808C4.26317 21.0824 4.50588 21.1959 4.75921 21.1959C5.01253 21.1959 5.25543 21.0826 5.43368 20.881C5.61356 20.681 5.71471 20.4089 5.71471 20.1252C5.71471 19.8413 5.61356 19.5691 5.43368 19.3693L3.23925 17.0378H18.05C18.3894 17.0378 18.703 16.8348 18.8727 16.5054C19.0424 16.1761 19.0424 15.7703 18.8727 15.4409C18.703 15.1115 18.3894 14.9086 18.05 14.9086Z"
          fill="#0D8DBF"
        />
      </svg>
    </div>
  );
};

export { TransactionIcon };
