import React from "react";
import { ExpensesIcon } from "../../../assets/icons";
import CategoryIcon from "../../../assets/icons/categoryIcon";

interface ExpenseItemProps {
  category: string;
  vendorName: string;
}

export const ExpenseItemComponent: React.FC<ExpenseItemProps> = ({
  vendorName,
  category,
}) => (
  <div className="flex flex-col justify">
    <div className="flex px-3">
      <ExpensesIcon color="#044E97" />
      <span className="text-[14px] font-normal pl-[1em]">{vendorName}</span>
    </div>
    <div className="flex mt-2 px-3 mb-2">
      <CategoryIcon />
      <span className="text-[14px] font-normal  pl-[1em]">{category}</span>
    </div>
  </div>
);
