import React from "react";
import ExpandLeft from "../../assets/icons/ExpandWhiteLeft";
import HeroImage from "../../assets/icons/svgicons/SubscriptionHeroImage.svg";
import { Button } from "../../components/common";
import { Link } from "react-router-dom";
import BellTimerSvg from "../../assets/icons/BellTimerSvg";
import { useTranslation } from "react-i18next";

const SubscriptionProcess = () => {
  const { t } = useTranslation("subscription");
  return (
    <div
      className="relative min-h-full lg:bg-contain bg-no-repeat"
      style={{ backgroundImage: `url(${HeroImage})` }}
    >
      <div className="flex flex-row pl-[20px]  md:pl-[115px] pt-[64px] text-[#fff]">
        <span className="">
          <ExpandLeft />
        </span>
        <Link to="/Subscription">
          <span className="">Go Back</span>
        </Link>
      </div>

      <div className="flex flex-col items-center">
        <div className="mt-[41px] md:mt-[150px] lg:mt-[98px] mb-[54px] md:mb-[172px] lg:mb-[89px] text-center space-y-[16px] lg:space-y-[32px]">
          <h1 className="text-[24px] max-w-[335px] md:max-w-full md:text-3xl font-bold text-[#fff]">
            {t("subscription.hangtight")}
          </h1>
          <h2 className="text-[16px] max-w-[335px] md:max-w-full md:text-2xl text-[#fff]">
            We're processing your subscription request. This should only <br />{" "}
            take a moment.
          </h2>
        </div>
        <div>
          <BellTimerSvg />
        </div>

        <div className="flex flex-col items-center lg:mt-[64px]">
          <h1 style={{ fontWeight: 600 }} className="text-[24px]">
            {t("subscription.wait")}:
          </h1>
          <ul className="list pt-[24px] space-x-[12px] list-disc">
            <li className="">{t("subscription.access")}</li>
            <li>
              You can check the status of your subscription in your account
              settings.
            </li>
          </ul>

          <div className="flex justify-center items-center text-center mt-[48px]">
            <a href="/dashboard">
              <Button variant="primary" size="full">
                <p className="py-[15px] px-[29px]">Return To Home Page</p>
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionProcess;
