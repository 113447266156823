import { create } from "zustand";
import { activeSubscription } from "../@types/subscripton";
import { SUBSCRIPTION } from "../constants";
import { subscriptionService } from "../services";
import {
  checkSubscription,
  SubscriptionCheckResult,
} from "../utils/subscription";

export interface subscriptionHistory {
  plan: string;
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  costPerMonth: number;
}

export interface subscriptions {
  status: string;
  startDate?: string;
  endDate?: string;
  plan: string;
  costPerMonth?: number;
  usage: {
    expenses: number;
    invoices: number;
    inventories: number;
    services: number;
    expenseAI: number;
    users: number;
    invoicePaymentUpload: number;
  };
  limits: {
    expenses: number;
    invoices: number;
    inventories: number;
    services: number;
    expenseAI: number;
    users: number;
    invoicePaymentUpload: number;
  };
  daysTotal: number;
  daysUsed: number;
  companyId: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  "companyId#status": string;
  paymentStatus: string;
  expenseLimit: number;
  expenseUsage: number;
  invoiceUsage: number;
  invoiceLimit: number;
  expenseAIUsage: number;
  usersUsage: number;
  invoicePaymentUploadUsage: number;
  expenseAILimit: number;
  usersLimit: number;
  invoicePaymentUploadLimit: number;
}

interface SubscriptionStore {
  subscription: subscriptions;
  subscriptionList:
    | {
        [name: string]: {
          name: string;
          price: number;
          limits: {
            invoices: number;
            expenses: number;
            reports: number;
            customers: number;
          };
          features: {
            support: boolean;
          };
        };
      }
    | undefined
    | null;
  history: subscriptionHistory[];
  isLoading: boolean;
  error: any;
  setSubscriptionData: (subscription: any) => void;
  subscriptionData: any;
  fetchActiveSubscription: () => Promise<SubscriptionCheckResult>;
  fetchListSubscription: () => Promise<void>;
  canPerformAction?: (actionType: string) => boolean;
  incrementUsage: (actionType: string) => void;
  hasSubscription: boolean;
  subscriptionType: string | null;
}

export const useSubscriptionStore = create<SubscriptionStore>((set, get) => ({
  subscription: {
    status: "active",
    nextDate: new Date(),
    plan: "",
    daysLeft: 0,
    costPerMonth: 0,
    usage: {
      expenses: 0,
      invoices: 0,
      reports: 0,
      services: 0,
      inventories: 0,
      expenseAI: 0,
      users: 0,
      invoicePaymentUpload: 0,
    },
    limits: {
      expenses: 0,
      invoices: 0,
      reports: 0,
      services: 0,
      inventories: 0,
      expenseAI: 0,
      users: 0,
      invoicePaymentUpload: 0,
    },
    companyId: "",
    id: "",
    createdAt: "",
    updatedAt: "",
    "companyId#status": "",
    paymentStatus: "",
    expenseLimit: 0,
    invoiceLimit: 0,
    invoiceUsage: 0,
    expenseUsage: 0,
    expenseAIUsage: 0,
    usersUsage: 0,
    invoicePaymentUploadUsage: 0,
    expenseAILimit: 0,
    usersLimit: 0,
    invoicePaymentUploadLimit: 0,
    daysTotal: 0,
    daysUsed: 0,
    daysRemainingFriendlyText: "",
    endDate: "",
    startDate: "",
  },
  subscriptionList: undefined,
  history: [],
  isLoading: false,
  error: null,
  hasSubscription: false,
  subscriptionType: null,
  subscriptionData: null,

  setSubscriptionData: (subscription: activeSubscription) => {
    const updatedSubscription = {
      ...get().subscriptionData,
      ...subscription,
    };
    localStorage.setItem(SUBSCRIPTION, JSON.stringify(subscription));
  },
  fetchActiveSubscription: async () => {
    const defaultResponse: SubscriptionCheckResult = {
      status: false,
      message: "No active subscription",
    };

    try {
      const res = await subscriptionService.get("/subscriptions/active");
      console.log(res);
      if (res.status === 204 || !res.data) {
        set({ subscriptionData: null });
        return defaultResponse;
      }

      const activeSubscriptionData: activeSubscription = res.data;
      set({
        subscriptionData: activeSubscriptionData,
        subscription: activeSubscriptionData,
        hasSubscription: activeSubscriptionData.plan !== "free",
        subscriptionType: activeSubscriptionData.plan,
      });
      const usageCheckResult: SubscriptionCheckResult =
        checkSubscription("Invoices");
      return usageCheckResult;
    } catch (error) {
      set({ subscriptionData: null });
      return {
        status: false,
        message: "An error occurred while fetching the subscription.",
      };
    }
  },
  fetchListSubscription: async () => {
    set({ isLoading: true });
    try {
      const res = await subscriptionService.get("/subscriptions");
      set({
        subscriptionList: res.data,
        isLoading: false,
      });
    } catch (e: any) {
      set({ isLoading: false, error: e.message });
    }
  },
  incrementUsage: (actionType: string) => {
    set((state) => {
      //   state.subscription.usage[actionType] += 1;
      return state;
    });
  },
}));
