import React from "react";
import BinIcon from "../../../../assets/icons/BinIcon";
import ListInventoryBody from "../../list/ListInventoryBody";
import { VariationTableData } from "../../../../@types/inventory";
import { MobileTableBody } from "../../../common/MobileTableBody";
import { useUserStore } from "../../../../state";
import { isNotAccountant } from "../../../../utils/isNotAccountant";
import { ReusableTable } from "../../../table";

export interface ReusableTableColumn {
  key: string;
  dataIndex: string;
  title: string | null;
  label: string;
  className?: string;
}

interface VariationTableProps {
  data: VariationTableData[];
  isLoading: boolean;
  isArchived: boolean;
}
const VariationTable = ({
  isLoading,
  data,
  isArchived,
}: VariationTableProps) => {
  const { roles } = useUserStore();
  const [tableData, setTableData] = React.useState<typeof data>([]);

  React.useEffect(() => {
    setTableData(data);
  }, [data]);

  const tableHead: ReusableTableColumn[] = [
    // { key: 'index', dataIndex: 'index', title: '#', label: '#', className: 'text-start' },
    // { key: 'index', dataIndex: 'index', title: '#', label: '#', className: 'text-start' },
    { key: "name", dataIndex: "name", title: "Variation", label: "Variation" },
    {
      key: "type",
      dataIndex: "type",
      title: "Variation Type",
      label: "Variation Type",
    },
    {
      key: "price",
      dataIndex: "price",
      title: "Unit Price",
      label: "Unit Price",
    },
    {
      key: "quantity",
      dataIndex: "quantity",
      title: "Quantity",
      label: "Quantity",
    },
    { key: "bin", dataIndex: "bin", title: null, label: "", className: "" },
  ];

  const ArchivedTableHead: ReusableTableColumn[] = [
    // { key: 'index', dataIndex: 'index', title: '#', label: '#', className: 'text-start' },
    { key: "name", dataIndex: "name", title: "Variation", label: "Variation" },
    {
      key: "type",
      dataIndex: "type",
      title: "Variation Type",
      label: "Variation Type",
    },
    {
      key: "price",
      dataIndex: "price",
      title: "Unit Price",
      label: "Unit Price",
    },
    {
      key: "quantity",
      dataIndex: "quantity",
      title: "Quantity",
      label: "Quantity",
    },
  ];

  return (
    <>
      <div className="hidden md:block cursor-pointer font-medium">
        <ReusableTable
          data={tableData}
          isLoading={isLoading}
          columns={
            isNotAccountant(roles) && isArchived ? ArchivedTableHead : tableHead
          }
        />
      </div>

      <div className="md:hidden">
        <MobileTableBody
          payHistoryTable={false}
          data={tableData}
          isLoading={false}
          CustomerCard={false}
          variationCard={true}
        />
      </div>
    </>
  );
};

export default VariationTable;
