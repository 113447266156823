import React, { ChangeEvent } from "react";
import UploadIcon from "../../assets/icons/UploadIcon";
import { useTranslation } from "react-i18next";

interface FileUploadProps {
  onUpload: (file: File) => void;
}

const FileUploadComponent: React.FC<FileUploadProps> = ({ onUpload }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleSelectFile = () => {
    inputRef.current?.click();
  };
  const { t } = useTranslation("invoices");
  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      onUpload(e.target.files[0]);
    }
  };

  return (
    <>
      <button
        onClick={handleSelectFile}
        className="flex flex-col items-center gap-2  px-[13px] py-[8px] rounded-[4px]"
      >
        <p className="text-[#1D2939] font-semibold">{t("uploadDocument")}</p>
        <p className="text-[#1D2939] ">JPEG, JPG or PDF max 2mb</p>
      </button>
      <input
        className="hidden"
        type="file"
        ref={inputRef}
        accept=".jpg, .png, .jpeg, .pdf"
        onChange={handleUpload}
      />
    </>
  );
};

export default FileUploadComponent;
