import React, { useState } from "react";
import { CloseIcon } from "../../../../../assets/icons";

// interface AutomateReminderModalProps {
//   setShowSendModal: (showReminder: boolean) => void;
//   onSaveReminder: (data: ISetReminder) => void;
//   reminderType: 'before' | 'due' | 'after';
//   days: number;
// }

interface AutomateReminderModalProps {
  setShowSendModal: (value: boolean) => void;
  onSaveReminder: (data: {
    days: number;
    remindBy: "before" | "due" | "after";
    isActive: boolean;
    remindMe: boolean;
  }) => void;
  reminderType: "before" | "due" | "after"; // Expecting one of these
  days: number;
}

const AutomateViewInvoice = ({
  setShowSendModal,
  onSaveReminder,
  reminderType,
  days,
}: AutomateReminderModalProps) => {
  const [isNotified, setIsNotified] = useState(false);
  const [loading, setLoading] = useState(false);

  const [daysInput, setDaysInput] = useState<string>(days.toString());

  const handleDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDaysInput(e.target.value);
  };

  const handleReminderToggle = () => {
    setIsNotified(!isNotified);
  };

  const handleSave = () => {
    setLoading(true);

    const reminderData = {
      days: reminderType === "due" ? 0 : daysInput ? Number(daysInput) : 0,
      remindBy: reminderType,
      isActive: isNotified,
      remindMe: isNotified,
    };

    onSaveReminder(reminderData);

    setLoading(false);
    setShowSendModal(false);
  };

  return (
    <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh]">
      <div className="flex items-center justify-center md:items-center">
        <div className="overflow-y-auto overflow-x-hidden fixed md:top-[25%] w-full md:w-[500px] lg:w-[500px] p-6 bg-white rounded-[8px]">
          <div className="flex justify-between items-center cursor-pointer mb-2">
            <h1 className="text-[16px] md:text-[20px] text-[#292D32] leading-7 font-semibold">
              Automate Reminder
            </h1>
            <div onClick={() => setShowSendModal(false)}>
              <CloseIcon />
            </div>
          </div>
          <p className="text-gray-700 mb-4 text-[10px] lg:text-[12px]">
            Send pre-set reminders to clients{" "}
            {reminderType === "before"
              ? "before"
              : reminderType === "after"
                ? "after"
                : "on"}{" "}
            the due date.
          </p>
          <div className="flex space-x-4 mb-4">
            {reminderType !== "due" && (
              <input
                type="number"
                value={daysInput}
                onChange={handleDaysChange}
                placeholder="Days"
                className="p-4 border rounded-lg text-center text-gray-400 w-[160px] lg:w-[220px] text-[14px]"
              />
            )}
            <div className="border rounded-lg p-4 text-gray-700 w-[190px] lg:w-[220px] text-[10px]  text-center">
              {reminderType.charAt(0).toUpperCase() + reminderType.slice(1)} Due
              Date
            </div>
          </div>
          <div className="flex items-center mb-6 cursor-pointer">
            <input
              type="checkbox"
              checked={isNotified}
              onChange={handleReminderToggle}
              className="mr-2"
            />
            <label className="text-gray-700 text-[12px] lg:text-[14px]">
              Notify me when reminders are sent
            </label>
          </div>
          <button
            className={`w-full bg-[#044E97] text-white text-[14px] py-3 rounded-lg cursor-pointer ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? "Saving..." : "Automate Reminder"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AutomateViewInvoice;
