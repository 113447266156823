import React, { useState } from "react";
import {
  createBudget,
  getBudgetHistory,
  updateBudget,
} from "../../../backend-services";
import { validateBudgetForm } from "../../../modules/settings/settings";
import PageLoader from "../../common/PageLoader";
import { CreateBudget } from "./create";
import { ViewBudget } from "./view";
import { useUserStore } from "../../../state";
import { initialBudgetState } from "../../../constants";
import BudgetModal from "../../common/BudgetModal";
import { useTranslation } from "react-i18next";
import { getCurrentMonthData } from "../../../utils/DateTimeRange";

export const BudgetManager = () => {
  const { t } = useTranslation("settings");
  const [isLoading, setIsLoading] = React.useState(false);
  const [, setErrorMessage] = React.useState<string>("");
  const [formData, setFormData] = React.useState(initialBudgetState);
  const [validationResults, setValidationResults] =
    React.useState(initialBudgetState);
  const { user } = useUserStore();
  const budget = user.budget;
  const [showModal, setShowModal] = useState(false);

  const handleCreateBudget = async () => {
    setIsLoading(true);
    try {
      const monthYearPattern = /^(0[1-9]|1[0-2])-(19|20)\d\d$/;
      const isValidMonthYear = monthYearPattern.test(formData.monthYear);
      if (!isValidMonthYear) {
        setErrorMessage("Invalid monthYear format");
        return;
      }
      const data = {
        ...formData,
        allocatedBudget: Number(formData.allocatedBudget),
        monthYear: formData.monthYear,
      };
      await updateBudget(data);
    } catch (error) {
      setErrorMessage("Unable to create.");
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (budget) {
      const UpdatedBudget = getCurrentMonthData(budget);
      setFormData(UpdatedBudget);
    } else {
      setFormData(initialBudgetState);
    }
  }, [budget]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const errorFound = validateBudgetForm(formData, setValidationResults);
    if (!errorFound) {
      handleCreateBudget();
    }
    setIsLoading(false);
  };

  const renderBudget = () => {
    if (formData?.monthYear) {
      return (
        <ViewBudget
          monthYear={formData.monthYear}
          allocatedBudget={formData.allocatedBudget}
          setShowModal={setShowModal}
        />
      );
    } else {
      return <CreateBudget onSubmit={handleSubmit} isLoading={isLoading} />;
    }
  };

  return (
    <div className="bg-[#FFFFFF] w-full h-[801px] p-[5.7%] md:p-11 text-[#000000]">
      <h1 className="text-[16px] md:text-[20px] font-semibold">
        {t("Settings.expensebudget")}
      </h1>
      <p className="text-[14px] md:text-[16px]">
        {t("Settings.setamonthlybudget")}
      </p>

      <hr className="mt-[10px] mb-5 md:mt-[1.5em] md:mb-[30px] lg:w-full border border-[#000000] border-opacity-25" />

      {isLoading ? <PageLoader /> : <>{renderBudget()}</>}

      {showModal && <BudgetModal setShowModal={setShowModal} />}
    </div>
  );
};
