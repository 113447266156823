import { Table } from "flowbite-react";
import { SearchInput } from "../../SearchInput";
import * as React from "react";
import { ItemInput } from "../../../../@types";
import {
  CloseIconSmall,
  RemoveIcon,
  SearchIcon,
} from "../../../../assets/icons";
import { TableColumn } from ".";
import { useServiceSearch } from "../../../../hooks/service/useServiceSearch";
import { calculateAdjustedRate } from "../../../../utils";

export interface Props {
  item: ItemInput;
  columns: TableColumn[];
  index: number;
  isSearchInput: boolean;
  removeItem: (index: number) => void;
  updateError: (index: number, error: string[]) => void;
  updateCell: (
    rowIndex: number,
    columnId: string,
    value: string | number,
  ) => void;
  updateRow: (rowIndex: number, updatedRow: ItemInput) => void;
  errors: string[];
  selectedCurrencyLabel: string;
  isInvoice: boolean;
  items: ItemInput[];
  exchangeRate?: number;
  currency: string;
}

export interface LineItemRef {
  validate: () => boolean;
}

const ServiceLineItem: React.ForwardRefRenderFunction<LineItemRef, Props> = (
  {
    isSearchInput,
    columns,
    index,
    removeItem,
    updateRow,
    updateCell,
    item,
    errors,
    updateError,
    selectedCurrencyLabel,
    items,
    isInvoice,
    exchangeRate,
    currency,
  },
  ref,
) => {
  const handleUpdate = (field: keyof ItemInput, value: string | number) => {
    if (field === "duration" && typeof value === "string") {
      value = value.trim();
    }

    updateCell(index, field as string, value);

    if (errors && errors.includes(field as string)) {
      const newErrors = errors.filter((err) => err !== field);
      updateError(index, newErrors);
    }
  };

  const {
    data: services,
    isSearching: isServiceSearching,
    handleSearch: handleServiceSearch,
  } = useServiceSearch();

  const handleServiceSearchDebounced = React.useCallback(
    handleServiceSearch,
    [],
  );

  const handleItemSelect = React.useCallback(
    (itemData: Omit<ItemInput, "variation"> | null) => {
      if (itemData) {
        const isDuplicate = items.some(
          (existingItem) => existingItem.id === String(itemData.id),
        );
        if (isDuplicate) {
          updateError(index, ["duplicate"]);
          return;
        }

        const updatedDuration = String(itemData.duration || "");

        let updatedPrice = 0;
        if (Array.isArray(itemData.price)) {
          const nairaPriceObject = itemData.price.find(
            //@ts-ignore
            (entry) => entry.currency === "NGN",
          );
          if (nairaPriceObject) {
            //@ts-ignore
            updatedPrice = nairaPriceObject.amount;
          }
        } else if (typeof itemData.price === "number") {
          updatedPrice = itemData.price;
        }

        const adjustedPrice = calculateAdjustedRate(updatedPrice, exchangeRate);

        const updatedRow = {
          ...itemData,
          duration: updatedDuration,
          price: adjustedPrice,
          id: String(itemData.id),
          name: String(itemData.name),
        };
        updateRow(index, updatedRow);
      } else {
        updateRow(index, { id: "", name: "", duration: "", price: 0 });
      }
    },
    [index, updateRow, items, updateError, exchangeRate],
  );

  const isFirstItem = index === 0;
  const isLastItem = index === items.length - 1 && items.length > 1;

  const handleRemoveItem = () => {
    if (!isFirstItem) {
      removeItem(index);
    }
  };
  const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(false);
  const inputWidth = isSidebarCollapsed
    ? "biglg:w-[400px] " // 100% width when sidebar is collapsed
    : " md:w-[170px] lg:w-[200px] biglg:w-[360px] xl:w-[340px] 2xl:w-[400px] 3xl:w-[270px] bigmd:w-[160px] 4xl:w-[320px]";

  return (
    <Table.Row key={index} className="bg-white" style={{ minHeight: "50px" }}>
      {columns.map(({ label }, columnIndex) => (
        <Table.Cell
          key={columnIndex}
          className={`
            ${columnIndex === 0 ? "pr-[20px] border-r" : "border-r"}
            ${columnIndex === 0 ? "w-[100px]" : "w-[150px] md:w-[150px]"}
          `}
        >
          {columnIndex === 0 ? (
            isSearchInput ? (
              <div>
                <SearchInput
                  placeholder="Service Name"
                  data={services}
                  displayKey="name"
                  isLoading={isServiceSearching}
                  emptyMessage="No Items yet"
                  classNames={`placeholder:text-[#B4B4B4] placeholder:text-[13.25px] bg-white border rounded-md placeholder:text-[#A4A4A4] ${errors.includes("name") ? "border-red-500" : "border-[#A4A4A4]"}  ${inputWidth}`}
                  // value={item?.id}
                  value={item?.name || ""}
                  selected={item.id ? item : null}
                  clearIcon={CloseIconSmall}
                  clearIconClass="xl:mr-[1em] md:mr-[1.7em] lg:mr-[2.5em] biglg:mr-[2em] bigmd:mr-[4.3em] 2xl:mr-[3em] 3xl:mr-[7em] xl:mt-[0.9em] biglg:mt-[0.75em]"
                  handleChangeText={handleServiceSearchDebounced}
                  onSelect={handleItemSelect}
                  dropdownClassName="xl:w-[340px] lg:w-[220px] 2xl:w-[400px] md:w-[170px] biglg:w-[360px] bigmd:w-[160px] 3xl:w-[400px] 4xl:w-[320px] lg:[120px]"
                  dropdownStyle={{ maxHeight: "800px", overflowY: "auto" }}
                  searchKey="name"
                  icon={<SearchIcon />}
                />
              </div>
            ) : (
              <div>
                <input
                  type="text"
                  className={`bg-gray-100 placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4] placeholder:text-[13.25px] border-none md:w-[160px] lg:w-[220px] biglg:w-[360px] xl:w-[340px] 2xl:w-[400px] 3xl:w-[350px] bigmd:w-[160px] 4xl:w-[400px] ${
                    errors.includes("name") ? "border-red-500" : ""
                  } ${item?.name ? "focus:outline-none focus:border-[#138EFF] focus:ring-2 focus:ring-[#138EFF]" : ""}`}
                  placeholder="Enter Item Name"
                  value={item?.name || ""}
                  onChange={({ target }) => handleUpdate("name", target.value)}
                />
                {errors.includes("name") && (
                  <p className="text-red-500 text-xs mt-1">
                    Please enter a valid item name
                  </p>
                )}
              </div>
            )
          ) : columnIndex === columns.length - 2 && isInvoice ? (
            <input
              type="text"
              className="md:w-[110px] bigmd:w-[110px] lg:w-[180px] biglg:w-[220px] 2xl:w-[320px] 3xl:w-[360px] 4xl:w-[300px] xl:w-[250px] bg-gray-100 placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4]"
              readOnly
              placeholder="Price"
              value={
                !isNaN(Number(item?.price))
                  ? Number(item?.price).toFixed(2)
                  : ""
              }
            />
          ) : columnIndex === columns.length - 2 ? (
            <input
              type="text"
              className="md:w-[110px] bigmd:w-[110px] lg:w-[180px] biglg:w-[220px] 2xl:w-[320px] 3xl:w-[360px] 4xl:w-[300px] xl:w-[250px] bg-gray-100 placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4]"
              onChange={({ target }) => handleUpdate("price", target.value)}
              placeholder="Price"
              value={!isNaN(Number(item?.price)) ? Number(item?.price) : ""}
            />
          ) : columnIndex === columns.length - 1 && isInvoice ? (
            <div className="flex justify-center items-center">
              {!isFirstItem && !isLastItem && (
                <button
                  onClick={handleRemoveItem}
                  className="flex justify-center items-center"
                >
                  <RemoveIcon /> <span className="text-xl text-red "></span>
                </button>
              )}
            </div>
          ) : columnIndex === columns.length - 1 ? (
            <div className="flex justify-center items-center">
              {!isFirstItem && !isLastItem && (
                <button
                  onClick={handleRemoveItem}
                  className="flex justify-center items-center"
                >
                  <RemoveIcon /> <span className="text-xl text-red "></span>
                </button>
              )}
            </div>
          ) : // ) : (
          columnIndex === columns.length - 3 && isInvoice ? (
            <input
              type="text"
              min="0"
              step="0.01"
              className={`bg-gray-100 placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4] 3xl:w-[250px] 4xl:w-[300px] md:w-[110px] bigmd:w-[110px] lg:w-[180px] biglg:w-[220px] xl:w-[300px] 2xl:w-[320px] bigmd:w-[120px] ${
                errors.includes(label.toLowerCase()) ? "border-red-500" : ""
              }`}
              placeholder={columnIndex === 1 ? "Duration" : ""}
              //@ts-ignore
              value={
                columnIndex === 1
                  ? item?.duration !== undefined
                    ? String(item?.duration)
                    : ""
                  : item[label.toLowerCase()] || ""
              }
              onChange={({ target }) =>
                handleUpdate(label.toLowerCase(), target.value)
              }
            />
          ) : columnIndex === columns.length - 3 ? (
            <input
              type="number"
              className={`bg-gray-100 placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4] 3xl:w-[250px] 4xl:w-[300px] md:w-[110px] bigmd:w-[110px] lg:w-[180px] biglg:w-[220px] xl:w-[300px] 2xl:w-[320px] bigmd:w-[120px] ${
                errors.includes(label.toLowerCase()) ? "border-red-500" : ""
              }`}
              placeholder={columnIndex === 1 ? "Duration" : ""}
              //@ts-ignore
              value={item?.duration || ""}
              onChange={({ target }) => handleUpdate("duration", target.value)}
            />
          ) : null}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

export default React.forwardRef(ServiceLineItem);
