import React, { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ICreateExpenseData, ICreateSalesData } from "../../../@types";
import DateIcon, { SmallDateIcon } from "../../../assets/icons/Date";
import { Tooltip } from "../../../assets/icons/Tooltip";
import { InputComponent } from "../../core/Forms";
import { useTranslation } from "react-i18next";

interface InvoiceDateProps {
  formData: ICreateExpenseData;
  setFormData: React.Dispatch<React.SetStateAction<ICreateExpenseData>>;
  validationResults: ICreateExpenseData;
  toolTipText?: string;
  setValidationResults: React.Dispatch<
    React.SetStateAction<ICreateExpenseData>
  >;
  handleInputChange: (event: { target: { name: any; value: any } }) => void;
}

const ExpenseDate: React.FC<InvoiceDateProps> = ({
  formData,
  setFormData,
  validationResults,
  toolTipText,
  setValidationResults,
  handleInputChange,
}) => {
  const { t } = useTranslation("expenses");
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [expenseDate, setExpenseDate] = useState<Date>(new Date());
  const [showExpenseDateCalendar, setShowExpenseDateCalendar] = useState(false);

  const handleExpenseDateChange = (value: Date | Date[] | null) => {
    const today = new Date();
    if (value instanceof Date && value <= today) {
      setExpenseDate(value);
      setFormData({ ...formData, date: value.toISOString() });
      setValidationResults({ ...validationResults, date: "" });
    } else {
      setValidationResults({
        ...validationResults,
        date: "Please select a date on or before today",
      });
    }
    setShowExpenseDateCalendar(false);
  };

  return (
    <>
      {/* Desktop */}
      <div className="flex flex-col items-center mt-[3em] hidden lg:block md:block">
        <div className="flex justify-between xl:gap-[4.5em] biglg:gap-[6em] md:gap-[3em] md: ">
          <div>
            <div className="lg:mr-[10.6em] lg:ml-0  2xl:ml-0 biglg:ml-0 bigmd:ml-0 md:ml-0 mt-[13px] lg:mt-0 xl:mt-0 2xl:mt-0 biglg:mt-0 bigmd:mt-0 md:mt-0">
              <div className="flex gap-1">
                <label
                  htmlFor="expenseDate"
                  className="lg:text-[16px] md:text-[14px] text-[9px]  font-normal"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {t("budget.expenseDate")}
                </label>

                {toolTipText && (
                  <div
                    className="relative mt-1"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Tooltip />
                    {isHovered && (
                      <div className="absolute w-[204px] left-[-5.7em] z-10">
                        <div className="flex items-center justify-center">
                          <svg
                            width="18"
                            height="15"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 0L17.6603 15H0.339746L9 0Z"
                              fill="#FFF4C6"
                            />
                          </svg>
                        </div>
                        <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                          {toolTipText}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="relative lg:w-[332px] md:w-[310px] lg:mr-[-11.3em] md:mr-[-1em]">
                <InputComponent
                  type="date"
                  className="w-full bg-[#F4F7FF] pl-1 pr-10 whitespace-nowrap py-3 cursor-pointer border-[#ACD4FC] appearance-none"
                  placeholder="Issue Date"
                  value={formData.date}
                  name="date"
                  handleChange={handleInputChange}
                  error={validationResults.date}
                  restrictFutureDates={true}
                />
              </div>

              {validationResults.date && (
                <div className="mt-2 mr-[-9em]">
                  <span className="text-red-500">{validationResults.date}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="flex flex-col  mt-[3em] lg:hidden md:hidden justify-end items-end text-right mr-[-3em]">
        <div className="flex flex-col gap-[6em]">
          <div>
            <div className="ml-[3em] lg:ml-0 xl:ml-0 2xl:ml-0 biglg:ml-0 bigmd:ml-0 md:ml-0 mt-[13px] lg:mt-0 xl:mt-0 2xl:mt-0 biglg:mt-0 bigmd:mt-0 md:mt-0">
              <div className="flex gap-1">
                <label
                  htmlFor="issueDate"
                  className="lg:text-[16px] text-[9px]  font-normal 375:ml-[0.3em] 414:ml-[1em] 390:ml-[0.6em] 430:ml-[] 412:ml-[0.6em] 360:ml-[1em]"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {t("budget.expenseDate")}
                </label>
                {toolTipText && (
                  <div
                    className="relative pb-2"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Tooltip />
                    {isHovered && (
                      <div className="absolute w-[204px] left-[-5.7em] z-10">
                        <div className="flex items-center justify-center">
                          <svg
                            width="18"
                            height="15"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 0L17.6603 15H0.339746L9 0Z"
                              fill="#FFF4C6"
                            />
                          </svg>
                        </div>
                        <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                          {toolTipText}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div>
                <InputComponent
                  type="date"
                  className="390:w-[120px] 375:w-[100px] 360:w-[90px] bg-[#F4F7FF] pl-1 pr-2 whitespace-nowrap py-3 cursor-pointer flex border-[#ACD4FC] "
                  placeholder=" Date"
                  value={formData.date}
                  name="date"
                  handleChange={handleInputChange}
                  error={validationResults.date}
                  restrictPreviousDates={false}
                />
              </div>
              {validationResults.date && (
                <div className="mt-2 ml-4">
                  <span className="text-red-500">{validationResults.date}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpenseDate;
