import React, { ReactNode } from "react";
import cx from "classnames";

interface SubmenuTab {
  label: string;
  tab: string;
  onClick: () => void;
}

interface Tab {
  label: string;
  tab: string;
  icon: ReactNode;
  onClick: () => void;
  submenu?: SubmenuTab[];
}

interface PageSubmenuProps {
  tabs: Tab[];
  tabContainerClass?: string;
  activeTab: string;
  urlTab?: string;
  onTabChange: (tab: string) => void;
}

const PageSubmenu: React.FC<PageSubmenuProps> = ({
  tabs,
  tabContainerClass,
  activeTab,
}) => {
  const [activeSubmenu, setActiveSubmenu] = React.useState("");

  const handleTabClick = (tab: string) => {
    if (activeSubmenu === tab) {
      setActiveSubmenu("");
    } else {
      setActiveSubmenu(tab);
    }
  };

  const handleSubmenuClick = (submenuTab: string) => {
    setActiveSubmenu(submenuTab);
  };

  return (
    <div
      className={cx(
        "flex flex-wrap items-center gap-x-7 gap-y-3 h" + tabContainerClass,
      )}
    >
      {tabs.map((tab, i) => (
        <div key={`tap-${tab.tab}`} className="text-[14px]">
          <button
            className={cx({
              "font-medium justify-center items-center flex border-b-2 border-[#044E97] text-[#044E97] text-[14px]":
                activeTab === tab.tab && !tab.submenu,
              "font-medium justify-center items-center flex border-b-2 border-[#044E97] text-[#044E97] text-[14px]  ":
                activeTab === tab.tab && tab.submenu,
              "": i == 1,
            })}
            onClick={tab.onClick}
          >
            <div className="flex items-end font-medium text-[10px] sm:text-[16px] gap-[6px]">
              {tab.icon}
              <div className="whitespace-nowrap text-[10px] sm:text-[16px]">
                {tab.label}
              </div>
            </div>
          </button>

          {tab.submenu && activeTab === tab.tab && (
            <div className="ml-[194px]">
              {tab.submenu.map((submenuItem) => (
                <button
                  key={`submenu-${submenuItem.tab}`}
                  className={cx({
                    "font-medium w-[194px] h-[58px] p-[16px] justify-center items-center flex rounded-[10px] bg-[#E3EFFC] text-[#044E97] mt-2":
                      activeSubmenu === submenuItem.tab,
                  })}
                  onClick={() => handleSubmenuClick(submenuItem.tab)}
                >
                  <div className="flex p-[26px] font-medium leading-5 items-center text-[16px] gap-[7px]">
                    <div className="w-5 h-5" />
                    <div className="whitespace-nowrap">{submenuItem.label}</div>
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export { PageSubmenu };
