import React from "react";

interface FullScreenOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const FullScreenOverlay: React.FC<FullScreenOverlayProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  if (!isOpen) return null;

  return (
    <div>
      <div className="hidden lg:block md:block ">
        <div className=" fixed top-0 left-0 w-screen h-screen bg-gray-800 bg-opacity-80 flex items-center justify-center z-10 ">
          <div className="bg-white p-4 rounded-lg shadow-lg">{children}</div>
        </div>
      </div>
      {/* Mobile Overlay */}
      <div className="lg:hidden md:hidden fixed inset-0 bg-black bg-opacity-50 z-50 overflow-y-auto">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl relative mx-auto mt-4 mb-4">
          {/* Optional Close Button */}
          {children}
        </div>
      </div>
    </div>
  );
};

export default FullScreenOverlay;
