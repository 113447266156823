import React, { ReactNode, useEffect } from "react";

const Modal = ({
  children,
  visible,
  onClose,
}: {
  children: ReactNode;
  visible: boolean;
  onClose: () => void;
}) => {
  useEffect(() => {
    if (visible) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [visible]);

  if (!visible) return null;

  return (
    <div className="fixed inset-0 w-full h-full bg-gray-800 bg-opacity-80 flex items-center justify-center z-50">
      <div className="lg:bg-[#F2F9FD] bg-[#F2F9FD] p-4 rounded-lg shadow-lg z-50">
        {children}
      </div>
    </div>
  );
};

export { Modal };
