import dayjs from "dayjs";
import { IExpense } from "../../@types";
import { getExpenseType } from "../invoice";
import { toSentenceCase } from "../toSentenceCase";
import { getDaySuffix } from "../invoice-list";

export const columns = [
  {
    key: "name",
    label: "Vendor",
    className: "border-x border-x-gray-200",
    width: 23,
  },
  {
    key: "category",
    label: "Expense Category",
    className: "border-x border-x-gray-200",
    width: 23,
  },
  {
    key: "date",
    label: "Date",
    className: "border-x border-x-gray-200",
    width: 13,
  },
  {
    key: "paymentMethod",
    label: "Payment Method",
    className: "border-x border-x-gray-200",
    width: 18,
  },
  {
    key: "type",
    label: "Type",
    className: "border-x border-x-gray-200",
    width: 12,
  },
  {
    key: "amount",
    label: "Amount",
    className: "border-x border-x-gray-200",
    width: 12,
  },
];

export const renderExpensesData = (expenses: IExpense[]) => {
  return expenses.map((expense: IExpense, index: number) => {
    const type = getExpenseType(expense.isBillable);
    return {
      createdAt: (
        <a href={`/expenses/${expense.id}/view`} className="xl:text-violet-800">
          {dayjs(expense.createdAt).format("MM/DD/YYYY")}
        </a>
      ),
      category: expense.category,
      companyId: expense.companyId,
      id: expense.id,
      name: (
        <a href={`/expenses/${expense.id}/view`} className="text-violet-800">
          {expense.vendorName}
        </a>
      ),
      paymentMethod: toSentenceCase(expense.paymentMethod?.toString() || ""),
      type: type,

      vendor: expense.vendorName,
      amount: `₦${expense.amount?.toLocaleString()}`,
      date: `${getDaySuffix(dayjs(expense.createdAt).date())} ${dayjs(expense.createdAt).format("MMM")}, ${dayjs(
        expense.createdAt,
      ).format("YYYY")}`,
    };
  });
};
