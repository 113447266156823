import React, { useEffect } from "react";
import TopSellingEmptyState from "../../assets/icons/TopSellingEmptyState";
import { useDashboardStats } from "../../hooks/useDashboardStats";
import { ImSpinner } from "react-icons/im";
import { formatMoney } from "../../utils";
import { useNavigate } from "react-router-dom";

interface mostBookedService {
  id: string;
  serviceName: string;
  duration: number;
  amount: number;
}

const MostBookedServices = () => {
  const navigate = useNavigate();
  const { fetchServices, mostBookedServices, loadingServices } =
    useDashboardStats();

  useEffect(() => {
    fetchServices();
  }, []);

  return (
    <div className="rounded-lg bg-white py-3 lg:py-6 w-full">
      {loadingServices ? (
        <div className="h-[300px] w-full flex justify-center items-center">
          <ImSpinner className="h-16 w-20 mx-auto text-slate-300 animate-spin" />
        </div>
      ) : (
        <>
          {mostBookedServices && mostBookedServices.length > 0 ? (
            <div className="w-full">
              <h2 className="px-3 md:px-6 text-[#1D2939] text-[16px] font-medium leading-6">
                Most Booked Services
              </h2>
              <hr className="border border-[#EAECF0] w-full mt-3 mb-2" />
              <ul className="space-y-2 px-3 md:px-6">
                {mostBookedServices.map((service: mostBookedService, index) => (
                  <li
                    id={service.id}
                    key={index}
                    className="flex gap-[12%] items-center py-2 text-[16px]"
                  >
                    <div className="text-gray-700 w-full font-medium">
                      {service?.serviceName}
                    </div>
                    <div className="flex items-center justify-between w-full font-semibold">
                      <span className="text-gray-600 leading-6">{`${service.duration}hrs`}</span>
                      <span className="text-[#000000] leading-6">
                        {formatMoney(service.amount)}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="bg-white w-full p-3">
              <h2 className="text-[#1D2939] text-[16px] font-medium leading-6">
                Most Booked Services
              </h2>
              <div className="flex flex-col justify-center items-center mt-5 gap-5">
                <TopSellingEmptyState />
                <p className="text-[#344054] text-[14px] font-medium leading-5 text-center">
                  Start managing your business. <br /> What will you like to do
                  ?{" "}
                </p>
                <div
                  onClick={() => navigate("/inventories")}
                  className="px-4 py-2 text-[#044E97] text-[14px] font-medium leading-5 border border-[#044E97] rounded-[4px] cursor-pointer"
                >
                  Create a service
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MostBookedServices;
