import * as React from "react";

interface BadgeProps {
  label: string;
  color:
    | "cancelled"
    | "paid"
    | "pending"
    | "draft"
    | "refunded"
    | "overdue"
    | "expired"
    | "secondary"
    | "active"
    | "quote";
}

const Badge: React.FC<BadgeProps> = (props) => {
  const getColor = React.useCallback((): string => {
    switch (props.color) {
      case "cancelled":
        return "#FB0303";
      case "paid":
        return "#088306";
      case "pending":
        return "#FF6702";
      case "draft":
        return "#4E4E4E";
      case "refunded":
        return "#044E97";
      case "overdue":
        return "#FB0303";
      case "active":
        return "#C89000";
      case "expired":
        return "#C800A8";
      case "quote":
        return "#B36100";
      case "secondary":
        return "";
      default:
        return props.color;
    }
  }, [props.color]);

  return (
    <span
      className={`bg-opacity-20 px-2 py-1 rounded-[4px] flex justify-center bg-[${getColor()}] text-[${getColor()}]`}
    >
      {props.label}
    </span>
  );
};

export { Badge };
