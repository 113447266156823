import React from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { deleteService } from "../../../backend-services/service";
import { useTranslation } from "react-i18next";

interface DeleteServicePromptProps {
  setShowPrompt: (show: boolean) => void;
  id?: string;
}

const DeleteServicePrompt = ({
  setShowPrompt,
  id,
}: DeleteServicePromptProps) => {
  const { t } = useTranslation("services");
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const navigate = useNavigate();

  const handleServiceDelete = async (id: string | undefined) => {
    if (id) {
      setIsDeleting(true);
      try {
        const res = await deleteService(id);
        setIsDeleting(false);
        setShowPrompt(false);
        navigate("/services");
        toast.success("Service Deleted Successfully");
      } catch (error: any) {
        setErrorMessage("Unable to Delete Service.");
        setIsDeleting(false);
        if (
          error.response &&
          (error.response.status === 400 || error.response.status === 500)
        ) {
          navigate("/error");
        }
      }
    }
  };

  return (
    <>
      <div className="h-screen pl-[1em] pr-[2em]">
        <h1 className="font-semibold text-[25px]">Delete Service</h1>
        <div className="flex justify-center mt-[13px] ">
          <h1 className="">
            {t("service.deletePrompt")}
            <br />
            <span> {t("service.deletePrompt1")} </span>
          </h1>
        </div>
        <div
          role="form"
          className="flex w-full gap-6 mt-8"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="w-1/2">
            <button
              onClick={() => setShowPrompt(false)}
              className="w-full rounded-[4px] h-[48px] bg-[#FFFFFF] text-[#044E97] font-semibold border border-blue-500"
            >
              {t("service.cancel")}
            </button>
          </div>
          <div className="w-1/2">
            <button
              onClick={() => handleServiceDelete(id)}
              className="w-full rounded-[4px] h-[48px] bg-[#FB0303] text-white  font-semibold"
            >
              {isDeleting ? "Deleting..." : "Delete"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { DeleteServicePrompt };
