import React, { useState, useCallback, useEffect } from "react";
import { InputComponent, DropdownComponent } from "../../core/Forms";
import { Button, SearchInput } from "../../common";
import { BankData } from "../../../types";
import { clearFieldError } from "../../../utils/handleErrors";
import {
  ValidationError,
  validateBankForm,
} from "../../../modules/settings/settings";
import { useBankAccountSearch } from "../../../hooks/bankAccount/useBankSearch";
import { Bank } from "../../../@types";
import { CloseIcon, SearchIcon } from "../../../assets/icons";

interface BankAccountFormProps {
  initialData: BankData;
  edit?: boolean;
  isLoading?: boolean;
  onSubmit: (data: BankData) => Promise<JSX.Element | void>;
  bankName?: Bank | null;
  onClose?: () => void;
  title: string;
  fromToDos?: boolean;
}

export const BankAccountForm: React.FC<BankAccountFormProps> = ({
  initialData,
  edit = false,
  onSubmit,
  onClose,
  isLoading,
  title,
  fromToDos,
}) => {
  const [formData, setFormData] = useState(initialData);
  const [validationResults, setValidationResults] = useState<ValidationError>({
    bankName: "",
    accountNumber: "",
    accountName: "",
  });
  const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    data: bankAccount,
    isSearching,
    handleSearch: handleBankAccountSearch,
  } = useBankAccountSearch();

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      if (name === "accountNumber") {
        const numericValue = value.replace(/\D/g, "");
        const restrictedValue = numericValue.slice(0, 10);

        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: restrictedValue,
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }

      clearFieldError(name, validationResults, setValidationResults);
    },
    [formData, validationResults],
  );
  const buttonText = edit ? "Save Changes" : fromToDos ? "Save" : "Add ";

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      const isValid = validateBankForm(formData, setValidationResults);
      if (isValid) {
        setLoading(true);
        try {
          await onSubmit(formData);
        } finally {
          setLoading(false);
        }
      }
    },
    [formData, onSubmit, setValidationResults],
  );

  const handleSetBankAccount = (selectedBank: Bank | null) => {
    if (selectedBank) {
      setFormData((prevData) => ({
        ...prevData,
        bankName: selectedBank.name,
      }));
      setSelectedBank(selectedBank);
      setValidationResults((prevErrors) => ({
        ...prevErrors,
        bankName: "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        bankName: "",
      }));
      setSelectedBank(null);
    }
    clearFieldError("selectedBank", validationResults, setValidationResults);
  };

  const selected = selectedBank
    ? { name: selectedBank.name, slug: selectedBank.name }
    : null;

  return (
    <form onSubmit={handleSubmit}>
      <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh]">
        <div className="hidden md:flex justify-center items-center h-full w-full">
          <div className="text-[#212121] w-[300px] md:w-[521px] md:p-5 bg-white rounded-3xl">
            <div className="flex items-center justify-between mb-5 md:mb-8">
              <h3 className="text-[18px] md:text-[24px] font-medium">
                {title}
              </h3>

              {!fromToDos && (
                <button
                  aria-label="Close"
                  className="flex items-center justify-center bg-[#F4F7FF] h-11 w-11 pr-[1em]"
                  type="button"
                  onClick={onClose}
                >
                  <CloseIcon color="#212121" />
                </button>
              )}
            </div>
            <div className="space-y-4 md:space-y-5">
              <div className=" w-full">
                <p className="text-[14px] leading-5 text-[#475467]">
                  Bank Name
                </p>
                <SearchInput
                  data={bankAccount}
                  placeholder="Bank Name"
                  displayKey="name"
                  isLoading={isSearching}
                  value={
                    formData?.bankName
                      ? formData.bankName
                      : initialData?.bankName
                  }
                  key={initialData.bankName}
                  clearIconClass="mr-[0.5em] md:mt-[0.8em]"
                  dropdownClassName="w-full"
                  dropdownStyle={{
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                  emptyMessage={"This bank is not found"}
                  classNames="w-full border border-gray-300 bg-white h-10 md:h-12 placeholder:text-[16px] placeholder:text-[#98A2B3] rounded-lg focus:outline-none focus:shadow-outline appearance-none lg:focus:ring-0 leading-normal"
                  onSelect={handleSetBankAccount}
                  handleChangeText={handleBankAccountSearch}
                  searchKey={"name"}
                  //@ts-ignore
                  selected={selected}
                  icon={<SearchIcon />}
                  clearIcon={CloseIcon}
                />
                {validationResults.bankName && (
                  <div className="text-red-500">
                    {validationResults.bankName}
                  </div>
                )}
              </div>
              <div>
                <div className="flex gap-1 items-center text-[14px] md:text-[16px]">
                  <p className="text-[14px] leading-5 text-[#475467]">
                    Account Number
                  </p>
                  <p className="leading-5 text-[#98A2B3]">(Required)</p>
                </div>
                <InputComponent
                  placeholder="Account Number"
                  name="accountNumber"
                  type="number"
                  value={formData.accountNumber}
                  handleChange={handleInputChange}
                  className="w-full h-10 md:h-12 placeholder:text-[16px] placeholder:text-[#98A2B3] rounded-lg border-[#D0D5DD]"
                />
                {validationResults.accountNumber && (
                  <div className="text-red-500">
                    {validationResults.accountNumber}
                  </div>
                )}
              </div>
              <div>
                <div className="flex gap-1 items-center text-[14px] md:text-[14px]">
                  <p className="leading-5 text-[#475467]">Account Name</p>
                  <p className="leading-5 text-[#98A2B3]">(Required)</p>
                </div>
                <InputComponent
                  placeholder="Account Name"
                  name="accountName"
                  type="text"
                  value={formData.accountName}
                  handleChange={handleInputChange}
                  className="w-full h-10 md:h-12 placeholder:text-[16px] placeholder:text-[#98A2B3] rounded-lg border-[#D0D5DD]"
                />
                {validationResults.accountName && (
                  <div className="text-red-500">
                    {validationResults.accountName}
                  </div>
                )}
              </div>
            </div>

            <div className="w-full gap-[5%] flex justify-between mt-8 md:mt-10">
              <button
                className={`text-[#044E97] border ${
                  fromToDos ? "border-[#EAECF0]" : "border-[#044E97]"
                } w-full h-12 rounded-md font-semibold`}
                onClick={onClose}
              >
                Cancel
              </button>
              <Button
                type="submit"
                isLoading={loading}
                variant={fromToDos ? "secondary" : "primary"}
                disabled={loading || isLoading}
                font="semibold"
                size="full"
              >
                {loading ? "Loading..." : buttonText}
              </Button>
            </div>
          </div>
        </div>

        <div
          className={`md:hidden fixed bottom-0 w-full z-50 px-4 p-4 pt-6 bg-white rounded-t-[16px]`}
        >
          <div className="text-[#212121]">
            <div className="flex items-center justify-between mb-5 md:mb-10">
              <h3 className="text-[18px] md:text-[24px] font-medium">
                {title}
              </h3>

              <button
                aria-label="Close"
                className="flex items-center justify-center bg-[#F4F7FF] h-11 w-11 pr-[1em]"
                type="button"
                onClick={onClose}
              >
                <CloseIcon color="#212121" />
              </button>
            </div>
            <div className="space-y-4 md:space-y-6">
              <div className=" w-full">
                <p className="text-[14px] leading-5 text-[#475467]">
                  Bank Name
                </p>
                <SearchInput
                  data={bankAccount}
                  placeholder="Search Bank Account"
                  displayKey="name"
                  isLoading={isSearching}
                  value={
                    formData?.bankName
                      ? formData.bankName
                      : initialData?.bankName
                  }
                  key={initialData.bankName}
                  clearIconClass="mr-[0.5em] md:mt-[0.8em]"
                  dropdownClassName="w-full"
                  dropdownStyle={{
                    maxHeight: "150px",
                    overflowY: "auto",
                  }}
                  emptyMessage={"This bank is not found"}
                  classNames="w-full border border-gray-300 bg-white h-10 md:h-12 placeholder:text-[15px] rounded focus:outline-none focus:shadow-outline appearance-none lg:focus:ring-0 leading-normal"
                  onSelect={handleSetBankAccount}
                  handleChangeText={handleBankAccountSearch}
                  searchKey={"name"}
                  //@ts-ignore
                  selected={selected}
                  icon={<SearchIcon />}
                  clearIcon={CloseIcon}
                />
                {validationResults.bankName && (
                  <div className="text-red-500">
                    {validationResults.bankName}
                  </div>
                )}
              </div>
              <div>
                <div className="flex justify-between items-center text-[14px] md:text-[16px] mb-1 md:mb-2">
                  <p> Account Number</p>
                  <p className="text-[#E02523]">**Required</p>
                </div>
                <InputComponent
                  placeholder="Account Number"
                  name="accountNumber"
                  type="number"
                  value={formData.accountNumber}
                  handleChange={handleInputChange}
                  className="w-full h-10 md:h-12 rounded placeholder:text-[15px]"
                />
                {validationResults.accountNumber && (
                  <div className="text-red-500">
                    {validationResults.accountNumber}
                  </div>
                )}
              </div>
              <div>
                <div className="flex justify-between items-center text-[14px] md:text-[16px] mb-1 md:mb-2">
                  <p> Account Name</p>
                  <p className="text-[#E02523]">**Required</p>
                </div>
                <InputComponent
                  placeholder="Account Name"
                  name="accountName"
                  type="text"
                  value={formData.accountName}
                  handleChange={handleInputChange}
                  className="w-full h-10 md:h-12 rounded placeholder:text-[15px]"
                />
                {validationResults.accountName && (
                  <div className="text-red-500">
                    {validationResults.accountName}
                  </div>
                )}
              </div>
            </div>

            <div className="w-full gap-[5%] flex justify-between mt-8 md:mt-10">
              <button
                className="text-[#044E97] border border-[#044E97] w-full h-12 rounded-md font-semibold "
                onClick={onClose}
              >
                Cancel
              </button>
              <Button
                type="submit"
                isLoading={loading}
                variant={fromToDos ? "secondary" : "primary"}
                disabled={loading || isLoading}
                font="semibold"
                size="full"
              >
                {loading ? "Loading..." : buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default BankAccountForm;
