import React from "react";
import { PreviewModal } from "../PreviewModal";
import MobilePreviewModal from "../MobilePreviewModal";
import { PreviewExpense } from "../../../@types";
import MobileUploadExpense from "../MobileUploadExpense";
import UploadExpense from "../UploadExpense";

interface ExpenseUploadCompProps {
  showUploadPopup: boolean;
  setShowUploadPopup: (showUploadPopup: boolean) => void;
}

const ExpenseUploadComp: React.FC<ExpenseUploadCompProps> = ({
  showUploadPopup,
  setShowUploadPopup,
}) => {
  const [preview, setPreview] = React.useState<boolean>(false);
  const [expenseData, setExpenseData] = React.useState<
    PreviewExpense | undefined
  >();

  const handleNewUploadExpense = (isUploadedPayment: boolean) => {
    setShowUploadPopup(false);
  };

  const closeUploadModal = () => {
    setShowUploadPopup(!showUploadPopup);
  };

  return (
    <>
      <div className="hidden md:block">
        {preview && (
          <PreviewModal setPreview={setPreview} expenseData={expenseData} />
        )}
      </div>
      <div className="md:hidden">
        {preview && (
          <MobilePreviewModal
            setPreview={setPreview}
            expenseData={expenseData}
          />
        )}
      </div>

      {showUploadPopup && (
        <MobileUploadExpense
          onRecordEntity={handleNewUploadExpense}
          paymentType="expenses"
          closeModal={closeUploadModal}
          preview={preview}
          setPreview={setPreview}
          setExpenseData={setExpenseData}
          setShowPopup={setShowUploadPopup}
        />
      )}

      {showUploadPopup && (
        <UploadExpense
          onRecordEntity={handleNewUploadExpense}
          paymentType="expenses"
          closeModal={closeUploadModal}
          preview={preview}
          setPreview={setPreview}
          setExpenseData={setExpenseData}
          setShowPopup={setShowUploadPopup}
        />
      )}
    </>
  );
};

export default ExpenseUploadComp;
