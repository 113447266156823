import { Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
import Root from "../pages/root";
import * as Pages from "../pages";
import {
  ListServicePage,
  CreateServicePage,
  ViewServicePage,
  Settings,
  WelcomePage,
  ErrorPage,
  UnauthorizedPage,
} from "../pages";
import React, { useEffect } from "react";
import ReportPage from "../pages/reports/ReportsPage";
import { CreateInventoryPage, ListInventoryPage } from "../pages";
import SubscriptionPage from "../pages/Subscription";
import SubscriptionProcess from "../pages/Subscription/process";
import { ArchivePage } from "../components/inventory/archive/archive";
import { ProtectedRoute } from "../middleware/ProtectedRoute";
import { USER_ROLE } from "../constants";
import ProfilePage from "../pages/profile/profile";
import UserManagementPage from "../pages/userManagement/userManagement";
import RestockPage from "../pages/inventories/restock";
import FreeInvoiceToolSetup from "../pages/freeInvoiceSetup";
import ViewInventoryPage from "../pages/inventories/view";

interface RouterProps {}

const Router: React.FC<RouterProps> = () => {

  return (
      <Routes>
        {/* AUTHENTICATION ROUTES */}
        <Route path="/" element={<Root />}>
          <Route index element={<Pages.AuthPage />} />
          <Route path="register" element={<Pages.AuthPage />} />
          <Route path="login" element={<Pages.AuthPage />} />
          <Route
            path="forget-password"
            element={<Pages.ForgetPasswordPage />}
          />
          <Route path="reset-password" element={<Pages.ResetPasswordPage />} />
          <Route path="new-password" element={<Pages.NewPasswordPage />} />
          <Route
            path="confirm-account"
            element={<Pages.ConfirmAccountPage />}
          />
          <Route path="new-user" element={<FreeInvoiceToolSetup />} />
          <Route
            path="change-password"
            element={<Pages.ChangePasswordPage />}
          />
        </Route>

        {/* ONBOARDING ROUTES */}

        <Route path="/new-user">
          <Route path="" element={<FreeInvoiceToolSetup />} />
        </Route>

        {/* DASHBOARD ROUTES */}
        <Route
          path=""
          element={<DashboardLayout />}
          errorElement={
            <Pages.ErrorPage
              statusCode={500}
              errorTitle="Internal Server Error"
              errorText="Oops seems like something went wrong. We’re working on it."
              actionButton={<button>Go back</button>}
            />
          }
        >
          <Route path="/dashboard">
            <Route
              path=""
              element={
                
                  <Pages.DashboardPage />
                
              }
            />
          </Route>

          <Route path="/operator-dashboard">
            <Route
              path=""
              element={
                <ProtectedRoute
                  requiredRoles={[USER_ROLE.ACCOUNTANT, USER_ROLE.OPERATOR]}
                >
                  <Pages.OperatorWelcomePage />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* HomePage Route */}
          <Route path="/welcomePage">
            <Route path="" element={<WelcomePage />} />
          </Route>

          {/* INVOICE ROUTES */}
          <Route path="invoices">
            <Route path="" element={<Pages.ListInvoicePage />} />
            <Route
              path="create"
              element={
                <ProtectedRoute
                  requiredRoles={[USER_ROLE.ADMIN, USER_ROLE.OPERATOR]}
                >
                  <Pages.CreateInvoicePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="createFreeform"
              element={
                <ProtectedRoute
                  requiredRoles={[USER_ROLE.ADMIN, USER_ROLE.OPERATOR]}
                >
                  <Pages.InvoiceFreeformPage />
                </ProtectedRoute>
              }
            />
            <Route path=":invoiceNo/view" element={<Pages.ViewInvoicePage />} />
            <Route
              path="send"
              element={
                <ProtectedRoute
                  requiredRoles={[USER_ROLE.ADMIN, USER_ROLE.OPERATOR]}
                >
                  <Pages.SendInvoicePage />
                </ProtectedRoute>
              }
            />

            <Route
              path=":invoiceNo"
              element={
                <ProtectedRoute
                  requiredRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.OPERATOR,
                    USER_ROLE.ACCOUNTANT,
                  ]}
                >
                  <Pages.DraftInvoicePage />
                </ProtectedRoute>
              }
            />
            {/* Freeform create route with invoiceId */}
            <Route
              path=":invoiceNo/freeform"
              element={
                <ProtectedRoute
                  requiredRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.OPERATOR,
                    USER_ROLE.ACCOUNTANT,
                  ]}
                >
                  <Pages.DraftFreeformInvoicePage />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* QUOTE ROUTES */}
          <Route path="quotes">
            <Route path="" element={<Pages.ListQuotePage />} />
            <Route
              path="create"
              element={
                <ProtectedRoute
                  requiredRoles={[USER_ROLE.ADMIN, USER_ROLE.OPERATOR]}
                >
                  <Pages.CreateQuotePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="createFreeform"
              element={
                <ProtectedRoute
                  requiredRoles={[USER_ROLE.ADMIN, USER_ROLE.OPERATOR]}
                >
                  <Pages.QuoteFreeformPage />
                </ProtectedRoute>
              }
            />
            <Route
              path=":quoteNo/view"
              element={
                <ProtectedRoute
                  requiredRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.OPERATOR,
                    USER_ROLE.ACCOUNTANT,
                  ]}
                >
                  <Pages.ViewQuotePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="edit/:quoteNo"
              element={
                <ProtectedRoute
                  requiredRoles={[USER_ROLE.ADMIN, USER_ROLE.OPERATOR]}
                >
                  <Pages.EditQuotePage />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* EXPENSES ROUTES  */}
          <Route path="expenses">
            <Route path="" element={<Pages.ViewExpensePage />} />
            <Route
              path="create"
              element={
                <ProtectedRoute
                  requiredRoles={[USER_ROLE.ADMIN, USER_ROLE.OPERATOR]}
                >
                  <Pages.CreateNewExpensePage />
                </ProtectedRoute>
              }
            />
            <Route
              path=":expenseId/view"
              element={
                <ProtectedRoute
                  requiredRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.OPERATOR,
                    USER_ROLE.ACCOUNTANT,
                  ]}
                >
                  <Pages.ViewExpense />
                </ProtectedRoute>
              }
            />

            <Route
              path="edit/:expenseId"
              element={
                <ProtectedRoute
                  requiredRoles={[USER_ROLE.ADMIN, USER_ROLE.OPERATOR]}
                >
                  <Pages.EditExpensePage />
                </ProtectedRoute>
              }
            />
            <Route path="test/expense" element={<Pages.ViewExpense />} />
          </Route>

          {/* CUSTOMERS ROUTES */}
          <Route path="customers">
            <Route path="" element={<Pages.ViewCustomersPages />} />
            <Route path=":customerId/view" element={<Pages.ViewCustomers />} />
          </Route>

          {/* INVENTORY ROUTES */}

          <Route path="inventories">
            <Route path="" element={<ListInventoryPage />} />
            <Route
              path=":inventoryId/view"
              element={
                <ProtectedRoute
                  requiredRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.OPERATOR,
                    USER_ROLE.ACCOUNTANT,
                  ]}
                >
                  <ViewInventoryPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="create"
              element={
                <ProtectedRoute
                  requiredRoles={[USER_ROLE.ADMIN, USER_ROLE.OPERATOR]}
                >
                  <CreateInventoryPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="archive"
              element={
                <ProtectedRoute requiredRoles={[USER_ROLE.ADMIN]}>
                  <ArchivePage />
                </ProtectedRoute>
              }
            />

            <Route
              path=":restockId/restock"
              element={
                <ProtectedRoute
                  requiredRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.OPERATOR,
                    USER_ROLE.ACCOUNTANT,
                  ]}
                >
                  <RestockPage />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* SERVICE ROUTES */}
          <Route path="services">
            <Route
              path=""
              element={
                <ProtectedRoute
                  requiredRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.OPERATOR,
                    USER_ROLE.ACCOUNTANT,
                  ]}
                >
                  <ListServicePage />
                </ProtectedRoute>
              }
            />
            <Route
              path=":serviceId/view"
              element={
                <ProtectedRoute
                  requiredRoles={[
                    USER_ROLE.ADMIN,
                    USER_ROLE.OPERATOR,
                    USER_ROLE.ACCOUNTANT,
                  ]}
                >
                  <ViewServicePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="create"
              element={
                <ProtectedRoute
                  requiredRoles={[USER_ROLE.ADMIN, USER_ROLE.OPERATOR]}
                >
                  <CreateServicePage />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* shipping ROUTES */}

          {/* SETTINGS ROUTES */}
          <Route
            path="settings"
            element={
              <ProtectedRoute
                requiredRoles={[USER_ROLE.ADMIN, USER_ROLE.OPERATOR]}
              >
                <Settings />
              </ProtectedRoute>
            }
          />

          <Route
            path="profile"
            element={
              <ProtectedRoute
                requiredRoles={[
                  USER_ROLE.ADMIN,
                  USER_ROLE.OPERATOR,
                  USER_ROLE.ACCOUNTANT,
                ]}
              >
                <ProfilePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="user-management"
            element={
              <ProtectedRoute
                requiredRoles={[
                  USER_ROLE.ADMIN,
                  USER_ROLE.OPERATOR,
                  USER_ROLE.ACCOUNTANT,
                ]}
              >
                <UserManagementPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/reports"
            element={
              <ProtectedRoute
                requiredRoles={[USER_ROLE.ADMIN, USER_ROLE.ACCOUNTANT]}
              >
                <ReportPage />
              </ProtectedRoute>
            }
          />
        </Route>

        {/* SUBSCRIPTION ROUTES */}
        <Route path="Subscription">
          <Route
            path=""
            element={
              <ProtectedRoute
                requiredRoles={[USER_ROLE.ADMIN, USER_ROLE.OPERATOR]}
              >
                <SubscriptionPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="processing"
            element={
              <ProtectedRoute
                requiredRoles={[USER_ROLE.ADMIN, USER_ROLE.OPERATOR]}
              >
                <SubscriptionProcess />
              </ProtectedRoute>
            }
          />
        </Route>
        {/* ERROR ROUTE */}
        <Route
          path="*"
          element={
            <ErrorPage
              statusCode={404}
              errorTitle={"Page Not Found"}
              errorText={"Oops, sorry! We couldn’t find this page."}
              actionButton={
                <button className="inline-flex items-center px-6 py-3 mt-1 text-base font-medium text-white">
                  Go back
                </button>
              }
            />
          }
        />
        <Route
          path="/error"
          element={
            <ErrorPage
              statusCode={500}
              errorTitle={"Internal Server Error"}
              errorText={
                "Oops seems like something went wrong. We're working on it."
              }
              actionButton={
                <button className="inline-flex items-center px-6 py-3 mt-1 text-base font-medium text-white">
                  Go back
                </button>
              }
            />
          }
        />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
      </Routes>
  );
};

export default Router;