import React from "react";
import { InputComponent } from "../../core/Forms";
import ExpandLeft from "../../../assets/icons/ExpandLeft";
import { CloseIcon } from "../../../assets/icons";
import { commasToNumber, convertToFloat } from "../../../utils/convertToFloat";
import { updateInventory } from "../../../backend-services/inventory";
import { toast } from "react-hot-toast";
import { Loading } from "../../common/Table/Loading";
import {
  validateCreateInventory,
  validateEdit,
} from "../../../modules/inventory/inventory";
import { Button } from "../../common";

interface EditInventoryProps {
  onClose: () => void;
  id: string | undefined;
  details: any;
  setShowPrompt: (showPromt: boolean) => void;
  fetchDetail: (id: string) => void;
}

const EditInventory = ({
  onClose,
  id,
  details,
  setShowPrompt,
  fetchDetail,
}: EditInventoryProps) => {
  const initialState = details
    ? { name: details.name, rate: details.unitprice, description: details.desc }
    : {
        name: "",
        rate: "",
        description: "",
      };

  const [editDetail, setEditDetails] = React.useState(initialState);
  const [error, setError] = React.useState({
    name: "",
    rate: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    if (name === "rate") {
      const resValue = convertToFloat(value); // Ensure rate is numeric
      const updatedDetails = { ...editDetail, [name]: resValue };
      setEditDetails(updatedDetails);
    } else {
      const updatedDetails = { ...editDetail, [name]: value };
      setEditDetails(updatedDetails);
    }
  };

  const editInventory = async () => {
    const { hasErrors, errors } = validateEdit({
      name: editDetail.name,
      rate: editDetail.rate,
      // description: editDetail.description,
    });
    if (hasErrors) {
      setError(errors);
    } else {
      try {
        setIsLoading(true);
        if (id) {
          const response = await updateInventory(id, editDetail);
          setIsLoading(false);
          setShowPrompt(false);
          fetchDetail(id);
          toast.success(" Inventory Updated Successfully!");
        }
      } catch (error: any) {
        if (error.message === "Inventory already exists") {
          setErrorMessage(error.message);
        } else {
          setErrorMessage(
            "Unable to update Inventory inventory: " + error.message,
          );
        }
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="bg-[white] rounded-[12px] w-[500px]">
        <div className="  px-[1em] py-[1em]">
          <div className="">
            <div className="flex mb-8 justify-between">
              <a href="/inventories">
                <p className="font-medium text-[20px] flex items-center gap-2">
                  Edit Inventory
                </p>
              </a>
              <div className="cursor-pointer" onClick={onClose}>
                <CloseIcon />
              </div>
            </div>
            <form action="" className="space-y-6">
              <div>
                <label className="font-normal text-[16px]">
                  Inventory Name
                </label>
                <InputComponent
                  placeholder="Adidas 2X2"
                  name="name"
                  type={"text"}
                  className="w-full"
                  value={editDetail.name}
                  handleChange={handleInputChange}
                  error={error.name}
                />
              </div>
              <div>
                <label className="font-normal text-[16px]">Selling Price</label>
                <InputComponent
                  placeholder="120000"
                  name="rate"
                  type="number"
                  className="w-full"
                  value={editDetail.rate}
                  handleChange={handleInputChange}
                  // error={error.rate}
                />
              </div>
              <div className="flex flex-col">
                <label className="font-normal text-[16px]">Description</label>
                <textarea
                  // className={`block w-full border-[0.5px] h-[80px] md:h-[140px] rounded ${
                  //   error.description ? 'border-red-500' : 'border-[#A4A4A4]'
                  // }`}
                  className="block w-full border-[0.5px] h-[80px] md:h-[140px] rounded "
                  id="grid-first-name"
                  name="description"
                  placeholder="Provide more details about the product.."
                  value={editDetail.description}
                  onChange={handleInputChange}
                />
                {/* {error.description && <span className="text-red-500">Field cannot be empty</span>} */}
              </div>

              <div className="flex justify-end  gap-6 font-semibold">
                {/* <Button onClick={onClose} text="Cancel" size="full" variant="secondary" outline /> */}
                <button className="text-[black] border border-gray-300 bg-white w-[140px] rounded-md ">
                  Cancel
                </button>
                <Button
                  onClick={editInventory}
                  text="Save"
                  size="small"
                  isLoading={isLoading}
                  variant="secondary"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditInventory;
