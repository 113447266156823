import React, { useEffect, useRef, useState } from "react";
import Card from "./Card";
import FloatingActionButton from "./FloatingActionButton";
import DotMenu from "./DotMenu";
import { TableItem } from "..";
import { useInView } from "react-intersection-observer";

interface CardListProps {
  data: TableItem[];
  CustomerCard?: boolean;
  expenseCard?: boolean | undefined;
  invoiceCard?: boolean | undefined;
  quoteCard?: boolean | undefined;
  payHistoryCard?: boolean | undefined;
  inventoryCard?: boolean | undefined;
  variationCard?: boolean | undefined;
  restockCard?: boolean | undefined;
  removeStockCard?: boolean | undefined;
  archiveCard?: boolean | undefined;
  serviceCard?: boolean | undefined;
  userCard?: boolean | undefined;
  fetchMoreData?: (direction: "up" | "down") => void | undefined;
  isLoading: boolean;
  isLoadingFilter?: boolean;
  modules?: string;
}

const CardList: React.FC<CardListProps> = ({
  data,
  fetchMoreData,
  restockCard,
  removeStockCard,
  variationCard,
  CustomerCard,
  expenseCard,
  invoiceCard,
  quoteCard,
  payHistoryCard,
  inventoryCard,
  archiveCard,
  serviceCard,
  userCard,
  isLoading,
  isLoadingFilter,
  modules,
}) => {
  const [ref, inView] = useInView({ threshold: 0 });

  useEffect(() => {
    if (inView && !isLoading && fetchMoreData) {
      fetchMoreData("down");
    }
  }, [inView, isLoading, fetchMoreData]);

  return (
    <>
      <div className="border border-gray-200 rounded-lg mt-6">
        {isLoadingFilter && (
          <div className="py-6 flex  justify-center w-full items-center gap-3">
            <div className="w-5 h-5 border-2 border-gray-200 border-t-[#044E97] rounded-full animate-spin"></div>
            <p>Loading...</p>
          </div>
        )}
        {data?.map((item: TableItem, index: number) => (
          <Card
            paymentHistoryCard={payHistoryCard}
            CustomerCard={CustomerCard}
            expenseCard={expenseCard}
            invoiceCard={invoiceCard}
            quoteCard={quoteCard}
            inventoryCard={inventoryCard}
            variationCard={variationCard}
            restockCard={restockCard}
            removeStockCard={removeStockCard}
            serviceCard={serviceCard}
            archiveCard={archiveCard}
            userCard={userCard}
            key={index}
            data={item}
            length={data.length}
            menu={
              <DotMenu
                data={item}
                CustomerCard={CustomerCard}
                expenseCard={expenseCard}
                invoiceCard={invoiceCard}
                quoteCard={quoteCard}
                inventoryCard={inventoryCard}
                ServiceCard={serviceCard}
              />
            }
            index={index}
            modules={modules}
          />
        ))}
        {isLoading && (
          <div className="py-6 flex justify-center w-full items-center gap-3">
            <div className="w-5 h-5 border-2 border-gray-200 border-t-[#044E97] rounded-full animate-spin"></div>
            <p>Loading more</p>
          </div>
        )}
      </div>
      {<div className="mb-5" ref={ref}></div>}
    </>
  );
};

export default CardList;
