import { CognitoUser, CognitoRefreshToken } from "amazon-cognito-identity-js";
import userPool from "./userPool";
import { STORAGE_TOKEN, STORAGE_REFRESH_TOKEN, USERNAME } from "../constants";
import { History } from "history";

export let refreshSubscribers: any[] = [];

export const onRefreshed = (newToken: string) => {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
};

export const addRefreshSubscriber = (callback: any) => {
  refreshSubscribers.push(callback);
};

export const refreshIdToken = async (
  history?: History | string[],
): Promise<string | null> => {
  try {
    const refreshTokenString = sessionStorage.getItem(STORAGE_REFRESH_TOKEN);
    const username = localStorage.getItem(USERNAME);

    if (!refreshTokenString || !username) {
      sessionStorage.removeItem(STORAGE_TOKEN);
      throw new Error("No refresh token or username found");
    }

    const userData = {
      Username: username,
      Pool: userPool,
    };

    const user = new CognitoUser(userData);
    const refreshTokenObject = new CognitoRefreshToken({
      RefreshToken: refreshTokenString as string,
    });

    return new Promise((resolve, reject) => {
      user.refreshSession(refreshTokenObject, (err, session) => {
        if (err) {
          sessionStorage.removeItem(STORAGE_TOKEN);
          sessionStorage.removeItem(STORAGE_REFRESH_TOKEN);
          sessionStorage.removeItem(USERNAME);
          if (history) {
            history.push("/");
          }
          reject(err);
        } else {
          const newIdToken = session.getIdToken().getJwtToken();
          sessionStorage.setItem(STORAGE_TOKEN, newIdToken);
          onRefreshed(newIdToken);
          resolve(newIdToken);
        }
      });
    });
  } catch (error) {
    if (history) {
      history.push("/");
    }
    return null;
  }
};
