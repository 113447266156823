import toast from "react-hot-toast";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  VariationError,
  InventoryItem,
  VariationItem,
} from "../../../@types/inventory";
import { InventoryForm } from "./InventoryForm";
import { SidebarDrawer } from "../../common";
import { useInventoryStore } from "../../../state/useInventoryStore";
import VariationList from "../Variations/VariationList";
import PageLoader from "../../../components/common/PageLoader";
import VariationForm from "../Variations/VariationForm";
import { convertToFloat } from "../../../utils/convertToFloat";
import {
  ValidateVariation,
  validateCreateInventory,
} from "../../../modules/inventory/inventory";
import { createInventory } from "../../../backend-services";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../assets/icons";

interface InventoryPageProps {
  showTitle?: boolean;
  showSidebar?: boolean;
  showVariationList?: boolean;
  showCreateForm?: boolean;
  showForms?: boolean;
  onClose?: () => void;
}

const InventoryPage: React.FC<InventoryPageProps> = ({
  showSidebar = true,
  showVariationList = true,
  showCreateForm: initialShowCreateForm = false,
  showTitle = true,
  showForms = true,
}) => {
  const { t } = useTranslation("inventories");
  const navigate = useNavigate();
  const [error, setError] = useState({
    name: "",
    rate: "",
    costPrice: "",
    quantity: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [variationList, setVariationList] = useState<VariationItem[]>([]);
  const [inventories, setInventories] = useState<InventoryItem>({
    name: "",
    rate: "",
    costPrice: "",
    quantity: "",
    description: "",
    variations: [],
  });
  const [variationError, setVariationError] = useState<VariationError[]>([]);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [showCreateForm, setShowCreateForm] = useState(initialShowCreateForm);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [variationState, setVariationState] = useState([]);
  const { fetchVariations, createInventory } = useInventoryStore((state) => ({
    fetchVariations: state.fetchVariation,
    createInventory: state.createInventory,
  }));
  const handleCreateInventory = async () => {
    setError({
      name: "",
      rate: "",
      costPrice: "",
      quantity: "",
    });

    // Convert values to appropriate types
    const newRate = convertToFloat(inventories.rate);
    const newCostPrice = convertToFloat(inventories.costPrice);
    const newQuantity = convertToFloat(inventories.quantity);

    // Convert variationList values
    const newVariationList = variationList.map((item) => ({
      ...item,
      price: convertToFloat(item.price),
      costPrice: convertToFloat(item.costPrice),
      quantity: convertToFloat(item.quantity),
    }));

    // Validate inventory creation
    const { hasErrors, errors } = validateCreateInventory({
      rate: newRate,
      costPrice: newCostPrice,
      name: inventories.name,
      variationLen: newVariationList.length,
    });

    // Validate variations
    const variationErrorMsg = ValidateVariation(newVariationList);
    setVariationError([...variationErrorMsg]);

    if (hasErrors || variationErrorMsg.length > 0) {
      setError(errors);
      setErrorMessage("Cannot create inventory with duplicate variations.");
      return;
    }

    try {
      setIsLoading(true);
      const newInventory = newVariationList.length
        ? {
            name: inventories.name,
            description: inventories.description,
            variations: newVariationList,
          }
        : {
            ...inventories,
            rate: newRate,
            costPrice: newCostPrice,
            quantity: newQuantity,
          };
      const response = await createInventory(newInventory);
      setIsLoading(false);
      setInventories({
        name: "",
        rate: "",
        quantity: "",
        costPrice: "",
        description: "",
        variations: [],
      });
      toast.success("New Inventory Added Successfully!");
      navigate("/inventories");
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message || "An error occurred");
      }
      setIsLoading(false);
      return;
    }
  };

  const { variations, Loading, createVariation } = useInventoryStore(
    (state) => ({
      variations: state.variations,
      Loading: state.isLoading,
      createVariation: state.createVariation,
    }),
  );

  const handleFetchVariations = useCallback(() => {
    fetchVariations();
  }, [showCreateForm]);

  const showForm = () => {
    setShowCreateForm(true);
  };

  const onClose = () => {
    setIsSideBarOpen(false);
  };

  useEffect(() => {
    handleFetchVariations();
    setVariationState(variations);

    if (variations.length > 0) {
      setShowCreateForm(false);
    }
  }, [fetchVariations, createVariation, isSideBarOpen, setIsSideBarOpen]);

  const title =
    !showCreateForm && variations.length >= 1
      ? t("inventory.listVariations")
      : t("inventory.addVariationPrompt");

  const handleGoBack = () => {
    navigate(-1); // This will navigate back in the browser history
  };

  return (
    <div>
      {showTitle && (
        <div className="flex items-center justify-between mt-8">
          <div
            onClick={handleGoBack}
            className=" cursor-pointer flex items-center gap-2"
          >
            <div className="py-2">
              <svg
                className="w-[24px] h-[24px]"
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 1L1.5 8L8.5 15"
                  stroke="#33363F"
                  strokeWidth="2"
                />
              </svg>
            </div>
            <h1 className="text-[18px] md:text-[20px] font-medium lg:font-bold">
              {" "}
              {t("addInventory")}
            </h1>
          </div>

          <button
            style={{ fontWeight: 600 }}
            className="hidden md:block px-[34px] py-4 bg-white cursor-pointer border border-[#044E97] text-[#044E97] text-[16px] rounded-md "
            onClick={() => {
              setIsSideBarOpen(!isSideBarOpen);
            }}
          >
            {t("inventory.inputVariations")}
          </button>
        </div>
      )}
      {showSidebar && (
        <>
          <div className="flex items-start justify-between mt-8 ">
            <div className="mt-6">
              <h1 className="text-[14px] md:text-[18px] lg:text-[24px] font-normal">
                {t("inventory.createNew")}
              </h1>
            </div>
            <button
              className=" rounded-lg shadow-sm py-2 pr-3 bg-[#F4F7FF] text-[black] w-35 h-35 "
              onClick={onClose}
            >
              <CloseIcon />
            </button>
            <button
              style={{ fontWeight: 600 }}
              className="hidden md:block px-[34px] py-4 bg-white cursor-pointer border border-[#044E97] text-[#044E97] text-[16px] rounded-md"
              onClick={() => {
                setIsSideBarOpen(!isSideBarOpen);
              }}
            >
              {t("inventory.inputVariations")}
            </button>
          </div>
          <InventoryForm
            handleCreateInventory={handleCreateInventory}
            inventory={inventories}
            variations={variationList}
            setVariations={setVariationList}
            setInventories={setInventories}
            setIsSideBarOpen={setIsSideBarOpen}
            isLoading={isLoading}
            error={error}
            setError={setError}
            variationList={variations}
            variationError={variationError}
            errorMessage={""}
            setVariationError={setVariationError}
          />
        </>
      )}

      {showForms && (
        <div className="bg-[#FFFFFF] p-4 md:px-8 mt-4 md:mt-8">
          <div className="mt-6 ">
            <h1 className="text-[14px] md:text-[18px] lg:text-[24px] font-normal">
              {t("inventory.createNew")}
            </h1>
          </div>
          <InventoryForm
            handleCreateInventory={handleCreateInventory}
            inventory={inventories}
            variations={variationList}
            setVariations={setVariationList}
            setInventories={setInventories}
            setIsSideBarOpen={setIsSideBarOpen}
            isLoading={isLoading}
            error={error}
            setError={setError}
            variationList={variations}
            variationError={variationError}
            errorMessage={errorMessage}
            setVariationError={setVariationError}
          />
        </div>
      )}
      {showSidebar &&
      showVariationList &&
      variations.length !== 0 &&
      !showCreateForm ? (
        <SidebarDrawer
          title={title}
          show={isSideBarOpen}
          position="left"
          onClose={onClose}
        >
          <VariationList closeForm={showForm} data={variations} />
        </SidebarDrawer>
      ) : (
        showSidebar && (
          <SidebarDrawer
            title={title}
            show={isSideBarOpen}
            position="left"
            onClose={onClose}
          >
            <VariationForm
              closeForm={onClose}
              closeCreateForm={() => setShowCreateForm(false)}
              close={setIsSideBarOpen}
            />
          </SidebarDrawer>
        )
      )}
      {showForms &&
      showVariationList &&
      variations.length !== 0 &&
      !showCreateForm ? (
        <SidebarDrawer
          title={title}
          show={isSideBarOpen}
          position="left"
          onClose={onClose}
        >
          <VariationList closeForm={showForm} data={variations} />
        </SidebarDrawer>
      ) : (
        showForms && (
          <SidebarDrawer
            title={title}
            show={isSideBarOpen}
            position="left"
            onClose={onClose}
          >
            <VariationForm
              closeForm={onClose}
              closeCreateForm={() => setShowCreateForm(false)}
              close={setIsSideBarOpen}
            />
          </SidebarDrawer>
        )
      )}
    </div>
  );
};

export { InventoryPage };
