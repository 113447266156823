import React from "react";
import LanguageOption from "../../common/LanguageOption";

const LanguageSwitcher = () => {
  return (
    <div className="bg-[#FFFFFF] w-full h-[801px] p-[5.7%] md:p-11 text-[#000000]">
      <h1 className="text-[16px] md:text-[20px] font-semibold">
        Language Settings
      </h1>
      <p className="text-[14px] md:text-[16px]">
        Select language that suits you best
      </p>

      <hr className="mt-[10px] mb-5 md:mt-[1.5em] md:mb-[30px] lg:w-full border border-[#000000] border-opacity-25" />

      <div className="bg-[#DBE7FF] p-5 rounded-[4px] md:max-w-[580px] border border-[#044E9733] border-opacity-20">
        <p className="text-[#0A0A0A] mb-8">
          Esemie provides you with diverse languages to ensure the product is{" "}
          <br className="hidden md:block" /> tailored to your needs.
        </p>
        <LanguageOption />
      </div>
    </div>
  );
};

export default LanguageSwitcher;
