import React, { FC, ReactNode, useState, Ref } from "react";
import View from "../../../../assets/icons/svgicons/View.svg";
import View_hide from "../../../../assets/icons/svgicons/View_hide.svg";
import { lab } from "d3-color";

interface InputProps {
  id?: string;
  error?: any;
  label?: string;
  icon?: React.FC;
  type?: React.HTMLInputTypeAttribute;
  inputType?: string;
  clickable?: boolean;
  position?: string;
  image?: string | ReactNode;
  name?: string;
  value?: any;
  defaultDate?: string;
  minDate?: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleOnFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  ref?: Ref<HTMLInputElement>;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  imageClassName?: string;
  imageStyle?: React.CSSProperties;
  onClearError?: () => void;
  maxValue?: number;
  restrictPreviousDates?: boolean;
  restrictFutureDates?: boolean;
  pattern?: string;
  readonly?: boolean;
}

const InputComponent: FC<InputProps> = ({
  id,
  error,
  label,
  icon,
  type,
  inputType,
  name,
  value,
  defaultDate,
  minDate,
  handleChange,
  handleOnInput,
  handleOnBlur,
  handleOnFocus,
  className,
  placeholder,
  disabled,
  imageClassName,
  onClearError,
  maxValue,
  restrictPreviousDates,
  restrictFutureDates,
  pattern,
  readonly,
  ref,
  ...props
}) => {
  const [dateValue, setDateValue] = useState(
    defaultDate || new Date().toISOString().substring(0, 10),
  );
  const [passwordShown, setPasswordShown] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const togglePasswordVisibility = () => setPasswordShown(!passwordShown);

  const getInputClassName = (
    error: string | undefined,
    className: string | undefined,
    value: string | undefined,
  ) => {
    const baseClasses =
      "border-1 lg:max-h-[48px] lg:min-h-[48px] md:max-h-[48px] md:min-h-[48px] h-[48px] lg:placeholder:text-[16px] placeholder:text-[16px] rounded-[8px] lg:p-2 lg:focus:outline-none lg:focus:ring-0 lg:appearance-none lg:leading-normal";
    const colorClasses = error
      ? "border-red-500 bg-white"
      : "border-[#D0D5DD] bg-white";
    const widthClasses =
      className && className.includes("w-") ? "" : "lg:w-[215px]";
    const filledClass = isFilled ? "lg:ring-1 lg:ring-[#138EFF]" : "";
    const errorOutlineClass = error ? " ring-red-500" : "";
    return `${baseClasses} ${colorClasses} ${widthClasses} ${filledClass} ${errorOutlineClass} ${className}`;
  };

  const handleMaxValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "number" && maxValue && e.target.valueAsNumber > maxValue) {
      e.target.valueAsNumber = maxValue;
    }

    // Prevent commas in the input field
    if (type === "text" && e.target.value.includes(",")) {
      e.target.value = e.target.value.replace(/,/g, "");
    }

    if (handleChange) handleChange(e);
    setIsFilled(!!e.target.value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "text") {
      const inputValue = e.target.value;
      const alphabeticValue = inputValue.replace(/[0-9]/g, "");
      e.target.value = alphabeticValue;
    }

    if (handleChange) handleChange(e);
    setIsFilled(!!e.target.value);
  };

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (handleOnFocus) handleOnFocus(e);
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (handleOnBlur) handleOnBlur(e);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (handleOnInput) handleOnInput(e);
  };

  React.useEffect(() => {
    if (type === "date" && typeof value === "string") {
      setDateValue(value.substring(0, 10));
    }
  }, [type, value]);

  const getPasswordIconStyle = () => {
    if (type === "password") {
      return {
        top: "50%",
        right: "10px", // Adjust the right position as needed
        transform: "translateY(-50%)",
      };
    }
    return {};
  };

  return (
    <div className="relative">
      {type === "password" && (
        <div className="relative max-w-[434px]">
          <input
            id={id}
            max={maxValue}
            type={passwordShown ? "text" : "password"}
            placeholder={placeholder}
            name={name}
            className={getInputClassName(error, className, label)}
            value={value}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onInput={handleInput}
          />
          <img
            src={passwordShown ? View : View_hide}
            alt={passwordShown ? "Hide password" : "View password"}
            className={` absolute ${imageClassName} `}
            onClick={togglePasswordVisibility}
          />
        </div>
      )}

      {type === "date" && (
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          name={name}
          className={getInputClassName(error, className, label)}
          value={dateValue}
          max={
            restrictFutureDates
              ? new Date().toISOString().substring(0, 10)
              : maxValue
          }
          min={
            restrictPreviousDates
              ? new Date().toISOString().substring(0, 10)
              : minDate
          }
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onInput={handleInput}
        />
      )}
      {type === "checkbox" && (
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          name={name}
          // className={getInputClassName(error, className, label)}
          value={value}
          onChange={handleMaxValueChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onInput={handleInput}
        />
      )}
      {type !== "password" && type !== "date" && type !== "checkbox" && (
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          name={name}
          className={getInputClassName(error, className, label)}
          value={value}
          onChange={handleMaxValueChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onInput={handleInput}
          disabled={disabled}
        />
      )}
      {error && (
        <p className="text-[red] absolute whitespace-nowrap text-[12px]">
          {error}
        </p>
      )}
    </div>
  );
};

export { InputComponent };
