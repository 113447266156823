let subscribers: ((token: string) => void)[] = [];

export const addRefreshSubscriber = (callback: (token: string) => void) => {
  subscribers.push(callback);
};

export const onRefreshed = (token: string) => {
  subscribers.forEach((callback) => callback(token));
  subscribers = [];
};
