import React, { useEffect, useRef, useState } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import EmptyPage from "../../../components/emptyPage/EmptyPage";
import { useInvoiceSearch } from "../../../hooks";
import { useUserStore } from "../../../state";
import { useQuoteStore } from "../../../state/useQuotesStore";
import ExpenseEmptyIcon from "../../../assets/icons/InvCusExpEmptyIcon";
import QuotesTable from "../../../components/Sales/quotes/Table/Table";
import PageLoader from "../../../components/common/PageLoader";
import { ICustomer } from "../../../@types";
import { useTranslation } from "react-i18next";
import ActionModal from "../../../components/common/ActionModal";
import UploadIcon from "../../../assets/icons/svgicons/UploadIcon.svg";
import { BUSINESS_TYPE_RETAIL } from "../../../constants";
import { checkSubscription } from "../../../utils/subscription";
import { servicesType } from "../../../constants/Subscription";
import useRetailBusinessStatus from "../../../hooks/invoice/useRetailBusinessStatus";
import generateActionButtons from "../../../components/Sales/invoice/invoiceCreationModal";
import { columns, renderQuotesData } from "../../../utils/quote/list-page";
import { ReusableTable } from "../../../components/table";
import ReusableListPage from "../../../components/list-page";

const ListQuotePage = () => {
  const location = useLocation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const fetchQuotes = useQuoteStore((state) => state.fetchQuotes);
  const fetchMoreData = useQuoteStore((state) => state.fetchMoreData);
  const onSearchData = useQuoteStore((state) => state.setSearchText);
  const handleFilter = useQuoteStore((state) => state.setFilter);
  const fetchData = useQuoteStore((state) => state.fetchData);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const quotes = useQuoteStore((state) => state.quotes);
  const isLoading = useQuoteStore((state) => state.isLoading);
  const isLoadingInitial = useQuoteStore((state) => state.isLoadingInitial);
  const isLoadingFilter = useQuoteStore((state) => state.isLoadingFilter);
  const isLoadingMore = useQuoteStore((state) => state.isLoadingMore);
  const range = useQuoteStore((state) => state.range);
  const pagination = useQuoteStore((state) => state.pagination);
  const searchText = useQuoteStore((state) => state.searchText);
  const filter = useQuoteStore((state) => state.filter);
  const { t } = useTranslation("quotes");
  const [showSubscriptionModal, setShowSubscriptionModal] =
    React.useState(false);
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
    React.useState<string>("");
  const isRetailBusiness = useRetailBusinessStatus();

  const {
    data: searchResult,
    isSearching,
    searchedDone,
    setSearchedDone,
    handleSearch: handleInvoiceSearch,
  } = useInvoiceSearch();

  React.useEffect(() => {
    fetchData();
  }, [searchText, filter, range, fetchData]);

  useEffect(() => {
    fetchQuotes();
  }, [params, location.state, fetchQuotes]);

  const handleNewQuoteClick = () => {
    setIsModalOpen(true);
  };

  const handleInventoryInvoiceClick = async () => {
    const subscriptionCheck = checkSubscription(servicesType.Invoices);
    if (subscriptionCheck.status) {
      navigate("/quotes/create");
      return;
    } else {
      setShowSubscriptionModal(true);
    }
  };

  const handleFreeformInvoiceClick = () => {
    const subscriptionCheck = checkSubscription(servicesType.Invoices);
    if (subscriptionCheck.status) {
      navigate("/quotes/createFreeform");
    } else {
      setShowSubscriptionModal(true);
      setSubscriptionErrorMessage(subscriptionCheck.message || "");
    }
  };
  const actionButtons = generateActionButtons(
    isRetailBusiness,
    handleInventoryInvoiceClick,
    handleFreeformInvoiceClick,
  );

  if (isLoadingInitial) {
    return (
      <>
        <PageLoader />
      </>
    );
  }

  return (
    <>
      <ReusableListPage
        handleCreate={handleNewQuoteClick}
        fetchMoreData={fetchMoreData}
        modules="quotes"
        showCalendar={false}
        infoCardData={[]}
        columns={columns}
        searchPlaceHolder="Search by customer"
        pageTitle="Quotes"
        searchDisplayKey="customer.firstName"
        data={renderQuotesData(quotes)}
        onSearchData={onSearchData}
        handleFilter={handleFilter}
        handleSearch={handleInvoiceSearch}
        meta={pagination}
        isLoading={isLoading}
        isLoadingMore={isLoadingMore}
        isLoadingFilter={isLoadingFilter}
        isSearching={isSearching}
        searchResult={searchResult}
        selectedFilter={filter}
        searchedDone={searchedDone}
        setSearchedDone={setSearchedDone}
        showFilter={false}
        mobileCard={"quoteCard"}
        filterByDate={false}
        emptyStateDescription={t("quote.quoteFirst")}
        emptyPageIcon={<ExpenseEmptyIcon />}
        emptyPageHref="https://www.youtube.com/embed/fJUWHHoUaxo"
        emptyPageLinkName="quotes"
        handleSetCustomer={function (selectedCustomer: ICustomer | null): void {
          throw new Error("Function not implemented.");
        }}
      />

      <div ref={containerRef}>
        <ActionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title="Select an invoice creation option"
          icon={UploadIcon}
          actionButtons={actionButtons}
        />
      </div>
    </>
  );
};

export { ListQuotePage };
