import React from "react";
import { CloseIcon } from "../../../assets/icons";
import { toSentenceCase } from "../../../utils";

interface ChipProps {
  label: string;
  clearAction: () => void;
  style?: React.CSSProperties;
}

const Chip: React.FC<ChipProps> = ({ label, clearAction }) => {
  const handleClearClick = () => {
    clearAction();
  };

  return (
    <div className="flex items-center border justify-between rounded-[2px] pl-1 py-[6px] pr-3 bg-[#E3EFFC] w-fit">
      <span className="text-[12px] font-medium text-[#000000]">{label}</span>
      <button className="" onClick={handleClearClick}>
        {<CloseIcon width="14" height="14" />}
      </button>
    </div>
  );
};

export default Chip;
