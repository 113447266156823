import React from "react";
import ServicePage from "../../components/services/create/ServicePage";

const CreateServicePage = () => {
  return (
    <>
      <ServicePage showBack={false} />
    </>
  );
};

export { CreateServicePage };
