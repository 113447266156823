import React, { useEffect, useState } from "react";
import { useServiceSearch } from "../../hooks/service/useServiceSearch";
import { useServiceStore } from "../../state/useServiceStore";
import { useNavigate } from "react-router-dom";
import EmptyPage from "../../components/emptyPage/EmptyPage";
import ExpenseEmptyIcon from "../../assets/icons/InvCusExpEmptyIcon";
import { getActiveSubscriptionWithHandling } from "../../backend-services/subscription";
import { MobileSubscriptionModal } from "../../components/common/Subscription/MobileSubscriptionModal";
import { SubscriptionModal } from "../../components/common/Subscription/SubscriptionModal";
import { useServiceStatsData } from "../../hooks/Stats/service";
import { ICustomer } from "../../@types";
import { useTranslation } from "react-i18next";
import ReusableListPage from "../../components/list-page";
import { columns, renderServiceData } from "../../utils/services/list-page";
import { ListInfoCardProps } from "../../components/list-page/desktop/ListInfoCard";
import {
  mobileServiceCategories,
  serviceCategories,
} from "../../constants/filterCategories";
import PageLoader from "../../components/common/PageLoader";

const ListServicePage = () => {
  const { t } = useTranslation("services");
  const navigate = useNavigate();
  const [showSubscriptionModal, setShowSubscriptionModal] =
    React.useState(false);
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
    React.useState<string>("");
  const currentDate = new Date();
  const [startOfMonth, setStartOfMonth] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
  );
  const [endOfMonth, setEndOfMonth] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
  );
  const { serviceStats, error } = useServiceStatsData(startOfMonth, endOfMonth);

  const fetchMoreData = useServiceStore((state) => state.fetchMoreData);
  const onSearchData = useServiceStore((state) => state.setSearchText);
  const handleFilter = useServiceStore((state) => state.setFilter);
  const fetchData = useServiceStore((state) => state.fetchData);
  const clearAllFilters = useServiceStore((state) => state.clearAllFilters);

  const services = useServiceStore((state) => state.services);
  const isLoading = useServiceStore((state) => state.isLoading);
  const isLoadingInitial = useServiceStore((state) => state.isLoadingInitial);
  const isLoadingFilter = useServiceStore((state) => state.isLoadingFilter);
  const isLoadingMore = useServiceStore((state) => state.isLoadingMore);
  const pagination = useServiceStore((state) => state.pagination);
  const searchText = useServiceStore((state) => state.searchText);
  const filter = useServiceStore((state) => state.filter);
  const range = useServiceStore((state) => state.range);

  const {
    data: searchResult,
    isSearching,
    searchedDone,
    setSearchedDone,
    handleSearch: handleServiceSearch,
  } = useServiceSearch();

  useEffect(() => {
    fetchData();
  }, [fetchData, range, searchText]);

  useEffect(() => {
    if ("status" in filter) {
      fetchData({ actionType: "filter" });
    }
  }, [filter, fetchData]);

  const infoCardData = [
    {
      title: t("service.totalNewServices"),
      mobileTitle: t("service.totalNewServices"),
      value: `${serviceStats?.totalNewServices}`,
      bgColor: "#F0F7FF",
      className: "w-full",
    },
    {
      title: t("service.totalServices"),
      mobileTitle: t("service.totalServices"),
      value: `${serviceStats?.totalServices}`,
      bgColor: "#ECFDF3",
      className: "w-full",
    },
    {
      title: t("service.totalBookings"),
      mobileTitle: t("service.totalBookings"),
      value: `${serviceStats?.totalBookedServices}`,
      bgColor: "#FFFAEB",
      className: "w-full",
    },
  ] as ListInfoCardProps[];

  const ServicesCreate = async () => {
    try {
      const response = await getActiveSubscriptionWithHandling("services");
      if (response.active) {
        navigate("/services/create");
      } else {
        setShowSubscriptionModal(true);
      }
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data;
        setShowSubscriptionModal(true);
        setSubscriptionErrorMessage(errorMessage);
      }
    }
  };

  const handleFilterSubmit = (filter: Record<string, string | boolean>) => {
    if (filter.status !== undefined) {
      //@ts-ignore
      const newFilter: Partial<Filter> = {};
      if (filter.status !== undefined) {
        newFilter.status = filter.status as string;
      }
      //@ts-ignore
      handleFilter(newFilter as Filter);
    }
  };

  if (isLoadingInitial) {
    return (
      <>
        <PageLoader />
      </>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <div className="pb-12">
        <ReusableListPage
          filterByDate={false}
          categories={serviceCategories}
          createTitle="Create service"
          infoCardData={infoCardData}
          handleCreate={ServicesCreate}
          fetchMoreData={fetchMoreData}
          onSearchData={onSearchData}
          handleSearch={handleServiceSearch}
          meta={pagination}
          data={renderServiceData(services)}
          modules="services"
          selectedFilter={filter}
          clearAllFilters={clearAllFilters}
          searchDisplayKey="name"
          pageTitle="Services"
          searchPlaceHolder="Search for services"
          isLoading={isLoading}
          isLoadingFilter={isLoadingFilter}
          isLoadingMore={isLoadingMore}
          isSearching={isSearching}
          searchResult={searchResult}
          searchedDone={searchedDone}
          setSearchedDone={setSearchedDone}
          columns={columns}
          onSubmit={handleFilterSubmit}
          mobileCard="serviceCard"
          mobileCategories={mobileServiceCategories}
          emptyStateDescription={t("service.serviceFirst")}
          emptyPageIcon={<ExpenseEmptyIcon />}
          emptyPageHref="https://www.youtube.com/embed/sQuQa_rR6FQ"
          emptyPageLinkName="services"
          handleSetCustomer={function (
            selectedCustomer: ICustomer | null,
          ): void {
            throw new Error("Function not implemented.");
          }}
          emptyMessage={t("service.emptyState")}
        />
      </div>

      <div className="mt-[-30em]">
        {showSubscriptionModal && (
          <SubscriptionModal>
            <MobileSubscriptionModal
              onClose={() => setShowSubscriptionModal(false)}
              titleText={`Need to access more features?`}
              subtitleText={subscriptionErrorMessage}
            />
          </SubscriptionModal>
        )}
      </div>
    </>
  );
};

export { ListServicePage };
