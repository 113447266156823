import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

export const handleErrors = (
  error: any,
  setErrorMessage: (message: string) => void,
) => {
  if (
    error.response &&
    (error.response.status === 400 || error.response.status === 500)
  ) {
    setErrorMessage("An error occurred.");

    const errorEl = document.getElementById("error-message");
    errorEl?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });

    document
      .getElementById("top-of-page")
      ?.scrollIntoView({ behavior: "smooth" });
  }

  if (error.response && error.response.status === 500) {
    const navigate = useNavigate();
    navigate("/error");
  }
};

export const clearFieldError = (
  name: string,
  fieldErrors: any,
  setFieldErrors: React.Dispatch<React.SetStateAction<any>>,
) => {
  if (fieldErrors && fieldErrors.hasOwnProperty(name)) {
    const updatedErrors = { ...fieldErrors };
    delete updatedErrors[name];
    setFieldErrors(updatedErrors);
  }
};

export const handleError = (e: any) => {
  toast.error(e.response?.data || e.message);
  throw new Error(e.response?.data || e.message);
};
