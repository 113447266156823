import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  RestockItem,
  VariationError,
  VariationItem,
} from "../../@types/inventory";
import { getInventory, restockInventory } from "../../backend-services";
import { toast } from "react-hot-toast";
import { convertToFloat } from "../../utils/convertToFloat";
import {
  ValidateVariation,
  validateRestock,
} from "../../modules/inventory/inventory";
import { useInventoryStore } from "../../state/useInventoryStore";
import PageLoader from "../../components/common/PageLoader";
import BackArrow from "../../assets/icons/backArrow";
import { EditInventoryForm } from "../../components/inventory/restock/EditInventoryForm";
import { useTranslation } from "react-i18next";

const RestockPage = () => {
  const { restockId } = useParams();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  const [inventories, setInventories] = useState<RestockItem>({
    id: restockId,
    name: "",
    rate: "",
    costPrice: "",
    quantity: "",
    date: "",
  });
  const [variationList, setVariationList] = useState<VariationItem[]>([]);
  const [variationError, setVariationError] = React.useState<VariationError[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation("inventories");
  const [error, setError] = React.useState({
    quantity: "",
    rate: "",
    costPrice: "",
  });

  const fetchInventoryItem = async (id: string) => {
    setIsLoading(true);
    try {
      const res = await getInventory(id);
      setInventories({
        ...inventories,
        name: res.name,
        rate: res.rate,
        costPrice: res.costPrice,
        quantity: "0",
        date: res.createdAt,
      });
      setVariationList(
        res.variations.map((item: any) => ({
          ...item,
          quantity: 0,
        })),
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to fetch variations");
    }
  };

  const handleRestock = async () => {
    const newRate = convertToFloat(inventories.rate);
    const newQuantity = convertToFloat(inventories.quantity);
    const newCostPrice = convertToFloat(inventories.costPrice);

    const newVariationList = variationList.map((item: any) => ({
      ...item,
      price: convertToFloat(item.price),
      costPrice: convertToFloat(item.costPrice),
      quantity: convertToFloat(item.quantity),
    }));

    const { hasErrors, errors } = validateRestock({
      rate: newRate,
      quantity: newQuantity,
      costPrice: newCostPrice,
    });
    const variationErrorMsg = ValidateVariation(newVariationList);
    setVariationError([...variationErrorMsg]);

    if (hasErrors && newVariationList.length === 0) {
      setError(errors);
      return;
    }

    if (variationErrorMsg.length === 0) {
      try {
        setIsLoading(true);
        const newInventory = newVariationList.length
          ? {
              variations: newVariationList,
              date: new Date(inventories?.date || "").toISOString(),
            }
          : {
              rate: newRate,
              costPrice: newCostPrice,
              quantity: newQuantity,
              date: new Date(inventories?.date || "").toISOString(),
            };
        if (!restockId) return;
        const response = await restockInventory(restockId, newInventory);
        setIsLoading(false);
        setInventories({
          name: "",
          rate: "",
          quantity: "",
          costPrice: "",
          variations: [],
        });
        setVariationList([]);
        navigate(-1);
        toast.success(t("inventory.restockSuccessful"));
      } catch (error: any) {
        if (error.message === "Inventory already exists") {
          toast(error.message);
        } else {
          toast("Unable to restock inventory");
        }
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (restockId) {
      fetchInventoryItem(restockId);
    }
  }, [restockId]);

  const { fetchVariations } = useInventoryStore((state) => ({
    fetchVariations: state.fetchVariation,
  }));

  const { variations } = useInventoryStore((state) => ({
    variations: state.variations,
  }));

  useEffect(() => {
    fetchVariations();
  }, [fetchVariations]);

  return isLoading ? (
    <PageLoader />
  ) : (
    <div className="relative">
      <div className="flex items-center justify-between mt-5 py-[7px]">
        <div className="flex items-center w-full md:w-fit gap-4">
          <div onClick={goBack} className="cursor-pointer">
            <BackArrow />
          </div>
          <h1 className="text-[18] md:text-[20px] leading-6 font-medium md:font-bold">
            {t("inventory.restock")}
          </h1>
        </div>
      </div>
      <div className="my-8">
        <h1 className="text-[20px] font-medium">{inventories.name}</h1>
      </div>
      <EditInventoryForm
        inventory={inventories}
        variations={variationList}
        setVariations={setVariationList}
        setInventories={setInventories}
        variationList={variations}
        error={error}
        setError={setError}
        variationError={variationError}
      />

      <div className="md:mt-10 md:flex md:justify-end">
        <div className="mb-34 flex gap-4 md:relative md:left-0 md:right-0 md:bottom-0">
          <button
            onClick={goBack}
            className="w-full bg-white rounded-[4px] md:w-[205px] text-black border border-[#044E97] text-center h-[48px] font-normal"
          >
            {t("inventory.cancel")}
          </button>
          <button
            onClick={handleRestock}
            className="w-full bg-[#044E97] rounded-[4px] md:w-[205px] text-white border border-[#A4A4A4]  h-[48px] text-center font-normal"
          >
            Save
          </button>
        </div>
      </div>
      <div className="mb-[44px]"></div>
    </div>
  );
};

export default RestockPage;
