import React, { useEffect, useMemo, useState } from "react";
import { Filter } from "../../../@types/invoice";
import { DropdownComponent } from "../../core/Forms";
import { CloseIcon } from "../../../assets/icons";
import UpArrowClose from "../../../assets/icons/UpArrowClose";

export interface FilterOption {
  label: string;
  type: string;
  value: string | boolean;
}

type ListFilterProps = {
  categories?: FilterOption[];
  onSubmit?: (filter: Record<string, string | boolean>) => void;
  selectedFilter?: Filter;
  handleFilter?: (filter: Filter) => void;
  clearAllFilters?: () => void;
};

const ListFilter: React.FC<ListFilterProps> = ({
  onSubmit,
  categories,
  selectedFilter,
  handleFilter,
  clearAllFilters,
}) => {
  const [localFilters, setLocalFilters] = useState<
    Record<string, string | boolean>
  >({});
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionChange = (category: string, value: string | boolean) => {
    setLocalFilters((prevFilters) => ({
      ...prevFilters,
      [category]: value,
    }));
  };

  const handleChipClose = (chipKey: keyof Filter) => {
    if (selectedFilter) {
      let newFilter = { ...selectedFilter };
      let newLocalFilters = { ...localFilters };

      switch (chipKey) {
        case "status":
        case "paymentMethod":
        case "stockStatus":
        case "variantStatus":
          if (typeof newFilter[chipKey] === "string") {
            //@ts-ignore
            newFilter[chipKey] = "";
          }
          break;
        case "isReoccurring":
          //@ts-ignore
          newFilter[chipKey] = null;
          break;
        case "billable":
          //@ts-ignore
          newFilter[chipKey] = null;
          break;
        default:
          break;
      }

      delete newLocalFilters[chipKey];
      setLocalFilters(newLocalFilters);
      if (handleFilter) {
        handleFilter(newFilter as Filter);
      }
    }
  };

  useEffect(() => {
    onSubmit && onSubmit(localFilters);
  }, [localFilters]);

  return (
    <>
      <div className="hidden lg:flex space-x-4 bg-gray-100 p-1 rounded-lg">
        {categories &&
          categories.map((category) => {
            const isActive = localFilters[category.type] === category.value;
            return (
              <div key={category.label} className="relative flex items-center">
                <button
                  className={`p-2 text-[14px] font-medium rounded-lg transition-all flex items-center
              ${isActive ? "bg-white shadow text-gray-800" : "bg-transparent hover:bg-gray-200 text-gray-500"}
            `}
                >
                  <div
                    onClick={() =>
                      handleOptionChange(category.type, category.value)
                    }
                  >
                    {category.label}
                  </div>
                  {isActive && (
                    <button
                      onClick={() =>
                        handleChipClose(category.type as keyof Filter)
                      }
                      aria-label="Remove Filter"
                    >
                      {<CloseIcon width="16" height="16" />}
                    </button>
                  )}
                </button>
              </div>
            );
          })}
      </div>

      <div className="hidden md:block lg:hidden relative w-full max-w-64">
        {/* Dropdown Trigger */}
        <button
          className="w-full p-3  text-gray-500 border border-gray-200 rounded-lg flex items-center justify-between"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="font-medium text-[16px]">All</span>

          {isOpen ? (
            <div className="rotate-180">
              <UpArrowClose />
            </div>
          ) : (
            <UpArrowClose />
          )}
        </button>

        {/* Dropdown Menu */}
        {isOpen && (
          <div className="absolute z-10 w-full mt-1 bg-white rounded-lg">
            {categories?.map((category) => (
              <button
                key={String(category.value)}
                onClick={() =>
                  handleOptionChange(category.type, category.value)
                }
                className="w-full p-3 text-left text-[16px] font-medium text-gray-700 hover:bg-gray-100 focus:outline-none"
              >
                {category.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ListFilter;
