import React, { useState } from "react";
import { Inventory, InventoryItem, Variation } from "../../../@types/inventory";
import { VariationLine } from "./VariationLine";

interface VariationForm {
  variations: Variation;
}

const VariationDropdownForm = ({ variations }: any) => {
  const [inventories, setInventories] = useState<InventoryItem[]>([]);
  const addInventory = () => {
    // Add validation logic here
    const newInventory: InventoryItem = {
      // id:'',
      name: "",
      rate: "",
      quantity: "",
      description: "",
      variations: [],
    };
    setInventories([...inventories, newInventory]);
  };

  return (
    <div className="">
      {variations.map((variation: any, index: React.Key | null | undefined) => (
        <VariationLine key={index} variation={variation} />
      ))}
      <button onClick={addInventory}>Add Variation</button>
    </div>
  );
};
export { VariationDropdownForm };
