import { useState } from "react";
import { toast } from "react-hot-toast";

interface UseCancelProps {
  onCancel: () => Promise<void>;
}

const useCancel = ({ onCancel }: UseCancelProps) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleCancel = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setIsProcessing(true);
      await onCancel();
      toast.success("Invoice canceled successfully!");
    } catch (error: any) {
      toast.error(
        error.message || "An error occurred while canceling the invoice.",
      );
    } finally {
      setIsProcessing(false);
    }
  };

  return { isProcessing, handleCancel };
};

export default useCancel;
