import React from "react";
import ExpandLeft from "../../../../assets/icons/ExpandLeft";
import { formatMoney, getInvoiceStatus } from "../../../../utils";
import { IInvoice } from "../../../../@types";
import AnnotationComponent from "../../../common/Annotation/AnnotationComponent";
import useAnnotations from "../../../../hooks/annotation/useAnnotations";
import { Annotation } from "../../../../@types/annotation";
import { Statuses } from "../../../../constants";
import { Button } from "../../../common";
import { isAdmin } from "../../../../utils/isAdmin";
import { useUserStore } from "../../../../state";
import { SubscriptionModal } from "../../../common/Subscription/SubscriptionModal";
import { CancelConfirmationPrompt } from "../CancelConfirmation";
import toast from "react-hot-toast";
import { RefundConfirmationPrompt } from "../RefundConfirmationPrompt";
import { useTranslation } from "react-i18next";
import { getStatusClass, getStatusColor } from "../Status/status";

interface HeaderProps {
  invoice: IInvoice;
  annotations: Annotation[];
  isRefunding: boolean;
  handleCancelInvoice: any;
  handleRefundInvoice: any;
}

const Header: React.FC<HeaderProps> = ({
  invoice,
  annotations,
  isRefunding,
  handleCancelInvoice,
  handleRefundInvoice,
}) => {
  const { t } = useTranslation("invoices");
  const annotationsData = useAnnotations(annotations, "Invoice");
  const { roles } = useUserStore();
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [showRefundPrompt, setShowRefundPrompt] = React.useState(false);
  const [, setIsCancelled] = React.useState(false);

  const handleCancelInvoiceWithSuccessModal = async () => {
    try {
      await handleCancelInvoice();
      setIsCancelled(true);
    } catch (error) {
      toast.error("Failed to cancel the invoice");
    }
  };

  return (
    <>
      {/* Desktop Header */}
      <div className="hidden lg:block px-[1em]">
        <div className="flex justify-between  ">
          <div className="flex justify-start">
            <a href="/invoices">
              <span className="">
                {" "}
                <ExpandLeft />
              </span>
            </a>
            <span className="font-semibold text-[20px] pl-3">
              {invoice.invoiceNumber}
            </span>
          </div>

          <div className=" flex  justify-end items-end gap-4 text-[16px] font-medium">
            <span
            // style={{ backgroundColor: getStatusColor(invoice.status) }}
            // className={`${getStatusClass(invoice.status)}  px-1 text-center rounded-sm text-[16px] mt-[-1em]`}
            >
              {getInvoiceStatus(invoice.status)}
            </span>

            <AnnotationComponent
              type="invoice"
              annotationData={annotationsData}
            />
          </div>
        </div>
        <div className="flex justify-start">
          {invoice.currency && invoice.currency !== "NGN" && (
            <p className="text-[16px] mt-[em] text-left">
              {t("exchangeRate")}:{formatMoney(invoice.exchangeRate)} per{" "}
              {invoice.currency}
            </p>
          )}
        </div>
      </div>

      {/* Mobile Header */}
      <>
        <div className=" lg:hidden flex justify-between">
          <a href="/invoices">
            <div className="mt-[5px]">
              <ExpandLeft />
            </div>
          </a>
          <div className="flex gap-[6em] md:gap-[18em]">
            <div className="flexitems-center justify-center ">
              <p className="text-[20px] font-semibold text-center md:ml-0 ">
                View Invoice
              </p>
            </div>
            <div className=" flex  justify-end items-end gap-[em] text-[16px] font-medium">
              {invoice.status !== Statuses.PAID.toLowerCase() && (
                <div className="menu-item-13">
                  <button
                    className={`text-[red]  ${
                      invoice.status === Statuses.CANCELLED.toLowerCase() ||
                      invoice.status === Statuses.REFUNDED.toLowerCase()
                        ? " text-gray-500 cursor-not-allowed"
                        : "cursor-pointer "
                    }`}
                    onClick={() => setShowPrompt(true)}
                    disabled={
                      invoice.status === Statuses.CANCELLED.toLowerCase() ||
                      invoice.status === Statuses.REFUNDED.toLowerCase()
                    }
                  >
                    Cancel
                  </button>
                </div>
              )}
              {isAdmin(roles) &&
                invoice.status === Statuses.PAID.toLowerCase() && (
                  <div className="">
                    <button
                      className={`text-[#044E97] w-[84px] border border-[#EAECF0] h-[40px] rounded-md ${
                        isRefunding ||
                        invoice.status !== Statuses.PAID.toLowerCase()
                          ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                      onClick={() => setShowRefundPrompt(true)}
                      disabled={
                        isRefunding ||
                        invoice.status !== Statuses.PAID.toLowerCase()
                      }
                    >
                      Refund
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
      </>
      {/* <hr className="mt-2 w-[100%] lg:hidden md:hidden" /> */}
      {showPrompt && (
        <CancelConfirmationPrompt
          onCancel={handleCancelInvoiceWithSuccessModal}
          onClose={() => setShowPrompt(false)}
          isOpen={showPrompt}
        />
      )}
      {showRefundPrompt && (
        <RefundConfirmationPrompt
          onRefund={handleRefundInvoice}
          onClose={() => setShowRefundPrompt(false)}
          isOpen={showRefundPrompt}
        />
      )}
    </>
  );
};

export default Header;
