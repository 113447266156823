import React from "react";

const CanDollarIcon = () => {
  return (
    <div>
      <svg
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.22222 0.277344C1.63285 0.277344 1.06762 0.51147 0.650874 0.928217C0.234126 1.34496 0 1.9102 0 2.49957L0 12.4996C0 13.0889 0.234126 13.6542 0.650874 14.0709C1.06762 14.4877 1.63285 14.7218 2.22222 14.7218H5.55556V0.277344H2.22222ZM17.7778 0.277344H14.4444V14.7218H17.7778C18.3671 14.7218 18.9324 14.4877 19.3491 14.0709C19.7659 13.6542 20 13.0889 20 12.4996V2.49957C20 1.9102 19.7659 1.34496 19.3491 0.928217C18.9324 0.51147 18.3671 0.277344 17.7778 0.277344Z"
          fill="#D52B1E"
        />
        <path
          d="M5.55469 0.277344H14.4436V14.7218H5.55469V0.277344Z"
          fill="#EEEEEE"
        />
        <path
          d="M10.3426 9.78476C11.0081 9.86198 11.6048 9.93143 12.2698 10.0075L12.1003 9.45087C12.0879 9.40297 12.0896 9.35249 12.1053 9.30554C12.1209 9.2586 12.1499 9.21722 12.1887 9.18643L14.1214 7.61198L13.7214 7.42365C13.5453 7.36087 13.5937 7.26143 13.6576 7.02254L13.9526 5.94698L12.8298 6.18421C12.7203 6.20087 12.6476 6.13143 12.6309 6.06476L12.4859 5.55865L11.5981 6.55532C11.472 6.71532 11.2164 6.71532 11.2959 6.34643L11.6753 4.32754L11.1659 4.59143C11.0231 4.67143 10.8803 4.68476 10.8009 4.54198L10.0981 3.22754L9.39533 4.54198C9.31533 4.68476 9.17255 4.67143 9.02977 4.59143L8.52089 4.32754L8.90033 6.34643C8.98033 6.71532 8.72422 6.71532 8.59811 6.55532L7.71033 5.55921L7.56589 6.06532C7.54922 6.13198 7.47589 6.20143 7.36644 6.18476L6.24366 5.94754L6.53866 7.02309C6.60144 7.26198 6.65033 7.36143 6.47422 7.42421L6.07422 7.61254L8.007 9.18698C8.08366 9.24643 8.12255 9.35365 8.09477 9.45143L7.92533 10.0081L9.85311 9.78532C9.912 9.78532 9.95089 9.81809 9.95033 9.88587L9.83144 11.9437H10.3626L10.2442 9.88587C10.2453 9.81754 10.2837 9.78476 10.3426 9.78476Z"
          fill="#D52B1E"
        />
      </svg>
    </div>
  );
};

export default CanDollarIcon;
