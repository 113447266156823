import React from "react";
import { ChangePasswordView } from "../../components/auth";

interface Props {
  children?: any;
}

export const ChangePasswordPage: React.FC<Props> = ({ children }) => {
  return <ChangePasswordView />;
};
