import React, { useEffect, useState } from "react";
import { ScrollupIcon, ScrolldownIcon } from "../../assets/icons";
import classNames from "classnames";

interface FloatingScrollButtonProps {
  action: () => void;
  title: string;
  direction: "up" | "down";
  fadeMode: boolean;
  className?: string;
}

const FloatingScrollButton: React.FC<FloatingScrollButtonProps> = ({
  action,
  title,
  direction,
  fadeMode,
  className,
}) => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      setIsScrolling(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    action();
  };

  const invertedIcon = direction === "up" ? "invert" : "";

  const buttonClasses = classNames("flex opacity-100 text-center", className, {
    "flex-col-reverse": direction !== "up",
    "opacity-50": !fadeMode,
    [invertedIcon]: direction === "up",
    "is-scrolling": isScrolling,
  });

  return (
    <button
      disabled={!fadeMode}
      onClick={handleClick}
      className={buttonClasses}
    >
      {direction === "up" ? <ScrollupIcon /> : <ScrolldownIcon />}
      <span>{title}</span>
    </button>
  );
};

export { FloatingScrollButton };
