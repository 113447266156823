import React from "react";

interface CustomerContactInfoProps {
  title: string;
  value: string;
  mt?: string;
}
export const CustomerContactInfo: React.FC<CustomerContactInfoProps> = ({
  title,
  value,
  mt = "",
}) => (
  <div>
    <h3
      className={`font-normal text-[#808080] text-[14px] leading-[14.4px] ${mt}`}
    >
      {title}
    </h3>
    <p className="font-semibold md:font-medium text-[#212121] text-[16px] md:text-[14px] leading-[18.4px] mt-2">
      {value}
    </p>
  </div>
);
