import { useState, useEffect } from "react";
import { InventoryStatsData } from "../../@types/inventory";
import { getInventoryStats } from "../../backend-services";

export function useInventoryStatsData(startDate: Date, endDate: Date) {
  const [inventoryStats, setInventoryStats] =
    useState<InventoryStatsData | null>(null);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    const start = startDate.toISOString();
    const end = endDate.toISOString();

    try {
      const response = await getInventoryStats(start, end);
      const data: InventoryStatsData = {
        totalInventory: response.totalInventories,
        totalValue: response.totalAmount,
        totalUnitsSold: response.QuantitySold,
        totalDeletedInventories: response.totalArchivedInventories,
        dateRange: `${startDate.toDateString()} - ${endDate.toDateString()}`,
      };
      setInventoryStats(data);
      setError(null);
    } catch (error) {
      setError("Failed to fetch inventory statistics");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { inventoryStats, error };
}
