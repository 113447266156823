import React, { useState } from "react";
import { Button, SidebarDrawer } from "../../common";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import SubscriptionExpiry from "./subscriptionExpiry";
import SubscriptionLimit from "./subscriptionLimit";

interface SubscriptionAlert {
  isExpired: boolean;
}

const SubscriptionAlert = ({ isExpired }: SubscriptionAlert) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="h-screen">
        {isExpired ? <SubscriptionExpiry /> : <SubscriptionLimit />}
      </div>
    </>
  );
};

export default SubscriptionAlert;
