import React, { useEffect } from "react";
import { IExpense, PreviewExpense, TransactionType } from "../../@types";
import useNotifications from "../../hooks/UseNotifications";
import { CloseIcon, PDFIcon } from "../../assets/icons";
import { shortenFileName } from "../../utils";
import { Button } from "../common";
import ExpenseUploadSuccess from "../payments/RecordPayment/ExpenseUploadSuccess";
import { toast } from "react-hot-toast";
import InfoIcon from "../../assets/icons/InfoIcon";
import { handleRecordPayment } from "../../hooks/payment/handleRecordPayment";
import FileUploadComponent from "./FileUploadComponent";
import { ReceiptUpload } from "../../backend-services";
import { fileToBase64 } from "../../utils/fileToBase64";
import useUploadExpense from "../../hooks/expenses/useUploadExpense";
import { useTranslation } from "react-i18next";

interface MobileUploadExpenseProps {
  entity?: IExpense;
  onRecordEntity: (data: any) => void;
  paymentType: TransactionType;
  closeModal: () => void;
  preview: boolean;
  setPreview: (preview: boolean) => void;
  setExpenseData: (expense: PreviewExpense) => void;
  setShowPopup: (modal: boolean) => void;
}

const MobileUploadExpense: React.FC<MobileUploadExpenseProps> = ({
  entity,
  onRecordEntity,
  paymentType,
  closeModal,
  setPreview,
  preview,
  setExpenseData,
  setShowPopup,
}) => {
  const { t } = useTranslation("expenses");
  const [triggerAction, setTriggerAction] = React.useState<boolean>(false);
  useNotifications(triggerAction);
  const {
    file,
    error,
    isLoading,
    isPreviewing,
    success,
    setFile,
    handleUpload,
    handleAutoSave,
    handlePreviewAndSave,
  } = useUploadExpense({
    entity,
    onRecordEntity,
    paymentType,
    setPreview,
    preview,
    setExpenseData,
    setShowPopup,
  });

  useEffect(() => {
    return () => {
      setTriggerAction(false);
    };
  }, [handleAutoSave]);

  const handleCloseModal = () => {
    closeModal();
  };

  if (success) {
    return (
      <ExpenseUploadSuccess
        closeModal={handleCloseModal}
        text={"Go back to Expense"}
        navigateTo={"expense"}
      />
    );
  }

  return (
    <>
      <div className="md:hidden bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh]">
        <div
          className={`md:hidden overflow-y-auto overflow-x-hidden fixed bottom-0 w-full z-50 px-4 py-8 bg-white rounded-t-[8px]`}
        >
          <div className="flex justify-between items-center font-bold text-[20px] leading-[24px]">
            <p>{t("budget.uploadExpense")}</p>
            <div onClick={closeModal}>
              <CloseIcon />
            </div>
          </div>

          {error ? (
            <div className="text-center p-2 bg-red-300 text-red-700 font-medium mb-4">
              {error}
            </div>
          ) : null}

          <div className="flex justify-center mt-[16px] bg-[#F9FAFB] w-full h-[120px] text-center">
            <FileUploadComponent onUpload={handleUpload} />
          </div>

          {file && (
            <div className="flex items-center justify-center text-lg mt-3 ">
              <div className="flex justify-start">
                <PDFIcon />
                <span className="text-[#0500F9] mx-3 font-medium">
                  {shortenFileName(file)}
                </span>
              </div>
              <a href="#close" onClick={() => setFile(undefined)}>
                <CloseIcon color="red" />
              </a>
            </div>
          )}

          <div className="text-[#475467] text-[14px] font-medium">
            Click 'Auto save' and our Esemie will extract the information and
            record it in your payment history or Click 'Preview & save' to
            review, edit and manually save the receipt.
          </div>
          <div className="flex justify-center">
            <div className="flex items-center gap-6 mt-[40px]">
              <Button
                onClick={handlePreviewAndSave}
                variant="secondary"
                outline={true}
                text={"Preview & Save"}
                isLoading={isPreviewing}
                disabled={isPreviewing}
                size="small"
              />

              <Button
                onClick={handleAutoSave}
                variant="secondary"
                text={"Auto Save"}
                isLoading={isLoading}
                disabled={isLoading}
                size="small"
                outline={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileUploadExpense;
