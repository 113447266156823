import React from "react";

interface ReportIconProps {
  color?: string;
}

const ReportIcon: React.FC<ReportIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5 16.5H7.33333C4.58347 16.5 3.20854 16.5 2.35427 15.6457C1.5 14.7915 1.5 13.4165 1.5 10.6667V1.5"
          stroke={color || "currentColor"}
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M13.7553 6.77745L11.3605 10.6535C11.0116 11.2182 10.6154 12.0713 9.89704 11.945C9.05224 11.7963 8.64649 10.5371 7.92016 10.1201C7.32868 9.78059 6.90106 10.1898 6.55526 10.6663M16.5013 2.33301L14.9567 4.83301M3.16797 15.6663L5.27323 12.5552"
          stroke={color || "currentColor"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export { ReportIcon };

export const ActiveReportIcon: React.FC<ReportIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5 16.5H7.33333C4.58347 16.5 3.20854 16.5 2.35427 15.6457C1.5 14.7915 1.5 13.4165 1.5 10.6667V1.5"
          stroke="#0D3C6D"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M13.7553 6.77745L11.3605 10.6535C11.0116 11.2182 10.6154 12.0713 9.89704 11.945C9.05224 11.7963 8.64649 10.5371 7.92016 10.1201C7.32868 9.78059 6.90106 10.1898 6.55526 10.6663M16.5013 2.33301L14.9567 4.83301M3.16797 15.6663L5.27323 12.5552"
          stroke="#0D3C6D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
