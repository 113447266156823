import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { deleteBankAccount } from "../../../backend-services";
import ErrorComponent from "../../Error";
import DangerIcon from "../../../assets/icons/DangerIcon";
import { Spinner } from "flowbite-react";
import { useUserStore } from "../../../state";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "../../../state/useCompanyStore";

interface BankAccountDeletePromptProps {
  showPrompt: boolean;
  bankAccountId: number;
  bankName: string;
  accountNumber: string;
  onClose: () => void;

  setDeleteSuccess: (deleteSuccess: boolean) => void;
}

export const BankAccountDeletePrompt: React.FC<
  BankAccountDeletePromptProps
> = ({
  showPrompt,
  bankAccountId,
  onClose,
  bankName,
  accountNumber,
  setDeleteSuccess,
}) => {
  const { t } = useTranslation("settings");
  const { setCompanyBankAccounts } = useCompanyStore();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleDeleteBankAccount = async (index: number) => {
    setIsDeleting(true);
    try {
      const data = await deleteBankAccount(index);
      setDeleteSuccess(true);
      setCompanyBankAccounts(data);
      onClose();
      setIsDeleting(false);
    } catch (error: any) {
      setErrorMessage("Unable to Delete Bank Account.");
      setIsDeleting(false);
      if (
        error.bankAccount &&
        (error.bankAccount.status === 400 || error.bankAccount.status === 500)
      ) {
        return <ErrorComponent errorCode={500} />;
      }
    }
  };

  const handleKeepBankAccount = () => {
    onClose();
  };
  return (
    <>
      {showPrompt && (
        <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh]">
          <div className="flex justify-center items-center h-full w-full">
            <div
              className={`fixed top-[25%] z-50 w-[320px] md:w-[457px] p-5 md:p-[30px] bg-white rounded-[12px]`}
            >
              <div className="flex justify-center">
                <DangerIcon />
              </div>
              <div className="mt-5 md:mt-[27px] text-center">
                <h2 className="mb-4 md:mb-6 leading-6 text-[20px] font-bold">
                  {t("Settings.deletebankaccount")}
                </h2>
                <p>
                  You are about to permanently delete bank account{" "}
                  <span className="font-semibold">
                    {accountNumber} -{bankName}
                  </span>
                  . This action cannot be reversed once confirmed.
                </p>
              </div>

              <div className="flex justify-between gap-[8%] mt-5 font-semibold">
                <button
                  onClick={handleKeepBankAccount}
                  type="button"
                  className="w-full text-[#FB0303] h-12 border border-[#FB0303] rounded"
                >
                  Cancel
                </button>
                <button
                  disabled={isDeleting}
                  onClick={() => handleDeleteBankAccount(bankAccountId)}
                  type="button"
                  className="w-full text-white bg-[#FB0303] rounded"
                >
                  {isDeleting ? <Spinner /> : "Delete Account"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
