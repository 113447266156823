import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ISetReminder } from "../../../../../@types";
import ArrowLeft from "../../../../../assets/icons/ArrowLeft";
import ArrowRight from "../../../../../assets/icons/ArrowRight";
import EditIcon from "../../../../../assets/icons/EditIcon";
import { updateIndividualReminders } from "../../../../../backend-services";
import { useUserStore } from "../../../../../state";
import { useCompanyStore } from "../../../../../state/useCompanyStore";
import AutomateViewInvoice from "./AutomateReminder";

interface ReminderInvoiceOptionsProps {
  onReminderChange: (data: {
    activeStatus: { before: boolean; due: boolean; after: boolean };
    days: { before: number; due: number; after: number };
  }) => void;
  invoiceId?: string;
}

const ReminderViewOptions: React.FC<ReminderInvoiceOptionsProps> = ({
  onReminderChange,
  invoiceId,
}) => {
  const { user, setUser,  } = useUserStore();
  const { setReminder} = useCompanyStore();
  const userId = user?.id;
  const [reminderActiveStatus, setReminderActiveStatus] = useState({
    before: false,
    due: false,
    after: false,
  });

  const [reminderDays, setReminderDays] = useState({
    before: 0,
    due: 0,
    after: 0,
  });
  const [isSending, setIsSending] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [currentReminderType, setCurrentReminderType] = useState<
    "before" | "due" | "after"
  >("before");

  const getReminderStorageKey = (userId: string | undefined) => {
    return userId ? `reminderData_${userId}` : "";
  };

  useEffect(() => {
    if (userId) {
      const storageKey = getReminderStorageKey(userId);
      if (storageKey) {
        const savedReminderData = localStorage.getItem(storageKey);
        if (savedReminderData) {
          try {
            const parsedData = JSON.parse(savedReminderData);

            setReminderActiveStatus({
              before: parsedData.before?.isActive ?? false,
              due: parsedData.due?.isActive ?? false,
              after: parsedData.after?.isActive ?? false,
            });

            setReminderDays({
              before: parsedData.before?.days ?? 0,
              due: parsedData.due?.days ?? 0,
              after: parsedData.after?.days ?? 0,
            });
          } catch (error) {
            console.error("Error parsing reminder:", error);
          }
        }
      }
    }
  }, [userId]);

  useEffect(() => {
    onReminderChange({
      activeStatus: reminderActiveStatus,
      days: reminderDays,
    });
  }, []);

  const handleToggleActive = (type: "before" | "due" | "after") => {
    setCurrentReminderType(type);
    setShowReminderModal(true);
  };
  const handleReminderSave = async (reminderData: {
    days: number;
    remindBy: "before" | "due" | "after";
    isActive: boolean;
    remindMe: boolean;
  }) => {
    if (!invoiceId) {
      toast.error("Invoice ID is required");
      return;
    }

    try {
      setIsSending(true);
      const payload = {
        days: reminderData.days,
        remindBy: reminderData.remindBy,
        isActive: reminderData.isActive,
        remindMe: reminderData.remindMe,
      };

      // Proceed only if invoiceId is valid
      await updateIndividualReminders(invoiceId, payload);

      setReminderActiveStatus((prev) => {
        const updatedStatus = {
          ...prev,
          [reminderData.remindBy]: true,
        };
        saveRemindersToLocalStorage(updatedStatus, reminderDays);
        return updatedStatus;
      });

      setReminderDays((prev) => {
        const updatedDays = {
          ...prev,
          [reminderData.remindBy]: reminderData.days,
        };
        saveRemindersToLocalStorage(reminderActiveStatus, updatedDays);
        return updatedDays;
      });

      setReminder({
        remindBy: reminderData.remindBy,
        days: reminderData.days,
        isActive: reminderData.isActive,
        remindMe: reminderData.remindMe,
      });

      toast.success("Reminder enabled successfully!");
      setShowReminderModal(false);
    } catch (error) {
      toast.error("Failed to set reminder");
      console.error("Failed to set reminder:", error);
    } finally {
      setIsSending(false);
    }
  };

  const saveRemindersToLocalStorage = (
    activeStatus: { before: boolean; due: boolean; after: boolean },
    days: { before: number; due: number; after: number },
  ) => {
    if (!userId) return;

    const storageKey = getReminderStorageKey(userId);
    if (!storageKey) return;

    const reminderData = {
      before: {
        days: days.before ?? 0,
        isActive: activeStatus.before ?? false,
        remindBy: "before",
      },
      due: {
        days: days.due ?? 0,
        isActive: activeStatus.due ?? false,
        remindBy: "due",
      },
      after: {
        days: days.after ?? 0,
        isActive: activeStatus.after ?? false,
        remindBy: "after",
      },
    };

    localStorage.setItem(storageKey, JSON.stringify(reminderData));
  };

  const handleEditReminder = (type: "before" | "due" | "after") => {
    setCurrentReminderType(type);
    setShowReminderModal(true);
  };

  const renderReminderRow = (
    label: string,
    type: "before" | "due" | "after",
    description: string,
  ) => (
    <div className="flex gap-2 mt-[-0.6em] text-sm">
      <div className="bg-[#F9FAFB] px-3 py-2 rounded-[8px] border border-[#044E9733] border-opacity-10 mt-4 flex items-center justify-between w-full">
        <div className="flex gap-2 items-center">
          <p className="text-[#044E97]">{label}</p>
          <span className="pl-4">
            {type === "due"
              ? "Due date"
              : `${reminderDays[type]} days ${description}`}
          </span>
        </div>
        <div
          className={`w-12 h-6 p-1 rounded-[69.33px] border-[0.53px] border-[#044E97] flex items-center transition duration-200 ease-in ${
            reminderActiveStatus[type]
              ? "bg-[#044E97] justify-end"
              : "bg-[#ffffff] justify-start"
          }`}
          onClick={() => handleToggleActive(type)}
        >
          <div
            className={`${
              reminderActiveStatus[type] ? "bg-[#ffffff]" : "bg-[#D1D5DB]"
            } cursor-pointer w-4 h-4 rounded-full flex justify-center items-center`}
          >
            {reminderActiveStatus[type] ? <ArrowLeft /> : <ArrowRight />}
          </div>
        </div>
      </div>
      {(reminderActiveStatus[type] && reminderDays[type] > 0) ||
        (reminderActiveStatus[type] && (
          <div
            onClick={() => handleEditReminder(type)}
            className="cursor-pointer border border-[#044E9733] rounded-md bg-[#F3FBFB] border-opacity-10 mt-4 flex justify-center items-center py-1 px-1"
          >
            <EditIcon />
          </div>
        ))}
    </div>
  );

  return (
    <div className="text-[10px] lg:text-[10px] mt-[0.5em] whitespace-nowrap cursor-pointer">
      {renderReminderRow("Reminder - 1", "before", "before due date")}
      {renderReminderRow("Reminder - 2", "due", " due date")}
      {renderReminderRow("Reminder - 3", "after", "after due date")}

      {showReminderModal && (
        <AutomateViewInvoice
          setShowSendModal={setShowReminderModal}
          onSaveReminder={handleReminderSave}
          reminderType={currentReminderType}
          days={reminderDays[currentReminderType]}
        />
      )}
    </div>
  );
};

export default ReminderViewOptions;
