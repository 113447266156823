import React, { lazy, useEffect, useMemo, useRef, useState } from "react";
import { createDraft, updateQuote } from "../../../backend-services";
import { Button, SidebarDrawer } from "../../common";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ICreateSalesData, ICustomer, ItemInput } from "../../../@types";
import {
  createDraftData,
  createInvoiceData,
  createQuoteData,
  InvoiceValidationErrors,
} from "../../../modules/invoice/invoice";
import {
  InvoiceErrors,
  validateInvoiceBasicInfo,
  validateInvoiceItemDetails,
  validateInvoiceSummary,
} from "../../../modules/validateInvoiceForm";
import { clearFieldError, handleErrors } from "../../../utils/handleErrors";
import toast from "react-hot-toast";
import useLineItems from "../../../modules/lineItem";
import { useUserStore } from "../../../state";
import LogoComponent from "../create/SalesCreate/LogoComponent";
import LineTable from "../create/SalesCreate/LineTable";
import InvoiceCalculation from "../create/SalesCreate/InvoiceCalculation";
import BankDetails from "../create/SalesCreate/BankDetails";
import Notes from "../create/SalesCreate/Notes";
import CustomerCreateInfo from "../create/SalesCreate/CustomerInfo";
import { updateProfile } from "../../../backend-services";
import CreateInvoice from "../create/SalesCreate/CreateInvoice";
import QuoteDate from "../create/SalesCreate/QuoteDate";
import {
  validateQuoteBasicInfo,
  validateQuoteItemDetails,
  validateQuoteSummary,
} from "../../../modules/validateQuoteForm";
import InvoiceCurrency from "../create/SalesCreate/InvoiceCurrency";
import { CreateModal } from "../../common/CreateModal";
import SuccessBanner from "../../common/Banner/SuccessBanner";
import { useTranslation } from "react-i18next";
import CompanyUpdate from "../create/SalesCreate/companyProfile";
import { checkSubscription } from "../../../utils/subscription";
import { servicesType } from "../../../constants/Subscription";
import CreateQuote from "../create/SalesCreate/CreateQuote";
import { useCompanyStore } from "../../../state/useCompanyStore";

interface QuoteFormProps {
  customerId?: string;
  quoteData: ICreateSalesData;
  setQuoteData: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
  headingText: string;
  updatedNewItem?: ItemInput;
}
const QuoteParent: React.FC<QuoteFormProps> = (props) => {
  const { t } = useTranslation("quotes");
  const userStore = useCompanyStore();
  const isRetail = userStore.isRetailBusiness();
  const initialFormData: ICreateSalesData = {
    additionalInformation: "",
    customerId: "",
    description: "",
    currency: "NGN",
    discount: 0,
    inventories: [
      {
        id: "",
        name: "",
        amount: 0,
        quantity: 0,
        rate: 0,
        variations: [
          {
            type: "",
            name: "",
            id: "",
            price: 0,
            quantity: 0,
          },
        ],
      },
    ],
    services: [{ id: "", name: "", duration: "", price: 0 }],
    expiryDate: "",
    invoiceNumber: "",
    issueDate: new Date().toISOString().substring(0, 10),
    isReoccurring: false,
    isReoccurringEndDate: "",
    isReoccurringStartDate: "",
    reoccurringGroupName: "",
    status: "pending",
    tax: 0,
    isPaymentCompleted: false,
    shouldSendEmail: true,
    exchangeRate: 1,
    paymentMethod: "",
    invoiceType: "inventory",
    logoBase64: "",
  };

  const [formData, setFormData] =
    React.useState<ICreateSalesData>(initialFormData);
  React.useEffect(() => {
    if (props.quoteData) {
      setFormData((prevState: any) => ({
        ...prevState,
        customerId: props.quoteData.customerId,
        customer: props.quoteData.customerId ? customer : null,
        description: props.quoteData.description,
        discount: props.quoteData.discount,
        inventories: props.quoteData.inventories,
        services: props.quoteData.services,
        expiryDate: props.quoteData.expiryDate,
        issueDate: props.quoteData.issueDate,
        shippingCharge: props.quoteData.shippingCharge,
        status: props.quoteData.status,
        tax: props.quoteData.tax,
        shouldSendEmail: props.quoteData.shouldSendEmail,
        currency: props.quoteData.currency,
        exchangeRate: props.quoteData.exchangeRate,
        invoiceType: props.quoteData.invoiceType,
      }));
    }
  }, [props.quoteData]);

  const resetForm = () => {
    setFormData({
      ...(initialFormData as ICreateSalesData),
      invoiceDueAt: "",
      additionalInformation: "",
    });
    setValidationResults({});
    setCustomer(null);

    // Reset line items (inventories and services)
    setItems([{ id: "", name: "", rate: 0, quantity: 0 }]);
    setShowSidebar(false);
  };

  const [validationResults, setValidationResults] =
    React.useState<InvoiceErrors>({});
  const { invoiceNo } = useParams<{ invoiceNo: string }>();
  const navigate = useNavigate();
  const [customer, setCustomer] = React.useState<ICustomer | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDraftLoading, setIsisDraftLoading] = React.useState<boolean>(false);
  const [subTotal, setSubtotal] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [calcTax, setCalcTax] = React.useState(0);
  const [calcDiscount, setCalcDiscount] = React.useState(0);
  const [showSidebar, setShowSidebar] = React.useState(false);
  const [showCreateSidebar, setShowCreateSidebar] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [createdQuoteId, setCreatedQuoteId] = React.useState("");
  const [createdQuote, setCreatedQuote] = useState(null);
  const [createdEmail, setCreatedEmail] = React.useState("");
  const [showRateInput, setShowRateInput] = useState(false);
  const [markAsPaid, setMarkAsPaid] = React.useState(false);
  const [sendEmail, setSendEmail] = React.useState(false);
  const [shouldSendWhatsApp, setShouldSendWhatsApp] = React.useState(false);
  const [shouldSendSMS, setShouldSendSMS] = React.useState(false);
  const [selectedCurrencyLabel, setSelectedCurrencyLabel] =
    React.useState("₦ Naira");
  const [selectedVariation, setSelectedVariation] = useState<
    string | undefined
  >(undefined);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
    React.useState<string>("");
  const [error, setError] = React.useState<string>("");
  const [isReoccurringChecked, setIsReoccurringChecked] = React.useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerLinkText, setBannerLinkText] = useState("");

  const [draftValidationResults, setDraftValidationResults] =
    React.useState<InvoiceValidationErrors>({
      customerId: undefined,
      invoiceNumber: undefined,
      invoiceDueAt: "",
      description: undefined,
      status: undefined,
      issueDate: undefined,
      additionalInformation: undefined,
      shippingCharge: undefined,
      discount: undefined,
      tax: undefined,
      isReoccurring: undefined,
      isReoccurringStartDate: undefined,
      isReoccurringEndDate: undefined,
      reoccurringGroupName: undefined,
      task: undefined,
      inventories: undefined,
      services: undefined,
      currency: undefined,
      exchangeRate: undefined,
      paymentMethod: undefined,
      invoiceType: undefined,
    });

  const { items, addItem, setItems, removeItem, updateCell, updateRow } =
    useLineItems([{ id: "", name: "", rate: 0, quantity: 0 }]);
  const { quoteNo } = useParams<{ quoteNo: string }>();
  const [isEditing, setIsEditing] = useState<boolean>(!!quoteNo);

  React.useEffect(() => {
    if (!isEditing) {
      clearFieldError("inventories", validationResults, setValidationResults);
      setFormData((prevState: ICreateSalesData) => {
        return {
          ...prevState,
          inventories: items.map((item: ItemInput) => ({
            id: item.id,
            name: item.name,
            amount: 0,
            quantity: item.quantity || 0,
            rate: item.rate || 0,
            variations: item.variation || [],
          })),
        } as ICreateSalesData;
      });
    }
  }, [items, isEditing]);

  React.useEffect(() => {
    if (!isEditing) {
      clearFieldError("services", validationResults, setValidationResults);
      setFormData((prevState: ICreateSalesData) => ({
        ...prevState,
        services: items,
      }));
    }
  }, [items, isEditing]);

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFormData((prevData) => {
      return { ...prevData, [name]: value };
    });
    clearFieldError(name, validationResults, setValidationResults);
  };

  useEffect(() => {
    let subTotal = 0;

    // Calculate subtotal based on the type of business
    if (isRetail && formData && formData.inventories) {
      for (const inventory of formData.inventories) {
        if (inventory.variations && inventory.variations.length > 0) {
          const variationToUse = selectedVariation
            ? inventory.variations.find(
                (variation) => variation.type === selectedVariation,
              )
            : inventory.variations[0];

          if (variationToUse) {
            subTotal += inventory.quantity * inventory.rate;
          }
        } else {
          subTotal += inventory.quantity * inventory.rate;
        }
      }
    } else if (!isRetail && formData && formData.services) {
      for (const service of formData.services) {
        const serviceAmount = service.price;
        subTotal += serviceAmount;
      }
    }

    let discountAmount =
      subTotal * (formData?.discount ? formData.discount / 100 : 0);
    let taxAmount = subTotal * (formData?.tax ? formData.tax / 100 : 0);
    let total = subTotal - discountAmount + taxAmount;

    setSubtotal(subTotal);
    setTotal(total);
    setCalcTax(taxAmount);
    setCalcDiscount(discountAmount);
  }, [formData, userStore, selectedVariation]);
  useEffect(() => {
    setFormData((prevData) => {
      return { ...prevData, total: total.toFixed(2).toString() };
    });
  }, [total]);

  useEffect(() => {
    handleDropDownChange("currency", selectedCurrencyLabel);
  }, []);

  const handleDropDownChange = (name: string, value: string) => {
    clearFieldError(name, validationResults, setValidationResults);
    const currencyValue = value === "₦ Naira" ? "NGN" : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: currencyValue || "NGN",
    }));
    setSelectedCurrencyLabel(value);
    setShowRateInput(currencyValue !== "NGN");
  };

  const location = useLocation();
  const { customerId } = useParams();
  React.useEffect(() => {
    if (customerId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: decodeURIComponent(customerId || ""),
      }));
    }
  }, [customerId]);

  const deepCompare = (arr1: any[], arr2: any[]) => {
    if (arr1.length !== arr2.length) return false;

    return arr1.every((item, index) => {
      return JSON.stringify(item) === JSON.stringify(arr2[index]);
    });
  };

  const prevItemsRef = useRef<ItemInput[]>([]);

  useEffect(() => {
    if (isEditing && props.quoteData) {
      const {
        customer,
        inventories = [],
        services = [],
        customerId,
        currency,
        status,
        exchangeRate,
      } = props.quoteData;

      setFormData((prev) => ({
        ...prev,
        customerId: customerId || "",
        currency: currency || "NGN",
        status: status || "active",
        exchangeRate: exchangeRate || 1,
        inventories: inventories || [],
        services: services || [],
      }));

      if (customer) {
        setCustomer(customer);
      }
    }
  }, [isEditing, props.quoteData]);

  useEffect(() => {
    if (isEditing) {
      const newItems = isRetail ? formData.inventories : formData.services;
      if (!deepCompare(newItems, prevItemsRef.current)) {
        setItems(newItems);
        prevItemsRef.current = newItems;
      }
    }
  }, [formData.inventories, formData.services, isRetail, isEditing]);

  const handleSetCustomer = (selectedCustomer: ICustomer | null) => {
    if (selectedCustomer) {
      setCustomer(selectedCustomer);
      if (selectedCustomer.id) {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          customerId: selectedCustomer.id,
          name: `${selectedCustomer.firstName} ${selectedCustomer.lastName}`,
        }));
      }
    } else {
      setCustomer(null);
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        customerId: "",
        name: "",
      }));
    }
  };

  const setLogoUrl = (url: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      logoBase64: url,
    }));
  };

  const handleEditProfileLogo = async () => {
    setIsLoading(true);
    try {
      const updatedLogo = { brandLogo: formData.logoBase64 };
      await updateProfile(updatedLogo);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setErrorMessage("Unable to update profile logo.");
    }
  };

  useEffect(() => {
    if (
      typeof formData.exchangeRate !== "number" &&
      isNaN(Number(formData.exchangeRate))
    ) {
      // Set the exchange rate to 1
      setFormData((prevData) => ({
        ...prevData,
        exchangeRate: 1,
      }));
    }
  }, [formData.exchangeRate]);

  const handleViewQuote = () => {
    if (createdQuote) {
      const viewQuoteRoute = `/quotes/${createdQuote}/view`;
      navigate(viewQuoteRoute);
    }
  };

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  const processCreateQuote = async (
    sendEmail: boolean,
    markedAsPaid: boolean,
    shouldSendWhatsApp: boolean,
    shouldSendSMS: boolean,
    quoteNo?: string,
  ) => {
    try {
      setIsLoading(true);
      setShowCreateSidebar(false);

      const {
        expiryDate,
        issueDate,
        inventories,
        services,
        discount,
        tax,
        customerId,
      } = formData;
      const user = userStore.user;
      const isRetail = userStore.isRetailBusiness();
      const validExpiry = expiryDate ? new Date(expiryDate) : null;
      const validIssueDate = issueDate ? new Date(issueDate) : null;

      if (!validExpiry || isNaN(validExpiry.getTime())) {
        throw new Error("Invalid expiry date");
      }

      if (!validIssueDate || isNaN(validIssueDate.getTime())) {
        throw new Error("Invalid issue date");
      }
      const quoteData: ICreateSalesData = {
        customerId: customerId,
        shouldSendEmail: sendEmail,
        isPaymentCompleted: markedAsPaid,
        shouldSendWhatsApp: shouldSendWhatsApp,
        shouldSendSMS: shouldSendSMS,
        expiryDate: validExpiry.toISOString(),
        issueDate: validIssueDate.toISOString(),
        inventories: isRetail
          ? inventories.length
            ? inventories.map(
                (i: {
                  id: string;
                  name: string;
                  rate: number;
                  quantity: number;
                  variations?: any;
                }) => ({
                  id: i.id,
                  name: i.name,
                  amount: 0,
                  quantity: Number(i.quantity),
                  rate: Number(i.rate),
                  variations: i.variations ? i.variations : undefined,
                }),
              )
            : []
          : [],

        services: !isRetail
          ? services.length
            ? services.map(
                (s: { id: ""; name: ""; duration: ""; price: "" }) => ({
                  id: s.id,
                  name: s.name,
                  duration: s.duration,
                  price: Number(s.price),
                }),
              )
            : []
          : [],
        discount: Number(discount ? discount : 0),
        tax: Number(tax ? tax : 0),
        status: formData.status || "pending",
        description: formData.description || "",
        additionalInformation: formData.additionalInformation || "",
        currency: formData.currency || "",
        exchangeRate: formData.exchangeRate,
        paymentMethod: formData.paymentMethod || "",
        invoiceType: isRetail ? "inventory" : "service",
      };
      if (customer) {
        if (quoteNo) {
          const taxValue = formData.tax ? Number(formData.tax) / 100 : 0;
          const formattedTax = taxValue.toFixed(2);

          const updatedQuoteData = {
            expiryDate: validExpiry.toISOString(),
            issueDate: validIssueDate.toISOString(),
            discount: Number(formData.discount || 0),
            tax: Number(formattedTax),

            ...(isRetail
              ? {
                  inventories: inventories.length
                    ? inventories.map(
                        (i: {
                          id: string;
                          name: string;
                          rate: number;
                          quantity: number;
                          variations?: any;
                        }) => ({
                          id: i.id,
                          name: i.name,
                          amount: 0,
                          quantity: Number(i.quantity),
                          rate: Number(i.rate),
                          variations: i.variations ? i.variations : undefined,
                        }),
                      )
                    : [],
                }
              : {
                  services: services.length
                    ? services.map(
                        (s: { id: ""; name: ""; duration: ""; price: "" }) => ({
                          id: s.id,
                          name: s.name,
                          duration: s.duration,
                          price: Number(s.price),
                        }),
                      )
                    : [],
                }),
          };
          const createdUpdatedQuote = await updateQuote({
            id: quoteNo,
            data: updatedQuoteData,
          });
          const createdUpdatedQuoteId = createdUpdatedQuote?.id;
          const createdEmail = customer.email || "";
          setCreatedQuote(createdUpdatedQuoteId);
          setCreatedEmail(createdEmail);
          setBannerMessage(" Quote Updated Successfully. To view quote, Click");
          setBannerLinkText("View Quote");
          setIsBannerVisible(true);
        } else {
          const createdQuote = await createQuoteData(
            quoteData,
            customer,
            isRetail,
            quoteNo,
          );

          const createdQuoteId = createdQuote?.id;
          const createdEmail = customer.email || "";
          setCreatedQuote(createdQuoteId);
          setCreatedEmail(createdEmail);

          setBannerMessage(
            "New Quote Created Successfully. To view quote, Click",
          );
          setBannerLinkText("View Quote");
          setIsBannerVisible(true);
        }
      } else {
      }
    } catch (error: any) {
      handleErrors(error, setErrorMessage);
      toast.error("An error occurred while processing the quote.");
      if (error.response) {
        const errorMessage = error.response.data;
        setError(errorMessage);
      }
      setIsLoading(false);
    }
  };

  const handleCreateQuote = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();

    try {
      const basicInfoValid = validateQuoteBasicInfo(
        formData,
        setValidationResults,
      );
      const itemDetailsValid = validateInvoiceItemDetails(
        formData,
        setValidationResults,
        userStore.isRetailBusiness(),
      );
      const summaryValid = validateInvoiceSummary(
        formData,
        setValidationResults,
      );

      if (basicInfoValid && itemDetailsValid && summaryValid) {
        setShowSidebar(true);
      } else {
        setErrorMessage("Please fill out fields correctly.");
        toast.error("Please fill out fields correctly.");
        const errorElements = [
          { id: "customer-info", hasError: !basicInfoValid },
          { id: "quote-date", hasError: !basicInfoValid },
          { id: "quote-currency", hasError: !basicInfoValid },
          { id: "line-table", hasError: !itemDetailsValid },
        ];

        const firstErrorElement = errorElements.find((el) => el.hasError);

        if (firstErrorElement) {
          const element = document.getElementById(firstErrorElement.id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }
      }
    } catch (error) {
      console.error("Failed to create invoice:", error);
      toast.error("Failed to create invoice. Please try again later.");
    }
  };

  const onCreateQuote = async () => {
    await processCreateQuote(
      sendEmail,
      markAsPaid,
      shouldSendWhatsApp,
      shouldSendSMS,
      quoteNo,
    );

    resetForm();
    setIsBannerVisible(true);
    setShowSidebar(false);
  };

  function handleSendEmailChange(checked: boolean) {
    setSendEmail(checked);
  }
  function handleMarkAsPaidChange(event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) {
    setMarkAsPaid(event.target.checked);
  }
  function handleshouldSendWhatsAppChange(checked: boolean) {
    setShouldSendWhatsApp(checked);
  }
  function handleshouldSendSMSChange(checked: boolean) {
    setShouldSendSMS(checked);
  }
  const handleIsReoccurringChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const target = e.target as HTMLInputElement;
    setFormData((prevData) => ({
      ...prevData,
      isReoccurring: target.checked,
    }));
    setIsReoccurringChecked(target.checked);
  };

  const handlePaymentDropDownChange = (name: string, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      {isBannerVisible && (
        <SuccessBanner
          message={bannerMessage}
          linkText={bannerLinkText}
          onLinkClick={handleViewQuote}
          onClose={handleCloseBanner}
        />
      )}
      <div className="flex items-left gap-[8.5px] ml-[2em]">
        <a href="/quotes">
          <svg
            className="mt-[-2px] ml-[-2em] "
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8.5 1L1.5 8L8.5 15" stroke="#33363F" strokeWidth="2" />
          </svg>
        </a>
        <h1 className="hidden lg:block md:block flex items-center xl:text-[20px] text-[16px] ml-[-1em] mt-[-0.4em] xl:mt-[-0.4em] font-bold">
          Create Quote
        </h1>
        <h1 className="lg:hidden  md:hidden flex items-center xl:text-[20px] text-[16px] ml-[-1em] mt-[-0.4em] xl:mt-[-0.4em] font-bold">
          Add New Quote
        </h1>
      </div>
      <div
        className="mx-auto h-full bg-white lg:ml-0 border-2 border-gray-100 mt-3 "
        id="quote-parent"
      >
        {errorMessage && (
          <p className="text-[red] lg:pl-[3em] pl-3 md:pl-[3em] mt-3">
            {errorMessage}
          </p>
        )}
        <div className="flex justify-between lg:pt-[4em] pt-[2em] ">
          <div className="lg:pl-[3em] pl-3 md:pl-[3em]">
            <LogoComponent
              logoUrl={formData.logoBase64 || ""}
              setLogoUrl={setLogoUrl}
              error={undefined}
              onUploadSuccess={handleEditProfileLogo}
            />
          </div>
          <h1 className="xl:text-[24px] biglg:text-[24px] text-[14px] font-medium lg:px-[3em] pr-3 md:pr-[3em]">
            QUOTE
          </h1>
        </div>

        <div className="hidden lg:block md:block">
          <div className="flex justify-between ">
            <div className="flex flex-col mt-[2em]">
              <div className="pl-3 lg:pl-[3em] ">
                <CompanyUpdate
                  show={true}
                  toolTipText={t("businessNameTooltip")}
                  toolTipText2={t("businessAddressTooltip")}
                  toolTipText3={t("phoneNumberTooltip")}
                  toolTipText4={t("RCNumberTooltip")}
                />
              </div>
              <div
                id="customer-info"
                className="lg:pl-[3em] pl-3 md:pl-[3em] mt-[-1em]"
              >
                <CustomerCreateInfo
                  handleSetCustomer={handleSetCustomer}
                  formData={formData}
                  customer={customer}
                  setValidationResults={setValidationResults}
                  validationResults={validationResults}
                  toolTipText={t("billTooltip")}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <div id="quote-date" className="pr-[1em] md:pr-[3em] mt-[-1em]">
                <QuoteDate
                  handleDropDownChange={handleDropDownChange}
                  formData={formData}
                  selectedCurrencyLabel={selectedCurrencyLabel}
                  setValidationResults={setValidationResults}
                  validationResults={validationResults}
                  setFormData={setFormData}
                  toolTipText={t("quote.IssuedTooltip")}
                  toolTipText2={t("quote.dueTooltip")}
                  handleInputChange={handleInputChange}
                />
              </div>
              <div id="invoice-currency" className="lg:mt-[3em]">
                <InvoiceCurrency
                  handleDropDownChange={handleDropDownChange}
                  formData={formData}
                  selectedCurrencyLabel={selectedCurrencyLabel}
                  setValidationResults={setValidationResults}
                  validationResults={validationResults}
                  setFormData={setFormData}
                  showRateInput={showRateInput}
                  toolTipText={t("currencyTooltip")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="lg:hidden md:hidden">
          <div className="flex justify-between ">
            <div className="flex flex-col mt-[2em]">
              <div className="pl-3 lg:pl-[3em] ">
                <CompanyUpdate show={true} />
              </div>
            </div>
            <div className="flex flex-col">
              <div id="quote-date" className="mr-[4em] md:pr-[3em] mt-[-2.2em]">
                <QuoteDate
                  handleDropDownChange={handleDropDownChange}
                  formData={formData}
                  selectedCurrencyLabel={selectedCurrencyLabel}
                  setValidationResults={setValidationResults}
                  validationResults={validationResults}
                  setFormData={setFormData}
                  toolTipText={t("quote.IssuedTooltip")}
                  toolTipText2={t("quote.dueTooltip")}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div
            id="customer-info"
            className="lg:pl-[3em] pl-3 md:pl-[3em] mt-[-1em]"
          >
            <CustomerCreateInfo
              handleSetCustomer={handleSetCustomer}
              formData={formData}
              customer={customer}
              setValidationResults={setValidationResults}
              validationResults={validationResults}
            />
          </div>
          <div id="invoice-currency" className="lg:mt-[3em]">
            <InvoiceCurrency
              handleDropDownChange={handleDropDownChange}
              formData={formData}
              selectedCurrencyLabel={selectedCurrencyLabel}
              setValidationResults={setValidationResults}
              validationResults={validationResults}
              setFormData={setFormData}
              showRateInput={showRateInput}
            />
          </div>
        </div>
        <hr className="lg:hidden md:hidden mt-[2em]" />
        <p className="lg:hidden md:hidden text-[9px] font-semibold flex justify-center text-center mt-3">
          Item Details
        </p>
        <div id="line-table" className="lg:mt-[4em] mt-[-1em] md:px-[3em]">
          <LineTable
            items={items}
            addItem={addItem}
            setItems={setItems}
            removeItem={removeItem}
            updateCell={updateCell}
            updateRow={updateRow}
            formData={formData}
            setFormData={setFormData}
            quoteNo={quoteNo}
            validationResults={validationResults}
            setValidationResults={setValidationResults}
            selectedCurrencyLabel={selectedCurrencyLabel}
          />
        </div>
        <div className="flex justify-end ">
          <div className="pr-[3em]">
            <InvoiceCalculation
              subTotal={subTotal}
              formData={formData}
              calcDiscount={calcDiscount}
              calcTax={calcTax}
              total={total}
              handleInputChange={handleInputChange}
            />
          </div>
        </div>
        <hr className="border mt-[3em]" />
        <div className="hidden lg:block md:block">
          <div className=" flex justify-between md:gap-[3em]">
            <div className="pl-[3em]">
              <BankDetails />
            </div>
            <div className="pr-[3em]">
              <Notes
                formData={formData}
                handleInputChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="lg:hidden md:hidden flex flex-col">
          <div className="px-[1em]">
            <BankDetails />
          </div>
          <div className="px-[1em]">
            <Notes formData={formData} handleInputChange={handleInputChange} />
          </div>
        </div>
        <hr className="border mt-[3em]" />
        <div className="flex justify-center ">
          <div className="hidden md:block mt-5">
            <Button
              text={isEditing ? "Save" : "Create Quote"}
              size="medium"
              type="submit"
              onClick={isEditing ? onCreateQuote : handleCreateQuote}
            />
          </div>
        </div>
        <div className="flex justify-center md:hidden lg:hidden">
          <div className="md:hidden mt-5 mb-4">
            <Button
              text={isEditing ? "Save" : "Create Quote"}
              size="small"
              type="submit"
              onClick={isEditing ? onCreateQuote : handleCreateQuote}
            />
          </div>
        </div>
        {showSidebar && (
          <CreateModal>
            <CreateQuote
              formData={formData}
              handleInputChange={handleInputChange}
              handlePaymentDropDownChange={handlePaymentDropDownChange}
              handleIsReoccurringChange={handleIsReoccurringChange}
              validationResults={validationResults}
              invoiceNo={invoiceNo}
              sendEmail={sendEmail}
              markAsPaid={markAsPaid}
              shouldSendWhatsApp={shouldSendWhatsApp}
              shouldSendSMS={shouldSendSMS}
              handleMarkAsPaidChange={handleMarkAsPaidChange}
              handleSendEmailChange={handleSendEmailChange}
              handleshouldSendWhatsAppChange={handleshouldSendWhatsAppChange}
              handleshouldSendSMSChange={handleshouldSendSMSChange}
              errorMessage={errorMessage}
              error={error}
              customer={customer}
              showMarkAsPaid={true}
              onCreateQuote={onCreateQuote}
              isLoading={isLoading}
              onClose={() => setShowSidebar(false)}
              setValidationResults={setValidationResults}
            />
          </CreateModal>
        )}
      </div>
    </>
  );
};

export default QuoteParent;
