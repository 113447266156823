import React, { useEffect, useState } from "react";
import { DropdownComponent, InputComponent } from "../core/Forms";
import useExpenseDetails from "../../hooks/expenses/previewExpense/useExpenseDetails";
import useLineItems from "../../hooks/expenses/previewExpense/useLineItems";
import { ICustomer, IVendor, PreviewExpense } from "../../@types";
import { formattedDate } from "../../utils/formatDate";
import { formatMoney } from "../../utils";
import { commasToRestockNumber } from "../../utils/convertToFloat";
import { EXPENSE_CATEGORY, PAYMENT_PROVIDER_LIST } from "../../constants";
import handleSavePreviewExpense from "../../utils/expense";
import { SearchInput } from "../common";
import { useCustomerSearch, useVendorSearch } from "../../hooks";
import { CloseIcon, SearchIcon } from "../../assets/icons";
import { AddVendors } from "../vendors/Addvendor";
import { Spinner } from "flowbite-react";

interface PreviewModalProps {
  expenseData: PreviewExpense | undefined;
  setPreview: (preview: boolean) => void;
}

const MobilePreviewModal = ({ expenseData, setPreview }: PreviewModalProps) => {
  const dropDownCategory = EXPENSE_CATEGORY;
  const dropDownPaymentMethod = PAYMENT_PROVIDER_LIST;
  const [isLoading, setIsLoading] = useState(false);
  const [expense, setExpense] = React.useState<PreviewExpense | null>(null);
  const { expenseDetails, setExpenseDetails, handleExpenseDetails } =
    useExpenseDetails();
  const {
    lineItems,
    setLineItems,
    handleLineItem,
    errorLineItem,
    setErrorLineItems,
  } = useLineItems([]);
  const {
    data: customers,
    isSearching: isCustomerSearching,
    handleSearch: handleCustomerSearch,
  } = useCustomerSearch();
  const [validationResults, setValidationResults] = React.useState<any>({
    vendorName: "",
    date: "",
    lineItems: [],
    category: "",
    paymentMethod: "",
  });

  const {
    data: vendors,
    isSearching: isVendorSearching,
    setSearchedDone,
    searchedDone,
    handleSearch: handleVendorSearch,
  } = useVendorSearch();

  const [showNewVendor, setShowNewVendor] = React.useState<boolean>(false);
  const [customer, setCustomer] = React.useState<ICustomer | undefined>();
  const openNewVendor = () => {
    setShowNewVendor(true);
  };
  const [vendorName, setVendorName] = React.useState<IVendor | undefined>();
  const handleSelectedVendor = (selectedVendor: IVendor | null) => {
    if (selectedVendor) {
      setVendorName(selectedVendor);
      if (selectedVendor.name) {
        setExpenseDetails((prevFormData) => ({
          ...prevFormData,
          vendorId: selectedVendor.id,
          vendor: selectedVendor.name,
        }));
      }
    } else {
      setVendorName(undefined);
      setExpenseDetails((prevFormData) => ({
        ...prevFormData,
        vendorName: "",
      }));
    }
  };

  const handleSelectCustomer = (selectedCustomer: ICustomer | null) => {
    if (selectedCustomer) {
      setCustomer(selectedCustomer);
      if (selectedCustomer.id) {
        setExpenseDetails((prevFormData) => ({
          ...prevFormData,
          customerId: selectedCustomer.id,
          customer: selectedCustomer.id,
        }));
      }
    } else {
      setCustomer(undefined);
      setExpenseDetails((prevFormData) => ({
        ...prevFormData,
        customerId: "",
      }));
    }
  };

  useEffect(() => {
    setExpense(expenseData as PreviewExpense);
    if (expenseData?.lineItems) {
      setLineItems(expenseData.lineItems);
    }
  }, []);

  const handleSaveExpense = async () => {
    const updatedLineItem = lineItems.map((item, i) => ({
      ...item,
      rate: Number(item.rate),
      quantity: Number(item.quantity),
    }));

    const data = {
      vendorName: expenseDetails.vendor,
      vendorId: "",
      category: expenseDetails.category
        ? expenseDetails.category
        : expense?.category,
      paymentMethod: expenseDetails.paymentMethod
        ? expenseDetails.paymentMethod
        : expense?.paymentMethod,
      notes: expenseDetails.notes,
      description: expenseDetails.tag,
      date: expense?.date
        ? new Date(expense.date).toISOString()
        : new Date().toISOString(),
      amount: expense?.amount ?? 0,
      isBillable: expense?.isBillable ?? false,
      currency: expense?.currency,
      lineItems: updatedLineItem,
      customerName: "",
      customerId: "",
    };

    await handleSavePreviewExpense({
      data,
      setIsLoading,
      setPreview,
      setValidationResults,
      setErrorLineItems,
    });
  };

  return (
    <div className="fixed bg-[#F2F9FD] top-16 px-[20px] right-0 left-0 z-50 h-[100vh] overflow-auto">
      <p className="mt-[40px] text-[20px] leading-[24px] font-semibold">
        Preview & Save
      </p>
      <p className="text-[#6A32C6] lg:text-[24px] leading-[28.8px] font-semibold ">
        {formattedDate(expense?.date)}
      </p>
      <div className="mt-[24px]">
        <span className="text-[16px] leading-[19.2px]">Expense Amount</span>
        <p className="text-[#FB0303] mt-[10px] text-[20px] font-bold leading-[30px]">
          {formatMoney(expense?.total || "")}
        </p>
      </div>

      <div className="flex flex-col gap-4 mt-8 text-[16px] font-normal">
        <div className="w-full">
          <p>Vendor</p>
          <SearchInput
            placeholder="Search for Vendor"
            data={vendors}
            displayKey="name"
            isLoading={isVendorSearching}
            searchedDone={searchedDone}
            setSearchedDone={setSearchedDone}
            value={expenseDetails.vendor}
            selected={vendorName}
            onSelect={handleSelectedVendor}
            handleChangeText={handleVendorSearch}
            emptyMessage={"This vendor is not found"}
            clearIcon={CloseIcon}
            clearIconClass="absolute mr-[1.5em] md:mr-[2.2em] lg:mr-[1.5em] biglg:mr-[1.5em] xl:mr-[1em] mt-[0.75em] md:mt-[0.75em]"
            classNames="w-full border border-gray-300 bg-white h-full min-h-[48px] placeholder:text-[15.25px] rounded  focus:outline-none focus:shadow-outline appearance-none focus:ring-0 leading-normal xl:w-[434px]"
            error={validationResults.vendorName}
            dropdownClassName="w-full"
            dropdownStyle={{
              maxHeight: "800px",
              overflowY: "auto",
            }}
            searchKey={"name"}
            icon={<SearchIcon />}
          />
        </div>
        <div className="w-full">
          <p>Payment Method</p>
          <DropdownComponent
            options={dropDownPaymentMethod}
            name="paymentMethod"
            onChange={handleExpenseDetails}
            selectedValue={
              expenseDetails.paymentMethod
                ? expenseDetails.paymentMethod
                : expense?.paymentMethod
            }
            value={
              expenseDetails.paymentMethod
                ? expenseDetails.paymentMethod
                : expense?.paymentMethod
            }
            className="w-full h-12 rounded"
            dropdownClassName="w-full"
            iconClassName="mr-3"
          />
          <div>
            {validationResults.paymentMethod && (
              <span className="text-red-500 whitespace-nowrap leading-[16px] p-0">
                {validationResults.paymentMethod}
              </span>
            )}
          </div>
        </div>
        <div className="w-full">
          <p>Notes</p>
          <InputComponent
            type="text"
            handleChange={(e) => handleExpenseDetails("notes", e.target.value)}
            name="notes"
            value={expenseDetails.notes}
            className="w-full h-12 rounded"
          />
        </div>
        <div className="w-full">
          <p>Expense Category</p>
          <DropdownComponent
            options={dropDownCategory}
            name="category"
            onChange={handleExpenseDetails}
            selectedValue={
              expenseDetails.category
                ? expenseDetails.category
                : expense?.category
            }
            value={
              expenseDetails.category
                ? expenseDetails.category
                : expense?.category
            }
            dropdownClassName="w-full"
            className="w-full h-12 rounded"
            iconClassName="mr-3"
          />
          <div className="">
            {validationResults.category && (
              <span className="text-red-500 whitespace-nowrap leading-[16px] p-0">
                {validationResults.category}
              </span>
            )}
          </div>
        </div>
        <div className="flex gap-4 ">
          <div className="w-full">
            <p>Billed to:</p>
            <SearchInput
              placeholder="Search"
              data={customers.map((customer) => ({
                ...customer,
                display: `${customer.name} (${customer.phone})`,
              }))}
              displayKey="name"
              isLoading={isCustomerSearching}
              value={expenseDetails.customer}
              selected={customer}
              searchedDone={searchedDone}
              setSearchedDone={setSearchedDone}
              onSelect={handleSelectCustomer}
              handleChangeText={handleCustomerSearch}
              emptyMessage={"This customer is not found"}
              clearIcon={CloseIcon}
              clearIconClass="absolute mr-[1.5em] md:mr-[2.2em] lg:mr-[1.5em] biglg:mr-[1.5em] xl:mr-[1em] mt-[0.75em] md:mt-[0.75em]"
              classNames="w-full border border-gray-300 bg-white h-full min-h-[48px] placeholder:text-[15.25px] rounded  focus:outline-none focus:shadow-outline appearance-none focus:ring-0 leading-normal"
              dropdownClassName="w-full"
              dropdownStyle={{
                maxHeight: "500px",
                overflowY: "auto",
              }}
              searchKey={"name"}
              icon={<SearchIcon />}
              onClearError={() => {
                setValidationResults((prevState: any) => ({
                  ...prevState,
                  vendorName: undefined,
                }));
              }}
            />
          </div>
          <div className="w-full">
            <p>Tag</p>
            <InputComponent
              type="text"
              handleChange={(e) => handleExpenseDetails("tag", e.target.value)}
              name="tag"
              value={expenseDetails.tag}
              className="w-full h-12 rounded"
            />
          </div>
        </div>
      </div>

      <div>
        <p className="text-[16px] font-semibold leading-[21.6px] mt-[32px] mb-[24px]">
          Expense Breakdown
        </p>
      </div>

      <div className="space-y-6">
        {lineItems?.map((item, index) => (
          <div className="border border-[#A4A4A4] rounded">
            <div className="border border-b-[#A4A4A4] pl-[8px] text-[#6A32C6]">{`# - ${index + 1}`}</div>

            <div className="p-2">
              <div className="flex gap-4">
                <div className="w-full">
                  <label className="text-[14px]" htmlFor="">
                    Items/Description
                  </label>
                  <InputComponent
                    value={item.name}
                    name="name"
                    type="text"
                    handleChange={(e) =>
                      handleLineItem(index, "name", e.target.value)
                    }
                    className="w-full h-[38px] border border-[#A4A4A4 rounded]"
                    error={errorLineItem[index]?.name}
                  />
                </div>
                <div className="w-full">
                  <label className="text-[14px]" htmlFor="">
                    Quantity
                  </label>
                  <InputComponent
                    value={commasToRestockNumber(item.quantity)}
                    name="quantity"
                    type="text"
                    handleChange={(e) =>
                      handleLineItem(index, "quantity", e.target.value)
                    }
                    className="w-full h-[38px] border border-[#A4A4A4] rounded"
                    error={errorLineItem[index]?.quantity}
                  />
                </div>
              </div>

              <div className="flex gap-4">
                <div className="w-full">
                  <label className="text-[14px]" htmlFor="">
                    Rate
                  </label>
                  <InputComponent
                    value={commasToRestockNumber(item.rate)}
                    name="rate"
                    type="text"
                    handleChange={(e) =>
                      handleLineItem(index, "rate", e.target.value)
                    }
                    className="w-full h-[38px] border border-[#A4A4A4] rounded"
                    error={errorLineItem[index]?.rate}
                  />
                </div>
                <div className="w-full">
                  <label className="text-[14px]" htmlFor="">
                    Amount
                  </label>
                  <InputComponent
                    value={
                      item.amount
                        ? commasToRestockNumber(item.amount)
                        : commasToRestockNumber(item.quantity * item.rate)
                    }
                    name="quantity"
                    type="text"
                    handleChange={(e) =>
                      handleLineItem(index, "amount", e.target.value)
                    }
                    className="w-full h-[38px] border border-[#A4A4A4] rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-10 mb-20">
        <button
          onClick={handleSaveExpense}
          disabled={isLoading}
          className="w-[90%] h-[50px] mx-auto bg-[#044E97] font-semibold leading-[19.2px] text-white flex justify-center items-center"
        >
          {isLoading ? <Spinner size="sm" light={true} /> : "Save"}
        </button>
      </div>
      <AddVendors
        show={showNewVendor}
        onClose={() => setShowNewVendor(false)}
        onVendorCreate={handleSelectedVendor}
      />
    </div>
  );
};

export default MobilePreviewModal;
