import React, { useEffect, useState } from "react";
import EnIcon from "../../assets/icons/EnIcon";
import NigeriaIcon from "../../assets/icons/NigeriaIcon";
import MarkIcon from "../../assets/icons/MarkIcon";
import TooltipDropdown from "./tooltipDropdown";
import { useUserStore } from "../../state";
import { localeType } from "../../@types";
import { useCompanyStore } from "../../state/useCompanyStore";

const languageOptions = [
  {
    language: "English",
    lng: "en",
    icon: <EnIcon />,
    TooltipText:
      "Use this for professional and formal business setting. Terms are presented in standard English, as commonly used in the corporate world",
  },
  {
    language: "Naija English",
    lng: "en-NG",
    icon: <NigeriaIcon />,
    TooltipText:
      "Designed for Nigerian business owners who prefer a more localized English dialect. Terms are simplified and adapted to better align with everyday Nigerian business practices.",
  },
  {
    language: "Naija Pidgin",
    lng: "pidgin",
    icon: <NigeriaIcon />,
    TooltipText:
      "Ideal for those who communicate primarily in Pidgin English. This setting provides a more familiar and accessible way to understand bookkeeping terms.",
  },
];

const LanguageOption = () => {
  const { setLocale, locale } = useCompanyStore();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [selectedLng, setSelectedLng] = useState<localeType>("en");

  useEffect(() => {
    if (locale) {
      setSelectedLng(locale as localeType);
    }
  }, []);

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const handleClickToolTip = (index: number) => {
    setHoveredIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const handleSelectedLng = (lng: localeType) => {
    setSelectedLng(lng);
    setLocale(lng);
  };

  return (
    <>
      <div className="space-y-4">
        {languageOptions.map((option, i) => (
          <div
            key={option?.lng}
            onClick={() => handleSelectedLng(option?.lng as localeType)}
            className={`w-full p-4 border-[0.5px] ${
              selectedLng === option.lng
                ? "border-[#044E97]"
                : "border-[#A4A4A4]"
            } border-[#044E97] bg-[#FFFFFF] rounded-lg flex justify-between items-center cursor-pointer`}
          >
            <div className="flex items-center gap-1">
              {option?.icon}
              <div className="flex items-center gap-1">
                <p
                  className={`text-[16px] leading-5 ${selectedLng === option?.lng ? "text-[#000]" : "text-[#8E8E8E]"}`}
                >
                  {option?.language}
                </p>
                <div
                  className="relative"
                  onClick={() => handleClickToolTip(i)}
                  onMouseEnter={() => handleMouseEnter(i)}
                  onMouseLeave={handleMouseLeave}
                >
                  <TooltipDropdown
                    isHovered={hoveredIndex === i}
                    toolTipText={option?.TooltipText}
                  />
                </div>
              </div>
            </div>
            {selectedLng === option?.lng && <MarkIcon />}
          </div>
        ))}
      </div>
    </>
  );
};

export default LanguageOption;
