export const Reports: {
  INVOICE_REPORT: string;
  INVOICE_REPORT_CSV: string;
  CUSTOMER_REPORT: string;
  CUSTOMER_REPORT_CSV: string;
  EXPENSE_REPORT: string;
  EXPENSE_REPORT_CSV: string;
  INVENTORY_REPORT: string;
  INVENTORY_REPORT_CSV: string;
  PROFITLOSS_REPORT: string;
  PROFITLOSS_REPORT_CSV: string;
} = {
  INVOICE_REPORT: "Invoice-Report",
  INVOICE_REPORT_CSV: "Invoice-Report-csv",
  CUSTOMER_REPORT: "Customer-Report",
  CUSTOMER_REPORT_CSV: "Customer-Report-csv",
  EXPENSE_REPORT: "Expense-Report",
  EXPENSE_REPORT_CSV: "Expense-Report-csv",
  INVENTORY_REPORT: "Inventory-Report",
  INVENTORY_REPORT_CSV: "Inventory-Report-csv",
  PROFITLOSS_REPORT: "ProfitLoss-Report",
  PROFITLOSS_REPORT_CSV: "ProfitLoss-Report-csv",
};

export const STATUS_VALUES = [
  { value: "paid", label: "paid" },
  { value: "pending", label: "pending" },
  { value: "refunded", label: "refunded" },
  { value: "cancelled", label: "cancelled" },
  { value: "due", label: "due" },
  { value: "draft", label: "draft" },
];

export const PAYMENT_METHOD = [
  { value: "Cash", label: "Cash" },
  { value: "Transfer", label: "Transfer" },
  { value: "Card", label: "Card" },
];
