import React, { useState, useRef, useEffect } from "react";
import { ICreateSalesData } from "../../../../@types";
import { InfoCircle } from "../../../../assets/icons/InfoCircle";
import { MiniCard } from "../../../common/TooltipCards/miniCards";
import { DropdownComponent, InputComponent } from "../../../core/Forms";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { InvoiceErrors } from "../../../../modules/validateInvoiceForm";
import { Tooltip } from "../../../../assets/icons/Tooltip";
import DateIcon from "../../../../assets/icons/Date";
import { PAYMENT_CURRENCY_LIST } from "./InvoiceCurrency";
import { useTranslation } from "react-i18next";

interface InvoiceDateProps {
  handleDropDownChange: (name: string, value: string) => void;
  formData: ICreateSalesData;
  setFormData: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
  selectedCurrencyLabel: string;
  validationResults: InvoiceErrors;
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>;
  toolTipText?: string;
  toolTipText2?: string;
  handleInputChange: (event: { target: { name: any; value: any } }) => void;
}

const dropDownCurrency = PAYMENT_CURRENCY_LIST;

const QuoteDate: React.FC<InvoiceDateProps> = ({
  formData,
  setFormData,
  selectedCurrencyLabel,
  handleDropDownChange,
  validationResults,
  setValidationResults,
  toolTipText,
  toolTipText2,
  handleInputChange,
}) => {
  const { t } = useTranslation("quotes");
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

  const [quoteDate, setQuoteDate] = useState<Date>(new Date());
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);

  const [showInvoiceDateCalendar, setShowInvoiceDateCalendar] = useState(false);
  const [showDueDateCalendar, setShowDueDateCalendar] = useState(false);

  const invoiceDateRef = useRef<HTMLDivElement>(null);
  const expiryDateRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log("Validation Results:", validationResults);
  }, [validationResults]);

  const handleInvoiceDateChange = (value: Date | Date[] | null) => {
    if (value instanceof Date) {
      setQuoteDate(value);
      setFormData({ ...formData, issueDate: value.toISOString() });
    }
    setShowInvoiceDateCalendar(false);
  };

  const handleExpiryDateChange = (value: Date | Date[] | null) => {
    if (value instanceof Date) {
      setExpiryDate(value);
      setFormData({ ...formData, expiryDate: value.toISOString() });
    }
    setShowDueDateCalendar(false);
  };

  return (
    <>
      {/* Desktop */}
      <div className="flex flex-col items-center mt-[3em] hidden lg:block md:block biglg:mr-[1.5em] lg:mr-[1.5em]">
        <div className="flex justify-between xl:gap-[1em] biglg:gap-[1em] lg:gap-[0.5em] md:gap-[3em] ">
          <div>
            <div className="ml-[3em] lg:ml-0 xl:ml-0 2xl:ml-0 biglg:ml-0 bigmd:ml-0 md:ml-0 mt-[13px] lg:mt-0 xl:mt-0 2xl:mt-0 biglg:mt-0 bigmd:mt-0 md:mt-0">
              <div className="flex gap-1">
                <label
                  htmlFor="issueDate"
                  className="lg:text-[16px] md:text-[14px] text-[9px]  font-light flex"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {t("quote.quoteDate")}:
                  <span className="mt-[0.3em]">
                    <Tooltip />
                  </span>
                </label>
                {toolTipText && (
                  <div
                    className="relative "
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {isHovered && (
                      <div className="absolute w-[204px] left-[-5.7em] z-10 biglg:mt-[2em]">
                        <div className="flex items-center justify-center">
                          <svg
                            width="18"
                            height="15"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 0L17.6603 15H0.339746L9 0Z"
                              fill="#FFF4C6"
                            />
                          </svg>
                        </div>
                        <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                          {toolTipText}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div>
                <InputComponent
                  type="date"
                  className="w-[150px] bg-[#F4F7FF] pl-1 pr-2 whitespace-nowrap py-3 cursor-pointer flex border-[#ACD4FC] "
                  placeholder="Issue Date"
                  value={formData.issueDate}
                  name="issueDate"
                  handleChange={handleInputChange}
                  restrictFutureDates={true}
                />
              </div>
              {validationResults.issueDate && (
                <div className="mt-2 ml-4">
                  <span className="text-red-500">
                    {validationResults.issueDate}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="mt-[13px] lg:mt-1.5 xl:mt-0 2xl:mt-0 biglg:mt-1.5 bigmd:mt-0 md:mt-0 md:ml-[-0.3em] xl:ml-0 biglg:ml-0 lg:mr-[-1em] bigmd:ml-0">
            <div className="flex gap-1">
              <label
                htmlFor="expiryDate"
                className="xl:text-[16px] text-[12px] md:text-[14px] xl:mt-0 lg:mt-[-0.2em] 375:ml-[1.7em] 414:ml-[0.3em] 390:ml-[1em] 412:ml-[0.6em] 360:ml-[1.7em] font-light flex"
              >
                {t("quote.Expirydate")}:
                <span className="mt-[0.2em]">
                  <Tooltip />
                </span>
              </label>
              {toolTipText2 && (
                <div
                  className="relative  "
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
                >
                  {isHovered2 && (
                    <div className="absolute w-[204px] left-[-5.7em] z-10">
                      <div className="flex items-center justify-center">
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 0L17.6603 15H0.339746L9 0Z"
                            fill="#FFF4C6"
                          />
                        </svg>
                      </div>
                      <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                        {toolTipText2}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div>
              <InputComponent
                type="date"
                className="w-[150px] bg-[#F4F7FF] pl-1 pr-2 whitespace-nowrap py-3 cursor-pointer flex border-[#ACD4FC] "
                placeholder="Expiry Date"
                value={formData.expiryDate}
                name="expiryDate"
                handleChange={handleInputChange}
                onClearError={() => {
                  setValidationResults((prevState) => ({
                    ...prevState,
                    expiryDate: undefined,
                  }));
                }}
                restrictPreviousDates={false}
              />
            </div>
            {validationResults.expiryDate && (
              <div className="mt-2 xl:text-[14px] lg:text-[12px] biglg:text-[12px] md:text-[11px] ">
                <span className="text-red-500">
                  {validationResults.expiryDate}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="flex flex-col  mt-[3em] lg:hidden md:hidden justify-end items-end text-right mr-[-3em]">
        <div className="flex flex-col gap-[6em]">
          <div>
            <div className="ml-[3em] lg:ml-0 xl:ml-0 2xl:ml-0 biglg:ml-0 bigmd:ml-0 md:ml-0 mt-[13px] lg:mt-0 xl:mt-0 2xl:mt-0 biglg:mt-0 bigmd:mt-0 md:mt-0">
              <div className="flex gap-1">
                <label
                  htmlFor="issueDate"
                  className="lg:text-[16px] text-[13px]  font-normal 375:ml-[3.3em] 414:ml-[4em] 390:ml-[3em] 430:ml-[] 412:ml-[0.6em] 360:ml-[2em]"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {t("quote.quoteDate")}:
                </label>
              </div>
              <div className="">
                <InputComponent
                  type="date"
                  placeholder="Issue Date"
                  value={formData.issueDate}
                  name="issueDate"
                  handleChange={handleInputChange}
                  restrictFutureDates={true}
                  className="w-[130px]"
                />
              </div>
              {validationResults.issueDate && (
                <div className="mt-2 ml-4">
                  <span className="text-red-500">
                    {validationResults.issueDate}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className=" lg:mt-0 xl:mt-0 2xl:mt-0 biglg:mt-0 bigmd:mt-0 md:mt-0 md:ml-[-0.3em] xl:ml-0 biglg:ml-0 lg:ml-0 bigmd:ml-0 mt-[-5.6em] ml-[3em]">
            <div className="flex flex-col">
              <label
                htmlFor="invoiceDueAt"
                className="lg:text-[16px] text-[13px] xl:mt-0 lg:mt-[-0.7em] 375:ml-[5em] 414:ml-[4.3em] 390:ml-[1em] 412:ml-[0.6em] 360:ml-[1.7em] font-normal"
              >
                {t("quote.Expirydate")}:
              </label>
            </div>
            <div>
              <InputComponent
                type="date"
                placeholder="Expiry Date"
                value={formData.expiryDate}
                name="expiryDate"
                handleChange={handleInputChange}
                restrictPreviousDates={false}
                className="w-[130px]"
              />
            </div>
            {validationResults.expiryDate && (
              <div className="mt-2 text-[9px]">
                <span className="text-red-500">
                  {validationResults.expiryDate}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuoteDate;
