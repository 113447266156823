import React, { Suspense, lazy } from "react";
import { getExpense } from "../../backend-services";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import PageLoader from "../../components/common/PageLoader";
import { ICreateExpenseData } from "../../@types";

const ExpenseParent = lazy(
  () => import("../../components/expense/ExpenseCreate/ExpenseParent"),
);

const EditExpensePage = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [expense, setExpense] = React.useState<ICreateExpenseData | null>();

  const { expenseId } = useParams<{ expenseId: string }>();

  const getExpenseHandler = async () => {
    setIsLoading(true);
    try {
      if (expenseId) {
        const data = await getExpense(expenseId);
        setExpense(data);
      }
    } catch (e) {
      toast.error("Failed to load expense");
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getExpenseHandler();
  }, [expenseId]);

  return (
    <>
      {isLoading && <PageLoader />}
      {!isLoading && (
        <Suspense fallback={<PageLoader />}>
          <ExpenseParent expense={expense as ICreateExpenseData} />
        </Suspense>
      )}
    </>
  );
};

export { EditExpensePage };
