import React from "react";
import UserManagement from "../../components/settings/userManagement";

const UserManagementPage = () => {
  return (
    <div className="mt-[-2em]">
      <UserManagement />
    </div>
  );
};

export default UserManagementPage;
