import React from "react";
import { CloseIcon, CloseIconBig } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";

interface MobileSubscriptionModalProps {
  onClose: () => void;
  titleText: string;
  subtitleText: string;
  subtitleText2?: string;
}

const MobileSubscriptionModal: React.FC<MobileSubscriptionModalProps> = ({
  onClose,
  titleText,
  subtitleText,
  subtitleText2,
}) => {
  const navigate = useNavigate();

  const handleUpgradePlan = () => {
    navigate("/subscription");
    onClose();
  };

  return (
    <>
      <div className="max-w-[358px] text-[#212121] pl-[16px] pr-[12px] bg-white rounded-[8px] py-[16px] shadow-xl">
        <div className="flex items-center justify-between">
          <p className="text-[14px] font-semibold leading-[17px]">
            {titleText}
          </p>
          <button onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <p className="text-[12px] font-medium mt-[12px] mb-[24px]">
          {subtitleText} <br /> {subtitleText2}{" "}
        </p>
        <button
          className="w-[140px] h-[36px] text-[#ffffff] text-[14px] font-semibold bg-[#044E97] rounded-[4px] text-center"
          onClick={handleUpgradePlan}
        >
          Upgrade Plan
        </button>
      </div>
    </>
  );
};

export { MobileSubscriptionModal };
