export const validateCreateInventory = ({
  name,
  rate,
  costPrice,
  variationLen,
}: any) => {
  const errors = {
    name: "",
    quantity: "",
    rate: "",
    costPrice: "",
  };
  if (!name) {
    errors.name = "Inventory Name is required";
  }
  if ((variationLen === 0 && isNaN(rate)) || rate === 0) {
    if (isNaN(rate)) errors.rate = "Selling Price is required";
    else errors.rate = "Enter a value greater than zero";
  }
  if (
    (variationLen === 0 && isNaN(costPrice)) ||
    (costPrice === 0 && variationLen === 0)
  ) {
    if (isNaN(costPrice)) errors.costPrice = "Cost Price is required";
    else errors.costPrice = "Enter a value greater than zero";
  }

  // Check if any error messages are present
  const hasErrors = Object.values(errors).some((error) => error !== "");

  return { hasErrors, errors };
};

export const validateRestock = ({ rate, quantity, costPrice }: any) => {
  const errors = {
    quantity: "",
    rate: "",
    costPrice: "",
  };

  if (isNaN(quantity) || quantity === 0) {
    if (isNaN(quantity)) errors.quantity = "Quantity is required";
    else errors.quantity = "Enter a value greater than zero";
  }
  if (isNaN(rate) || rate === 0) {
    if (isNaN(rate)) errors.rate = "Selling Price is required";
    else errors.rate = "Enter a value greater than zero";
  }
  if (isNaN(costPrice) || costPrice === 0) {
    if (isNaN(costPrice)) errors.costPrice = "Cost Price is required";
    else errors.costPrice = "Enter a value greater than zero";
  }

  // Check if any error messages are present
  const hasErrors = Object.values(errors).some((error) => error !== "");

  return { hasErrors, errors };
};
export const validateEdit = ({ name, description, rate }: any) => {
  const errors = {
    name: "",
    rate: "",
  };

  if (!name) {
    errors.name = "Inventory Name is required";
  }
  // if (!description) {
  //   errors.description = 'Description is required';
  // }
  if (rate !== undefined && isNaN(rate)) {
    errors.rate = "Rate must be a valid number";
  }
  // Check if any error messages are present
  const hasErrors = Object.values(errors).some((error) => error !== "");

  return { hasErrors, errors };
};

export const ValidateVariation = (variations: any) => {
  const data = [...variations];
  let errorMsg = [];
  const seenVariations: Record<string, boolean> = {};

  for (let index = 0; index < data.length; index++) {
    let valid = true;
    let error = {
      id: index,
      type: "",
      costPrice: "",
      price: "",
      quantity: "",
    };

    // name and type combination
    const nameTypeCombination = `${data[index].name}-${data[index].type}`;

    if (seenVariations[nameTypeCombination]) {
      error.type = "type already exists";
      valid = false;
    } else {
      seenVariations[nameTypeCombination] = true;
    }

    // type
    if (!data[index].type) {
      error.type = "type is required";
      valid = false;
    }

    // Cost Price
    if (isNaN(data[index].costPrice)) {
      error.costPrice = "cost price is required";
      valid = false;
    } else if (data[index].costPrice === 0) {
      error.costPrice = "Invalid Cost Price";
      valid = false;
    }
    // Selling Price
    if (isNaN(data[index].price)) {
      error.price = "selling price required";
      valid = false;
    } else if (data[index].price === 0) {
      error.price = "Invalid Selling Price";
      valid = false;
    }

    // Quantity
    if (isNaN(data[index].quantity)) {
      error.quantity = "quantity required";
      valid = false;
    } else if (data[index].quantity === 0) {
      error.quantity = "Invalid Quantity";
      valid = false;
    }
    if (!valid) errorMsg.push(error);
  }
  return errorMsg;
};

export const validateRemoveStock = ({
  quantity,
  reason,
  name,
  type,
  isVariation,
}: any) => {
  const errors = {
    quantity: "",
    reason: "",
    name: "",
    type: "",
  };

  if (!name && isVariation) {
    errors.name = "required";
  }

  if (!type && isVariation) {
    errors.type = "required";
  }

  if (!isNaN(quantity) || quantity === 0) {
    if (!quantity) {
      errors.quantity = "Enter a valid quantity";
    }
    if (quantity === 0) {
      errors.quantity = "Enter a valid quantity";
    }
  }

  if (!reason) {
    errors.reason = "Required";
  }

  const hasErrors = Object.values(errors).some((error) => error !== "");

  return { hasErrors, errors };
};
