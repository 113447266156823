import React from "react";

interface UserIconProps {
  color?: string;
}

const UserIcon: React.FC<UserIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.0013 15.667H0.667969C0.667969 12.4453 3.65274 9.83366 7.33464 9.83366C8.91121 9.83366 10.36 10.3125 11.5013 11.1131M10.668 4.00033C10.668 5.84127 9.17558 7.33366 7.33464 7.33366C5.49369 7.33366 4.0013 5.84127 4.0013 4.00033C4.0013 2.15938 5.49369 0.666992 7.33464 0.666992C9.17558 0.666992 10.668 2.15938 10.668 4.00033Z"
          stroke={color || "currentColor"}
          stroke-width="1.2"
        />
        <path
          d="M14.0013 17.3337L14.0013 10.667M10.668 14.0003H17.3346"
          stroke="white"
          stroke-width="1.2"
        />
      </svg>
    </>
  );
};

export default UserIcon;

export const ActiveUserIcon: React.FC<UserIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.0417 15.0417L15.0417 17.5417H13.375L13.375 15.0417H10.875V13.375H13.375V10.875H15.0417L15.0417 13.375H17.5417V15.0417H15.0417Z"
          fill="#0D3C6D"
        />
        <path
          d="M7.7526 0.458984C5.56648 0.458984 3.79427 2.23119 3.79427 4.41732C3.79427 6.60344 5.56648 8.37565 7.7526 8.37565C9.93873 8.37565 11.7109 6.60344 11.7109 4.41732C11.7109 2.23119 9.93873 0.458984 7.7526 0.458984Z"
          fill="#0D3C6D"
        />
        <path
          d="M7.7526 9.62565C3.80653 9.62565 0.460938 12.4414 0.460938 16.084V16.709H9.6276V12.1257H12.1276V10.9174C10.8985 10.1024 9.37637 9.62565 7.7526 9.62565Z"
          fill="#0D3C6D"
        />
      </svg>
    </>
  );
};
