import { useEffect, useState, useRef } from "react";
import { useNotificationStore } from "../state/useNotificationStore";

const useNotifications = (triggerAction: unknown) => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const batchGetNotifications = useNotificationStore(
    (state) => state.fetchNotifications,
  );
  const notifications = useNotificationStore((state) => state.notifications);
  const numFetchAttempts = useRef(0);

  const clearFetchInterval = (id: NodeJS.Timeout | null) => {
    if (id) {
      clearInterval(id);
      setIntervalId(null);
    }
  };

  const fetchNotifications = async () => {
    try {
      if (numFetchAttempts.current >= 3 || notifications?.length > 0) {
        throw new Error(
          "API request limit reached or notifications already exist",
        );
      }
      await batchGetNotifications();
      numFetchAttempts.current += 1;
      return notifications?.length > 0;
    } catch (error) {
      return false;
    }
  };

  const fetchNotificationsAtInterval = async () => {
    try {
      if (triggerAction && numFetchAttempts.current < 3) {
        const fetched = await fetchNotifications();
        if (fetched && intervalId) {
          clearFetchInterval(intervalId);
        } else {
          const id = setInterval(async () => {
            const fetched = await fetchNotifications();
            if (fetched || numFetchAttempts.current >= 3) {
              clearFetchInterval(id);
            }
          }, 30000);
          setIntervalId(id);
        }
      } else {
        clearFetchInterval(intervalId);
      }
    } catch (error) {
      console.error("Error in fetchNotificationsAtInterval:", error);
      clearFetchInterval(intervalId);
    }
  };

  useEffect(() => {
    fetchNotificationsAtInterval().catch((error) =>
      console.error("Error in useEffect:", error),
    );
  }, [triggerAction, notifications]);

  return notifications;
};

export default useNotifications;
