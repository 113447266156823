import dayjs from "dayjs";
import { IInvoice } from "../../@types";
import { getDaySuffix } from "../invoice-list";
import { getInvoiceStatus } from "../invoice";
import { Link } from "react-router-dom";
import { getStatusClass } from "../../components/Sales/invoice/Status/status";
import { formatMoney } from "../format";

export const renderQuotesData = (quotes: IInvoice[]) => {
  return quotes
    .filter((quote: IInvoice) => {
      return true;
    })
    .map((quote: IInvoice, index) => {
      const statusCapitalized = quote.status
        ? quote.status.charAt(0).toUpperCase() + quote.status.slice(1)
        : "";
      const linkTo = `/quotes/${quote.id}/view`;
      const linkText = quote.quoteNumber;
      const statusClass = getStatusClass(statusCapitalized);
      const quoteNo = quote.id;

      return {
        quoteNumbers: (
          <div>
            <Link to={linkTo}>{linkText}</Link>
          </div>
        ),
        customer: (
          <Link
            className="md:text-violet-800"
            to={`/customers/${quote?.customer?.id}/view`}
          >{`${quote?.customer?.firstName} ${quote?.customer?.lastName}`}</Link>
        ),
        issueDate: `${dayjs(quote.issueDate).format("MMM")} ${getDaySuffix(dayjs(quote.issueDate).date())} ${dayjs(
          quote.issueDate,
        ).format("YYYY")}`,
        quoteNumber: quote.quoteNumber,
        status: (
          <div
            className={`${statusClass} border border-gray-200 rounded-[4px] w-fit px-2 py-1`}
          >
            <Link className="block" to={linkTo}>
              {getInvoiceStatus(statusCapitalized)}
            </Link>
          </div>
        ),
        expiryDate: `${dayjs(quote.expiryDate).format("MMM")} ${getDaySuffix(dayjs(quote.expiryDate).date())} ${dayjs(
          quote.expiryDate,
        ).format("YYYY")}`,
        //@ts-ignore
        inventories: formatMoney(quote.total, quote.currency),
        id: quoteNo,
      };
    });
};

export const columns = [
  { key: "quoteNumbers", label: "Quote ID", width: 7 },
  { key: "customer", label: "Customer", width: 24 },
  { key: "issueDate", label: "Issue Date", width: 18 },
  { key: "expiryDate", label: "Validity Date", width: 18 },
  { key: "status", label: "Status", width: 15 },
  { key: "inventories", label: "Balance Due", width: 18 },
];
