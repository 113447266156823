import * as React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import DesktopSubscriptionModal from "../Subscription/DesktopSubscriptionModal";
import { SubscriptionModal } from "../Subscription/SubscriptionModal";
import { MobileSubscriptionModal } from "../Subscription/MobileSubscriptionModal";
import { useNavigate } from "react-router-dom";
import { useSubscriptionStore, useUserStore } from "../../../state";
import {
  checkSubscription,
  SubscriptionCheckResult,
} from "../../../utils/subscription";

const btnClass = (textColorClass: string, disabled: boolean) => {
  return cx("flex items-center gap-0 md:gap-[4px] cursor-pointer", {
    [textColorClass]: true,

    "opacity-50 pointer-events-none": disabled,
  });
};
interface IGroupButton {
  text: string;
  icon: any;
  textColorClass?: string;
  onClick?: () => void;
  href?: string;
  disabled?: boolean;
  fontSize?: string;
  ref?: React.MutableRefObject<any>;
}

interface GroupButtonProps {
  buttons?: Array<IGroupButton>;
}

const GroupButton = React.forwardRef<HTMLButtonElement, GroupButtonProps>(
  (props, ref) => {
    const [showSubscriptionModal, setShowSubscriptionModal] =
      React.useState(false);
    const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
      React.useState<string>("");
    const buttons = props.buttons || [];
    const navigate = useNavigate();
    const handleButtonClick = async (button: IGroupButton) => {
      try {
        switch (button.text) {
          case "New Quote":
            navigate(button.href || "/quotes/create");
            break;
          case "New Inventory":
            navigate(button.href || "/inventories/create");
            break;
          default:
            if (button.onClick) {
              button.onClick();
            }
        }
      } catch (error: any) {
        if (error.response) {
          const errorMessage = error.response.data;
        }
      }
    };

    const btnClass = (textColorClass?: string, disabled?: boolean) => {
      return cx("flex items-center gap-0 md:gap-[4px] cursor-pointer", {
        [textColorClass || ""]: !!textColorClass,
        "opacity-50 pointer-events-none": disabled,
      });
    };

    const isMobile = window.innerWidth < 768;

    return (
      <div className="flex md:flex-wrap justify-between">
        <div className="bg-[#F2F4F7] h-[44px] bg-opacity-20 font-medium rounded-md py-2 flex  gap-[0px]  border border-gray-100">
          {buttons.map(
            (btn: IGroupButton, index: React.Key | null | undefined) => {
              const Comp = btn.href ? Link : "span";

              return (
                <React.Fragment key={index}>
                  <Comp
                    ref={btn.ref} // Attach the ref here
                    to={btn.href || "#"}
                    onClick={() => handleButtonClick(btn)}
                    className={btnClass(
                      `text-[#044E97] biglg:text-[16px] md:text-[16px] font-medium text-[10px] xl:text-[16px] 2xl:text-[18px] ${btn.fontSize} whitespace-nowrap px-2 md:px-5`,
                      btn.disabled || false,
                    )}
                  >
                    {btn.text} {btn.icon}
                  </Comp>

                  {index !== buttons.length - 1 && (
                    <span className="border-l border-[#000000] border-opacity-20 xl:ml-2 xl:mr-2 xl:mr-19 2xl:mr-0" />
                  )}
                </React.Fragment>
              );
            },
          )}
        </div>
        <div className="mt-[-30em]">
          {showSubscriptionModal && (
            <SubscriptionModal>
              <MobileSubscriptionModal
                onClose={() => setShowSubscriptionModal(false)}
                titleText={`Need to access more features?`}
                subtitleText={subscriptionErrorMessage}
              />
            </SubscriptionModal>
          )}
        </div>
      </div>
    );
  },
);

export { GroupButton };
