import * as React from "react";

interface DropDownProps {
  children: any;
  component: React.ReactNode;
  show?: boolean;
  onChange?: (value: boolean) => void;
}

const DropDown: React.FC<DropDownProps> = ({
  show = false,
  onChange,
  component,
  children,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const toogleDropDown = () => {
    setIsOpen((prev) => !prev);
    onChange && onChange(!isOpen);
  };

  return (
    <div className="relative">
      <span onClick={toogleDropDown}>{component}</span>

      {isOpen && (
        <div className="right-0 md:left-0 border rounded border-[#138EFF] bg-white xl:min-w-[200px] w-[200px] xl:w-[400px] absolute z-[999] animate-zoom-in">
          {children}
        </div>
      )}
    </div>
  );
};

export { DropDown };
