import React from "react";
import {
  InfoCard,
  InfoCardProps,
} from "../../components/core/InfoCard/InfoCard";
import { NariaIcon } from "../../assets/icons";
import { formatMoney } from "../../utils";
import { useTranslation } from "react-i18next";
import Naira from "../../assets/icons/Naira";

interface InventoryInfoCardsProps {
  stats: {
    totalCostPrice: number;
    totalSellingPrice: number;
    totalPurchase: number;
  };
  isLoading: boolean;
}

const InventoryInfoCards: React.FC<InventoryInfoCardsProps> = ({
  stats,
  isLoading,
}) => {
  const { t } = useTranslation("inventories");

  const infoCardData: InfoCardProps[] = [
    {
      title: t("inventory.sellingPrice"),
      value: `${formatMoney(stats.totalSellingPrice)}`,
      color: "inactive",
      icon: <NariaIcon />,
      isLoading: false,
      styles: "mt-4",
    },
    {
      title: t("inventory.totalCostPrice"),
      value: `${formatMoney(stats.totalCostPrice)}`,
      color: "inactive",
      icon: <Naira />,
      isLoading,
      styles: "mt-4",
    },
    {
      title: t("inventory.totalPurchase"),
      value: `${stats.totalPurchase}`,
      color: "inactive",
      icon: <NariaIcon />,
      isLoading,
      styles: "mt-4",
    },
  ];

  return (
    <>
      <div className="hidden lg:block md:block">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-3  ">
          {infoCardData.map((card, index) => (
            <div
              key={index}
              className="border border-gray-200 rounded-md pb-3 "
            >
              <InfoCard key={index} {...card} />
            </div>
          ))}
        </div>
      </div>
      <div className="lg:hidden space-y-2 mt-[1em]  md:mt-12 ">
        <div className="grid grid-cols-2 gap-4 rounded-md">
          {infoCardData.slice(0, 2).map((cardData, i) => (
            <div
              key={i}
              className="border border-gray-200 rounded-md text-[13px] "
            >
              <InfoCard {...cardData} />
            </div>
          ))}
        </div>
        <div className="grid gap-4">
          {infoCardData.slice(2, 3).map((cardData, i) => (
            <div
              key={i}
              className="border border-gray-200 rounded-md text-[13px]"
            >
              <InfoCard {...cardData} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default InventoryInfoCards;
