import React, { useState } from "react";
import LeftNotificationPopup from "../../core/NotificationPopup/LeftNotificationPopup";
import ProcessSuccessful from "../../../assets/icons/ProcessSuccessful";
import toast from "react-hot-toast";
import { cancelSubscription } from "../../../backend-services/subscription";
import { SidebarDrawer } from "../../common";
import { CloseIcon } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface cancelSubscriptionPromptProps {
  id: string;
  setShowPrompt: (showPromt: boolean) => void;
  onClose?: () => void;
  onDeleteSuccess?: () => void;
  handleGetActiveSubscription: () => void;
}

const CancelSubscriptionPrompt = ({
  id,
  setShowPrompt,
  onClose,
  onDeleteSuccess,
  handleGetActiveSubscription,
}: cancelSubscriptionPromptProps) => {
  const { t } = useTranslation("subscription");
  const [isCanceling, setIsCanceling] = useState(false);
  const [showSuccessSidebar, setShowSuccessSidebar] = React.useState(false);
  const navigate = useNavigate();

  const closeSuccessSidebar = () => {
    setShowSuccessSidebar(false);
  };

  const handleCancelSubscription = async () => {
    setIsCanceling(true);
    try {
      const res = await cancelSubscription(id);
      setShowSuccessSidebar(true);
      onDeleteSuccess && onDeleteSuccess();
      handleGetActiveSubscription();
      navigate(`/settings?tab=subscription`);
      setIsCanceling(false);
    } catch (error: any) {
      toast.error(error.response?.data || error.message);
      setIsCanceling(false);
      throw new Error(error.message);
    }
  };

  return (
    <>
      <div className="bg-gray-900 bg-opacity-60 fixed top-0 right-0 left-0 z-50 h-[100vh]">
        <div className="fixed top-0 right-0 z-40 h-screen px-8 pt-[34px] overflow-y-auto transition-transform bg-white w-full sm:w-[498px]">
          <div className="h-screen">
            <div className="flex justify-end">
              <div onClick={() => setShowPrompt(false)}>
                <CloseIcon color="#000000" />
              </div>
            </div>
            <div className="text-[24px] font-semibold leading-[28.8px]">
              {" "}
              {t("subscription.cancelPrompt")}
            </div>
            <div className="mt-[16px] ">
              <h1>{t("subscription.cancelWarning")}</h1>
              <span>
                {/* You will not be charged on the next subscription <br /> date. */}
                {t("subscription.cancelWarning1")}
              </span>
            </div>
            <div role="form" onSubmit={(e) => e.preventDefault()}>
              <div className="flex gap-[24px] mt-[91px]">
                <button
                  onClick={onClose}
                  className="border border-[#044E97] rounded-[4px] w-full h-[48px] flex items-center justify-center text-[#044E97] text-[16px] whitespace-nowrap"
                  style={{ fontWeight: 600 }}
                >
                  {t("ssubscription.continue")}
                </button>
                <button
                  className="w-full h-[48px] flex items-center justify-center rounded-[4px] bg-[#FB0303] text-white font-semibold"
                  onClick={handleCancelSubscription}
                  disabled={isCanceling}
                >
                  {isCanceling ? "Canceling..." : "Cancel"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSuccessSidebar && (
        <SidebarDrawer
          title=""
          show={true}
          position="left"
          onClose={closeSuccessSidebar}
        >
          <LeftNotificationPopup
            title="Subscription Canceled"
            icon={<ProcessSuccessful />}
            position="left"
            showSuccessModal={true}
          />
        </SidebarDrawer>
      )}
    </>
  );
};

export default CancelSubscriptionPrompt;
