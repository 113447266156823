import { Plan } from "../@types/subscripton";
import { useTranslation } from "react-i18next";
// const { t } = useTranslation('subscription');
export const Subscription = [
  {
    id: 1,
    title: "Free",
    price: 0,
    features: [
      "10 Invoices",
      "Unlimited Customers",
      "24/7 Online Support",
      "DashBoard and Analytics",
      "10 Expenses",
    ],
    plan: "Choose This plan",
  },
  {
    id: 2,
    title: "Essential",
    price: 5000,
    features: [
      "10 Invoices",
      "Unlimited Customers",
      "24/7 Online Support",
      "DashBoard and Analytics",
      "10 Expenses",
    ],
    plan: "Active Plan",
  },
  {
    id: 3,
    title: "Premium",
    price: 10000,
    features: [
      "10 Invoices",
      "Unlimited Customers",
      "24/7 Online Support",
      "DashBoard and Analytics",
      "10 Expenses",
    ],
    plan: "Upgrade",
  },
];

export const monthlyPlan: Plan[] = [
  {
    title: "Free plan",
    duration: "/ monthly",
    price: 0,
    features: [
      {
        name: "10 Invoices",
        tooltip:
          "With Esemie invoicing feature, you get to create invoices, manage payments and even issue refunds for your customers",
      },
      {
        name: "10 Expenses",
        tooltip:
          "With Esemie expense feature, you get to create expenses and keep proper records of the money going out of your business.",
      },
      {
        name: "5 Inventory",
        tooltip:
          "Esemie inventory allows users to keep proper records of everything they have in stock. Users can restock, get alerts for low stock and even manage stock removals in case of damage.",
      },
      {
        name: "3 Services",
        tooltip:
          "With Esemie, Users can keep proper records of all their services.",
      },
      {
        name: "Customized Invoice Template",
        tooltip:
          "Esemie provides users with an invoice template easily customizable with their logo & brand color.",
      },
      {
        name: "Unlimited customers",
        tooltip:
          "Esemie robust feature give users the platform to properly manage customers records.",
      },
      {
        name: "Unlimited Report",
        tooltip:
          "Esemie report feature allows you to generate of your invoice, expense, inventory, profit &loss reports of your business.",
      },
      {
        name: "Dashboard and Analytics",
        tooltip:
          "The Esemie dashboard offers you a robust insight into money coming in and going out of your account.",
      },
      { name: "24/7 Online support" },
    ],
    buttonText: "Use free plan",
    plan: "",
  },
  {
    // title:  t('subscription.basicPlan'),
    title: "Essential plan",
    subPrice: 5000,
    price: 2500,
    duration: "/ monthly",
    features: [
      {
        name: "30 Invoices",
        tooltip:
          "With Esemie invoicing feature, you get to create invoices, manage payments and even issue refunds for your customers",
      },
      {
        name: "30 Expenses",
        tooltip:
          "With Esemie expense feature, you get to create expenses and keep proper records of the money going out of your business.",
      },
      {
        name: "20 Inventory",
        tooltip:
          "Esemie inventory allows users to keep proper records of everything they have in stock. Users can restock, get alerts for low stock and even manage stock removals in case of damage",
      },
      {
        name: "10 Services",
        tooltip:
          "With Esemie, Users can keep proper records of all their services.",
      },
      {
        name: "10 Invoice payment upload",
        tooltip:
          "Esemie AI feature allows users to record payment by uploading a screenshot of the receipt while Esemie extract these record and ensure a balanced account.",
      },
      {
        name: "Send Reminder",
        tooltip:
          "The Send Reminder feature allows users to automatically or manually send reminders to clients for overdue or upcoming invoice payments.",
      },
      {
        name: "Unlimited Report",
        tooltip:
          "Esemie report feature allows you to generate of your invoice, expense, inventory, profit &loss reports of your business",
      },
      {
        name: "Weekly Report",
        tooltip:
          "Esemie weekly report gives you a detailed summary and insight into your business financial health",
      },
      {
        name: "Payment Integration",
        tooltip:
          "With Esemie you can receive payment anywhere. Esemie allows you to generate payment link from paystack making payment management easier.",
      },
      { name: "User Management (3 Users)", tooltip: "Manage up to 3 users" },
      {
        name: "Unlimited customers",
        tooltip:
          "Esemie robust feature give users the platform to properly manage customers records.",
      },
      {
        name: "Customized Invoice Template",
        tooltip:
          "Esemie provides users with an invoice template easily customizable with their logo & brand color.",
      },
      {
        name: "Dashboard and Analytics",
        tooltip:
          "The Esemie dashboard offers you a robust insight into money coming in and going out of your account.",
      },
      { name: "24/7 Online support" },
    ],
    // buttonText:  t('subscription.chooseThisPlan'),
    buttonText: "Choose this plan",
    plan: "essential-monthly-discount",
  },
  {
    // title:  t('subscription.premiumPlan'),
    title: "Premium plan",
    price: 4500,
    subPrice: 9000,
    duration: "/ monthly",
    features: [
      {
        name: "100 Invoices",
        tooltip:
          "With Esemie invoicing feature, you get to create invoices, manage payments and even issue refunds for your customers",
      },
      {
        name: "200 Expenses",
        tooltip:
          "With Esemie expense feature, you get to create expenses and keep proper records of the money going out of your business.",
      },
      {
        name: "Unlimited Inventory",
        tooltip:
          "Esemie inventory allows users to keep proper records of everything they have in stock. Users can restock, get alerts for low stock and even manage stock removals in case of damage",
      },
      {
        name: "Service Management",
        tooltip:
          "With Esemie, Users can keep proper records of all their services.",
      },
      {
        name: "50 Invoice payment upload",
        tooltip:
          "Esemie AI feature allows users to record payment by uploading a screenshot of the receipt while Esemie extract these record and ensure a balanced account.",
      },
      {
        name: "Send Reminder",
        tooltip:
          "The Send Reminder feature allows users to automatically or manually send reminders to clients for overdue or upcoming invoice payments.",
      },
      {
        name: "50 Expenses AI automation",
        tooltip:
          "With Esemie expense AI automation feature, users can upload a screenshot of their expense and Esemie organizes the records for them.",
      },
      {
        name: "Unlimited Report",
        tooltip:
          "Esemie report feature allows you to generate of your invoice, expense, inventory, profit &loss reports of your business",
      },
      {
        name: "Weekly Report",
        tooltip:
          "Esemie weekly report gives you a detailed summary and insight into your business financial health",
      },
      {
        name: "Payment Integration",
        tooltip:
          "With Esemie you can receive payment anywhere. Esemie allows you to generate payment link from paystack making payment management easier.",
      },
      { name: "User Management (6 Users)", tooltip: "Manage up to 3 users" },
      {
        name: "Unlimited customers",
        tooltip:
          "Esemie robust feature give users the platform to properly manage customers records.",
      },
      {
        name: "Customized Invoice Template",
        tooltip:
          "Esemie provides users with an invoice template easily customizable with their logo & brand color.",
      },
      {
        name: "Dashboard and Analytics",
        tooltip:
          "The Esemie dashboard offers you a robust insight into money coming in and going out of your account.",
      },
      { name: "24/7 Online support" },
    ],
    buttonText: "Choose this plan",
    plan: "premium-monthly-discount",
  },
];

export const yearlyPlan: Plan[] = [
  {
    // title: t('subscription.freePlan'),
    title: "Free plan",
    duration: "/ yearly",
    price: 0,
    features: [
      {
        name: "10 Invoices",
        tooltip:
          "With Esemie invoicing feature, you get to create invoices, manage payments and even issue refunds for your customers",
      },
      {
        name: "10 Expenses",
        tooltip:
          "With Esemie expense feature, you get to create expenses and keep proper records of the money going out of your business.",
      },
      {
        name: "5 Inventory",
        tooltip:
          "Esemie inventory allows users to keep proper records of everything they have in stock. Users can restock, get alerts for low stock and even manage stock removals in case of damage.",
      },
      {
        name: "3 Services",
        tooltip:
          "With Esemie, Users can keep proper records of all their services.",
      },
      {
        name: "Customized Invoice Template",
        tooltip:
          "Esemie provides users with an invoice template easily customizable with their logo & brand color.",
      },
      {
        name: "Unlimited customers",
        tooltip:
          "Esemie robust feature give users the platform to properly manage customers records.",
      },
      {
        name: "Unlimited Report",
        tooltip:
          "Esemie report feature allows you to generate of your invoice, expense, inventory, profit &loss reports of your business.",
      },
      {
        name: "Dashboard and Analytics",
        tooltip:
          "The Esemie dashboard offers you a robust insight into money coming in and going out of your account.",
      },
      { name: "24/7 Online support" },
    ],
    buttonText: "Use free plan",
    plan: "",
  },
  {
    // title:  t('subscription.basicPlan'),
    title: "Essential plan",
    subPrice: 50000,
    price: 25000,
    duration: "/ yearly",
    features: [
      {
        name: "30 Invoices",
        tooltip:
          "With Esemie invoicing feature, you get to create invoices, manage payments and even issue refunds for your customers",
      },
      {
        name: "30 Expenses",
        tooltip:
          "With Esemie expense feature, you get to create expenses and keep proper records of the money going out of your business.",
      },
      {
        name: "20 Inventory",
        tooltip:
          "Esemie inventory allows users to keep proper records of everything they have in stock. Users can restock, get alerts for low stock and even manage stock removals in case of damage",
      },
      {
        name: "10 Services",
        tooltip:
          "With Esemie, Users can keep proper records of all their services.",
      },
      {
        name: "10 Invoice payment upload",
        tooltip:
          "Esemie AI feature allows users to record payment by uploading a screenshot of the receipt while Esemie extract these record and ensure a balanced account.",
      },
      {
        name: "Send Reminder",
        tooltip:
          "The Send Reminder feature allows users to automatically or manually send reminders to clients for overdue or upcoming invoice payments.",
      },
      {
        name: "Unlimited Report",
        tooltip:
          "Esemie report feature allows you to generate of your invoice, expense, inventory, profit &loss reports of your business",
      },
      {
        name: "Weekly Report",
        tooltip:
          "Esemie weekly report gives you a detailed summary and insight into your business financial health",
      },
      {
        name: "Payment Integration",
        tooltip:
          "With Esemie you can receive payment anywhere. Esemie allows you to generate payment link from paystack making payment management easier.",
      },
      { name: "User Management (3 Users)", tooltip: "Manage up to 3 users" },
      {
        name: "Unlimited customers",
        tooltip:
          "Esemie robust feature give users the platform to properly manage customers records.",
      },
      {
        name: "Customized Invoice Template",
        tooltip:
          "Esemie provides users with an invoice template easily customizable with their logo & brand color.",
      },
      {
        name: "Dashboard and Analytics",
        tooltip:
          "The Esemie dashboard offers you a robust insight into money coming in and going out of your account.",
      },
      { name: "24/7 Online support" },
    ],
    buttonText: "Choose this plan",
    plan: "essential-yearly-discount",
  },
  {
    // title:  t('subscription.premiumPlan'),
    title: "Premium plan",
    price: 45000,
    subPrice: 90000,
    duration: "/ yearly",
    features: [
      {
        name: "100 Invoices",
        tooltip:
          "With Esemie invoicing feature, you get to create invoices, manage payments and even issue refunds for your customers",
      },
      {
        name: "200 Expenses",
        tooltip:
          "With Esemie expense feature, you get to create expenses and keep proper records of the money going out of your business.",
      },
      {
        name: "Unlimited Inventory",
        tooltip:
          "Esemie inventory allows users to keep proper records of everything they have in stock. Users can restock, get alerts for low stock and even manage stock removals in case of damage",
      },
      {
        name: "Service Management",
        tooltip:
          "With Esemie, Users can keep proper records of all their services.",
      },
      {
        name: "50 Invoice payment upload",
        tooltip:
          "Esemie AI feature allows users to record payment by uploading a screenshot of the receipt while Esemie extract these record and ensure a balanced account.",
      },
      {
        name: "Send Reminder",
        tooltip:
          "The Send Reminder feature allows users to automatically or manually send reminders to clients for overdue or upcoming invoice payments.",
      },
      {
        name: "50 Expenses AI automation",
        tooltip:
          "With Esemie expense AI automation feature, users can upload a screenshot of their expense and Esemie organizes the records for them.",
      },
      {
        name: "Unlimited Report",
        tooltip:
          "Esemie report feature allows you to generate of your invoice, expense, inventory, profit &loss reports of your business",
      },
      {
        name: "Weekly Report",
        tooltip:
          "Esemie weekly report gives you a detailed summary and insight into your business financial health",
      },
      {
        name: "Payment Integration",
        tooltip:
          "With Esemie you can receive payment anywhere. Esemie allows you to generate payment link from paystack making payment management easier.",
      },
      { name: "User Management (6 Users)", tooltip: "Manage up to 3 users" },
      {
        name: "Unlimited customers",
        tooltip:
          "Esemie robust feature give users the platform to properly manage customers records.",
      },
      {
        name: "Customized Invoice Template",
        tooltip:
          "Esemie provides users with an invoice template easily customizable with their logo & brand color.",
      },
      {
        name: "Dashboard and Analytics",
        tooltip:
          "The Esemie dashboard offers you a robust insight into money coming in and going out of your account.",
      },
      { name: "24/7 Online support" },
    ],
    buttonText: "Choose this plan",
    plan: "premium-yearly-discount",
  },
];

export const Active = "active";
export const Cancelled = "cancelled";

export const servicesType = {
  Invoices: "invoices" as const,
  Quotes: "quotes" as const,
  Expenses: "expenses" as const,
  Services: "services" as const,
  Inventories: "inventories" as const,
  Users: "user" as const,
};

export const Free = "free";
