import React, { useLayoutEffect } from "react";
import { ICreateSalesData } from "../../../@types";
import { useLocation, useParams } from "react-router-dom";
import QuoteParent from "../../../components/Sales/quotes/quoteParent";

const CreateQuotePage = () => {
  const location = useLocation();
  const [quoteDataState, setQuoteDataState] = React.useState<ICreateSalesData>({
    customerId: "",
    description: "",
    discount: 0,
    inventories: [],
    expiryDate: "",
    invoiceNumber: "",
    issueDate: new Date().toISOString().substring(0, 10),
    status: "active",
    tax: 0,
    shouldSendEmail: true,
    exchangeRate: 0,
    currency: "",
  });

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <>
      <QuoteParent
        quoteData={quoteDataState}
        setQuoteData={setQuoteDataState}
        headingText="Add Quote"
        customerId={""}
      />
    </>
  );
};

export { CreateQuotePage };
