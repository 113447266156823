import { useState, useEffect, useCallback } from "react";
import {
  AuthenticationDetails,
  CognitoUserSession,
  CognitoUserAttribute,
  ISignUpResult,
  CognitoUser,
  ICognitoUserPoolData,
} from "amazon-cognito-identity-js";
import userPool from "../../utils/userPool";
import { removeToken, setToken } from "../../utils/token";

interface UseCognitoReturn {
  user: CognitoUser | null;
  session: CognitoUserSession | null;
  loading: boolean;
  error: string | null;
  login: (username: string, password: string) => Promise<CognitoUserSession>;
  logout: () => void;
  register: (
    username: string,
    password: string,
    attributes: CognitoUserAttribute[],
  ) => Promise<ISignUpResult>;
  forgotPassword: (username: string) => Promise<void>;
  confirmPassword: (
    username: string,
    verificationCode: string,
    newPassword: string,
  ) => Promise<void>;
  refreshSession: () => Promise<CognitoUserSession>;
}

const useCognito = (): UseCognitoReturn => {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [session, setSession] = useState<CognitoUserSession | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [lastVerificationCodeSent, setLastVerificationCodeSent] = useState<
    number | null
  >(null);

  const login = useCallback(
    (username: string, password: string): Promise<CognitoUserSession> => {
      setLoading(true);
      setError(null);

      return new Promise<CognitoUserSession>((resolve, reject) => {
        const authenticationDetails = new AuthenticationDetails({
          Username: username,
          Password: password,
        });

        const cognitoUser = new CognitoUser({
          Username: username,
          Pool: userPool,
        });

        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (session: CognitoUserSession) => {
            setUser(cognitoUser);
            setSession(session);
            setLoading(false);
            const token = session.getIdToken().getJwtToken();
            setToken(token);
            resolve(session);
          },
          onFailure: (err: Error) => {
            console.error("Login failed:", err);
            setError(err.message || JSON.stringify(err));
            setLoading(false);
            reject(err);
          },
        });
      });
    },
    [],
  );

  const logout = useCallback((): void => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser.signOut();
      setUser(null);
      setSession(null);
      removeToken();
    }
  }, []);

  const register = useCallback(
    (
      username: string,
      password: string,
      attributes: CognitoUserAttribute[],
    ): Promise<ISignUpResult> => {
      setLoading(true);
      setError(null);

      return new Promise<ISignUpResult>((resolve, reject) => {
        userPool.signUp(
          username,
          password,
          attributes,
          null as unknown as CognitoUserAttribute[],
          // @ts-ignore
          (err: Error | null, result?: ISignUpResult) => {
            setLoading(false);
            if (err) {
              console.error("Registration failed:", err);
              setError(err.message || JSON.stringify(err));
              reject(err);
            } else if (result) {
              resolve(result);
            } else {
              const unexpectedError = new Error(
                "Unexpected error during registration.",
              );
              console.error(unexpectedError);
              setError(unexpectedError.message);
              reject(unexpectedError);
            }
          },
        );
      });
    },
    [],
  );

  const forgotPassword = useCallback((username: string): Promise<void> => {
    setLoading(true);
    setError(null);

    return new Promise<void>((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      cognitoUser.forgotPassword({
        onSuccess: () => {
          setLoading(false);
          setLastVerificationCodeSent(Date.now());
          resolve();
        },
        onFailure: (err: Error) => {
          console.error("Forgot password failed:", err);
          setError(err.message || JSON.stringify(err));
          setLoading(false);
          reject(err);
        },
      });
    });
  }, []);

  const confirmPassword = useCallback(
    (
      username: string,
      verificationCode: string,
      newPassword: string,
    ): Promise<void> => {
      setLoading(true);
      setError(null);

      if (
        lastVerificationCodeSent &&
        Date.now() - lastVerificationCodeSent > 300000
      ) {
        const error = new Error(
          "Verification code expired or is not valid anymore.",
        );
        setError(error.message);
        setLoading(false);
        return Promise.reject(error);
      }

      return new Promise<void>((resolve, reject) => {
        const cognitoUser = new CognitoUser({
          Username: username,
          Pool: userPool,
        });

        cognitoUser.confirmPassword(verificationCode, newPassword, {
          onSuccess: () => {
            setLoading(false);
            resolve();
          },
          onFailure: (err: Error) => {
            console.error("Confirm password failed:", err);
            setError(err.message || JSON.stringify(err));
            setLoading(false);
            reject(err);
          },
        });
      });
    },
    [lastVerificationCodeSent],
  );

  const refreshSession = useCallback((): Promise<CognitoUserSession> => {
    if (!user) {
      console.warn("No user to refresh session for.");
      return Promise.reject(new Error("No user is currently logged in."));
    }

    return new Promise<CognitoUserSession>((resolve, reject) => {
      user.getSession(
        (err: Error | null, currentSession: CognitoUserSession | null) => {
          if (err) {
            console.error("Error getting current session:", err);
            setError(err.message || JSON.stringify(err));
            reject(err);
          } else if (!currentSession) {
            const sessionError = new Error("No session available.");
            console.error(sessionError);
            setError(sessionError.message);
            reject(sessionError);
          } else {
            if (currentSession.isValid()) {
              setSession(currentSession);
              const token = currentSession.getIdToken().getJwtToken();
              setToken(token);
              resolve(currentSession);
            } else {
              user.refreshSession(
                currentSession.getRefreshToken(),
                (
                  refreshErr: Error | null,
                  refreshedSession: CognitoUserSession | null,
                ) => {
                  if (refreshErr) {
                    console.error("Failed to refresh session:", refreshErr);
                    setError(refreshErr.message || JSON.stringify(refreshErr));
                    reject(refreshErr);
                  } else if (refreshedSession) {
                    setSession(refreshedSession);
                    const token = refreshedSession.getIdToken().getJwtToken();
                    setToken(token);
                    resolve(refreshedSession);
                  } else {
                    const refreshError = new Error(
                      "Failed to refresh session.",
                    );
                    console.error(refreshError);
                    setError(refreshError.message);
                    reject(refreshError);
                  }
                },
              );
            }
          }
        },
      );
    });
  }, [user]);

  useEffect(() => {
    let refreshTimer: NodeJS.Timeout;

    const scheduleRefresh = (currentSession: CognitoUserSession) => {
      const expiresAt = currentSession.getAccessToken().getExpiration() * 1000;
      const now = Date.now();
      const timeout = expiresAt - now - 5 * 60 * 1000; // Refresh 5 minutes before expiration

      if (timeout > 0) {
        refreshTimer = setTimeout(() => {
          refreshSession()
            .then((newSession) => {
              scheduleRefresh(newSession);
            })
            .catch((err) => {
              console.error("Failed to refresh session:", err);
              logout();
            });
        }, timeout);
      } else {
        refreshSession()
          .then((newSession) => {
            scheduleRefresh(newSession);
          })
          .catch((err) => {
            console.error("Failed to refresh session:", err);
            logout();
          });
      }
    };

    if (session) {
      scheduleRefresh(session);
    } else {
      const currentUser = userPool.getCurrentUser();
      if (currentUser) {
        currentUser.getSession(
          (err: Error | null, currentSession: CognitoUserSession | null) => {
            if (err) {
              console.error("Error initializing session:", err);
              logout();
            } else if (currentSession) {
              setUser(currentUser);
              setSession(currentSession);
              const token = currentSession.getIdToken().getJwtToken();
              setToken(token);
              scheduleRefresh(currentSession);
            }
          },
        );
      }
    }

    return () => {
      if (refreshTimer) {
        clearTimeout(refreshTimer);
      }
    };
  }, [session, refreshSession, logout]);

  useEffect(() => {
    const initializeUser = () => {
      const currentUser = userPool.getCurrentUser();
      if (currentUser) {
        currentUser.getSession(
          (err: Error | null, currentSession: CognitoUserSession | null) => {
            if (err) {
              console.error("Error initializing user session:", err);
              logout();
            } else if (currentSession) {
              setUser(currentUser);
              setSession(currentSession);
              const token = currentSession.getIdToken().getJwtToken();
              setToken(token);
            }
          },
        );
      }
    };

    initializeUser();
  }, [logout]);

  return {
    user,
    session,
    loading,
    error,
    login,
    logout,
    register,
    forgotPassword,
    confirmPassword,
    refreshSession,
  };
};

export default useCognito;
