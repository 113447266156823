import React from "react";

const NairaCurrency = () => {
  return (
    <div>
      <svg
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.22222 0.28125C1.63285 0.28125 1.06762 0.515376 0.650874 0.932124C0.234126 1.34887 0 1.9141 0 2.50347L0 12.5035C0 13.0928 0.234126 13.6581 0.650874 14.0748C1.06762 14.4916 1.63285 14.7257 2.22222 14.7257H6.66667V0.28125H2.22222Z"
          fill="#009A49"
        />
        <path
          d="M6.66663 0.28125H13.3333V14.7257H6.66663V0.28125Z"
          fill="#EEEEEE"
        />
        <path
          d="M17.7778 0.28125H13.3334V14.7257H17.7778C18.3672 14.7257 18.9324 14.4916 19.3492 14.0748C19.7659 13.6581 20 13.0928 20 12.5035V2.50347C20 1.9141 19.7659 1.34887 19.3492 0.932124C18.9324 0.515376 18.3672 0.28125 17.7778 0.28125Z"
          fill="#009A49"
        />
      </svg>
    </div>
  );
};

export default NairaCurrency;
