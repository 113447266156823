import React from "react";

interface UnionIconProps {
  color?: string;
}

const UnionIcon = ({ color }: UnionIconProps) => {
  return (
    <>
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L6.29289 6.29289C6.62623 6.62623 6.79289 6.79289 6.79289 7C6.79289 7.20711 6.62623 7.37377 6.29289 7.70711L1 13"
          stroke={color || "currentColor"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export { UnionIcon };
