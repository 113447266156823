import React, { useEffect } from "react";
import { useThermalPrinter } from "../../hooks/usethermalPrinter";
import { connectAndPrintUSB } from "../../utils";
import { encodeReceipt } from "../../utils/EscPosEncoder";
import { useUserStore } from "../../state";
interface ThermalPrinterProps {
  receipt: any;
  printCounter: number;
}

const ThermalPrinter: React.FC<ThermalPrinterProps> = ({
  receipt,
  printCounter,
}) => {
  const {
    isApiSupported,
    printer,
    error,
    setError,
    setPrinter,
    handleDetectPrinters,
  } = useThermalPrinter();
  const { user } = useUserStore();

  useEffect(() => {
    const storedPrinterJSON = localStorage.getItem("selectedPrinter");
    if (storedPrinterJSON) {
      const storedPrinter = JSON.parse(storedPrinterJSON);
      if (storedPrinter && storedPrinter.name) {
        setPrinter(storedPrinter);
      } else {
        localStorage.removeItem("selectedPrinter");
      }
    }
  }, []);

  useEffect(() => {
    if (printCounter > 0) {
      printReceipt().then(() => {});
    }
  }, [printCounter]);

  const printReceipt = async () => {
    try {
      let currentPrinter = printer;
      if (!currentPrinter || !currentPrinter.name) {
        currentPrinter = await handleDetectPrinters();
      }
      if (currentPrinter && currentPrinter.name) {
        await connectAndPrintUSB(
          currentPrinter,
          await encodeReceipt(receipt, user),
        );
      }
    } catch (error) {
      console.error("Printing error:", error);
    }
  };

  if (!isApiSupported) {
    return (
      <p>
        Your browser does not support the required APIs. Please use a compatible
        browser or alternative methods to print receipts.
      </p>
    );
  }

  return (
    <div>
      <div>
        {error && (
          <div className="error" role="alert">
            <strong>Error:</strong> {error}
            <button onClick={() => setError(null)}>Close</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThermalPrinter;
