import React from "react";
import { useUserStore } from "../../../state/useUserStore";

interface TableProps {
  columns: string[];
  data: any[][];
}

const PreviewTable: React.FC<TableProps> = ({ columns, data }) => {
  const { user } = useUserStore();
  if (!data) {
    // Handle the case where data is undefined, if needed
    return null;
  }
  return (
    <div>
      <div className="relative ">
        <table className="w-full text-sm text-left text-black dark:text-black">
          <thead
            className="text-[11px] text-white sentencecase "
            style={{ backgroundColor: user.brandColor || "#044e97" }}
          >
            <tr>
              {columns.map((column, index) => (
                <th
                  key={index}
                  scope="col"
                  className="pl-[4em] py-3 text-[11px]"
                >
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className={`${
                  rowIndex % 2 === 0
                    ? "bg-white text-[11px]"
                    : "bg-white dark:bg-gray-800"
                } border-b dark:border-gray-700 text-[11px]`}
              >
                {row.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    className={`pl-[4em] py-4 ${
                      cellIndex === 0
                        ? " text-black md:text-[11px] text-[11px]"
                        : ""
                    } whitespace-nowrap dark:text-white`}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PreviewTable;
