import React from "react";

const ThreeDot = () => {
  return (
    <>
      <svg
        width="3"
        height="15"
        viewBox="0 0 3 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 13.5C3 13.7967 2.91203 14.0867 2.74721 14.3334C2.58238 14.58 2.34812 14.7723 2.07403 14.8858C1.79994 14.9994 1.49834 15.0291 1.20737 14.9712C0.916394 14.9133 0.64912 14.7704 0.439341 14.5607C0.229562 14.3509 0.0867006 14.0836 0.0288228 13.7926C-0.0290551 13.5017 0.000649922 13.2001 0.114181 12.926C0.227713 12.6519 0.419972 12.4176 0.666646 12.2528C0.913319 12.088 1.20333 12 1.5 12C1.89783 12 2.27936 12.158 2.56066 12.4393C2.84197 12.7206 3 13.1022 3 13.5ZM1.5 3C1.79667 3 2.08668 2.91203 2.33336 2.74721C2.58003 2.58238 2.77229 2.34811 2.88582 2.07403C2.99935 1.79994 3.02906 1.49834 2.97118 1.20737C2.9133 0.916394 2.77044 0.649119 2.56066 0.439341C2.35088 0.229562 2.08361 0.0867006 1.79264 0.0288228C1.50166 -0.0290551 1.20007 0.000649922 0.925976 0.114181C0.651887 0.227713 0.417619 0.419972 0.252797 0.666646C0.0879744 0.913319 8.08342e-07 1.20333 8.08342e-07 1.5C8.08342e-07 1.89783 0.158036 2.27936 0.439341 2.56066C0.720645 2.84197 1.10218 3 1.5 3ZM1.5 6C1.20333 6 0.913319 6.08797 0.666646 6.2528C0.419972 6.41762 0.227713 6.65189 0.114181 6.92598C0.000649922 7.20007 -0.0290551 7.50166 0.0288228 7.79264C0.0867006 8.08361 0.229562 8.35088 0.439341 8.56066C0.64912 8.77044 0.916394 8.9133 1.20737 8.97118C1.49834 9.02906 1.79994 8.99935 2.07403 8.88582C2.34812 8.77229 2.58238 8.58003 2.74721 8.33336C2.91203 8.08668 3 7.79667 3 7.5C3 7.10218 2.84197 6.72065 2.56066 6.43934C2.27936 6.15804 1.89783 6 1.5 6Z"
          fill="black"
        />
      </svg>
    </>
  );
};

export default ThreeDot;
