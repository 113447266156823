import React from "react";
import { ConfirmAccountView } from "../../components/auth";

interface Props {
  children?: any;
}

export const ConfirmAccountPage: React.FC<Props> = ({ children }) => {
  return <ConfirmAccountView />;
};
