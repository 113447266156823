import React from "react";
import { DownloadIcon } from "../../assets/icons";
import { DownloadPaymentIcon } from "../../assets/icons/DownloadPaymentIcon";
import { DownloadPaymentIcon2 } from "../../assets/icons/DownloadPaymentIcon2";

interface DownloadButtonProps {
  receiptUrl?: string;
  isDownloading: boolean;
  onDownload: () => void;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  receiptUrl,
  isDownloading,
  onDownload,
}) => {
  if (receiptUrl) {
    return (
      <button
        className="flex items-center text-blue-600 hover:underline"
        onClick={onDownload}
        disabled={isDownloading}
        aria-label="Download Receipt"
      >
        {isDownloading ? "Download Processing..." : ""}
        <DownloadIcon />
      </button>
    );
  } else {
    return (
      <span className="flex items-center text-gray-500 cursor-not-allowed">
        <DownloadPaymentIcon2 />
      </span>
    );
  }
};

export default DownloadButton;
