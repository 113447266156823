import React from "react";

const SendReminderIcon2 = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.17157 13H6C4.34315 13 3 11.6569 3 10C3 8.34315 4.34315 7 6 7C6.27954 7 6.41931 7 6.51699 6.98034C6.81502 6.92036 6.95652 6.82876 7.13328 6.58143C7.19121 6.50036 7.27282 6.31851 7.43606 5.95481C8.21776 4.21308 9.96722 3 12 3C14.0328 3 15.7822 4.21307 16.5639 5.95481C16.7272 6.31851 16.8088 6.50036 16.8667 6.58143C17.0435 6.82876 17.185 6.92036 17.483 6.98034C17.5807 7 17.7205 7 18 7C19.6569 7 21 8.34315 21 10C21 11.6569 19.6569 13 18 13H16.8284L13.4142 9.58579L12 8.17157L10.5858 9.58579L7.17157 13Z"
          fill="#044E97"
        />
        <path
          d="M12 12L11.2929 11.2929L12 10.5858L12.7071 11.2929L12 12ZM13 21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21L13 21ZM7.29289 15.2929L11.2929 11.2929L12.7071 12.7071L8.70711 16.7071L7.29289 15.2929ZM12.7071 11.2929L16.7071 15.2929L15.2929 16.7071L11.2929 12.7071L12.7071 11.2929ZM13 12L13 21L11 21L11 12L13 12Z"
          fill="#044E97"
        />
      </svg>
    </div>
  );
};

export default SendReminderIcon2;
