import * as React from "react";
import emptyTransaction from "./svgicons/emptyTransaction.svg";

interface EmptyIconProps {
  color?: string;
  style?: React.CSSProperties;
}

const EmptyTransIcon: React.FC<EmptyIconProps> = (props) => {
  return <img {...props} src={emptyTransaction} alt=" empty icon" />;
};

export { EmptyTransIcon };
