import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../../../assets/icons";

interface CancelConfirmationPromptProps {
  onDisable: () => void;
  onClose?: () => void;
  showPrompt: boolean;
}

const DisablePrompt: React.FC<CancelConfirmationPromptProps> = ({
  onDisable,
  onClose,
}) => {
  const handleDisableReminder = async () => {
    onDisable();
    onClose && onClose();
  };

  const handleKeepReminder = () => {
    onClose && onClose();
  };

  const { t } = useTranslation("invoices");

  return (
    <>
      <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
        <div className="bg-white rounded-md shadow-lg w-[90%] max-w-[500px] overflow-hidden px-[1em] mb-5 ">
          <div className="flex justify-between items-center px-4 py-3 ">
            <h1 className="text-[24px] font-medium text-gray-900 text-center">
              Disable Reminder
            </h1>
            <button className="rounded-lg p-2" onClick={onClose}>
              <CloseIcon />
            </button>
          </div>

          <div className="px-4 py-6 text-left text-[18px] font-normal">
            <p className="text-gray-700">
              Are you sure you want to disable invoice reminder automation? This
              means reminder will no longer be sent to this customer.
            </p>
          </div>

          <div className="flex justify-between mb-5 px-[1em] gap-4">
            <button
              className="w-full max-w-[239px] rounded-md h-[55px] border border-[#FB0303] bg-[white] text-[#FB0303] font-semibold"
              onClick={handleKeepReminder}
            >
              Cancel
            </button>
            <button
              className="w-full max-w-[239px] rounded-md h-[55px] bg-[#FB0303] text-[white] font-semibold border "
              onClick={handleDisableReminder}
            >
              Disable Reminder
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { DisablePrompt };
