import { useSubscriptionStore, useUserStore } from "../state";
import { Active, Cancelled, servicesType } from "../constants/Subscription";

export interface SubscriptionCheckResult {
  status: boolean;
  message?: string;
}

export function checkSubscription(service: string): SubscriptionCheckResult {
  const subscription = useSubscriptionStore.getState().subscriptionData;

  if (!subscription) {
    return { status: false, message: "No active subscription found." };
  }

  if (subscription.status !== Active && subscription.status !== Cancelled) {
    return { status: false, message: "Subscription is not active." };
  }

  const usage = subscription.usage;
  const limits = subscription.limits;

  if (service === servicesType.Invoices && usage.invoices >= limits.invoices) {
    return { status: false, message: "Invoice limit exceeded." };
  }
  if (service === servicesType.Quotes && usage.quotes >= limits.quotes) {
    return { status: false, message: "Quotes limit exceeded." };
  }
  if (service === servicesType.Expenses && usage.expenses >= limits.expenses) {
    return { status: false, message: "Expenses limit exceeded." };
  }
  if (service === servicesType.Services && usage.services >= limits.services) {
    return { status: false, message: "Services limit exceeded." };
  }
  if (
    service === servicesType.Inventories &&
    usage.inventories >= limits.inventories
  ) {
    return { status: false, message: "Inventory limit exceeded." };
  }
  if (service === servicesType.Users && usage.users >= limits.users) {
    return { status: false, message: "User creation limit exceeded." };
  }
  return { status: true };
}

export const subscriptionStatus = (status: string, plan: string) => {
  return status !== "active" || plan === "free";
};
