import React from "react";
import { ImSpinner } from "react-icons/im";

const PageLoader: React.FC = () => {
  return (
    <section
      data-testid="page-1"
      className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50"
    >
      <div className="flex flex-col items-center space-x-2 animate-pulse">
        <ImSpinner className="h-20 w-20 text-slate-300 animate-spin" />
        <h4 className="text-xl text-white">Loading...</h4>
      </div>
    </section>
  );
};

export default PageLoader;
