import React from "react";

const CardIco = ({ color = "" }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill="#E0EEFE" />
        <path
          d="M6.16797 12.0007C6.16797 9.93712 6.16797 8.90535 6.7821 8.21651C6.88033 8.10633 6.98859 8.00444 7.10565 7.91199C7.83755 7.33398 8.9338 7.33398 11.1263 7.33398H12.8763C15.0688 7.33398 16.1651 7.33398 16.897 7.91199C17.014 8.00444 17.1223 8.10633 17.2205 8.21651C17.8346 8.90535 17.8346 9.93712 17.8346 12.0007C17.8346 14.0642 17.8346 15.096 17.2205 15.7848C17.1223 15.895 17.014 15.9969 16.897 16.0893C16.1651 16.6673 15.0688 16.6673 12.8763 16.6673H11.1263C8.9338 16.6673 7.83755 16.6673 7.10565 16.0893C6.98859 15.9969 6.88033 15.895 6.7821 15.7848C6.16797 15.096 6.16797 14.0642 6.16797 12.0007Z"
          stroke="#044E97"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.8359 14.334H11.7109"
          stroke="#044E97"
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.4609 14.334L15.5026 14.334"
          stroke="#044E97"
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.16797 10.25H17.8346"
          stroke="#044E97"
          stroke-width="1.2"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default CardIco;
