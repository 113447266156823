import React from "react";

const MarkIcon = () => {
  return (
    <>
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.683594 8.80234C3.4707 11.818 6.17305 14.5328 8.7707 17.9664C11.5949 12.3492 14.4855 6.7125 19.2551 0.608203L17.9699 0.0195312C13.9426 4.29063 10.8137 8.33359 8.09492 13.1383C6.2043 11.4352 3.14883 9.025 1.2832 7.78672L0.683594 8.80234Z"
          fill="#044E97"
        />
      </svg>
    </>
  );
};

export default MarkIcon;
