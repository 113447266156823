import React from "react";
import { Table } from "flowbite-react";

const InvoiceExample = () => {
  return (
    <div>
      {/* Invoice Itself */}
      <div className=" bg-white border mt-[24px] px-[70px] py-[70px] w-[850px] h-[1100px]">
        <div className="flex justify-between">
          <div>
            <p>
              Odalo PLC <br />
              Lekki, Lagos, <br />
              Nigeria
            </p>
          </div>

          <p className="text-right font-semibold">
            <p className="text-[30px] font-bold">INVOICE</p>
            <p className="text-[23px] "> INV-001</p>
            <p>
              Balance Due <br />
              NGN 4,000
            </p>
          </p>
        </div>

        <div className="mt-[76px]">
          <p>
            Bill to <br /> <br />
          </p>
          <div className="flex justify-between">
            <p className="leading-5">
              <p>
                Kelly Audu <br />
                Ilasan New Road, Ikate <br />
                Lekki, <br />
                Lagos State.
              </p>
            </p>

            <div>
              <p className="flex gap-[35px]">
                Invoice Date: <p>12th, August, 2020</p>
              </p>
              <br />
              <p className="flex gap-[57px]">
                Due Date: <p>12th, August, 2020</p>
              </p>
            </div>
          </div>
        </div>

        <div className="mt-[100px]"></div>
        <Table className="">
          <Table.Head className="text-white">
            <Table.HeadCell className="bg-[#044E97]  ">#</Table.HeadCell>
            <Table.HeadCell className="bg-[#044E97] ">
              Items/Description
            </Table.HeadCell>
            <Table.HeadCell className="bg-[#044E97] ">Qty</Table.HeadCell>
            <Table.HeadCell className="bg-[#044E97] ">Rate</Table.HeadCell>
            <Table.HeadCell className="bg-[#044E97] ">Amount</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                <a href="src/pages/invoices/vuiew.tsx"> 1</a>
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                <a href="src/pages/invoices/vuiew.tsx"> Cassava, Ewa, Agbado</a>
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                <a href="src/pages/invoices/vuiew.tsx"> 6.00</a>
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap font-semibold text-black dark:text-white">
                <p className="  py-[4px] w-[80px] rounded-[4px]">
                  <a href="src/pages/invoices/vuiew.tsx"> N800</a>
                </p>
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap font-semibold text-black dark:text-white">
                <p className="  py-[4px] w-[80px] rounded-[4px]">
                  <a href="src/pages/invoices/vuiew.tsx"> N4800</a>
                </p>
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                <a href="src/pages/invoices/vuiew.tsx"> 1</a>
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                <a href="src/pages/invoices/vuiew.tsx">Ewa</a>
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                <a href="src/pages/invoices/vuiew.tsx"> 6.00</a>
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap font-semibold text-black dark:text-white">
                <p className="  py-[4px] w-[80px] rounded-[4px]">
                  <a href="src/pages/invoices/vuiew.tsx"> N800</a>
                </p>
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap font-semibold text-black dark:text-white">
                <p className="  py-[4px] w-[80px] rounded-[4px]">
                  <a href="src/pages/invoices/vuiew.tsx"> N4800</a>
                </p>
              </Table.Cell>
            </Table.Row>
          </Table.Body>{" "}
        </Table>
        <div className="flex mt-60 justify-between">
          <div></div>
          <div className=" ">
            <p className="flex gap-[65px]">
              Sub Total: <p>₦4,000</p>{" "}
            </p>

            <p className="flex gap-[54px] mt-[20px] font-bold">
              Total(NGN): <p>₦4,000</p>{" "}
            </p>

            <div className="bg-[#F2F9FD] px-[5px] ">
              <p className="flex gap-[41px] py-[10px]  mt-[20px] font-bold">
                Balance Due: <p>₦4,000</p>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceExample;
