import React from "react";

const ArrowRight = ({ size = "6", color = "white" }) => {
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox="0 0 7 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_42149_21847)">
          <path
            d="M3.83008 0.884766L5.83008 2.99588L3.83008 5.10699"
            stroke={color}
            stroke-width="1.00392"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.82921 3.10547H1.38477"
            stroke={color}
            stroke-width="1.00392"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_42149_21847">
            <rect
              width="6"
              height="6"
              fill={color}
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default ArrowRight;
