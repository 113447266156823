import React from "react";

export const Bell = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="47" height="47" rx="11.5" fill="#F9FAFB" />
      <rect x="0.5" y="0.5" width="47" height="47" rx="11.5" stroke="#F2F4F7" />
      <path
        d="M33.5 28L33.989 28.5687C34.2614 28.3344 34.3294 27.9399 34.1512 27.6279L33.5 28ZM14.5 28L13.8488 27.6279C13.6706 27.9399 13.7386 28.3344 14.011 28.5687L14.5 28ZM31.5 24.5H30.75V24.6992L30.8488 24.8721L31.5 24.5ZM16.5 24.5L17.1512 24.8721L17.25 24.6992V24.5H16.5ZM24 31.75C28.0514 31.75 31.7088 30.5294 33.989 28.5687L33.011 27.4313C31.0683 29.1018 27.7895 30.25 24 30.25V31.75ZM14.011 28.5687C16.2912 30.5294 19.9486 31.75 24 31.75V30.25C20.2105 30.25 16.9316 29.1018 14.989 27.4313L14.011 28.5687ZM34.1512 27.6279L32.1512 24.1279L30.8488 24.8721L32.8488 28.3721L34.1512 27.6279ZM32.25 24.5V21.5H30.75V24.5H32.25ZM15.75 21.5V24.5H17.25V21.5H15.75ZM15.8488 24.1279L13.8488 27.6279L15.1512 28.3721L17.1512 24.8721L15.8488 24.1279ZM24 13.25C19.4437 13.25 15.75 16.9437 15.75 21.5H17.25C17.25 17.7721 20.2721 14.75 24 14.75V13.25ZM32.25 21.5C32.25 16.9437 28.5563 13.25 24 13.25V14.75C27.7279 14.75 30.75 17.7721 30.75 21.5H32.25Z"
        fill="#475467"
      />
      <path
        d="M28 30C28 32.2091 26.2091 34 24 34C21.7909 34 20 32.2091 20 30"
        stroke="#475467"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Bell;
