import React, { useState } from "react";
import { IInvoice } from "../../@types";
import { getQuoteDownload } from "../../backend-services";

interface DownloadInvoiceProps {
  quote: IInvoice;
  quoteId: string;
  onUpdate: (updatedInvoice: IInvoice) => void;
}

function useDownloadQuote({ quote, quoteId, onUpdate }: DownloadInvoiceProps) {
  const [isLoadingQuote, setIsLoadingQuote] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const downloadQuotePDF = async () => {
    setIsLoadingQuote(true);
    const currentTimestamp = Math.floor(Date.now() / 1000);

    try {
      const response = await getQuoteDownload(quoteId);

      if (!response || !response.url) {
        throw new Error("Invalid response received");
      }

      const blob = await fetch(response.url).then((res) => res.blob());

      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = `Quote-${quoteId}.pdf`;
      a.click();

      const expirationTimestamp = currentTimestamp + 7 * 24 * 60 * 60;
      const updatedInvoice = {
        ...quote,
        pdfUrl: response.url,
        expirationTimestamp,
      };
      onUpdate(updatedInvoice);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setIsLoadingQuote(false);
    }
  };

  return {
    downloadQuotePDF,
    isLoadingQuote,
    error,
  };
}

export { useDownloadQuote };
