interface DateRange {
  start: string;
  end: string;
}

export function DateTimeRange(date: Date): DateRange {
  const start = new Date(date);
  start.setUTCHours(0, 0, 0, 0);
  const end = new Date(date);
  end.setUTCHours(23, 59, 59, 999);

  return { start: start.toISOString(), end: end.toISOString() };
}

export const getMonthLabel = (month: number) => {
  const currentYear = new Date().getFullYear();

  const options = { month: "long" } as Intl.DateTimeFormatOptions;
  const date = new Date(currentYear, month - 1, 1);
  return date.toLocaleDateString("en-NG", options);
};

export function getCurrentMonthData(budgetData: any[]) {
  const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, "0");
  const currentYear = new Date().getFullYear().toString();
  const currentMonthYear = `${currentMonth}-${currentYear}`;

  return budgetData.find((item) => item.monthYear === currentMonthYear);
}
