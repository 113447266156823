import React from "react";
import { AlarmIcon } from "../../../assets/icons/alarm";
import { Button, SidebarDrawer } from "../../common";
import { useTranslation } from "react-i18next";
const subscriptionExpiry = () => {
  const { t } = useTranslation("subscription");
  return (
    <div className="flex flex-col">
      <div className="flex justify-center pb-[60px]">
        <AlarmIcon />
      </div>
      <div className="px-[24px]">
        <div className="mb-[40px] flex flex-col gap-[16px]">
          <h1 className="text-[24px]" style={{ fontWeight: 600 }}>
            {" "}
            {t("subscription.expired")}
          </h1>
          <p>{t("subscription.expiredMessage")}</p>
        </div>
        <div>
          <Button variant="danger">Subscribe Now</Button>
        </div>
      </div>
    </div>
  );
};

export default subscriptionExpiry;
