import { useSearch } from "../useSearch";
import { vendorService } from "../../services";
import { IVendor } from "../../@types";

export const useVendorSearch = () => {
  return useSearch({
    service: vendorService,
    endpoint: "vendors/search",
    queryParameter: "q",
    responseMapper: (data: any[]): any =>
      data.map((vendor: any) => ({
        ...vendor,
      })) as unknown as IVendor,
  });
};
