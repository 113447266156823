import React from "react";

const NewTooltips = () => {
  return (
    <div className="cursor-pointer">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.03942 0.166687H7.03942C8.49989 0.166678 9.64701 0.166671 10.5426 0.287083C11.4604 0.410474 12.1886 0.668497 12.7607 1.24063C13.3328 1.81276 13.5908 2.54093 13.7142 3.4587C13.8347 4.35431 13.8346 5.50142 13.8346 6.96188V6.96191V7.03813V7.03817C13.8346 8.49862 13.8347 9.64573 13.7142 10.5413C13.5908 11.4591 13.3328 12.1873 12.7607 12.7594C12.1886 13.3315 11.4604 13.5896 10.5426 13.713C9.64702 13.8334 8.4999 13.8334 7.03945 13.8334H7.03941H6.96319H6.96316C5.5027 13.8334 4.35559 13.8334 3.45998 13.713C2.54221 13.5896 1.81404 13.3315 1.24191 12.7594C0.669779 12.1873 0.411756 11.4591 0.288365 10.5413C0.167953 9.64573 0.16796 8.49861 0.167969 7.03814V7.03814V6.9619V6.9619C0.16796 5.50143 0.167953 4.35431 0.288365 3.4587C0.411756 2.54093 0.669779 1.81276 1.24191 1.24063C1.81404 0.668497 2.54221 0.410474 3.45998 0.287083C4.3556 0.166671 5.50271 0.166678 6.96318 0.166687H6.96319H7.03942ZM6.78922 6.01819C6.95392 6.04033 7.18175 6.09961 7.37508 6.29294C7.5684 6.48626 7.62768 6.71409 7.64982 6.87879C7.66816 7.01522 7.66806 7.17387 7.66798 7.30712L7.66797 7.33338V10C7.66797 10.3682 7.36949 10.6667 7.0013 10.6667C6.63311 10.6667 6.33464 10.3682 6.33464 10V7.33338C5.96645 7.33338 5.66797 7.0349 5.66797 6.66671C5.66797 6.29852 5.96645 6.00004 6.33464 6.00004L6.36089 6.00003C6.49414 5.99995 6.65279 5.99985 6.78922 6.01819ZM6.99826 3.33335C6.63172 3.33335 6.33458 3.63183 6.33458 4.00002C6.33458 4.36821 6.63172 4.66669 6.99826 4.66669H7.00423C7.37077 4.66669 7.66791 4.36821 7.66791 4.00002C7.66791 3.63183 7.37077 3.33335 7.00423 3.33335H6.99826Z"
          fill="#D0D5DD"
        />
      </svg>
    </div>
  );
};

export default NewTooltips;
