import * as React from "react";
import { Accordion, Button } from "../../common";
import { SalesStatus } from "../../../@types";
import { Statuses } from "../../../constants";
import { useEffect } from "react";
import { Filter } from "../../../@types/invoice";

interface InvoiceFilterProps {
  setStatus: (value: SalesStatus) => void;
  status?: string;
  isReoccurring?: boolean | null;
  setIsReoccurring: (value: boolean) => void;
  onSubmit: (e: React.ChangeEvent<HTMLFormElement>) => void;
  onClose: () => void;
  showFilter: boolean;
  handleFilter: (filter: Filter) => void;
}

const InvoiceFilter: React.FC<InvoiceFilterProps> = (props) => {
  const [localStatus, setLocalStatus] = React.useState<SalesStatus | string>(
    props.status ? (props.status as SalesStatus) : "",
  );
  const [localIsReoccurring, setLocalIsReoccurring] = React.useState<
    boolean | null
  >(typeof props.isReoccurring === "boolean" ? props.isReoccurring : null);

  useEffect(() => {
    props.setStatus(localStatus as SalesStatus);
    props.setIsReoccurring(localIsReoccurring as boolean);
  }, [
    localStatus,
    localIsReoccurring,
    props.setStatus,
    props.setIsReoccurring,
  ]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const modalContent = document.getElementById("modal-content");

      if (modalContent && !modalContent.contains(event.target as Node)) {
        props.onClose();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [props]);

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Assuming you have some data to filter with
    const filter: Filter = {
      status: localStatus,
      isReoccurring: localIsReoccurring,
      // Add other filter properties as needed
    };

    // Call the handleFilter function with the filter object
    props.handleFilter(filter);
    props.onClose();
  };
  return (
    <div className="lg:hidden">
      {props.showFilter && (
        <div id="modal-content">
          <form onSubmit={handleSubmit} className="w-[443px] py-4">
            <Accordion
              items={[
                {
                  title: "Status",
                  content: (
                    <>
                      <label htmlFor="cancelled" className="block mb-3">
                        <input
                          type="radio"
                          name="status"
                          id="status-cancelled"
                          value={Statuses.CANCELLED}
                          checked={
                            localStatus?.toUpperCase() ===
                            Statuses.CANCELLED.toUpperCase()
                          }
                          onChange={({ target }) =>
                            setLocalStatus(target.value as SalesStatus)
                          }
                        />
                        <span className="ml-3 inline-block text-gray-900">
                          Cancelled
                        </span>
                      </label>
                      <label htmlFor="paid" className="block mb-3">
                        <input
                          type="radio"
                          name="status"
                          id="status-paid"
                          value={Statuses.PAID}
                          checked={
                            localStatus?.toUpperCase() ===
                            Statuses.PAID.toUpperCase()
                          }
                          onChange={({ target }) =>
                            setLocalStatus(target.value as SalesStatus)
                          }
                        />
                        <span className="ml-3 inline-block text-gray-900">
                          Paid
                        </span>
                      </label>
                      <label htmlFor="draft" className="block mb-3">
                        <input
                          type="radio"
                          name="status"
                          id="draft"
                          value={Statuses.DRAFT}
                          checked={
                            localStatus?.toUpperCase() ===
                            Statuses.DRAFT.toUpperCase()
                          }
                          onChange={({ target }) =>
                            setLocalStatus(target.value as SalesStatus)
                          }
                        />
                        <span className="ml-3 inline-block text-gray-900">
                          Draft
                        </span>
                      </label>
                      <label htmlFor="pending" className="block mb-3">
                        <input
                          type="radio"
                          name="status"
                          id="status-pending"
                          value={Statuses.PENDING}
                          checked={
                            localStatus?.toUpperCase() ===
                            Statuses.PENDING?.toUpperCase()
                          }
                          onChange={({ target }) =>
                            setLocalStatus(target.value as SalesStatus)
                          }
                        />
                        <span className="ml-3 inline-block text-gray-900">
                          Pending
                        </span>
                      </label>
                      <label htmlFor="refunded" className="block mb-3">
                        <input
                          type="radio"
                          name="status"
                          id="status-refunded"
                          value={Statuses.REFUNDED}
                          checked={
                            localStatus?.toUpperCase() ===
                            Statuses.REFUNDED?.toUpperCase()
                          }
                          onChange={({ target }) =>
                            setLocalStatus(target.value as SalesStatus)
                          }
                        />
                        <span className="ml-3 inline-block text-gray-900">
                          Refunded
                        </span>
                      </label>
                    </>
                  ),
                },
                {
                  title: "Type",
                  content: (
                    <>
                      <label htmlFor="isReoccuring" className="block mb-3">
                        <input
                          type="radio"
                          name="reoccurring"
                          id="isReoccuring"
                          value="isReoccuring"
                          checked={localIsReoccurring === true}
                          onChange={() => setLocalIsReoccurring(true)}
                        />
                        <span className="ml-3 inline-block text-gray-900">
                          Reoccurring
                        </span>
                      </label>
                      <label htmlFor="non-reoccuring" className="block mb-3">
                        <input
                          type="radio"
                          name="non-reoccurring"
                          id="non-reoccuring"
                          value="non-reocurring"
                          checked={localIsReoccurring === false}
                          onChange={() => setLocalIsReoccurring(false)}
                        />
                        <span className="ml-3 inline-block text-gray-900">
                          {" "}
                          Non-Reoccurring{" "}
                        </span>
                      </label>
                    </>
                  ),
                },
              ]}
            />
            <div className="my-[20px] justify-center flex ml-[-23em] md:ml-[-14em] xl:ml-[1em]">
              <Button text="Select" size="small" />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default InvoiceFilter;
