import React from "react";
import { InventoryPage } from "../../components/inventory/Create/InventoryPage";

const CreateInventoryPage = () => {
  return (
    <div>
      <InventoryPage
        showSidebar={false}
        showVariationList={true}
        showCreateForm={true}
        showTitle={true}
        showForms={true}
      />
    </div>
  );
};

export { CreateInventoryPage };
