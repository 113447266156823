import React, { useState } from "react";
import { InputComponent } from "../../core/Forms";
import * as UI from "../../common";
import View from "../../../assets/icons/svgicons/View.svg";
import { validateCreatePasswordForm } from "../../../modules/settings/settings";
import { Button } from "../../common";
import ErrorComponent from "../../Error";
import { PasswordError } from "../../../@types/password";
import { useTranslation } from "react-i18next";

interface Props {
  onSubmit: (formData: {
    currentPassword: string;
    newPassword: string;
  }) => void;
  validationErrors: PasswordError;
  setValidationErrors: (error: PasswordError) => void;
  isLoading: boolean;
}

export const CurrentPassword: React.FC<Props> = ({
  onSubmit,
  validationErrors,
  setValidationErrors,
  isLoading,
}) => {
  const { t } = useTranslation("settings");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
  });

  const handleSubmit = async () => {
    setValidationErrors({ ...validationErrors, currentPassword: "" });

    try {
      const validationErrors = validateCreatePasswordForm(formData);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
      onSubmit(formData);
      setFormData({ currentPassword: "", newPassword: "" });
      setErrors({});
    } catch (error) {
      return <ErrorComponent errorCode={500} />;
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    setValidationErrors({ ...validationErrors, currentPassword: "" });
  };

  return (
    <div className="bg-[#FFFFFF] rounded-[8px] w-full lg:w-full h-screen lg:h-[788px] p-[5.7%] lg:p-11 text-[#212121]">
      <h1 className="text-[16px] md:text-[20px] font-semibold">
        {t("Settings.changepassword")}
      </h1>
      <p className="text-[12px] md:text-[16px] mt-2 md:mt-3">
        {t("Settings.secureyouraccountwithastrongpassword")}
      </p>

      <hr className="mt-[10px] mb-[20px] md:my-6 border border-[#000000] border-opacity-25 lg:max-w-[992px]" />

      <div className="w-full md:max-w-[434px]">
        <div className="text-[14px] md:text-[16px] mb-1 md:mb-2">
          {t("Settings.currentpassword")}
        </div>
        <InputComponent
          placeholder="Enter Password"
          name="currentPassword"
          type="password"
          value={formData.currentPassword}
          handleChange={handleInputChange}
          className="w-full !pl-4 rounded h-10 md:h-12 border !border-[#A4A4A4]"
          image={View}
          error={errors.currentPassword}
          imageClassName="ml-[90%] mt-[-2em] md:mt-[-2.2em]"
        />
        {validationErrors.currentPassword && (
          <div className="text-[#FB0303] text-[13px]">
            {validationErrors.currentPassword}
          </div>
        )}

        <div className="w-full  mt-8">
          <Button
            type="submit"
            text="Change Password"
            isLoading={isLoading}
            disabled={isLoading}
            font="semibold"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default CurrentPassword;
