import React from "react";

const ShowDropdownIcon = ({ iconClassName = "" }) => {
  return (
    <>
      <svg
        className={`block fill-none ${iconClassName}`}
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1.5L6 6.5L11 1.5"
          stroke="#667085"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default ShowDropdownIcon;
