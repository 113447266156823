import React, { useState, useEffect } from "react";
import { View } from "./view";
import PageLoader from "../../../common/PageLoader";
import { CreateEditProfile } from "../Edit";
import { ICreateProfile } from "../../../../@types";
import { useUserStore } from "../../../../state";
import NewEditIcon from "../../../../assets/icons/NewEditIcon";
import { TickIcon } from "../../../../assets/icons/TickIcon";

interface FormData {
  firstName: string;
  lastName: string;
  businessName: string;
  industry: string;
  email: string;
  phone: string;
  address: string;
  state: string;
  city: string;
  businessSize: string;
  code: string;
  brandColor: string;
  logoBase64: string;
  isVerified: boolean;
}

export const ViewProfile = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showEditProfile, setShowEditProfile] = useState<boolean>(false);
  const user = useUserStore((state) => state.user);
  const userStore = useUserStore();

  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    businessName: "",
    email: "",
    phone: "",
    industry: "",
    address: "",
    state: "",
    city: "",
    businessSize: "",
    brandColor: "",
    code: "",
    logoBase64: "",
    isVerified: false,
  });

  const fetchUserData = async () => {
    try {
      const userData = userStore.user;
      if (userData) {
        setFormData({
          firstName: userData.firstName || "",
          lastName: userData.lastName || "",
          businessName: userData.businessName || "",
          email: userData.email || "",
          phone: userData.phone || "",
          industry: userData.industry || "",
          address: userData.address || "",
          state: userData.state || "",
          city: userData.city || "",
          businessSize: userData.businessSize || "",
          brandColor: userData.brandColor || "",
          code: userData.code || "",
          logoBase64: userData.logoBase64 || "",
          isVerified: userData.isVerified || false,
        });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [userStore.user]);

  const handleUpdatedProfileData = (updatedData: ICreateProfile) => {
    if (
      typeof updatedData.brandLogo === "string" ||
      updatedData.brandLogo === undefined ||
      updatedData.brandLogo === null
    ) {
      setFormData({
        firstName: updatedData.firstName,
        lastName: updatedData.lastName,
        businessName: updatedData.businessName,
        email: updatedData.email,
        phone: updatedData.phone,
        industry: updatedData.industry,
        address: updatedData.address,
        state: updatedData.state,
        city: updatedData.city,
        code: updatedData.code,
        businessSize: updatedData.businessSize,
        brandColor: updatedData.brandColor as string,
        logoBase64: updatedData.logoBase64 as string,
        isVerified: updatedData.isVerified,
      });
    } else {
    }
  };

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <div className="mx-1 bg-[#FFFFFF] rounded-[12px] p-4 md:p-8 text-[#000000]">
            <div className="">
              <h1 className="text-[20px] font-bold leading-6">My Account</h1>

              <div className="border-[0.5px] border-[#A4A4A4] my-4 p-4 rounded-[8px] flex justify-between items-center">
                <div className="flex items-center gap-2 md:gap-[17px]">
                  <div className="bg-[#044E97] w-6 h-6 md:w-[100px] md:h-[100px] rounded-full text-[8.4px] md:text-[35px] text-[#ffff] flex justify-center items-center">
                    {formData.firstName[0]}
                  </div>

                  <div className=" text-[15px] md:text-[18px]">
                    <div className="flex">
                      <h4 className="font-bold leading-[18px] pr-3">
                        {formData.businessName}
                      </h4>
                      {user.isVerified ? (
                        <div className="flex border border-[#20A852] bg-[#F1F8F5] mt-[-0.4em] rounded-md">
                          <span className="mt-[0.3em] pl-1">
                            {" "}
                            <TickIcon />
                          </span>
                          <span className=" text-[#20A852] lg:text-[14px] text-[10px] md:text-[12px]  py-1 px-2 ">
                            RC Verified
                          </span>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <p className="leading-[18px] mt-2">{formData.email}</p>
                  </div>
                </div>

                <div
                  onClick={() => setShowEditProfile(true)}
                  className="flex items-center gap-1 text-[#044E97] cursor-pointer"
                >
                  <p className="text-[16px] font-semibold leading-[22px]">
                    Edit
                  </p>
                  <NewEditIcon />
                </div>
              </div>
            </div>
            <View formData={formData} />
          </div>
        </>
      )}
      <CreateEditProfile
        show={showEditProfile}
        onClose={() => setShowEditProfile(false)}
        onCreateEditProfile={handleUpdatedProfileData}
      />
    </>
  );
};
