import { IInventory } from "../@types";
import { Inventory, Variation } from "../@types/inventory";
export function cardProducts(products: any) {
  const updatedProduct = products.flatMap((product: Inventory) => {
    if (product.variantStatus === "has-variation" && product.variations) {
      return product?.variations.map((variant: Variation) => {
        return {
          id: product.id,
          variantId: variant.id,
          name: product.name,
          type: variant.type,
          quantity: variant.quantity,
          sellingPrice: variant.price,
        };
      });
    } else {
      return {
        id: product.id,
        name: product.name,
        quantity: Number(product.quantity),
        sellingPrice: product?.rate?.toString() || "",
      };
    }
  });
  const newProducts = updatedProduct.slice(0, 4);
  return newProducts;
}
