import React, { FormEvent, useState } from "react";
import { DropdownComponent, InputComponent } from "../../components/core/Forms";
import { useReportStore } from "../../state/useReportStore";
import { REPORT_LIST, CUSTOMER_LIST } from "../../constants";
import { Reports } from "../../constants/Report";
import CustomerReportFilterForm from "../../components/Report/customerReportFilterForm";
import ExpenseReportFilterForm from "../../components/Report/expenseReportFilterForm";
import InvoiceReportFilterForm from "../../components/Report/invoiceReportFilterForm/index";
import InventoryFilterForm from "./InventoryReportFilterForm";
import useDownloadReport from "../../hooks/useDownloadReport";
import { getInvoiceReport } from "../../backend-services";
import ProfitLossReportFilterForm from "./profitLossReportFlterForm";
import { ItemInput } from "../../@types";
import { Tooltip } from "../../assets/icons/Tooltip";
import { getYesterdayDate } from "../../utils/duration";
import useDownloadCsvReport from "../../hooks/UseDownloadCsvReport";
import { useTranslation } from "react-i18next";

const defaultItem: ItemInput = {
  id: "",
  name: "",
};

interface FormData {
  vendorId?: string;
  customerId?: string;
  startDate: string;
  endDate: string;
  status?: string;
  paymentMethod?: string;
}

const ReportForm = () => {
  const { t } = useTranslation("report");
  const [reportValue, setReportValue] = useState(t("report.selectType"));
  const [text, setText] = React.useState("");
  const [formData, setFormData] = useState<FormData>({
    vendorId: "",
    customerId: "",
    startDate: new Date().toISOString().substring(0, 10),
    endDate: "",
    status: "",
    paymentMethod: "",
  });

  const dropdownArray = REPORT_LIST;
  const [item, setItem] = useState<ItemInput>(defaultItem);
  const [serviceName, SetServiceName] = React.useState("");

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      return { ...prevFormData, [name]: value };
    });
  };

  const yesterdayDate = getYesterdayDate();
  const handleUpdate = (updatedReport: any) => {};

  const { downloadPDF, isLoadingPDF } = useDownloadReport({
    type:
      reportValue === Reports.INVOICE_REPORT
        ? Reports.INVOICE_REPORT
        : reportValue === Reports.EXPENSE_REPORT
          ? Reports.EXPENSE_REPORT
          : reportValue === Reports.CUSTOMER_REPORT
            ? Reports.CUSTOMER_REPORT
            : reportValue === Reports.INVENTORY_REPORT
              ? Reports.INVENTORY_REPORT
              : reportValue === Reports.PROFITLOSS_REPORT
                ? Reports.PROFITLOSS_REPORT
                : "",
    startDate: formData.startDate,
    endDate: formData.endDate,
    customerId: formData.customerId,
    status: formData.status,
    vendorId: formData.vendorId,

    paymentMethod: formData.paymentMethod,
    onUpdate: handleUpdate,
  });
  const { downloadCSV, isLoadingCSV } = useDownloadCsvReport({
    type:
      reportValue === Reports.INVOICE_REPORT
        ? Reports.INVOICE_REPORT
        : reportValue === Reports.EXPENSE_REPORT
          ? Reports.EXPENSE_REPORT
          : reportValue === Reports.CUSTOMER_REPORT
            ? Reports.CUSTOMER_REPORT
            : reportValue === Reports.INVENTORY_REPORT
              ? Reports.INVENTORY_REPORT
              : reportValue === Reports.PROFITLOSS_REPORT
                ? Reports.PROFITLOSS_REPORT
                : "",
    startDate: formData.startDate,
    endDate: formData.endDate,
    customerId: formData.customerId,
    status: formData.status,
    vendorId: formData.vendorId,

    paymentMethod: formData.paymentMethod,
    onUpdate: handleUpdate,
  });

  const handleSubmitPDF = async (e: any) => {
    e.preventDefault();
    await downloadPDF();
  };

  const handleSubmitCSV = async (e: any) => {
    e.preventDefault();
    await downloadCSV();
  };

  const handleInfo = (name: string, value: string) => {
    if (value === Reports.EXPENSE_REPORT) {
      SetServiceName(t("report.expense"));
      return;
    }
    if (value === Reports.EXPENSE_REPORT_CSV) {
      SetServiceName("Expense's Report Csv");
      return;
    }
    if (value === Reports.CUSTOMER_REPORT) {
      SetServiceName(t("report.customer"));
      return;
    }
    if (value === Reports.CUSTOMER_REPORT_CSV) {
      SetServiceName("Customer's Report Csv");
      return;
    }
    if (value === Reports.INVENTORY_REPORT) {
      SetServiceName(t("report.inventory"));
      return;
    }
    if (value === Reports.INVENTORY_REPORT_CSV) {
      SetServiceName("Inventory's Report");
      return;
    }
    if (value === Reports.INVOICE_REPORT) {
      SetServiceName(t("report.invoice"));
      return;
    }
    if (value === Reports.INVOICE_REPORT_CSV) {
      SetServiceName("Invoice-Report-csv");
      return;
    }
    if (value === Reports.PROFITLOSS_REPORT) {
      SetServiceName("Profit and Loss' Report");
      return;
    }
    if (value === Reports.PROFITLOSS_REPORT_CSV) {
      SetServiceName("Profit and Loss' Report");
      return;
    }
  };

  return (
    <div className="relative flex flex-col mx-auto max-w-[358px] md:max-w-full md:items-center md:justify-normal md:pl-[0px] lg:pl-[0px] lg:pt-[38px]">
      <form>
        <div className="w-[100%]">
          <label className="">{t("report.type")}</label>
          <div className="w-[100%] xsmd:w-[576px] xl:w-[434px]">
            <DropdownComponent
              options={dropdownArray}
              value={reportValue}
              selectedValue={reportValue}
              setSelectedValue={setReportValue}
              onChange={handleInfo}
              dropdownClassName="360:ml-[0.2em] lg:ml-0 w-[100%] xsmd:w-[576px] xl:w-[434px] cursor-pointer"
              iconClassName="mr-[2em] sm:mr-[7em] md:mr-[1.5em]"
            />
          </div>
        </div>
        {reportValue === Reports.EXPENSE_REPORT && (
          <>
            <ExpenseReportFilterForm
              formData={formData}
              setFormData={setFormData}
              handleOnChange={handleChange}
            />
          </>
        )}
        {reportValue === Reports.INVOICE_REPORT && (
          <>
            <InvoiceReportFilterForm
              formData={formData}
              setFormData={setFormData}
              handleOnChange={handleChange}
            />
          </>
        )}
        {reportValue === Reports.CUSTOMER_REPORT && (
          <>
            <CustomerReportFilterForm
              formData={formData}
              setFormData={setFormData}
              handleOnChange={handleChange}
            />
          </>
        )}
        {reportValue === Reports.INVENTORY_REPORT && (
          <>
            <InventoryFilterForm
              formData={formData}
              handleOnChange={handleChange}
              setFormData={setFormData}
              item={item}
            />
          </>
        )}
        {reportValue === Reports.PROFITLOSS_REPORT && (
          <>
            <ProfitLossReportFilterForm />
          </>
        )}
        <div className="flex 280:flex-col 360:flex-row md:flex-row xmd:gap-[24px] xsmd:gap-[24px] lg:gap-[16px] justify-between pt-[20px] xl:pt-[33px]">
          <div className="w-[171px] md:w-[276px] lg:w-[209px]">
            <label>{t("report.startDate")}</label>
            <InputComponent
              type="date"
              className="w-[171px] md:w-[276px] lg:w-[209px] rounded-[4px] border-1 h-[48px] placeholder:text-[#A4A4A4]"
              placeholder="Start Date"
              value={formData.startDate}
              name="startDate"
              handleChange={handleChange}
              restrictFutureDates={true}
            />
          </div>

          <div className="w-[171px] md:w-[276px] lg:w-[209px]">
            <label>{t("report.endDate")}</label>
            <InputComponent
              type="date"
              className="w-[171px] md:w-[276px] lg:w-[209px] rounded-[4px] border-1 h-[48px] placeholder:text-[#A4A4A4]"
              placeholder="End Date"
              value={formData.endDate}
              name="endDate"
              handleChange={handleChange}
              restrictFutureDates={true}
            />

            {serviceName && (
              <div className="flex items-start md:items-center font-medium text-[9px] md:text-[9.8px] mt-[2px]">
                <div>
                  <Tooltip color={"#044E97"} />
                </div>
                <p className="text-[#044E97] nowrap">{`${serviceName} available till ${yesterdayDate}`}</p>
              </div>
            )}
          </div>
        </div>
        <div className="lg:w-full mt-[61px] flex justify-center sm:w-full sm:flex sm:items-center sm:justify-center lg:h-[48px] lg:flex lg:mt-[69px] mb-[20px] lg:mb-[67px]">
          <div className="w-[200px] sm:w-[434px]">
            <button
              className="border border-[#044E97] bg-[#044E97] text-white h-[48px] px-[2em] w-full rounded-md"
              onClick={handleSubmitPDF}
              disabled={isLoadingPDF}
            >
              {isLoadingPDF ? "Downloading..." : "Generate Report as PDF"}
            </button>
          </div>
        </div>
        <div className="lg:w-full mt-[] flex justify-center sm:w-full sm:flex sm:items-center sm:justify-center lg:h-[48px] lg:flex lg:mt-[69px] mb-[20px] lg:mb-[67px]">
          <div className="w-[200px] sm:w-[434px]">
            <button
              className="border border-[#044E97] text-[#044E97] h-[48px] px-[2em] w-full rounded-md"
              onClick={handleSubmitCSV}
              disabled={isLoadingCSV}
            >
              {isLoadingCSV ? "Downloading..." : "Generate Report as CSV"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReportForm;
