import { useEffect, useState } from "react";
import { Annotation } from "../../@types/annotation";
import { formatAnnotation } from "../../utils/formatAnnotation";

const useAnnotations = (
  originalAnnotations: any[],
  serviceName: string,
): { [date: string]: Annotation[] } => {
  const [groupedAnnotations, setGroupedAnnotations] = useState<{
    [date: string]: Annotation[];
  }>({});

  useEffect(() => {
    // const formattedAnnotations = originalAnnotations.map(formatAnnotation);
    const formattedAnnotations = originalAnnotations.map((annotation) =>
      formatAnnotation(annotation, serviceName),
    );
    const grouped = formattedAnnotations.reduce(
      (acc: { [date: string]: Annotation[] }, annotation) => {
        const dateKey = annotation.date.toISOString().split("T")[0];
        if (!acc[dateKey]) {
          acc[dateKey] = [];
        }
        acc[dateKey].push(annotation);
        return acc;
      },
      {},
    );
    setGroupedAnnotations(grouped);
  }, [originalAnnotations]);

  return groupedAnnotations;
};

export default useAnnotations;
