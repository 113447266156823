import React from "react";
import toast from "react-hot-toast";
import { useSubUserStore } from "../../../state/useSubUserStore";
import showToast from "../../../utils/toastUtils";
import { Button } from "../../common";

interface DeleteUserProps {
  setShowPrompt: (show: boolean) => void;
  userId: string;
}

const DeleteUser = ({ userId, setShowPrompt }: DeleteUserProps) => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const { deleteUser } = useSubUserStore((state) => ({
    deleteUser: state.deleteUser,
  }));

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      const res = await deleteUser(userId);
      setShowPrompt(false);
      // showToast('User Deleted Successfully', 'success');
      toast.success("User Deleted Successfully");
      setIsDeleting(false);
    } catch (error: any) {
      // showToast(`${error.message}`, 'error');
      setErrorMessage(error);
    }
  };

  return (
    <div>
      <div className="mb-[1em]">
        <div className="flex justify-start mt-[13px] ">
          <h1 className="text-[#475467] text-[13px] md:text-[18px] lg:text-[18px]">
            You are about to delete a user. This action cannot be
            <span>
              reversed once <br className="hidden lg:block" />
              confirmed.
            </span>
          </h1>
        </div>
        <div
          role="form"
          className="flex lg:mt-[-1.2em] mt-[-1em] md:mt-[-1em] justify-end items-end "
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="mt-[32px]  lg:w-[215px] w-[130px] lg:text-[16px] text-[12px] md:text-[14px] flex justify-end items-end mr-[1em] lg:mr-[-3em] rounded-lg ">
            <Button
              text={"Cancel"}
              size="small"
              onClick={() => setShowPrompt(false)}
              variant="white"
            />
          </div>
          <div className="mt-[32px] lg:w-[215px] w-[130px] flex justify-end items-end rounded-lg lg:text-[16px] text-[12px] md:text-[14px]">
            <Button
              isLoading={isDeleting}
              text={"Delete"}
              size="small"
              onClick={handleDelete}
              variant="danger"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { DeleteUser };
