import React, { useEffect, useState } from "react";
import { ItemInput } from "../../../../@types";
import {
  CloseIcon,
  CloseIconSmall,
  RemoveIcon,
  SearchIcon,
} from "../../../../assets/icons";
import ExpandDown from "../../../../assets/icons/ExpandDown";
import ExpandUp from "../../../../assets/icons/ExpandUp";
import { useInventorySearch } from "../../../../hooks/inventory";
import { useServiceSearch } from "../../../../hooks/service/useServiceSearch";
import { calculateAdjustedRate, formatMoney } from "../../../../utils";
import { SearchInput } from "../../SearchInput";

interface Props {
  item: ItemInput;
  index: number;
  removeItem: (index: number) => void;
  updateCell: (
    rowIndex: number,
    columnId: string,
    value: string | number,
  ) => void;
  updateRow: (rowIndex: number, updatedRow: ItemInput) => void;
  errors: string[];
  updateError: (index: number, error: string[]) => void;
  isSearchInput: boolean;
  expanded: boolean;
  setExpanded: (index: number) => void;
  selectedCurrencyLabel: string;
  exchangeRate?: number;
  currency: string;
}

const ServiceLineItemMobile: React.FC<Props> = ({
  item,
  isSearchInput,
  index,
  removeItem,
  updateCell,
  updateRow,
  errors,
  updateError,
  expanded,
  setExpanded,
  selectedCurrencyLabel,
  exchangeRate,
  currency,
}) => {
  const handleUpdate = (field: keyof ItemInput, value: string | number) => {
    updateCell(index, field as string, value);
    if (errors && errors.includes(field as string)) {
      errors = errors.filter((err) => err !== field);
    }
  };
  const {
    data: services,
    isSearching: isServiceSearching,
    handleSearch: handleServiceSearch,
  } = useServiceSearch();
  const [showNewInventoryModal, setShowNewInventoryModal] =
    React.useState<boolean>(false);
  const handleServiceSearchDebounced = React.useCallback(
    handleServiceSearch,
    [],
  );
  const [isRowExpanded, setIsRowExpanded] = useState(true);

  const handleItemSelect = React.useCallback(
    (itemData: Omit<ItemInput, "variation"> | null) => {
      if (itemData) {
        let updatedDuration: string = "";

        // Check if the duration is a string
        if (
          typeof itemData.duration === "string" ||
          typeof itemData.duration === "number"
        ) {
          updatedDuration = String(itemData.duration);
        }

        let updatedPrice: number = 0;

        if (Array.isArray(itemData.price)) {
          const nairaPriceObject = itemData.price.find(
            //@ts-ignore
            (entry) => entry.currency === "NGN",
          );
          if (nairaPriceObject) {
            //@ts-ignore
            updatedPrice = nairaPriceObject.amount;
          }
        } else if (typeof itemData.price === "number") {
          updatedPrice = itemData.price;
        }

        const updatedId: string | undefined =
          typeof itemData.id === "number" || typeof itemData.id === "string"
            ? String(itemData.id)
            : undefined;

        const updatedName: string | undefined =
          typeof itemData.name === "string" ? itemData.name : undefined;

        const adjustedPrice = calculateAdjustedRate(updatedPrice, exchangeRate);

        updateRow(index, {
          ...itemData,
          duration: updatedDuration,
          price: adjustedPrice,
          id: updatedId || "",
          name: updatedName || "",
        });
      } else {
        updateRow(index, { id: "", name: "", duration: "", price: 0 });
      }
    },
    [index, updateRow, exchangeRate],
  );

  const formatDurationForDisplay = (duration: string | undefined) => {
    return duration?.replace(/(\d+d)(\d+h)(\d+m)/, "$1 $2 $3");
  };

  const handleChange = (event: { target: { value: any } }) => {
    const { value } = event.target;
    handleUpdate("serviceCharge", value); // Update the serviceCharge value in the state
  };

  const [expandedTableIndex, setExpandedTableIndex] = useState<number | null>(
    0,
  );

  const handleToggleExpansion = () => {
    setExpanded(index);
  };

  return (
    <div className="p-2 relative items-center">
      <button
        type="button"
        className="ml-[20em] p-2 rounded mt-[-1em] "
        onClick={() => handleToggleExpansion()}
      >
        {expandedTableIndex === index ? <ExpandUp /> : <ExpandDown />}
        <span className="ml-2">{isRowExpanded ? "" : ""}</span>
      </button>

      {expanded && (
        <div className="p-2  mt-[-4em] ml-[0.3em] items-center ">
          <label className="text-gray-700 text-[12px] mt-[2em] pt-[4em] pl-4 ml-[-0.5em]">
            Item Name
          </label>
          {isSearchInput ? (
            <SearchInput
              placeholder="Service Name"
              data={services}
              displayKey="name"
              isLoading={isServiceSearching}
              classNames=" shadow-inner placeholder:text-[#B4B4B4] items-center ml-[0.4em]  placeholder:text-[13.25px] w-[330px]  bg-gray-100 placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4] "
              value={item?.id}
              selected={item.id ? item : null}
              clearIcon={CloseIconSmall}
              clearIconClass="mr-[0.9em]  "
              handleChangeText={handleServiceSearchDebounced}
              onSelect={handleItemSelect}
              dropdownClassName="w-[300px] ml-[0.9em] "
              dropdownStyle={{
                maxHeight: "800px",
                overflowY: "auto",
              }}
              emptyMessage={"No Items yet"}
              searchKey={"name"}
              icon={<SearchIcon />}
            />
          ) : (
            <input
              type="text"
              placeholder="Enter Item Name"
              className="bg-gray-100 placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4]  p-2 ml-[0.5em] placeholder:text-[13.25px] w-[310px]"
              value={item?.name}
              onChange={({ target }) => handleUpdate("name", target.value)}
            />
          )}
          {errors.includes("name") && (
            <p className="text-red-500 text-xs mt-1">Please enter a value</p>
          )}
          <div className="grid grid-cols-2 gap-6">
            <div className="flex flex-col ml-[1em]">
              <label className="text-gray-700 text-[12px] mt-[2em] ml-[-0.5em] ">
                Duration(Hours)
              </label>
              <input
                type="text"
                className={` p-2 ml-[-0.4em]  w-[157px]  bg-gray-100 placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4]  ${errors.includes("duration") ? "border-red-500" : ""}`}
                placeholder="Duration"
                value={item?.duration || ""}
                onChange={({ target }) =>
                  handleUpdate("duration", target.value)
                }
              />
              {errors.includes("duration") && (
                <p className="text-red-500 text-xs mt-1">
                  Please enter a value
                </p>
              )}
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 text-[12px] mt-[2em]">
                Service Charge
              </label>
              <input
                type="text"
                className={`p-2 w-[147px] bg-gray-100 placeholder:text-[#B4B4B4] shadow-md rounded-md border border-[#A4A4A4] ${
                  errors.includes("serviceCharge") ? "border-red-500" : ""
                }`}
                placeholder="Service Charge"
                value={item?.price as string | number}
                onChange={handleChange}
              />
              {errors.includes("serviceCharge") && (
                <p className="text-red-500 text-xs mt-1">
                  Please enter a value
                </p>
              )}
            </div>
          </div>
          <div className="ml-[20em] text-white p-2 rounded mt-[2em]">
            <p className="w-[50px] text-[black] font-semi-bold text-[px]">
              {selectedCurrencyLabel === "Naira"
                ? "NGN"
                : selectedCurrencyLabel}
            </p>
          </div>
        </div>
      )}
      {!expanded && (
        <div className="grid grid-cols-2   p-3 mt-[-3.5em] ml-[-0.3em]">
          <div className="">
            <input
              type="text"
              readOnly
              placeholder="Item Name"
              value={item?.name}
              className="border-none  p-2 placeholder:text-[13.25px] w-[75px]"
            />
          </div>
          <div className="text-right">
            <input
              type="text"
              readOnly
              placeholder="Amount"
              className="border-none p-2 placeholder:text-[13.25px] w-[75px]"
              value={
                !isNaN(Number(item?.quantity)) && !isNaN(Number(item?.rate))
                  ? (Number(item?.quantity) * Number(item?.rate)).toFixed(2)
                  : ""
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { ServiceLineItemMobile };
