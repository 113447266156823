import React, { FC, useState } from "react";
import {
  BankacctIcon,
  CustomerIcon,
  InvoiceSmallIcon,
  KeyIcon,
  SettingsProfile,
  SubscriptionBlueCheck,
  TitleIcon,
} from "../../assets/icons";
import { UserProfile } from "../../assets/icons/UserProfile";
import GlobeIcon from "../../assets/icons/GlobeIcon";

interface TabComponentProps {
  isActive: boolean;
  tabName: string;
}

export const TabComponent: FC<TabComponentProps> = ({ isActive, tabName }) => {
  const tabIcons = {
    profile: {
      active: <SettingsProfile />,
      inactive: <CustomerIcon />,
    },
    password: {
      active: <KeyIcon color="#044E97" />,
      inactive: <KeyIcon color="#33363F" />,
    },
    bankAccount: {
      active: <BankacctIcon color="#044E97" />,
      inactive: <BankacctIcon color="#33363F" />,
    },
    expense: {
      active: <TitleIcon color="#044E97" />,
      inactive: <TitleIcon color="#33363F" />,
    },
    invoice: {
      active: <InvoiceSmallIcon color="#044E97" />,
      inactive: <InvoiceSmallIcon color="#33363F" />,
    },
    subscription: {
      active: <SubscriptionBlueCheck color="#044E97" />,
      inactive: <SubscriptionBlueCheck color="#33363F" />,
    },
    language: {
      active: <GlobeIcon color="#044E97" />,
      inactive: <GlobeIcon color="#33363F" />,
    },
  };

  //@ts-ignore
  const { active, inactive } = tabIcons[tabName] || {};

  return isActive ? active : inactive;
};
