import { AuthLayout, LoginView, RegisterView } from "../../components/auth";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import LogoWithBetaBadge from "../../assets/icons/LogoWithBetaBadge";

interface Props {}

export const AuthPage: React.FC<Props> = () => {
  const navigate = useNavigate();

  const isRegisterRoute = location.pathname.endsWith("/register");

  const handleTabChange = (isRegister: boolean) => {
    console.log("Navigating to:", isRegister ? "/register" : "/login");
    navigate(isRegister ? "/register" : "/login");
  };
  const backgroundClass = isRegisterRoute ? "bgSignUp" : "bgLogin";

  useEffect(() => {
    console.log("Current path:", location.pathname);
  }, [location.pathname]);

  return (
    <>
      <AuthLayout backgroundClass={backgroundClass}>
        <div className="lg:hidden flex justify-center bigmd:ml-[24em]">
          <div className="mt-[2.5em]">
            <LogoWithBetaBadge />
          </div>
        </div>

        {isRegisterRoute ? (
          <RegisterView handleTabChange={handleTabChange} />
        ) : (
          <LoginView handleTabChange={handleTabChange} />
        )}

        <Outlet />
      </AuthLayout>
    </>
  );
};
