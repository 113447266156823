import React, { useState, Dispatch, SetStateAction } from "react";
import {
  DropdownComponent,
  InputComponent,
} from "../../../components/core/Forms";
import { useReportStore } from "../../../state/useReportStore";
import { Button, SearchInput } from "../../../components/common";
import { CloseIconSmall, SearchIcon } from "../../../assets/icons";
import useDownloadReport from "../../../hooks/useDownloadReport";
import { ICustomer, IInventory } from "../../../@types";
import { REPORT_LIST, CUSTOMER_LIST } from "../../../constants";
import { STATUS_VALUES } from "../../../constants/Report";
import { useCustomerSearch } from "../../../hooks/customer/useCustomerSearch";
import { useInventorySearch } from "../../../hooks/inventory";
import { useTranslation } from "react-i18next";

interface InvoiceFilterProps {
  handleOnChange: (e: any) => void;
  formData: any;
  setFormData: Dispatch<
    SetStateAction<{
      vendorId?: string;
      customerId?: string;
      inventoryId?: string;
      startDate: string;
      endDate: string;
      status?: string;
      paymentMethod?: string;
    }>
  >;
}

const InvoiceReportFilterForm = ({
  handleOnChange,
  formData,
  setFormData,
}: InvoiceFilterProps) => {
  const { t } = useTranslation("report");
  const [text, setText] = React.useState("");
  const generateReport = useReportStore((state) => state.generateReport);
  const [statusValue, setStatusValue] = useState("Select Status");
  const [typeValue, setTypeValue] = useState("Select Type");
  const [customerState, setCustomerState] = useState<ICustomer | null>(null);
  const [inventoryState, setInventoryState] = useState<IInventory | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string>("Select Status");
  const {
    data: customers,
    isSearching,
    handleSearch: handleCustomerSearch,
  } = useCustomerSearch();
  const {
    data: inventory,
    isSearching: inventorySearch,
    handleSearch: handleInventorySearch,
  } = useInventorySearch();

  const handleCustomerSelect = (selectedCustomer: ICustomer | null) => {
    if (selectedCustomer) {
      setCustomerState(selectedCustomer);
      if (selectedCustomer.id) {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          customerId: selectedCustomer.id,
        }));
      }
    } else {
      setCustomerState(null);
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        customerId: "",
      }));
    }
  };

  const dropDownOptions = STATUS_VALUES;
  const handleInventorySelect = (selectedInventory: IInventory | null) => {
    if (selectedInventory) {
      setInventoryState(selectedInventory);
      if (selectedInventory.id) {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          inventoryId: selectedInventory.id,
        }));
      }
    }
    if (selectedInventory == null) {
      setInventoryState(null);
    }
  };

  const handleDropDownChange = (name: string, value: string) => {
    const selectedStatus = dropDownOptions.find(
      (option) => option.value === value,
    );
    setSelectedStatus(selectedStatus?.label as string);
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div>
      <div className="flex flex-col relative">
        <div className="flex flex-col md:flex-row justify-between gap-[16px] lg:w-[434px] md:gap-[16px]  pt-[20px] xl:pt-[33px]">
          <div className="md:w-[276px] lg:w-[209px]">
            <label>{t("report.searchCustomer")}</label>
            <SearchInput
              placeholder="Search by Customer"
              displayKey="name"
              isLoading={isSearching}
              value={text !== null ? text : ""}
              clearIcon={CloseIconSmall}
              handleChangeText={handleCustomerSearch}
              selected={customerState}
              onSelect={handleCustomerSelect}
              data={customers.map((customer: any) => ({
                ...customer,
                display: `${customer.name} (${customer.id})`,
              }))}
              emptyMessage={"This Item is not found"}
              clearIconClass="xl:mr-[1em] mr-[0.47em] md:mr-[3.2em] biglg:mr-[0.1em] biglg:mb-[4em]"
              dropdownClassName="w-[100%] lg:w-[209px] lg:ml-[7em] xl:w-[209px] xl:ml-[-0em] biglg:ml-[-0.1em]"
              dropdownStyle={{
                maxHeight: "800px",
                overflowY: "auto",
              }}
              searchKey={"name"}
              icon={<SearchIcon />}
              classNames="w-[100%] md:w-[276px] lg:w-[209px] border border-gray-300 bg-white max-h-[48px] min-h-[48px] placeholder:text-[15.25px]  rounded p-2  focus:outline-none focus:shadow-outline appearance-none focus:ring-0 leading-normal"
            />
          </div>

          <div className="md:w-[276px] lg:w-[209px]">
            <label>{t("report.searchInventoryID")}</label>
            <SearchInput
              placeholder={"Search by Inventory"}
              displayKey={"name"}
              isLoading={inventorySearch}
              value={text}
              clearIcon={CloseIconSmall}
              handleChangeText={handleInventorySearch}
              selected={inventoryState}
              onSelect={handleInventorySelect}
              data={inventory.map((inv: any) => ({
                ...inv,
                display: `${inv.id}`,
              }))}
              emptyMessage={"This Item is not found"}
              clearIconClass="xl:mr-[1em] mr-[0.47em] md:mr-[3.2em] biglg:mr-[0.1em] biglg:mb-[4em]"
              dropdownClassName="w-[100%] lg:w-[209px] lg:ml-[7em] xl:w-[209px] xl:ml-[0em] biglg:ml-[-0.1em]"
              dropdownStyle={{
                maxHeight: "800px",
                overflowY: "auto",
              }}
              searchKey={"name"}
              icon={<SearchIcon />}
              classNames="w-[100%] md:w-[276px] lg:w-[209px] border border-gray-300 bg-white max-h-[48px] min-h-[48px] placeholder:text-[15.25px]  rounded p-2  focus:outline-none focus:shadow-outline appearance-none focus:ring-0 leading-normal"
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-[16px] lg:w-[434px] md:gap-[16px] pt-[20px] xl:pt-[33px]">
          <div>
            <label>{t("report.generateByStatus")}</label>
            <DropdownComponent
              name="status"
              options={dropDownOptions}
              value={formData.status}
              selectedValue={selectedStatus}
              setSelectedValue={setStatusValue}
              onChange={(name, value) => handleDropDownChange(name, value)}
              dropdownClassName="w-[100%] md:w-[276px] lg:w-[209px] cursor-pointer"
              iconClassName="mr-[2em] sm:mr-[7em] md:mr-[1.5em]"
            />
          </div>

          <div>
            <label>{t("report.generateByType")}</label>
            <DropdownComponent
              options={[]}
              value={typeValue}
              selectedValue={typeValue}
              setSelectedValue={setTypeValue}
              dropdownClassName="w-[100%] md:w-[276px] lg:w-[209px] cursor-pointer"
              iconClassName="mr-[2em] sm:mr-[7em] md:mr-[1.5em]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceReportFilterForm;
