import React from "react";
import { EmptyTransIcon } from "../../../assets/icons/ResentTransactionEmptyIcon";

const EmptyTransaction = ({ text = "" }) => {
  return (
    <div className="flex item-center justify-center">
      <div className="w-[120px] justify-center my-[47px] mb-[] xl:w-[200px] xl:ml-[1em] flex flex-col ">
        <EmptyTransIcon />
        <div className="flex items-center justify-center ">
          <p className="text-[14px] font-medium whitespace-nowrap text-black mt-[1em] lg:mt-[0em]">
            {text}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmptyTransaction;
