import { toast } from "react-hot-toast";
import {
  AttachExpenseReceiptProps,
  PaymentType,
  TransactionEvidenceEntity,
} from "../@types";
import { paymentService } from "../services";
import { PaymentCredential } from "../types";
import { handleError, withRetry } from "../utils";

export const getPaymentHistory = async (entity: string) => {
  return withRetry(async () => {
    const res = await paymentService.get(`/payment/entity/${entity}`);
    return res.data;
  }).catch(handleError);
};

export const createPaymentCredential = async (data: PaymentCredential) => {
  return withRetry(async () => {
    const res = await paymentService.post(`/provider/payment`, data);
    return res.data;
  }).catch(handleError);
};

export const getPaymentCredential = async () => {
  return withRetry(async () => {
    const res = await paymentService.get(`/provider/payment`);
    return res.data;
  }).catch(handleError);
};

export const updatePaymentCredential = async (data: PaymentCredential) => {
  return withRetry(async () => {
    const res = await paymentService.patch(`/provider/payment/update`, data);
    return res.data;
  }).catch(handleError);
};

export const recordManualPayments = async (input: {
  amount: number;
  paymentType: PaymentType;
  paymentDate: string | Date | number;
  entityId: string;
  customerId: string;
  entityType: string;
  companyId: string;
  outletId: string;
}) => {
  const data = {
    ...input,
  };
  return withRetry(async () => {
    const res = await paymentService.post(`/payment`, data);
    return res.data;
  }).catch(handleError);
};

export const generateUploadURL = async (input: TransactionEvidenceEntity) => {
  const data: TransactionEvidenceEntity = {
    ...input,
  };
  return withRetry(async () => {
    const res = await paymentService.post(`/payment/evidence`, data);
    return res.data;
  }).catch(handleError);
};

export const generatePaymentLink = async (data: PaymentCredential) => {
  return withRetry(async () => {
    const res = await paymentService.post(`/provider/payment/link`, data);
    return res.data;
  }).catch(handleError);
};

export const convertExpense = async (id: string) => {
  return withRetry(async () => {
    const res = await paymentService.patch(`/expenses/${id}/convert`);
    return res.data;
  }).catch(handleError);
};

export const ReceiptUpload = async (data: { image: string }) => {
  return withRetry(async () => {
    const res = await paymentService.post(`/expenses/upload`, data);
    return res.data;
  }).catch(handleError);
};

export const AttachExpenseReceipt = async ({
  id,
  image,
}: AttachExpenseReceiptProps) => {
  return withRetry(async () => {
    const res = await paymentService.patch(
      `/expenses/${id}/attach-receipt`,
      JSON.stringify({ image }),
    );
    return res.data;
  }).catch(handleError);
};
export const copyPaymentLink = async (data: {
  invoiceId: string;
  paymentProvider: string;
  amount: number;
  subaccountId: string;
}) => {
  return withRetry(async () => {
    const res = await paymentService.post(`/provider/payment/link`, data);
    return res.data;
  }).catch(handleError);
};
