import React from "react";

const NigeriaIcon = () => {
  return (
    <div>
      <svg
        width="18"
        height="12"
        viewBox="0 0 18 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_178_815)">
          <path
            d="M0.388672 0.722168H17.6109V11.2777H0.388672V0.722168Z"
            fill="white"
          />
          <path
            d="M0.388672 0.722168H6.222V11.2777H0.388672V0.722168ZM11.7776 0.722168H17.6109V11.2777H11.7776V0.722168Z"
            fill="#186648"
          />
          <path
            d="M0.388672 0.722168H17.6109V11.2777H0.388672V0.722168Z"
            stroke="black"
            stroke-width="0.555556"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_178_815">
            <rect width="18" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default NigeriaIcon;
