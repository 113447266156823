import React from "react";

interface DeleteIconProps {
  size?: string;
}

const DeleteIcon: React.FC<DeleteIconProps> = ({ size = "24" }) => {
  return (
    <div>
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 15.5L10 12.5"
          stroke="#FB0303"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M14 15.5L14 12.5"
          stroke="#FB0303"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M3 7.5H21V7.5C20.0681 7.5 19.6022 7.5 19.2346 7.65224C18.7446 7.85523 18.3552 8.24458 18.1522 8.73463C18 9.10218 18 9.56812 18 10.5V16.5C18 18.3856 18 19.3284 17.4142 19.9142C16.8284 20.5 15.8856 20.5 14 20.5H10C8.11438 20.5 7.17157 20.5 6.58579 19.9142C6 19.3284 6 18.3856 6 16.5V10.5C6 9.56812 6 9.10218 5.84776 8.73463C5.64477 8.24458 5.25542 7.85523 4.76537 7.65224C4.39782 7.5 3.93188 7.5 3 7.5V7.5Z"
          stroke="#FB0303"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M10.0681 3.87059C10.1821 3.76427 10.4332 3.67033 10.7825 3.60332C11.1318 3.53632 11.5597 3.5 12 3.5C12.4403 3.5 12.8682 3.53632 13.2175 3.60332C13.5668 3.67033 13.8179 3.76427 13.9319 3.87059"
          stroke="#FB0303"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default DeleteIcon;
