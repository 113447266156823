import React, { useState, useEffect } from "react";
import {
  ICreateExpenseData,
  ICreateSalesData,
  ICustomer,
  IVendor,
} from "../../../@types";
import { CloseIcon, CloseIconSmall, SearchIcon } from "../../../assets/icons";
import { useCustomerSearch, useVendorSearch } from "../../../hooks";
import { useCustomerStore } from "../../../state";
import { SearchInput } from "../../common";
import shallow from "zustand/shallow";
import CreateVendorModal from "./createVendor";

interface CustomerInfoProps {
  handleSelectedVendor: (selectedVendor: IVendor | null) => void;
  formData: ICreateExpenseData;
  vendor: IVendor | undefined;
  onClose: () => void;
  vendorName: IVendor | undefined;
}

const SelectVendor: React.FC<CustomerInfoProps> = ({
  handleSelectedVendor,
  formData,
  vendor,
  onClose,
  vendorName,
}) => {
  const {
    data: vendors,
    isSearching: isVendorSearching,
    handleSearch: handleVendorSearch,
    setSearchedDone,
    searchedDone,
  } = useVendorSearch();
  const [showCreateVendor, setShowCreateVendor] = useState(false);

  const handleAddNewVendor = () => {
    setShowCreateVendor(true);
  };

  return (
    <>
      {/* Desktop */}
      <div className=" hidden lg:block md:block w-[380px] h-[440px] relative">
        <button
          className="absolute top-2 right-2 shadow-sm py-[0.2em] bg-[#F4F7FF] pr-4"
          aria-label="Close modal"
          onClick={onClose}
        >
          <CloseIcon color="black" />
        </button>
        <h1 className="font-normal lg:text-[25px] text-[18px]">
          Select A Vendor
        </h1>
        <div className=" mt-6 md:mt-[3em] ">
          <SearchInput
            placeholder="Search for Vendor"
            data={vendors}
            displayKey="name"
            isLoading={isVendorSearching}
            setSearchedDone={setSearchedDone}
            searchedDone={searchedDone}
            value={formData.vendorName}
            selected={vendorName}
            onSelect={handleSelectedVendor}
            handleChangeText={handleVendorSearch}
            emptyMessage={"This vendor is not found"}
            clearIcon={CloseIcon}
            clearIconClass="xl:mr-[1em] mr-[0.5em] md:mr-[3.2em] biglg:mr-[2em] lg:mr-[1em]"
            classNames="w-full border border-gray-300 bg-white max-h-[48px] min-h-[48px] placeholder:text-[15.25px] rounded  focus:outline-none focus:shadow-outline appearance-none focus:ring-0 leading-normal"
            dropdownClassName="w-full "
            dropdownStyle={{
              maxHeight: "800px",
              overflowY: "auto",
            }}
            searchKey={"name"}
            icon={<SearchIcon />}
          />
          <div className="flex justify-end mt-[15em] gap-5">
            <button
              className="text-[#044E97] border border-[#044E97] w-[150px] flex justify-center items-center text-center p-3 rounded-md font-semibold lg:mt-0 xl:mt-0 biglg:mt-0 mt-[1em] md:mt-[-0.3em]"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="text-[white] border bg-[#044E97] flex justify-center items-center text-center p-3 rounded-md font-semibold lg:mt-0 xl:mt-0 biglg:mt-0 mt-[1em] md:mt-[-0.3em]"
              onClick={handleAddNewVendor}
            >
              Add New Vendor
            </button>
          </div>
        </div>
        {showCreateVendor && (
          <CreateVendorModal
            showSideModal={showCreateVendor}
            setShowSideModal={setShowCreateVendor}
            handleSelectedVendor={handleSelectedVendor}
            onVendorCreate={(vendor) => {
              handleSelectedVendor(vendor);
              setShowCreateVendor(false);
            }}
            onClose={() => setShowCreateVendor(false)}
          />
        )}
      </div>

      {/* Mobile */}
      <div className="relative">
        <div className="lg:hidden md:hidden fixed bg-white w-[full] h-[491px] bottom-0 right-0 left-0 rounded-t-[4em]">
          <button
            className="absolute top-2 right-2 mt-[2em] shadow-sm py-[0.2em] bg-[#F4F7FF] pr-4"
            aria-label="Close modal"
            onClick={onClose}
          >
            <CloseIcon color="red" />
          </button>
          <h1 className="font-normal lg:text-[25px] text-[18px] mt-[2em] pl-[2em]">
            Select A Vendor
          </h1>
          <div className=" mt-6 flex flex-col justify-center items-center text-center">
            <SearchInput
              placeholder="Search for Vendor"
              data={vendors}
              displayKey="name"
              isLoading={isVendorSearching}
              value={formData.vendorName}
              selected={vendorName}
              onSelect={handleSelectedVendor}
              handleChangeText={handleVendorSearch}
              emptyMessage={"This vendor is not found"}
              clearIcon={CloseIcon}
              clearIconClass="xl:mr-[1em] mr-[0.5em] md:mr-[3.2em] biglg:mr-[2em] lg:mr-[1em]"
              classNames="w-[300px] border border-gray-300 bg-white max-h-[48px] min-h-[48px] placeholder:text-[15.25px] rounded  focus:outline-none focus:shadow-outline appearance-none focus:ring-0 leading-normal"
              dropdownClassName="w-[300px]"
              dropdownStyle={{
                maxHeight: "800px",
                overflowY: "auto",
              }}
              searchKey={"name"}
              icon={<SearchIcon />}
            />
            <div className="flex mt-[15em] gap-2">
              <button
                className="text-[#044E97] border border-[#044E97] w-[150px]  flex justify-center items-center text-center p-3 rounded-md font-semibold lg:mt-0 xl:mt-0 biglg:mt-0 mt-[1em] text-[12px]"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="text-[white] border bg-[#044E97] w-[150px]  flex justify-center items-center text-center p-3 rounded-md font-semibold lg:mt-0 xl:mt-0 biglg:mt-0 mt-[1em]  text-[12px]"
                onClick={handleAddNewVendor}
              >
                Add New Vendor
              </button>
            </div>
          </div>
          <CreateVendorModal
            showSideModal={showCreateVendor}
            setShowSideModal={setShowCreateVendor}
            handleSelectedVendor={handleSelectedVendor}
          />
        </div>
      </div>
    </>
  );
};

export default SelectVendor;
