import React from "react";

const ForgetPasswordIcon = ({ color }: any) => {
  return (
    <>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="64" height="64" rx="12" fill="#E0EEFE" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.3333 20.3334C26.756 20.3334 24.6667 22.4228 24.6667 25.0001V28.3334C24.6667 29.0698 24.0697 29.6667 23.3333 29.6667C22.597 29.6667 22 29.0698 22 28.3334V25.0001C22 20.95 25.2832 17.6667 29.3333 17.6667C33.3834 17.6667 36.6667 20.95 36.6667 25.0001V28.3334C36.6667 29.0698 36.0697 29.6667 35.3333 29.6667C34.597 29.6667 34 29.0698 34 28.3334V25.0001C34 22.4228 31.9107 20.3334 29.3333 20.3334Z"
          fill="#044E97"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.1974 38.0237C40.9205 37.7528 40.782 37.6173 40.7846 37.4455C40.7872 37.2737 40.9267 37.1452 41.2058 36.8883C41.2801 36.8199 41.3516 36.755 41.4192 36.6946C41.6226 36.5127 41.7243 36.4218 41.8768 36.4217C42.0294 36.4216 42.1307 36.5119 42.3335 36.6926C42.5365 36.8736 42.7781 37.0995 43.0478 37.3632L44.0952 38.3877C44.6142 38.8952 45.4479 38.8877 45.9574 38.3707C46.4669 37.8538 46.4593 37.0233 45.9404 36.5158L44.893 35.4913C44.445 35.0531 43.9674 34.5858 43.5722 34.2909C43.1302 33.961 42.5786 33.6667 41.8755 33.6667C41.1724 33.6667 40.6208 33.961 40.1788 34.2909C39.7836 34.5858 39.3588 35.0014 38.9108 35.4397L36.3143 37.9791C36.1255 38.1638 36.031 38.2562 35.9199 38.281C35.8088 38.3058 35.6652 38.2558 35.3779 38.1558C33.8752 37.6329 32.1349 37.9606 30.9299 39.1392C29.2473 40.7849 29.2473 43.4632 30.9299 45.109C32.5991 46.7416 35.2957 46.7416 36.9649 45.109C38.1646 43.9355 38.5089 42.2371 37.9978 40.7624C37.8988 40.4769 37.8493 40.3341 37.8726 40.2251C37.8739 40.2188 37.8749 40.2147 37.8765 40.2085C37.9044 40.1005 37.9977 40.0093 38.1843 39.8268C38.4382 39.5784 38.5652 39.4542 38.7215 39.4497C38.7292 39.4495 38.737 39.4495 38.7448 39.4497C38.9011 39.4542 39.028 39.5784 39.282 39.8268L39.9055 40.4366C40.4244 40.9442 41.2581 40.9366 41.7677 40.4197C42.2772 39.9028 42.2696 39.0723 41.7506 38.5647L41.1974 38.0237ZM32.7751 41.0111C33.4198 40.3805 34.475 40.3805 35.1197 41.0111C35.751 41.6285 35.751 42.6196 35.1197 43.2371C34.475 43.8677 33.4198 43.8677 32.7751 43.2371C32.1438 42.6196 32.1438 41.6285 32.7751 41.0111Z"
          fill="#044E97"
        />
        <path
          d="M29.3346 27C27.2069 27 25.2728 27.0456 23.3683 27.1332C20.6242 27.2593 18.3961 29.3842 18.034 32.0736C17.8365 33.5407 17.668 35.0825 17.668 36.6667C17.668 38.2509 17.8365 39.7926 18.034 41.2598C18.3961 43.9491 20.6242 46.074 23.3683 46.2002C24.8516 46.2683 26.3527 46.3111 27.9494 46.3267C28.5176 46.3322 28.8017 46.335 28.8894 46.1839C28.9771 46.0328 28.8203 45.7644 28.5067 45.2277C27.1141 42.8439 27.4557 39.7396 29.5315 37.7093C31.0483 36.2257 33.1317 35.6674 35.072 36.0149C35.2382 36.0446 35.3213 36.0595 35.3908 36.0381C35.4603 36.0167 35.5168 35.9615 35.6296 35.8511L37.562 33.9612C37.9713 33.5606 38.4784 33.0642 38.9826 32.6879C39.2772 32.4681 39.65 32.2269 40.0997 32.0342C40.3528 31.9257 40.4794 31.8715 40.5282 31.7704C40.577 31.6693 40.5463 31.5568 40.4849 31.3316C39.8499 29.0025 37.7872 27.2475 35.301 27.1332C33.3964 27.0456 31.4624 27 29.3346 27Z"
          fill="#044E97"
        />
        <path
          d="M40.2154 42.77C40.2184 42.7632 40.2142 42.7555 40.207 42.7541C40.2003 42.7528 40.194 42.7575 40.1933 42.7643C40.1919 42.7774 40.2102 42.7822 40.2154 42.77Z"
          fill="#044E97"
        />
      </svg>
    </>
  );
};

export default ForgetPasswordIcon;
