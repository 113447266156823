import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProcessSuccessful from "../../../assets/icons/ProcessSuccessful";
import SendReceiptModal from "../../Sales/invoice/SendReceiptModal";
import { ICustomer } from "../../../@types";
import { CreateModal } from "../../common/CreateModal";
import { CloseIcon } from "../../../assets/icons";
import { useTranslation } from "react-i18next";

interface RecordPaymentSuccessProps {
  invoiceId?: string;
  handleDownload: (id: string) => void;
  triggerPrint: () => void;
  setSuccess: (success: boolean) => void;
  setShowSendModal: (success: boolean) => void;
}

const RecordPaymentSuccess = ({
  invoiceId,
  handleDownload,
  setSuccess,
  setShowSendModal,
  triggerPrint,
}: RecordPaymentSuccessProps) => {
  const handleSendReceipt = () => {
    setShowSendModal(true);
    setSuccess(false);
  };
  const { t } = useTranslation("invoices");
  const handleDownloadReceipt = () => {
    setSuccess(false);
    if (invoiceId) {
      handleDownload(invoiceId);
    }
    setSuccess(false);
  };

  return (
    <>
      <CreateModal>
        <div className="w-full md:w-[448px] md:px-4 md:py-2">
          <div className="flex justify-end">
            <div onClick={() => setSuccess(false)} className="cursor-pointer">
              <CloseIcon color="red" />
            </div>
          </div>
          <h1 className="text-[25px] font-bold text-center my-4">
            {t("paymentRecorded")}
          </h1>
          <div className="flex justify-center mt-[12px] h-[116px]">
            <ProcessSuccessful width="120" height="116" />
          </div>
          <div className="flex flex-col items-center justify-center gap-4 text-[16px] font-medium mt-5 text-[#044E97]">
            <button
              type="button"
              onClick={handleSendReceipt}
              className="w-[280px] h-[48px] rounded-md text-[#FFFFFF] bg-[#044E97]"
            >
              Send Receipt
            </button>
            <button
              type="button"
              onClick={triggerPrint}
              className="w-[280px] h-[48px] rounded-md bg-[#FFFFFF] border border-[#044E97]"
            >
              Print Receipt
            </button>
            <button
              type="button"
              onClick={handleDownloadReceipt}
              className="w-[280px] h-[48px] rounded-md bg-[#FFFFFF] "
            >
              Download Receipt
            </button>
          </div>
        </div>
      </CreateModal>
    </>
  );
};

export default RecordPaymentSuccess;
