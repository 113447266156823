import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ISetReminder } from "../../../../@types";
import { CloseIcon } from "../../../../assets/icons";
import ReminderOptions from "../../../settings/invoice/ReminderOptions";
import ReminderInvoiceOptions from "./ReminderOption";

interface AutomateReminderModalProps {
  setShowSendModal: (showReminder: boolean) => void;
  onSaveReminder: (data: ISetReminder) => void;
  reminderType: "before" | "due" | "after";
  days: number;
  title: string;
}

const InvoiceReminderModal = ({
  setShowSendModal,
  onSaveReminder,
  reminderType,
  days,
  title,
}: AutomateReminderModalProps) => {
  const [isNotified, setIsNotified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [daysInput, setDaysInput] = useState<string>(days.toString());

  const [reminderData, setReminderData] = useState<ISetReminder[]>([
    { days: 0, remindBy: "before", remindMe: isNotified, isActive: false },
    { days: 0, remindBy: "due", remindMe: isNotified, isActive: false },
    { days: 0, remindBy: "after", remindMe: isNotified, isActive: false },
  ]);

  useEffect(() => {
    setReminderData((prevData) =>
      prevData.map((reminder) => ({
        ...reminder,
        remindMe: isNotified,
        days:
          reminder.remindBy === reminderType
            ? Number(daysInput)
            : reminder.days,
      })),
    );
  }, [daysInput, isNotified, reminderType]);

  const handleReminderToggle = () => {
    setIsNotified((prev) => {
      const newIsNotified = !prev;

      // Update `reminderData` based on the new value of `isNotified`
      setReminderData((prevData) =>
        prevData.map((reminder) => ({
          ...reminder,
          remindMe: newIsNotified,
        })),
      );

      return newIsNotified;
    });
  };
  const handleReminderChange = (updatedReminderData: {
    activeStatus: { before: boolean; due: boolean; after: boolean };
    days: { before: number; due: number; after: number };
  }) => {
    const adaptedReminderData: ISetReminder[] = Object.keys(
      updatedReminderData.days,
    ).map((key) => ({
      days: updatedReminderData.days[key as "before" | "due" | "after"],
      remindBy: key as "before" | "due" | "after",
      remindMe: isNotified,
      isActive:
        updatedReminderData.activeStatus[key as "before" | "due" | "after"],
    }));

    console.log(adaptedReminderData);

    setReminderData(adaptedReminderData);
  };

  const handleSave = () => {
    if (Array.isArray(reminderData)) {
      reminderData.forEach((data) => onSaveReminder(data));
    } else {
      onSaveReminder(reminderData);
    }
  };

  return (
    <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh] ">
      <div className="flex items-end justify-center md:items-center h-full w-full ">
        <div className="overflow-y-auto overflow-x-hidden fixed md:top-[10%] w-full md:w-[500px] lg:w-[500px] p-6 bg-white rounded-[8px]">
          <div className="flex justify-between items-center cursor-pointer mb-2">
            <h1 className="text-[16px] md:text-[24px] lg:text-[24px] text-[#292D32] leading-7 font-normal">
              {title}
            </h1>
            <div onClick={() => setShowSendModal(false)}>
              <CloseIcon />
            </div>
          </div>
          <p className="text-gray-700 mb-4 text-[12px] lg:text-[16px]">
            Send pre-set reminders to clients{" "}
            {reminderType === "before"
              ? "before"
              : reminderType === "after"
                ? "after"
                : "on"}{" "}
            the due date.
          </p>
          <div>
            <div className="flex items-center justify-between border border-gray-300 py-2 px-[1em] md:max-w-[580px] rounded-md">
              <span className="text-sm text-gray-700">
                Disable Automated Reminders
              </span>
              <div
                className={`w-12 h-6 p-1 rounded-[69.33px] flex items-center transition duration-200 ease-in cursor-pointer bg-[#044E97] justify-end border-[#044E97]`}
                style={{
                  borderWidth: "1px",
                }}
              >
                <div
                  className={`w-4 h-4 rounded-full shadow-md transition-transform duration-200 ease-in bg-[#ffffff]`}
                ></div>
              </div>
            </div>

            <div className="bg-[#F9FAFB] p-5 rounded-[4px] md:max-w-[540px] border border-[#044E9733] border-opacity-20 mt-[0.5em] lg:ml-[2.2em]">
              <p className="text-[#0A0A0A] mb-8 text-[12px]">
                Automatically send timely reminders to clients before or after
                invoice due dates to improve payment collection and reduce
                manual follow-ups.
              </p>
              <div className="mt-[-2em]">
                <ReminderInvoiceOptions
                  onReminderChange={handleReminderChange}
                />
              </div>
            </div>
          </div>

          <div className="flex items-center mb-6 mt-[1em] cursor-pointer">
            <input
              type="checkbox"
              checked={isNotified}
              onChange={handleReminderToggle}
              className="mr-2"
            />
            <label className="text-gray-700 text-[12px] lg:text-[14px]">
              Notify me when reminders are sent
            </label>
          </div>
          <button
            className={`w-full bg-[#044E97] text-white py-3 rounded-lg cursor-pointer ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? "Saving..." : "Automate Reminder"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoiceReminderModal;
