import { useState } from "react";

const useExpenseDetails = () => {
  const [expenseDetails, setExpenseDetails] = useState({
    vendor: "",
    vendorId: "",
    paymentMethod: "",
    category: "",
    notes: "",
    tag: "",
    customer: "",
    customerId: "",
    customerName: "",
    isBillable: false,
  });

  const handleExpenseDetails = (name: string, value: string) => {
    const updatedDetails = { ...expenseDetails, [name]: value };
    setExpenseDetails(updatedDetails);
  };

  return { expenseDetails, setExpenseDetails, handleExpenseDetails };
};

export default useExpenseDetails;
