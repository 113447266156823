import React, { useState } from "react";
import DownloadingLogo from "../../assets/icons/DownloadingLogo";

const AccountLoader = () => {
  const [dotCount, setDotCount] = useState(1);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setDotCount((prev) => (prev === 3 ? 1 : prev + 1));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <span className="flex justify-center items-center">
        <DownloadingLogo />
      </span>
      <p className="lg:text-[20px] text-[16px] font-semibold text-gray-800 mt-4">
        We are creating your Invoice now
      </p>

      <div className="flex justify-center mt-4">
        {[...Array(3)].map((_, idx) => (
          <span
            key={idx}
            className={`h-6 w-5 mx-1 rounded-full animate-up-down ${idx < dotCount ? "bg-blue-600" : "bg-black"}`}
            style={{ animationDelay: `${idx * 150}ms` }}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default AccountLoader;
