import * as Sentry from "@sentry/react";
import { isProduction } from "./env";

export const handleError = (error: any, context: string = "") => {
  if (isProduction) {
    Sentry.captureException(error, {
      tags: { context },
    });
  }
};
