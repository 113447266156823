import React from "react";
import cx from "classnames";
import { invoiceData, expenseData } from "../../@types";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "../../utils";
import { useTranslation } from "react-i18next";
interface InvoiceCardProps {
  title: string;
  value: string;
  text: string;
  icon: React.ReactNode;
  totalNumber: number | null;
  data?: invoiceData | expenseData | any;
  toolTipText?: string;
}

const InvoiceCard: React.FC<InvoiceCardProps> = ({
  title,
  data,
  value,
  icon,
}) => {
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const isTrue = value.trim().toLowerCase() === "filter by date".toLowerCase();

  return (
    <div className="pb-2 pt-0 bg-white rounded-lg w-full">
      <div className="px-2 flex items-center justify-between p-3">
        <div className="flex gap-[3px] items-center">
          {icon}
          <p className="text-[14px] lg:text-[16px] text-[#000000] leading-6 font-semibold">
            {title}
          </p>
        </div>

        <div className="bg-[#F2F4F7] border border-[#EAECF0] py-1 px-2 rounded-lg">
          <p className="text-[14px] font-medium text-[#000000]">
            {isTrue ? "Today" : value}
          </p>
        </div>
      </div>

      <hr className="border border-gray-200 w-full my-2" />

      <div className="px-3 py-2">
        <Card
          title={t("totalValue")}
          bgColorClass="bg-[#ECFDF3] text-[#05603A]"
          value={data?.totalInvoiceValue}
        />
        <div className="flex gap-2 items-center w-full mt-2">
          <Card
            title={t("invoicePaid")}
            bgColorClass="bg-[#E0EEFE] text-[#0D3C6D]"
            value={data?.totalPaidInvoiceValue}
          />
          <Card
            title={t("invoiceUnpaid")}
            bgColorClass="bg-[#FEE4E2] text-[#B42318]"
            value={data?.totalUnPaidInvoiceValue}
          />
        </div>
      </div>
      <div
        onClick={() => navigate("/invoices")}
        className="text-center text-[16px] text-[#0469C5] leading-5 font-semibold mt-5 cursor-pointer mb-4"
      >
        View Invoices
      </div>
    </div>
  );
};

export { InvoiceCard };

interface InfoCardProps {
  title: string;
  value: string;
  bgColorClass: string;
  valuePosition?: { marginRight: string };
}

const Card: React.FC<InfoCardProps> = ({
  title,
  value,
  bgColorClass,
  valuePosition,
}) => {
  const bgClassName = (bgColorClass: string) => {
    return cx("w-full h-[80px] rounded-lg flex items-center justify-center", {
      [bgColorClass]: true,
    });
  };

  return (
    <div className={`${bgClassName(bgColorClass)}`}>
      <div className="font-semibold text-center">
        <p className="text-[12px] leading-4">{title}</p>
        <p className={"text-[16px] leading-6 mt-3"} style={valuePosition}>
          {formatMoney(value)}
        </p>
      </div>
    </div>
  );
};

export { Card };
