import React, { useState } from "react";
import { CloseIcon } from "../../assets/icons";

interface TermsConditionsPromptProps {
  onClose?: () => void;
  setShowModal: (modal: boolean) => void;
}

export const TermsConditions: React.FC<TermsConditionsPromptProps> = ({
  onClose,
  setShowModal,
}) => {
  const [acceptedToc, setAcceptedToc] = useState(false);
  const handleTerm = () => {
    onClose && onClose();
  };
  return (
    <div className="fixed top-0 right-0 left-0 z-50 h-full items-start flex bg-gray-900 bg-opacity-50 rounded-lg shadow blur-background justify-center">
      <div className="lg:w-[900px] md:w-[600px] w-[350px] bg-white mt-[4em] shadow rounded-lg">
        <div className="w-full">
          <div
            className="py-[2em] px-[2em]"
            style={{ maxHeight: "65vh", overflowY: "auto" }}
          >
            <div className="flex justify-end">
              <div
                className="cursor-pointer"
                onClick={() => setShowModal(false)}
              >
                <CloseIcon />
              </div>
            </div>
            <div>
              <h1 className="lg:text-[28px] xl:text-[32px] font-bold whitespace-nowrap">
                Terms and Conditions
              </h1>
              <p className="text-[20px] ">Last Updated: 27th October, 2023</p>
            </div>
            <p className="mt-8">
              Welcome to Esemie, a powerful SaaS business management tool
              designed to streamline your business processes, including
              invoicing, expense tracking, and inventory management. Before you
              proceed with using our services, please carefully read and
              understand the following terms and conditions. Your use of Esemie
              is conditioned upon your acceptanConditionsce of and compliance
              with these terms.
            </p>
            <h2 className="xl:text-[20px] font-semibold py-2">
              1. Acceptance of Terms
            </h2>
            <p>
              {" "}
              By accessing or using the Esemie platform, you agree to be bound
              by these terms and conditions. If you do not agree with any part
              of these terms, you may not use our services.
            </p>
            <h2 className="xl:text-[20px] font-semibold py-2">
              2. Account Registration
            </h2>
            <p>
              {" "}
              To use Esemie, you must register for an account. You agree to
              provide accurate, current, and complete information during the
              registration process. You are responsible for maintaining the
              confidentiality of your account credentials and for all activities
              that occur under your account.
            </p>
            <h2 className="xl:text-[20px] font-semibold py-2">
              3. User Conduct
            </h2>
            <p>
              {" "}
              You agree to use Esemie for lawful purposes only. You are
              prohibited from engaging in any conduct that, in our sole
              discretion, restricts or inhibits any other user from using or
              enjoying Esemie. Prohibited activities include, but are not
              limited to, attempting to interfere with the proper functioning of
              the platform, and transmitting any material that is harmful,
              offensive, or violates applicable laws.
            </p>
            <h2 className="xl:text-[20px] font-semibold py-2">
              4. Data Security
            </h2>
            <p>
              {" "}
              Esemie is committed to maintaining the security and
              confidentiality of your data. We implement industry-standard
              security measures to protect your information; however, we cannot
              guarantee the absolute security of your data.
            </p>
            <h2 className="xl:text-[20px] font-semibold py-2">
              5. Payment and Subscription
            </h2>
            <p>
              {" "}
              If you choose to subscribe to Esemie&#39;s premium services, you
              agree to pay the fees associated with your selected plan. Payments
              are due on the specified billing cycle, and failure to pay may
              result in the suspension or termination of your account.
            </p>
            <h2 className="xl:text-[20px] font-semibold py-2">
              6. Termination
            </h2>
            <p>
              {" "}
              We reserve the right to suspend or terminate your account at our
              discretion if we believe you have violated these terms or engaged
              in fraudulent, abusive, or unlawful activities.
            </p>
            <h2 className="xl:text-[20px] font-semibold py-2">
              7. Intellectual Property
            </h2>
            <p>
              {" "}
              Esemie and its content, features, and functionality are owned by
              [Your Company Name] and are protected by international copyright,
              trademark, patent, trade secret, and other intellectual property
              or proprietary rights laws.
            </p>
            <h2 className="xl:text-[20px] font-semibold py-2">
              8. Disclaimer of Warranties
            </h2>
            <p>
              {" "}
              Esemie is provided &#34;as is&#34; and &#34;as available&#34;
              without any warranty of any kind, either expressed or implied,
              including, but not limited to, the implied warranties of
              merchantability, fitness for a particular purpose, or
              non-infringement.
            </p>
            <h2 className="xl:text-[20px] font-semibold py-2">
              9. Limitation of Liability
            </h2>
            <p>
              {" "}
              In no event shall [Your Company Name] or its suppliers be liable
              for any indirect, incidental, special, consequential, or punitive
              damages, including, without limitation, loss of profits, data,
              use, goodwill, or other intangible losses.
            </p>
            <h2 className="xl:text-[20px] font-semibold py-2">
              10. Changes to Terms
            </h2>
            <p>
              {" "}
              Esemie reserves the right to modify or replace these terms at any
              time. We will notify you of any changes by posting the new terms
              on the Esemie platform. Your continued use of Esemie after any
              such changes constitute your acceptance of the new terms.
            </p>
            <h2 className="xl:text-[20px] font-semibold py-2">
              11. Governing Law
            </h2>
            <p>
              {" "}
              These terms and conditions are governed by and construed in
              accordance with the laws of The Federal Republic of Nigeria. Any
              disputes arising from or in connection with these terms shall be
              subject to the exclusive jurisdiction of the courts in Nigeria.
            </p>
            <p className="py-2">
              {" "}
              By using Esemie, you acknowledge that you have read, understood,
              and agreed to these terms and conditions. If you have any
              questions or concerns, please contact us via
            </p>
            <p>
              Email:{" "}
              <span className="xl:text-blue-500">officalesemie@gmail.com</span>
            </p>
            <p className="py-2"> Thank you for choosing Esemie! </p>

            <div className="flex items-center justify-end mt-6 mb-4">
              <button
                onClick={handleTerm}
                className="bg-[#FF6702] text-[white] w-[375px] h-[48px] rounded-md"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
