import React, { useEffect, useState } from "react";
import TopSellingEmptyState from "../../assets/icons/TopSellingEmptyState";
import { useDashboardStats } from "../../hooks/useDashboardStats";
import { ImSpinner } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "../../utils";

interface TopSellingType {
  id: string;
  productName: string;
  amount: number;
}

const TopSellingProducts = () => {
  const navigate = useNavigate();
  const { fetchProducts, topSellingProducts, loadingProducts } =
    useDashboardStats();

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="rounded-lg bg-white py-3 lg:py-6 w-full">
      {loadingProducts ? (
        <div className="h-[300px] w-full flex justify-center items-center">
          <ImSpinner className="h-16 w-20 mx-auto text-slate-300 animate-spin" />
        </div>
      ) : (
        <>
          {topSellingProducts && topSellingProducts.length > 0 ? (
            <div className="w-full">
              <h2 className="px-3 md:px-6 text-[#1D2939] text-[16px] font-medium leading-6">
                Top selling Products
              </h2>
              <hr className="border border-[#EAECF0] w-full mt-3 mb-2" />
              <div className="space-y-2 px-3 md:px-6">
                {topSellingProducts?.map((product: TopSellingType, index) => (
                  <div
                    id={product.id}
                    key={product.id}
                    className="flex justify-between items-center py-2 text-[14px]"
                  >
                    <p className="text-gray-700 font-medium leading leading-5">
                      {product.productName}
                    </p>
                    <p className="text-[#000000] font-semibold leading leading-5">
                      {formatMoney(product.amount)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="bg-white w-full p-3">
              <h2 className="text-[#1D2939] text-[16px] font-medium leading-6">
                Top selling Products
              </h2>
              <div className="flex flex-col justify-center items-center mt-5 gap-5">
                <TopSellingEmptyState />
                <p className="text-[#344054] text-[14px] font-medium leading-5 text-center">
                  Start managing your business. <br /> What will you like to do
                  ?{" "}
                </p>
                <div
                  onClick={() => navigate("/inventories")}
                  className="px-4 py-2 text-[#044E97] text-[14px] font-medium leading-5 border border-[#044E97] rounded-[4px] cursor-pointer"
                >
                  Create an Inventory
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TopSellingProducts;
