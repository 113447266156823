import { userService } from "../services";
import { UserDetail, UserT } from "../types";
import { handleError, withRetry } from "../utils";

const BASE = `users`;

export const createUser = async (data: UserDetail) => {
  return withRetry(async () => {
    const res = await userService.post(`/${BASE}`, data);
    return res.data.data;
  }).catch(handleError);
};

export const updateUser = async (companyId: string, data: UserDetail) => {
  return withRetry(async () => {
    const res = await userService.patch(`/${BASE}/${companyId}`, data);
    return res.data;
  }).catch(handleError);
};

export const getUser = async (companyId: string) => {
  return withRetry(async () => {
    const res = await userService.get(`/${BASE}/${companyId}`);
    return res.data;
  }).catch(handleError);
};

export const deleteUser = async (companyId: string) => {
  return withRetry(async () => {
    const res = await userService.delete(`/${BASE}/${companyId}`);
    return res.data;
  }).catch(handleError);
};
