import React from "react";

const PlusBoxIcon = () => {
  return (
    <div>
      <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.125 9C2.125 6.78337 2.125 5.67506 2.5521 4.82655C2.93793 4.06004 3.56004 3.43793 4.32655 3.0521C5.17506 2.625 6.28337 2.625 8.5 2.625V2.625C10.7166 2.625 11.8249 2.625 12.6734 3.0521C13.44 3.43793 14.0621 4.06004 14.4479 4.82655C14.875 5.67506 14.875 6.78337 14.875 9V9C14.875 11.2166 14.875 12.3249 14.4479 13.1734C14.0621 13.94 13.44 14.5621 12.6734 14.9479C11.8249 15.375 10.7166 15.375 8.5 15.375V15.375C6.28337 15.375 5.17506 15.375 4.32655 14.9479C3.56004 14.5621 2.93793 13.94 2.5521 13.1734C2.125 12.3249 2.125 11.2166 2.125 9V9Z"
          stroke="#044E97"
          strokeWidth="2"
        />
        <path
          d="M8.5 6.16797L8.5 11.8346"
          stroke="#044E97"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M11.332 9L5.66536 9"
          stroke="#044E97"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default PlusBoxIcon;
