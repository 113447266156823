import React from "react";

const InvCusExpEmptyIcon = () => {
  return (
    <>
      <svg
        width="151"
        height="162"
        viewBox="0 0 151 162"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="12"
          width="150"
          height="150"
          rx="75"
          fill="url(#paint0_linear_49048_115861)"
        />
        <rect
          x="25.5"
          width="100"
          height="153"
          rx="8"
          fill="url(#paint1_linear_49048_115861)"
        />
        <rect x="35.5" y="28" width="75" height="10" rx="5" fill="#C5DBFC" />
        <rect x="35.5" y="10" width="60" height="10" rx="5" fill="#C5DBFC" />
        <rect x="35.5" y="64" width="45" height="10" rx="5" fill="#C5DBFC" />
        <rect x="35.5" y="46" width="63" height="10" rx="5" fill="#C5DBFC" />
        <path
          d="M14.5 131C20.768 131 23.5 128.363 23.5 122C23.5 128.363 26.213 131 32.5 131C26.213 131 23.5 133.713 23.5 140C23.5 133.713 20.768 131 14.5 131Z"
          fill="#1085E7"
          stroke="#1085E7"
          stroke-width="1.5"
          stroke-linejoin="round"
        />
        <path
          d="M141.5 14.25C141.851 14.25 142.155 14.493 142.232 14.8352L142.77 17.2268C143.164 18.9728 144.527 20.3363 146.273 20.7296L148.665 21.2683C149.007 21.3454 149.25 21.6493 149.25 22C149.25 22.3507 149.007 22.6546 148.665 22.7317L146.273 23.2704C144.527 23.6637 143.164 25.0272 142.77 26.7732L142.232 29.1648C142.155 29.507 141.851 29.75 141.5 29.75C141.149 29.75 140.845 29.507 140.768 29.1648L140.23 26.7732C139.836 25.0272 138.473 23.6637 136.727 23.2704L134.335 22.7317C133.993 22.6546 133.75 22.3507 133.75 22C133.75 21.6493 133.993 21.3454 134.335 21.2683L136.727 20.7296C138.473 20.3363 139.836 18.9728 140.23 17.2268L140.768 14.8352C140.845 14.493 141.149 14.25 141.5 14.25Z"
          fill="#7EC1FB"
        />
        <path
          d="M133.5 24.25C133.851 24.25 134.155 24.493 134.232 24.8352L134.616 26.5435C134.879 27.7099 135.79 28.6208 136.957 28.8835L138.665 29.2683C139.007 29.3454 139.25 29.6493 139.25 30C139.25 30.3507 139.007 30.6546 138.665 30.7317L136.957 31.1165C135.79 31.3792 134.879 32.2901 134.616 33.4565L134.232 35.1648C134.155 35.507 133.851 35.75 133.5 35.75C133.149 35.75 132.845 35.507 132.768 35.1648L132.384 33.4565C132.121 32.2901 131.21 31.3792 130.043 31.1165L128.335 30.7317C127.993 30.6546 127.75 30.3507 127.75 30C127.75 29.6493 127.993 29.3454 128.335 29.2683L130.043 28.8835C131.21 28.6208 132.121 27.7099 132.384 26.5435L132.768 24.8352C132.845 24.493 133.149 24.25 133.5 24.25Z"
          fill="#7EC1FB"
        />
        <path
          d="M10.4987 28.833C10.7325 28.833 10.9351 28.995 10.9865 29.2231L11.3456 30.8175C11.6078 31.9815 12.5168 32.8905 13.6809 33.1527L15.2752 33.5119C15.5033 33.5633 15.6654 33.7659 15.6654 33.9997C15.6654 34.2335 15.5033 34.4361 15.2752 34.4875L13.6809 34.8466C12.5168 35.1088 11.6078 36.0178 11.3456 37.1818L10.9865 38.7762C10.9351 39.0043 10.7325 39.1663 10.4987 39.1663C10.2649 39.1663 10.0623 39.0043 10.0109 38.7762L9.65177 37.1818C9.38956 36.0178 8.48055 35.1088 7.31654 34.8466L5.72215 34.4875C5.49406 34.4361 5.33203 34.2335 5.33203 33.9997C5.33203 33.7659 5.49406 33.5633 5.72215 33.5119L7.31654 33.1527C8.48055 32.8905 9.38956 31.9815 9.65177 30.8175L10.0109 29.2231C10.0623 28.995 10.2649 28.833 10.4987 28.833Z"
          fill="#BBDDFC"
        />
        <path
          d="M5.16536 35.5C5.39917 35.5 5.60176 35.662 5.65314 35.8901L5.90968 37.029C6.08485 37.8066 6.69211 38.4138 7.46973 38.589L8.60858 38.8456C8.83667 38.8969 8.9987 39.0995 8.9987 39.3333C8.9987 39.5671 8.83667 39.7697 8.60858 39.8211L7.46973 40.0776C6.69211 40.2528 6.08485 40.8601 5.90968 41.6377L5.65314 42.7765C5.60176 43.0046 5.39917 43.1667 5.16536 43.1667C4.93156 43.1667 4.72897 43.0046 4.67759 42.7765L4.42105 41.6377C4.24588 40.8601 3.63862 40.2528 2.861 40.0776L1.72215 39.8211C1.49406 39.7697 1.33203 39.5671 1.33203 39.3333C1.33203 39.0995 1.49406 38.8969 1.72215 38.8456L2.861 38.589C3.63862 38.4138 4.24588 37.8066 4.42105 37.029L4.67759 35.8901C4.72897 35.662 4.93156 35.5 5.16536 35.5Z"
          fill="#BBDDFC"
        />
        <defs>
          <linearGradient
            id="paint0_linear_49048_115861"
            x1="75.5"
            y1="12"
            x2="75.5"
            y2="162"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.289415" stop-color="#F1F8FF" />
            <stop offset="1" stop-color="#FCFEFF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_49048_115861"
            x1="75.5"
            y1="0"
            x2="75.5"
            y2="153"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#DFEFFF" />
            <stop offset="1" stop-color="white" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default InvCusExpEmptyIcon;
