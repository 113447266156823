import React from "react";
import CompleteIcon from "../../../assets/icons/CompleteIcon";
import { isCompleted } from "../../../utils/todoSection";

const ProgressBar = ({ percentage }: { percentage: number }) => {
  return (
    <>
      {isCompleted(percentage) ? (
        <div className="flex item-center gap-2">
          <div data-testid="complete-icon">
            <CompleteIcon />
          </div>
          <p className="text-[#039855]">Completed</p>
        </div>
      ) : (
        <div className="w-full h-2 rounded-lg bg-[#FFF0D3]">
          <div
            data-testid="progress-bar"
            style={{ width: `${percentage === 0 ? 5 : percentage}%` }}
            className="bg-[#FF9D32] rounded-lg h-full"
          ></div>
        </div>
      )}
    </>
  );
};

export default ProgressBar;
