import {
  createUser,
  updateUser,
  deleteUser,
  getUser,
} from "../backend-services/user";
import { UserDetail } from "../types";
import { userService } from "../services";
import { toast } from "react-hot-toast";

export const subUserModule = {
  fetchUsers: async () => {
    try {
      let query = `/users`;
      const res = await userService.get(query);
      return res.data.data;
    } catch (error) {
      console.error(error);
    }
  },
  addUser: async (userDetails: UserDetail) => {
    try {
      const res = await createUser(userDetails);
      return res;
    } catch (error: any) {
      throw new Error(error.response?.data || error.message);
    }
  },
  updateUser: async (userId: string, userDetail: UserDetail) => {
    try {
      const res = await updateUser(userId, userDetail);
      return res;
    } catch (error) {
      throw new Error("Failed to update user details");
    }
  },
  disableUser: async (
    userId: string,
    userStatus: { status: "DISABLED" | "ENABLED" },
  ) => {
    try {
      const res = await updateUser(userId, userStatus);
      return res;
    } catch (error) {
      throw new Error("Failed to disable user");
    }
  },
  deleteUser: async (userId: string) => {
    try {
      const res = await deleteUser(userId);
      return res;
    } catch (error) {
      throw new Error("Failed to delete user");
    }
  },
  getUser: async (userId: string) => {
    try {
      const res = await getUser(userId);
      return res;
    } catch (error) {
      throw new Error("Failed to get user");
    }
  },
};
