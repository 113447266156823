import React from "react";

const CompleteIcon = () => {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_50101_118361)">
          <path
            d="M14.6654 8C14.6654 4.31811 11.6806 1.33334 7.9987 1.33334C4.3168 1.33334 1.33203 4.31811 1.33203 8C1.33203 11.6819 4.3168 14.6667 7.9987 14.6667C11.6806 14.6667 14.6654 11.6819 14.6654 8Z"
            stroke="#039855"
            stroke-width="1.5"
          />
          <path
            d="M5.33203 8.5C5.33203 8.5 6.3987 9.10835 6.93203 10C6.93203 10 8.53203 6.5 10.6654 5.33334"
            stroke="#039855"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_50101_118361">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default CompleteIcon;
