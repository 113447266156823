import * as React from "react";
import cx from "classnames";
import { Spinner } from "flowbite-react";

interface ButtonProps {
  /**
   * Loading state of the button.
   * @type boolean
   * @default false
   */
  isLoading?: boolean;
  /**
   * Text to display as button label
   * @text string
   * @default undefined
   */
  text?: string;
  children?: any;
  /**
   * Different button variant.
   * @variant primary | success | secondary | danger | white
   * @default primary
   */
  variant?:
    | "primary"
    | "success"
    | "secondary"
    | "danger"
    | "white"
    | "plain"
    | "white-blue-border";
  font?: "normal" | "medium" | "semibold" | "bold";
  /**
   * Is button disabled?
   * @disabled boolean
   */
  disabled?: boolean;
  /**
   * Determines the size of the button
   * @size small | full
   * @default full
   */
  size?: "small" | "full" | "medium";
  /**
   * Renders outline button with borders
   * @type boolean
   * @default false
   */
  outline?: boolean;
  /**
   * Function called on button click
   * @param e React.
   * @return void
   * @default undefined
   *
   */
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: "button" | "submit" | "reset";
  /** Test ID for testing purposes */
  "data-testid"?: string;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  isLoading,
  disabled,
  variant = "primary",
  size = "full",
  outline = false,
  text,
  children,
  type,
  font = "normal",
  "data-testid": testId,
}) => {
  return (
    <>
      <button
        data-testid={testId}
        disabled={disabled || isLoading}
        onClick={onClick}
        className={cx({
          "cursor-pointer": !disabled && !isLoading,
          "w-full": size === "full",
          "w-[150px]": size === "small",
          "w-[215px]": size === "medium",
          "font-normal": font === "normal",
          "font-medium": font === "medium",
          "font-semibold": font === "semibold",
          "font-bold": font === "bold",
          "rounded-[4px]": true,
          "cursor-progress": isLoading,
          "cursor-not-allowed": disabled,
          "text-center": true,
          "text-black": variant === "white",
          "bg-white": variant === "white",
          "text-white": variant !== "white" && !outline,
          "text-[#828282]": disabled,
          "text-red": variant === "danger",
          "h-[48px]": true,
          "hover:bg-[#084784]":
            variant === "secondary" && !disabled && !outline,
          "hover:opacity-[0.7]":
            !disabled && variant !== "white" && variant !== "secondary",
          "hover:bg-[#E6F4FF]": variant === "white-blue-border" && !disabled,
          "bg-[#FF6702]": !disabled && !outline && variant === "primary",
          "bg-[#088306]": !disabled && !outline && variant === "success",
          "bg-[#044E97]": !disabled && !outline && variant === "secondary",
          "bg-[#FB0303]": !disabled && !outline && variant === "danger",
          // 'bg-white': (variant === 'white-blue-border' || variant === 'white') && !disabled,
          "border-[#FF6702]": outline && variant === "primary" && !disabled,
          "border-[#088306]": outline && variant === "success" && !disabled,
          "border-[#044E97]": outline && variant === "secondary" && !disabled,
          "border-[#FB0303]": outline && variant === "danger" && !disabled,
          "border-[#EAECF0]": variant === "white" && !outline && !disabled,
          "border-[#084784]": variant === "white-blue-border",
          "border-[1px]":
            outline || variant === "white" || variant === "white-blue-border",
          "text-[#FF6702]": outline && variant === "primary" && !disabled,
          "text-[#044E97]": outline && variant === "secondary" && !disabled,
          "text-[#088306]": outline && variant === "success" && !disabled,
          "text-[#FB0303]": outline && variant === "danger" && !disabled,
          "text-[#084784]": variant === "white-blue-border",
          "bg-[#F6F6F6]": outline && !disabled,
          "bg-[#BBDDFC]": disabled,
        })}
      >
        {isLoading ? (
          <div className="mr-3 ">
            <Spinner size="sm" light={true} />
          </div>
        ) : (
          text || children
        )}
      </button>
    </>
  );
};

export { Button };
