import * as React from "react";

interface ServiceBadgeProps {
  label: string;
  color: "active" | "pending" | "inactive" | "secondary";
}

const ServiceBadge: React.FC<ServiceBadgeProps> = (props) => {
  const getColor = React.useCallback((): string => {
    switch (props.color) {
      case "active":
        return "#088306";
      case "pending":
        return "#FF6702";
      case "inactive":
        return "#4E4E4E";
      case "secondary":
        return "#0a0707";
      default:
        return props.color;
    }
  }, [props.color]);

  return <>{props.label}</>;
};

export { ServiceBadge };
