import React, { useEffect, useRef, useState } from "react";
import Joyride, { CallBackProps, Step } from "react-joyride";
import { InfoBigCircle } from "../../../assets/icons/InfoCircle";
import InfoIcon from "../../../assets/icons/InfoIcon";
import { BUSINESS_TYPE_RETAIL } from "../../../constants";
import { useUserStore } from "../../../state";
import MobileSideMenu from "../../sidemenu/MobileSideMenu";
import WelcomeModal from "./StartModal";
import { useTranslation } from "react-i18next";
import useRetailBusinessStatus from "../../../hooks/invoice/useRetailBusinessStatus";

const MobileProtipsModal: React.FC = () => {
  const { t } = useTranslation("onboarding");
  const isRetailBusiness = useRetailBusinessStatus();

  const tips = [
    {
      target: ".menu-item-1",
      title: (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-1 flex-1 justify-center">
              <InfoBigCircle />
              <p className="text-[16px] text-[#404040]"> Dashboard</p>
            </div>
            <button
              onClick={() => setIsTourActive(false)}
              className="text-[#404040] focus:outline-none shadow-md bg-[#F4F7FF] w-6 mt-[-1.5em]"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
        </>
      ),
      content: (
        <p
          style={{ color: "#000000" }}
          className="xl:text-[18px] lg:text-[16px] text-[14px] text-[#000000]"
        >
          {t("onboarding.tour1")}
        </p>
      ),
      disableBeacon: true,
    },
    {
      target: ".menu-item-2",
      title: (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-1 flex-1 justify-center">
              <InfoBigCircle />
              <p className="text-[16px] text-[#404040]"> Sales</p>
            </div>
            <button
              onClick={() => setIsTourActive(false)}
              className="text-[#404040] focus:outline-none shadow-md bg-[#F4F7FF] w-6 mt-[-1.5em]"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
        </>
      ),
      content: (
        <>
          <p
            style={{ color: "#000000" }}
            className="xl:text-[16px] lg:text-[16px] text-[14px]"
            dangerouslySetInnerHTML={{
              __html: t("onboarding.tour2", {
                invoice: "Invoice",
                quote: "Quote",
                bill: "Bill",
              }),
            }}
          />
          <p
            style={{ color: "#000000" }}
            className="xl:text-[18px] lg:text-[16px] text-[14px]"
            dangerouslySetInnerHTML={{
              __html: t("onboarding.tour2b", { quote: "Quote" }),
            }}
          />
          <p
            style={{ color: "#000000" }}
            className="xl:text-[18px] lg:text-[16px] text-[14px]"
            dangerouslySetInnerHTML={{
              __html: t("onboarding.tour2c", {
                invoice: "Invoice",
                bill: "Bill",
              }),
            }}
          />
        </>
      ),
      disableBeacon: true,
    },
    {
      target: ".menu-item-3",
      title: (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-1 flex-1 justify-center">
              <InfoBigCircle />
              <p className="text-[16px] text-[#404040]"> Expenses</p>
            </div>
            <button
              onClick={() => setIsTourActive(false)}
              className="text-[#404040] focus:outline-none shadow-md bg-[#F4F7FF] w-6 mt-[-1.5em]"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
        </>
      ),
      content: (
        <p
          style={{ color: "#000000" }}
          className="xl:text-[18px] lg:text-[16px] text-[14px]"
        >
          {t("onboarding.tour3")}
        </p>
      ),
      disableBeacon: true,
    },
    {
      target: ".menu-item-4",
      title: (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-1 flex-1 justify-center">
              <InfoBigCircle />
              <p className="text-[16px] text-[#404040]">Customer</p>
            </div>
            <button
              onClick={() => setIsTourActive(false)}
              className="text-[#404040] focus:outline-none shadow-md bg-[#F4F7FF] w-6 mt-[-1.5em]"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
        </>
      ),
      content: (
        <p
          style={{ color: "#000000" }}
          className="xl:text-[18px] lg:text-[16px] text-[14px]"
        >
          {" "}
          {t("onboarding.tour4")}
        </p>
      ),
      disableBeacon: true,
    },
    {
      target: ".menu-item-5",
      title: (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-1 flex-1 justify-center">
              <InfoBigCircle />
              <p className="text-[16px] text-[#404040]">
                {" "}
                {isRetailBusiness ? "Inventory" : "Service"}
              </p>
            </div>
            <button
              onClick={() => setIsTourActive(false)}
              className="text-[#404040] focus:outline-none shadow-md bg-[#F4F7FF] w-6 mt-[-1.5em]"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
        </>
      ),
      content: (
        <p
          style={{ color: "#000000" }}
          className="xl:text-[18px] lg:text-[16px] text-[14px]"
        >
          {isRetailBusiness ? t("onboarding.tour5") : t("onboarding.tour6")}
        </p>
      ),
      disableBeacon: true,
    },
    {
      target: ".menu-item-6",
      title: (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-1 flex-1 justify-center">
              <InfoBigCircle />
              <p className="text-[16px] text-[#404040]">Reports</p>
            </div>
            <button
              onClick={() => setIsTourActive(false)}
              className="text-[#404040] focus:outline-none shadow-md bg-[#F4F7FF] w-6 mt-[-1.5em]"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
        </>
      ),
      content: (
        <p
          style={{ color: "#000000" }}
          className="xl:text-[18px] lg:text-[16px] text-[14px]"
        >
          {" "}
          {t("onboarding.tour7")}
        </p>
      ),
      disableBeacon: true,
    },
    {
      target: ".menu-item-7",
      title: (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-1 flex-1 justify-center">
              <InfoBigCircle />
              <p className="text-[16px] text-[#404040]">User</p>
            </div>
            <button
              onClick={() => setIsTourActive(false)}
              className="text-[#404040] focus:outline-none shadow-md bg-[#F4F7FF] w-6 mt-[-1.5em]"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
        </>
      ),
      content: (
        <p
          style={{ color: "#000000" }}
          className="xl:text-[18px] lg:text-[16px] text-[14px]"
        >
          {t("onboarding.tour8")}
        </p>
      ),
      disableBeacon: true,
    },
  ];
  const [isTourActive, setIsTourActive] = useState(false);
  const [isModalOpen, setModalOpen] = useState(true);
  const [stepIndex, setStepIndex] = useState(0);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const steps: Step[] = tips.map((tip) => ({
    target: tip.target,
    title: tip.title,
    content: tip.content,
    disableBeacon: true,
  }));

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    if (status === "finished" || status === "skipped") {
      setIsTourActive(false);
    }
  };

  useEffect(() => {
    if (isTourActive) {
      setStepIndex(0);
    }
  }, [isTourActive]);

  const handleShowMeClick = () => {
    setModalOpen(false);
    setIsTourActive(true);
    setShowMobileMenu(true);
  };
  const handleShowMobileMenu = () => {
    setShowMobileMenu((prev) => !prev);
  };

  return (
    <div>
      <Joyride
        steps={steps}
        continuous={true}
        showProgress={false}
        showSkipButton={false}
        hideCloseButton={true}
        run={isTourActive}
        disableScrollParentFix={false}
        disableOverlay={false}
        callback={handleJoyrideCallback}
        locale={{
          back: "Back",
          next: "Next",
          last: "Finish",
          skip: "Skip",
        }}
        styles={{
          options: {
            arrowColor: "#fff",
            backgroundColor: "#ffffff",
            textColor: "#000000",
            width: 500,
            zIndex: 1000,
          },
          buttonNext: {
            backgroundColor: "#044E97",
            color: "#fff",
            borderRadius: "8px",
            padding: "15px 90px",
            fontSize: "16px",
            fontWeight: "bold",
            marginLeft: "auto",
          },
          buttonBack: {
            backgroundColor: "#fff",
            marginRight: 10,
            padding: "15px 80px",
            borderRadius: "8px",
            color: "#044E97",
            border: "2px solid #044E97",
            fontSize: "16px",
            fontWeight: "bold",
            alignSelf: "flex-start",
          },

          tooltip: {
            backgroundColor: "#fff",
            borderRadius: "8px",
            padding: "20px",
          },
          tooltipTitle: {
            fontSize: "18px",
            fontWeight: "bold",
            color: "#5D6D73",
          },
          tooltipContent: {
            fontSize: "16px",
            color: "#000000",
          },
        }}
      />
      {isModalOpen && (
        <WelcomeModal
          onClose={() => setModalOpen(false)}
          onShowMeClick={handleShowMeClick}
          message1="Did you know you can do more with Esemie?"
          message2="Let’s explore together.Click the button below to get started."
        />
      )}

      <div
        className={`lg:hidden md:hidden fixed top-0 left-0 z-30 h-screen w-[300px] transition-transform duration-300 ${
          showMobileMenu ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {showMobileMenu && (
          <MobileSideMenu handleShowMobileMenu={handleShowMobileMenu} />
        )}
      </div>
    </div>
  );
};

export default MobileProtipsModal;
