import React from "react";

const SubscriptionMarkDone = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
      >
        <circle cx="18" cy="18" r="12" fill="#109EC9" />
        <path
          d="M14 17L17.5561 20.8794C17.6155 20.9442 17.7178 20.9442 17.7772 20.8794L25 13"
          stroke="#FF6702"
          stroke-width="2"
        />
        <path
          d="M29.5 18C29.5 20.2745 28.8255 22.4979 27.5619 24.3891C26.2983 26.2802 24.5022 27.7542 22.4009 28.6246C20.2995 29.495 17.9872 29.7228 15.7565 29.279C13.5257 28.8353 11.4766 27.74 9.86827 26.1317C8.25997 24.5234 7.1647 22.4743 6.72097 20.2435C6.27724 18.0128 6.50498 15.7005 7.37539 13.5991C8.24579 11.4978 9.71978 9.70174 11.6109 8.4381C13.5021 7.17446 15.7255 6.5 18 6.5"
          stroke="#044E97"
          stroke-linecap="round"
        />
      </svg>
    </>
  );
};

export default SubscriptionMarkDone;
